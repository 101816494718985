import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {ScheduleMonth} from './schedule-month.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {dateToString} from '../../../../utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class ScheduleMonthFormControlService implements GaiaRecordFormControl<ScheduleMonth> {

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  toFormGroup(record: ScheduleMonth): FormGroup {
    return this.formBuilder.group({
      month: new FormControl(dateToString(record.month), Validators.required),
      planned_tonnage: new FormControl(record.planned_tonnage, Validators.required),
      year: new FormControl(record.year, Validators.required),
      harvest_year: new FormControl(record.harvest_year, Validators.required),
      adjustment_text: new FormControl(record.adjustment_text, Validators.required),
      adjustment_value: new FormControl(record.adjustment_value, Validators.required),
      active: new FormControl(record.active),
    });
  }
}
