import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export class SentinelRoutes {
  public static FUNCTIONS = 'functions';
}

const routes: Routes = [
  {
    path: SentinelRoutes.FUNCTIONS,
    loadChildren: () => import('./sentinel-functions/sentinel-functions-routing.module').then(m => m.SentinelFunctionsRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SentinelRoutingModule { }
