import {Component, Inject, OnInit} from '@angular/core';
import {NewDialogData, NewDialogDirective} from '../../../components/new-dialog.directive';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {SoilSample} from '../soil-sample.model';
import {SoilSamplesService} from '../soil-samples.service';
import {SoilSampleFormControlService} from '../soil-sample-form-control.service';

@Component({
  selector: 'app-soil-sample-new-dialog',
  templateUrl: './soil-sample-new-dialog.component.html',
  styleUrls: ['./soil-sample-new-dialog.component.scss']
})
export class SoilSampleNewDialogComponent extends NewDialogDirective<SoilSample> implements OnInit{

  showCode = false;
  newSoilSample: SoilSample;

  constructor(
    soilSamplesService: SoilSamplesService,
    soilSampleFormControlService: SoilSampleFormControlService,
    @Inject(MAT_DIALOG_DATA) data: NewDialogData<SoilSample>,
    private router: Router,
    public dialogRef: MatDialogRef<SoilSampleNewDialogComponent>,
  ) {
    super(soilSamplesService, soilSampleFormControlService, data);
  }

  successfulSave(result: SoilSample) {
    this.hideOverlay();
    this.showCode = true;
    this.newSoilSample = result;
  }

  redirectToForm() {
    this.router.navigate([AppRoutes.GROWING, GrowingRoutes.SOIL_SAMPLES, this.newSoilSample.id]).then((x) => {
      this.dialogRef.close();
    });
  }
}
