
<gaia-shared-standard-dialog dialogTitle="Link New Contact">

  <div content>
    <div *ngIf="!selectedContact else contactFormSection">
      <p i18n="instruction to search or add for contact">Please search for an existing contact, or click add new</p>

      <div fxLayout="column">
        <div fxLayout="row">
          <app-contact-search (contactSelected)="existingContactSelected($event)" fxFlex="100%">
          </app-contact-search>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="newContact()" i18n="button to add new contact">New Contact</button>
        </div>
      </div>

    </div>

    <ng-template #contactFormSection>
      <app-contact-form [form]="contactForm" [account]="account"></app-contact-form>
    </ng-template>
  </div>

  <div actions>
    <button mat-button [mat-dialog-close]="{ contact: null }">Cancel</button>
    <button *ngIf="selectedContact" class="save-btn" [disabled]="!contactForm.valid" mat-button (click)="saveAndClose()">Save &amp; Add</button>
  </div>

</gaia-shared-standard-dialog>
