import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {PriceGroupFormControlService} from '../price-group-form-control.service';
import {ProductPriceList} from '../price-group.model';

@Component({
  selector: 'app-price-group-form',
  templateUrl: './price-group-form.component.html',
  styleUrls: ['./price-group-form.component.scss']
})
export class PriceGroupFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  newForm = false;

  constructor(
    private priceGroupFormBuilder: PriceGroupFormControlService,
  ) { }

  ngOnInit(): void {
  }

  addProduct() {
    const array = this.form.get('products') as FormArray;
    array.push(this.priceGroupFormBuilder.productPriceListFormGroup({} as ProductPriceList));
  }

}
