

<app-universal-search-preview-wrapper [searchResult]="record" classType="Contact" [icon]="gaiaIconSet.contacts">

  <mat-list>
    <mat-list-item>
      <span matLine>Name</span>
      <span matLine>{{record.first_name}} {{ record.surname}}</span>
    </mat-list-item>
    <mat-list-item>
      <span matLine>Phone</span>
      <span matLine>{{record.phone}}</span>
    </mat-list-item>
  </mat-list>


  <ng-container buttons>
    <button mat-icon-button>
      <mat-icon (click)="showContact()">edit</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
    <mat-list *ngIf="previewData">
      <mat-list-item>
        <mat-icon matListIcon>{{ gaiaIconSet.contacts }}</mat-icon>
        <div matLine>
          <span i18n>Accounts</span> ({{ previewData.accounts_count }})
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>

</app-universal-search-preview-wrapper>

