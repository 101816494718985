<ng-container *ngIf="form" [formGroup]="form">

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>{{gaiaIcons.drone_images}}</mat-icon>
      <mat-card-title i18n>Drone Image Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field>
        <mat-label i18n>Date Taken</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date_taken">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Image Count</mat-label>
        <input matInput formControlName="image_count" type="number" step="1" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Drone Operator</mat-label>
        <input matInput formControlName="drone_operator" type="text" />
      </mat-form-field>

    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="contractedField">

    <mat-card-header>
      <mat-icon mat-card-avatar>{{gaiaIcons.contracted_fields}}</mat-icon>
      <mat-card-title i18n>Field Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      {{ contractedField.name }}

    </mat-card-content>
  </mat-card>
</ng-container>

