import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageGalleryDialogComponent } from './image-gallery-dialog/image-gallery-dialog.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatIconModule} from '@angular/material/icon';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import { ImagesIndexComponent } from './images-index/images-index.component';
import { ImageThumbnailComponent } from './image-thumbnail/image-thumbnail.component';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import { ImageShowComponent } from './image-show/image-show.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import { ImageVersionViewComponent } from './image-version-view/image-version-view.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    declarations: [
        ImageGalleryDialogComponent,
        ImagesIndexComponent,
        ImageThumbnailComponent,
        ImageShowComponent,
        ImageVersionViewComponent,
        ImageUploaderComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        GaiaSharedModule,
        FlexModule,
        MatButtonModule,
        MatCardModule,
        RouterModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        GaiaGeneralModule,
        MatCheckboxModule
    ],
    exports: [
        ImageGalleryDialogComponent, ImagesIndexComponent, ImageUploaderComponent
    ]
})
export class ImagesModule { }
