import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ContractedField} from '../contracted-field.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {
  ContractedFieldAddToContractDialogComponent
} from '../contracted-field-add-to-contract-dialog/contracted-field-add-to-contract-dialog.component';
import {
  ContractedFieldRemoveFromContractDialogComponent,
  ContractedFieldRemoveFromContractDialogData
} from '../contracted-field-remove-from-contract-dialog/contracted-field-remove-from-contract-dialog.component';
import {
  ContractedFieldAddToEstablishmentContractDialogComponent, ContractedFieldAddToEstablishmentContractDialogData
} from '../contracted-field-add-to-establishment-contract-dialog/contracted-field-add-to-establishment-contract-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';
import {ContractedFieldsService} from '../contracted-fields.service';

@Component({
  selector: 'app-contracted-field-contract-panel',
  templateUrl: './contracted-field-contract-panel.component.html',
  styleUrls: ['./contracted-field-contract-panel.component.scss']
})
export class ContractedFieldContractPanelComponent implements OnInit, OnChanges {

  @Input() contractedField: ContractedField;

  @Output()
  detailsChanged = new EventEmitter();

  gaiaIconSet = GaiaIconSet;

  constructor(
    private matDialog: MatDialog,
    private contractedFieldsService: ContractedFieldsService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contractedField.previousValue !== changes.contractedField.currentValue) {
      this.reload();
    }
  }

  reload(){
  }

  removeFromTerm() {
    const ref = this.matDialog.open<ContractedFieldRemoveFromContractDialogComponent, ContractedFieldRemoveFromContractDialogData,
                                      ContractedField>(ContractedFieldRemoveFromContractDialogComponent,
      {
        data: {
          contractedField: this.contractedField,
        }
      });
    ref.afterClosed().subscribe((field) => {
      if (field) {
        this.contractedField = field;
        this.detailsChanged.emit();
      }
    });

  }

  assignToTerm() {
    const ref = this.matDialog.open<ContractedFieldAddToContractDialogComponent, ContractedFieldRemoveFromContractDialogData,
                                      ContractedField>(
      ContractedFieldAddToContractDialogComponent, {
        data: {
          contractedField: this.contractedField,
        }
      });
    ref.afterClosed().subscribe((field) => {
      if (field) {
        this.contractedField = field;
        this.detailsChanged.emit();
      }
    });
  }

  assignToEstablishment() {
    const ref = this.matDialog.open<ContractedFieldAddToEstablishmentContractDialogComponent,
                                    ContractedFieldAddToEstablishmentContractDialogData,
                                    ContractedField>(ContractedFieldAddToEstablishmentContractDialogComponent, {
        data: {
          contractedField: this.contractedField,
        }
      }
    );
    ref.afterClosed().subscribe((field) => {
      if (field) {
        this.contractedField = field;
        this.detailsChanged.emit();
      }
    });
  }

  removeFromEstablishment() {
    const ref = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent,
      {
        data: {
          title: 'Confirm Removal From Establishment Contract',
          message: 'Are you sure you wish to remove this field from the establishment contract?',
        }
      });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.contractedFieldsService.updateRecord({
          recordId: this.contractedField.id,
          data: {
            // @ts-ignore
            establishment_contract_id: null
          }
        }).subscribe((field) => {
          this.detailsChanged.emit();
        });
      }
    });
  }
}
