import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffMileageClaimsTableComponent } from './staff-mileage-claims-table/staff-mileage-claims-table.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TeamSpaceConcernsModule} from '../team-space-concerns/team-space-concerns.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import { StaffMileageClaimFormDialogComponent } from './staff-mileage-claim-form-dialog/staff-mileage-claim-form-dialog.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {MatMenuModule} from '@angular/material/menu';



@NgModule({
    declarations: [StaffMileageClaimsTableComponent, StaffMileageClaimFormDialogComponent],
    exports: [
        StaffMileageClaimsTableComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTableModule,
        FormsModule,
        TeamSpaceConcernsModule,
        MatPaginatorModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        PhanesComponentsModule,
        FlexModule,
        MatMenuModule
    ]
})
export class StaffMileageClaimsModule { }
