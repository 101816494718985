
<ng-container *ngIf="form" [formGroup]="form">

  <gaia-shared-address-embed-form title="Stack Address" formGroupName="address" i18n-title></gaia-shared-address-embed-form>

  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>Stack Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">

        <mat-form-field fxFlex="calc(50% -15px)">
          <mat-label i18n>Stored Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="stored_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <app-schedule-month-select formControlName="schedule_month_id" [harvestYear]="record.harvest_year"
          labelText="Schedule Month"></app-schedule-month-select>

        <div fxLayout="row wrap" fxLayoutGap="15px">
          <app-loader-availabilities-select formControlName="loader_availability_id"></app-loader-availabilities-select>
          <app-bale-grab-availability-select formControlName="bale_grab_availability_id"></app-bale-grab-availability-select>
          <app-stack-storage-method-select formControlName="storage_method_id"></app-stack-storage-method-select>
          <app-stack-notice-period-select formControlName="notice_period_id"></app-stack-notice-period-select>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="15px">
          <mat-form-field fxFlex="calc(50% -15px)">
            <mat-label i18n>Location Description</mat-label>
            <textarea matInput formControlName="location_description" placeholder=""></textarea>
          </mat-form-field>

          <phanes-what-three-words-input fxFlex="calc(50% - 15px)" formGroupName="what_3_words"></phanes-what-three-words-input>

          <mat-form-field fxFlex="calc(50% -15px)">
            <mat-label i18n>Access Restrictions</mat-label>
            <textarea matInput formControlName="access_restrictions" placeholder=""></textarea>
          </mat-form-field>
          <mat-form-field fxFlex="calc(50% -15px)">
            <mat-label i18n>Notes</mat-label>
            <textarea matInput formControlName="notes" placeholder=""></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>Stack Contact</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-account-contact-select formControlName="stack_contact_id"
                                  (contactChanged)="updateContactChanges($event)"
                                  [account]="grower"></app-account-contact-select>
    </mat-card-content>
  </mat-card>




</ng-container>
