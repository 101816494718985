import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermContractsTableComponent } from './term-contracts-table/term-contracts-table.component';
import { TermContractShowComponent } from './term-contract-show/term-contract-show.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import { TermContractFormComponent } from './term-contract-form/term-contract-form.component';
import { TermContractNewDialogComponent } from './term-contract-new-dialog/term-contract-new-dialog.component';
import {FlexModule} from '@angular/flex-layout';
import {ContractsSharedModule} from '../contracts-shared/contracts-shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { TermContractHectarageOverviewWidgetComponent } from './widgets/term-contract-hectarage-overview-widget/term-contract-hectarage-overview-widget.component';
import {DashboardSharedModule} from '../../../custom-dashboards/dashboard-shared/dashboard-shared.module';
import { TermContractYearPriceFormDialogComponent } from './term-contract-year-price/term-contract-year-price-form-dialog/term-contract-year-price-form-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {TermContractSelectComponent} from './term-contract-select/term-contract-select.component';
import {MatSelectModule} from '@angular/material/select';
import {
  TermContractsFieldTableEmbedComponent
} from './term-contracts-field-table-embed/term-contracts-field-table-embed.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {TermContractDisplayCardComponent} from './term-contract-display-card/term-contract-display-card.component';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TermContractHistoryTableComponent } from './term-contract-history-table/term-contract-history-table.component';
import {TermContractYearPriceModule} from './term-contract-year-price/term-contract-year-price.module';
import { TermContractAddFieldDialogComponent } from './term-contract-add-field-dialog/term-contract-add-field-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



@NgModule({
  declarations: [
    TermContractsTableComponent,
    TermContractShowComponent,
    TermContractFormComponent,
    TermContractNewDialogComponent,
    TermContractHectarageOverviewWidgetComponent,
    TermContractYearPriceFormDialogComponent,
    TermContractHectarageOverviewWidgetComponent,
    TermContractSelectComponent,
    TermContractsFieldTableEmbedComponent,
    TermContractDisplayCardComponent,
    TermContractHistoryTableComponent,
    TermContractAddFieldDialogComponent,
  ],
  exports: [
    TermContractSelectComponent,
    TermContractsFieldTableEmbedComponent,
    TermContractDisplayCardComponent
  ],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        GaiaSharedModule,
        MatTableModule,
        RouterModule,
        MatPaginatorModule,
        FlexModule,
        ContractsSharedModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        DashboardSharedModule,
        MatSelectModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatProgressSpinnerModule,
        TermContractYearPriceModule,
        MatDatepickerModule,
        MatDialogModule,
        MatSlideToggleModule
    ]
})
export class TermContractsModule { }
