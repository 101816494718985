import {Component, ViewChild} from '@angular/core';
import {GrowersService} from '../growers.service';
import {Grower} from '../grower.model';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MatDialog} from '@angular/material/dialog';
import {SearchFilterButtonComponent} from '../../../gaia-general/search-filter-button/search-filter-button.component';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowerNewDialogComponent} from '../grower-new-dialog/grower-new-dialog.component';
import {GrowingRoutes} from '../../growing-routing.module';

@Component({
  selector: 'app-grower-table',
  templateUrl: './growers-table.component.html',
  styleUrls: ['./growers-table.component.scss']
})
export class GrowersTableComponent extends IndexTableDirective<Grower> {

  displayedColumns = ['grower_code', 'account_name', 'field_count', 'replanting_count', 'badges'];
  growerRoutes = GrowingRoutes;

  @ViewChild('inContract')
  inContract: SearchFilterButtonComponent;
  inContractInitialState = false;

  @ViewChild('hasWarnings')
  hasWarnings: SearchFilterButtonComponent;
  hasWarningsInitialState = false;

  constructor(
    growersService: GrowersService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(growersService, router, activatedRoute, matDialog, GrowerNewDialogComponent,
        { active: 'grower_code', direction: 'asc' }, ['account_name', 'grower_code']);
  }

  searchOpts(): any {
    const searchData = super.searchOpts();
    if (this.searchTerm) {
      searchData.search_mode = 'or';
    }
    if (this.inContract && this.inContract.getState()) {
      searchData.in_contract = 1;
    }
    if (this.hasWarnings && this.hasWarnings.getState()) {
      searchData.has_warnings = true;
    }
    return searchData;
  }

  parseStartSearch(search: any) {
    super.parseStartSearch(search);
    if (search.in_contract) {
      this.inContractInitialState = true;
      this.currentSearch.in_contract = 1;
    }
    if (search.has_warnings) {
      this.hasWarningsInitialState = true;
      this.currentSearch.has_warnings = 1;
    }
  }
}
