<div fxLayout="row">

  <mat-card fxFlex="100%">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="account_name">
        <mat-header-cell mat-header-cell *matHeaderCellDef i18n> Account </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{element.account_name}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="relationship_type" >
        <mat-header-cell mat-header-cell *matHeaderCellDef i18n> Relationship </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{element.relationship_type}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grower_code">
        <mat-header-cell mat-header-cell *matHeaderCellDef i18n> Grower Code </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{element.grower_code}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="account_type" >
        <mat-header-cell mat-header-cell *matHeaderCellDef i18n> Account Type </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          <div [ngSwitch]="element.account_type">
          <div *ngSwitchCase="'Grower'"> <a mat-button color="accent" [routerLink]="['/',appRoutes.GROWING, growerRoutes.GROWERS, element.id]">
            {{element.account_type}}</a>
          </div>
            <div *ngSwitchCase="'Processor'"> <a mat-button color="accent" [routerLink]="['/',appRoutes.OPERATIONS, operationRoutes.PROCESSORS, element.id]">
              {{element.account_type}}</a>
            </div>
            <div *ngSwitchCase="'Contractor'"> <a mat-button color="accent" [routerLink]="['/',appRoutes.OPERATIONS, operationRoutes.CONTRACTORS, element.id]">
              {{element.account_type}}</a>
            </div>
            <div *ngSwitchCase="'Haulier'"> <a mat-button color="accent" [routerLink]="['/',appRoutes.OPERATIONS, operationRoutes.HAULIERS, element.id]">
              {{element.account_type}}</a>
            </div>
            <div *ngSwitchCase="'Stockist'"> <a mat-button color="accent" [routerLink]="['/',appRoutes.COMMERCIAL_SALES, commercialSalesRoutes.STOCKISTS, element.id]">
              {{element.account_type}}</a>
            </div>
            <div *ngSwitchDefault>
              No Account for this Contact
            </div>
          </div>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <!--
      <mat-row *matNoDataRow>
        <mat-cell>No data</mat-cell>
      </mat-row>
      -->

    </mat-table>

  </mat-card>
</div>
