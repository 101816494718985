import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MiscanthusHarvest} from '../miscanthus-harvest.model';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../../growing/growing-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';
import {MiscanthusStack} from '../../miscanthus-stacks/miscanthus-stack.model';
import {GrowersService} from '../../../growing/growers/growers.service';
import {GrowerHarvestForYear} from '../../../growing/growers/grower.model';
import {MiscanthusStacksService} from '../../miscanthus-stacks/miscanthus-stacks.service';
import {ConfirmDialogComponent, ConfirmDialogData, ProcessingOverlayComponent} from '@terravesta/gaia-shared';
import {MiscanthusHarvestsService} from '../miscanthus-harvests.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-miscanthus-harvest-review',
  templateUrl: './miscanthus-harvest-review.component.html',
  styleUrls: ['./miscanthus-harvest-review.component.scss']
})
export class MiscanthusHarvestReviewComponent implements OnChanges {

  @Input()
  miscanthusHarvest: MiscanthusHarvest;

  harvestSummary: GrowerHarvestForYear;

  stacks: Array<MiscanthusStack> = new Array<MiscanthusStack>();

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  operationsRoutes = OperationsRoutes;

  fieldColumns = ['name', 'planted_area_ha', 'baler_count', 'bale_count', 'total_yield', 'per_ha', 'variety'];

  canUpdate = false;

  @ViewChild(ProcessingOverlayComponent)
  overlay: ProcessingOverlayComponent;

  constructor(
    private miscanthusHarvestsService: MiscanthusHarvestsService,
    private miscanthusStacksService: MiscanthusStacksService,
    private growersService: GrowersService,
    private matDialog: MatDialog,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.miscanthusHarvest) {
      this.loadFieldPerformance(!changes.miscanthusHarvest.isFirstChange());
      this.getStacks();
    }
  }

  getStacks(): void {
    this.miscanthusStacksService.getRecords(
      { search: { harvest: { uuid: this.miscanthusHarvest.id }}, pageNumber: 0, pageSize: 100}
    ).subscribe(
      (response) => {
        this.stacks = response.records;
      });
  }

  loadFieldPerformance(lockScreen = true) {
    if (lockScreen) {
      this.overlay.lock();
    }
    this.growersService.harvestDetailsForYear(this.miscanthusHarvest.grower, this.miscanthusHarvest.year).subscribe(
      (response) => {
        this.harvestSummary = response;
        if (lockScreen) {
          this.overlay.unlock();
        }
      }
    );
  }

  markVerified() {
    const dialogRef = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent,
      {
        data: {
          title: 'Confirm verification',
          message: 'You are confirming that this stack declaration is correct. You can makes changes after verifying but '
                  + 'please be sure that details have been checked properly, as this flows through to our yield calculation system.'
        } as ConfirmDialogData
      });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.miscanthusHarvestsService.updateRecord({
          recordId: this.miscanthusHarvest.id,
          data: {
            ops_checked: true
          } as MiscanthusHarvest
        }).subscribe((result) => {
          this.miscanthusHarvest = result;
        });
      }
    });
  }
}
