import { Injectable, ErrorHandler} from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import {environment} from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() {
    Sentry.init({
      dsn: 'https://1e7a04fe559e4821a2a88d9ac874f592@o73135.ingest.sentry.io/5556534',
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['https://platform.terravesta.com'],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }

  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    Sentry.showReportDialog({
      eventId ,
      title: 'Oops, we are sorry something has gone wrong.',
      subtitle: 'We have been made aware of the issue.',
    });
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
