import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {DashboardWidgetDirective} from '../../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {WidgetDefinition} from '../../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';
import {EChartsOption} from 'echarts';
import {getHarvestYear} from '../../../../../../utils/common-functions';
import {TermContractsService} from '../../term-contracts.service';

@Component({
  selector: 'app-term-contract-hectarage-overview-widget',
  templateUrl: './term-contract-hectarage-overview-widget.component.html',
  styleUrls: ['./term-contract-hectarage-overview-widget.component.scss']
})
export class TermContractHectarageOverviewWidgetComponent extends DashboardWidgetDirective implements OnInit {


  hectarageChart: EChartsOption;
  startYear = getHarvestYear();
  endYear = getHarvestYear() + 10;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private termContractService: TermContractsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(elementRef, renderer);
  }

  ngOnInit(): void {
    this.updateGraph();
  }

  static widgetDefinition(): WidgetDefinition {
    return  {
      name: 'Term Contract Hectarage',
      componentClass: TermContractHectarageOverviewWidgetComponent,
      description: 'Chart to show contracted hectarage per year',
      code: 'TERM-CONTRACT-HECTARAGE-CHART',
      grouping: 'Operations',
      department: 'Operations',
      minColumnCount: 4,
      minRowCount: 2,
    };
  }

  updateGraph() {
    this.termContractService.yearlyStatistics(this.startYear, this.endYear).subscribe((response) => {
      const years = [];
      const areas = []
      const numOfContracts = [];
      response.forEach((item) => {
        years.push(item.year);
        areas.push(item.original_contracted_ha);
        numOfContracts.push(item.number_of_contracts);
      });

      this.hectarageChart = {
        xAxis: {
          type: 'category',
          data: years
        },
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value'
          }
        ],
        legend: {},
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name: 'Contracted hectares',
            data: areas,
            type: 'bar',
            yAxisIndex: 0,
          },
          {
            name: 'Number of Contracts',
            data: numOfContracts,
            type: 'bar',
            yAxisIndex: 1,
          }
        ]
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  processSettings(settings: any) {
  }

  endYearChanged($event: number) {
    if(this.endYear != $event) {
      this.endYear = $event;
      this.updateGraph();
    }
  }

  startYearChanged($event: number) {
    if(this.startYear != $event) {
      this.startYear = $event;
      this.updateGraph();
    }
  }
}
