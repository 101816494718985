import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {EstablishmentContract} from './establishment-contract.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContractsFormControlService} from '../contracts-form-control.service';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentContractFormControlService implements GaiaRecordFormControl<EstablishmentContract> {

  constructor(
    private formBuilder: FormBuilder,
    private contractsService: ContractsFormControlService
  ) {
  }

  toFormGroup(record: EstablishmentContract): FormGroup {
    const group =  this.formBuilder.group({
      price_per_hectare: new FormControl(record.price_per_hectare, Validators.required),
      own_use: new FormControl(record.own_use),
      planting_year: new FormControl(record.planting_year),
      planting_discount: new FormControl(record.planting_discount),
    });
    this.contractsService.addMiscanthusContractFields(group, record);
    return group;
  }
}
