import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {EventBookingFormComponent} from './event-booking-form/event-booking-form.component';
import { EventBookingShowComponent } from './event-booking-show/event-booking-show.component';
import {MatTabsModule} from '@angular/material/tabs';
import { EventBookingTableComponent } from './event-booking-table/event-booking-table.component';
import {MatSelectModule} from '@angular/material/select';
import {ContactsModule} from '../../contacts/contacts.module';
import { EventBookingEditDialogComponent } from './event-booking-edit-dialog/event-booking-edit-dialog.component';



@NgModule({
  declarations: [
    EventBookingFormComponent,
    EventBookingShowComponent,
    EventBookingTableComponent,
    EventBookingEditDialogComponent,
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatButtonModule,
        GaiaSharedModule,
        ReactiveFormsModule,
        MatIconModule,
        MatCardModule,
        GaiaGeneralModule,
        MatDialogModule,
        RouterModule,
        FlexModule,
        MatCheckboxModule,
        MatTabsModule,
        MatSelectModule,
        ContactsModule,
        ExtendedModule,
    ],
    exports: [
        EventBookingShowComponent,
        EventBookingTableComponent,
        EventBookingFormComponent,
    ],
})
export class EventBookingModule { }
