<mat-tab-group *ngIf="record">

  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>Processor Details</span>
    </ng-template>
    <app-show-record-wrapper *ngIf="record" [form]="form">
      <app-processor-form [form]="form"></app-processor-form>
    </app-show-record-wrapper>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>Delivery Months</span>
    </ng-template>
    <app-schedule-months-overview fxFlex="100%" [processor]="record"></app-schedule-months-overview>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>Delivery Sites</span>
    </ng-template>
    <app-processor-delivery-sites-table [processor]="record"></app-processor-delivery-sites-table>
  </mat-tab>
</mat-tab-group>


