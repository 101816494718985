import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';
import {Product, ProductPriceBook} from './product.model';
import {RailsRecordParams} from '@terravesta/phanes';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends RestApiService<Product>{

  constructor(httpClient: HttpClient) {
    super('/products', 'product', httpClient);
  }

  getPriceList(params: RailsRecordParams): Observable<ProductPriceBook[]>{
    return this.httpGet<ProductPriceBook[]>(this.generateUrl(params, '/price_list'));
  }
}
