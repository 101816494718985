import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../services/gaia-record-form-control';
import {InternalNote} from './internal-note.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InternalNoteFormControlService implements GaiaRecordFormControl<InternalNote> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: InternalNote): FormGroup {
    return this.formBuilder.group({
      date_noted: new FormControl(record.date_noted ?? new Date()),
      details: new FormControl(record.details, Validators.required),
      related_record_id: new FormControl(record.id, Validators.required)
    });
  }
}
