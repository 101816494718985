import {Component, Inject} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {MiscanthusFieldHarvest} from '../miscanthus-field-harvest.model';
import {MiscanthusFieldHarvestsService} from '../miscanthus-field-harvests.service';
import {MiscanthusFieldHarvestFormControlService} from '../miscanthus-field-harvest-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MiscanthusStack} from '../../miscanthus-stack.model';

@Component({
  selector: 'app-miscanthus-field-harvest-dialog',
  templateUrl: './miscanthus-field-harvest-dialog.component.html',
  styleUrls: ['./miscanthus-field-harvest-dialog.component.scss']
})
export class MiscanthusFieldHarvestDialogComponent extends FormDialogDirective<MiscanthusFieldHarvest> {

  constructor(
    miscanthusFieldHarvestService: MiscanthusFieldHarvestsService,
    miscanthusFieldHarvestFormControl: MiscanthusFieldHarvestFormControlService,
    matDialog: MatDialogRef<MiscanthusFieldHarvestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<MiscanthusFieldHarvest, MiscanthusStack>,
  ) {
    super(miscanthusFieldHarvestService, miscanthusFieldHarvestFormControl, matDialog, data);
  }

}
