import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-warning',
  templateUrl: './header-warning.component.html',
  styleUrls: ['./header-warning.component.scss']
})
export class HeaderWarningComponent implements OnInit {

  @Input()
  warningHeader: string;

  constructor() { }

  ngOnInit(): void {
  }

}
