import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {PriceGroupsService} from '../price-groups.service';
import {PriceGroup} from '../price-group.model';
import {FormGroup} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {Customer, CustomerType} from '../../orders/order.model';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-price-group-select',
  templateUrl: './price-group-select.component.html',
  styleUrls: ['./price-group-select.component.scss']
})
export class PriceGroupSelectComponent implements OnInit, AfterViewInit {

  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: string;

  @Input()
  record: Customer;

  @ViewChild('priceGroupSelector')
  matSelect: MatSelect;

  priceGroups: Array<PriceGroup> = new Array<PriceGroup>();

  constructor(
    private priceGroupsService: PriceGroupsService,
  ) { }

  ngOnInit(): void {
    const currentValue = this.formGroup.get(this.controlName).value;
    this.priceGroupsService.getRecords({pageNumber: 0, pageSize: 20, search: {active: true}}).pipe(
      delay(0) // This gets round the problem caused by changing form value as part of an async call
    ).subscribe(
      (response) => {
        this.priceGroups = response.records;
        if (this.record && currentValue === null) {
          this.setDefault();
        }
      }
    );
  }

  ngAfterViewInit() {
  }

  setDefault() {
    let priceGroup: PriceGroup;
    switch (this.record.type_enum) {
      case CustomerType.commercial_user:
        priceGroup = this.priceGroups.find((record) => record.default_group_commercial_user);
        break;
      case CustomerType.stockist:
        priceGroup = this.priceGroups.find((record) => record.default_group_stockist);
        break;
      case CustomerType.brand_ambassador:
        priceGroup = this.priceGroups.find((record) => record.default_group_brand_ambassador);
        break;
      default:
        priceGroup = this.priceGroups.find((record) => record.default_group_contact);
        break;
    }
    if (priceGroup) {
      const values = {};
      values[this.controlName] = priceGroup.id;
      this.formGroup.patchValue(values);
    }
  }

}
