<gaia-shared-standard-dialog [formGroup]="formGroup" dialogTitle="Change Stack Bale Type" i18n-dialogTitle="dialog lets user change bale type on stack">

  <div content>
    <ng-container [formGroup]="formGroup">
      <app-bale-type-select labelText="Bale Type" i18n-labelText formControlName="bale_type_id"></app-bale-type-select>
    </ng-container>
  </div>

  <div actions>
    <button mat-button (click)="changeBaleType()" [disabled]="!formGroup.valid">Change</button>
    <button mat-button mat-dialog-close>Close</button>
  </div>


</gaia-shared-standard-dialog>
