import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-long-table-wrapper',
  templateUrl: './long-table-wrapper.component.html',
  styleUrls: ['./long-table-wrapper.component.scss']
})
export class LongTableWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
