import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MiscanthusStack} from '../../miscanthus-stacks/miscanthus-stack.model';
import {PaginatedDataSource} from '@terravesta/phanes';
import {BaleCollection} from '../../bale-collections/bale-collection.model';
import {BaleCollectionsService} from '../../bale-collections/bale-collections.service';
import {AppRoutes} from '../../../app-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Haulier} from '../../hauliers/haulier.model';
import {Grower} from '../../../growing/growers/grower.model';
import {MonthYearSelection} from '../../../restful-calendar/calendar.model';
import {monthToTimeframeForSearch} from '../../../restful-calendar/date-functions';
import moment from 'moment';

@Component({
  selector: 'app-bale-collections-embed-table',
  templateUrl: './bale-collections-embed-table.component.html',
  styleUrls: ['./bale-collections-embed-table.component.scss']
})
export class BaleCollectionsEmbedTableComponent implements OnInit, OnChanges, AfterViewInit {

  displayedColumns = ['processor_ref', 'collection_date', 'bales_in_load', 'tonnage_accepted'];

  appRoutes = AppRoutes;
  operationsRoutes = OperationsRoutes;

  @Input()
  miscanthusStack: MiscanthusStack;

  @Input()
  haulier: Haulier;

  @Input()
  grower: Grower;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  collections: PaginatedDataSource<BaleCollection>;

  managementYear: number;
  managementMonth: number;

  constructor(
    private baleCollectionsService: BaleCollectionsService,
  ) { }

  ngOnInit(): void {
    this.managementYear = moment().year();
    this.managementMonth = moment().month() + 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.miscanthusStack){
      if (changes.miscanthusStack.currentValue) {
      }
    }
    if(changes.haulier) {
      if (changes.haulier.currentValue) {
        this.displayedColumns.push('haulier_invoice_reference');
      }
    }
    this.loadCollections();
  }

  ngAfterViewInit(): void {
    this.collections.paginator = this.paginator;
    this.collections.sort = this.sort;
  }

  loadCollections() {
    const searchParams = {};
    if(this.miscanthusStack) {
      searchParams['stack_collections'] = {
        uuid: this.miscanthusStack.id
      }
    }
    if(this.haulier) {
      searchParams['haulier'] = {
        uuid: this.haulier.id,
      }
    }

    this.collections = new PaginatedDataSource<BaleCollection>(
      (params) => {
        params.search = {
          ...params.search,
          ...searchParams
        }
        return this.baleCollectionsService.getRecords(params);
      }
    );
  }

  monthSelected(event: MonthYearSelection) {
    this.collections.search({
      collection_date: monthToTimeframeForSearch(event.month, event.year),
    });
  }
}
