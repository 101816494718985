import {ChangeDetectorRef, Component} from '@angular/core';
import {Product} from '../product.model';
import {ActivatedRoute} from '@angular/router';
import {ProductsService} from '../products.service';
import {ProductFormControlService} from '../product-form-control.service';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-product-show',
  templateUrl: './product-show.component.html',
  styleUrls: ['./product-show.component.scss']
})
export class ProductShowComponent extends ShowPageDirective<Product> {

  product: Product;

  constructor(
    productsService: ProductsService,
    productsFormControl: ProductFormControlService,
    route: ActivatedRoute,
    snackbar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(productsService, productsFormControl, route, snackbar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Product) {
    this.product = record;
  }
}
