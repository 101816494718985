import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {GaiaIconSet} from '../models/gaia-icon-set';
import {UniversallySearchableService} from './universally-searchable.service';
import {UniversalSearchData} from './universal-search.model';

@Directive()
export abstract class UniversalSearchPreviewDirective<T extends UniversalSearchData, R> implements OnChanges {

  gaiaIconSet = GaiaIconSet;

  previewData: R;

  @Input()
  record: T;

  @Output()
  requestClose: EventEmitter<null> = new EventEmitter<null>();

  protected constructor(
    private service: UniversallySearchableService<R>
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.record) {
      if(changes.record.currentValue) {
        this.service.universalSearchPreviewData(this.record.id).subscribe((response) => {
          this.previewData = response;
        })
      }
    }
  }


}
