<ng-container *ngIf="record">

  <app-show-page-header recordType="Haulier" i18n-recordType="Page title for haulier page"
                        [displayName]="record.account_name" [recordIcon]="gaiaIconSet.hauliers"></app-show-page-header>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Haulier Details</span>
      </ng-template>
      <app-show-record-wrapper [form]="form">
        <ng-container menu>
        </ng-container>
        ID: {{ record.id }} <br><br>
        <app-haulier-form [form]="form"></app-haulier-form>
      </app-show-record-wrapper>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Collections</span>
      </ng-template>
      <ng-template matTabContent>
        <app-bale-collections-embed-table [haulier]="record"></app-bale-collections-embed-table>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Drivers</span>
      </ng-template>
      <ng-template matTabContent>
        <app-haulier-drivers-embed-table [haulier]="record"></app-haulier-drivers-embed-table>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Vehicles</span>
      </ng-template>
      <ng-template matTabContent>
        <app-haulier-vehicles-embed-table [haulier]="record"></app-haulier-vehicles-embed-table>
      </ng-template>

    </mat-tab>
  </mat-tab-group>
</ng-container>
