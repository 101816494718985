import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {SessionDataService} from '../../../services/session-data.service';
import {DashboardContainerDirective} from '../dashboard-container.directive';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {AddWidgetDialogComponent} from '../add-widget-dialog/add-widget-dialog.component';
import {DashboardWidgetFactory, WidgetDefinition} from '../dashboard-widget-factory';
import {DashboardGridConfig, MyDashboardConfig, MyDashboardWidgetConfig} from '../../dashboard-config';
import {StaffMemberService} from '../../../system-admin/staff-member/staff-member.service';
import {StaffMember} from '../../../system-admin/staff-member/staff-member.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DashboardWidgetDirective} from '../../dashboard-shared/dashboard-widget.directive';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit, AfterViewInit {

  constructor(
    private sessionDataService: SessionDataService,
    private staffMemberService: StaffMemberService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  widgetId = 0;
  widgetIds = [];
  widgets: Array<ComponentRef<DashboardWidgetDirective>> = [];
  gaiaIconSet = GaiaIconSet;
  dashboardConfig: MyDashboardConfig;

  editMode = false;

  dashboardChanged = false;

  @ViewChild(DashboardContainerDirective, {static: true})
  container!: DashboardContainerDirective;

  @ViewChild('container', {static: true}) containerWrapper: ElementRef;

  ngOnInit(): void {
    this.dashboardConfig = this.sessionDataService.getUser().my_dashboard_config ?? { widgets: [], column_count: 8,
                                                                                row_count: 8 } as MyDashboardConfig;
    GaiaLogger.log(this.dashboardConfig);
    const observer = new ResizeObserver(entries => {
      this.containerChanged();
    });
    observer.observe(this.containerWrapper.nativeElement);
  }

  ngAfterViewInit() {
    this.drawDashboard();
    this.changeDetectorRef.detectChanges();
  }

  getGridConfig(): DashboardGridConfig {
    const gap = 10;
    const minRowHeight = 150;
    const natEl = this.containerWrapper.nativeElement;
    const width = natEl.offsetWidth;
    const widthWithoutGap = width - (this.dashboardConfig.column_count * gap);
    const cellWidth = (widthWithoutGap / this.dashboardConfig.column_count);
    let cellHeight;
    if (cellWidth > minRowHeight) {
      cellHeight = cellWidth;
    } else {
      cellHeight = minRowHeight;
    }
    const rect = natEl.getBoundingClientRect();
    return {cellWidth, cellHeight, gap, top: rect.top, left: rect.left};
  }

  drawDashboard(){
    const gridConfig = this.getGridConfig();
    this.updateBackgroundGrid(gridConfig);
    const viewContainerRef = this.container.viewContainerRef;
    viewContainerRef.clear();
    this.widgetIds = [];
    this.widgets = [];
    this.dashboardConfig.widgets.forEach((widget, index) => {
      const widgetCode = widget.widget_code;
      const widgetDefinition = DashboardWidgetFactory.getComponentByCode(widgetCode);
      const vcf = viewContainerRef.createComponent<DashboardWidgetDirective>(widgetDefinition.componentClass);
      const id = this.generateWidgetID();
      vcf.instance.widgetId = id;
      vcf.instance.setupWidget(widget, gridConfig);
      vcf.instance.dashboard = this;
      this.widgetIds.push(id);
      this.widgets.push(vcf);
      // vcf.location.nativeElement.style = `grid-row: span ${vcf.instance.gridHeight}; grid-column: span ${vcf.instance.gridWidth};`;
      vcf.instance.widgetDeleted.subscribe((widgetId) => {
        this.dashboardChanged = true;
        const configIndex = this.widgetIds.indexOf(widgetId);
        this.dashboardConfig.widgets.splice(configIndex, 1);
        this.widgetIds.splice(configIndex, 1);
        const widgetInstance = this.widgets.splice(configIndex, 1);
        widgetInstance[0].destroy();
        // TODO optimise this, redrawing is very heavy, maybe delete vcfs
        this.drawDashboard();
      });
    });
  }

  updateBackgroundGrid(gridConfig: DashboardGridConfig){
    const minHeight = (gridConfig.cellHeight * this.dashboardConfig.row_count) + (gridConfig.gap * this.dashboardConfig.row_count);
    this.renderer.setStyle(this.containerWrapper.nativeElement, 'min-height', `${minHeight}px`);
    this.renderer.setStyle(this.containerWrapper.nativeElement, 'background-size',
      `${gridConfig.cellWidth + gridConfig.gap}px ${gridConfig.cellHeight + gridConfig.gap}px`);
    this.renderer.setStyle(this.containerWrapper.nativeElement, 'background-image',
      `radial-gradient(2px 2px at 0px 0px, #212121 100%, transparent 0%), ` +
      `radial-gradient(2px 2px at ${gridConfig.cellWidth}px 0px, #212121 100%, transparent 0%), ` +
      `radial-gradient(2px 2px at 0px ${gridConfig.cellHeight}px, #212121 100%, transparent 0%), ` +
      `radial-gradient(2px 2px at ${gridConfig.cellWidth}px ${gridConfig.cellHeight}px, #212121 100%, transparent 0%)`);
  }

  generateWidgetID() {
    return this.widgetId++;
  }

  addComponent(widget: WidgetDefinition) {
    this.dashboardConfig.widgets.push({ widget_code: widget.code, x: 0, y: 0,
        height: widget.minRowCount, width: widget.minColumnCount} as MyDashboardWidgetConfig );
    this.drawDashboard();
  }

  addWidget() {
    const dialogRef = this.matDialog.open(AddWidgetDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addComponent(result);
        this.editBoardLayout();
      }
    });
  }

  saveDashboard() {
    // Update Session (might need to reload auth check?)
    const newConfig: MyDashboardConfig = {
      widgets: [],
      column_count: this.dashboardConfig.column_count,
      row_count: this.dashboardConfig.row_count
    };
    this.widgets.forEach((widget) => {
      newConfig.widgets.push(widget.instance.saveWidget());
    });
    this.dashboardConfig = newConfig;
    this.sessionDataService.getUser().my_dashboard_config = newConfig;
    this.staffMemberService.updateRecord({
      recordId: this.sessionDataService.getUser().id,
      data: {
        my_dashboard_config: newConfig,
      } as StaffMember
    }).subscribe((response) => {
      this.snackBar.open('Dashboard saved');
      this.dashboardChanged = false;
      this.editMode = false;
      this.drawDashboard();
    });
  }

  containerChanged() {
    const gridConfig = this.getGridConfig();
    this.updateBackgroundGrid(gridConfig);
    this.widgets.forEach((widget) => widget.instance.updateSize(gridConfig));
  }

  editBoardLayout() {
    const gridConfig = this.getGridConfig();
    this.updateBackgroundGrid(gridConfig);
    this.editMode = true;
    this.dashboardChanged = true;
    this.widgets.forEach((widget) => {
      widget.instance.editMode = this.editMode;
      widget.instance.dashboard = this;
    });
  }
}
