import { Injectable } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Stockist} from './stockist.model';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {AddressFormControlService} from '@terravesta/gaia-shared';

@Injectable({
  providedIn: 'root'
})
export class StockistFormControlService implements GaiaRecordFormControl<Stockist> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormBuilder: AddressFormControlService,
  ) { }

  toFormGroup(stockist: Stockist): FormGroup {
    return this.formBuilder.group({
      account_name: new FormControl(stockist.account_name, Validators.required),
      phone_number: new FormControl(stockist.phone_number),
      billing_email: new FormControl(stockist.billing_email, Validators.email),
      billing_address: this.addressFormBuilder.toFormControl(stockist.billing_address),
      shipping_address: this.addressFormBuilder.toFormControl(stockist.shipping_address),
      default_price_group_id: new FormControl(stockist.default_price_group ? stockist.default_price_group.id : null, Validators.required),
      on_account: new FormControl(stockist.on_account),
      payment_term_days: new FormControl(stockist.payment_term_days, Validators.pattern('^\\d+$')),
    });
  }

}

