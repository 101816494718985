import {Component, Inject, OnInit} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {StackStorageMethod} from '../stack-storage-method.model';
import {StackStorageMethodsService} from '../stack-storage-methods.service';
import {StackStorageMethodFormControlService} from '../stack-storage-method-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-stack-storage-method-form-dialog',
  templateUrl: './stack-storage-method-form-dialog.component.html',
  styleUrls: ['./stack-storage-method-form-dialog.component.scss']
})
export class StackStorageMethodFormDialogComponent extends FormDialogDirective<StackStorageMethod> {

  constructor(
    stackStorageMethodsService: StackStorageMethodsService,
    stackStorageMethodFormControl: StackStorageMethodFormControlService,
    matDialog: MatDialogRef<StackStorageMethodFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<StackStorageMethod>
  ) {
    super(stackStorageMethodsService, stackStorageMethodFormControl, matDialog, data);
  }


}
