import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractedFieldsTableComponent } from './contracted-fields-table/contracted-fields-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ContractedFieldsService} from './contracted-fields.service';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {ContractedFieldsRoutingModule} from './contracted-fields-routing.module';
import {ContractedFieldShowComponent} from './contracted-field-show/contracted-field-show.component';
import {ContractedFieldEmbedComponent } from './contracted-fields-embed/contracted-field-embed.component';
import {MatTabsModule} from '@angular/material/tabs';
import {PhanesModule, PhanesComponentsModule} from '@terravesta/phanes';
import {MatButtonModule} from '@angular/material/button';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import { ContractedFieldFormComponent } from './contracted-field-form/contracted-field-form.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MiscanthusVarietiesModule} from '../miscanthus-varieties/miscanthus-varieties.module';
import {GrowerContractsModule} from '../grower-contracts/grower-contracts.module';
import { ParcelFinderComponent } from './parcel-finder/parcel-finder.component';
import {MatListModule} from '@angular/material/list';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import { ContractedFieldNewDialogComponent } from './contracted-field-new-dialog/contracted-field-new-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ContractedFieldMapPanelComponent } from './contracted-field-map-panel/contracted-field-map-panel.component';
import {MatSelectModule} from '@angular/material/select';
import {DroneImagesModule} from '../drone-images/drone-images.module';
import {NgxEchartsModule} from 'ngx-echarts';
import { ContractedFieldFinderComponent } from './contracted-field-finder/contracted-field-finder.component';
import {SoilSamplesModule} from '../soil-samples/soil-samples.module';
import {ClimateGridUkModule} from '../../field-data/climate-grid-uk/climate-grid-uk.module';
import { PlantingByAreaComponent } from './planting-by-area/planting-by-area.component';
import { ContractedFieldStatsDashboardComponent } from './contracted-field-stats-dashboard/contracted-field-stats-dashboard.component';
import { ContractedFieldHarvestGraphComponent } from './contracted-field-harvest-graph/contracted-field-harvest-graph.component';
import { ContractedFieldMapFeatureWindowComponent } from './contracted-field-map-feature-window/contracted-field-map-feature-window.component';
import { ContractedFieldSentinelViewerComponent } from './contracted-field-sentinel-viewer/contracted-field-sentinel-viewer.component';
import {ContractorsModule} from '../../operations/contractors/contractors.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SentinelFunctionsModule} from '../../system-resources/sentinel/sentinel-functions/sentinel-functions.module';
import {MatSliderModule} from '@angular/material/slider';
import {RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ContractedFieldSentinelViewerDialogComponent} from './contracted-field-sentinel-viewer-dialog/contracted-field-sentinel-viewer-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ContractedFieldRemoveFromContractDialogComponent } from './contracted-field-remove-from-contract-dialog/contracted-field-remove-from-contract-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import { ContractedFieldContractPanelComponent } from './contracted-field-contract-panel/contracted-field-contract-panel.component';
import { ContractedFieldWarningsComponent } from './contracted-field-warnings/contracted-field-warnings.component';
import {MatSortModule} from '@angular/material/sort';
import { ContractedFieldBadgePanelComponent } from './contracted-field-badge-panel/contracted-field-badge-panel.component';
import { ContractedFieldsSimpleTableComponent } from './contracted-fields-simple-table/contracted-fields-simple-table.component';
import { ContractedFieldAddToContractDialogComponent } from './contracted-field-add-to-contract-dialog/contracted-field-add-to-contract-dialog.component';
import { ContractedFieldAddToEstablishmentContractDialogComponent } from './contracted-field-add-to-establishment-contract-dialog/contracted-field-add-to-establishment-contract-dialog.component';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {PlantingSummaryWidgetComponent} from './widget/planting-summary-widget/planting-summary-widget.component';
import {DashboardSharedModule} from '../../custom-dashboards/dashboard-shared/dashboard-shared.module';
import {SoilMeasurementsModule} from '../soil-measurements/soil-measurements.module';
import { ContractedFieldUniversalSearchResultComponent } from './contracted-field-universal-search-result/contracted-field-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../../universal-search/universal-search-layout.module';
import {EstablishmentContractsModule} from '../contracts/establishment-contracts/establishment-contracts.module';
import {TermContractsModule} from '../contracts/term-contracts/term-contracts.module';

@NgModule({
  declarations: [ContractedFieldsTableComponent, ContractedFieldShowComponent, ContractedFieldEmbedComponent,
    ContractedFieldFormComponent, ParcelFinderComponent, ContractedFieldNewDialogComponent,
    ContractedFieldMapPanelComponent, ContractedFieldFinderComponent, PlantingByAreaComponent,
    ContractedFieldMapFeatureWindowComponent, ContractedFieldSentinelViewerComponent,
    ContractedFieldStatsDashboardComponent, ContractedFieldHarvestGraphComponent,
    ContractedFieldSentinelViewerDialogComponent, ContractedFieldRemoveFromContractDialogComponent,
    ContractedFieldContractPanelComponent, ContractedFieldWarningsComponent, ContractedFieldBadgePanelComponent,
    ContractedFieldsSimpleTableComponent, ContractedFieldAddToContractDialogComponent,
    ContractedFieldAddToEstablishmentContractDialogComponent,
    PlantingSummaryWidgetComponent,
    ContractedFieldUniversalSearchResultComponent
  ],
    imports: [
        CommonModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        ContractedFieldsRoutingModule,
        GaiaGeneralModule,
        MatTabsModule,
        MatButtonModule,
        PhanesModule,
        PhanesComponentsModule,
        GaiaSharedModule,
        MatCardModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MiscanthusVarietiesModule,
        GrowerContractsModule,
        FormsModule,
        MatListModule,
        FlexModule,
        ExtendedModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSelectModule,
        DroneImagesModule,
        NgxEchartsModule,
        SoilSamplesModule,
        ClimateGridUkModule,
        ContractorsModule,
        MatDialogModule,
        MatDatepickerModule,
        SentinelFunctionsModule,
        MatSliderModule,
        RouterModule,
        MatTooltipModule,
        MatExpansionModule,
        MatMenuModule,
        MatSortModule,
        DashboardSharedModule,
        SoilMeasurementsModule,
        UniversalSearchLayoutModule,
        NgxMapboxGLModule,
        EstablishmentContractsModule,
        TermContractsModule,
    ],
  exports: [
    ContractedFieldsTableComponent,
    ContractedFieldEmbedComponent,
    ContractedFieldMapFeatureWindowComponent,
    ContractedFieldUniversalSearchResultComponent
  ],
  providers: [
    ContractedFieldsService
  ]
})
export class ContractedFieldsModule { }
