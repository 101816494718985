import {Component, Inject, OnInit} from '@angular/core';
import {ContactsService} from '../contacts.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactFormControlService} from '../contact-form-control.service';
import {FormGroup} from '@angular/forms';
import {Contact} from '../contact.model';
import {GaiaRecord} from '../../models/gaia-record.model';
import {RailsUploadParams} from '@terravesta/phanes';

@Component({
  selector: 'app-new-contact-for-account-dialog',
  templateUrl: './new-contact-for-account-dialog.component.html',
  styleUrls: ['./new-contact-for-account-dialog.component.scss']
})
export class NewContactForAccountDialogComponent implements OnInit {

  selectedContact: Contact;
  contactForm: FormGroup;
  account: GaiaRecord;

  constructor(
    private contactsService: ContactsService,
    private contactFormControlService: ContactFormControlService,
    private dialogRef: MatDialogRef<NewContactForAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: NewContactForAccountData,
  ) {
    this.account = data.account;
  }

  ngOnInit(): void {
  }

  existingContactSelected(contact: Contact) {
    this.selectedContact = contact;
    this.contactForm = this.contactFormControlService.toFormGroup(this.selectedContact);
  }

  newContact() {
    this.selectedContact = {} as Contact;
    this.contactForm = this.contactFormControlService.toFormGroup(this.selectedContact);
  }

  saveAndClose() {
    if (this.contactForm.valid) {
      let serviceMethodService;
      const params: RailsUploadParams<Contact> = {
        data: this.contactForm.value,
        additionalParams: {
          account_id: this.account.id,
        }
      };
      if (this.selectedContact.id) {
        params.recordId = this.selectedContact.id;
        serviceMethodService = this.contactsService.updateRecord(params);
      } else{
        serviceMethodService = this.contactsService.createRecord(params);
      }
      serviceMethodService.subscribe(
        (contact) => {
          this.dialogRef.close({ contact });
        },
        (error) => {

        });
    }
  }
}

export interface NewContactForAccountData {
  account: GaiaRecord;
}
