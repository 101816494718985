import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {GroupValueAccessorConnectorDirective} from '@terravesta/phanes';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Contact} from '../contact.model';

@Component({
  selector: 'app-contact-embed-form-simple',
  templateUrl: './contact-embed-form-simple.component.html',
  styleUrls: ['./contact-embed-form-simple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactEmbedFormSimpleComponent),
      multi: true
    }
  ]
})
export class ContactEmbedFormSimpleComponent extends GroupValueAccessorConnectorDirective implements OnInit {

  @Input()
  cardTitle: string;

  @Input()
  contact: Contact;

  constructor(
    inj: Injector
  ) {
    super(inj);
  }

  ngOnInit(): void {
  }

}
