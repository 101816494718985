<ng-container [formGroup]="control">
  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>contact_page</mat-icon>
      <mat-card-title i18n="contact details header">{{ cardTitle }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-contact-email-input formControlName="email" [contact]="contact"></app-contact-email-input>

      <gaia-shared-has-name-embed [formGroup]="control"></gaia-shared-has-name-embed>

      <div class="phone-numbers-row">

        <mat-form-field>
          <mat-label i18n>Phone</mat-label>
          <input matInput type="tel" formControlName="phone" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Mobile</mat-label>
          <input matInput type="tel" formControlName="mobile" />
        </mat-form-field>

      </div>

    </mat-card-content>
  </mat-card>

</ng-container>
