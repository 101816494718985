import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search-filter-button',
  templateUrl: './search-filter-button.component.html',
  styleUrls: ['./search-filter-button.component.scss']
})
export class SearchFilterButtonComponent implements OnInit {

  @Input()
  filterText: string;

  @Input()
  initialState = false;

  @Output()
  stateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  filterByMe = false;

  constructor() { }

  ngOnInit(): void {
    this.filterByMe = this.initialState;
  }

  clicked(){
    this.filterByMe = !this.filterByMe;
    this.stateChanged.emit(this.filterByMe);
  }

  getState(){
    return this.filterByMe;
  }

}
