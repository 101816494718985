import { Component} from '@angular/core';
import {UniversalSearchPreviewDirective} from '../../../universal-search/universal-search-preview.directive';
import {HauliersService} from '../hauliers.service';
import {AppRoutes} from '../../../app-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';
import {HaulierUniversalSearchPreview, HaulierUniversalSearchResult} from '../haulier.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-haulier-universal-search-result',
  templateUrl: './haulier-universal-search-result.component.html',
  styleUrls: ['./haulier-universal-search-result.component.scss']
})
export class HaulierUniversalSearchResultComponent extends UniversalSearchPreviewDirective<HaulierUniversalSearchResult, HaulierUniversalSearchPreview>  {

  constructor(
    private router: Router,
    haulierService: HauliersService,
  ) {
    super(haulierService);
  }

  showHaulier() {
    this.router.navigate(['/', AppRoutes.OPERATIONS, OperationsRoutes.HAULIERS, this.record.id]).then(() => {
      this.requestClose.emit();
    });
  }

}
