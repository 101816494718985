
<div class="container">

  <mat-card>
    <mat-card-header>
      <mat-card-title>Term Contracts</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="button-menu">
        <button mat-fab (click)="newTermContract()"><mat-icon>{{gaiaIconSet.add}}</mat-icon></button>
      </div>
      <mat-table [dataSource]="termContractsDataSource">

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef i18n="Contract Code">Contract Code</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.grower_contract_ref }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef i18n="Contract Status" class="w-75">Status</mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-75">
            {{ element.status | contractStatus }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="current_price">
          <mat-header-cell *matHeaderCellDef i18n="Contract current price">Current Price</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.current_price }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="area">
          <mat-header-cell *matHeaderCellDef i18n="Area">Area</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.intended_hectarage }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sustainability_check_list_signed">
          <mat-header-cell *matHeaderCellDef i18n="Area">Sustainability</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span style="font-weight: bold;" *ngIf="element.sustainability_check_list_signed; else not_signed">&#10003;</span>
            <ng-template #not_signed>
             <span style="font-weight: bold;">&#10007;</span>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="self_billing_signed">
          <mat-header-cell *matHeaderCellDef i18n="Area">Self Billing</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span style="font-weight: bold;" *ngIf="element.self_billing_signed; else not_signed">&#10003;</span>
            <ng-template #not_signed>
              <span style="font-weight: bold;">&#10007;</span>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="menu">
          <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="w-75" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Term Contract menu">
              <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.TERM_CONTRACTS, element.id]">
                <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
                <span i18n="edit option from menu">Edit</span>
              </a>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="termColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: termColumns"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Establishment Contracts</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="button-menu">
        <button mat-fab (click)="newEstablishmentContract()"><mat-icon>{{ gaiaIconSet.add }}</mat-icon></button>
      </div>
      <mat-table [dataSource]="establishmentContractsDataSource">

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef i18n="Contract Code">Contract Code</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.grower_contract_ref }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef i18n="Contract Status">Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="menu">
          <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
          <mat-cell class="w-75" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Term Contract menu">
              <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.ESTABLISHMENT_CONTRACTS, element.id]">
                <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
                <span i18n="edit option from menu">Edit</span>
              </a>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="establishmentColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: establishmentColumns"></mat-row>
      </mat-table>

    </mat-card-content>
  </mat-card>
</div>
