import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {Customer, CustomerType, Order, OrderSource, OrderStatus} from './order.model';
import {HttpClient} from '@angular/common/http';
import {RailsAPIWrapper, RailsGetRecordsParams, RailsRecordParams, RailsUploadParams} from '@terravesta/phanes';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class OrdersService extends RestApiService<Order> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/orders', 'order', httpClient);
  }

  static processEnum(order: Order) {
    if (order.customer) {
      order.customer.type_enum = CustomerType[order.customer.type];
    }
    order.order_source_enum = OrderSource[order.order_source];
    order.order_status_enum = OrderStatus[order.order_status];
  }

  getRecords(params: RailsGetRecordsParams): Observable<RailsAPIWrapper<Order>> {
    return super.getRecords(params).pipe(map((orders) => {
      orders.records.map((order) => OrdersService.processEnum(order));
      return orders;
    }));
  }

  getRecord(params: RailsRecordParams): Observable<Order> {
    return super.getRecord(params).pipe(map((order) => {
      OrdersService.processEnum(order);
      return order;
    }));
  }

  updateRecord(params: RailsUploadParams<Order>): Observable<Order> {
    return super.updateRecord(params).pipe(map((order) => {
      OrdersService.processEnum(order);
      return order;
    }));
  }

  customerSearch(searchTerm): Observable<Customer[]>{
    return this.httpGet<Customer[]>(this.generateUrl({} as RailsGetRecordsParams, '/search_customers'),
                                  { search: searchTerm }).pipe(map(this.processCustomerType));
  }

  sendInvoice(order: Order): Observable<Order> {
    return this.httpGet<Order>(this.generateUrl({recordId: order.id}, '/send_invoice'))
      .pipe(map((returnOrder) => {
        OrdersService.processEnum(returnOrder);
        return returnOrder;
    }));
  }

  downloadInvoice(order: Order, completed): Subscription {
    return this.httpClient.get(this.generateUrl({ recordId: order.id }, '/invoice'),
      {withCredentials: true, responseType: 'arraybuffer'})
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/pdf'});
          FileSaver.saveAs(file, `${order.order_number}_invoice.pdf`);
        },
        () => {},
        () => {
          completed();
        }
    );
  }

  private processCustomerType(res: Customer[]): Customer[]{
    res.forEach((customer) => {
      customer.type_enum = CustomerType[customer.type];
    });
    return res;
  }

  downloadDeliveryNote(order: Order, completed: () => void) {
    return this.httpClient.get(this.generateUrl({ recordId: order.id }, '/delivery_note'),
      {withCredentials: true, responseType: 'arraybuffer'})
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/pdf'});
          FileSaver.saveAs(file, `${order.order_number}_delivery_note.pdf`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }
}
