import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export class GrowingRoutes {
  public static DRONES = 'drones';
  public static GROWERS = 'growers';
  public static GROWER_CONTRACTS = 'grower-contracts';
  public static CONTRACTS = 'contracts';
  public static CONTRACTED_FIELDS = 'contracted-fields';
  public static MISCANTHUS_VARIETIES = 'miscanthus-varieties';
  public static SOIL_SAMPLES = 'soil-samples';
  public static PLANTING_OPPORTUNITIES = 'planting-opportunities';
}

const routes: Routes = [
  { path: GrowingRoutes.GROWERS,
    loadChildren: () => import('./growers/grower-routing.module').then(m => m.GrowerRoutingModule),
    // canActivate: [AuthGuard],
  },
  { path: GrowingRoutes.CONTRACTED_FIELDS,
    loadChildren: () => import('./contracted-fields/contracted-fields-routing.module').then(m => m.ContractedFieldsRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: GrowingRoutes.GROWER_CONTRACTS,
    loadChildren: () => import('./grower-contracts/grower-contracts-routing.module').then(m => m.GrowerContractsRoutingModule),
  },
  {
    path: GrowingRoutes.CONTRACTS,
    loadChildren: () => import('./contracts/contracts-routing.module').then(m => m.ContractsRoutingModule),
  },
  {
    path: GrowingRoutes.MISCANTHUS_VARIETIES,
    loadChildren: () => import('./miscanthus-varieties/miscanthus-varieties-routing.module').then(m => m.MiscanthusVarietiesRoutingModule),
  },
  {
    path: GrowingRoutes.SOIL_SAMPLES,
    loadChildren: () => import('./soil-samples/soil-samples-routing.module').then(m => m.SoilSamplesRoutingModule),
  },
  {
    path: GrowingRoutes.PLANTING_OPPORTUNITIES,
    loadChildren: () => import('./planting-opportunities/planting-opportunities-routing.module').then(
      m => m.PlantingOpportunitiesRoutingModule),
  },
  {
    path: GrowingRoutes.DRONES,
    loadChildren: () => import('./drone-images/drone-image-routing.module').then(m => m.DroneImageRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrowingRoutingModule{ }
