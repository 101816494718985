
<div fxLayout="column" fxLayoutGap="10px">
  <app-page-title [pageTitle]="pageTitle"></app-page-title>

  <ng-content select="[dashboard]"></ng-content>

  <div fxLayout="row" fxLayoutGap="20px">

    <div class="search-section" fxFlex="25%" *ngIf="!hideSearch" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-form-field class="simple-search">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput #searchBox type="text"
               class="search-box"
               [formControl]="searchControl"
               [placeholder]="searchHint" />
      </mat-form-field>
    </div>

    <div class="custom-section" fxFlex="50%" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
      <ng-content></ng-content>
    </div>

    <div class="button-section" fxFlex="25%" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
      <button mat-fab color="primary" type="button" aria-label="" class="add-new" (click)="addNewClicked.emit()" *ngIf="!hideAdd"
        title="Add New" i18n-title="tooltip on button to add new record">
        <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
      </button>
    </div>
  </div>
</div>
