import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProspectiveFieldsEmbedListComponent } from './prospective-fields-embed-list/prospective-fields-embed-list.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import {PhanesComponentsModule, What3WordsModule} from '@terravesta/phanes';
import { ProspectiveFieldShowComponent } from './prospective-field-show/prospective-field-show.component';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { ProspectiveFieldFormComponent } from './prospective-field-form/prospective-field-form.component';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {MatIconModule} from '@angular/material/icon';
import { ProspectiveFieldNewDialogComponent } from './prospective-field-new-dialog/prospective-field-new-dialog.component';
import { ConvertToContractedDialogComponent } from './convert-to-contracted-dialog/convert-to-contracted-dialog.component';
import {MiscanthusVarietiesModule} from '../../miscanthus-varieties/miscanthus-varieties.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatDialogModule} from '@angular/material/dialog';
import { ProspectiveFieldNewFarmSurveyDialogComponent } from './prospective-field-new-farm-survey-dialog/prospective-field-new-farm-survey-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PlantingOpportunitiesService} from '../planting-opportunities.service';
import { ProspectiveFieldMapPanelComponent } from './prospective-field-map-panel/prospective-field-map-panel.component';

@NgModule({
  declarations: [
    ProspectiveFieldsEmbedListComponent,
    ProspectiveFieldShowComponent,
    ProspectiveFieldFormComponent,
    ProspectiveFieldNewDialogComponent,
    ConvertToContractedDialogComponent,
    ProspectiveFieldNewFarmSurveyDialogComponent,
    ProspectiveFieldMapPanelComponent
  ],
  exports: [
    ProspectiveFieldsEmbedListComponent,
    ProspectiveFieldNewDialogComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    PhanesComponentsModule,
    MatButtonModule,
    RouterModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    What3WordsModule,
    FlexModule,
    GaiaGeneralModule,
    MatIconModule,
    MiscanthusVarietiesModule,
    GaiaSharedModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  providers: [PlantingOpportunitiesService]
})
export class ProspectiveFieldsModule { }
