import {GaiaRecord} from '../../models/gaia-record.model';
import {RequiresApproval} from '../team-space-concerns/requires-approval/requires-approval.model';
import {HasCurrency, RecordedMonthly, StaffClaim} from '../team-space-concerns/recorded-monthly/recorded-monthly.model';
import {StaffExpenseCategory} from '../staff-expense-categories/staff-expense-category.model';

export const STAFF_EXPENSE_CLAIM_CUT_OFF = 15; // Hard coded to match Gaia

export interface StaffExpenseClaim extends GaiaRecord, RequiresApproval,
                                            RecordedMonthly, HasCurrency, StaffClaim {
  details: string;
  claimed_amount: number;
  paid: boolean;
  paid_date: Date;
  unique_id: string;
  currency_display: string;

  category: StaffExpenseCategory;

}
