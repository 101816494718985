<mat-card *ngIf="formGroup">
  <mat-card-header>
    <mat-card-title> {{ miscanthusStack.address.post_code }} </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <gaia-shared-processing-overlay defaultMessage="Updating stack, please wait" i18n-defaultMessage="stack update message"></gaia-shared-processing-overlay>

    <div fxLayout="row">
      <div fxLayout="column" fxFlex="50%">
        <div><span i18n="total bale count for stack">Total Bale Count:</span> {{ miscanthusStack.declared_bale_count }}</div>
        <div><span i18n="total bale count for fields">Total Field Count:</span> {{ miscanthusStack.field_bale_count }}</div>
      </div>
      <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start end">
        <button mat-raised-button [disabled]="!formGroup.valid" (click)="updateFieldHarvests()"> Update Stack </button>
      </div>
    </div>

    <div [formGroup]="formGroup">
      <mat-table [dataSource]="fieldHarvests" formArrayName="field_harvests">

        <ng-container matColumnDef="field_name">
          <mat-header-cell *matHeaderCellDef i18n>Field Name</mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index">
            <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTED_FIELDS, element.contracted_field.id]" target="_blank">
              {{element.contracted_field.name}}
            </a>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="variety">
          <mat-header-cell *matHeaderCellDef i18n>Variety</mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index">
            {{element.contracted_field.planted_variety ? element.contracted_field.planted_variety.name : "" }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="year_planted">
          <mat-header-cell *matHeaderCellDef i18n>Year Planted</mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index"> {{element.contracted_field.year_planted}} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="planted_area">
          <mat-header-cell *matHeaderCellDef i18n>Planted Area</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.contracted_field.planted_area_ha}} </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bale_count">
          <mat-header-cell *matHeaderCellDef i18n>Bale Count</mat-header-cell>
          <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
            <input matInput formControlName="bale_count" type="number" />
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{currentBaleTotal}}</mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="fieldColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: fieldColumns"></mat-row>
        <mat-footer-row *matFooterRowDef="fieldColumns"></mat-footer-row>
      </mat-table>
    </div>
  </mat-card-content>
</mat-card>
