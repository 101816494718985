import { Component, OnInit } from '@angular/core';
import {StaffMemberService} from '../staff-member.service';
import {StaffMember} from '../staff-member.model';
import {PaginatedDataSource, PaginatedState} from '@terravesta/gaia-shared';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {SessionDataService} from '../../../services/session-data.service';
import {AppRoutes} from '../../../app-routing.module';
import {SystemAdminRoutes} from '../../system-admin-routing.module';
import {MatDialog} from '@angular/material/dialog';
import {
  StaffMemberContactQrDialogComponent
} from '../staff-member-contact-qr-dialog/staff-member-contact-qr-dialog.component';
import {StaffMemberDialogComponent} from "../staff-member-dialog/staff-member-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-staff-directory',
  templateUrl: './staff-directory.component.html',
  styleUrls: ['./staff-directory.component.scss']
})
export class StaffDirectoryComponent implements OnInit {

  currentStaffList: PaginatedDataSource<StaffMember>;
  displayedColumns = ['name', 'job_title', 'email', 'mobile', 'qr_code'];
  currentUser: StaffMember;

  gaiaIconSet = GaiaIconSet;

  appRoutes = AppRoutes;
  systemAdminRoutes = SystemAdminRoutes;

  constructor(
    private sessionDataService: SessionDataService,
    private staffMembersService: StaffMemberService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.currentStaffList = new PaginatedDataSource<StaffMember>(
      (params) => {
        return this.staffMembersService.getRecords(params);
      }
    );
    this.currentStaffList.loadRecordsPaginated(this.getPaginatedState());
    this.currentUser = this.sessionDataService.getUser();
    if (this.currentUser.has_hr_access) {
      this.displayedColumns.push('edit');
      this.displayedColumns.push('send_invite');
    }
  }

  getPaginatedState(): PaginatedState {
    return {
      pageSize: 100,
      pageNumber: 0,
      search: {
        employed: true,
      }
    };
  }

  openQRCode(element: StaffMember) {
    this.matDialog.open(StaffMemberContactQrDialogComponent, {
      data: { record: element}
    });
  }

  sendInvite(element: StaffMember) {
    this.staffMembersService.sendInvite(element).subscribe(()=>{
      this.showSnackbar('Invite sent successfully');
    });
  }

  showSnackbar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
    });
  }

  openDialog() {
    const dialogRef = this.matDialog.open(StaffMemberDialogComponent, {
      data: {record: this.currentUser },
      hasBackdrop: true,
    });
    dialogRef.afterClosed().subscribe(()=>{
      this.currentStaffList.loadRecordsPaginated(this.getPaginatedState());
    });
  }

}
