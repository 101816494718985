import { Component, OnInit } from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {AppRoutes} from '../../app-routing.module';
import {MarketingRoutes} from '../marketing-routing.module';

@Component({
  selector: 'app-marketing-menu',
  templateUrl: './marketing-menu.component.html',
  styleUrls: ['./marketing-menu.component.scss']
})
export class MarketingMenuComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;
  marketingRoutes = MarketingRoutes;

  constructor() { }

  ngOnInit(): void {
  }

}
