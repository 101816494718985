import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../services/rest-api.service';
import {ContactLogin} from './contact.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactLoginsService extends RestApiService<ContactLogin> {

  constructor(httpClient: HttpClient) {
    super('contact_logins', 'contact_login', httpClient);
  }

  unlockAccount(login: ContactLogin){
    return this.httpPost<{result: boolean}>(this.generateUrl({ recordId: login.id } , 'unlock_account'), null)
      .pipe(map((response) => {
        return response;
      }));
  }
}
