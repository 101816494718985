import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegacyHarvestReviewComponent } from './legacy-harvest-review/legacy-harvest-review.component';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {LegacyFieldHarvestsModule} from './legacy-field-harvests/legacy-field-harvests.module';



@NgModule({
  declarations: [LegacyHarvestReviewComponent],
  exports: [
    LegacyHarvestReviewComponent
  ],
    imports: [
      CommonModule,
      MatTableModule,
      MatCardModule,
      MatIconModule,
      MatButtonModule,
      FlexLayoutModule,
      ReactiveFormsModule,
      MatInputModule,
      RouterModule,
      LegacyFieldHarvestsModule
    ]
})
export class LegacyHarvestsModule { }
