import {Component, OnInit} from '@angular/core';
import {Customer, CustomerType} from '../order.model';
import {FormGroup} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {StockistFormControlService} from '../../stockists/stockist-form-control.service';
import {Stockist} from '../../stockists/stockist.model';
import {ContactFormControlService} from '../../../contacts/contact-form-control.service';
import {Contact} from '../../../contacts/contact.model';
import {ContactsService} from '../../../contacts/contacts.service';
import {StockistsService} from '../../stockists/stockists.service';
import {RestApiService} from '../../../services/rest-api.service';
import {MatDialogRef} from '@angular/material/dialog';
import {CommercialUser} from '../../commercial-users/commercial-user.model';
import {CommercialUserFormControlService} from '../../commercial-users/commercial-user-form-control.service';
import {BrandAmbassadorFormControlService} from '../../brand-ambassadors/brand-ambassador-form-control.service';
import {BrandAmbassadorsService} from '../../brand-ambassadors/brand-ambassadors.service';
import {CommercialUsersService} from '../../commercial-users/commercial-users.service';
import {CommercialCustomer} from '../../concerns';

@Component({
  selector: 'app-customer-new-dialog',
  templateUrl: './customer-new-dialog.component.html',
  styleUrls: ['./customer-new-dialog.component.scss']
})
export class CustomerNewDialogComponent implements OnInit {

  private defaultTitle = $localize`:@@selectCustomerType:Select Customer Type`;
  private stockistTitle = $localize`:@@newStockist:New Stockist`;
  private directTitle = $localize`:@@newCustomerDialog:New Customer`;
  private brandAmbassadorTitle = $localize`:@@newCustomerDialog:New Brand Ambassador`;
  private commercialUserTitle = $localize`:@@newCustomerDialog:New Commercial User`;

  customerTypes = CustomerType;
  selectedType: CustomerType;
  form: FormGroup;
  dialogTitle = this.defaultTitle;


  constructor(
    private stockistFormBuilder: StockistFormControlService,
    private contactFormBuilder: ContactFormControlService,
    private commercialUsersFormBuilder: CommercialUserFormControlService,
    private brandAmbassadorsFormBuilder: BrandAmbassadorFormControlService,
    private contactsService: ContactsService,
    private stockistsService: StockistsService,
    private brandAmbassadorsService: BrandAmbassadorsService,
    private commercialUsersService: CommercialUsersService,
    public matDialogRef: MatDialogRef<CustomerNewDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  typeSelected(event: MatSelectChange) {
    switch (this.selectedType){
      case CustomerType.stockist:
        this.dialogTitle = this.stockistTitle;
        this.form = this.stockistFormBuilder.toFormGroup({} as Stockist);
        break;
      case CustomerType.direct:
        this.dialogTitle = this.directTitle;
        this.form = this.contactFormBuilder.toFormGroup({} as Contact);
        break;
      case CustomerType.commercial_user:
        this.dialogTitle = this.commercialUserTitle;
        this.form = this.commercialUsersFormBuilder.toFormGroup({} as CommercialUser);
        break;
      case CustomerType.brand_ambassador:
        this.dialogTitle = this.brandAmbassadorTitle;
        this.form = this.brandAmbassadorsFormBuilder.toFormGroup({} as CommercialUser);
        break;
    }
  }

  getService(): RestApiService<Contact|CommercialCustomer> {
    switch (this.selectedType){
      case CustomerType.stockist:
        return this.stockistsService;
      case CustomerType.direct:
        return this.contactsService;
      case CustomerType.brand_ambassador:
        return this.brandAmbassadorsService;
      case CustomerType.commercial_user:
        return this.commercialUsersService;
    }
  }

  save() {
    if (this.form.valid) {
      const service = this.getService();
      service.createRecord({ data: this.form.value }).subscribe(
        (response) => {
          const customer = {
            type_enum: this.selectedType,
            record: response
          } as Customer;
          this.matDialogRef.close(customer);
        }
      );
    }

  }

  canSave(): boolean {
    return this.form && this.form.valid;
  }
}
