import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrdersService} from '../orders.service';
import {OrderFormControlService} from '../order-form-control.service';
import {CustomerType, getCustomerEmail, Order} from '../order.model';
import {MatDialog} from '@angular/material/dialog';
import {DateSelectorDialogComponent} from '../../../gaia-general/date-selector-dialog/date-selector-dialog.component';
import {PaymentDialogComponent} from '../payment-dialog/payment-dialog.component';
import {ShowRecordWrapperComponent} from '../../../gaia-general/show-record-wrapper/show-record-wrapper.component';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {ConfirmDialogComponent, SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';
import {Stockist} from '../../stockists/stockist.model';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';

@Component({
  selector: 'app-order-show',
  templateUrl: './order-show.component.html',
  styleUrls: ['./order-show.component.scss']
})
export class OrderShowComponent extends ShowPageDirective<Order> {

  order: Order;
  invoiceText: string;

  @ViewChild('invoiceDownload')
  invoiceDownload: SpinnerLoadingButtonComponent;

  @ViewChild('deliveryNoteDownload')
  deliveryNoteDownload: SpinnerLoadingButtonComponent;

  @ViewChild(ShowRecordWrapperComponent) loadingCover;

  constructor(
    ordersService: OrdersService,
    orderFormControl: OrderFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
  ) {
    super(ordersService, orderFormControl, route, snackBar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Order) {
    const invoice =  $localize`:@@invoice:Invoice`;
    const proforma =  $localize`:@@invoice:Proforma`;
    this.order = record;
    if (record.customer.type_enum === CustomerType.stockist) {
      const stockist = record.customer.record as Stockist;
      if (stockist.on_account) {
        this.invoiceText = invoice;
      }else{
        this.invoiceText = this.order.paid ? invoice : proforma;
      }
    }else{
      this.invoiceText = this.order.paid ? invoice : proforma;
    }
  }

  downloadInvoice() {
    (this.recordService as OrdersService).downloadInvoice(this.record, () => {
      this.invoiceDownload.processFinished();
    });
  }

  sendInvoice() {
    const email = getCustomerEmail(this.record.customer);
    const message = $localize`:@@confirmInvoiceSend:Confirming will lock the order, raise an invoice and send it to ${email}`;
    const dialogData = {
      title: $localize`:@@ConfirmInvoiceSendTitle:Confirm Invoice Send?`,
      message
    };
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult){
        this.doInvoiceSend();
      }
    });
  }

  private doInvoiceSend(){
    this.lockScreen($localize`:@@sendingInvoice:Sending ${this.invoiceText}`);
    (this.recordService as OrdersService).sendInvoice(this.record)
      .subscribe((order) => {
        this.updateRecord(order);
        this.snackBar.open(`${this.invoiceText} Sent`, 'Dismiss', { duration: 2000 });
      },
      () => { this.snackBar.open($localize`:@@invoiceSendError:Problem sending ${this.invoiceText}, please contact admin`, 'Dismiss'); },
      () => {
        this.unlockScreen();
      });
  }

  fulfill() {
    const dateDialog = this.matDialog.open(DateSelectorDialogComponent, {
    });
    dateDialog.afterClosed().subscribe((response) => {
      if (response.selectedDate) {
        this.doUpdate({shipped_on: response.selectedDate} as Order, 'Shipping Updated');
      }
    });
  }

  markAsPaid() {
    const paymentDialog = this.matDialog.open(PaymentDialogComponent, {
      width: '400px',
    });
    paymentDialog.afterClosed().subscribe((response) => {
      if (response.payment_method){
        this.doUpdate(response as Order, 'Payment Updated');
      }
    });
  }

  downloadDeliveryNote() {
    (this.recordService as OrdersService).downloadDeliveryNote(this.record, () => {
      this.deliveryNoteDownload.processFinished();
    });
  }
}
