import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {DialogPosition, MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {GaiaRecord} from '../../models/gaia-record.model';
import {InternalNote} from '../internal-note.model';
import {MatPaginator} from '@angular/material/paginator';
import {InternalNotesService} from '../internal-notes.service';
import {
  InternalNoteFormDialogComponent,
} from '../internal-note-form-dialog/internal-note-form-dialog.component';
import {PaginatedDataSource} from '@terravesta/phanes';
import {GaiaLogger} from '../../../utils/common-functions';

@Component({
  selector: 'app-internal-notes-popup',
  templateUrl: './internal-notes-popup.component.html',
  styleUrls: ['./internal-notes-popup.component.scss']
})
export class InternalNotesPopupComponent implements OnInit, AfterViewInit {

  notes: PaginatedDataSource<InternalNote>;
  noteWidth = 400;

  noteTitle = "Notes";

  @Output()
  notesUpdated = new EventEmitter();

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  displayedColumns = ['date_noted'];

  constructor(
    private matDialog: MatDialog,
    private notesService: InternalNotesService,
    private matDialogRef: MatDialogRef<InternalNotesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: InternalNotesPopupData,
  ) {
  }

  ngOnInit(): void {
    if (this.data.position) {
      let left = this.data.position.x + this.data.position.width - this.noteWidth;
      const top = this.data.position.y + this.data.position.height;
      if (left < 0) {
        left = 5; // rather than
      }
      const newPosition: DialogPosition = {
        top: `${top}px`,
        left: `${left}px`,
      };
      GaiaLogger.log(newPosition);
      this.matDialogRef.updatePosition(newPosition);
      this.matDialogRef.updateSize(`${this.noteWidth}px`, '50vh');
    }
    this.notes = new PaginatedDataSource<InternalNote>(
      (params) => {
        params.additionalParams = { record_id: this.data.record.id};
        return this.notesService.getRecords(params);
      });
    this.notes.loadRecords();
  }

  ngAfterViewInit(): void {
    this.notes.paginator = this.paginator;
  }

  createNew() {
    const dialogRef = this.matDialog.open(InternalNoteFormDialogComponent, {
      width: '60%',
      height: '60vh',
      data: { record: {} as InternalNote, relatedRecord: this.data.record},
    });
    dialogRef.afterClosed().subscribe(() => {
      this.notesUpdated.emit();
      this.reload();
    });
  }

  reload() {
    this.notes.loadRecords();
  }

}

export interface InternalNotesPopupData {
  record: GaiaRecord;
  position?: { x: number, y: number, width: number, height: number };
}
