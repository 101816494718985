import {Component} from '@angular/core';
import {ContactUniversalSearchPreview, ContactUniversalSearchResult} from '../contact.model';
import {UniversalSearchPreviewDirective} from '../../universal-search/universal-search-preview.directive';
import {ContactsService} from '../contacts.service';
import {AppRoutes} from '../../app-routing.module';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact-universal-search-result',
  templateUrl: './contact-universal-search-result.component.html',
  styleUrls: ['./contact-universal-search-result.component.scss']
})
export class ContactUniversalSearchResultComponent extends UniversalSearchPreviewDirective<ContactUniversalSearchResult, ContactUniversalSearchPreview>{

  constructor(
    private router: Router,
    contactsService: ContactsService,
  ) {
    super(contactsService);
  }

  showContact() {
    this.router.navigate(['/', AppRoutes.CONTACTS, this.record.id]).then(() => {
      this.requestClose.emit();
    });
  }
}
