<div class="growers">

  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Growers" i18n-pageTitle="Page title for growers page"
                    i18n-searchHint searchHint="Grower Code or Account Name">

    <app-grower-stats-dashboard dashboard></app-grower-stats-dashboard>

    <app-search-filter-button #hasWarnings filterText="Has Warnings" i18n-filterText=""
                              [initialState]="hasWarningsInitialState"
                              (stateChanged)="doSearch()"></app-search-filter-button>
    <app-search-filter-button #inContract filterText="In Contract" i18n-filterText=""
                              [initialState]="inContractInitialState"
                              (stateChanged)="doSearch()"></app-search-filter-button>
  </app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="grower_code">
      <mat-header-cell *matHeaderCellDef i18n="unique code for grower" mat-sort-header> Grower Code </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growerRoutes.GROWERS, element.id ]">{{element.grower_code}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_count">
      <mat-header-cell *matHeaderCellDef i18n="number of fields"> Field Count </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.field_count}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="replanting_count">
      <mat-header-cell *matHeaderCellDef i18n="number of fields"> FOC Replanting </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.replanting_count}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="in_contract">
      <mat-header-cell *matHeaderCellDef i18n> In Contract </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.in_contract">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="badges">
      <mat-header-cell *matHeaderCellDef i18n></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <app-grower-badge-panel [record]="element"></app-grower-badge-panel>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="account_name">
      <mat-header-cell *matHeaderCellDef i18n="column heading, shows account name in row"> Account Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.account_name}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageSize]="dataSource.pageSize"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
