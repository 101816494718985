import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../../services/rest-api.service';
import {BaleGain} from './bale-gain.model';

@Injectable({
  providedIn: 'root'
})
export class BaleGainsService extends RestApiService<BaleGain> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/miscanthus_stacks/{parentId}/bale_gains', 'bale_gain', httpClient);
  }
}
