import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {WidgetDefinition} from '../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';
import {DashboardWidgetDirective} from '../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {ContractedFieldsService} from '../../contracted-fields.service';
import {EChartsOption} from 'echarts';
import {PlantingOpportunitiesYearStats} from '../../../planting-opportunities/planting-opportunity.model';
import {FieldMapStats, MapStatus} from '../../contracted-field.model';
import {GaiaLogger} from '../../../../../utils/common-functions';

@Component({
  selector: 'app-planting-summary-widget',
  templateUrl: './planting-summary-widget.component.html',
  styleUrls: ['./planting-summary-widget.component.scss']
})
export class PlantingSummaryWidgetComponent extends DashboardWidgetDirective implements OnInit {

  currentYear = new Date().getFullYear();
  varietyPlanted: EChartsOption;

  mapStatuses = MapStatus;

  mappingStats: Array<FieldMapStats>;
  opsStats: PlantingOpportunitiesYearStats;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private contractedFieldsService: ContractedFieldsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(elementRef, renderer);
  }

  ngOnInit(): void {
  }

  static widgetDefinition(): WidgetDefinition {
    return  {
      name: 'Planting Summary',
      componentClass: PlantingSummaryWidgetComponent,
      description: 'Chart to current planting statistics',
      code: 'PLANTING_SUMMARY',
      grouping: 'Operations',
      department: 'Operations',
      minColumnCount: 4,
      minRowCount: 2,
    };
  }

  processSettings(settings: any) {
  }

  statsByMapStatus(mapStatus: MapStatus): number{
    if(this.mappingStats) {
      const result =  this.mappingStats.find((item) => item.status == mapStatus);
      return result ? result.count : 0;
    } else {
      return 0;
    }
  }

  updateGraph($event: number) {
    this.currentYear = $event;
    this.contractedFieldsService.yearlyPlantingStats(this.currentYear).subscribe(
      (response) => {
        this.drawVarietyGraph(response.contracted_field_stats);
        GaiaLogger.log(response);
        this.mappingStats = response.contracted_field_stats.mapping_stats;
        this.opsStats = response.planting_opportunity_stats;
        this.changeDetectorRef.detectChanges();
      }
    )
  }

  private drawVarietyGraph(fieldStats){
    const varieties = fieldStats.variety_area.map((item) => item.miscanthus_variety);
    const areas = fieldStats.variety_area.map((item) => parseFloat(item.planted_area_ha).toFixed(2));
    this.varietyPlanted = {
      title: {
        text: this.currentYear.toString(),
      },
      xAxis: {
        type: 'category',
        data: varieties
      },
      tooltip: {
        trigger: 'axis',
      },
      yAxis: [
        {
          type: 'value',
        },
      ],
      legend: {},
      series: {
        name: 'Area (ha)',
        data: areas,
        type: 'bar',
      }
    };
  }
}
