
<div class="badge-panel" fxLayout="row" fxLayoutGap="5px" [class.with-background]="drawBackground">
  <ng-container *ngIf="record">
    <!--
    <app-record-badge color="#ff0000" i18n-tooltip tooltip="There are X open tickets">
      <mat-icon>{{ gaiaIconSet.openTicket }}</mat-icon>
    </app-record-badge>
    -->
  </ng-container>
  <ng-content>
  </ng-content>
</div>
