import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpotContractsTableComponent } from './spot-contracts-table/spot-contracts-table.component';
import { SpotContractShowComponent } from './spot-contracts-show/spot-contract-show.component';
import { SpotContractNewDialogComponent } from './spot-contract-new-dialog/spot-contract-new-dialog.component';
import { SpotContractFormComponent } from './spot-contract-form/spot-contract-form.component';
import {ContractsSharedModule} from '../contracts-shared/contracts-shared.module';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [
    SpotContractsTableComponent,
    SpotContractShowComponent,
    SpotContractNewDialogComponent,
    SpotContractFormComponent
  ],
  imports: [
    CommonModule,
    ContractsSharedModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    GaiaGeneralModule,
    RouterModule,
    MatButtonModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule
  ]
})
export class SpotContractsModule { }
