import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {StaffExpenseCategory} from '../staff-expense-category.model';
import {StaffExpenseCategoriesService} from '../staff-expense-categories.service';

@Component({
  selector: 'app-staff-expense-category-select',
  templateUrl: './staff-expense-category-select.component.html',
  styleUrls: ['./staff-expense-category-select.component.scss']
})
export class StaffExpenseCategorySelectComponent implements OnInit {

  @Input()
  fieldName: string;

  @Input()
  form: FormGroup;

  categories = new Array<StaffExpenseCategory>();

  constructor(
    private staffExpenseCategoriesService: StaffExpenseCategoriesService,
  ) { }

  ngOnInit(): void {
    this.staffExpenseCategoriesService.getRecords({pageNumber: 0, pageSize: 30, search: { active: true }}).subscribe(
      (categoriesResults) => {
        this.categories = categoriesResults.records;
      }
    );
  }

}
