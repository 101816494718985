import {MatSnackBar} from '@angular/material/snack-bar';


export function showSomethingWentWrongSnackbar(snackBar: MatSnackBar) {
  snackBar.open($localize`:@@SomethingWrong:We are sorry, something went wrong, please try again later`,
    $localize`:@@Dismiss:Dismiss`);
}

export function copiedToClipboard(snackBar: MatSnackBar) {
  snackBar.open($localize`:@@CopiedToClipboard:Copied`,
    $localize`:@@Dismiss:Dismiss`);
}
