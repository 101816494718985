    <!--<div>
      <mat-card *ngIf="visible" id="infoWindow">
        <mat-card-header>
          <mat-card-title>Information</mat-card-title>
        </mat-card-header>
        <ng-container *ngIf="contractedField">
          <mat-card-content>
          <b>Name</b>
          <p class="card-title">{{contractedField.name}}
          </p>
          <b>Planted Area(ha)</b>
          <p class="card-title">{{contractedField.planted_area_ha}}
          </p>
          <b>Non Planted Area(ha)</b>
          <p class="card-title">{{contractedField.non_planted_area_ha}}
          </p>
          <b>Total Area(ha)</b>
          <p class="card-title">{{contractedField.total_field_area_ha}}
          </p>
          <b>Field ref. number</b>
          <p class="card-title">{{contractedField.field_reference_number}}</p>
          <b>Contracted</b>
          <p class="card-title">{{contractedField.contracted}}</p>
          <b>Year Planted</b>
          <p class="card-title">{{contractedField.year_planted}}</p>
          <p> <b>Grower - </b><a style="text-align:left" [routerLink]="['/',appRoutes.GROWING, growerRoutes.GROWERS, contractedField.grower.id]">{{contractedField.grower.grower_code}}</a>
          </p>
          <button *ngIf="showSentinel" mat-stroked-button style="color: #448ccb" (click)="openDialog($event)">Sentinel Viewer</button>
           &lt;!&ndash;<app-contracted-field-sentinel-viewer [contractedField]="contractedField">Open dialog
          </app-contracted-field-sentinel-viewer>&ndash;&gt;
          &lt;!&ndash;<b>Reason for removal</b>
          <p class="card-title">{{contractedField.reason_for_removal}}</p>&ndash;&gt;
        </mat-card-content>
        </ng-container>
        <mat-card-actions>
          <button id="infoClose" mat-raised-button color="primary" (click)="CloseInfo()">Close</button>
        </mat-card-actions>
      </mat-card>
    </div>-->
    <div *ngIf="visible">
      <mat-expansion-panel id="infoWindow" [expanded]="panelOpenState" *ngIf="contractedField">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span><b>Name - </b> {{contractedField.name}}</span>
            <span> <b>Grower - </b><a style="text-align:left" [routerLink]="['/',appRoutes.GROWING, growerRoutes.GROWERS, contractedField.grower.id]">{{contractedField.grower.grower_code}}</a>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p></p>
            <b>Planted Area(ha)</b>
            <p class="card-title">{{contractedField.planted_area_ha}}
            </p>
            <b>Non Planted Area(ha)</b>
            <p class="card-title">{{contractedField.non_planted_area_ha}}
            </p>
            <b>Total Area(ha)</b>
            <p class="card-title">{{contractedField.total_field_area_ha}}
            </p>
            <b>Field ref. number</b>
            <p class="card-title">{{contractedField.field_reference_number}}</p>
            <b>Contracted</b>
            <p class="card-title">{{contractedField.contracted}}</p>
            <b>Year Planted</b>
            <p class="card-title">{{contractedField.year_planted}}</p>
            <button *ngIf="showSentinel" mat-stroked-button style="color: #448ccb" (click)="openDialog($event)">Sentinel Viewer</button><!--
            <mat-action-row matPrefix>
              <button mat-button id="infoClose" mat-raised-button color="primary" (click)="CloseInfo()">Close</button>
            </mat-action-row>-->
            <mat-card-actions>
              <p></p>
              <button id="infoClose" mat-raised-button color="primary" (click)="CloseInfo()">Close</button>
            </mat-card-actions>
      </mat-expansion-panel>
    </div>
