<loading></loading>
<p>Asset Listing!</p>

<div class="download_asset_list_btn">
  <button mat-raised-button color="primary" (click)="downloadAssetList()">Download Asset List</button>
</div>

<!--Accordion markup code start-->

<mat-expansion-panel class="accordion_area" showToggle>
  <mat-expansion-panel-header>
    <mat-panel-title> Company Assets Status </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="assets_bord_and_charsets_area">
    <div class="left_content_area">
      <h2>All Assets</h2>
      <div class="asset_summery">
        <p><span>Total Assets:</span> <span>{{ assetsStats?.['total_assets'] }}</span></p>
        <p><span>Total Assets Value:</span> <span>{{ assetsStats?.['total_assets_value'] }}</span></p>

      </div>

<!--      <li *ngFor="let category of assetsStats?.['asset_categories'] ">-->
<!--        <p> {{ category.name }} - Qty: {{ category.quantity }} - Val: {{ category.value }} </p>-->
<!--      </li>-->

<!--      Assets Table start-->
      <table class="table" style="width:100%">
        <tr class="table_heading">
          <th class="col_head"></th>
          <th class="col_head tda">Qty:</th>
          <th class="col_head tda">Val:</th>
        </tr>
        <tr *ngFor="let category of assetsStats?.['asset_categories'] ">
          <td>{{ category.name }}</td>
          <td class="tda">{{ category.quantity }}</td>
          <td class="tda">{{ category.value }}</td>
        </tr>
      </table>
      <!--      Assets Table end-->

    </div>
    <div class="right_content_area">
      <!--echarsts - all assets quantity and values-->
      <div echarts [options]='option'></div>
    </div>
  </div>

  <!--echarsts - yearly category-wise quantity and values-->
  <form class="assets_popup_area" id="yearly_asset_filter" [formGroup]="yearlyAssetfilterForm">
    <mat-form-field class="input-full-width category_filter_wrapper" appearance="fill">
      <mat-label>Select Year For Graph View</mat-label>
      <mat-select placeholder="Select Year"
                  formControlName="year"
                  (selectionChange)="submitYearlyAssetfilterForm($event.value)"
      >
        <mat-option value="">Select Year</mat-option>
        <mat-option *ngFor="let year of years" [value]="year"> {{ year }}   </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="year_charsets_map" echarts [options]='optionsYearlyChart'></div>
  <!--END echarsts - yearly category-wise quantity and values-->
</mat-expansion-panel>
<!--Accordion markup code end-->

<div class="mat-elevation-z8">
<!--  <div>-->
<!--    <a mat-raised-button color="primary" (click)="openPopup('')">Create New</a>-->
<!--  </div>-->

<!--  THIS BUTTON WILL BE HERE-->
<!--  <button class="new-matrix" mat-flat-button color="accent" (click)="newMatrix()">-->
<!--    <mat-icon>{{ gaiaIconSet.add }}</mat-icon>-->
<!--    <span i18n>New Matrix</span>-->
<!--  </button>-->

<!--New added code by RY-->
<div class="top_area">
  <div class="left_filter_area">
    <mat-form-field class="filter_by_text">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>

    <form class="assets_popup_area" id="category_filter" [formGroup]="filterAssetForm">
      <mat-form-field class="input-full-width category_filter_wrapper" appearance="fill">
        <mat-label>Asset Category</mat-label>
        <mat-select placeholder="Select Category"
                    formControlName="category"
                    (selectionChange)="submitFilterAssetForm($event.value)"
        >
          <mat-option value="">All Category</mat-option>
          <mat-option *ngFor="let category of categories" [value]="category.id"> {{ category.name }}   </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <button mat-fab extended  mat-raised-button color="primary" (click)="openPopup('')">
    <mat-icon>add</mat-icon>
  </button>
</div>

  <table mat-table [dataSource]="finalData" matSort class="mat-elevation-z8 assets_table">
    <!--    name Column-->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> NAME </th>
      <td mat-cell *matCellDef="let row">
        <a mat-button color="accent" [routerLink]="['/assets', row.id]"> {{row.name}} </a>
      </td>
    </ng-container>

    <!--    terravesta_reference Column-->
    <ng-container matColumnDef="terravesta_reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> TV REFERENCE </th>
      <td mat-cell *matCellDef="let row"> {{row.terravesta_reference}} </td>
    </ng-container>

    <!--    category_name Column-->
    <ng-container matColumnDef="category_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> CATEGORY NAME </th>
      <td mat-cell *matCellDef="let row"> {{row.category_name}} </td>
    </ng-container>

    <!--    actions Column-->
    <ng-container matColumnDef="actions">
      <th class="table_action_head" mat-header-cell *matHeaderCellDef> ACTIONS </th>
      <td mat-cell *matCellDef="let row">
        <div>
          <a mat-stroked-button (click)="editAsset(row.id)">
            <mat-icon>edit</mat-icon>
          </a>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter {{ input.value }} </td>
    </tr>
  </table>

<!--  <mat-paginator [length]="paginatorData.total_count"-->
<!--                 [pageSize]="paginatorData.per_page"-->
<!--                 [pageSizeOptions]="[5, 10, 25, 100]"-->
<!--                 aria-label="Select page">-->
<!--  </mat-paginator>  -->

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"
                 [pageSize]="10"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</div>

<!--<div class="mat-elevation-z0">-->
<!--  <div>-->

<!--  </div>-->

<!--  <ul>-->
<!--    <li *ngFor="let asset of assets">{{ asset.name }}</li>-->
<!--  </ul>-->
<!--</div>-->
