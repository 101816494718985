import {Component, Input} from '@angular/core';
import {ContractedField} from '../contracted-field.model';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {yearArray} from "../../../../utils/common-functions";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-contracted-field-form',
  templateUrl: './contracted-field-form.component.html',
  styleUrls: ['./contracted-field-form.component.scss']
})
export class ContractedFieldFormComponent extends RecordFormDirective<ContractedField> {

  @Input()
  formGroup: FormGroup;

  years: number[] = yearArray(2016, new Date().getFullYear()).reverse();
  isReplantingField: boolean;
  yearSelection = new FormControl(new Date().getFullYear());
  contractedField: ContractedField;


  ngOnInit(): void {
    this.toggleReplanting(this.form.controls.replanting.value);
  }

  toggleReplanting(event: boolean) {
    this.isReplantingField = event;
  }

}
