import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {ProcessorDeliverySite} from './processor-delivery-site.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressFormControlService} from '@terravesta/gaia-shared';
import {What3WordsFormControlService} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class ProcessorDeliverySiteFormControlService implements GaiaRecordFormControl<ProcessorDeliverySite> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService,
    private what3wordsFormControl: What3WordsFormControlService,
  ) { }

  toFormGroup(record: ProcessorDeliverySite): FormGroup {
    return this.formBuilder.group({
      processor_id: new FormControl(record.processor ? record.processor.id : null, Validators.required),
      name: new FormControl(record.name, Validators.required),
      active: new FormControl(record.active),
      address: this.addressFormControl.toFormControl(record.address, true),
      what_3_words: this.what3wordsFormControl.toFormGroup(record),
    });
  }
}
