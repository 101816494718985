import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StaffMemberModule} from './staff-member/staff-member.module';
import { SystemAdminMenuComponent } from './system-admin-menu/system-admin-menu.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatInputModule} from "@angular/material/input";
import {AddressesModule, GaiaSharedModule} from "@terravesta/gaia-shared";
import {MatCardModule} from "@angular/material/card";
import {GaiaGeneralModule} from "../gaia-general/gaia-general.module";
import {MatDialogModule} from "@angular/material/dialog";
import {FlexModule} from "@angular/flex-layout";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {EventBookingModule} from "../marketing/event-bookings/event-booking.module";
import {What3WordsModule} from "@terravesta/phanes";
import {MatTabsModule} from "@angular/material/tabs";
import {MatDatepickerModule} from "@angular/material/datepicker";



@NgModule({
  declarations: [
    SystemAdminMenuComponent
  ],
  exports: [
    SystemAdminMenuComponent
  ],
  imports: [
    StaffMemberModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    GaiaSharedModule,
    ReactiveFormsModule,
    MatCardModule,
    GaiaGeneralModule,
    MatDialogModule,
    FlexModule,
    MatCheckboxModule,
    EventBookingModule,
    AddressesModule,
    What3WordsModule,
    MatTabsModule,
    MatDatepickerModule
  ]
})
export class SystemAdminModule { }
