import {Directive, EventEmitter, Input, Output} from '@angular/core';

@Directive()
export abstract class EmbedCardDirective<T> {

  @Input()
  record: T;

  @Input()
  canCancel = true;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  cancelClicked() {
    this.cancelled.emit();
  }

}
