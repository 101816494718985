import {Component, Inject, OnInit} from '@angular/core';
import {PaymentMethods} from '../order.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-payement-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {

  paymentMethods = PaymentMethods;
  paidOn: string;
  paymentMethodChosen: PaymentMethods;

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
  ) {
    this.dialogRef.disableClose = true;
  }


  ngOnInit(): void {
  }

  canComplete(){
    return !(this.paymentMethodChosen && this.paidOn);
  }

  markPaid() {
    this.dialogRef.close({
      payment_method: this.paymentMethodChosen,
      paid_on: this.paidOn
    });
  }
}
