import { Injectable } from '@angular/core';
import {StaffExpenseCategory} from './staff-expense-category.model';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class StaffExpenseCategoriesService extends RestApiService<StaffExpenseCategory> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/staff_expense_categories', 'staff_expense_category', httpClient);
  }
}
