import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StaffMemberShowComponent} from './staff-member-show/staff-member-show.component';
import {StaffMemberFormComponent} from './staff-member-form/staff-member-form.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AddressesModule, GaiaBehavioursModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {StaffAbsencesModule} from '../../team-space/staff-absences/staff-absences.module';
import {StaffMileageClaimsModule} from '../../team-space/staff-mileage-claims/staff-mileage-claims.module';
import {StaffExpenseClaimsModule} from '../../team-space/staff-expense-claims/staff-expense-claims.module';
import { StaffDirectoryComponent } from './staff-directory/staff-directory.component';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {ImagesModule} from '../../system-resources/images/images.module';
import { StaffMemberContactQrDialogComponent } from './staff-member-contact-qr-dialog/staff-member-contact-qr-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {CompanyOfficesModule} from '../../business-settings/company-offices/company-offices.module';
import { StaffMemberEmailSignatureComponent } from './staff-member-email-signature/staff-member-email-signature.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { HrSummaryComponent } from './hr-summary/hr-summary.component';
import { StaffMemberDialogComponent } from './staff-member-dialog/staff-member-dialog.component';
import {EventsModule} from "../../marketing/events/events.module";
import {ContactsModule} from "../../contacts/contacts.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [StaffMemberShowComponent, StaffMemberFormComponent, StaffDirectoryComponent,
    StaffMemberContactQrDialogComponent, StaffMemberEmailSignatureComponent, HrSummaryComponent, StaffMemberDialogComponent],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    ReactiveFormsModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    AddressesModule,
    GaiaBehavioursModule,
    MatProgressSpinnerModule,
    AddressesModule,
    MatButtonModule,
    MatTabsModule,
    StaffAbsencesModule,
    StaffMileageClaimsModule,
    StaffExpenseClaimsModule,
    MatTableModule,
    RouterModule,
    MatListModule,
    ImagesModule,
    GaiaSharedModule,
    MatDialogModule,
    CompanyOfficesModule,
    MatCheckboxModule,
    FormsModule,
    MatSelectModule,
    MatDatepickerModule,
    EventsModule,
    ContactsModule,
    MatTooltipModule,
  ]

})
export class StaffMemberModule { }
