<app-index-header [hideAdd]="true" (searchRequest)="doSearch($event)"
                  pageTitle="Stacks" i18n-pageTitle="page title for stacks page"
                  searchHint="By grower code" i18n-searchHint="">

  <div dashboard>
    <phanes-google-map [mapPoints]="stackPoints"></phanes-google-map>
  </div>

  <app-search-filter-toggle-button [filterOptions]="completedOptions" (filterChanged)="doSearch(null)"></app-search-filter-toggle-button>
  <mat-form-field>
    <mat-label i18n="Harvest Year Selector label">Harvest Year</mat-label>
    <mat-select (selectionChange)="yearChanged($event)" [formControl]="harvestYear">
      <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
    </mat-select>
  </mat-form-field>
</app-index-header>

<app-long-table-wrapper>
  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>
  <mat-table [dataSource]="dataSource" [ngClass.lt-md]="'mobile-table'">

    <ng-container matColumnDef="grower">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for grower">Grower</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for grower">Grower</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.grower ? element.grower.grower_code : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="harvest_year">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for harvest year">Harvest Year</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for harvest year">Year</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.harvest_year}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="post_code">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for post code">Post Code</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for post code">Postcode</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.address.post_code}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="bale_type">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for bale type">Bale Type</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for bale type">Bale Type</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.bale_type ? element.bale_type.name : ''}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="declared_bale_count">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for declared bale count">Declared Bale Count</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for declared bale count">Dec. Bales</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.declared_bale_count}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remaining_bale_count">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for remaining bale count">Remaining Bale Count</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for remaining bale count">Remain. Bales</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.remaining_bale_count}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="est_tonnage">
      <mat-header-cell *matHeaderCellDef>
        <span i18n="column heading for estimated tonnage">Est. Tonnage</span>
        <gaia-shared-cell-mobile-label i18n="mobile version column heading for estimated tonnage">Est. Tonnage</gaia-shared-cell-mobile-label>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.estimated_tonnage | number: '1.2'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="menu">
      <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="w-75" *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Stack menu">
          <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="[element.id]">
            <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
            <span i18n="edit option from menu">Edit</span>
          </a>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>
</app-long-table-wrapper>

<mat-paginator [length]="dataSource.totalRecords"
               [pageIndex]="dataSource.pageIndex"
               [pageSize]="dataSource.pageSize"
               [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
