import {RestApiService} from './rest-api.service';
import {PaginatedDataSource, PhanesNode} from '@terravesta/phanes';
import {Sort} from '@angular/material/sort';

export class GaiaIndexDataSource<T extends PhanesNode> extends PaginatedDataSource<T> {

  constructor(
    dataService: RestApiService<T>,
    defaultSort: Sort = null,
    pageSize = 30,
    pageIndex = 0,
  ) {
    super((params) => dataService.getRecords(params), pageSize, pageIndex, defaultSort);
  }
}

