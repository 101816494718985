import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ContactsService} from '../../../contacts/contacts.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {OrdersService} from '../orders.service';
import {Customer, CustomerType} from '../order.model';
import {MatDialog} from '@angular/material/dialog';
import {CustomerNewDialogComponent} from '../customer-new-dialog/customer-new-dialog.component';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {GaiaIconSet} from '../../../models/gaia-icon-set';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit, OnDestroy {

  gaiaIcons = GaiaIconSet;

  customerTypes = CustomerType;

  @Output()
  customerSelected: EventEmitter<Customer> = new EventEmitter<Customer>();

  private searchTerm: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  private searchDelay = 500;

  matches = new Array<Customer>();

  constructor(
    private ordersService: OrdersService,
    public matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscription = this.searchTerm.pipe(
      debounceTime(this.searchDelay)
    ).subscribe((searchTerm) => {
      if (searchTerm) {
        this.ordersService.customerSearch(searchTerm).subscribe(
          (response) => {
            this.matches = response;
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  doSearch(searchTerm: string){
    this.searchTerm.next(searchTerm);
  }

  selectedCustomer(event: MatAutocompleteSelectedEvent) {
    const selected = event.option.value;
    this.customerSelected.emit(selected);
  }

  createCustomer() {
    const dialogRef = this.matDialog.open(CustomerNewDialogComponent, {
      minWidth: '350px',
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.customerSelected.emit(result as Customer);
        }
      }
    );
  }
}
