import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaleGainDialogComponent } from './bale-gain-dialog/bale-gain-dialog.component';
import { BaleGainFormComponent } from './bale-gain-form/bale-gain-form.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BaleGainsEmbedTableComponent } from './bale-gains-embed-table/bale-gains-embed-table.component';
import {MatTableModule} from '@angular/material/table';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
    declarations: [BaleGainDialogComponent, BaleGainFormComponent, BaleGainsEmbedTableComponent],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    ReactiveFormsModule,
    PhanesComponentsModule,
    MatCardModule,
    MatFormFieldModule,
    FlexModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    ExtendedModule,
    GaiaSharedModule,
    MatIconModule,
    MatDatepickerModule,
    MatMenuModule,
    MatButtonModule
  ],
    exports: [
        BaleGainsEmbedTableComponent
    ]
})
export class BaleGainsModule { }
