import { Injectable } from '@angular/core';
import {RestApiService} from "../../services/rest-api.service";
import {Document} from "./document.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService extends RestApiService<Document>{

  constructor(
    httpClient: HttpClient,
  ) {
    super('/documents', 'document', httpClient);
  }

  newVersion(document: Document, file: File): Observable<Document> {
    const params = {
      new_version: file ,
    };
    return this.httpPost(this.generateUrl({recordId: document.id}, '/new_version'),
      this.paramsToFormData(params));
  }

  // Angular service method to get the document URL from the backend
  getDocumentUrl(document: Document): Observable<string> {
    return this.httpGet<Document>(
      this.generateUrl({ recordId: document.id }, '/get_download_url')
    ).pipe(
      map((response) => {
        return response.secure_url;
      })
    );
  }
}
