import { Injectable } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SpecificationCheck} from './specification-check.model';

@Injectable({
  providedIn: 'root'
})
export class SpecificationCheckFormControlService {

  constructor(private formBuilder: FormBuilder) { }

  toFormGroup(specifications: Array<SpecificationCheck>): FormArray {
    let groups = [];
    if (specifications) {
      groups = specifications.map((spec) => this.toRow(spec));
    }
    return this.formBuilder.array(groups);
  }

  toRow(specification: SpecificationCheck): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(specification.id),
      min_value: new FormControl(specification.min_value, Validators.required),
      max_value: new FormControl(specification.max_value, Validators.required),
      value_adjustment: new FormControl(specification.value_adjustment, Validators.required),
      value_adjustment_description: new FormControl(specification.value_adjustment_description, Validators.required),
      to_delete: new FormControl(false),
    });
  }
}
