import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventShowComponent } from './event-show/event-show.component';
import { EventsTableComponent } from './events-table/events-table.component';
import { EventFormComponent } from './event-form/event-form.component';
import { EventNewDialogComponent } from './event-new-dialog/event-new-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {EventsService} from './events.service';
import {RouterModule} from '@angular/router';
import {FlexModule} from '@angular/flex-layout';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {EventBookingModule} from '../event-bookings/event-booking.module';
import {What3WordsModule} from '@terravesta/phanes';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';



@NgModule({
  declarations: [
    EventShowComponent,
    EventsTableComponent,
    EventFormComponent,
    EventNewDialogComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    GaiaSharedModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    GaiaGeneralModule,
    MatDialogModule,
    RouterModule,
    FlexModule,
    MatCheckboxModule,
    EventBookingModule,
    AddressesModule,
    What3WordsModule,
    MatTabsModule,
    MatDatepickerModule
  ],
    exports: [
        EventShowComponent,
        EventsTableComponent,
        EventFormComponent,
    ],
  providers: [EventsService]
})
export class EventsModule { }
