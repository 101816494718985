import {Component, Input, OnInit} from '@angular/core';
import {Ticketable} from '../../tickets/ticket.model';
import {GaiaIconSet} from '../../models/gaia-icon-set';

@Component({
  selector: 'app-badge-panel',
  templateUrl: './badge-panel.component.html',
  styleUrls: ['./badge-panel.component.scss']
})
export class BadgePanelComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;

  @Input()
  drawBackground = false;

  @Input()
  record: Ticketable;

  constructor() { }

  ngOnInit(): void {
  }

}
