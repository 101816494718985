<mat-card *ngIf="note">
  <mat-card-header>
    <mat-icon mat-card-avatar>{{ gaiaIcons.note }}</mat-icon>
    <mat-card-title>{{ note.date_noted | date: 'short' }}</mat-card-title>
    <mat-card-subtitle>{{ note.noted_by.name }}</mat-card-subtitle>

  </mat-card-header>
  <mat-card-content>
    <ng-container>
      <phanes-editor-js-viewer *ngIf="jsNote else oldNote" [contentStr]="note.details"></phanes-editor-js-viewer>
      <ng-template #oldNote>
        <div [innerHTML]="note.details"></div>
      </ng-template>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <!--
    <ng-container *ngIf="hasMore">
      <button mat-icon-button *ngIf="!this.showMore else hide" (click)="toggleShow()"><mat-icon>{{gaiaIcons.readMore}}</mat-icon></button>
      <ng-template #hide>
        <button mat-button (click)="toggleShow()"><mat-icon>{{gaiaIcons.readLess}}</mat-icon></button>
      </ng-template>
    </ng-container>
    -->
    <ng-container *ngIf="note.documents && note.documents.length > 0">
      <h5><b>Attachments</b></h5>
      <div *ngFor="let document of note.documents" class="document-item">
        <span class="hyperlink" (click)="downloadAttachment(document)" matTooltip="Click to download the file">{{document.name}}.{{document.extension}}</span>
        <button mat-icon-button class="note-delete-btn" matTooltip="Delete file" (click)="deleteAttachment(document)"><b>x</b></button>
      </div>
<!--      The href="javascript:void(0);" is used to make the cursor change on hover, even though the href is not pointing to a real link.-->
    </ng-container>
    <div style="display: flex; align-items: center; justify-content: flex-start;">
      <button mat-icon-button class="note-edit-btn" (click)="editNote()"><mat-icon>{{gaiaIcons.edit}}</mat-icon></button>
      <button mat-icon-button class="note-pin-btn" (click)="pinNote()" [color]="note.pinned ? 'accent' : null">
        <mat-icon [class.pinned]="note.pinned">{{gaiaIcons.pin}}</mat-icon>
      </button>
      <button mat-icon-button class="note-delete-btn" (click)="deleteNote()">
        <mat-icon>{{gaiaIcons.delete}}</mat-icon>
      </button>
      <app-document-uploader-icon-button (emitUploadComplete)="uploadDocComplete()" [allowMultiple]="true" [record]="this.note" ></app-document-uploader-icon-button>
    </div>
  </mat-card-actions>
</mat-card>
