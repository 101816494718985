import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GaiaIndexDataSource} from '../../services/gaia-index-data-source';
import {MatPaginator} from '@angular/material/paginator';
import {ContractorsService} from '../contractors/contractors.service';
import {Contractor} from '../contractors/contractor.model';
import {InternalNoteTrackingService} from '../../internal-notes/internal-note-tracking.service';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {MiscanthusHarvest} from '../miscanthus-harvests/miscanthus-harvest.model';
import {MatSort} from '@angular/material/sort';
import {MiscanthusHarvestsService} from '../miscanthus-harvests/miscanthus-harvests.service';
import {GaiaLogger, getHarvestYear, yearArray} from '../../../utils/common-functions';

@Component({
  selector: 'app-harvest-reviewer',
  templateUrl: './harvest-reviewer.component.html',
  styleUrls: ['./harvest-reviewer.component.scss']
})
export class HarvestReviewerComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns = ['grower_code', 'bale_count', 'verified'];
  dataSource: GaiaIndexDataSource<MiscanthusHarvest>;
  gaiaIconSet = GaiaIconSet;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedYear: number = getHarvestYear();
  selectedHarvest: MiscanthusHarvest;
  incVerified: boolean;
  contractors: Array<Contractor> = new Array<Contractor>();

  growerSearch: string;
  contractorSearch: string;
  years = yearArray(2020).reverse();

  constructor(
    private miscanthusHarvestService: MiscanthusHarvestsService,
    private contractorsService: ContractorsService,
    private internalNotesTracking: InternalNoteTrackingService,
  ) { }

  ngOnDestroy(): void {
    this.internalNotesTracking.unsetRecord();
  }

  ngOnInit(): void {
    this.dataSource = new GaiaIndexDataSource<MiscanthusHarvest>(this.miscanthusHarvestService);
    this.dataSource.search(this.searchOpts());
    this.contractorsService.getRecords({pageSize: 100, pageNumber: 0, sort: 'account_name'}).subscribe(
      (results) => {
        this.contractors = results.records;
      }
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  yearSelected() {
    this.dataSource.search(this.searchOpts());
  }

  searchOpts(): any {
    const search = {
      year: this.selectedYear,
      ops_checked: false,
      grower: null,
      stack: null,
    };
    if (this.incVerified) {
      search.ops_checked = null;
    }
    if (this.growerSearch) {
      search.grower = {
        grower_code: `*${this.growerSearch}*`
      };
    }
    if (this.contractorSearch) {
      search.stack = {
        baled_by: {
          uuid: this.contractorSearch
        }
      };
    }
    return search;
  }

  selectHarvest(element: MiscanthusHarvest) {
    this.selectedHarvest = element;
    GaiaLogger.log(this.selectedHarvest);
  }

}
