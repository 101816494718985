<mat-table [dataSource]="vehicles">

  <ng-container matColumnDef="registration">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Registration</span>
      <gaia-shared-cell-mobile-label i18n>Registration</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.registration}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Vehicle Type</span>
      <gaia-shared-cell-mobile-label i18n>Type</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="bale_capacity">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Bale Capacity</span>
      <gaia-shared-cell-mobile-label i18n>Capacity</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.bale_capacity}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell class="w-75" *matHeaderCellDef>
    </mat-header-cell>
    <mat-cell class="w-75" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Contact settings menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editVehicle(element)">
          <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
          <span i18n>Edit</span>
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
