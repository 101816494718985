import {Component} from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {Grower} from '../grower.model';

@Component({
  selector: 'app-grower-form',
  templateUrl: './grower-form.component.html',
  styleUrls: ['./grower-form.component.scss']
})
export class GrowerFormComponent extends RecordFormDirective<Grower>{
  record: any;

  recordGot(record: Grower) {
    this.record = record;
  }
}

