import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {Processor} from './processor.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressFormControlService} from '@terravesta/gaia-shared';
import {
  SpecificationCheckFormControlService
} from '../../concerns/specification-check/specification-check-form-control.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessorFormControlService implements GaiaRecordFormControl<Processor> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService,
    private specificationFormControl: SpecificationCheckFormControlService,
  ) { }

  toFormGroup(record: Processor): FormGroup {
    return this.formBuilder.group({
      account_name: new FormControl(record.account_name, Validators.required),
      active: new FormControl(record.active),
      billing_address: this.addressFormControl.toFormControl(record.billing_address),
      moisture_specifications: this.specificationFormControl.toFormGroup(record.moisture_specifications),
      map_colour: new FormControl(record.map_colour)
    });
  }


}
