import { Component, OnInit } from '@angular/core';
import {AppRoutes} from '../../app-routing.module';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {BusinessSettingsRoutes} from '../business-settings-routing.module';

@Component({
  selector: 'app-business-settings-menu',
  templateUrl: './business-settings-menu.component.html',
  styleUrls: ['./business-settings-menu.component.scss']
})
export class BusinessSettingsMenuComponent implements OnInit {

  appRoutes = AppRoutes;
  businessSettingsRoutes = BusinessSettingsRoutes;
  gaiaIcons = GaiaIconSet;

  constructor() { }

  ngOnInit(): void {
  }

}
