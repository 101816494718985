import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent, ProcessingOverlayComponent} from '@terravesta/gaia-shared';
import {ErrorPanelComponent, PhanesNode} from '@terravesta/phanes';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-new-record-dialog',
  templateUrl: './new-record-dialog.component.html',
  styleUrls: ['./new-record-dialog.component.scss']
})
export class NewRecordDialogComponent implements OnInit, OnChanges {

  @Input()
  form: FormGroup;

  @Input()
  record: PhanesNode;

  @Input()
  dialogTitle = 'New Record';

  @Output()
  saveClicked = new EventEmitter();

  @Input()
  defaultMessage = $localize`:@@defaultNewRecordProcessingLockMessage:Saving...`;

  @Input()
  dialogRef: MatDialogRef<any>;

  @ViewChild(ProcessingOverlayComponent)
  overlay: ProcessingOverlayComponent;

  @ViewChild(ErrorPanelComponent)
  errorsPanel: ErrorPanelComponent;

  canSave = false;
  changed = false;
  formChanges: Subscription;

  constructor(
    private confirmMatDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form){
      if (changes.form.currentValue !== changes.form.previousValue){
        if (this.formChanges) { this.formChanges.unsubscribe(); }
        this.formChanges = this.form.statusChanges.subscribe(() => {
          this.canSave = this.form.dirty && this.form.valid;
          this.changed = this.form.touched;
          // GaiaLogger.log('form change detected', this.form.dirty, this.form.valid, this.form.touched);
        });
      }
    }
  }

  unlockOverlay() {
    this.overlay.unlock();
  }

  emitSave() {
    this.overlay.lock();
    this.saveClicked.emit();
  }

  checkClose() {
    if (this.changed) {
      const message = $localize`:@@confirmCancelCreate:Are you sure?`;
      const dialogData = {
        title: $localize`:@@ConfirmCancelTitle:Abandon Record Creation`,
        message
      };
      const confirmDialogRef = this.confirmMatDialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData
      });
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  showErrors(errors){
    this.errorsPanel.setErrors(errors);
  }
}


