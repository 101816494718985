import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContractedField} from '../../contracted-fields/contracted-field.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';

@Component({
  selector: 'app-drone-image-form',
  templateUrl: './drone-image-form.component.html',
  styleUrls: ['./drone-image-form.component.scss']
})
export class DroneImageFormComponent implements OnInit {

  gaiaIcons = GaiaIconSet;

  @Input()
  form: FormGroup;

  @Input()
  contractedField: ContractedField;

  constructor() { }

  ngOnInit(): void {
    this.setContractedField();
  }

  setContractedField(){
    if (this.contractedField){
      this.form.patchValue({contracted_field_id: this.contractedField.id });
    }
  }

}
