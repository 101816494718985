import { Injectable } from '@angular/core';
import {BaseApiService} from '@terravesta/phanes';
import {UniversalSearchResult} from './universal-search.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UniversalSearchService extends BaseApiService<UniversalSearchResult> {

  constructor(
    httpClient: HttpClient,
  ) {
    super(environment.server_url, 'search', httpClient);
  }

  universalSearch(term: string): Observable<Array<UniversalSearchResult>> {
    return this.httpGet(this.generateNonRecordUrl('universal_search'), {
      search_term: term
    });
  }

}
