import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ContractedField} from '../contracted-field.model';
import {PhanesNode} from '@terravesta/phanes';
import {ContractedFieldsService} from '../contracted-fields.service';
import {MatDialog} from '@angular/material/dialog';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {ContractedFieldSentinelViewerDialogComponent} from '../contracted-field-sentinel-viewer-dialog/contracted-field-sentinel-viewer-dialog.component';
import {DeviceType, LayoutTrackingService} from '../../../gaia-layout/layout-tracking.service';
@Component({
  selector: 'app-contracted-field-map-feature-window',
  templateUrl: './contracted-field-map-feature-window.component.html',
  styleUrls: ['./contracted-field-map-feature-window.component.scss']
})
export class ContractedFieldMapFeatureWindowComponent implements OnInit, OnChanges {
  appRoutes = AppRoutes;
  growerRoutes = GrowingRoutes;
  constructor(
    private contractedFieldService: ContractedFieldsService,
    public dialog: MatDialog,
    private cdf: ChangeDetectorRef,
    private layoutTracking: LayoutTrackingService,
  ) {
    this.layoutTracking.deviceType.subscribe((deviceType) => {
      this.isMobile = deviceType === DeviceType.MOBILE;
      this.panelOpenState = !this.isMobile;
      console.log(deviceType, this.panelOpenState);
    });
  }

  @Input() clickedContractedFieldRecord: PhanesNode;
  @Output() closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  contractedField: ContractedField;
  visible = true;
  showSentinel = false;
  panelOpenState = true;
  isMobile = false;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.visible = true;
    if (changes.clickedContractedFieldRecord.currentValue){
      this.getContractedFieldData();
    }
    if (this.isMobile) {

    }
  }
  getContractedFieldData(){
    this.showSentinel = false;
    this.contractedFieldService.getRecord({recordId: this.clickedContractedFieldRecord.id}).subscribe((contractedField) => {
      this.contractedField = contractedField;
      if (this.contractedField.geo_data.points.length > 0)
      {
        this.showSentinel = true;
      }
    });
  }
  CloseInfo() {
    this.visible = !this.visible;
    this.closeClicked.emit(true);
  }

  openDialog($event: MouseEvent) {
    /* this.cdf.detectChanges();*/
    const dialogRef = this.dialog.open(ContractedFieldSentinelViewerDialogComponent, {
      data: this.contractedField,
      hasBackdrop: true,
    });
  }
}
