<gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>
<app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                  [searchValue]="searchTerm"
                  pageTitle="Orders" i18n-pageTitle="orders page title"
                  i18n-searchHint searchHint="Email or Order Number">

  <app-search-filter-button #unshipped [filterText]="'Unshipped'" (stateChanged)="doSearch()"></app-search-filter-button>
  <app-search-filter-button #unpaid [filterText]="'Unpaid'" (stateChanged)="doSearch()"></app-search-filter-button>

</app-index-header>

<mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="order_number">
    <mat-header-cell *matHeaderCellDef i18n="order number">Order Number</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-button color="accent" [routerLink]="['/', appRoutes.COMMERCIAL_SALES, commercialSalesRoutes.ORDERS, element.id]">{{element.order_number}}</button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="ordered_date">
    <mat-header-cell *matHeaderCellDef i18n="date of order">Ordered Date</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.ordered_date | date }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total_order_cost">
    <mat-header-cell *matHeaderCellDef i18n="order total order cost">Total Order</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.total_order_cost | currency}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="order_source">
    <mat-header-cell *matHeaderCellDef i18n="order source">Source</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.order_source}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="order_status">
    <mat-header-cell *matHeaderCellDef i18n="order status">Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div [ngSwitch]="element.order_status_enum">
        <div *ngSwitchCase="orderStatus.invoice_not_raised" i18n>Invoice Not Raised</div>
        <div *ngSwitchCase="orderStatus.awaiting_payment" i18n>Awaiting Payment</div>
        <div *ngSwitchCase="orderStatus.completed" i18n>Completed</div>
        <div *ngSwitchCase="orderStatus.refunded" i18n>Refunded</div>
        <div *ngSwitchCase="orderStatus.needing_fulfillment" i18n>Awaiting Shipping</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef i18n="order customer email">Email</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="element.customer">
        {{ showEmail(element.customer) }}
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator [length]="dataSource.totalRecords"
               [pageIndex]="dataSource.pageIndex"
               [pageSize]="dataSource.pageSize"
               [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
