import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DroneImageViewerComponent } from './drone-image-viewer/drone-image-viewer.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import { DroneImageFormComponent } from './drone-image-form/drone-image-form.component';
import { DroneImageNewDialogComponent } from './drone-image-new-dialog/drone-image-new-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import { DroneImageFieldEmbedComponent } from './drone-image-field-embed/drone-image-field-embed.component';
import { DroneImageViewComponent } from './drone-image-view/drone-image-view.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import { DroneImagePrintComponent } from './drone-image-print/drone-image-print.component';
import {RouterModule} from '@angular/router';
import {MatSelectModule} from '@angular/material/select';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';

@NgModule({
    declarations: [DroneImageViewerComponent, DroneImageFormComponent, DroneImageNewDialogComponent,
        DroneImageFieldEmbedComponent, DroneImageViewComponent, DroneImagePrintComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    RouterModule,
    MatSelectModule,
    NgxMapboxGLModule,
  ],
    exports: [
        DroneImageFieldEmbedComponent
    ]
})
export class DroneImagesModule { }
