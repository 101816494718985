import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {HaulierDriver} from './haulier-driver.model';
import {ContactFormControlService} from '../../../contacts/contact-form-control.service';
import {Contact} from '../../../contacts/contact.model';

@Injectable({
  providedIn: 'root'
})
export class HaulierDriverFormControlService implements GaiaRecordFormControl<HaulierDriver> {

  constructor(
    private formBuilder: FormBuilder,
    private contactFormControlService: ContactFormControlService,
  ) { }

  toFormGroup(record: HaulierDriver): FormGroup {
    return this.formBuilder.group({
      active: new FormControl(record.active),
      collection_notice_sms: new FormControl(record.collection_notice_sms),
      collection_notice_email: new FormControl(record.collection_notice_email),
      contact: this.contactFormControlService.toSimpleForm(record.contact ?? {} as Contact),
    });
  }
}
