import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {AppRoutes} from '../../../../app-routing.module';
import {GrowingRoutes} from '../../../growing-routing.module';
import {ContractedField, FieldContractHistory} from '../../../contracted-fields/contracted-field.model';
import {ContractedFieldsService} from '../../../contracted-fields/contracted-fields.service';

@Component({
  selector: 'app-term-contracts-field-table-embed',
  templateUrl: './term-contracts-field-table-embed.component.html',
  styleUrls: ['./term-contracts-field-table-embed.component.scss']
})
export class TermContractsFieldTableEmbedComponent implements OnInit, OnChanges {

  gaiaIconSet = GaiaIconSet;
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  displayedColumns = ['added', 'removed', 'current'];

  histories: Array<FieldContractHistory>;

  @Input()
  contractedField: ContractedField;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contractedField) {
      this.histories = new Array<FieldContractHistory>();
      if (changes.contractedField.currentValue) {
        this.contractedFieldsService.allTermContracts(this.contractedField).subscribe((response) => {
          this.histories = response;
        });
      }
    }
  }

}
