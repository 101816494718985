
export async function extractKMLString(file: Blob): Promise<string> {
  return await file.text();
}

export function listPlacemarksInKMLString(kmlText: string) {
  const parser = new DOMParser();
  const kml = parser.parseFromString(kmlText, 'text/xml');

  const response = new Array<KmlPlacemark>();
  for (const item of kml.getElementsByTagName('Placemark') as any) {
    const placeMarkName = item.getElementsByTagName('name')[0].childNodes[0].nodeValue;
    response.push({
      name: placeMarkName
    });
  }
  return response;
}

export interface KmlPlacemark {
  name: string;
}
