import { Component } from '@angular/core';
import {UniversalSearchPreviewDirective} from '../../../universal-search/universal-search-preview.directive';
import {ContractorUniversalSearchPreview, ContractorUniversalSearchResult} from '../contractor.model';
import {AppRoutes} from '../../../app-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';
import {ContractorsService} from '../contractors.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contractor-universal-search-result',
  templateUrl: './contractor-universal-search-result.component.html',
  styleUrls: ['./contractor-universal-search-result.component.scss']
})
export class ContractorUniversalSearchResultComponent extends UniversalSearchPreviewDirective<ContractorUniversalSearchResult, ContractorUniversalSearchPreview> {

  constructor(
    private router: Router,
    contractorsService: ContractorsService
  ) {
    super(contractorsService);
  }

  showContractor() {
    this.router.navigate(['/', AppRoutes.OPERATIONS, OperationsRoutes.CONTRACTORS, this.record.id]).then(() => {
      this.requestClose.emit();
    });
  }

}
