import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractRpiAdjustmentsTableComponent } from './contract-rpi-adjustments-table/contract-rpi-adjustments-table.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import { ContractRpiAdjustmentFormDialogComponent } from './contract-rpi-adjustment-form-dialog/contract-rpi-adjustment-form-dialog.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import { ContractRpiAdjustmentProcessContractsDialogComponent } from './contract-rpi-adjustment-process-contracts-dialog/contract-rpi-adjustment-process-contracts-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';



@NgModule({
  declarations: [
    ContractRpiAdjustmentsTableComponent,
    ContractRpiAdjustmentFormDialogComponent,
    ContractRpiAdjustmentProcessContractsDialogComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    PhanesComponentsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSortModule
  ]
})
export class ContractRpiAdjustmentsModule { }
