<div>
  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <app-index-header (addNewClicked)="newRecord()"
                    (searchRequest)="doSearch($event)"
                    searchHint="Search by account name" i18n-searchHint=""
                    pageTitle="Hauliers" i18n-pageTitle="page title for hauliers page">

  </app-index-header>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="account_name">
      <mat-header-cell *matHeaderCellDef> Account Name </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="['/', appRoutes.OPERATIONS, operationsRoutes.HAULIERS, element.id ]">{{element.account_name}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.active">{{ gaiaIconSet.active }}</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="driver_count">
      <mat-header-cell *matHeaderCellDef> Driver Count </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.driver_count }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSize]="dataSource.pageSize"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
