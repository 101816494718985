<ng-container *ngIf="record">
  <div fxLayout="row" fxLayoutGap="2%">

    <phanes-google-map width="100%" height="250px" fxFlex="30%" [mapRecordsItems]="[mapItem]"></phanes-google-map>

    <mat-card fxFlex="30%">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{ gaiaIconSet.summary }}</mat-icon>
        <mat-card-title i18n>Summary</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <app-stats-number-line label="Harvested Bales" i18n-label [displayNumber]="record.field_bale_count"></app-stats-number-line>
        <app-stats-number-line label="Other Bales" i18n-label [displayNumber]="record.other_bale_count"></app-stats-number-line>
        <app-stats-number-line label="Bale Losses" i18n-label [displayNumber]="-record.bale_loss_count"></app-stats-number-line>
        <app-stats-number-line label="Available Bales" i18n-label [displayNumber]="record.declared_bale_count" [overLine]="true"></app-stats-number-line>

      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="30%">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{ gaiaIconSet.summary }}</mat-icon>
        <mat-card-title i18n>Collection Summary</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <app-stats-number-line label="Collected Bales" i18n-label [displayNumber]="record.collected_bale_count"></app-stats-number-line>
        <app-stats-number-line label="Avg. Bale Weight" i18n-label [displayNumber]="record.delivered_avg_bale_weight"></app-stats-number-line>
        <app-stats-number-line label="Uncollected Bales" i18n-label [displayNumber]="record.remaining_bale_count" [overLine]="true"></app-stats-number-line>

      </mat-card-content>
    </mat-card>

  </div>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Details</label>
      </ng-template>
      <ng-container *ngIf="form">
        <app-show-record-wrapper [form]="form">
          <ng-container menu>
            <button [disabled]="!record.completed" mat-raised-button color="primary" i18n="unlock stack for further editing" (click)="unlockStack()">Unlock Stack</button>
            <button [disabled]="record.completed" mat-raised-button color="primary" i18n="mark stack as completed" (click)="completedStack()">Mark Completed</button>
            <button [disabled]="!record.bale_type" mat-raised-button color="primary" i18n="change bale type on stack" (click)="changeBaleType()">Change Bale Type</button>
            <button mat-raised-button color="primary" i18n="refresh bale count on stack" (click)="refreshBaleCount()">Refresh Bale Count</button>
          </ng-container>

          <app-miscanthus-stack-form [form]="form" [record]="record" [grower]="record.grower"></app-miscanthus-stack-form>

        </app-show-record-wrapper>
      </ng-container>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Bale Details</label>
      </ng-template>
      <ng-template matTabContent>
        <app-button-row-container>
          <button mat-raised-button color="primary" i18n (click)="addFieldHarvest()">Add Bales (Field)</button>
          <button mat-raised-button color="primary" i18n (click)="addBaleGain()">Add Bales (Other)</button>
          <button mat-raised-button color="primary" i18n (click)="removeBales()">Remove Bales</button>
        </app-button-row-container>

        <div fxLayout="column" fxLayoutGap="15px">
          <mat-expansion-panel>
            <mat-expansion-panel-header i18n>From Field ({{record.field_bale_count}})</mat-expansion-panel-header>
            <app-miscanthus-field-harvest-embed-table [stack]="record" (changeMade)="baleCountChangeMade($event)"></app-miscanthus-field-harvest-embed-table>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header i18n>Other Sources ({{record.other_bale_count}})</mat-expansion-panel-header>
            <app-bale-gains-embed-table [stack]="record" (changeMade)="baleCountChangeMade($event)"></app-bale-gains-embed-table>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header i18n>Bale Losses ({{record.bale_loss_count}})</mat-expansion-panel-header>
            <app-bale-losses-embed-table [stack]="record" (changeMade)="baleCountChangeMade($event)"></app-bale-losses-embed-table>
          </mat-expansion-panel>
        </div>
      </ng-template>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Collections</label>
      </ng-template>

      <ng-template matTabContent>
        <app-bale-collections-embed-table [miscanthusStack]="record"></app-bale-collections-embed-table>
      </ng-template>

    </mat-tab>

  </mat-tab-group>

</ng-container>
