import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContractedFieldsTableComponent} from './contracted-fields-table/contracted-fields-table.component';
import {ContractedFieldShowComponent} from './contracted-field-show/contracted-field-show.component';
import {ContractedFieldFinderComponent} from './contracted-field-finder/contracted-field-finder.component';

export class ContractedFieldsRoutes {
  public static PARCEL_FINDER = 'parcel-finder';
}

const routes: Routes = [
  { path: '', component: ContractedFieldsTableComponent },
  { path: ContractedFieldsRoutes.PARCEL_FINDER, component: ContractedFieldFinderComponent },
  { path: ':id', component: ContractedFieldShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractedFieldsRoutingModule{ }
