import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import * as FileSaver from 'file-saver';
import {AgriculturalField} from './agricultural-field.model';
import {RestApiService} from '../services/rest-api.service';
import {GaiaRecord} from '../models/gaia-record.model';
import {RailsRecordParams} from '@terravesta/phanes';

export abstract class AgriculturalFieldsService<T extends AgriculturalField> extends RestApiService<T> {

  protected constructor(
    endPoint: string,
    modelName: string,
    httpClient: HttpClient
  ) {
    super(endPoint, modelName, httpClient);
  }

  downloadKML(field: T, completed, parent?: GaiaRecord): Subscription {
    const params = {
      recordId: field.id,
    } as RailsRecordParams;
    if (parent) {
      params.parentId = parent.id;
    }
    return this.httpGetBuffered(this.generateUrl(params, 'generate_kml'))
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/vnd.google-earth.kml+xml'});
          FileSaver.saveAs(file, `${field.name}_map.kml`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  uploadKML(field: T, kmlFile: File, placemarkName: string, parent?: GaiaRecord) {
    const params = {
      recordId: field.id,
    } as RailsRecordParams;
    if (parent) {
      params.parentId = parent.id;
    }
    const formData = new FormData();
    formData.append('kml_filepath', kmlFile);
    formData.append('placemark', placemarkName);
    return this.httpPost<{ result: boolean }>(this.generateUrl(params, 'process_kml'), formData);
  }
}
