<ng-container *ngIf="form" [formGroup]="form">

  <div class="form-wrapper">
    <app-miscanthus-contract-form [form]="form"></app-miscanthus-contract-form>

    <mat-card>
      <mat-card-header>
        <mat-card-title i18n="title for box containing the pricing details"> Contract Pricing </mat-card-title>
      </mat-card-header>

      <mat-card-content [formGroup]="form">

        <mat-form-field>
          <mat-label i18n="Base price for the contract, input label">Base Price</mat-label>
          <input matInput formControlName="base_price">
        </mat-form-field>

        <mat-slide-toggle formControlName="ex_farm_contract" i18n>Ex Farm Contract</mat-slide-toggle>
        <mat-slide-toggle formControlName="self_billing_signed" i18n>Self Billing</mat-slide-toggle>
<!--        <mat-slide-toggle formControlName="sustainability_report_signed" i18n>Sustainability Report</mat-slide-toggle>-->
        <mat-slide-toggle formControlName="sustainability_check_list_signed" i18n>Sustainability Checklist</mat-slide-toggle>



      </mat-card-content>
    </mat-card>
  </div>


</ng-container>
