import {Component, OnInit, ViewChild} from '@angular/core';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {Customer, getCustomerEmail, Order, OrderStatus} from '../order.model';
import {OrdersService} from '../orders.service';
import {OrderNewDialogComponent} from '../order-new-dialog/order-new-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SearchFilterButtonComponent} from '../../../gaia-general/search-filter-button/search-filter-button.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CommercialSalesRoutes} from '../../commercial-sales-routing.module';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss']
})
export class OrdersTableComponent extends IndexTableDirective<Order> implements OnInit {

  commercialSalesRoutes = CommercialSalesRoutes;
  displayedColumns = ['order_number', 'ordered_date', 'total_order_cost', 'order_source', 'order_status', 'email'];
  orderStatus = OrderStatus;
  searchTerm: string = null;

  @ViewChild('unshipped')
  unshippedFilter: SearchFilterButtonComponent;

  @ViewChild('unpaid')
  unpaidFilter: SearchFilterButtonComponent;

  constructor(
    ordersService: OrdersService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(ordersService, router, activatedRoute, matDialog, OrderNewDialogComponent,
      { active: 'record_number', direction: 'desc' }, ['order_number']);
  }

  showEmail(customer: Customer) {
    return getCustomerEmail(customer);
  }

  searchOpts(): any {
    let searchData: any;
    if (this.searchTerm) {
      const fuzzyTerm = `*${this.searchTerm}*`;
      searchData = {
        customer: {email: fuzzyTerm},
        search_mode: 'or',
      };
    }else{
      searchData = {};
    }
    if (this.unshippedFilter && this.unshippedFilter.getState()){
      searchData.unshipped = 1;
    }
    if (this.unpaidFilter && this.unpaidFilter.getState()){
      searchData.unpaid = 1;
    }
    return searchData;
  }

}
