
<mat-form-field>
  <mat-label i18n>Parcel Reference</mat-label>
  <input matInput [(ngModel)]="parcelReference" class="parcel-input">
</mat-form-field>
<button mat-raised-button color="primary" (click)="locateParcel()" i18n>Locate</button>

<div fxLayout="row wrap" fxLayoutGap="15px">
  <mat-card fxFlex="calc(50% - 15px)">
    <mat-card-header>
      <mat-card-title i18n>Location</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <mat-form-field>
        <mat-label i18n>Postcode</mat-label>
        <input matInput [readonly]="true" [value]="parcelInfo ? parcelInfo.post_code.postcode : null" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Distance from postcode (m)</mat-label>
        <input matInput [readonly]="true" [value]="parcelInfo ? parcelInfo.post_code.distance.toString() : null" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Latitude</mat-label>
        <input matInput [readonly]="true" [value]="parcelInfo ? parcelInfo.gps_data.latitude.toString() : null" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Longitude</mat-label>
        <input matInput [readonly]="true" [value]="parcelInfo ? parcelInfo.gps_data.longitude.toString() : null" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Northing</mat-label>
        <input matInput [readonly]="true" [value]="parcelInfo ? parcelInfo.gps_data.northing.toString() : null" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Easting</mat-label>
        <input matInput [readonly]="true" [value]="parcelInfo ? parcelInfo.gps_data.easting.toString() : null" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>What 3 Words</mat-label>
        <input matInput [readonly]="true" [value]=" w3words ? w3words.words: null" />
      </mat-form-field>
    </mat-card-content>

  </mat-card>

  <mat-card fxFlex="calc(50% - 15px)">
    <mat-card-header>
      <mat-card-title i18n>Map</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <div *ngIf="parcelInfo" fxLayout="column" fxLayoutGap="15px">
        <div>
          <a *ngIf="parcelInfo" mat-raised-button color="primary"
             href="https://www.google.com/maps/dir/?api=1&travelmode=driving&destination={{parcelInfo.gps_data.latitude}},{{parcelInfo.gps_data.longitude}}"
             target="_blank" i18n>Navigate</a>
        </div>
        <div fxLayout="row">
          <mat-form-field>
            <mat-label i18n>Within Miles</mat-label>
            <input matInput [(ngModel)]="miles" type="number" />
          </mat-form-field>
          <div>
            <button mat-raised-button color="primary" (click)="loadFields()" i18n>Show fields within</button>
          </div>
        </div>
      </div>
      <phanes-google-map width="100%" [mapPoints]="points" [mapRecordsItems]="fields" [preferPolygon]="true"></phanes-google-map>
    </mat-card-content>
  </mat-card>

  <mat-card fxFlex="100%" *ngIf="parcelInfo">
    <mat-card-header>
      <mat-card-title i18n>Map</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-climate-grid-graphs fxFlex="100%" [gpsPoint]="parcelInfo.gps_data"></app-climate-grid-graphs>
    </mat-card-content>
  </mat-card>
</div>
