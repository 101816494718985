<div *ngIf="form" [formGroup]="form">
  <mat-card fxFlex>
    <mat-card-header>
      <mat-card-title i18n>Process Information</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">

      <mat-form-field>
        <mat-label i18n>Sample ID</mat-label>
        <input matInput title="Will be set by server" i18n-title [value]="record.unique_id ? record.unique_id : null" [readonly]="true" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Owner</mat-label>
        <input matInput [value]="record.owner.name" [readonly]="true" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Field</mat-label>
        <input matInput [value]="record.field.name" [readonly]="true" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Date Taken</mat-label>
        <input matInput formControlName="date_taken" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>To be Processed By</mat-label>
        <input matInput formControlName="processed_by" />
      </mat-form-field>

    </mat-card-content>
  </mat-card>

</div>
