<mat-tab-group>

  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>Parcel Finder</span>
    </ng-template>
    <app-parcel-finder></app-parcel-finder>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>KML Query</span>
    </ng-template>

    <input #fileInput type="file" [hidden]="true" (change)="uploadMap(fileInput.files)" accept="application/vnd.google-earth.kml+xml" [multiple]="false" />
    <button (click)="fileInput.click()" mat-raised-button color="primary" i18n>Upload KML</button>


    <div fxLayout="column">

      <div *ngFor="let match of matches">
        {{match.placemark.name }} - {{ match.record.grower.grower_code}} - {{ match.record.name }} {{ match.record.processed_field_reference_number }}
      </div>

    </div>

  </mat-tab>
</mat-tab-group>
