import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessorsTableComponent } from './processors-table/processors-table.component';
import { ProcessorNewDialogComponent } from './processor-new-dialog/processor-new-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import { ProcessorShowComponent } from './processor-show/processor-show.component';
import {ProcessorsRoutingModule} from './processors-routing.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ReactiveFormsModule} from '@angular/forms';
import { ProcessorFormComponent } from './processor-form/processor-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ScheduleMonthsModule} from './schedule-months/schedule-months.module';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {ProcessorDeliverySitesModule} from './processor-delivery-sites/processor-delivery-sites.module';
import { ProcessorMapFeatureWindowComponent } from './processor-map-feature-window/processor-map-feature-window.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {SpecificationCheckModule} from '../../concerns/specification-check/specification-check.module';
import { ProcessorSelectComponent } from './processor-select/processor-select.component';
import {MatSelectModule} from '@angular/material/select';
import { ProcessorYearlyStatsComponent } from './processor-yearly-stats/processor-yearly-stats.component';
import { ProcessorUniversalSearchResultComponent } from './processor-universal-search-result/processor-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../../universal-search/universal-search-layout.module';
import {MatListModule} from '@angular/material/list';



@NgModule({
    declarations: [ProcessorsTableComponent, ProcessorNewDialogComponent, ProcessorShowComponent,
      ProcessorFormComponent, ProcessorMapFeatureWindowComponent, ProcessorSelectComponent, ProcessorYearlyStatsComponent, ProcessorUniversalSearchResultComponent],
  exports: [
    ProcessorMapFeatureWindowComponent,
    ProcessorSelectComponent,
    ProcessorYearlyStatsComponent,
    ProcessorUniversalSearchResultComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    ProcessorsRoutingModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatSlideToggleModule,
    AddressesModule,
    ScheduleMonthsModule,
    FlexModule,
    MatButtonModule,
    ProcessorDeliverySitesModule,
    MatExpansionModule,
    MatTabsModule,
    SpecificationCheckModule,
    MatSelectModule,
    UniversalSearchLayoutModule,
    MatListModule
  ]
})
export class ProcessorsModule { }
