import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {GaiaGeneralModule} from './gaia-general/gaia-general.module';
import {ClimateDataSourcesModule, PhanesComponentsModule, PhanesModule, PhanesSecurityModule} from '@terravesta/phanes';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {CommercialSalesModule} from './commercial-sales/commercial-sales.module';
import {ContactsModule} from './contacts/contacts.module';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {OperationsModule} from './operations/operations.module';
import {PlantingModule} from './planting/planting.module';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {GrowingModule} from './growing/growing.module';
import {TeamSpaceModule} from './team-space/team-space.module';
import {environment} from '../environments/environment';
import {NgxEchartsModule} from 'ngx-echarts';
import {InternalNotesModule} from './internal-notes/internal-notes.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import {getErrorHandler} from './services/sentry-error-handler';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from '@angular/material/dialog';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {GaiaLayoutModule} from './gaia-layout/gaia-layout.module';
import {SystemResourcesModule} from './system-resources/system-resources.module';
import {SystemAdminModule} from './system-admin/system-admin.module';

import {MatTooltipModule} from '@angular/material/tooltip';
import {DashboardModule} from './dashboard/dashboard.module';
import {KmlUtilsModule} from './kml-utils/kml-utils.module';
import {BusinessSettingsModule} from './business-settings/business-settings.module';
import {GaiaIconSet} from './models/gaia-icon-set';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS} from '@angular-material-components/color-picker';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {NgxMapboxGLModule} from 'ngx-mapbox-gl';
import {AssetsManagementModule} from "./assets-management/assets-management.module";
import {LoadingService} from "./loading/loading.service";

export function importEcharts(){
  return import('echarts');
}

registerLocaleData(localeEnGB, 'en-GB');


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GaiaLayoutModule,
    CommercialSalesModule,
    OperationsModule,
    BusinessSettingsModule,
    ContactsModule,
    PlantingModule,
    GrowingModule,
    SystemResourcesModule,
    SystemAdminModule,
    InternalNotesModule,
    TeamSpaceModule,
    KmlUtilsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'X-XSRF-Token',
      headerName: 'X-CSRF-Token',
    }),
    NgxMapboxGLModule.withConfig({
      // tslint:disable-next-line:max-line-length
      accessToken: 'pk.eyJ1IjoibWljaGFlbHN0ZXJyYXZlc3RhIiwiYSI6ImNsZW84cDRhMjAwMTAzdGxubWM4N2IyeWwifQ.Z8jiYryKMFE0JGtiEo7F6A',
    }),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSnackBarModule,
    GaiaGeneralModule,
    PhanesModule.forRoot({
      server_url: environment.server_url,
      icon_set: GaiaIconSet
    }),
    PhanesComponentsModule,
    ClimateDataSourcesModule.forRoot({
      icon_set: GaiaIconSet,
      server_url: environment.server_url
    }),
    GaiaSharedModule.forRoot({
      iconSet: GaiaIconSet,
    }),
    FlexLayoutModule,
    MatMomentDateModule,
    NgxMatMomentModule,
    PhanesSecurityModule.forRoot(environment),
    AddressesModule.forRoot(environment),
    NgxEchartsModule.forRoot({
      echarts: importEcharts,
    }),
    ClipboardModule,
    MatTooltipModule,
    DashboardModule,
    AssetsManagementModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP'},
    {provide: LOCALE_ID, useValue: 'en-GB' },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    DatePipe,
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500} },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        maxHeight: '90vh',
        maxWidth: '900px',
        width: '80%',
        minWidth: '320px',
      } as MatDialogConfig},
     { provide: Window, useValue: window },
    LoadingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
