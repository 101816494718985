
export enum RequiresApprovalStatuses  {
  not_submitted = 'not_submitted',
  awaiting_approval = 'awaiting_approval',
  approved = 'approved',
  rejected = 'rejected'
}

export interface RequiresApproval {
  current_status: string;
  current_status_enum: RequiresApprovalStatuses;
}

export function processRequiresApprovalEnum(record: RequiresApproval){
  record.current_status_enum = RequiresApprovalStatuses[record.current_status];
}
