import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export class ContractRoutes {
  public static ESTABLISHMENT_CONTRACTS = 'establishment_contracts';
  public static TERM_CONTRACTS = 'term_contracts';
  public static SPOT_CONTRACTS = 'spot_contracts';
  public static RPI_ADJUSTMENTS = 'rpi_adjustments';
}

const routes: Routes = [
  {
    path: ContractRoutes.ESTABLISHMENT_CONTRACTS,
    loadChildren: () => import('./establishment-contracts/establishment-contracts-routing.module')
      .then(m => m.EstablishmentContractsRoutingModule),
  },
  {
    path: ContractRoutes.TERM_CONTRACTS,
    loadChildren: () => import('./term-contracts/term-contracts-routing.module')
      .then(m => m.TermContractsRoutingModule),
  },
  {
    path: ContractRoutes.SPOT_CONTRACTS,
    loadChildren: () => import('./spot-contracts/spot-contracts-routing.module')
      .then(m => m.SpotContractsRoutingModule),
  },
  {
    path: ContractRoutes.RPI_ADJUSTMENTS,
    loadChildren: () => import('./contract-rpi-adjustments/contract-rpi-adjustments-routing.module')
      .then(m => m.ContractRpiAdjustmentRoutingModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractsRoutingModule { }
