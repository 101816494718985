import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SentinelFunctionsModule} from './sentinel-functions/sentinel-functions.module';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {GaiaSharedModule} from '@terravesta/gaia-shared';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SentinelFunctionsModule,
    MatTableModule,
    MatButtonModule,
    GaiaGeneralModule,
    MatPaginatorModule,
    MatIconModule,
    GaiaSharedModule
  ]
})
export class SentinelModule { }
