<ng-container *ngIf="!file else showFile">
  <div fxLayout="column">
    <div i18n>Choose file</div>
    <!-- <span>{{file ? file.name : 'or drag and drop file here' }}</span> -->
    <input class="file-input" type="file" [accept]="fileTypes">
  </div>
</ng-container>

<ng-template #showFile>

  <div class="image-container">
    <button mat-icon-button color="accent" (click)="unLinkFile()">
      <mat-icon>cancel</mat-icon>
    </button>
    <img [src]="url"  />
  </div>
</ng-template>

