
<div class="asset_img_entry">
  <h1>Asset Image Entry</h1>
</div>

<div class="asset_img_popup_area">
  <form [formGroup]="assetImageForm">
    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>ID</mat-label>
      <input formControlName="id" matInput>
    </mat-form-field>

    <mat-form-field class="input-full-width" appearance="fill">
      <mat-label>Image Description</mat-label>
      <input formControlName="description" matInput>
    </mat-form-field>

    <!--  <mat-form-field class="input-full-width" appearance="fill">-->
    <!--    <mat-label>Name</mat-label>-->
    <!--    <input formControlName="image" matInput>-->
    <!--  </mat-form-field>-->

    <!--  <mat-form-field appearance="fill">-->
    <!--    <mat-label>Image</mat-label>-->
    <!--    <input type="file">-->
    <!--  </mat-form-field>-->

    <div fxLayout="column">
      <div i18n>Choose file</div>
      <input type="file"
             (change)="onFileChange($event)"
      >
    </div>

    <div class="asset_img_popup_dialogbox">
      <button mat-raised-button  color="primary" (click)="saveAssetImage()"> Save </button>
      <a mat-button  class="cancel_btn" (click)="closePopup()"> Cancel </a>
    </div>
  </form>
</div>
