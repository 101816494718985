import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OverviewMapComponent} from './overview-map/overview-map.component';


export class DashboardRoutes {
  public static OVERVIEW_MAP = 'overview-map';
}

const routes: Routes = [
  {
    path: DashboardRoutes.OVERVIEW_MAP,
    component: OverviewMapComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
