import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Contact} from '../contact.model';
import {PostcodeIoServices} from '../postcode-io.services';
import {ContractedFieldsService} from '../../growing/contracted-fields/contracted-fields.service';
import {PhanesGoogleMapComponent, PhanesMapPoint, PhanesRecordMapItem} from '@terravesta/phanes';
import {ProcessorsService} from '../../operations/processors/processors.service';
import {PostCodeIoAddress} from '../address.model';
import {DistanceMatrix} from '../../operations/processors/processor.model';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {mapDataInfoWindowHtml} from '../../growing/contracted-fields/contracted-field-functions';

@Component({
  selector: 'app-lead-overview',
  templateUrl: './lead-overview.component.html',
  styleUrls: ['./lead-overview.component.scss']
})
export class LeadOverviewComponent implements OnInit, OnChanges {

  displayedColumns = ['name', 'kilometer', 'miles'];
  distanceMatrix: Array<DistanceMatrix>;
  currentAddress: PostCodeIoAddress;
  miles = 25;

  fieldRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  mapPoints: Array<PhanesMapPoint> = new Array<PhanesMapPoint>();

  private pointList: Array<PhanesMapPoint> = new Array<PhanesMapPoint>();

  @ViewChild(PhanesGoogleMapComponent)
  map: PhanesGoogleMapComponent;


  @Input()
  currentContact: Contact;

  gaiaIconSet = GaiaIconSet;

  constructor(
    private postcodeIoServices: PostcodeIoServices,
    private processorsService: ProcessorsService,
    private contractedFieldsService: ContractedFieldsService,
  ) {
  }

  ngOnInit(): void {
  }

  resetMap() {
    this.pointList = new Array<PhanesMapPoint>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentContact.currentValue){
      // Moved out of ngOnInit due to test issues
      this.resetMap();
      this.getProcessors();
      this.loadContactInformation();
    }
  }

  onClick(){
    this.miles = parseFloat((document.getElementById('radius') as HTMLInputElement).value);
    this.loadFields();
  }

  loadFields() {
    const  latitude  = this.currentContact.address.address_latitude;
    const  longitude  = this.currentContact.address.address_longitude;
    const address = {latitude, longitude};
    this.contractedFieldsService.withinMilesOf(address, this.miles).subscribe(
      (responseFields) => {
        this.fieldRecords = new Array<PhanesRecordMapItem>();
        responseFields.forEach((mapData) => {
          this.fieldRecords.push({
            record: mapData,
            infoWindowHtml: mapDataInfoWindowHtml(mapData),
            displayOptions: {
              markerStyle: {
                markerColor: mapData.color,
              },
              polygonStyle: {
                strokeColor: mapData.color,
                fillColor: mapData.color
              }
            }
          });
        });
      }
    );
  }

  loadContactInformation(){
    if (this.currentContact.address) {
      const address = this.currentContact.address;
     /* this.postcodeIoServices.getAddress(this.currentContact.address.post_code).subscribe((address) => {*/
        const content = `<strong style="font-size: 120%;">
          <a style="color: #000000;" target="_blank"</a>
        </strong>
        <br />
        <strong>County:</strong> ${address.county}
        <br />
        <strong>Post-code:</strong> ${address.post_code}
        <br />
        <strong>City:</strong> ${address.city}`;
        // {icon: customMapPin('#f28c07'), zIndex: 10}, {content});
        this.pointList.push({
          position: {
            latitude: this.currentContact.address.address_latitude,
            longitude: this.currentContact.address.address_longitude
          },
          style: {
            markerColor: '#f28c07',
          },
          infoWindowHtml: content,
          } as PhanesMapPoint);
        this.updateMap();
        this.processorsService.getDistmatrixList(address.post_code).subscribe(
          (distanceMatrix) => {
              this.distanceMatrix = distanceMatrix;
            });
       /* });*/
    }
  }

  getProcessors(){
    this.processorsService.getRecords({pageNumber: 0, pageSize: 100, search: {active: true} }).subscribe(
      (processorsList) => {
        processorsList.records.forEach((processor) => {
          this.postcodeIoServices.getAddress(processor.billing_address.post_code).subscribe((address) => {
            const content = `<strong style="font-size: 120%;">
            <a style="color: #000000;" target="_blank"</a>
            </strong>
            <br />
            <strong>Name:</strong> ${processor.account_name}
            <br />
            <strong>Post-code:</strong> ${processor.billing_address.post_code}
            <br />
            <strong>City:</strong> ${processor.billing_address.city}
            `;
            this.pointList.push({
              position: {
                latitude: address.result.latitude,
                longitude: address.result.longitude
              },
              infoWindowHtml: content,
              zIndex: 5,
              style: {
                markerUrl: '/assets/icons/processor.png'
              }
            });
            this.updateMap();
          });
        });
    });
  }

  reloadMap(){
    this.map.updateCenter();
  }

  updateMap() {
    // Temp fix to make the new map work
    this.mapPoints = this.pointList.slice();
  }

}
