import { Injectable } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SampleLocation} from './sample-location.model';
import {What3WordsFormControlService} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class HasSampleLocationsFormControlService {

  constructor(
    private formBuilder: FormBuilder,
    private what3WordsFormControlService: What3WordsFormControlService,
  ) { }

  sampleLocationsForm(sampleLocations: Array<SampleLocation>): FormArray {
    const controls = [];
    if (sampleLocations) {
      sampleLocations.forEach((location) => {
        controls.push(this.sampleLocationForm(location));
      });
    }
    return this.formBuilder.array(controls);
  }

  sampleLocationForm(sampleLocation: SampleLocation): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(sampleLocation.id),
      what_3_words: this.what3WordsFormControlService.toFormGroup(sampleLocation),
      delete: new FormControl(false),
    });
  }
}
