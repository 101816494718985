import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractsMenuComponent } from './contracts-menu/contracts-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {EstablishmentContractsModule} from './establishment-contracts/establishment-contracts.module';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';
import {TermContractsModule} from './term-contracts/term-contracts.module';
import { GrowerContractOverviewComponent } from './grower-contract-overview/grower-contract-overview.component';
import {MatTableModule} from '@angular/material/table';
import {SpotContractsModule} from './spot-contracts/spot-contracts.module';
import {ContractRpiAdjustmentsModule} from './contract-rpi-adjustments/contract-rpi-adjustments.module';
import {ContractsSharedModule} from './contracts-shared/contracts-shared.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";


@NgModule({
  declarations: [
    ContractsMenuComponent,
    GrowerContractOverviewComponent,
  ],
  exports: [
    ContractsMenuComponent,
    GrowerContractOverviewComponent,
  ],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        MatButtonModule,
        MatCardModule,
        FlexModule,
        EstablishmentContractsModule,
        TermContractsModule,
        SpotContractsModule,
        ContractRpiAdjustmentsModule,
        MatTableModule,
        ContractsSharedModule,
        FormsModule,
        MatSlideToggleModule,
        ReactiveFormsModule
    ]
})
export class ContractsModule { }
