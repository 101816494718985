import { Component, OnInit } from '@angular/core';
import {DashboardWidgetFactory} from '../dashboard-widget-factory';
import {FormControl, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-widget-dialog',
  templateUrl: './add-widget-dialog.component.html',
  styleUrls: ['./add-widget-dialog.component.scss']
})
export class AddWidgetDialogComponent implements OnInit {

  groupedByDepartmentWidgets = DashboardWidgetFactory.widgetsByDepartment();
  selectedWidget: FormControl = new FormControl(null, Validators.required);

  constructor(
    private matDialogRef: MatDialogRef<AddWidgetDialogComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  addWidget() {
    this.matDialogRef.close(this.selectedWidget.value);
  }
}
