import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import {FlexModule} from '@angular/flex-layout';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {GrowerProfileShowComponent} from './grower-profile-show/grower-profile-show.component';
import {GrowerProfileTableComponent} from './grower-profile-table/grower-profile-table.component';
import {GrowerProfileFormComponent} from './grower-profile-form/grower-profile-form.component';
import {GrowerProfileNewDialogComponent} from './grower-profile-new-dialog/grower-profile-new-dialog.component';
import {GrowerProfilesService} from './grower-profiles.service';
import {ImagesModule} from '../../system-resources/images/images.module';
import {GrowerSharedModule} from '../../growing/grower-shared-components/grower-shared.module';

@NgModule({
  declarations: [
    GrowerProfileShowComponent,
    GrowerProfileTableComponent,
    GrowerProfileFormComponent,
    GrowerProfileNewDialogComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    GaiaSharedModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    GaiaGeneralModule,
    MatDialogModule,
    RouterModule,
    FlexModule,
    MatCheckboxModule,
    ImagesModule,
    GrowerSharedModule,
  ],
  exports: [
    GrowerProfileShowComponent,
    GrowerProfileTableComponent,
  ],
  providers: [GrowerProfilesService]
})
export class GrowerProfilesModule { }
