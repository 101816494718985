import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GaiaIconSet} from '../../../models/gaia-icon-set';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;

  @Input()
  form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
