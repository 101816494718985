<a mat-flat-button aria-hidden="false" aria-label="Marketing" color="primary" (click)="marketingSalesMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.marketing }}</mat-icon>
  <div #marketingSalesMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="marketingSalesMenu"></div>
</a>
<mat-menu #marketingSalesMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.MARKETING, marketingRoutes.FEATURE_BANNERS]" i18n="feature banners menu link">
    <mat-icon>{{gaiaIcons.feature_banners}}</mat-icon>
    Banners
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.MARKETING, marketingRoutes.EVENT_CATEGORIES]" i18n="event categories menu link">
    <mat-icon>{{gaiaIcons.flag}}</mat-icon>
    Event Categories
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.MARKETING, marketingRoutes.EVENTS]" i18n="events menu link">
    <mat-icon>{{gaiaIcons.event}}</mat-icon>
    Events
  </a>
 <!-- <a mat-menu-item [routerLink]="[appRoutes.MARKETING, marketingRoutes.EVENT_BOOKINGS]" i18n="events menu link">
    <mat-icon>{{gaiaIcons.event}}</mat-icon>
    Event Booking
  </a>-->
  <a mat-menu-item [routerLink]="[appRoutes.MARKETING, marketingRoutes.WEB_ENQUIRIES]" i18n="events menu link">
   <mat-icon>{{gaiaIcons.resend_invite}}</mat-icon>
   Web Enquiries
 </a>
  <a mat-menu-item [routerLink]="[appRoutes.MARKETING, marketingRoutes.GROWER_PROFILES]" i18n="grower profile menu link">
    <mat-icon>{{gaiaIcons.growers}}</mat-icon>
    Grower Profiles
  </a>
</mat-menu>
