
<div class="header-section">
  <app-processor-yearly-stats></app-processor-yearly-stats>

  <div class="menu">
    <div class="year-selector">
      <app-year-selector [currentYear]="thisYear" [startYear]="2012" (yearChanged)="yearChanged($event)"></app-year-selector>
    </div>
    <div class="controls-wrapper">
      <div class="controls">
        <button mat-button mat-raised-button color="primary" (click)="downloadPowerStationStackReport()">
          Power Station Stack Report (*)
        </button>

        <a mat-raised-button color="primary" [routerLink]="['..']">
          Show List
        </a>

        <button mat-button mat-raised-button color="primary" (click)="downloadMileageReport()">
          Stack Mileage Report (*)
        </button>

      </div>
      <span>* <span i18n>This will take a long time to process</span></span>
    </div>
  </div>

</div>

<ng-container *ngIf="summary">
  <mat-expansion-panel class="month-view" *ngFor="let month of monthList" [expanded]="month.numeric === currentMonth" (afterExpand)="loadMonthData(month.numeric)">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ month.name }}</mat-panel-title>
      <mat-panel-description>
        <div class="stack-stats">
          <div><span i18n="label to show number of stacks that have no collections yet">Not Started</span> ({{ summary[month.numeric].not_stated }})</div>
          <div><span i18n="label to show number of stacks that have had some collections">Started</span> ({{ summary[month.numeric].part_completed }})</div>
          <div><span i18n="label to show number of stacks that have been completed">Completed</span> ({{ summary[month.numeric].completed }})</div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="month-timer">
      <span class="timer">Last Loaded: {{ getLastLoaded(month.numeric) }}</span>
      <button mat-mini-fab (click)="loadMonthData(month.numeric)">
        <mat-icon>{{ gaiaIconSet.refresh }}</mat-icon>
      </button>
    </div>
    <mat-table class="wide-table" [dataSource]="getStackListForMonth(month.numeric).stacks">

      <ng-container matColumnDef="menu">
        <mat-header-cell class="w-75" *matHeaderCellDef i18n="column heading for growers unique code">
        </mat-header-cell>
        <mat-cell class="w-75" *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Stack menu">
            <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item [routerLink]="['/', appRoutes.OPERATIONS, operationsRoutes.STACKS, element.id]">
              <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
              <span i18n="edit option from menu">Edit</span>
            </a>
            <button mat-menu-item (click)="refreshBaleCount(element, month)">
              <mat-icon>{{ gaiaIconSet.refresh }}</mat-icon>
              <span i18n="refresh bale counts menu button">Refresh Bale Count</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grower_code">
        <mat-header-cell class="smaller" *matHeaderCellDef i18n="column heading for growers unique code">
          Grower Code
        </mat-header-cell>
        <mat-cell class="smaller" *matCellDef="let element">
          {{ element.grower ? element.grower.grower_code : '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="grower_account_name">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for grower account name">
          Grower Name
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element">
          {{ element.grower ? element.grower.account_name : '' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="post_code">
        <mat-header-cell class="smaller" *matHeaderCellDef i18n="column heading for post code">
          Post Code
        </mat-header-cell>
        <mat-cell class="smaller" *matCellDef="let element"> {{element.address.post_code}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="stack_completed">
        <mat-header-cell class="smaller" *matHeaderCellDef i18n="column heading for stack completed check">
         Completed?
        </mat-header-cell>
        <mat-cell class="smaller" *matCellDef="let element">
          <mat-icon *ngIf="element.completed"> {{ gaiaIconSet.check }}</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="processor">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for processor">
          Processor
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element"> {{element.processor.account_name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="miles_to_processor">
        <mat-header-cell *matHeaderCellDef i18n="column heading for miles to processor">
          Miles to Processor
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.miles_to_selected_processor }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="collection_month">
        <mat-header-cell *matHeaderCellDef i18n="column heading for collection month">
          Collection Month
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.schedule_month.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contact_name">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for post code">
          Contact
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element">
          <app-contact-hover-popup [downloadFullRecord]="true" [contact]="element.stack_contact"></app-contact-hover-popup>
          {{ element.stack_contact?.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contact_phone">
        <mat-header-cell *matHeaderCellDef i18n="column heading for post code">
          Contact Phone
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.stack_contact?.mobile }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="bale_type">
        <mat-header-cell *matHeaderCellDef i18n="column heading for bale type">
          Bale Type
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.bale_type ? element.bale_type.name : ''}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="declared_bale_count">
        <mat-header-cell class="smaller" *matHeaderCellDef i18n="column heading for declared bale count">
          Declared Bale Count
        </mat-header-cell>
        <mat-cell class="smaller" *matCellDef="let element"> {{element.declared_bale_count}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="collected_bale_count">
        <mat-header-cell class="smaller" *matHeaderCellDef i18n="column heading for collected bale count">
          Collected Bale Count
        </mat-header-cell>
        <mat-cell class="smaller" *matCellDef="let element"> {{element.collected_bale_count}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="remaining_bale_count">
        <mat-header-cell class="smaller" *matHeaderCellDef i18n="column heading for remaining bale count">
          Remaining Bale Count
        </mat-header-cell>
        <mat-cell class="smaller" *matCellDef="let element"> {{element.remaining_bale_count}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="est_tonnage">
        <mat-header-cell *matHeaderCellDef i18n="column heading for estimated tonnage">
          Est. Tonnage
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.estimated_tonnage | number: '1.2'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="storage_method">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for type of storage">
          Storage Method
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element"> {{element.storage_method.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loader_availability">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for if loader is available">
          Loader
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element"> {{element.loader_availability.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="bale_grab_availability">
        <mat-header-cell *matHeaderCellDef i18n="column heading for type of bale grab that is available">
          Bale Grab
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.bale_grab_availability.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notice_period">
        <mat-header-cell *matHeaderCellDef i18n="column heading for notice period">
          Notice
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.notice_period.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location_description">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for location description">
          Location Description
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element">
          <app-cell-text-long [longText]="element.location_description"></app-cell-text-long>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="access_restrictions">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for access restrictions">
          Access Restrictions
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element">
          <app-cell-text-long [longText]="element.access_restrictions"></app-cell-text-long>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes">
        <mat-header-cell class="longer" *matHeaderCellDef i18n="column heading for notes">
          Notes
        </mat-header-cell>
        <mat-cell class="longer" *matCellDef="let element">
          <app-cell-text-long [longText]="element.notes"></app-cell-text-long>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="notes_system">
        <mat-header-cell *matHeaderCellDef class="w-75">
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="w-75">
          <app-internal-notes-embed-tool [record]="element"></app-internal-notes-embed-tool>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"
               (contextmenu)="openMenu(row, $event)"
               [class.stack-completed]="row.stat_completed_collections"
               [class.stack-part-collected]="row.stat_part_collected"
               [class.stack-not-started]="row.stat_no_collections"
      ></mat-row>

    </mat-table>
  </mat-expansion-panel>
</ng-container>

<mat-menu #collectionMenu="matMenu">
  <a mat-menu-item [routerLink]="destination" target="_blank">
    <mat-icon>{{ gaiaIconSet.edit }}</mat-icon>
    <span i18n="edit menu button">Edit</span>
  </a>
</mat-menu>
<div style="visibility: hidden; position: fixed;"
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="collectionMenu"></div>
