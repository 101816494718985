import { Component } from '@angular/core';
import {NewDialogDirective} from '../../../components/new-dialog.directive';
import {Grower} from '../grower.model';
import {GrowersService} from '../growers.service';
import {GrowerFormControlService} from '../grower-form-control.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';

@Component({
  selector: 'app-grower-new-dialog',
  templateUrl: './grower-new-dialog.component.html',
  styleUrls: ['./grower-new-dialog.component.scss']
})
export class GrowerNewDialogComponent extends NewDialogDirective<Grower> {

  constructor(
    growersService: GrowersService,
    growerFormControl: GrowerFormControlService,
    private router: Router,
    public dialogRef: MatDialogRef<GrowerNewDialogComponent>
  ) {
    super(growersService, growerFormControl);
  }

  successfulSave(result: Grower) {
    this.router.navigate([AppRoutes.GROWING, GrowingRoutes.GROWERS, result.id]).then((x) => {
      this.dialogRef.close();
    });
  }

}
