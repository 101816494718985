import {Directive, OnInit, ViewChild} from '@angular/core';
import {GaiaRecordFormControl} from '../services/gaia-record-form-control';
import {RestApiService} from '../services/rest-api.service';
import {FormGroup} from '@angular/forms';
import {NewRecordDialogComponent} from '../gaia-general/new-record-dialog/new-record-dialog.component';
import {GaiaRecord} from '../models/gaia-record.model';
import {PhanesNode, RailsUploadParams} from '@terravesta/phanes';
import {GaiaLogger} from '../../utils/common-functions';

@Directive()
export abstract class NewDialogDirective<T extends PhanesNode> implements OnInit {
  form: FormGroup;
  record: T;

  @ViewChild(NewRecordDialogComponent) newRecordWrapper: NewRecordDialogComponent;

  protected constructor(
    protected service: RestApiService<T>,
    protected formControl: GaiaRecordFormControl<T>,
    protected recordData?: NewDialogData<T>,
  ) {
  }

  ngOnInit(): void {
    if (this.recordData && this.recordData.record) {
      this.record = this.recordData.record as T;
    }else {
      this.record = {} as T;
    }
    GaiaLogger.log(this.record);
    this.form = this.formControl.toFormGroup(this.record);
  }

  abstract successfulSave(result: T);

  save() {
    if (this.form.valid) {
      const params: RailsUploadParams<T> = {data: this.form.value};
      if (this.recordData && this.recordData.parent) {
        params.parentId = this.recordData.parent.id;
      }
      if (this.record.id){
        params.recordId = this.record.id;
        this.service.updateRecord(params).subscribe(
          (result) => {
           this.record = result;
           this.successfulSave(result);
          },
          (error) => {
            this.showErrorsAndHide(error.error);
          },
        );
      }else {
        this.service.createRecord(params).subscribe(
          (result) => {
            this.record = result;
            this.successfulSave(result);
          },
          (error) => {
            this.showErrorsAndHide(error.error);
          },
        );
      }
    }
  }

  showErrorsAndHide(errors) {
    this.showErrors(errors);
    this.hideOverlay();
  }

  showErrors(errors){
    this.newRecordWrapper.showErrors(errors);
  }

  hideOverlay(){
    this.newRecordWrapper.unlockOverlay();
  }
}

export interface NewDialogData<T extends PhanesNode> {
  record?: T;
  parent?: GaiaRecord;
  doNotRedirect?: boolean; // If set will not do a redirect
}
