<button mat-fab (click)="newDeliverySite()">
  <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
</button>


<mat-table [dataSource]="processorDeliverySites" [ngClass.lt-md]="'mobile-table'">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-button color="accent" (click)="editSite(element)">
        <gaia-shared-cell-mobile-label>Name</gaia-shared-cell-mobile-label>
        {{element.name}}
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="post_code">
    <mat-header-cell *matHeaderCellDef i18n>Post Code</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <gaia-shared-cell-mobile-label>Post code</gaia-shared-cell-mobile-label>
      {{element.address.post_code}}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

