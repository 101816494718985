import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';
import {Contractor, ContractorUniversalSearchPreview} from './contractor.model';
import {OperationalRegion} from '../operational-regions/operational-region.model';
import {Observable, of, Subscription} from 'rxjs';
import {RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';
import * as FileSaver from 'file-saver';
import {GaiaLogger} from '../../../utils/common-functions';
import {UniversallySearchableService} from '../../universal-search/universally-searchable.service';

@Injectable({
  providedIn: 'root'
})
export class ContractorsService extends RestApiService<Contractor> implements UniversallySearchableService<ContractorUniversalSearchPreview> {

  constructor(
    httpClient: HttpClient
  ) {
    super('/contractors', 'contractor', httpClient);
  }

  universalSearchPreviewData(recordId: string): Observable<ContractorUniversalSearchPreview> {
    return of(null);
  }

  byRegion(region: OperationalRegion, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<Contractor>> {
    const httpParams = {
      operational_region_id: region.id,
      ...params
    };
    return this.httpGet<RailsAPIWrapper<Contractor>>(this.generateNonRecordUrl('by_region'), httpParams);
  }

  unlinkFromAccounts(contractor: Contractor): Observable<Contractor> {
    return this.httpPost<Contractor>(this.generateUrl({recordId: contractor.id}, 'unlink_from_accounts'), {});
  }

  downloadContractorList(regionIds: Array<string>, completed: () => void): Subscription {
    GaiaLogger.log(regionIds);
    return this.httpClient.get(this.generateNonRecordUrl('download_contractor_list'),
      {withCredentials: true, responseType: 'arraybuffer', params: { 'region_ids[]': regionIds }})
      .subscribe(
      (response) => {
        const file = new Blob([response], {type: 'application/pdf'});
        FileSaver.saveAs(file, 'contractor_list.pdf');
      },
      () => {},
      () => {
        completed();
      }
    );
  }
}
