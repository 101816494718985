import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-miscanthus-contract-form',
  templateUrl: './miscanthus-contract-form.component.html',
  styleUrls: ['./miscanthus-contract-form.component.scss']
})
export class MiscanthusContractFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
