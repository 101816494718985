import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductTableComponent } from './product-table/product-table.component';
import { ProductShowComponent } from './product-show/product-show.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {ProductsService} from './products.service';
import {ProductRoutingModule} from './product-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import { PriceBookComponent } from './price-book/price-book.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import { ProductSearchComponent } from './product-search/product-search.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import { ProductFormComponent } from './product-form/product-form.component';
import {MatCardModule} from '@angular/material/card';



@NgModule({
  declarations: [ProductTableComponent, ProductShowComponent, PriceBookComponent, ProductSearchComponent, ProductFormComponent],
    imports: [
        CommonModule,
        GaiaSharedModule,
        PhanesComponentsModule,
        MatPaginatorModule,
        MatTableModule,
        MatInputModule,
        MatButtonModule,
        ProductRoutingModule,
        ReactiveFormsModule,
        GaiaGeneralModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatCardModule
    ],
  exports: [ProductTableComponent, ProductShowComponent, ProductSearchComponent],
  providers: [ProductsService]
})
export class ProductsModule { }
