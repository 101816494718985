<app-universal-search-preview-wrapper [searchResult]="record" classType="Contracted Field" [icon]="gaiaIconSet.growers">

  <mat-list>
    <mat-list-item>
      <span matLine>Year Planted </span>
      <span matLine>{{record.year_planted}}</span>
    </mat-list-item>
  </mat-list>


  <ng-container buttons>
    <button mat-icon-button>
      <mat-icon (click)="showField()">{{ gaiaIconSet.edit }}</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
    <ng-container *ngIf="previewData">
      <mat-list>
        <mat-list-item>
          <mat-icon matListIcon>{{ gaiaIconSet.growers }}</mat-icon>
          <div matLine>{{ previewData.grower_code }}</div>
        </mat-list-item>
      </mat-list>
    </ng-container>

  </ng-container>

</app-universal-search-preview-wrapper>
