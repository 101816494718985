import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {Contact} from '../contact.model';
import {ContactsService} from '../contacts.service';

@Component({
  selector: 'app-contact-hover-popup',
  templateUrl: './contact-hover-popup.component.html',
  styleUrls: ['./contact-hover-popup.component.scss']
})
export class ContactHoverPopupComponent implements OnInit, OnChanges, AfterViewInit {

  gaiaIconSet = GaiaIconSet;
  showPopup = false;

  loading = false;

  @Input()
  contact: Contact;

  @Input()
  downloadFullRecord = false;

  fullLoadCompleted = false;

  record: Contact;
  leftPosition: string;
  topPosition: string;

  constructor(
    private contactsService: ContactsService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.contact) {
      this.record = this.contact;
    }
  }

  doContactLoad(){
    if (this.downloadFullRecord) {
      if(!this.fullLoadCompleted){
        this.loading = true;
        this.contactsService.getRecord({recordId: this.contact.id}).subscribe((record) => {
          this.record = record;
          this.loading = false;
          this.fullLoadCompleted = true;
        })
      }
    }
  }

  openPopup(event: MouseEvent) {
    this.doContactLoad();
    this.leftPosition = `${event.clientX}px`;
    this.topPosition = `${event.clientY}px`;
    this.showPopup = true;
  }

  closePopup(){
    this.showPopup = false;
  }
}
