import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoilSamplesTableComponent } from './soil-samples-table/soil-samples-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SoilSampleFormComponent } from './soil-sample-form/soil-sample-form.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { SoilSampleShowComponent } from './soil-sample-show/soil-sample-show.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FlexModule} from '@angular/flex-layout';
import {NgxEchartsModule} from 'ngx-echarts';
import {MatButtonModule} from '@angular/material/button';
import { SoilSamplesEmbedComponent } from './soil-samples-embed/soil-samples-embed.component';
import {MatIconModule} from '@angular/material/icon';
import { SoilSampleNewDialogComponent } from './soil-sample-new-dialog/soil-sample-new-dialog.component';
import { SoilSampleCreateFormComponent } from './soil-sample-create-form/soil-sample-create-form.component';
import { PhGaugeComponent } from './ph-gauge/ph-gauge.component';
import {HasSampleLocationsModule} from '../../concerns/has-sample-locations/has-sample-locations.module';
import { SoilTypeSelectComponent } from './soil-type-select/soil-type-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [
        SoilSamplesTableComponent,
        SoilSampleFormComponent,
        SoilSampleShowComponent,
        SoilSamplesEmbedComponent,
        SoilSampleNewDialogComponent,
        SoilSampleCreateFormComponent,
        PhGaugeComponent,
        SoilTypeSelectComponent
    ],
    exports: [
        SoilSamplesEmbedComponent
    ],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        GaiaSharedModule,
        MatTableModule,
        RouterModule,
        MatPaginatorModule,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        FlexModule,
        NgxEchartsModule,
        MatButtonModule,
        MatIconModule,
        HasSampleLocationsModule,
        MatSelectModule
    ]
})
export class SoilSamplesModule { }
