<mat-form-field>
  <mat-label i18n>{{ labelText }}</mat-label>
  <mat-select
    [formControl]="control"
    ngDefaultControl>
    <mat-option *ngFor="let contract of contracts" [value]="contract.id">
      {{ contract.grower_contract_ref}} - {{ contract.start_date }} - {{ contract.contract_term }} years
    </mat-option>
  </mat-select>
</mat-form-field>
