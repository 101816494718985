<gaia-shared-standard-dialog [dialogTitle]="'Select Payment Method'">
  <div content fxLayout="column">
      <mat-form-field>
        <mat-label i18n>Payment Method</mat-label>
        <mat-select [(ngModel)]="paymentMethodChosen">
          <mat-option [value]="paymentMethods.cash"><app-payment-method-i18n [key]="paymentMethods.cash"></app-payment-method-i18n></mat-option>
          <mat-option [value]="paymentMethods.bank_transfer"><app-payment-method-i18n [key]="paymentMethods.bank_transfer"></app-payment-method-i18n></mat-option>
          <mat-option [value]="paymentMethods.paypal"><app-payment-method-i18n [key]="paymentMethods.paypal"></app-payment-method-i18n></mat-option>
          <!-- no option for shopify or bank transfer on new orders -->
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>Paid On</mat-label>
        <input matInput [(ngModel)]="paidOn" type="datetime-local" />
      </mat-form-field>
    </div>
  <div actions>
    <button mat-raised-button mat-dialog-close="cancel" i18n>Cancel</button>
    <button mat-raised-button color="primary" (click)="markPaid()" [disabled]="canComplete()" i18n>Mark Paid</button>
  </div>
</gaia-shared-standard-dialog>

