<mat-card *ngIf="contract else loading">
  <mat-card-header>
    <mat-card-title>
       <a [routerLink]="['/', AppRoutes.GROWING, GrowingRoutes.CONTRACTS, ContractRoutes.TERM_CONTRACTS, contract.id]">{{ contract.grower_contract_ref }}</a>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <mat-list>
      <mat-list-item>
        <span i18n="">Contract Area</span>: {{ contract.intended_hectarage }}
      </mat-list-item>
      <mat-list-item>
        <span i18n="">End Date</span>: {{ contract.end_date | date }}
      </mat-list-item>
    </mat-list>

  </mat-card-content>

</mat-card>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
