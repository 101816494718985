import { Injectable } from '@angular/core';
import {StaffAbsence, StaffAbsenceTypes} from './staff-absence.model';
import {HttpClient} from '@angular/common/http';
import {RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {processRequiresApprovalEnum} from '../team-space-concerns/requires-approval/requires-approval.model';
import {StaffMember} from '../../system-admin/staff-member/staff-member.model';
import {dateToString} from '../../../utils/common-functions';
import {RequiresApprovalService} from '../team-space-concerns/requires-approval/requires-approval.service';

@Injectable({
  providedIn: 'root'
})
export class StaffAbsencesService extends RequiresApprovalService<StaffAbsence> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('staff_absences', 'staff_absence' , httpClient, StaffAbsencesService.processAbsence);
  }

  public static processAbsence(absence: StaffAbsence) {
    absence.absence_type_enum = StaffAbsenceTypes[absence.absence_type];
    processRequiresApprovalEnum(absence);
  }

  getStaffMemberAbsences(staffMember: StaffMember, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<StaffAbsence>>{
    params.search = {
      ...params.search,
      staff_member: {uuid: staffMember.id}
    };
    console.log(params);
    return this.getRecords(params);
  }

  getDayCount(startDate: Date, endDate: Date, startInAfternoon: boolean, returnInAfternoon: boolean): Observable<number> {
    return this.httpGet(this.generateNonRecordUrl('count_days'),
      { start_date: dateToString(startDate), end_date: dateToString(endDate),
        start_in_afternoon: startInAfternoon, return_in_afternoon: returnInAfternoon})
      .pipe(map((response) => {
        // @ts-ignore
        return response.days;
    }));
  }

  submitForApproval(staffAbsence: StaffAbsence): Observable<boolean> {
    return this.httpPost(this.generateUrl({ recordId: staffAbsence.id}, 'submit_for_approval'), null);
  }
}
