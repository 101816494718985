<a mat-flat-button aria-hidden="false" aria-label="Growing" color="primary" (click)="growingMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.growing }}</mat-icon>
  <div #growingMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="growingMenu"></div>
</a>
<mat-menu #growingMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.GROWERS]" i18n="growers list">
    <mat-icon>{{ gaiaIcons.growers }}</mat-icon>
    Growers
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.DRONES, droneImageRoutes.DRONE_IMAGE_VIEWER]" i18n="drone image review tool">
    <mat-icon>{{ gaiaIcons.drone_images }}</mat-icon>
    Drone Images
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.CONTRACTED_FIELDS]" i18n="contracted fields menu link">
    <mat-icon>{{ gaiaIcons.contracted_fields }}</mat-icon>
    Contracted Fields
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.PLANTING_OPPORTUNITIES]">
    <mat-icon>{{ gaiaIcons.planting_opportunity }}</mat-icon>
    <span i18n="planting opportunities menu link">Planting Opportunities</span>
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.SOIL_SAMPLES]" i18n="soil samples menu link">
    <mat-icon>{{ gaiaIcons.soil_samples }}</mat-icon>
    Soil Samples
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.MISCANTHUS_VARIETIES]" i18n="varieties menu link">
    <mat-icon>{{ gaiaIcons.miscanthus_variety }}</mat-icon>
    Varieties
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.CONTRACTED_FIELDS,
        contractedFieldRoutes.PARCEL_FINDER]" i18n="parcel finder menu link">
    <mat-icon>{{ gaiaIcons.parcel_finder }}</mat-icon>
    Parcel Finder
  </a>
  <app-contracts-menu></app-contracts-menu>
</mat-menu>
