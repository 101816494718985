<a mat-flat-button aria-hidden="false" aria-label="System Resources" color="primary" (click)="systemAdminMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.systemResource }}</mat-icon>
  <div #systemAdminMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="systemAdminMenu"></div>
</a>
<mat-menu #systemAdminMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.SYSTEM_RESOURCES, systemResourcesRoutes.HARVEST_HUB_HELP]" i18n="help">
    <mat-icon>{{ gaiaIcons.help }}</mat-icon>
    Harvest Hub Help
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.SYSTEM_RESOURCES, systemResourcesRoutes.IMAGES]" i18n="images">
    <mat-icon>{{ gaiaIcons.images }}</mat-icon>
    Images
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.SYSTEM_RESOURCES, systemResourcesRoutes.DOCUMENTS]" i18n="documents">
    <mat-icon>{{ gaiaIcons.document }}</mat-icon>
    Documents
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.SYSTEM_RESOURCES, systemResourcesRoutes.SENTINEL, sentinelRoutes.FUNCTIONS]" i18n="images">
    <mat-icon>{{ gaiaIcons.sentinel_satellite }}</mat-icon>
    Sentinel Functions
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.SYSTEM_RESOURCES, systemResourcesRoutes.CLIMATE_DATA_SOURCES]" i18n="images">
    <mat-icon>{{ gaiaIcons.sentinel_satellite }}</mat-icon>
    Climate Data Sources
  </a>
</mat-menu>
