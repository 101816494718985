import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessorDeliverySitesTableComponent } from './processor-delivery-sites-table/processor-delivery-sites-table.component';
import {MatTableModule} from '@angular/material/table';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { ProcessorDeliverySitesSelectComponent } from './processor-delivery-sites-select/processor-delivery-sites-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ProcessorDeliverySiteFormDialogComponent } from './processor-delivery-site-form-dialog/processor-delivery-site-form-dialog.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {PhanesComponentsModule, What3WordsModule} from '@terravesta/phanes';
import { ProcessorDeliverySiteFormEmbedComponent } from './processor-delivery-site-form-embed/processor-delivery-site-form-embed.component';
import {MatCardModule} from '@angular/material/card';



@NgModule({
  declarations: [
    ProcessorDeliverySitesTableComponent,
    ProcessorDeliverySitesSelectComponent,
    ProcessorDeliverySiteFormDialogComponent,
    ProcessorDeliverySiteFormEmbedComponent
  ],
  exports: [
    ProcessorDeliverySitesTableComponent,
    ProcessorDeliverySitesSelectComponent,
    ProcessorDeliverySiteFormEmbedComponent
  ],
    imports: [
        CommonModule,
        MatTableModule,
        GaiaSharedModule,
        ExtendedModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSlideToggleModule,
        GaiaGeneralModule,
        PhanesComponentsModule,
        AddressesModule,
        FlexModule,
        MatCardModule,
        What3WordsModule
    ],
  entryComponents: [ProcessorDeliverySiteFormDialogComponent]
})
export class ProcessorDeliverySitesModule { }
