<div class="grower-contracts">
  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Grower Contracts" i18n-pageTitle="page title for grower contracts page"
                    i18n-searchHint searchHint="Grower Code">

  </app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="contract_ref">
      <mat-header-cell *matHeaderCellDef i18n> Contract Ref </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growingRoutes.GROWER_CONTRACTS, element.id]">
          {{element.grower_contract_ref}}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="grower_code">
      <mat-header-cell *matHeaderCellDef i18n> Grower Code </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.grower ? element.grower.grower_code : "" }}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageSize]="dataSource.pageSize"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
