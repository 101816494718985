<ng-container *ngIf="form">
  <div [formGroup]="form" fxLayout="row wrap" fxLayoutGap="15px">

    <mat-card fxFlex="calc(50% - 15px)">
      <mat-card-header>
        <mat-icon mat-card-avatar>contact_page</mat-icon>
        <mat-card-title i18n="contact details header">Details</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="row wrap">
        <app-contact-email-input [contact]="record" formControlName="email"></app-contact-email-input>
        <gaia-shared-has-name-embed [formGroup]="form"></gaia-shared-has-name-embed>

        <mat-form-field>
          <mat-label i18n>Phone</mat-label>
          <input matInput type="tel" formControlName="phone" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Mobile</mat-label>
          <input matInput type="tel" formControlName="mobile" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Job title</mat-label>
          <input matInput formControlName="job_title" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <div fxFlex="calc(50% - 15px)" fxLayout="column" fxLayoutGap="15px">

      <mat-card *ngIf="account">
        <mat-card-header>
          <mat-card-title i18n="account details">Account</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-contact-type-select formControlName="relationship_to_account"></app-contact-type-select>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title i18n="contact marketing header">Marketing</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="checkbox-list">
            <mat-checkbox formControlName="accept_email_contact">Allow Email</mat-checkbox>
            <mat-checkbox formControlName="accept_phone_contact">Allow Phone</mat-checkbox>
            <mat-checkbox formControlName="accept_post_contact">Allow Post</mat-checkbox>
            <mat-form-field>
              <mat-label i18n="contact source">Source</mat-label>
              <input matInput formControlName="source_text" />
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>

    </div>

    <gaia-shared-address-embed-form fxFlex="calc(50% - 15px)" formGroupName="address"></gaia-shared-address-embed-form>

  </div>
</ng-container>
