import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaleTypesListComponent } from './bale-types-list/bale-types-list.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { BaleTypeFormDialogComponent } from './bale-type-form-dialog/bale-type-form-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {BaleTypeSelectComponent} from './bale-type-select/bale-type-select.component';
import {MatSelectModule} from '@angular/material/select';
import { BaleTypeSelectDialogComponent } from './bale-type-select-dialog/bale-type-select-dialog.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatDialogModule} from '@angular/material/dialog';
import { BaleTypesTableComponent } from './bale-types-table/bale-types-table.component';
import {RouterModule} from '@angular/router';
import { BaleTypeShowComponent } from './bale-type-show/bale-type-show.component';
import { BaleTypeFormComponent } from './bale-type-form/bale-type-form.component';
import {SpecificationCheckModule} from '../../concerns/specification-check/specification-check.module';
import {MatTabsModule} from '@angular/material/tabs';
import { BaleTypesStatsComponent } from './bale-types-stats/bale-types-stats.component';
import {NgxEchartsModule} from 'ngx-echarts';



@NgModule({
    declarations: [BaleTypesListComponent, BaleTypeFormDialogComponent, BaleTypeSelectComponent,
      BaleTypeSelectDialogComponent, BaleTypesTableComponent, BaleTypeShowComponent, BaleTypeFormComponent, BaleTypesStatsComponent],
    exports: [
        BaleTypesListComponent,
        BaleTypeSelectComponent
    ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatCardModule,
    GaiaGeneralModule,
    PhanesComponentsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule,
    FlexModule,
    MatSelectModule,
    GaiaSharedModule,
    MatDialogModule,
    RouterModule,
    SpecificationCheckModule,
    MatTabsModule,
    NgxEchartsModule
  ]
})
export class BaleTypesModule { }
