import {Component} from '@angular/core';
import {ContractedField} from '../contracted-field.model';
import {ContractedFieldsService} from '../contracted-fields.service';
import {MatDialog} from '@angular/material/dialog';
import {FieldMapPanelDirective} from '../../directives/field-map-panel.directive';

@Component({
  selector: 'app-contracted-field-map-panel',
  templateUrl: './contracted-field-map-panel.component.html',
  styleUrls: ['./contracted-field-map-panel.component.scss']
})
export class ContractedFieldMapPanelComponent extends FieldMapPanelDirective<ContractedField> {


  constructor(
    contractedFieldsService: ContractedFieldsService,
    matDialog: MatDialog,
  ) {
    super(contractedFieldsService, matDialog);
  }

}
