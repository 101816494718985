import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CompanyShowComponent} from './company-show/company-show.component';
import {CompaniesTableComponent} from './companies-table/companies-table.component';


const routes: Routes = [
  { path: '', component: CompaniesTableComponent },
  { path: ':id', component: CompanyShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
