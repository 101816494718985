<div (mouseenter)="openPopup($event)" (mouseleave)="closePopup()">

  <mat-icon>{{ gaiaIconSet.contact }}</mat-icon>

  <div class="popup-wrapper">
    <div class="popup" *ngIf="showPopup" [style.left]="leftPosition" [style.top]="topPosition">
      <mat-spinner *ngIf="loading else showRecord "></mat-spinner>
      <ng-template #showRecord>
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{ record.name }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>Phone: {{ record.phone }}</div>
            <div>Mobile: {{ record.mobile }}</div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </div>
  </div>

</div>
