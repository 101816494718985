<ng-container *ngIf="form" [formGroup]="form">

  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>Bale Addition</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>These bales will be assumed to be {{stack.bale_type.name}}</p>

      <div fxLayout="column" fxLayoutGap="15px">
        <mat-form-field>
          <mat-label i18n>Bales to add</mat-label>
          <input matInput formControlName="bales_added" placeholder="" type="number" min="0" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Reason (source of bales)</mat-label>
          <textarea matInput formControlName="reason" placeholder=""></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Date Added</mat-label>
          <input matInput formControlName="date_added" placeholder="" [matDatepicker]="datePicker" />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <mat-checkbox i18n formControlName="carry_over">Carried over from last year? *</mat-checkbox>
        <mat-hint i18n>* This will update the previous stacks in the future</mat-hint>


      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
