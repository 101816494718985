<div class="ClimateGrid">

  <app-climate-grid-graphs [gpsPoint]="gpsPoint" [selectedYear]="selectedYear" (gridLocation)="updatePosition($event)"></app-climate-grid-graphs>

  <div fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="calc(50% - 20px)">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Climate Grid</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <phanes-google-map [mapRecordsItems]="mapRecords" [mapPoints]="mapPoints" width="100%"></phanes-google-map>
    </mat-card-content>
  </mat-card>
      </div>
  </div>
</div>

