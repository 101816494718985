
<div class="harvest-list">

  <div class="options">
    <mat-form-field appearance="fill">
      <mat-label i18n>Harvest Year</mat-label>
      <mat-select [(ngModel)]="selectedYear" (selectionChange)="yearSelected()">
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label i18n>Grower Code</mat-label>
      <input matInput [(ngModel)]="growerSearch" type="text" (input)="yearSelected()" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label i18n>Contractor Name</mat-label>
      <mat-select [(ngModel)]="contractorSearch" (selectionChange)="yearSelected()">
        <mat-option></mat-option>
        <mat-option *ngFor="let contractor of contractors" [value]="contractor.id">{{ contractor.account_name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="incVerified" (change)="yearSelected()">Inc Verified?</mat-slide-toggle>
  </div>
  <div>
    <div appScrollableTable [maxHeight]="'90vh'">
      <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="grower_code">
          <mat-header-cell *matHeaderCellDef i18n>Grower Code</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button color="accent" (click)="selectHarvest(element)">
              {{element.grower.grower_code}}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="bale_count">
          <mat-header-cell *matHeaderCellDef i18n>Bale Count</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.declared_bale_count }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="verified">
          <mat-header-cell *matHeaderCellDef i18n>Verified</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.ops_checked">{{ gaiaIconSet.verified }}</mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>
    </div>

    <mat-paginator [length]="dataSource.totalRecords" [pageSize]="30"
                   [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
  </div>

</div>

<div class="harvest-viewer">
  <div *ngIf="selectedHarvest">
    <app-miscanthus-harvest-review [miscanthusHarvest]="selectedHarvest"></app-miscanthus-harvest-review>
  </div>
</div>
