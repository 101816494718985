import {Component} from '@angular/core';
import {PriceGroupsService} from '../price-groups.service';
import {PriceGroup} from '../price-group.model';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MatDialog} from '@angular/material/dialog';
import {PriceGroupNewDialogComponent} from '../price-group-new-dialog/price-group-new-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CommercialSalesRoutes} from '../../commercial-sales-routing.module';

@Component({
  selector: 'app-price-groups-table',
  templateUrl: './price-groups-table.component.html',
  styleUrls: ['./price-groups-table.component.scss']
})
export class PriceGroupsTableComponent extends IndexTableDirective<PriceGroup> {

  commercialSalesRoutes = CommercialSalesRoutes;
  displayedColumns = ['name', 'active', 'default_group_contact', 'default_group_stockist',
    'default_group_brand_ambassador', 'default_group_commercial_user'];

  constructor(
    priceGroupService: PriceGroupsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(priceGroupService, router, activatedRoute, matDialog, PriceGroupNewDialogComponent, null,
      ['name']);
  }

}
