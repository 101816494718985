<mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n> Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTED_FIELDS, element.id]">
        {{element.name}}
      </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="field_reference_number">
    <mat-header-cell *matHeaderCellDef i18n> Field Reference Number </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.field_reference_number}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="year_planted">
    <mat-header-cell *matHeaderCellDef i18n>Year Planted</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.year_planted}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="variety">
    <mat-header-cell *matHeaderCellDef i18n>Variety</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.planted_variety.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="area_planted">
    <mat-header-cell *matHeaderCellDef i18n>Area Planted</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.planted_area_ha}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="total_yield">
    <mat-header-cell *matHeaderCellDef i18n>
      <mat-form-field>
        <mat-label>Total Yield for</mat-label>
        <mat-select [formControl]="yearSelection">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.yield_cache">
        {{ element.yield_cache[yearSelection.value] | number : '1.0-2' }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="per_ha">
    <mat-header-cell *matHeaderCellDef i18n>Per Ha</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.yield_cache">
        {{ (element.yield_cache[yearSelection.value]/element.planted_area_ha) | number : '1.0-2' }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="contract">
    <mat-header-cell *matHeaderCellDef i18n>Current Contract</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a mat-button color="accent" *ngIf="element.current_term_contract"
         [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.TERM_CONTRACTS, element.current_term_contract.id]">
        {{ element.current_term_contract.grower_contract_ref }}
      </a>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>

<mat-paginator #currentPaginator [length]="dataSource.totalRecords"
               [pageSize]="dataSource.pageSize"
               [pageIndex]="dataSource.pageIndex"
               [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

