import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {Customer, CustomerType, OrderItem, PaymentMethods} from '../order.model';
import {Product} from '../../products/product.model';
import {PriceGroup, PricePoint} from '../../price-groups/price-group.model';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PriceGroupsService} from '../../price-groups/price-groups.service';
import {OrderFormControlService} from '../order-form-control.service';
import {Stockist} from '../../stockists/stockist.model';
import {MatDialog} from '@angular/material/dialog';
import {AddressCopyChoiceDialogComponent} from '../address-copy-choice-dialog/address-copy-choice-dialog.component';
import {MatSelectChange} from '@angular/material/select';
import moment from 'moment';
import { orderBy } from 'lodash';
import {CommercialUser} from '../../commercial-users/commercial-user.model';
import {Address, AddressEmbedFormComponent} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  form: FormGroup;

  @Input()
  customer: Customer = null;

  @Input()
  newOrder = false;

  @ViewChildren('productSearch')
  private productInputs: QueryList<ElementRef>;

  @ViewChild('billingAddress')
  billingAddress: AddressEmbedFormComponent;

  @ViewChild('shippingAddress')
  shippingAddress: AddressEmbedFormComponent;

  orderItemColumns = ['product_name', 'vat_rate', 'quantity', 'ex_vat_sales_price', 'sales_price', 'discount', 'line_total', 'delete'];
  priceGroup: PriceGroup;
  customerTypes = CustomerType;
  paymentMethods = PaymentMethods;
  editable = true; // editable unless otherwise specified
  orderItemsExTotal: number;
  orderItemsTotal: number;
  orderTotal: number;
  refundTotal: number;

  lineTracker = new BehaviorSubject<AbstractControl[]>([]);

  constructor(
    private snackBar: MatSnackBar,
    private priceGroupsService: PriceGroupsService,
    private orderFormControl: OrderFormControlService,
    public matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.updateForm();
    this.form.valueChanges.subscribe((changes) => {
      this.updateForm();
    });
  }

  ngAfterViewInit() {
    if (this.customer === null || this.customer.type_enum === CustomerType.direct) {
      // No customer so we will use default price group
      this.priceGroupsService.getDefaultPriceGroup().subscribe(
        (priceGroup) => {
          this.priceGroup = priceGroup;
          this.form.patchValue({
            price_group_used_id: this.priceGroup.id
          });
        });
    }else{
      this.priceGroup = (this.customer.record as Stockist).default_price_group;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateForm();
  }

  updateForm(){
    this.setOrderTotal();
    this.lineTracker.next(this.getLineControls());
    if (this.form.get('paid').value) {
      this.editable = false;
    }
    if (this.editable) {
    }
  }

  setOrderTotal() {
    this.orderItemsExTotal = 0;
    this.orderItemsTotal = 0;
    this.orderTotal = 0;
    this.refundTotal = 0;
    const lineCount = this.getLines().length;
    for (let i = 0; i < lineCount; i++){
      this.orderItemsTotal += this.getLineTotal('sales_price', i);
      this.orderItemsExTotal += this.getLineTotal('ex_vat_sales_price', i);
    }
    (this.form.get('refunds') as FormArray).controls.forEach((control) => {
      this.refundTotal += control.get('amount_refunded').value;
    });
    this.orderTotal = this.orderItemsTotal + this.form.get('delivery_cost').value - this.refundTotal;
  }

  setCustomer(cust: Customer) {
    this.customer = cust;
    this.form.patchValue({ customer_id: this.customer.record.id });
    if (this.customer.type_enum !== CustomerType.direct){
      if ('default_price_group' in this.customer.record) {
        this.priceGroup = this.customer.record.default_price_group;
      }
    }
  }

  getCustomerOnAccount(): boolean{
    if (this.customer.type_enum === CustomerType.direct){
      return false;
    }else{
      return (this.customer.record as CommercialUser).on_account;
    }
  }

  getLines(){
    return this.form.get('order_items') as FormArray;
  }

  getLineControls(){
    return this.getLines().controls;
  }

  addNewLine(){
    if (this.customer){
      const control = this.orderFormControl.toOrderItemFormGroup({quantity: 1, discount: 0} as OrderItem);
      this.getLines().push(control);
    }else{
      this.snackBar.open($localize`:@@orderSelectCustomerFirst: Please ensure you have set the customer before adding items`, `Dismiss`, {
        duration: 2000,
      });
    }
  }

  getOrderLineProduct(index: number): Product {
    const line = this.getLines().controls[index];
    let prod;
    if (line.get('product_id').value){
      prod = {
        id: line.get('product_id').value,
        name: line.get('product_name').value,
      } as Product;
      return prod;
    } else {
      prod = undefined;
    }
    return prod;
  }

  productSelected(index: number, product: Product) {
    const newValues =  {product_id: product.id, product_name: product.name, sales_price: null,
                        list_price: null, vat_rate: null, ex_vat_sales_price: null};
    const currentQty = this.getLineControls()[index].get('quantity').value;
    const price = this.getProductPrice(product.id, currentQty);
    newValues.sales_price = price.vat_price;
    newValues.list_price = price.vat_price;
    newValues.vat_rate = price.vat_rate;
    newValues.ex_vat_sales_price = price.ex_vat_price;
    this.getLineControls()[index].patchValue(newValues);
  }

  getProductPrice(productId: string, qty: number): PricePoint {
    if (this.priceGroup) {
      const priceGroupPrices = this.priceGroup.products.filter((product) => product.id === productId)[0];
      const sorted = orderBy(priceGroupPrices.price_points, ['min_qty'], ['desc']);
      const prices = sorted.filter((item) => qty >= item.min_qty);
      return prices[0];
    }else{
      return null;
    }
  }

  getLineTotal(priceSelector: string, index: number) {
    let total;
    const currentLine = this.getLineControls()[index];
    if (currentLine.get('to_delete').value) {
      total = 0;
    }else {
      const qty = currentLine.get('quantity').value;
      const salesPrice = currentLine.get(priceSelector).value;
      const discount = currentLine.get('discount').value;
      try {
        let linePrice = salesPrice;
        if (discount > 0) {
          linePrice = linePrice * ((100.0 - discount) / 100.0);
        }
        total = qty * linePrice;
      } catch (error) {
        total = 0;
      }
    }
    return total;
  }

  removeLine(index: number) {
    const line = this.getLineControls()[index];
    if (line.get('id').value) {
      line.patchValue({to_delete: true});
    }else{
      this.getLines().removeAt(index);
      this.lineTracker.next(this.getLineControls());
    }
  }

  unsetCustomer() {
    this.form.patchValue({ customer_id: null });
    this.customer = null;
  }

  canAddLine() {
    return this.customer && !(this.form.get('order_items').disabled);
  }

  addressCopied(selectedAddress: Address) {
    const dialogRef = this.matDialog.open(AddressCopyChoiceDialogComponent, {
      data: selectedAddress,
      width: '400px',
      height: '155px'
    });
    dialogRef.afterClosed().subscribe((choice) => {
      switch (choice) {
        case 'shipping':
          this.shippingAddress.updateAddress(selectedAddress);
          break;
        case 'billing':
          this.billingAddress.updateAddress(selectedAddress);
          break;
        case 'both':
          this.billingAddress.updateAddress(selectedAddress);
          this.shippingAddress.updateAddress(selectedAddress);
          break;
      }
    });
  }

  paymentMethodSelected(paymentMethodChange: MatSelectChange) {
    const currentPaymentMethod = this.form.get('payment_method').value;
    let paidOn;
    if (currentPaymentMethod === null){
      paidOn = null;
    }else{
      paidOn = moment().format(`YYYY-MM-DDTHH:mm`);
    }
    this.form.patchValue({paid_on: paidOn});
  }

  updatePrice(index: number) {
    const line = this.getLineControls()[index];
    const qty = line.get('quantity').value;
    const productId = line.get('product_id').value;
    const price = this.getProductPrice(productId, qty);
    line.patchValue({
      sales_price: price.vat_price,
      list_price: price.vat_price,
      vat_rate: price.vat_rate,
      ex_vat_sales_price: price.ex_vat_price,
    });
  }
}
