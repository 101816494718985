import {Component, Inject} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {LoaderAvailability} from '../loader-availability.model';
import {LoaderAvailabilitiesService} from '../loader-availabilities.service';
import {LoaderAvailabilityFormControlService} from '../loader-availability-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-loader-availability-form-dialog',
  templateUrl: './loader-availability-form-dialog.component.html',
  styleUrls: ['./loader-availability-form-dialog.component.scss']
})
export class LoaderAvailabilityFormDialogComponent extends FormDialogDirective<LoaderAvailability> {

  constructor(
    loaderAvailabilitiesService: LoaderAvailabilitiesService,
    loaderAvailabilityFormControl: LoaderAvailabilityFormControlService,
    matDialog: MatDialogRef<LoaderAvailability>,
    @Inject(MAT_DIALOG_DATA) data: FormDialogData<LoaderAvailability>,
  ) {
    super(loaderAvailabilitiesService, loaderAvailabilityFormControl, matDialog, data);
  }


}
