import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {extractKMLString, KmlPlacemark, listPlacemarksInKMLString} from '../kml-tools';
import {FormControl, Validators} from '@angular/forms';
import {GaiaLogger} from '../../../utils/common-functions';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-kml-select-placemark-dialog',
  templateUrl: './kml-select-placemark-dialog.component.html',
  styleUrls: ['./kml-select-placemark-dialog.component.scss']
})
export class KmlSelectPlacemarkDialogComponent implements OnInit {

  placemarks: Array<KmlPlacemark> = new Array<KmlPlacemark>();
  placemarkSelector = new FormControl(null, Validators.required);
  loading = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { file: File },
    private dialogRef: MatDialogRef<KmlSelectPlacemarkDialogComponent>,
  ) { }

  ngOnInit(): void {
    if (this.data.file) {
      extractKMLString(this.data.file).then(
        (response) => {
          this.placemarks = listPlacemarksInKMLString(response);
          this.loading.next(false);
          GaiaLogger.log('here', this.placemarks);
        });
    }
  }

  selectPlacemark() {
    this.dialogRef.close(
      { placemark: this.placemarkSelector.value }
    );
  }
}

