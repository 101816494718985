<div *ngIf="form" [formGroup]="form">
  <div class="two-column-cards">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.processors}}</mat-icon>
        <mat-card-title>Processor Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <mat-label i18n>Account Name</mat-label>
          <input matInput formControlName="account_name" />
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>Map Colour</mat-label>
          <input matInput formControlName="map_colour" />
          <mat-hint i18n>Hex code (e.g. #efefef)</mat-hint>
        </mat-form-field>
        <mat-slide-toggle formControlName="active" i18n>Active</mat-slide-toggle>
      </mat-card-content>
    </mat-card>

    <gaia-shared-address-embed-form formGroupName="billing_address"></gaia-shared-address-embed-form>
  </div>

  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>
        Moisture Specification
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-specification-checks-embed-form specificationUnits="%" formGroupName="moisture_specifications"></app-specification-checks-embed-form>
    </mat-card-content>
  </mat-card>
</div>
