
<ng-container *ngIf="staffMember.primary_office else noOffice">
  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>{{ gaiaIconSet.settings }}</mat-icon>
      <mat-card-title>Settings</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-checkbox [(ngModel)]="showSalutation">Show Salutation</mat-checkbox>
      <mat-checkbox [(ngModel)]="showQRCode">Show QR Code (not working just yet)</mat-checkbox>
    </mat-card-content>
  </mat-card>

  <div style="margin: 10px 0;">
    <p i18n="Instructions to use signature generated below">To use your signature, highlight everything below the line, and copy and paste it into your email client.</p>
  </div>

  <div class="email-signature-box" #emailSig>
    <!-- Email Signature -->

    <table style="font-size: 10pt; font-family: Arial, Verdana, sans-serif;">
      <tr>
        <td>
          <span><ng-container *ngIf="showSalutation">{{ staffMember.salutation }} </ng-container>{{ staffMember.first_name }} {{ staffMember.surname }}</span>
          <br />
          <span style="font-weight: bold;">{{ staffMember.job_title }}</span>
        </td>
        <td></td>
      </tr>
      <tr>
        <td style="border-bottom: 1px solid #EF8700; padding: 10px 0 15px 0;">
          <a href="https://www.terravesta.com">
            <img src="https://www.terravesta.com/static/terravesta-growing-innovation.png" alt="Terravesta, Growing Innovation" />
          </a>
        </td>
      </tr>

      <tr>
        <td style="border-bottom: 1px solid #6D8119; padding-bottom: 5px;">
          <table>
            <tr>
              <td>
                <table>
                  <tr>
                    <td>{{ addressString }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color: #6D8119;">Telephone:</span>&nbsp;<a href="tel:{{ staffMember.primary_office.phone_number.as_str }}">{{ staffMember.primary_office.phone_number.as_str }}</a> |
                      <span style="color: #6D8119;">Mobile:</span>&nbsp;<a href="tel:{{ staffMember.mobile_number.as_str }}">{{ staffMember.mobile_number.as_str}}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color: #6D8119;">Website:</span>&nbsp;<a href="https://www.terravesta.com/">www.terravesta.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 6px 0 8px 0;">
                      <a style="color: #EF8700;" href="https://eepurl.com/cSA7kD">Join our mailing list</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color: #6D8119;">Social:&nbsp;</span>
                      <a href="https://www.facebook.com/terravesta">Facebook</a>
                      |
                      <a href="https://www.twitter.com/terravesta">Twitter</a>
                      |
                      <a href="https://www.linkedin.com/company/terravesta">LinkedIn</a>
                      |
                      <a href="https://www.youtube.com/user/Terravesta">YouTube</a>
                    </td>
                  </tr>
                </table>
              </td>
              <td style="text-align: center;">
                <table *ngIf="showQRCode" >
                  <tr>
                    <td>
                      <img alt="Staff Member Details QR Code" [src]="qrCodeUrl" style="width: 90px; height: auto;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style="font-size: 9pt;">Scan me for <br /> contact information</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <p style="font-size: 8pt; font-family: Arial, Verdana, sans-serif;">
      This message is intended only for the stated addressee(s) and may be confidential. Access to this email by anyone else is unauthorised. Any opinions expressed in this email do not necessarily reflect the opinions of Terravesta Ltd. Any unauthorised disclosure, use or dissemination, either whole or in part is prohibited. If you are not the intended recipient of this message, please notify the sender immediately.
    </p>

    <p style="font-size: 8pt; font-family: Arial, Verdana, sans-serif;">
      Terravesta Ltd has taken every reasonable precaution to ensure that any attachment to this e-mail has been swept for viruses. However, we cannot accept liability for any damage sustained as a result of software viruses and would advise that you carry out your own virus checks before opening any attachment.
    </p>

    <p style="font-size: 8pt; font-family: Arial, Verdana, sans-serif;">
      Thank you for your co-operation.
    </p>


    <!-- End of Email Signature -->

  </div>
</ng-container>
<ng-template #noOffice>
  <span>Please ensure that the primary office is set to generate a signature</span>
</ng-template>
