import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandAmbassadorTableComponent } from './brand-ambassador-table/brand-ambassador-table.component';
import { BrandAmbassadorShowComponent } from './brand-ambassador-show/brand-ambassador-show.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import { BrandAmbassadorFormComponent } from './brand-ambassador-form/brand-ambassador-form.component';
import { BrandAmbassadorNewDialogComponent } from './brand-ambassador-new-dialog/brand-ambassador-new-dialog.component';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PriceGroupsModule} from '../price-groups/price-groups.module';
import {MatButtonModule} from '@angular/material/button';
import { BrandAmbassadorCardComponent } from './brand-ambassador-card/brand-ambassador-card.component';
import {MatListModule} from '@angular/material/list';


@NgModule({
    declarations: [BrandAmbassadorTableComponent, BrandAmbassadorShowComponent, BrandAmbassadorFormComponent,
        BrandAmbassadorNewDialogComponent,
        BrandAmbassadorCardComponent],
    imports: [
        CommonModule,
        GaiaSharedModule,
        MatTableModule,
        GaiaGeneralModule,
        RouterModule,
        MatPaginatorModule,
        MatCardModule,
        ReactiveFormsModule,
        MatIconModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        PriceGroupsModule,
        MatButtonModule,
        MatListModule,
    ],
    exports: [
        BrandAmbassadorFormComponent,
        BrandAmbassadorCardComponent
    ]
})
export class BrandAmbassadorsModule { }
