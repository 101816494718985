import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {RailsFile} from '../../models/rails-file.model';
import {GaiaLogger} from '../../../utils/common-functions';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

  file: File | RailsFile = null;
  onChange: any;

  url: string|SafeUrl;

  @Input()
  fileTypes: string;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
    this.updateUrl();
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  updateUrl(){
    GaiaLogger.log('here', this.file);
    if (this.file) {
      if (this.file.hasOwnProperty('url')) {
        this.url = (this.file as RailsFile).url;
      }else{
        this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file as Blob));
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: File|RailsFile|null): void {
    this.host.nativeElement.value = '';
    this.file = value;
    this.updateUrl();
  }

  unLinkFile() {
    this.onChange(null);
    this.file = null;
    this.updateUrl();
  }
}
