import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCategoriesFormComponent } from './event-categories-form/event-categories-form.component';
import { EventCategoriesNewDialogComponent } from './event-categories-new-dialog/event-categories-new-dialog.component';
import { EventCategoryShowComponent } from './event-category-show/event-category-show.component';
import { EventCategoriesTableComponent } from './event-categories-table/event-categories-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormGroupDirective, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    EventCategoriesFormComponent,
    EventCategoriesNewDialogComponent,
    EventCategoryShowComponent,
    EventCategoriesTableComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FlexModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [
    EventCategoriesTableComponent,
  ]

})
export class EventCategoriesModule { }
