<a mat-flat-button aria-hidden="false" aria-label="Asset" color="primary" (click)="assetMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.assets }}</mat-icon>
  <div #assetMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="assetMenu"></div>
</a>

<mat-menu #assetMenu="matMenu">
  <a mat-menu-item [routerLink]="'assets'" i18n="assets list">
    <mat-icon>{{ gaiaIcons.assets }}</mat-icon>
    Assets List
  </a>
</mat-menu>
