import {Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Grower} from '../../../growers/grower.model';
import {EstablishmentContractsService} from '../establishment-contracts.service';
import {EstablishmentContract} from '../establishment-contract.model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorConnectorDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-establishment-contract-select',
  templateUrl: './establishment-contract-select.component.html',
  styleUrls: ['./establishment-contract-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EstablishmentContractSelectComponent),
      multi: true
    }

  ]
})
export class EstablishmentContractSelectComponent extends ControlValueAccessorConnectorDirective implements OnInit, OnChanges {

  @Input()
  grower: Grower;

  @Input()
  labelText: string;

  contracts: Array<EstablishmentContract>;

  constructor(
    private establishmentContractsService: EstablishmentContractsService,
    inj: Injector,
  ) {
    super(inj);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grower) {
      if (changes.grower.currentValue != changes.grower.previousValue) {
        this.establishmentContractsService.forGrower(this.grower).subscribe((results) => {
          this.contracts = results.records;
        });
      }
    }
  }

}
