import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {CommercialUser} from '../commercial-user.model';

@Component({
  selector: 'app-commercial-user-form',
  templateUrl: './commercial-user-form.component.html',
  styleUrls: ['./commercial-user-form.component.scss']
})
export class CommercialUserFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  commercialUser: CommercialUser;

  gaiaIcons = GaiaIconSet;

  constructor() { }

  ngOnInit(): void {
  }

}
