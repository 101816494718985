import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {ContractedField} from '../contracted-field.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {yearArray} from '../../../../utils/common-functions';
import {FormControl} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ContractRoutes} from '../../contracts/contracts-routing.module';

@Component({
  selector: 'app-contracted-fields-simple-table',
  templateUrl: './contracted-fields-simple-table.component.html',
  styleUrls: ['./contracted-fields-simple-table.component.scss']
})
export class ContractedFieldsSimpleTableComponent implements OnInit, AfterViewInit {

  gaiaIconSet = GaiaIconSet;
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  contractRoutes= ContractRoutes;
  displayedColumns = ['name', 'field_reference_number', 'variety', 'year_planted', 'area_planted', 'total_yield', 'per_ha', 'contract'];

  years = yearArray(2020).reverse();

  yearSelection = new FormControl(new Date().getFullYear());

  @Input() dataSource: GaiaIndexDataSource<ContractedField>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
