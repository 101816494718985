import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenLockService {

  public lockState = new BehaviorSubject<boolean>(false);
  public currentMessage = '';

  constructor() { }

  lockScreen(message: string) {
    this.currentMessage = message;
    this.lockState.next(true);
  }

  unlockScreen(){
    this.currentMessage = '';
    this.lockState.next(false);
  }
}
