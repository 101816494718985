import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {PlantingOpportunity, PlantingOpportunityMapData} from './planting-opportunity.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {flattenSearch, RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';
import {Grower} from '../growers/grower.model';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PlantingOpportunitiesService extends RestApiService<PlantingOpportunity> {

  constructor(httpClient: HttpClient) {
    super('/planting_opportunities', 'planting_opportunity', httpClient);
  }

  opportunity(search: any = {}): Observable<Array<PlantingOpportunityMapData>> {
    const flattened = flattenSearch(search, 'search');
    return this.httpGet<Array<PlantingOpportunityMapData>>(this.generateNonRecordUrl('opportunity'),
      { ...flattened });
  }

  getRecordsForGrower(grower: Grower, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<PlantingOpportunity>> {
    params.search = {
      ...params.search,
      grower: {uuid: grower.id}
    };
    return this.getRecords(params);
  }

  downloadFarmSurvey(opportunity: PlantingOpportunity, completed: () => void){
    return this.httpGetBuffered(this.generateUrl({recordId: opportunity.id}, 'download_farm_survey'))
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/pdf'});
          FileSaver.saveAs(file, 'farm_survey.pdf');
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  countByStage(expectedPlantingYear: number): Observable<[{ stage: string, count: number }]> {
    return this.httpGet(this.generateNonRecordUrl('count_by_stages'), { year: expectedPlantingYear });
  }
}
