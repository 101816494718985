<a mat-flat-button aria-hidden="false" aria-label="System Admin" color="primary" (click)="systemAdminMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.system_admin }}</mat-icon>
  <div #systemAdminMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="systemAdminMenu"></div>
</a>
<mat-menu #systemAdminMenu="matMenu">
  <a mat-menu-item *ngIf="currentUser.is_system_admin" [routerLink]="[appRoutes.SYSTEM_ADMIN, systemAdminRoutes.APP_CREDENTIALS]" i18n="help">
    <mat-icon>{{ gaiaIcons.help }}</mat-icon>
    App Credentials
  </a>
  <a mat-menu-item *ngIf="currentUser.has_hr_access" [routerLink]="[appRoutes.SYSTEM_ADMIN, systemAdminRoutes.STAFF_MEMBERS, staffMemberRoutes.HR_SUMMARY]" i18n="help">
    <mat-icon>{{ gaiaIcons.directory }}</mat-icon>
    HR Summary
  </a>
</mat-menu>
