import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PriceGroupsModule} from '../price-groups/price-groups.module';
import {MatButtonModule} from '@angular/material/button';
import { CommercialUsersTableComponent } from './commercial-users-table/commercial-users-table.component';
import { CommercialUserShowComponent } from './commercial-user-show/commercial-user-show.component';
import { CommercialUserFormComponent } from './commercial-user-form/commercial-user-form.component';
import { CommercialUserNewDialogComponent } from './commercial-user-new-dialog/commercial-user-new-dialog.component';
import { CommercialUserCardComponent } from './commercial-user-card/commercial-user-card.component';
import {MatListModule} from '@angular/material/list';



@NgModule({
    declarations: [CommercialUsersTableComponent, CommercialUserShowComponent, CommercialUserFormComponent,
        CommercialUserNewDialogComponent, CommercialUserCardComponent],
    imports: [
        CommonModule,
        GaiaSharedModule,
        MatTableModule,
        GaiaGeneralModule,
        RouterModule,
        MatPaginatorModule,
        MatCardModule,
        ReactiveFormsModule,
        MatIconModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        PriceGroupsModule,
        MatButtonModule,
        AddressesModule,
        MatListModule,
    ],
    exports: [
        CommercialUserFormComponent,
        CommercialUserCardComponent
    ]
})
export class CommercialUsersModule { }
