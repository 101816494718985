import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


export class MarketingRoutes {
  public static SETTINGS = 'settings';
  public static FEATURE_BANNERS = 'feature-banners';
  public static EVENT_CATEGORIES = 'event-categories';
  public static EVENTS = 'events';
 // public static EVENT_BOOKINGS = 'event-bookings';
  public static GROWER_PROFILES = 'grower-profiles';
  public static WEB_ENQUIRIES = 'web-enquiries';
}

const routes: Routes = [
  {
    path: MarketingRoutes.FEATURE_BANNERS,
    loadChildren: () => import('./feature-banners/feature-banner-routing.module').then(m => m.FeatureBannerRoutingModule),
  },
  {
    path: MarketingRoutes.EVENT_CATEGORIES,
    loadChildren: () => import('./event-categories/event-categories-routing.module').then(m => m.EventCategoriesRoutingModule),
  },
  {
    path: MarketingRoutes.EVENTS,
    loadChildren: () => import('./events/events-routing.module').then(m => m.EventsRoutingModule),
  },
  /*{
    path: MarketingRoutes.EVENT_BOOKINGS,
    loadChildren: () => import('./event-bookings/event-bookings-routing.module').then(m => m.EventBookingsRoutingModule),
  },*/
  {
    path: MarketingRoutes.WEB_ENQUIRIES,
    loadChildren: () => import('./web-enquiries/web-enquiries-routing.module').then(m => m.WebEnquiriesRoutingModule),
  },
  {
    path: MarketingRoutes.GROWER_PROFILES,
    loadChildren: () => import('./grower-profiles/grower-profiles-routing.module').then(m => m.GrowerProfilesRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketingRoutingModule { }
