import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GPSPoint, PhanesMapPoint, PhanesRecordMapItem} from '@terravesta/phanes';
import {ContractedField} from '../../../growing/contracted-fields/contracted-field.model';
import {ClimateGridGraphsComponent} from '../climate-grid-graphs/climate-grid-graphs.component';


@Component({
  selector: 'app-climate-grid-viewer',
  templateUrl: './climate-grid-viewer.component.html',
  styleUrls: ['./climate-grid-viewer.component.scss']
})
export class ClimateGridViewerComponent implements OnInit, OnChanges {

  @Input()
  selectedYear: number = new Date().getFullYear() - 1;

  @Input()
  contractedField: ContractedField;

  @ViewChild(ClimateGridGraphsComponent)
  graphs: ClimateGridGraphsComponent;

  mapRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  mapPoints: Array<PhanesMapPoint> = new Array<PhanesMapPoint>();

  gpsPoint: GPSPoint;

  constructor(
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contractedField) {
      if (changes.contractedField.currentValue) {
        this.gpsPoint = this.contractedField;
      }
    }
    this.loadFieldData();
  }

  updateMap() {
    this.loadFieldData();
  }

  loadFieldData() {
    this.mapRecords = new Array<PhanesRecordMapItem>();
    const content = `
    <strong>Name:</strong> ${this.contractedField.name}
    <br />
    <strong>Year Planted:</strong> ${this.contractedField.year_planted}
    <br />
    `;
    const recordItem = {
      record: this.contractedField,
      infoWindowHtml: content,
    } as PhanesRecordMapItem;
    this.mapRecords.push(recordItem);
  }

  updatePosition(event: GPSPoint) {
    if (event.longitude !== null) {
      const content = `
        <strong>grid_latitude:</strong> ${event.latitude}
        <br />
        <strong>grid_longitude:</strong> ${event.longitude}
        `;
      const point = {
        position: event,
        zIndex: 10,
        infoWindowHtml: content,
        style: {
          markerColor: '#f28c07'
        }
      };
      // Need to change the array, not just append to it
      this.mapPoints = new Array<PhanesMapPoint>(point);
    }
  }
}




