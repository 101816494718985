import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../../services/rest-api.service';
import {HaulierDriver} from './haulier-driver.model';

@Injectable({
  providedIn: 'root'
})
export class HaulierDriversService extends RestApiService<HaulierDriver> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/hauliers/{parentId}/haulier_drivers/', 'haulier_driver', httpClient);
  }
}
