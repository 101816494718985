import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approval-panel',
  templateUrl: './approval-panel.component.html',
  styleUrls: ['./approval-panel.component.scss']
})
export class ApprovalPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
