import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {AssetManagementService} from "../asset-management.service";

@Component({
  selector: 'asset-image-popup',
  templateUrl: './asset-image-popup.component.html',
  styleUrls: ['./asset-image-popup.component.scss']
})
export class AssetImagePopupComponent implements OnInit{

  constructor(
    private dialog: MatDialog,
    private builder: FormBuilder,
    private assetService: AssetManagementService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}

  ngOnInit(): void {
  }

  assetImageForm = this.builder.group({
    id: this.builder.control({value: '', disabled: true}),
    description: this.builder.control(''),
    image: this.builder.control(null, Validators.required),
  })

  saveAssetImage() {

    console.log('Asset ID will show here', this.data.assetId)

    const formData = new FormData();
    formData.append('company_asset_image[description]', this.assetImageForm.get('description')?.value);
    formData.append('company_asset_image[image]', this.assetImageForm.get('image')?.value);
    formData.append('company_asset_image[company_asset]', this.data.assetId);

    this.assetService.saveAssetImage(formData).subscribe({
      next:(res) => {
        alert("Asset Image Created Successfully");
        this.closePopup();
      },
      error:(res) => {
        console.log(res.error.error_message);
        alert(res.error.error_message);
        // alert("Error while creating the Asset");
      }
    })
  }

  closePopup() {
    this.dialog.closeAll();
  }

  onFileChange(event: any) {
    console.log(event.target.files)

    const file = event && event.target.files[0];
    this.assetImageForm.patchValue({
      image: file
    })

    console.log(this.assetImageForm.get('image'));
  }
}
