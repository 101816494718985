import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {SoilSample} from './soil-sample.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SoilSamplesService extends RestApiService<SoilSample> {

  constructor(
    httpClient: HttpClient
  ) {
    super('soil_samples', 'soil_sample', httpClient);
  }
}
