import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ContactsService} from '../contacts.service';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Contact} from '../contact.model';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss']
})
export class ContactSearchComponent implements OnInit {

  contactSearch: FormControl = new FormControl(null);
  contactMatches: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);

  selectedContact: Contact;

  @Output()
  contactSelected = new EventEmitter<Contact>();

  constructor(
    private contactsService: ContactsService
  ) { }

  ngOnInit(): void {
    this.contactSearch.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe((searchTerm) => {
      const search = {
        surname: `*${searchTerm}*`,
        email: `*${searchTerm}*`,
        search_mode: 'or',
      };
      this.contactsService.getRecords({pageNumber: 0, pageSize: 15, search}).subscribe(
        (contacts) => {
          this.contactMatches.next(contacts.records);
        });
    });
  }

  contactChosen(event: MatAutocompleteSelectedEvent) {
    this.selectedContact = event.option.value;
    this.contactSelected.emit(event.option.value);
  }

}
