import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {GrowerContract} from './grower-contract.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GrowerContractFormControlService implements GaiaRecordFormControl<GrowerContract>{

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: GrowerContract): FormGroup {
    return this.formBuilder.group({
      grower_contract_ref: new FormControl(record.grower_contract_ref, Validators.required),
      base_price: new FormControl(record.base_price),
      start_date: new FormControl(record.start_date, Validators.required),
      end_date: new FormControl(record.end_date, Validators.required),
      contract_total_area_ha: new FormControl(record.contract_total_area_ha),
    });
  }
}
