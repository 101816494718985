import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Image} from '../image.model';
import {GaiaRecord} from '../../../models/gaia-record.model';
import {ImagesService} from '../images.service';
import {ImageFormControlService} from '../image-form-control.service';
import {FormGroup} from '@angular/forms';
import {GaiaLogger} from '../../../../utils/common-functions';
import {ProcessingOverlayComponent} from '@terravesta/gaia-shared';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit, OnChanges {

  @Input()
  uploaderTitle: string;

  @Input()
  currentImage: Image;

  @Input()
  record: GaiaRecord;

  @Input()
  imageName: string;

  @ViewChild(ProcessingOverlayComponent) overlay: ProcessingOverlayComponent;

  form: FormGroup;

  imageUrl: string;
  fileType: string;

  constructor(
    private imagesService: ImagesService,
    private imageFormControlService: ImageFormControlService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.form = this.imageFormControlService.newVersionForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentImage) {
      if (changes.currentImage.currentValue) {
        this.imageUrl = this.currentImage.thumbnail_url;
        this.fileType = this.currentImage.file_type;
      }
    }
  }

  upload(): void {
    this.overlay.lock();
    let subscription: Observable<any>;
    if (this.form.valid){
      GaiaLogger.log(this.currentImage);
      if (this.currentImage){
        subscription = this.imagesService.newVersion(this.currentImage, this.form.get('new_version').value);
      } else {
        subscription = this.imagesService.createRecordMultiPart({ data: {
          name: this.imageName, associated_record_id: this.record.id, ...this.form.value
        } as Image});
      }
    }
    subscription.subscribe((response: Image) => {
      this.currentImage = response;
      this.imageUrl = response.current_version.file.thumb.url;
      this.form.reset();
    },
    (errors) => {
      this.overlay.unlock();
      this.form.reset();
      this.matSnackBar.open('The image you have uploaded is not valid, please check its file type');
    },
    () => {
      this.overlay.unlock();
    });
  }


}
