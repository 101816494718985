import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HarvestReviewerComponent} from './harvest-reviewer/harvest-reviewer.component';
import {LegacyFieldHarvestTableComponent} from './legacy-harvests/legacy-field-harvests/legacy-field-harvest-table/legacy-field-harvest-table.component';

export class OperationsRoutes {
  public static BALE_COLLECTIONS = 'bale-collections';
  public static BALE_TYPES = 'bale-types';
  public static HARVEST_REVIEWER = 'harvest-reviewer';
  public static CONTRACTORS = 'contractors';
  public static PROCESSORS = 'processors';
  public static OPERATIONAL_REGIONS = 'operational-regions';
  public static LEGACY_FIELD_HARVESTS = 'legacy-field-harvests';
  public static HARVESTS = 'harvests';
  public static STACKS = 'stacks';
  public static HAULIERS = 'hauliers';
  public static BALER_RECORDS = 'baler-records';
  public static HAULAGE_MATRICES = 'haulage-matrices';
}

const routes: Routes = [
  { path: OperationsRoutes.HARVEST_REVIEWER, component: HarvestReviewerComponent },
  { path: OperationsRoutes.LEGACY_FIELD_HARVESTS, component: LegacyFieldHarvestTableComponent },
  {
    path: OperationsRoutes.CONTRACTORS,
    loadChildren: () => import('./contractors/contractors-routing.module').then(m => m.ContractorsRoutingModule),
  },
  {
    path: OperationsRoutes.STACKS,
    loadChildren: () => import('./miscanthus-stacks/miscanthus-stacks-routing.module').then(m => m.MiscanthusStacksRoutingModule),
  },
  {
    path: OperationsRoutes.HARVESTS,
    loadChildren: () => import('./miscanthus-harvests/miscanthus-harvests-routing.module').then(m => m.MiscanthusHarvestsRoutingModule),
  },
  {
    path: OperationsRoutes.PROCESSORS,
    loadChildren: () => import('./processors/processors-routing.module').then(m => m.ProcessorsRoutingModule),
  },
  {
    path: OperationsRoutes.HAULIERS,
    loadChildren: () => import('./hauliers/hauliers-routing.module').then(m => m.HauliersRoutingModule),
  },
  {
    path: OperationsRoutes.BALER_RECORDS,
    loadChildren: () => import('./baler-records/baler-records-routing.module').then(m => m.BalerRecordsRoutingModule),
  },
  {
    path: OperationsRoutes.BALE_TYPES,
    loadChildren: () => import('./bale-types/bale-types-routing.module').then(m => m.BaleTypesRoutingModule),
  },
  {
    path: OperationsRoutes.OPERATIONAL_REGIONS,
    loadChildren: () => import('./operational-regions/operational-regions-routing.module').then(m => m.OperationalRegionsRoutingModule),
  },
  {
    path: OperationsRoutes.BALE_COLLECTIONS,
    loadChildren: () => import('./bale-collections/bale-collections-routing.module').then(m => m.BaleCollectionsRoutingModule),
  },
  {
    path: OperationsRoutes.HAULAGE_MATRICES,
    loadChildren: () => import('./haulage-matrix/haulage-matrix-routing.module').then(m => m.HaulageMatrixRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule{ }
