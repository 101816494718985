import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {MiscanthusStack} from './miscanthus-stack.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressFormControlService} from '@terravesta/gaia-shared';
import {What3WordsFormControlService} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class MiscanthusStackFormControlService implements GaiaRecordFormControl<MiscanthusStack> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService,
    private what3wordsFormControl: What3WordsFormControlService,
  ) { }

  toFormGroup(record: MiscanthusStack): FormGroup {
    return this.formBuilder.group({
      harvest_id: new FormControl(record.harvest.id, Validators.required),
      stored_date: new FormControl(record.stored_date),
      stack_contact_id: new FormControl(record.stack_contact ? record.stack_contact.id : null, Validators.required),
      schedule_month_id: new FormControl(record.schedule_month ? record.schedule_month.id : null, Validators.required),
      address: this.addressFormControl.toFormControl(record.address, true),
      location_description: new FormControl(record.location_description, Validators.maxLength(250)),
      access_restrictions: new FormControl(record.access_restrictions, Validators.maxLength(250)),
      loader_availability_id: new FormControl(record.loader_availability ? record.loader_availability.id : null,
        Validators.required),
      bale_grab_availability_id: new FormControl(record.bale_grab_availability ? record.bale_grab_availability.id : null,
        Validators.required),
      storage_method_id: new FormControl(record.storage_method ? record.storage_method.id : null,
        Validators.required),
      notice_period_id: new FormControl(record.notice_period ? record.notice_period.id : null,
        Validators.required),
      notes: new FormControl(record.notes, Validators.maxLength(250)),
      what_3_words: this.what3wordsFormControl.toFormGroup(record),
    });
  }
}
