import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RecordedMonthlyFormControlService} from '../recorded-monthly-form-control.service';
import {FormGroup} from '@angular/forms';
import {yearArray} from '../../../../../utils/common-functions';
import {RecordedMonthWindow} from '../recorded-monthly.model';

@Component({
  selector: 'app-record-monthly-period-selector',
  templateUrl: './record-monthly-period-selector.component.html',
  styleUrls: ['./record-monthly-period-selector.component.scss']
})
export class RecordMonthlyPeriodSelectorComponent implements OnInit {

  formGroup: FormGroup;
  years = yearArray(2017);

  @Output()
  changed: EventEmitter<RecordedMonthWindow> = new EventEmitter<RecordedMonthWindow>();

  constructor(private formControl: RecordedMonthlyFormControlService) {
    this.formGroup = this.formControl.periodSelectForm();
    console.log(this.formGroup);
  }

  ngOnInit(): void {
  }

  doChange() {
    this.changed.emit(this.formGroup.value);
  }

}

