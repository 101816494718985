import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SoilMeasurementsService} from '../soil-measurements.service';
import {SoilMeasurementFormControlService} from '../soil-measurement-form-control.service';
import {SoilMeasurement} from '../soil-measurement.model';
import {FormDialogData, FormDialogDirective} from '../../../components/form-dialog.directive';

@Component({
  selector: 'app-soil-measurement-form-dialog',
  templateUrl: './soil-measurement-form-dialog.component.html',
  styleUrls: ['./soil-measurement-form-dialog.component.scss']
})
export class SoilMeasurementFormDialogComponent extends FormDialogDirective<SoilMeasurement> implements OnInit {

  constructor(
    soilSamplesService: SoilMeasurementsService,
    soilSampleFormControlService: SoilMeasurementFormControlService,
    @Inject(MAT_DIALOG_DATA) data: FormDialogData<SoilMeasurement>,
    public dialogRef: MatDialogRef<SoilMeasurementFormDialogComponent>,
  ) {
    super(soilSamplesService, soilSampleFormControlService, dialogRef, data);
  }
}
