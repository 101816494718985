import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Address} from '@terravesta/gaia-shared';
import {EmbedCardDirective} from '../../components/embed-card.directive';
import {Contact} from '../contact.model';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent extends EmbedCardDirective<Contact> implements OnInit {

  @Input()
  canCopy = true;

  @Output()
  copyAddress: EventEmitter<Address> = new EventEmitter<Address>();


  ngOnInit(): void {
  }

}
