
<app-year-selector [startYear]="2017" [endYear]="endYear" [currentYear]="currentYear" labelText="Select Year" (yearChanged)="loadData($event)"></app-year-selector>

<mat-table [dataSource]="hrSummary">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.name }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="leave_allowance">
    <mat-header-cell *matHeaderCellDef i18n>Leave Allowance</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.leave_allowance }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="leave_used">
    <mat-header-cell *matHeaderCellDef i18n>Leave Used</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.leave_used }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="employment_started">
    <mat-header-cell *matHeaderCellDef i18n>Employment Started</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.employment_started | date }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
