import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {AssetImagePopupComponent} from "../asset-image-popup/asset-image-popup.component";
import {Asset, CompanyAssetImage} from "../asset.model";
import {AssetManagementService} from "../asset-management.service";
import {MatTableDataSource} from "@angular/material/table";
import * as alertifyjs from "alertifyjs";
import {FormBuilder, Validators} from "@angular/forms";
import {LoadingService} from "../../loading/loading.service";

@Component({
  selector: 'asset-show',
  templateUrl: './asset-show.component.html',
  styleUrls: ['./asset-show.component.scss']
})

export class AssetShowComponent implements OnInit{
  id: string;
  assetImages: CompanyAssetImage[] = []
  categories: any[] = [];
  makes: any[] = [];
  models: any[] = [];
  tested_by_users: any[] = [];
  store_locations: any[] = [];
  editData: any;
  initialFormState: any;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private assetService: AssetManagementService,
    private builder: FormBuilder,
    private loadingService: LoadingService
    ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
    this.loadAssetImages();


    // const allCategories$ =  this.assetService.loadAssetCategories();
    const allCategories$ =  this.assetService.loadAssetDependencies();
    this.loadingService.showLoaderUntilCompleted(allCategories$).subscribe({
      next:(res) => {
        this.categories = res["categories"];
        this.makes = res["makes"];
        this.models = res["models"];
        this.tested_by_users = res["tested_by_users"];
        this.store_locations = res["store_locations"];
        console.log(this.categories);
      },
      error: () => {
        alert("Error while fetching asset categories")
      }
    })

    const findAsset$ = this.assetService.findAsset(this.id)
    this.loadingService.showLoaderUntilCompleted(findAsset$).subscribe(res => {
      this.editData = res;
      console.log('this.editData', this.editData)
      this.assetForm.setValue({
        id: this.editData.id,
        name: this.editData.name,
        category: this.editData.category_id,
        status: this.editData.status,
        purchase_price: this.editData.purchase_price,
        estimated_value: this.editData.estimated_value,
        purchase_date: this.editData.purchase_date,
        make: this.editData.make_id,
        model: this.editData.model_id,
        tested_by_staffmember_id: this.editData.tested_by_staffmember_id,
        store_location: this.editData.store_location_id,
        terravesta_reference: this.editData.terravesta_reference,
        notes: this.editData.notes
      });

      // Set the initial form state after setting the form values
      setTimeout(() => {
        this.initialFormState = JSON.parse(JSON.stringify(this.assetForm.getRawValue()));
      });
    })

  }

  assetForm = this.builder.group({
    id: this.builder.control({value: '', disabled: true}),
    name: this.builder.control('', Validators.required),
    category: this.builder.control('', Validators.required),
    status: this.builder.control('', Validators.required),
    purchase_price: this.builder.control(''),
    estimated_value: this.builder.control(''),
    purchase_date: this.builder.control(''),
    make: this.builder.control('', Validators.required),
    model: this.builder.control('', Validators.required),
    tested_by_staffmember_id: this.builder.control(''),
    store_location: this.builder.control(''),
    terravesta_reference: this.builder.control({value: 'TV-', disabled: false}),
    notes: this.builder.control('')
  })

  hasChanges(): boolean {
    return JSON.stringify(this.initialFormState) !== JSON.stringify(this.assetForm.getRawValue());
  }

  openPopup(id: any) {
    const _popup = this.dialog.open(AssetImagePopupComponent,{
      width: '800px',
      data: {
        id: id,
        assetId: this.id
      }
    })

    _popup.afterClosed().subscribe(res => {
      this.loadAssetImages();
    })

  }

  loadAssetImages() {
    this.assetService.loadAssetImages(this.id).subscribe({
      next:(res) => {
        this.assetImages = res['asset_images'];
        console.log(this.assetImages)
      },
      error: () => {
        alert("Error while fetching assets")
      }
    })
  }

  deleteAssetImage(id: any) {
    alertifyjs.confirm("Remove Asset Image", "Do you want to remove this image?", () => {
      this.assetService.deleteAssetImage(id).subscribe({
        next:() => {
          alertifyjs.success("Image deleted successfully");
          this.loadAssetImages();
        },
        error:() => {
          alert("Error while deleting the image")
        }
      })
    }, function (){

    })
  }

  onSelectionCategoryChange(selectedCtegoryId: any) {
    // const selected = this.categories.find(category => category.id === selectedCtegoryId)
    // this.assetForm.get('terravesta_reference').setValue(`TV-${selected.sort_code}`);

    let entryType = '';
    let assetId = '';
    entryType = 'edit';
    assetId = this.id;

    // alert(entryType);
    this.assetService.get_terravesta_reference(entryType, selectedCtegoryId, assetId).subscribe({
      next:(res) => {
        this.assetForm.get('terravesta_reference').setValue(res["terravesta_reference"]);
      },
      error:() => {
        alert("Error fetching terravesta reference.");
      }
    })
  }

  saveAsset() {
    if(this.assetForm.valid) {
      const editId = this.id;

      // if (editId != '' && editId != null) {
        this.assetService.updateAsset(editId, this.assetForm.value).subscribe({
          next:() => {
            alert("Asset updated successfully");
            // this.closePopup();
            // Set the initial form state after setting the form values
            setTimeout(() => {
              this.initialFormState = JSON.parse(JSON.stringify(this.assetForm.getRawValue()));
            });
          },
          error:(res) => {
            console.log(res.error.error_message);
            alert(res.error.error_message);
          }
        })
      // }
    } else {
      alert("Fill up all required fields with valid data.")
    }
  }
}
