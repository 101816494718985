
<mat-card>
  <mat-card-header>
    <mat-card-title i18n="My dashboard title">My Dashboard</mat-card-title>
    <mat-card-subtitle *ngIf="dashboardChanged">Unsaved Changes</mat-card-subtitle>
    <div fxFlex="1 1 auto" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <button mat-mini-fab (click)="addWidget()">
        <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
      </button>
      <button mat-mini-fab (click)="editBoardLayout()" [disabled]="editMode">
        <mat-icon>{{ gaiaIconSet.edit }}</mat-icon>
      </button>
      <button mat-mini-fab (click)="saveDashboard()" [disabled]="!dashboardChanged" matTooltip="Save dashboard settings">
        <mat-icon>{{ gaiaIconSet.save }}</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>

    <div #container class="dashboard-container">
      <ng-template appDashboardContainer>

      </ng-template>
    </div>

  </mat-card-content>
</mat-card>
