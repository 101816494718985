import { Component, OnInit } from '@angular/core';
import {ContractedFieldsService} from '../contracted-fields.service';
import {KMLIntersectionResult} from '../contracted-field.model';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-contracted-field-finder',
  templateUrl: './contracted-field-finder.component.html',
  styleUrls: ['./contracted-field-finder.component.scss']
})
export class ContractedFieldFinderComponent implements OnInit {

  matches: Array<KMLIntersectionResult> = new Array<KMLIntersectionResult>();

  constructor(
    private contractedFieldsService: ContractedFieldsService,
  ) { }

  ngOnInit(): void {
  }

  uploadMap(files: FileList) {
    this.contractedFieldsService.kmlIntersection(files.item(0)).subscribe(
      (response) => {
        GaiaLogger.log(response);
        this.matches = response;
      }
    );
  }
}
