
<app-badge-panel [record]="record" [drawBackground]="drawBackground">
  <app-record-badge *ngIf="record.in_contract" i18n-label label="Contracted Ha">{{ record.current_contract_total_ha | number : '1.0-2'}}</app-record-badge>
  <app-record-badge *ngIf="record.all_own_use" i18n-label label="Own Use Grower">OU</app-record-badge>
  <app-record-badge *ngIf="record.replanting_any" i18n-label label="FOC Replanting">FR</app-record-badge>

  <app-record-badge *ngIf="record.has_warnings" i18n-label label="Warnings">
    <mat-icon>{{ gaiaIconSet.warning }}</mat-icon>
  </app-record-badge>
</app-badge-panel>
