import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrdersModule} from './orders/orders.module';
import {ProductsModule} from './products/products.module';
import {PriceGroupsModule} from './price-groups/price-groups.module';
import {StockistsModule} from './stockists/stockists.module';
import {BrandAmbassadorsModule} from './brand-ambassadors/brand-ambassadors.module';
import {CommercialUsersModule} from './commercial-users/commercial-users.module';
import { CommercialSalesMenuComponent } from './commercial-sales-menu/commercial-sales-menu.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    CommercialSalesMenuComponent
  ],
  imports: [
    CommonModule,
    OrdersModule,
    ProductsModule,
    PriceGroupsModule,
    StockistsModule,
    CommonModule,
    BrandAmbassadorsModule,
    CommercialUsersModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule
  ],
  exports: [
    CommercialSalesMenuComponent
  ],
  providers: []
})
export class CommercialSalesModule { }
