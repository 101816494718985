import {PhanesLoggerService} from '@terravesta/phanes';
import moment from 'moment';


export function dateTimeToFormControl(date: Date): string{
  return date.toISOString().slice(0, 16);
}

export function processAPIDateTime(dateString: string|Date): string {
  if (dateString) {
    return new Date(dateString).toISOString().slice(0, 16);
  }else{
    return '';
  }
}

export function dateToString(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function dateToQueryString(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function stripHTMLTags(htmlString: string): string {
  return htmlString.replace(/(<([^>]+)>)/gi, '');
}

export function yearArray(start: number, end: number = moment().year()){
  const years = new Array<number>();
  for (let i = start; i <= end; i++){
    years.push(i);
  }
  return years;
}

export function isToday(someDate): boolean {
  GaiaLogger.log(someDate);
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
}

export function getHarvestYear() {
  const today = new Date();
  let year;
  if (today.getMonth() < 3) {
    year = today.getFullYear() - 1;
  } else {
    year = today.getFullYear();
  }
  return year;
}

export function validateFloNumber(floNumberString: String) {
  const matches = floNumberString.match(/\d+v?\d+/g)
  let floNumber = null;
  if(matches && matches.length > 0) {
    floNumber = matches[0];
  }
  return floNumber;
}

export const GaiaLogger = new PhanesLoggerService();

export function navigateToPointUrl(latitude, longitude){
  return `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${latitude},${longitude}`;
}

export function navigateToPostcode(postcode){
  return `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${postcode}`;
}
