import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {Image} from './image.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ImageFormControlService implements GaiaRecordFormControl<Image> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: Image): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(record.name),
      description: new FormControl(record.description),
      public: new FormControl(record.public),
    });
  }

  newVersionForm(): FormGroup {
    return this.formBuilder.group({
      new_version: new FormControl(null, Validators.required),
    });
  }
}
