import { Component, OnInit } from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {AppRoutes} from '../../app-routing.module';
import {SystemResourcesRoutes} from '../system-resources-routing.module';
import {SentinelRoutes} from '../sentinel/sentinel-routing.module';
import {SystemAdminRoutes} from '../../system-admin/system-admin-routing.module';

@Component({
  selector: 'app-system-resources-menu',
  templateUrl: './system-resources-menu.component.html',
  styleUrls: ['./system-resources-menu.component.scss']
})
export class SystemResourcesMenuComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;
  systemResourcesRoutes = SystemResourcesRoutes;
  sentinelRoutes = SentinelRoutes;
  systemAdminRoutes = SystemAdminRoutes;

  constructor() { }

  ngOnInit(): void {
  }

}
