import {Component} from '@angular/core';
import {Product} from '../product.model';
import {ProductsService} from '../products.service';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CommercialSalesRoutes} from '../../commercial-sales-routing.module';

@Component({
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss']
})
export class ProductTableComponent extends IndexTableDirective<Product> {

  commercialSalesRoutes = CommercialSalesRoutes;
  displayedColumns = ['name', 'sku', 'cost_price'];

  constructor(
    productsService: ProductsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog
  ) {
    super(productsService, router, activatedRoute, matDialog, null);
  }

}
