<ng-container *ngIf="form">
  <ng-container [formGroup]="form">

    <mat-card>
      <mat-card-header>
        <mat-card-title i18n>Bale Loss</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>These bales will be assumed to be {{stack.bale_type.name}}</p>

        <div fxLayout="column" fxLayoutGap="15px">
          <mat-form-field>
            <mat-label i18n>Number of bales lost</mat-label>
            <input matInput formControlName="bales_lost" placeholder="" type="number" min="0" />
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n>Reason for loss</mat-label>
            <textarea matInput formControlName="reason" placeholder=""></textarea>
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n>Date of loss</mat-label>
            <input matInput formControlName="date_of_loss" placeholder="" type="date" />
          </mat-form-field>

        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>
