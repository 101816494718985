<app-new-record-dialog [form]="form" dialogTitle="Remove Bales from Stack" i18n-dialogTitle (saveClicked)="save()" [dialogRef]="matDialog">

  <phanes-error-panel></phanes-error-panel>

  <ng-container *ngIf="form" [formGroup]="form">

    <app-bale-loss-form [form]="form" [stack]="data.parent"></app-bale-loss-form>

  </ng-container>
</app-new-record-dialog>
