<mat-form-field>
  <mat-label i18n style="display: flex; font-size: medium">Meteorological Year </mat-label>
  <mat-select [(ngModel)]="selectedYear" (selectionChange)="loadMetClimateData();">
    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
  </mat-select>
</mat-form-field>

<div fxLayout="row wrap" fxLayoutGap="15px" fxLayoutAlign="start stretch">
  <div fxFlex="calc(50% - 20px)"  id="met30YrClimateCard">
    <phanes-climate-data-source-graph #averageData defaultDataSourceKey="uk-1km-30yr-avg" [queryData]="queryData"
                                      (rangeChange)="averageRangeChange($event)"></phanes-climate-data-source-graph>
  </div>
  <div fxFlex="calc(50% - 20px)" id="climateCard">
    <phanes-climate-data-source-graph #yearlyData defaultDataSourceKey="uk-1km-grid" [queryData]="queryData"
                                      (rangeChange)="yearlyRangeChange($event)"
      (gridPosition)="gridLocation.emit($event)"></phanes-climate-data-source-graph>
  </div>
</div>

