import { Component, OnInit } from '@angular/core';
import {IndexTableDirective} from '../../../../components/index-table.directive';
import {LegacyContractedFieldHarvest} from '../legacy-field-harvest.model';
import {MatDialog} from '@angular/material/dialog';
import {LegacyFieldHarvestsService} from '../legacy-field-harvests.service';
import {FormControl} from '@angular/forms';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-legacy-field-harvest-table',
  templateUrl: './legacy-field-harvest-table.component.html',
  styleUrls: ['./legacy-field-harvest-table.component.scss']
})
export class LegacyFieldHarvestTableComponent extends IndexTableDirective<LegacyContractedFieldHarvest> implements OnInit {
  displayedColumns = ['grower_code', 'field_name', 'planted', 'checked', 'bale_count', 'area_harvested', 'act_tonnage_per_ha', 'variety'];

  varietyFilter: FormControl = new FormControl();
  checkedFilter: FormControl = new FormControl();
  gaiaIconSet = GaiaIconSet;

  constructor(
    legacyFieldHarvestsService: LegacyFieldHarvestsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(legacyFieldHarvestsService, router, activatedRoute, matDialog, null);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  searchOpts(): any {
    return {
      harvest: {
        ops_checked: this.checkedFilter.value ? true : null,
      },
      field: {
        planted_variety: {
          uuid: this.varietyFilter.value,
        }
      }
    };
  }
}

