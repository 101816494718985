import {Component, Inject} from '@angular/core';
import {NewDialogData, NewDialogDirective} from '../../../../components/new-dialog.directive';
import {TermContract} from '../term-contract.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../../app-routing.module';
import {GrowingRoutes} from '../../../growing-routing.module';
import {ContractRoutes} from '../../contracts-routing.module';
import {TermContractsService} from '../term-contracts.service';
import {TermContractFormControlService} from '../term-contract-form-control.service';

@Component({
  selector: 'app-term-contract-new-dialog',
  templateUrl: './term-contract-new-dialog.component.html',
  styleUrls: ['./term-contract-new-dialog.component.scss']
})
export class TermContractNewDialogComponent extends NewDialogDirective<TermContract> {

  constructor(
    termContractService: TermContractsService,
    termContractFormControl: TermContractFormControlService,
    @Inject(MAT_DIALOG_DATA) data: NewDialogData<TermContract>,
    public matDialog: MatDialogRef<TermContractNewDialogComponent>,
    private router: Router,
  ) {
    super(termContractService, termContractFormControl, data);
  }

  successfulSave(result: TermContract) {
    this.router.navigate([AppRoutes.GROWING, GrowingRoutes.CONTRACTS, ContractRoutes.TERM_CONTRACTS, result.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
