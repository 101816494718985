<a mat-flat-button aria-hidden="false" aria-label="Operations" color="primary" (click)="opsMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.operations }}</mat-icon>
  <div #opsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="opsMenu"></div>
</a>
<mat-menu #opsMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.HARVEST_REVIEWER]" i18n="harvest review tool">
    <mat-icon>{{ gaiaIcons.harvest_review_tool }}</mat-icon>
    Harvest Review Tool
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.STACKS, stackRoutes.DAILY_OPS]" i18n="stacks">
    <mat-icon>{{ gaiaIcons.miscanthus_stack }}</mat-icon>
    Stacks
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.HARVESTS]" i18n="harvests">
    <mat-icon>{{ gaiaIcons.miscanthus_harvest }}</mat-icon>
    Harvests
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.BALE_COLLECTIONS]" i18n="collections">
    <mat-icon>{{ gaiaIcons.bale_collections }}</mat-icon>
    Collections
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.CONTRACTORS]" i18n="Contractors list">
    <mat-icon>{{ gaiaIcons.contractors }}</mat-icon>
    Contractors
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.BALER_RECORDS]" i18n="contractor declarations">
    <mat-icon>{{ gaiaIcons.baler_records }}</mat-icon>
    Contractor Declarations
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.PROCESSORS]" i18n="Processors list">
    <mat-icon>{{ gaiaIcons.processors }}</mat-icon>
    Processors
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.HAULIERS]" i18n="Hauliers list">
    <mat-icon>{{ gaiaIcons.hauliers }}</mat-icon>
    Hauliers
  </a>

  <div mat-menu-item [matMenuTriggerFor]="opsSettingsMenu">
    <mat-icon>{{ gaiaIcons.settings }}</mat-icon>
    <span i18n>Settings</span>
  </div>
</mat-menu>

<mat-menu #opsSettingsMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.BALE_TYPES]" i18n="Bale types list">
    Bale Types
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.OPERATIONAL_REGIONS]" i18n="Operational regions list">
    Operational Regions
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.OPERATIONS, operationRoutes.STACKS, stackRoutes.SETTINGS]" i18n="Stack Settings">
    Stack Settings
  </a>
</mat-menu>
