<div fxLayout="column">
  <div fxLayout="row">

    <mat-form-field>
      <mat-label>Post Code Region</mat-label>
      <input matInput [formControl]="control" />
    </mat-form-field>

    <div>
      <button mat-raised-button (click)="getData()">Get Data</button>
    </div>
  </div>
  <ng-container *ngIf="currentReport">
    <mat-table [dataSource]="currentReport.plantings">
      <ng-container matColumnDef="year_planted">
        <mat-header-cell *matHeaderCellDef i18n>Year Planted</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.planting_year }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_area">
        <mat-header-cell *matHeaderCellDef i18n>Total Ha</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.total_planted_area_ha }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
  </ng-container>

</div>
