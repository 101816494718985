import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {BrandAmbassador} from './brand-ambassador.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrandAmbassadorsService extends RestApiService<BrandAmbassador> {

  constructor(
    httpClient: HttpClient
  ) {
    super('/brand_ambassadors', 'brand_ambassador', httpClient);
  }
}
