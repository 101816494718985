import { Component, } from '@angular/core';
import {ForApprovalTableDirective} from '../../team-space-concerns/requires-approval/for-approval-table.directive';
import {StaffExpenseClaim} from '../staff-expense-claim.model';
import {StaffExpenseClaimsService} from '../staff-expense-claims.service';
import {MatDialog} from '@angular/material/dialog';
import {
  StaffExpenseClaimsFormDialogComponent
} from '../staff-expense-claims-form-dialog/staff-expense-claims-form-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-staff-expense-claims-approval-table',
  templateUrl: './staff-expense-claims-approval-table.component.html',
  styleUrls: ['./staff-expense-claims-approval-table.component.scss']
})
export class StaffExpenseClaimsApprovalTableComponent extends ForApprovalTableDirective<StaffExpenseClaim> {
  displayedColumns = ['unique_id', 'claimant', 'details', 'claimed_amount', 'menu'];

  constructor(
    staffExpenseClaimsService: StaffExpenseClaimsService,
    matDialog: MatDialog,
    snackBar: MatSnackBar,
  ) {
    super(staffExpenseClaimsService, matDialog, snackBar, StaffExpenseClaimsFormDialogComponent);
  }

}
