<div fxFlexLayout="row" style="display: flex; justify-content: flex-start; margin-bottom: 5px;">
  <div class="search" fxLayout="row" fxLayoutAlign="start center">
    <!-- <mat-form-field appearance="outline">
       <mat-label i18n>Enter a date range</mat-label>
       <mat-date-range-input [rangePicker]="picker">
         <input matStartDate placeholder="Start date" [(ngModel)]="searchStartDate">
         <input matEndDate placeholder="End date" [(ngModel)]="searchEndDate">
       </mat-date-range-input>
       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
       <mat-date-range-picker #picker></mat-date-range-picker>
     </mat-form-field>-->
    <mat-form-field id="plantingYear" [formGroup]="plantingYearForm" appearance="outline">
      <mat-label i18n>Planting Year</mat-label>
      <mat-select formControlName="year_planted" required>
        <mat-option *ngFor="let date of this.plantingYear" [value]="date">
          {{ date }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <gaia-shared-spinner-loading-button id="search" #searchButton (clickOccurred)="doSearch()" color="primary" style="margin-left: 5px;">
      <mat-icon>search</mat-icon>
      <span>Search</span>
    </gaia-shared-spinner-loading-button>
    <gaia-shared-spinner-loading-button #kmlDownload (clickOccurred)="downloadKml()" color="primary" style="margin-left: 5px;">
      <mat-icon>{{ gaiaIconSet.download }}</mat-icon>
      <span i18n="download drone kml. Download signified by icon">Drone KML</span>
    </gaia-shared-spinner-loading-button>
    <button mat-raised-button class="copy-to-clipboard" *ngIf="copyIdBtn" color="primary" style="margin-left: 5px;" (click)="copyIdToClipboard()">
      <mat-icon>{{ gaiaIconSet.copy }}</mat-icon>
      Copy ID
    </button>
    <button mat-raised-button class="copy-to-clipboard" *ngIf="copyIdBtn" color="primary" style="margin-left: 5px;" (click)="copyMbIdToClipboard()">
      <mat-icon>{{ gaiaIconSet.copy }}</mat-icon>
      Copy MB ID
    </button>
    <ng-container *ngIf="!releasedToHH; else textBlock">
      <gaia-shared-spinner-loading-button #addHHButton (clickOccurred)="releaseToHH()" *ngIf="releaseBtn" color="primary" style="margin-left: 5px;">
        <mat-icon>{{ gaiaIconSet.planting }}</mat-icon>
        <span>Add to HH</span>
      </gaia-shared-spinner-loading-button>
    </ng-container>

    <ng-template #textBlock>
      <button mat-stroked-button disabled color="primary" style="margin-left: 5px;justify-content: flex-end">
        Released by {{this.selectedImage.released_by.first_name}} on {{this.selectedImage.released_to_hh_at|date}}</button>
     <!-- <button mat-stroked-button disabled style="margin-left: 5px;">Released</button>-->
    </ng-template>
  </div>

</div>


<div fxLayout="row" fxLayoutGap="10px">

  <div class="selector">
    <div appScrollableTable [maxHeight]="'85vh'" *ngIf="showTable">
      <mat-table  id="droneTable" [dataSource]="dataSource">

        <ng-container matColumnDef="field">
          <mat-header-cell *matHeaderCellDef i18n>Field</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button color="accent" (click)="showDroneImage(element)">
              {{ element.contracted_field.name }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="grower">
          <mat-header-cell *matHeaderCellDef i18n>Grower</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button color="accent" (click)="showDroneImage(element)">
              {{ element.grower.grower_code }}
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date_taken">
          <mat-header-cell *matHeaderCellDef i18n>Date Taken</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button color="accent" (click)="showDroneImage(element)">
              {{ element.date_taken | date: 'shortDate'}}
            </button>
          </mat-cell>
        </ng-container>

       <!-- <ng-container matColumnDef="year_planted">
          <mat-header-cell *matHeaderCellDef i18n>Planting Year</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button color="accent" (click)="showDroneImage(element)">
              {{ element.contracted_field.year_planted}}
            </button>
          </mat-cell>
        </ng-container>-->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>
    </div>

    <mat-paginator [length]="dataSource.totalRecords" [pageSize]="30"
                   [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
  </div>

  <mat-card class="form-card" fxFlex="1 1 auto" fxLayout="column" >
    <mgl-map
      [style]="'mapbox://styles/michaelsterravesta/cleo7aas3002p01mrbmj843vt'"
      [zoom]="[15]"
      [center]="mapCenter">
      <mgl-raster-source *ngIf="urlString" id="droneImage" [url]="urlString"></mgl-raster-source>
      <mgl-layer *ngIf="urlString" id="droneLayer" type="raster" source="droneImage" ></mgl-layer>
      <mgl-control mglNavigation></mgl-control>
    </mgl-map>
  </mat-card>
</div>
