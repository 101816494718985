import {Component, Inject, OnInit} from '@angular/core';
import {NewDialogData, NewDialogDirective} from '../../../components/new-dialog.directive';
import {ContractedField} from '../contracted-field.model';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ContractedFieldFormControlService} from '../contracted-field-form-control.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GrowingRoutes} from '../../growing-routing.module';
import {AppRoutes} from '../../../app-routing.module';

@Component({
  selector: 'app-contracted-field-new-dialog',
  templateUrl: './contracted-field-new-dialog.component.html',
  styleUrls: ['./contracted-field-new-dialog.component.scss']
})
export class ContractedFieldNewDialogComponent extends NewDialogDirective<ContractedField> implements OnInit{

  constructor(
    contractedFieldsService: ContractedFieldsService,
    contractedFieldFormControl: ContractedFieldFormControlService,
    @Inject(MAT_DIALOG_DATA) data: NewDialogData<ContractedField>,
    private router: Router,
    public dialogRef: MatDialogRef<ContractedFieldNewDialogComponent>,
  ) {
    super(contractedFieldsService, contractedFieldFormControl, data);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  successfulSave(result: ContractedField) {
    this.router.navigate([AppRoutes.GROWING, GrowingRoutes.CONTRACTED_FIELDS, result.id]).then((x) => {
      this.dialogRef.close();
    });
  }
}
