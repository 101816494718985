<app-new-record-dialog [form]="form" dialogTitle="Expense Claim" (saveClicked)="save()" [dialogRef]="matDialog">

  <ng-container *ngIf="form" [formGroup]="form">

    <phanes-error-panel></phanes-error-panel>

    <div fxLayout="column">
      <mat-form-field>
        <mat-label i18n>Date Of Claim</mat-label>
        <input matInput type="date" [formControlName]="'date_of_claim'" />
      </mat-form-field>

      <app-staff-expense-category-select [fieldName]="'category_id'" [form]="form"></app-staff-expense-category-select>

      <mat-form-field>
        <mat-label i18n>Claimed Amount</mat-label>
        <input matInput type="number" [formControlName]="'claimed_amount'" step="0.01" />
      </mat-form-field>

      <app-currency-select [fieldName]="'currency'" [form]="form"></app-currency-select>

      <mat-form-field>
        <mat-label i18n>Details</mat-label>
        <input matInput type="text" [formControlName]="'details'" />
      </mat-form-field>
    </div>

  </ng-container>
</app-new-record-dialog>
