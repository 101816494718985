export const GaiaIconSet = {
  attachment: 'attach_file',
  my_location: 'my_location',
  document: 'description',
  back: 'keyboard_return',
  person: 'person',
  map: 'map',
  table: 'table_chart',
  check: 'check',
  add: 'add',
  new: 'add',
  minus: 'remove_circle_outline',
  edit: 'edit',
  help: 'help',
  menu: 'more_vert',
  delete: 'delete',
  logout: 'logout',
  search: 'search',
  phone: 'phone',
  email: 'email',
  sms: 'sms',
  locked: 'lock',
  unlock: 'lock_open',
  flag: 'flag',
  pricing: 'payments',
  summary: 'summarize',
  pin: 'push_pin',
  readMore: 'read_more',
  readLess: 'vertical_align_top',
  download: 'cloud_download',
  copy: 'content_copy',
  job: 'work',
  human_resources: 'manage_accounts',
  view: 'preview',
  unlink: 'link_off',
  qr_code: 'qr_code',
  print: 'print',
  save: 'save',
  drag: 'drag_indicator',
  warning: 'warning',
  cancel: 'cancel',
  refresh: 'refresh',
  up: 'arrow_upward',
  down: 'arrow_downward',
  calculate: 'calculate',
  link: 'link',

  approve: 'thumb_up_off',
  reject: 'thumb_down_off',

  settings: 'settings',
  verified: 'verified',

  // Map Statuses
  map_status_polygon: 'hexagon',
  map_status_pin: 'push_pin',
  map_status_not: 'block',

  directory: 'recent_actors',

  revoke_access: 'person_remove',
  grant_access: 'person_add',
  resend_invite: 'forward_to_inbox',
  invite_pending: 'pending',
  promote_to_admin: 'thumb_up',
  demote_admin: 'thumb_down',
  miscanthus_stack: 'table_rows',
  hauliers: 'local_shipping',
  miscanthus_harvest: 'home_work',
  baler_records: 'list_alt',
  bale_collections: 'moving',
  soil_samples: 'cleaning_services',

  hide_side_column: 'chevron_right',
  show_side_column: 'chevron_left',
  hide_menu: 'keyboard_backspace',
  stockists: 'storefront',
  companies: 'domain',
  products: 'shopping_bag',
  orders: 'shopping_cart',
  price_groups: 'money',
  commercial_sales: 'store',
  home: 'home',
  management: 'business',
  growers: 'cottage',
  contacts: 'people',
  contact: 'account_circle',
  brand_ambassadors: 'face',
  commercial_users: 'business_center',
  operations: 'local_shipping',
  drone_images: 'satellite',
  planting: 'grass',
  growing: 'grass',
  harvest_review_tool: 'grading',
  contracted_fields: 'grass',
  gps_map: 'map',
  grower_contracts: 'note',
  team_space: 'work',
  my_record: 'account_circle',
  absences: 'wine_bar',
  miscanthus_variety: 'grass',
  company_car: 'drive_eta',
  expense_claims: 'money',
  mileage_claims: 'drive_eta',
  appraisals: 'star_outline',
  contractors: 'agriculture',
  active: 'check_circle',
  processors: 'fireplace',
  notes: 'mode_comment',
  note: 'sticky_note_2',
  parcel_finder: 'image_search',
  planting_opportunity: 'receipt',
  sentinel_satellite: 'satellite',
  offices: 'apartment',
  measurement: 'monitoring',

  field_size: 'fit_screen',

  assign_to_contract: 'assignment_returned',
  remove_from_contract: 'assignment_late',

  // Contracts
  contracts: 'receipt_long',
  invoice: 'receipt_long',

  // Marketing

  marketing: 'web',
  feature_banners: 'ad_units',

  system_admin: 'admin_panel_settings',
  systemResource: 'category',
  images: 'image',
  event: 'event',

  // Field Specific
  remove_crop: 'arrow_upward',
  outgoing_mail: 'outgoing_mail',

  // assets management
  assets: 'waves',

  // Leads
  leads: 'filter_alt'
};
