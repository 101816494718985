import {Component, Input, OnInit} from '@angular/core';
import {ProcessorDeliverySitesService} from '../processor-delivery-sites.service';
import {ProcessorDeliverySite} from '../processor-delivery-site.model';
import {Processor} from '../../processor.model';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {ProcessorDeliverySiteFormDialogComponent} from '../processor-delivery-site-form-dialog/processor-delivery-site-form-dialog.component';
import {FormDialogData} from '../../../../components/form-dialog.directive';

@Component({
  selector: 'app-processor-delivery-sites-table',
  templateUrl: './processor-delivery-sites-table.component.html',
  styleUrls: ['./processor-delivery-sites-table.component.scss']
})
export class ProcessorDeliverySitesTableComponent implements OnInit {

  @Input()
  processor: Processor;

  gaiaIconSet = GaiaIconSet;

  processorDeliverySites = new Array<ProcessorDeliverySite>();
  displayedColumns = ['name', 'post_code'];

  constructor(
    private processorDeliverySiteService: ProcessorDeliverySitesService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadSites();
  }

  loadSites() {
    // TODO move this to a datasource controller
    this.processorDeliverySiteService.getRecords(this.getCurrentSearch()).subscribe(
      (response) => {
        this.processorDeliverySites = response.records;
      }
    );
  }

  getCurrentSearch() {
    return {
      pageNumber: 0,
      pageSize: 30,
      search: {
        processor: {
          uuid: this.processor.id
        }
      }
    };
  }

  newDeliverySite() {
    this.openSiteDialog({
      processor: this.processor,
    } as ProcessorDeliverySite);
  }

  editSite(site: ProcessorDeliverySite) {
    this.openSiteDialog(site);
  }

  private openSiteDialog(site: ProcessorDeliverySite){
    const dialogRef = this.matDialog.open(ProcessorDeliverySiteFormDialogComponent,
      {
        data: {
          record: site,
        } as FormDialogData<ProcessorDeliverySite>
      });
    dialogRef.afterClosed().subscribe(() => {
      this.loadSites();
    });
  }
}
