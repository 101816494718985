import {Component} from '@angular/core';
import {CompaniesService} from '../companies.service';
import {Company} from '../company.model';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MatDialog} from '@angular/material/dialog';
import {CompanyNewDialogComponent} from '../company-new-dialog/company-new-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {BusinessSettingsRoutes} from '../../business-settings-routing.module';

@Component({
  selector: 'app-companies-table',
  templateUrl: './companies-table.component.html',
  styleUrls: ['./companies-table.component.scss']
})
export class CompaniesTableComponent extends IndexTableDirective<Company> {

  displayedColumns = ['name', 'vat_number', 'use_for_orders'];
  businessRoutes = BusinessSettingsRoutes;

  constructor(
    companiesService: CompaniesService,
    router: Router,
    activatedRoute: ActivatedRoute,
    public matDialog: MatDialog,
  ) {
    super(companiesService, router, activatedRoute, matDialog, CompanyNewDialogComponent, null,
          ['name']);
  }

}
