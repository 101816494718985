<div class="leadOverviewPage">
  <h1 class="page-title" style="text-align: center">Lead Overview Dashboard </h1>

  <div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="start stretch">
    <div fxFlex="calc(50% - 20px)">
      <mat-card>
        <mat-card-header>
          <mat-icon mat-card-avatar>{{gaiaIconSet.contact}}</mat-icon>
          <mat-card-title>Contact Information</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <ng-container *ngIf="currentContact">
            <p><mat-icon>{{ gaiaIconSet.contact }}</mat-icon> Name</p>
            <p class="card-title">{{currentContact.first_name + " "+ currentContact.surname}}
            </p>
            <p><mat-icon>{{ gaiaIconSet.email }}</mat-icon> Email</p>
            <p class="card-title">{{currentContact.email}}
            </p>
            <p><mat-icon>{{ gaiaIconSet.phone }}</mat-icon> Mobile</p>
            <p class="card-title">{{currentContact.mobile}}
            </p>
          </ng-container>

          <ng-container *ngIf="currentAddress">
            <p><mat-icon>{{ gaiaIconSet.home }}</mat-icon> Address</p>
            <p class="card-title">{{currentAddress.result.admin_district+" "+currentAddress.result.region+" - "+currentAddress.result.postcode}}
            </p>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>

    <div fxFlex="calc(50% - 20px)" >
      <mat-card>
        <mat-card-header>
          <mat-card-title>Nearest Processors</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-table [dataSource]="distanceMatrix">

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.destination.name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="kilometer">
              <mat-header-cell *matHeaderCellDef> Kms </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.kilometer | number : '1.0-0'}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="miles">
              <mat-header-cell *matHeaderCellDef> Miles </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.miles | number : '1.0-0'}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <div fxLayout="row"  fxLayoutGap="20px">
    <mat-card fxFlex="100%">
      <mat-card-header>
        <mat-card-title>Processors and Fields</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <phanes-google-map width="100%" preferPolygon="true" [mapRecordsItems]="fieldRecords" [mapPoints]="mapPoints"></phanes-google-map>
        <form (submit)="$event.preventDefault()">
          <mat-form-field appearance="fill">
            <mat-label>Radius (miles)</mat-label>
            <input id="radius" type="number" matInput [value]="miles">
          </mat-form-field>
          <button mat-raised-button (click)="onClick()">Update</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>

</div>







