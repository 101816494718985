<div class="menu-wrapper">
  <div class="default-buttons">
    <div class="left-side">
      <button class="save-btn" mat-raised-button color="primary" type="submit" [disabled]="!canSave" (click)="saveBtClicked()" i18n>Save</button>
    </div>
    <div class="right-side">
      <button *ngIf="allowDelete" class="save-btn" mat-raised-button color="warn" type="submit" (click)="deleteBtnClicked()">
        <mat-icon>{{ gaiaIconSet.delete }}</mat-icon>
        <span i18n="delete button">Delete</span>
      </button>
    </div>
  </div>
  <app-button-row-container>
    <ng-content>
    </ng-content>
  </app-button-row-container>
</div>
