import {Component} from '@angular/core';
import {BadgePanelDirective} from '../../../components/badge-panel.directive';
import {ContractedField} from '../contracted-field.model';

@Component({
  selector: 'app-contracted-field-badge-panel',
  templateUrl: './contracted-field-badge-panel.component.html',
  styleUrls: ['./contracted-field-badge-panel.component.scss']
})
export class ContractedFieldBadgePanelComponent extends BadgePanelDirective<ContractedField> {

  mapToolTip = '';

  recordChanged() {
    switch (this.record.map_status){
      case 'polygon':
        this.mapToolTip = 'Fully Mapped';
        break;
      case 'pin_only':
        this.mapToolTip = 'No boundary, only pin';
        break;
      case 'unmapped':
        this.mapToolTip = 'No mapping data';
        break;
    }
  }

}
