<ng-container *ngIf="form" [formGroup]="form">

  <div fxLayout="row wrap" fxLayoutGap="15px grid">
    <app-miscanthus-contract-form fxFlex="40%" [form]="form"></app-miscanthus-contract-form>

    <div fxFlex="40%">
      <mat-card>
        <mat-card-header>
          <mat-card-title i18n> Establishment Details </mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="column">

          <mat-form-field>
            <mat-label i18n>Planting Year</mat-label>
            <input matInput formControlName="planting_year" />
          </mat-form-field>

          <div class="price-fields">

            <mat-form-field>
              <mat-label i18n>Price Per Ha</mat-label>
              <input matInput formControlName="price_per_hectare" />
              <span matPrefix>&pound;</span>
            </mat-form-field>

            <mat-form-field>
              <mat-label i18n>Discount</mat-label>
              <input matInput formControlName="planting_discount" />
              <span matSuffix>%</span>
            </mat-form-field>
          </div>


        </mat-card-content>
      </mat-card>
    </div>


    <div fxFlex="20%">
      <mat-card>
        <mat-card-header>
          <mat-card-title i18n> Identify </mat-card-title>
        </mat-card-header>

        <mat-card-content fxLayout="column">
          <mat-slide-toggle formControlName="own_use" i18n>Own Use</mat-slide-toggle>
        </mat-card-content>
      </mat-card>
    </div>


  </div>


</ng-container>
