import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Stockist} from '../stockist.model';

@Component({
  selector: 'app-stockist-form',
  templateUrl: './stockist-form.component.html',
  styleUrls: ['./stockist-form.component.scss']
})
export class StockistFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  stockist: Stockist;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  isOnAccount(): boolean {
    return this.form.get('on_account').value;
  }

}
