import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MiscanthusStacksTableComponent} from './miscanthus-stacks-table/miscanthus-stacks-table.component';
import {StackSettingsComponent} from './stack-settings/stack-settings.component';
import {MiscanthusStackShowComponent} from './miscanthus-stack-show/miscanthus-stack-show.component';
import {
  MiscanthusStacksDailyOpsReportComponent
} from './miscanthus-stacks-daily-ops-report/miscanthus-stacks-daily-ops-report.component';

export class MiscanthusStacksRoutes {
  public static SETTINGS = 'settings';
  public static DAILY_OPS = 'daily-ops'
}

const routes: Routes = [
  {
    path: MiscanthusStacksRoutes.SETTINGS, component: StackSettingsComponent,
  },
  {
    path: MiscanthusStacksRoutes.DAILY_OPS, component: MiscanthusStacksDailyOpsReportComponent
  },
  { path: '', component: MiscanthusStacksTableComponent },
  { path: ':id', component: MiscanthusStackShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MiscanthusStacksRoutingModule{ }
