<ng-container *ngIf="currentUser">

  <ng-container *ngIf="currentUser.has_hr_access">
    <a mat-flat-button aria-hidden="false" aria-label="HR" color="primary" (click)="humanResourcesTrigger.openMenu()">
      <mat-icon>{{ gaiaIcons.human_resources }}</mat-icon>
      <div #humanResourcesTrigger="matMenuTrigger" [matMenuTriggerFor]="humanResourcesMenu"></div>
    </a>
    <mat-menu #humanResourcesMenu="matMenu">
      <a mat-menu-item [routerLink]="[appRoutes.TEAM_SPACE, teamSpaceRoutes.ADMIN, teamSpaceAdminRoutes.APPROVAL_PANEL]" i18n="Approval system">
        <mat-icon>{{ gaiaIcons.check }}</mat-icon>
        Approvals
      </a>
      <a mat-menu-item [routerLink]="[appRoutes.TEAM_SPACE, teamSpaceRoutes.ADMIN, teamSpaceAdminRoutes.EXPENSE_SUMMARY]" i18n="Expense system">
        <mat-icon>{{ gaiaIcons.expense_claims }}</mat-icon>
        Expenses
      </a>
    </mat-menu>
  </ng-container>
</ng-container>
