<ng-container *ngIf="form" [formGroup]="form">

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>shopping_cart</mat-icon>
      <mat-card-title i18n>Order Details</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <mat-form-field>
        <mat-label i18n>Ordered Date</mat-label>
        <input matInput formControlName="ordered_date" type="date" />
      </mat-form-field>
      <mat-form-field *ngIf="!newOrder">
        <mat-label i18n>Invoice Sent Date</mat-label>
        <input matInput formControlName="invoice_last_sent" type="datetime-local" />
      </mat-form-field>
      <mat-form-field *ngIf="form.get('invoice_send_issue').value">
        <mat-label i18n>Invoice Send Issue</mat-label>
        <input matInput formControlName="invoice_send_issue" />
      </mat-form-field>
      <mat-form-field *ngIf="!newOrder">
        <mat-label i18n>Invoice Raised</mat-label>
        <input matInput formControlName="invoice_raised" type="date" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <ng-container *ngIf="!customer else customerPanel">
    <div>
      <app-customer-search (customerSelected)="setCustomer($event);"></app-customer-search>
    </div>
  </ng-container>

  <ng-template #customerPanel>
    <div [ngSwitch]="customer.type_enum">
      <app-contact-card *ngSwitchCase="customerTypes.direct" [record]="customer.record"
                        (copyAddress)="addressCopied($event)"
                        (cancelled)="unsetCustomer()" [canCopy]="editable"></app-contact-card>

      <app-stockist-card *ngSwitchCase="customerTypes.stockist" [stockist]="customer.record"
                         (copyAddress)="addressCopied($event)"
                         (cancelled)="unsetCustomer()" [editable]="editable"></app-stockist-card>

      <app-brand-ambassador-card *ngSwitchCase="customerTypes.brand_ambassador" [brandAmbassador]="customer.record"
                         (copyAddress)="addressCopied($event)"
                         (cancelled)="unsetCustomer()" [editable]="editable"></app-brand-ambassador-card>

      <app-commercial-user-card *ngSwitchCase="customerTypes.commercial_user" [commercialUser]="customer.record"
                                 (copyAddress)="addressCopied($event)"
                                 (cancelled)="unsetCustomer()" [editable]="editable"></app-commercial-user-card>
    </div>
  </ng-template>

  <div gaiaSharedMultiAddressContainer *ngIf="customer">

    <gaia-shared-address-embed-form #billingAddress formGroupName="billing_address"
                            [title]="'Billing'" [icon]="'payment'" [editable]="editable"></gaia-shared-address-embed-form>
    <gaia-shared-address-embed-form #shippingAddress formGroupName="shipping_address"
                            [title]="'Shipping'" [icon]="'local_shipping'" [editable]="editable"></gaia-shared-address-embed-form>
  </div>

  <div class="order-products" *ngIf="customer">
    <button mat-fab color="primary" type="button" aria-label="" (click)="addNewLine()" [disabled]="!canAddLine()">
      <mat-icon>add</mat-icon>
    </button>

    <mat-table [dataSource]="lineTracker" [formArrayName]="'order_items'">

      <ng-container matColumnDef="vat_rate">
        <mat-header-cell *matHeaderCellDef i18n="order item vat rate">VAT (%)</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="vat_rate" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="sales_price">
        <mat-header-cell *matHeaderCellDef i18n="order item inc vat price">Price</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="sales_price" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="ex_vat_sales_price">
        <mat-header-cell *matHeaderCellDef i18n="order item ex vat price">Ex VAT Price</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="ex_vat_sales_price" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="product_name">
        <mat-header-cell *matHeaderCellDef i18n="order item product">Product</mat-header-cell>
        <mat-cell #productSearch *matCellDef="let element; let index = index" [formArrayName]="index">
          <div *ngIf="getOrderLineProduct(index) else productLookup">
            {{ getOrderLineProduct(index).name }}
          </div>
          <ng-template #productLookup>
            <app-product-search [priceGroup]="this.priceGroup" (productSelected)="productSelected(index, $event)"></app-product-search>
          </ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef i18n="order item quantity">Qty</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="quantity" type="number" (change)="updatePrice(index)" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="discount">
        <mat-header-cell *matHeaderCellDef i18n="order item discount">Discount %</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="discount" type="number" min="0" max="100"/>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="line_total">
        <mat-header-cell *matHeaderCellDef i18n="order item total">Total</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          {{ getLineTotal('sales_price', index) | currency }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ orderItemsTotal | currency }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef><mat-icon>delete</mat-icon></mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <button mat-icon-button color="primary" type="button" aria-label="" (click)="removeLine(index)" *ngIf="!this.form.get('order_items').disabled">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="orderItemColumns"></mat-header-row>
      // *ngFor="let n of natures; let i = index;" [formGroupName]="i"
      <mat-row *matRowDef="let row; let i = index; columns: orderItemColumns"
        [class.to-delete]="row.get('to_delete').value"></mat-row>

    </mat-table>


    <div class="summary-wrapper">
      <mat-card class="summary">
        <mat-card-header>
          <mat-icon mat-card-avatar>payment</mat-icon>
          <mat-card-title i18n>Payment Details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf="newOrder else existingOrderPayment">
            <mat-form-field>
              <mat-label i18n>Payment Method</mat-label>
              <mat-select formControlName="payment_method" (selectionChange)="paymentMethodSelected($event)">
                <mat-option [value]="null" i18n>No Payment</mat-option>
                <mat-option [value]="paymentMethods.cash" i18n>Cash</mat-option>
                <mat-option [value]="paymentMethods.bank_transfer" i18n>Bank Transfer</mat-option>
                <mat-option [value]="paymentMethods.paypal" i18n>Paypal</mat-option>
                <!-- no option for shopify or bank transfer on new orders -->
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.form.get('payment_method').value !== null">
              <mat-label i18n>Paid On</mat-label>
              <input matInput formControlName="paid_on" type="datetime-local" />
            </mat-form-field>
          </ng-container>
          <ng-template #existingOrderPayment>
            <div *ngIf="getCustomerOnAccount()">
              <mat-form-field *ngIf="!this.form.get('paid').value">
                <mat-label i18n>Payment Due</mat-label>
                <input matInput formControlName="invoice_payment_due" type="date" />
              </mat-form-field>
            </div>
            <div *ngIf="this.form.get('paid').value" fxLayout="row" fxLayoutAlign="start center">
              Paid by&nbsp;<app-payment-method-i18n [key]="this.form.get('payment_method').value"></app-payment-method-i18n>
              &nbsp;on {{ this.form.get('paid_on').value | date }}
            </div>
          </ng-template>
        </mat-card-content>
      </mat-card>

      <mat-card class="summary">
        <mat-card-header>
          <mat-icon mat-card-avatar>shopping_cart</mat-icon>
          <mat-card-title i18n>Order Summary</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="order-summary-row">
            <div i18n>Total Ex Vat</div>
            <div>{{orderItemsExTotal | currency}}</div>
          </div>
          <div class="order-summary-row">
            <div i18n>Total Inc Vat</div>
            <div>{{orderItemsTotal | currency}}</div>
          </div>
          <div class="order-summary-row">
            <div i18n>Shipping Cost</div>
            <div>
              <input matInput formControlName="delivery_cost" type="number" step="0.01" />
            </div>
          </div>
          <div class="order-summary-row" *ngIf="refundTotal > 0">
            <div i18n>Refunded Total</div>
            <div>{{refundTotal | currency}}</div>
          </div>
          <div class="order-summary-row">
            <div i18n>Total</div>
            <div>{{orderTotal | currency}}</div>
          </div>
        </mat-card-content>
      </mat-card>

    </div>

  </div>
</ng-container>

