
<mat-card [class.warn-mode]="warnMode">
  <mat-card-header>
    <mat-icon mat-card-avatar *ngIf="recordIcon">{{ recordIcon }}</mat-icon>
    <mat-card-title><span style="white-space: nowrap">{{ displayName }}</span></mat-card-title>
    <mat-card-subtitle><span style="white-space: nowrap">{{ recordType }}</span></mat-card-subtitle>
    <div class="button-section">
      <ng-content select="[header-button-section]">
      </ng-content>
    </div>
    <div class="header-menu">
      <ng-content select="[header-menu]"></ng-content>
    </div>
  </mat-card-header>
  <mat-card-content>
    <ng-content select="[header-content]"></ng-content>
  </mat-card-content>
</mat-card>
