<app-new-record-dialog [form]="form" dialogTitle="Contact Type" (saveClicked)="save()" [dialogRef]="matDialog">

  <ng-container *ngIf="form" [formGroup]="form">

    <phanes-error-panel></phanes-error-panel>

    <div fxLayout="column">
      <mat-form-field>
        <mat-label i18n>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-slide-toggle formControlName="active" i18n>Active?</mat-slide-toggle>
    </div>

  </ng-container>
</app-new-record-dialog>
