import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {
  Processor,
  DistanceMatrix,
  ProcessorScheduleMonthList,
  ProcessorDeliverySiteList,
  ProcessorYearlyStat, ProcessorUniversalSearchPreview
} from './processor.model';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {UniversallySearchableService} from '../../universal-search/universally-searchable.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessorsService extends RestApiService<Processor> implements UniversallySearchableService<ProcessorUniversalSearchPreview> {

  constructor(
    httpClient: HttpClient
  ) {
    super('processors', 'processor', httpClient);
  }

  createYearlySchedule(processor: Processor, data: any): Observable<{ created: boolean }>{
    return this.httpPost<{created: boolean}>(this.generateUrl({recordId: processor.id},
      'create_yearly_schedule'), data);
  }

  deliverySiteList(): Observable<Array<ProcessorDeliverySiteList>> {
    return this.httpGet<Array<ProcessorDeliverySiteList>>(this.generateNonRecordUrl('delivery_site_list'));
  }

  getDistmatrixList(postCode: string): Observable<Array<DistanceMatrix>>{
    /* return this.httpClient.get<ProcessorModel>(this.generateNonRecordUrl("distance_matrix_from"),{withCredentials:true,
       params:{post_code: post_code}})*/
    return this.httpGet(this.generateNonRecordUrl('distance_matrix_from'), { post_code: postCode });
  }

  getScheduleMonths(harvestYear: number): Observable<Array<ProcessorScheduleMonthList>>{
    return this.httpGet(this.generateNonRecordUrl('get_active_schedule_months'), { harvest_year: harvestYear });
  }

  yearlyStats(harvestYear: number): Observable<Array<ProcessorYearlyStat>> {
    return this.httpGet(this.generateNonRecordUrl('yearly_stats'), { harvest_year: harvestYear });
  }

  universalSearchPreviewData(recordId: string): Observable<ProcessorUniversalSearchPreview> {
    return of(null);
  }

  /*withinMilesOf(point: GPSPoint, miles: number = 50): Observable<Array<ContractedFieldMapData>> {
    return this.httpGet<Array<ContractedFieldMapData>>(this.generateNonRecordUrl('within_miles_of'),
      {
        latitude: point.latitude,
        longitude: point.longitude,
        miles
      });
  }
*/
}
