import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {PriceGroup} from './price-group.model';
import {Observable} from 'rxjs';
import {RailsGetRecordsParams} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class PriceGroupsService extends RestApiService<PriceGroup> {

  constructor(httpClient: HttpClient) {
    super('/price_groups', 'price_group', httpClient);
  }

  getDefaultPriceGroup(): Observable<PriceGroup>{
    return this.httpGet<PriceGroup>(this.generateUrl({} as RailsGetRecordsParams, '/default_price_group'));
  }

}
