import {Injectable} from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {GrowerProfile} from './grower-profile.model';

@Injectable({
  providedIn: 'root'
})
export class GrowerProfilesService extends RestApiService<GrowerProfile> {

  constructor(
    httpClient: HttpClient
  ) {
    super('/grower_profiles', 'grower_profile', httpClient);
  }

}
