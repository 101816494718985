<div class="companies">

  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Companies" i18n-pageTitle="Page title for companies page"
                    i18n-searchHint searchHint="Company name">

  </app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n> Name </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="['/', appRoutes.BUSINESS_SETTINGS, businessRoutes.COMPANIES,  element.id]">{{element.name}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vat_number">
      <mat-header-cell *matHeaderCellDef> VAT Number </mat-header-cell>
      <mat-cell *matCellDef="let element" i18n> {{element.vat_number}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="use_for_orders">
      <mat-header-cell *matHeaderCellDef i18n> Used for Orders</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.use_for_orders">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageSize]="dataSource.pageSize"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
