import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HaulageMatricesViewerComponent } from './haulage-matrices-viewer/haulage-matrices-viewer.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { HaulageMatrixFormComponent } from './haulage-matrix-form/haulage-matrix-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { HaulageMatrixNewDialogComponent } from './haulage-matrix-new-dialog/haulage-matrix-new-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import { HaulageMatricesYearViewerComponent } from './haulage-matrices-year-viewer/haulage-matrices-year-viewer.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {GaiaSharedModule} from '@terravesta/gaia-shared';



@NgModule({
  declarations: [
    HaulageMatricesViewerComponent,
    HaulageMatrixFormComponent,
    HaulageMatrixNewDialogComponent,
    HaulageMatricesYearViewerComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    GaiaGeneralModule,
    MatTableModule,
    MatCardModule,
    MatSlideToggleModule,
    GaiaSharedModule
  ],
})
export class HaulageMatricesModule { }
