import {Component, OnInit} from '@angular/core';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ParcelLocationData} from '../contracted-field.model';
import {
  PhanesMapPoint, PhanesRecordMapItem,
  What3WordsCoordinate,
  What3WordsService
} from '@terravesta/phanes';
import {GaiaLogger} from '../../../../utils/common-functions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {mapDataInfoWindowHtml} from '../contracted-field-functions';

@Component({
  selector: 'app-parcel-finder',
  templateUrl: './parcel-finder.component.html',
  styleUrls: ['./parcel-finder.component.scss']
})
export class ParcelFinderComponent implements OnInit {

  parcelReference: string;
  parcelInfo: ParcelLocationData;
  w3words: What3WordsCoordinate;

  miles = 20;
  points: Array<PhanesMapPoint> = new Array<PhanesMapPoint>();
  fields: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    private w3wService: What3WordsService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  locateParcel() {
    this.contractedFieldsService.getParcelLocationInformation(this.parcelReference).subscribe(
      (result) => {
        this.parcelInfo = result;
        this.resetMap();
        this.w3wService.convertTo3wa(this.parcelInfo.gps_data.latitude, this.parcelInfo.gps_data.longitude).subscribe(
          (response) => {
            this.w3words = response;
            GaiaLogger.log(response);
          }
        );
      },
      () => {
        this.parcelInfo = null;
        this.matSnackBar.open($localize`:@@ParcelFinder.serverError:Something has gone wrong when trying to locate this parcel`);
      }
    );
  }

  resetMap() {
    this.points = new Array<PhanesMapPoint>();
    const point = {
      position: {latitude: this.parcelInfo.gps_data.latitude, longitude: this.parcelInfo.gps_data.longitude},
      title: this.parcelReference,
      style: {
        markerColor: '#0000FF',
      }
    } as PhanesMapPoint;
    this.points.push(point);
  }

  loadFields() {
    this.contractedFieldsService.withinMilesOf(this.parcelInfo.gps_data, this.miles).subscribe(
      (response) => {
        this.fields = new Array<PhanesRecordMapItem>();
        response.forEach((mapData) => {
          const content = mapDataInfoWindowHtml(mapData);
          this.fields.push({
            record: mapData,
            infoWindowHtml: content,
            title: `${mapData.grower_code} - ${mapData.name}`,
            displayOptions: {
              markerStyle: {
                markerColor: mapData.color
              },
              polygonStyle: {
                fillColor: mapData.color,
                strokeColor: mapData.color,
              }
            }
          } as PhanesRecordMapItem);
        });
      }
    );
  }
}
