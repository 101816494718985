import { Injectable } from '@angular/core';
import {
  Grower,
  GrowerDashboardStats,
  GrowerHarvestForYear,
  GrowerHarvestSeries, GrowersBaleHistories,
  GrowersWithinDistance, GrowerUniversalSearchPreview
} from './grower.model';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import * as FileSaver from 'file-saver';
import {DashboardService} from '../../services/dashboard.service';
import {UniversallySearchableService} from '../../universal-search/universally-searchable.service';

@Injectable({
  providedIn: 'root'
})
export class GrowersService extends DashboardService<Grower, GrowerDashboardStats> implements UniversallySearchableService<GrowerUniversalSearchPreview> {

  constructor(httpClient: HttpClient) {
    super('growers', 'grower', httpClient);
  }

  downloadPaperHarvestDec(grower: Grower, completed): Subscription {
    return this.httpClient.get(this.generateUrl({recordId: grower.id}, 'paper_harvest_dec'),
      {withCredentials: true, responseType: 'arraybuffer'})
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/pdf'});
          FileSaver.saveAs(file, `${grower.grower_code}_harvest_dec.pdf`);
        },
        () => {
        },
        () => {
          completed();
        }
      );
  }

  harvestSeries(grower: Grower): Observable<GrowerHarvestSeries> {
    return this.httpGet<GrowerHarvestSeries>(this.generateUrl({recordId: grower.id}, 'harvest_series'));
  }

  harvestDetailsForYear(grower: Grower, harvestYear: number): Observable<GrowerHarvestForYear> {
    return this.httpGet<GrowerHarvestForYear>(this.generateUrl({recordId: grower.id}, 'harvest_details_for_year'),
      {harvest_year: harvestYear});
  }

  currentFieldKMLForGrower(grower: Grower, completed: () => void): Subscription {
    return this.httpGetBuffered(this.generateUrl({ recordId: grower.id }, 'contracted_fields_kml'))
      .subscribe((response) => {
          const file = new Blob([response], {type: 'application/vnd'});
          FileSaver.saveAs(file, `${grower.grower_code}.kml`);
        },
        () => {
        },
        () => {
          completed();
        }
      );
  }

  growerKML(completed: () => void): Subscription {
    return this.httpGetBuffered(this.generateNonRecordUrl('grower_kml'))
      .subscribe((response) => {
          const file = new Blob([response], {type: 'application/vnd'});
          FileSaver.saveAs(file, `grower.kml`);
        },
        () => {
        },
        () => {
          completed();
        }
      );
  }

  withinMilesOf(latitude: number, longitude: number, miles: number, limit: number): Observable<Array<GrowersWithinDistance>> {
    return this.httpGet(this.generateNonRecordUrl('within_miles_of'), {
      latitude, longitude, miles, limit
    });
  }

  baleHistory(): Observable<GrowersBaleHistories> {
    return this.httpGet<GrowersBaleHistories>(this.generateNonRecordUrl('bale_histories'));
  }

  baleHistoryCSV(completed: () => void): Subscription {
    return this.httpGetBuffered(this.generateNonRecordUrl('bale_histories_csv'))
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/csv'});
          FileSaver.saveAs(file, `bale_histories.csv`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  universalSearchPreviewData(recordId: string): Observable<GrowerUniversalSearchPreview> {
    return this.httpGet(this.generateUrl({ recordId }, 'universal_search_preview_data'));
  }

}
