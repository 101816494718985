import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaleCollectionsService } from './bale-collections.service';
import { BaleCollectionsTableComponent } from './bale-collections-table/bale-collections-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import { BaleCollectionsOverviewComponent } from './bale-collections-overview/bale-collections-overview.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { BaleCollectionsCreationDialogComponent } from './bale-collections-creation-dialog/bale-collections-creation-dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MiscanthusStacksModule} from '../miscanthus-stacks/miscanthus-stacks.module';
import {ProcessorDeliverySitesModule} from '../processors/processor-delivery-sites/processor-delivery-sites.module';
import {HauliersModule} from '../hauliers/hauliers.module';
import {HaulierDriversModule} from '../hauliers/haulier-drivers/haulier-drivers.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RouterModule} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RestfulCalendarModule} from '../../restful-calendar/restful-calendar.module';
import {MatCardModule} from '@angular/material/card';
import {AutoPriceModule} from '../../concerns/auto-price/auto-price.module';
import { BaleCollectionCalendarItemComponent } from './bale-collection-calendar-item/bale-collection-calendar-item.component';
import {MatMenuModule} from '@angular/material/menu';
import { BaleCollectionShowComponent } from './bale-collection-show/bale-collection-show.component';
import {MatSortModule} from '@angular/material/sort';
import { BaleCollectionFormComponent } from './bale-collection-form/bale-collection-form.component';
import {HaulierSharedComponentsModule} from '../haulier-shared-components/haulier-shared-components.module';
import { BaleCollectionWeightReportLoaderComponent } from './bale-collection-weight-report-loader/bale-collection-weight-report-loader.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ExtendedModule} from '@angular/flex-layout';
import {ProcessorsModule} from '../processors/processors.module';
import { BaleCollectionInvalidateDialogComponent } from './bale-collection-invalidate-dialog/bale-collection-invalidate-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ProcessorRefInputAndCheckComponent } from './processor-ref-input-and-check/processor-ref-input-and-check.component';
import { BaleCollectionSplitLoadCreationDialogComponent } from './bale-collection-split-load-creation-dialog/bale-collection-split-load-creation-dialog.component';
import { BaleCollectionsManagementComponent } from './bale-collections-management/bale-collections-management.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BaleCollectionBulkUpdaterDialogComponent } from './bale-collection-bulk-updater-dialog/bale-collection-bulk-updater-dialog.component';
import {
  BaleCollectionUniversalSearchResultComponent
} from './bale-collection-universal-search-result/bale-collection-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../../universal-search/universal-search-layout.module';
import {MatListModule} from '@angular/material/list';
import {
  BaleCollectionInvoiceReportLoaderComponent
} from './bale-collection-invoice-report-loader/bale-collection-invoice-report-loader.component';

@NgModule({
  declarations: [BaleCollectionsTableComponent, BaleCollectionsOverviewComponent, BaleCollectionsCreationDialogComponent,
    BaleCollectionCalendarItemComponent, BaleCollectionShowComponent, BaleCollectionFormComponent,
    BaleCollectionWeightReportLoaderComponent,
    BaleCollectionInvoiceReportLoaderComponent,
    BaleCollectionInvalidateDialogComponent,
    ProcessorRefInputAndCheckComponent,
    BaleCollectionSplitLoadCreationDialogComponent,
    BaleCollectionsManagementComponent,
    BaleCollectionBulkUpdaterDialogComponent,
    BaleCollectionUniversalSearchResultComponent,
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    ExtendedModule,
    MatPaginatorModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ProcessorDeliverySitesModule,
    HauliersModule,
    HaulierDriversModule,
    MatSlideToggleModule,
    RouterModule,
    MatProgressSpinnerModule,
    RestfulCalendarModule,
    MatCardModule,
    AutoPriceModule,
    MatMenuModule,
    MatSortModule,
    HaulierSharedComponentsModule,
    PhanesComponentsModule,
    DragDropModule,
    MatDatepickerModule,
    ProcessorsModule,
    MiscanthusStacksModule,
    MatDialogModule,
    MatExpansionModule,
    MatCheckboxModule,
    UniversalSearchLayoutModule,
    MatListModule
  ],
  providers: [BaleCollectionsService],
  exports: [
      BaleCollectionCalendarItemComponent,
      BaleCollectionUniversalSearchResultComponent,
  ],
})
export class BaleCollectionsModule { }
