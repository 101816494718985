import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {StaffMember} from '../staff-member.model';
import {StaffMemberService} from '../staff-member.service';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {GaiaLogger} from '../../../../utils/common-functions';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-staff-member-email-signature',
  templateUrl: './staff-member-email-signature.component.html',
  styleUrls: ['./staff-member-email-signature.component.scss']
})
export class StaffMemberEmailSignatureComponent implements OnInit {

  qrCodeUrl: string;
  showSalutation = false;
  showQRCode = false;
  gaiaIconSet = GaiaIconSet;

  addressString = '';

  @ViewChild ('emailSig') inp: ElementRef;

  @Input() staffMember: StaffMember;

  constructor(
    private staffMemberService: StaffMemberService,
    private clipboard: Clipboard,
  ){ }

  ngOnInit(): void {
    this.qrCodeUrl = this.staffMemberService.getQRCodeUrl(this.staffMember);
    if (this.staffMember.primary_office) {
      const address = this.staffMember.primary_office.address;
      const addressArray = [address.address_line_1, address.address_line_2, address.address_line_3,
        address.city, address.post_code];
      const cleanedArray = addressArray.filter(e => e);
      this.addressString = cleanedArray.join(', ');
    }else{
      this.addressString = '';
    }

  }

  copyText() {
    GaiaLogger.log(this.inp);
    const content = this.inp.nativeElement.select;
    GaiaLogger.log(content);
    this.clipboard.copy(content);
  }
}
