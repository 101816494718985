import {GpsData} from '@terravesta/phanes';
import {MiscanthusVariety} from '../miscanthus-varieties/miscanthus-variety.model';
import {AgriculturalField} from '../agricultural-field.model';
import {Grower} from '../growers/grower.model';
import {Ticketable} from '../../tickets/ticket.model';
import {PlantingOpportunitiesYearStats} from '../planting-opportunities/planting-opportunity.model';
import {UniversalSearchData} from '../../universal-search/universal-search.model';
import {TermContract, TermContractFieldAssignment} from '../contracts/term-contracts/term-contract.model';
import {EstablishmentContract} from '../contracts/establishment-contracts/establishment-contract.model';
import {ReplantingField} from "../replanting_field.model";

export interface ContractedFieldMapData extends GpsData {
  id: string;
  name: string;
  grower_code: string;
  variety: string;
  color: string;
  year_planted: number;
}

export interface ContractedField extends AgriculturalField, Ticketable {
  added_during_declaration: boolean;
  year_planted: number;
 /* year_replanted: number;
  replanted_field_cache: {
    [year: string]: number
  };*/
  planted_variety: MiscanthusVariety;
  replanted_field: ReplantingField;

  date_planting_started: Date;
  date_planting_ended: Date;

  establishment_contract: EstablishmentContract;
  current_term_contract: TermContract;

  has_warnings: boolean;
  term_contract_missing: boolean;
  size_issue: boolean;
  polygon_issue: boolean;

  mixed_year_planting: boolean;
  grower: Grower;
  contracted: boolean;
  replanting: boolean;
  crop_removed_on: string; // Date
  reason_for_removal: string;

  yield_cache: {
    [year: string]: number
  };
  current_harvest_yield: number;
  planting_density: number;
}

export interface ParcelLocationData {
  gps_data: ParcelGPSData;
  post_code: {
    postcode: string;
    distance: number; // meters
  };

}

export interface ParcelGPSData extends GpsData {
  northing: number;
  easting: number;
}

export interface ContractedFieldHarvestSeries {
  series: [
    {
      bales: number;
      year: number;
      avg_bale_weight: number;
      per_ha: number;
      age: number;
    }
  ];
}

export interface KMLIntersectionResult {
  placemark: {
    name: string;
    index: number;
    points: Array<any>;
  };
  record: ContractedField;
}

export interface PlantingReport {
  area: string;
  growers: number;
  plantings: [
    {
      total_planted_area_ha: number,
      planting_year: number,
    }
  ];
}

export interface FieldContractHistory {
  id: string;
  grower_contract_ref: string;
  history: Array<TermContractFieldAssignment>;
}

export interface PlantingSummaryStats {
  contracted_field_stats: {
    variety_area: Array<{
      miscanthus_variety: string,
      planted_area_ha: number,
    }>;
    mapping_stats: Array<FieldMapStats>;
  };
  planting_opportunity_stats: PlantingOpportunitiesYearStats;

}

export interface FieldMapStats {
  status: MapStatus,
  count: number
}

export enum MapStatus {
  polygon = 'polygon',
  pinOnly= 'pin_only',
  unmmapped = 'unmapped',
}


export interface ContractedFieldUniversalSearchResult extends UniversalSearchData {
  year_planted: number;
}

export interface ContractedFieldUniversalSearchPreview {
  grower_code: string;
}
