import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-cell-text-long',
  templateUrl: './cell-text-long.component.html',
  styleUrls: ['./cell-text-long.component.scss']
})
export class CellTextLongComponent implements OnInit, OnChanges {

  @Input()
  longText: string;

  @Input()
  wordsToShow = 6;

  displayText: string;
  showEllipse = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.longText) {
      if (changes.longText.currentValue) {
        this.shortenText();
      }
    }
  }

  shortenText() {
    const words = this.longText.split(' ');
    if (words.length > this.wordsToShow){
      this.showEllipse = true
      this.displayText = words.splice(0, this.wordsToShow).join(' ');
    } else {
      this.showEllipse = false
      this.displayText = this.longText;
    }
  }

}
