<mat-card *ngIf="contract else loading">
  <mat-card-header>
    <mat-card-title>
       {{ contract.grower_contract_ref }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <ng-container [ngSwitch]="contract.contract_type">

      <div *ngSwitchCase="'Establishment Contract'">
        <mat-list>
          <mat-list-item>
            <span i18n="">Year Planted</span>: {{ contract.planting_year }}
          </mat-list-item>
          <mat-list-item>
            <span i18n="">Price per ha</span>: {{ contract.price_per_hectare }}
          </mat-list-item>
          <mat-list-item>
            <span i18n="">Contract Area</span>: {{ contract.contract_total_area_ha }}
          </mat-list-item>
        </mat-list>
      </div>

      <div *ngSwitchDefault>
        <mat-list>
          <mat-list-item>
            <span i18n="">Type</span>: {{ contract.contract_type }}
          </mat-list-item>
          <mat-list-item>
            <span i18n="">Contract Area</span>: {{ contract.contract_total_area_ha }}
          </mat-list-item>
          <mat-list-item>
            <span i18n="">End Date</span>: {{ contract.end_date | date }}
          </mat-list-item>
        </mat-list>

      </div>

    </ng-container>

  </mat-card-content>

</mat-card>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</ng-template>
