import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleMonthsOverviewComponent } from './schedule-months-overview/schedule-months-overview.component';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {NgxEchartsModule} from 'ngx-echarts';
import {MatButtonModule} from '@angular/material/button';
import { ScheduleMonthCreateDialogComponent } from './schedule-month-create-dialog/schedule-month-create-dialog.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {MatInputModule} from '@angular/material/input';
import {PhanesComponentsModule} from '@terravesta/phanes';
import { ScheduleMonthSelectComponent } from './schedule-month-select/schedule-month-select.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
    declarations: [ScheduleMonthsOverviewComponent, ScheduleMonthCreateDialogComponent, ScheduleMonthSelectComponent],
  exports: [
    ScheduleMonthsOverviewComponent,
    ScheduleMonthSelectComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatSelectModule,
        FormsModule,
        MatTableModule,
        NgxEchartsModule,
        MatButtonModule,
        GaiaGeneralModule,
        MatInputModule,
        ReactiveFormsModule,
        PhanesComponentsModule,
        MatProgressSpinnerModule
    ]
})
export class ScheduleMonthsModule { }
