<gaia-shared-standard-dialog dialogTitle="Add Widget">

  <div content>
    <mat-form-field>
      <mat-label i18n="Select widget to add label for drop down">Select Widget</mat-label>
      <mat-select [formControl]="selectedWidget">
        <mat-optgroup *ngFor="let group of groupedByDepartmentWidgets" [label]="group.department">
          <mat-option *ngFor="let widget of group.widgets" [value]="widget">
            {{ widget.name }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>

    <p *ngIf="selectedWidget.value">
      {{ selectedWidget.value.description }}
    </p>

  </div>

  <div actions>
    <button mat-button color="primary" i18n="add widget" (click)="addWidget()">Add Widget</button>
    <button mat-button type="button" [mat-dialog-close]="null">Close</button>
  </div>

</gaia-shared-standard-dialog>
