import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {
  RailsApiMethods,
  RailsAPIWrapper,
  RailsFullApiService,
  RailsGetRecordsParams,
  RailsRecordParams,
  RailsUploadParams
} from '@terravesta/phanes';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


export abstract class RestApiService<T> extends RailsFullApiService<T> implements RailsApiMethods<T>{

  private readonly processorFunction: (record: T) => void;

  protected constructor(
    restEndPoint: string,
    paramName: string,
    http: HttpClient,
    processorFunction?: (record: T) => void
  ) {
    super(environment.server_url, restEndPoint, paramName, http);
    this.processorFunction = processorFunction;
  }

  getRecord(params: RailsRecordParams): Observable<T> {
    if (this.processorFunction) {
      return super.getRecord(params).pipe(map((record: T) => {
        this.processorFunction(record);
        return record;
      }));
    } else {
      return super.getRecord(params);
    }
  }



  getRecords(params: RailsGetRecordsParams): Observable<RailsAPIWrapper<T>> {
    if (this.processorFunction) {
      return super.getRecords(params).pipe(map((results: RailsAPIWrapper<T>) => {
        results.records.map((record: T) => this.processorFunction(record));
        return results;
      }));
    } else {
      return super.getRecords(params);
    }
  }

  updateRecord(params: RailsUploadParams<T>): Observable<T> {
    if (this.processorFunction) {
      return super.updateRecord(params).pipe(map((record: T) => {
        this.processorFunction(record);
        return record;
      }));
    } else {
      return super.updateRecord(params);
    }
  }
}
