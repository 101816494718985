<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.contracted_fields}}</mat-icon>
    <mat-card-title i18n>Fields</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <button mat-fab (click)="addContractedField()">
      <mat-icon>{{gaiaIconSet.add}}</mat-icon>
    </button>

    <div class="contracted-fields-embed">
      <mat-tab-group (selectedTabChange)="updateMap($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            Current Fields (Table)
          </ng-template>
          <app-contracted-fields-simple-table [dataSource]="dataSource"></app-contracted-fields-simple-table>
        </mat-tab>
        <mat-tab label="map-tab">
          <ng-template mat-tab-label>
            Current Fields (Map)
          </ng-template>
          <phanes-google-map [mapRecordsItems]="fields" width="100%" [preferPolygon]="true"></phanes-google-map>
          <gaia-shared-spinner-loading-button color="accent" (clickOccurred)="exportToKML()" i18n="export a kml of the fields currently under contract by clicking this btn">
            Export KML
          </gaia-shared-spinner-loading-button>
        </mat-tab>
        <mat-tab *ngIf="showOld">
          <ng-template mat-tab-label>
            Old Fields
          </ng-template>
          <app-contracted-fields-simple-table [dataSource]="oldFieldsDataSource"></app-contracted-fields-simple-table>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Drone Images
          </ng-template>
            <div fxLayoutGap="10px">
              <div appScrollableTable [maxHeight]="'85vh'" >
                <mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef i18n> Name</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <a mat-button color="accent" (click)="zoomOnImage(element)">
                        {{element.name}}
                      </a>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="year_planted">
                    <mat-header-cell *matHeaderCellDef i18n>Year Planted</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.year_planted}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="variety">
                    <mat-header-cell *matHeaderCellDef i18n>Variety</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.planted_variety.name}} </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                </mat-table>

                <mat-paginator #currentPaginator [length]="dataSource.totalRecords"
                               [pageSize]="dataSource.pageSize"
                               [pageIndex]="dataSource.pageIndex"
                               [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
              </div>
              <div class="map-container" fxFlex="1 1 auto" fxLayout="column">
                <span style="color: #1b7001">**This map only shows the latest drone image for all the fields</span>
                <mgl-map
                  [style]="'mapbox://styles/michaelsterravesta/cleo7aas3002p01mrbmj843vt'"
                  [zoom]="[zoom]"
                  [center]="mapCenter">
                  <ng-container *ngFor="let image of latestDroneImage">
                    <mgl-raster-source *ngIf="image.urlString" [id]="'droneImage' + image.droneImage.id" [url]="image.urlString"></mgl-raster-source>
                    <mgl-layer *ngIf="image.urlString" [id]="'droneLayer' + image.droneImage.id" type="raster" [source]="'droneImage' + image.droneImage.id"
                             (layerMouseEnter)="handleFeatureClick($event)" ></mgl-layer>
                    <mgl-popup *ngIf="showPopup" [lngLat]="[image.droneImage.contracted_field.longitude, image.droneImage.contracted_field.latitude]">
                      <div class="popup-content">
                        <h3>{{ image.droneImage.contracted_field.name }}</h3>
                        <!-- Add more content for the popup as needed -->
                      </div>
                    </mgl-popup>
                  </ng-container>
                  <mgl-control mglNavigation></mgl-control>
                </mgl-map>
              </div>
            </div>

        </mat-tab>
      </mat-tab-group>
    </div>


  </mat-card-content>
</mat-card>
