import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from '../app-routing.module';
import { IndexHeaderComponent } from './index-header/index-header.component';
import {MatInputModule} from '@angular/material/input';
import { SearchFilterButtonComponent } from './search-filter-button/search-filter-button.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DateSelectorDialogComponent } from './date-selector-dialog/date-selector-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ShowRecordWrapperComponent } from './show-record-wrapper/show-record-wrapper.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import { NewRecordDialogComponent } from './new-record-dialog/new-record-dialog.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import { ShowRecordMenuComponent } from './show-record-menu/show-record-menu.component';
import {OrderByPipe} from './pipes/order-by.pipe';
import { FormControlOrderByPipe } from './pipes/form-control-order-by.pipe';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ScrollableTableDirective } from './directives/scrollable-table.directive';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {LoginExpiryCheckPopupComponent} from './login-expiry-check-popup/login-expiry-check-popup.component';
import { LongTableWrapperComponent } from './long-table-wrapper/long-table-wrapper.component';
import { ButtonRowContainerComponent } from './button-row-container/button-row-container.component';
import { PercentageCircleDisplayComponent } from './percentage-circle-display/percentage-circle-display.component';
import { RepeatForPipe } from './pipes/repeat-for.pipe';
import { SearchFilterToggleButtonsComponent } from './search-filter-toggle-buttons/search-filter-toggle-buttons.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { IndexDashboardWrapperComponent } from './index-dashboard-wrapper/index-dashboard-wrapper.component';
import { StatsNumberLineComponent } from './stats-number-line/stats-number-line.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import { RecordBadgeComponent } from './record-badge/record-badge.component';
import { BadgePanelComponent } from './badge-panel/badge-panel.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { GetLocationButtonComponent } from './get-location-button/get-location-button.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { RecordReloadConfirmDialogComponent } from './record-reload-confirm-dialog/record-reload-confirm-dialog.component';
import { ShowPageHeaderComponent } from './show-page-header/show-page-header.component';
import { FileSelectorComponent } from './file-selector/file-selector.component';
import { HeaderWarningComponent } from './header-warning/header-warning.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { YearSelectorComponent } from './year-selector/year-selector.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { TableLoadingWrapperComponent } from './table-loading-wrapper/table-loading-wrapper.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { EmailLinkComponent } from './email-link/email-link.component';
import { CellTextLongComponent } from './cell-text-long/cell-text-long.component';

@NgModule({
  declarations: [
    IndexHeaderComponent,
    SearchFilterButtonComponent,
    FileUploadComponent,
    DateSelectorDialogComponent,
    ShowRecordWrapperComponent,
    NewRecordDialogComponent,
    ShowRecordMenuComponent,
    OrderByPipe,
    FormControlOrderByPipe,
    ScrollableTableDirective,
    PercentageCircleDisplayComponent,
    RepeatForPipe,
    ScrollableTableDirective,
    LoginExpiryCheckPopupComponent,
    LongTableWrapperComponent,
    ButtonRowContainerComponent,
    SearchFilterToggleButtonsComponent,
    IndexDashboardWrapperComponent,
    StatsNumberLineComponent,
    RecordBadgeComponent,
    BadgePanelComponent,
    TextEditorComponent,
    GetLocationButtonComponent,
    RecordReloadConfirmDialogComponent,
    ShowPageHeaderComponent,
    FileSelectorComponent,
    HeaderWarningComponent,
    PrintLayoutComponent,
    YearSelectorComponent,
    PageTitleComponent,
    TableLoadingWrapperComponent,
    EmailLinkComponent,
    CellTextLongComponent
  ],
    exports: [
        IndexHeaderComponent,
        SearchFilterButtonComponent,
        FileUploadComponent,
        ShowRecordWrapperComponent,
        NewRecordDialogComponent,
        ShowRecordMenuComponent,
        OrderByPipe,
        FormControlOrderByPipe,
        ScrollableTableDirective,
        PercentageCircleDisplayComponent,
        RepeatForPipe,
        LoginExpiryCheckPopupComponent,
        LongTableWrapperComponent,
        ButtonRowContainerComponent,
        SearchFilterToggleButtonsComponent,
        IndexDashboardWrapperComponent,
        StatsNumberLineComponent,
        TextEditorComponent,
        GetLocationButtonComponent,
        RecordReloadConfirmDialogComponent,
        ShowPageHeaderComponent,
        FileSelectorComponent,
        HeaderWarningComponent,
        RecordBadgeComponent,
        BadgePanelComponent,
        YearSelectorComponent,
        TableLoadingWrapperComponent,
        EmailLinkComponent,
        PageTitleComponent,
        CellTextLongComponent
    ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    GoogleMapsModule,
    MatExpansionModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    GaiaSharedModule,
    PhanesComponentsModule,
    MatMenuModule,
    MatToolbarModule,
    FlexModule,
    ExtendedModule,
    MatButtonToggleModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
})
export class GaiaGeneralModule { }

