import {Component, Input, OnInit} from '@angular/core';
import {DeviceType, LayoutTrackingService} from '../../gaia-layout/layout-tracking.service';

@Component({
  selector: 'app-index-dashboard-wrapper',
  templateUrl: './index-dashboard-wrapper.component.html',
  styleUrls: ['./index-dashboard-wrapper.component.scss']
})
export class IndexDashboardWrapperComponent implements OnInit {

  @Input()
  dashboardTitle: string;

  defaultOpenState = true;

  constructor(
    private layoutTracker: LayoutTrackingService,
  ) { }

  ngOnInit(): void {
    this.layoutTracker.deviceType.subscribe((result) => {
      if (result === DeviceType.MOBILE || result == DeviceType.TABLET) {
        this.defaultOpenState = false;
      }
    });
  }

}
