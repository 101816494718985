<app-universal-search-preview-wrapper [searchResult]="record" classType="Processor" i18n-classType="Processor label for search result" [icon]="gaiaIconSet.growers">

  <mat-list>
    <mat-list-item>
      <span matLine>Account Name</span>
      <span matLine>{{record.account_name}}</span>
    </mat-list-item>
  </mat-list>


  <ng-container buttons>
    <button mat-icon-button>
      <mat-icon (click)="showProcessor()">{{ gaiaIconSet.edit }}</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
    <mat-list *ngIf="previewData">
    </mat-list>
  </ng-container>

</app-universal-search-preview-wrapper>
