import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MiscanthusFieldHarvest} from '../miscanthus-field-harvests/miscanthus-field-harvest.model';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../../growing/growing-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';
import {MiscanthusStacksService} from '../miscanthus-stacks.service';
import {MiscanthusFieldHarvestsService} from '../miscanthus-field-harvests/miscanthus-field-harvests.service';
import {MiscanthusStack} from '../miscanthus-stack.model';
import {FormArray, FormGroup} from '@angular/forms';
import {
  MiscanthusFieldHarvestFormControlService
} from '../miscanthus-field-harvests/miscanthus-field-harvest-form-control.service';
import {ProcessingOverlayComponent} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-miscanthus-stack-review',
  templateUrl: './miscanthus-stack-review.component.html',
  styleUrls: ['./miscanthus-stack-review.component.scss']
})
export class MiscanthusStackReviewComponent implements OnChanges {

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  operationsRoutes = OperationsRoutes;

  @Input()
  miscanthusStack: MiscanthusStack;

  @Output()
  fieldHarvestsUpdated: EventEmitter<null> = new EventEmitter<null>();

  fieldHarvests: Array<MiscanthusFieldHarvest> = new Array<MiscanthusFieldHarvest>();
  formGroup: FormGroup;

  fieldColumns = ['field_name', 'variety', 'year_planted', 'planted_area', 'bale_count'];
  currentBaleTotal = 0;

  @ViewChild(ProcessingOverlayComponent)
  processingOverlay: ProcessingOverlayComponent;

  constructor(
    private stacksService: MiscanthusStacksService,
    private fieldHarvestsService: MiscanthusFieldHarvestsService,
    private fieldHarvestsFormControlService: MiscanthusFieldHarvestFormControlService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.miscanthusStack) {
      this.loadRecords();
    }
  }

  loadRecords(): void {
    this.fieldHarvestsService.getRecords({
      parentId: this.miscanthusStack.id,
      pageNumber: 0,
      pageSize: 100, // Hard code for now, might need paginator in future
    }).subscribe((response) => {
      this.fieldHarvests = response.records;
      this.formGroup = this.fieldHarvestsFormControlService.toFormArray(this.fieldHarvests, this.miscanthusStack);
      this.currentBaleTotal = 0;
      this.fieldHarvests.forEach((harvest) => this.currentBaleTotal += harvest.bale_count);
      this.formGroup.valueChanges.subscribe(() => {
        this.currentBaleTotal = 0;
        const controls = this.formGroup.get('field_harvests') as FormArray;
        controls.controls.forEach((control) => {
          this.currentBaleTotal += control.get('bale_count').value;
        });
      });
    });
  }

  updateFieldHarvests(): void {
    if (this.formGroup.valid) {
      this.processingOverlay.lock();
      this.stacksService.updateFieldHarvests(this.miscanthusStack, this.formGroup.value).subscribe(
        () => {
          this.fieldHarvestsUpdated.emit();
          this.processingOverlay.unlock();
        });
    }
  }
}
