import {GaiaRecord} from '../../models/gaia-record.model';
import {Grower} from '../growers/grower.model';
import {GPSPoint} from '@terravesta/phanes';
import {StaffMember} from '../../system-admin/staff-member/staff-member.model';

export interface PlantingOpportunity extends GaiaRecord {
  name: string;
  type: string;
  expected_planting_year: number;
  field_count: number;
  next_steps: string;
  stage: string;
  sales_survey_completed: boolean;
  grower: Grower;
  surveyed_by: StaffMember;
  farm_survey_date: Date;
}

export interface PlantingOpportunityMapData extends GPSPoint{
  name: string;
  type: string;
  expected_planting_year: number;
  field_count: number;
  next_steps: string;
  stage: string;
  sales_survey_completed: boolean;
  id: string;
}

export const PLANTING_OPPORTUNITY_SALESFORCE_STAGES = [
  'New Opportunity',
  'Unqualified - Contact Required',
  'Qualified - Meeting Required',
  'Meeting Booked',
  'Meeting Completed',
  'Proposal Sent',
  'Proposal - Deferred Contacts',
  'Awaiting Contract Info',
  'Contract Issued',
  'Closed Won',
  'Closed Lost'
];

export const STAGES_COLORS = [
  '#2743D9FF',
  '#80200a',
  '#d64fe5',
  '#fcba03',
  '#fc5603',
  '#03fcf8',
  '#796812',
  '#03fc9d',
  '#7b03fc',
  '#0a7c80',
  '#fc035a'
];

export function stageToColor(stage: string) {
  const index = PLANTING_OPPORTUNITY_SALESFORCE_STAGES.indexOf(stage);
  return STAGES_COLORS[index];
}


export interface PlantingOpportunitiesYearStats {
  closed_won_count: number;
  ops_missing_contracted_fields: number;
}
