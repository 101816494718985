import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PostCodeIoAddress} from './address.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostcodeIoServices{

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  private baseUrl = 'https://api.postcodes.io/postcodes';
  postCode: string ;


   getPostcode(cvCode): string {
    return `${this.baseUrl}/${cvCode}`;
  }

   getAddress(postcode: string): Observable<PostCodeIoAddress>{
     return this.httpClient.get<PostCodeIoAddress>(this.getPostcode(postcode));
  }

}
