import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoilMeasurementFormComponent } from './soil-measurement-form/soil-measurement-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import { SoilMeasurementsTableComponent } from './soil-measurements-table/soil-measurements-table.component';
import { SoilMeasurementsEmbedComponent } from './soil-measurements-embed/soil-measurements-embed.component';
import { SoilMeasurementFormDialogComponent } from './soil-measurement-form-dialog/soil-measurement-form-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {PhanesComponentsModule, What3WordsModule} from '@terravesta/phanes';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';



@NgModule({
  declarations: [
    SoilMeasurementFormComponent,
    SoilMeasurementsTableComponent,
    SoilMeasurementsEmbedComponent,
    SoilMeasurementFormDialogComponent
  ],
  exports: [
    SoilMeasurementsEmbedComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    GaiaGeneralModule,
    PhanesComponentsModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    What3WordsModule
  ]
})
export class SoilMeasurementsModule { }
