<app-universal-search-preview-wrapper [searchResult]="record" classType="Grower" [icon]="record.grower_code ? gaiaIconSet.growers : gaiaIconSet.leads">

  <mat-list>
    <mat-list-item>
      <span matLine>Grower Code</span>
      <span matLine>{{record.grower_code}}</span>
    </mat-list-item>
    <mat-list-item>
      <span matLine>Account Name</span>
      <span matLine>{{record.account_name}}</span>
    </mat-list-item>
  </mat-list>


  <ng-container buttons>
    <button mat-icon-button>
      <mat-icon (click)="showGrower()">{{ gaiaIconSet.edit }}</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
    <ng-container *ngIf="previewData">
      <div class="primary_contact" *ngIf="previewData.primary_contact">
        <div class="header" i18n>Primary Contact</div>
        <mat-list>
          <mat-list-item>
            <mat-icon matListIcon>{{ gaiaIconSet.contact }}</mat-icon>
            <div matLine>{{ previewData.primary_contact.name }}</div>
          </mat-list-item>
          <mat-list-item *ngIf="previewData.primary_contact.phone">
            <mat-icon matListIcon>{{ gaiaIconSet.phone }}</mat-icon>
            <div matLine>{{ previewData.primary_contact.phone }}</div>
          </mat-list-item>
          <mat-list-item *ngIf="previewData.primary_contact.mobile">
            <mat-icon matListIcon>{{ gaiaIconSet.phone }}</mat-icon>
            <div matLine>{{ previewData.primary_contact.mobile }}</div>
          </mat-list-item>
          <mat-list-item *ngIf="previewData.primary_contact.email">
            <mat-icon matListIcon>{{ gaiaIconSet.email }}</mat-icon>
            <div matLine><a href="mailto:{{previewData.primary_contact.email}}" target="_blank">{{ previewData.primary_contact.email }}</a></div>
          </mat-list-item>
        </mat-list>
      </div>
      <mat-list dense>
        <mat-list-item>
          <mat-icon matListIcon>{{ gaiaIconSet.contracted_fields }}</mat-icon>
          <div matLine>
            <span i18n>Contracted Fields</span> ({{ previewData.contracted_field_count }})
          </div>
        </mat-list-item>
        <mat-list-item>
          <mat-icon matListIcon>{{ gaiaIconSet.planting_opportunity }}</mat-icon>
          <div matLine>
            <span i18n>Opportunities</span> (0)
          </div>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </ng-container>

</app-universal-search-preview-wrapper>
