import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersTableComponent } from './orders-table/orders-table.component';
import {OrderRoutingModule} from './order-routing.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { OrderShowComponent } from './order-show/order-show.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ContactsModule} from '../../contacts/contacts.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {CustomerSearchComponent} from './customer-search/customer-search.component';
import {MatButtonModule} from '@angular/material/button';
import {ProductsModule} from '../products/products.module';
import {MatDialogModule} from '@angular/material/dialog';
import { OrderNewDialogComponent } from './order-new-dialog/order-new-dialog.component';
import { OrderFormComponent } from './order-form/order-form.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {StockistsModule} from '../stockists/stockists.module';
import { CustomerNewDialogComponent } from './customer-new-dialog/customer-new-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';
import { AddressCopyChoiceDialogComponent } from './address-copy-choice-dialog/address-copy-choice-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatChipsModule} from '@angular/material/chips';
import {MatListModule} from '@angular/material/list';
import { PaymentMethodI18nComponent } from './payment-method-i18n/payment-method-i18n.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrandAmbassadorsModule} from '../brand-ambassadors/brand-ambassadors.module';
import {CommercialUsersModule} from '../commercial-users/commercial-users.module';

@NgModule({
    declarations: [OrdersTableComponent, OrderShowComponent, CustomerSearchComponent, OrderNewDialogComponent,
        OrderFormComponent, CustomerNewDialogComponent, AddressCopyChoiceDialogComponent, PaymentMethodI18nComponent,
        PaymentDialogComponent],
    imports: [
        CommonModule,
        OrderRoutingModule,
        GaiaSharedModule,
        MatTableModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        AddressesModule,
        ContactsModule,
        MatCardModule,
        MatIconModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        FormsModule,
        ProductsModule,
        MatDialogModule,
        GaiaGeneralModule,
        StockistsModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatMenuModule,
        FlexLayoutModule,
        MatChipsModule,
        MatListModule,
        MatToolbarModule,
        BrandAmbassadorsModule,
        CommercialUsersModule,
    ],
    exports: [OrdersTableComponent, OrderShowComponent]
})
export class OrdersModule { }
