<a mat-flat-button aria-hidden="false" aria-label="Company Settings" color="primary" (click)="managementMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.management }}</mat-icon>
  <div #managementMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="managementMenu"></div>
</a>
<mat-menu #managementMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.BUSINESS_SETTINGS, businessSettingsRoutes.COMPANIES]" i18n="Companies menu link">
    <mat-icon>{{ gaiaIcons.companies }}</mat-icon>
    Companies
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.BUSINESS_SETTINGS, businessSettingsRoutes.OFFICES]" i18n="Company Offices menu link">
    <mat-icon>{{ gaiaIcons.offices }}</mat-icon>
    Company Offices
  </a>
</mat-menu>
