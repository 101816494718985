import {Component, ElementRef, forwardRef, Injector} from '@angular/core';
import {BaleGrabAvailability} from '../bale-grab-availability.model';
import {BaleGrabAvailabilitiesService} from '../bale-grab-availabilities.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {RecordListSelectDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-bale-grab-availability-select',
  templateUrl: './bale-grab-availability-select.component.html',
  styleUrls: ['./bale-grab-availability-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaleGrabAvailabilitySelectComponent),
      multi: true
    }
  ]
})
export class BaleGrabAvailabilitySelectComponent extends RecordListSelectDirective<BaleGrabAvailability> {


  constructor(
    baleGrabAvailabilitiesService: BaleGrabAvailabilitiesService,
    inj: Injector,
  ) {
    super(baleGrabAvailabilitiesService, inj);
  }

}
