import {Component, Input} from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {MiscanthusStack} from '../miscanthus-stack.model';
import {Grower} from '../../../growing/growers/grower.model';

@Component({
  selector: 'app-miscanthus-stack-form',
  templateUrl: './miscanthus-stack-form.component.html',
  styleUrls: ['./miscanthus-stack-form.component.scss']
})
export class MiscanthusStackFormComponent extends RecordFormDirective<MiscanthusStack> {

  @Input()
  grower: Grower;

  updateContactChanges($event: boolean) {

  }
}
