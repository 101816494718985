import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-login-expiry-check-popup',
  templateUrl: './login-expiry-check-popup.component.html',
  styleUrls: ['./login-expiry-check-popup.component.scss']
})
export class LoginExpiryCheckPopupComponent implements OnInit {

  constructor(
    public matDialog: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
  }

  continue() {
    this.matDialog.close(true);
  }

  logout() {
    this.matDialog.close(false);
  }
}
