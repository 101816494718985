import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarNoteCalendarItemComponent } from './calendar-note-calendar-item/calendar-note-calendar-item.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import { CalendarNoteFormDialogComponent } from './calendar-note-form-dialog/calendar-note-form-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {EditorJsModule} from '@terravesta/phanes';
import {NgxMatColorPickerModule} from '@angular-material-components/color-picker';



@NgModule({
  declarations: [
    CalendarNoteCalendarItemComponent,
    CalendarNoteFormDialogComponent
  ],
    imports: [
        CommonModule,
        DragDropModule,
        MatCardModule,
        MatMenuModule,
        RouterModule,
        MatIconModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        EditorJsModule,
        NgxMatColorPickerModule
    ]
})
export class CalendarNotesModule { }
