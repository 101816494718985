import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {AppRoutes} from '../../app-routing.module';
import {SystemAdminRoutes} from '../../system-admin/system-admin-routing.module';
import {StaffMemberRoutes} from '../../system-admin/staff-member/staff-member-routing.module';
import {SessionDataService} from '../../services/session-data.service';
import {LayoutTrackingService} from '../layout-tracking.service';

@Component({
  selector: 'app-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss']
})
export class TopToolbarComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;
  appRoutes = AppRoutes;
  systemAdminRoutes = SystemAdminRoutes;
  staffMemberRoutes = StaffMemberRoutes;

  showMenu = false;

  @Output()
  menuRequestChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private sessionData: SessionDataService,
    public layoutTracking: LayoutTrackingService,
  ) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.sessionData.logout();
  }

  openMenu() {
    this.showMenu = true;
    this.menuRequestChanged.emit(this.showMenu);
  }

  closeMenu() {
    this.showMenu = false;
    this.menuRequestChanged.emit(this.showMenu);
  }
}
