<ng-container [formGroup]="form">

  <mat-form-field>
    <mat-label i18n>Currency</mat-label>
    <mat-select [formControlName]="fieldName">
      <mat-option value="GBP">&pound; GBP</mat-option>
      <mat-option value="PLN">zł PLN</mat-option>
      <mat-option value="EUR">&euro; EUR</mat-option>
      <mat-option value="USD">&#x24; USD</mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
