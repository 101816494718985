import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContractedFieldFormControlService} from '../contracted-field-form-control.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ContractedField} from '../contracted-field.model';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-contracted-field-add-to-establishment-contracts-dialog',
  templateUrl: './contracted-field-add-to-establishment-contract-dialog.component.html',
  styleUrls: ['./contracted-field-add-to-establishment-contract-dialog.component.scss']
})
export class ContractedFieldAddToEstablishmentContractDialogComponent implements OnInit {

  formGroup: FormGroup;

  contractedField: ContractedField;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    private contractedFieldFormControl: ContractedFieldFormControlService,
    private dialogRef: MatDialogRef<ContractedFieldAddToEstablishmentContractDialogComponent, ContractedField>,
    @Inject(MAT_DIALOG_DATA) data: ContractedFieldAddToEstablishmentContractDialogData,
    private matDialog: MatDialog,
  ) {
    this.contractedField = data.contractedField;
  }

  ngOnInit(): void {
    this.formGroup = this.contractedFieldFormControl.establishmentContractsForm(this.contractedField);
  }

  confirmThenAdd() {
    if (this.formGroup.valid) {
      const ref = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent,
        {
          data: {
            title: 'Confirm Establishment Contract',
            message: 'Are you sure you wish to add this field to the establishment contract?',
          }
        });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.contractedFieldsService.updateRecord({
            recordId: this.contractedField.id,
            data: this.formGroup.value
          }).subscribe((field) => {
            this.dialogRef.close(field);
          });
        }
      });
    }

  }
}

export interface ContractedFieldAddToEstablishmentContractDialogData {
  contractedField: ContractedField;
}
