import {Component, Inject} from '@angular/core';
import {NewDialogData, NewDialogDirective} from '../../../components/new-dialog.directive';
import {Haulier} from '../haulier.model';
import {HauliersService} from '../hauliers.service';
import {HaulierFormControlService} from '../haulier-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../app-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';

@Component({
  selector: 'app-haulier-new-dialog',
  templateUrl: './haulier-new-dialog.component.html',
  styleUrls: ['./haulier-new-dialog.component.scss']
})
export class HaulierNewDialogComponent extends NewDialogDirective<Haulier> {

  constructor(
    hauliersService: HauliersService,
    haulierFormControl: HaulierFormControlService,
    @Inject(MAT_DIALOG_DATA) data: NewDialogData<Haulier>,
    public matDialog: MatDialogRef<HaulierNewDialogComponent>,
    private router: Router,
  ) {
    super(hauliersService, haulierFormControl, data);
  }

  successfulSave(result: Haulier) {
    this.router.navigate(['/', AppRoutes.OPERATIONS, OperationsRoutes.HAULIERS, result.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
