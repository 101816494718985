<gaia-shared-standard-dialog dialogTitle="Select Payment Method">
  <div content>
    <div i18n>Select the address you wish to set</div>
  </div>
  <div actions>
    <button mat-button mat-dialog-close="billing">Billing</button>
    <button mat-button mat-dialog-close="shipping">Shipping</button>
    <button mat-button mat-dialog-close="both">Both</button>
    <button mat-button mat-dialog-close="cancel">Cancel</button>
  </div>
</gaia-shared-standard-dialog>

