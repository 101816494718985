import { Component, OnInit } from '@angular/core';
import {StaffExpenseClaimsService} from '../staff-expense-claims.service';
import {StaffClaimSummary} from '../../team-space-concerns/staff-claims/staff-claim.model';
import {
  getCurrentRecordedMonthWindow,
  RecordedMonthWindow
} from '../../team-space-concerns/recorded-monthly/recorded-monthly.model';
import {STAFF_EXPENSE_CLAIM_CUT_OFF} from '../staff-expense-claim.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-staff-expense-claims-overview',
  templateUrl: './staff-expense-claims-overview.component.html',
  styleUrls: ['./staff-expense-claims-overview.component.scss']
})
export class StaffExpenseClaimsOverviewComponent implements OnInit {

  summaries: Array<StaffClaimSummary>;
  periodSelection: RecordedMonthWindow = getCurrentRecordedMonthWindow(STAFF_EXPENSE_CLAIM_CUT_OFF);
  gaiaIconSet = GaiaIconSet;
  displayedColumns = ['name', 'total'];

  constructor(
    private staffExpenseClaimsService: StaffExpenseClaimsService,
  ) { }

  ngOnInit(): void {
    this.update();
  }

  update(): void {
    this.staffExpenseClaimsService.getSummary(this.periodSelection.month, this.periodSelection.year).subscribe(
      (response) => {
        this.summaries = response;
        GaiaLogger.log(this.summaries);
      }
    );
  }

  periodChanged(window: RecordedMonthWindow) {
    this.periodSelection = window;
    this.update();
  }
}
