import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {RouterModule} from '@angular/router';
import {FlexModule} from '@angular/flex-layout';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {EventBookingModule} from '../event-bookings/event-booking.module';
import {What3WordsModule} from '@terravesta/phanes';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {WebEnquiriesService} from './web-enquiries.service';
import {WebEnquiryShowComponent} from './web-enquiry-show/web-enquiry-show.component';
import {WebEnquiryTableComponent} from './web-enquiry-table/web-enquiry-table.component';



@NgModule({
  declarations: [
    WebEnquiryShowComponent,
    WebEnquiryTableComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    GaiaSharedModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    GaiaGeneralModule,
    MatDialogModule,
    RouterModule,
    FlexModule,
    MatCheckboxModule,
    EventBookingModule,
    AddressesModule,
    What3WordsModule,
    MatTabsModule,
    MatDatepickerModule
  ],
  exports: [
    WebEnquiryShowComponent,
    WebEnquiryTableComponent,
  ],
  providers: [WebEnquiriesService]
})
export class WebEnquiriesModule { }
