import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {EChartsOption} from 'echarts';
import {MiscanthusHarvestsService} from '../../miscanthus-harvests.service';
import {
  DashboardWidgetDirective,
} from '../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {getHarvestYear} from '../../../../../utils/common-functions';
import {WidgetDefinition} from '../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';

@Component({
  selector: 'app-harvest-completion-summary-widget',
  templateUrl: './harvest-completion-summary-widget.component.html',
  styleUrls: ['./harvest-completion-summary-widget.component.scss']
})
export class HarvestCompletionSummaryWidgetComponent extends DashboardWidgetDirective implements OnInit{

  summaryChart: EChartsOption;
  harvestYear: number = getHarvestYear();

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private harvestService: MiscanthusHarvestsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(elementRef, renderer);
  }

  static widgetDefinition(): WidgetDefinition {
    return {
      name: 'Harvest Declaration Completion Chart',
      componentClass: HarvestCompletionSummaryWidgetComponent,
      description: 'Pie chart showing the status of current year harvest declarations',
      code: 'HARVEST-DEC-COMPLETION-CHART',
      grouping: 'Operations',
      department: 'Operations',
      minColumnCount: 2,
      minRowCount: 2,
    };
  }

  ngOnInit(): void {
  }

  updateGraph(year: number) {
    this.harvestService.completionStats(year).subscribe((response) => {
      this.summaryChart = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Completed',
            type: 'pie',
            radius: ['40%', '70%'],
            data: [
              { value: response.not_declared, name: 'Not Declared' },
              { value: response.completed, name: 'Completed' },
              { value: response.uncompleted, name: 'In Progress' },
            ]
          }
        ]
      };
    });
    this.changeDetectorRef.detectChanges();
  }

  processSettings(settings: any) {
  }
}
