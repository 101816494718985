
<ng-container *ngIf="form" [formGroup]="form">

  <ng-container *ngIf="!selectedField else showField">
    <ng-container *ngIf="fields else loadingFields">
      <gaia-shared-contracted-field-select [contractedFields]="fields" (fieldSelected)="selectField($event)"></gaia-shared-contracted-field-select>
    </ng-container>
    <ng-template #loadingFields>
      <span i18n>Getting growers field list</span>
    </ng-template>
  </ng-container>

  <ng-template #showField>
    <div fxLayout="column">
      <mat-form-field>
        <mat-label i18n>Selected Field</mat-label>
        <input matInput [value]="selectedField.name" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Bale Count</mat-label>
        <input matInput formControlName="bale_count" type="number" min="0" step="1" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Est Moisture Content</mat-label>
        <input matInput formControlName="estimated_moisture_content" type="number" min="0" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Added On</mat-label>
        <input matInput formControlName="added_to_stack" type="date"  />
      </mat-form-field>

      <ng-container *ngIf="stack.bale_type else chooseBaleType">
        <mat-form-field>
          <mat-label i18n>Bale Type</mat-label>
          <input matInput [value]="stack.bale_type.name" readonly />
          <mat-hint i18n>Stack bale type is already set, this cannot be changed</mat-hint>
        </mat-form-field>
      </ng-container>
      <ng-template #chooseBaleType>
        <app-bale-type-select formControlName="bale_type_id"></app-bale-type-select>
      </ng-template>
    </div>

  </ng-template>

</ng-container>
