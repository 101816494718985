import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationalRegionNewDialogComponent } from './operational-region-new-dialog/operational-region-new-dialog.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {OperationalRegionSelectorComponent} from './operational-region-selector/operational-region-selector.component';
import { OperationalRegionSelectDialogComponent } from './operational-region-select-dialog/operational-region-select-dialog.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {FlexModule} from '@angular/flex-layout';
import { OperationalRegionsTableComponent } from './operational-regions-table/operational-regions-table.component';
import { OperationalRegionShowComponent } from './operational-region-show/operational-region-show.component';
import {RouterModule} from '@angular/router';
import { OperationalRegionFormComponent } from './operational-region-form/operational-region-form.component';
import { OperationalRegionSelectComponent } from './operational-region-select/operational-region-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [OperationalRegionNewDialogComponent,
        OperationalRegionSelectorComponent,
        OperationalRegionSelectDialogComponent,
        OperationalRegionsTableComponent,
        OperationalRegionShowComponent,
        OperationalRegionFormComponent,
        OperationalRegionSelectComponent],
  exports: [
    OperationalRegionSelectorComponent,
    OperationalRegionSelectComponent
  ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatCardModule,
        GaiaGeneralModule,
        PhanesComponentsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        MatButtonModule,
        GaiaSharedModule,
        FlexModule,
        RouterModule,
        MatSelectModule
    ]
})
export class OperationalRegionsModule { }
