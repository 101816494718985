import {Component, forwardRef, Injector} from '@angular/core';
import {BaleTypesService} from '../bale-types.service';
import {BaleType} from '../bale-type.model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {RecordListSelectDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-bale-type-select',
  templateUrl: './bale-type-select.component.html',
  styleUrls: ['./bale-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaleTypeSelectComponent),
      multi: true
    }
  ]
})
export class BaleTypeSelectComponent extends RecordListSelectDirective<BaleType> {

  constructor(
    baleTypesService: BaleTypesService,
    inj: Injector,
  ) {
    super(baleTypesService, inj);
  }

}
