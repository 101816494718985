import {Component, forwardRef, Injector} from '@angular/core';
import {LoaderAvailability} from '../loader-availability.model';
import {LoaderAvailabilitiesService} from '../loader-availabilities.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {RecordListSelectDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-loader-availabilities-select',
  templateUrl: './loader-availabilities-select.component.html',
  styleUrls: ['./loader-availabilities-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoaderAvailabilitiesSelectComponent),
      multi: true
    }
  ]
})
export class LoaderAvailabilitiesSelectComponent extends RecordListSelectDirective<LoaderAvailability> {

  constructor(
    loaderAvailabilitiesService: LoaderAvailabilitiesService,
    inj: Injector
  ) {
    super(loaderAvailabilitiesService, inj);
  }

}
