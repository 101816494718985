<div [formGroup]="form" *ngIf="form" class="form-container">
  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>{{gaiaIconSet.my_record}}</mat-icon>
      <mat-card-title i18n="Header for users own details section">Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <gaia-shared-has-name-embed [formGroup]="form"></gaia-shared-has-name-embed>

        <mat-form-field>
          <mat-label i18n="input label for job title">Job Title</mat-label>
          <input matInput formControlName="job_title" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n="input label for email">Email Address</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>

        <gaia-shared-telephone-embed labelText="Company Mobile Number" i18n-labelText="Company mobile number"
          formGroupName="mobile_number"></gaia-shared-telephone-embed>

        <mat-form-field>
          <mat-label i18n="input label for office phone extension number">Extension Number</mat-label>
          <input matInput formControlName="extension" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n="input label for web description">Web Description</mat-label>
          <textarea matInput formControlName="web_description"></textarea>
        </mat-form-field>
        <mat-checkbox formControlName="web_visible">Show on website?</mat-checkbox>

        <app-company-office-select formControlName="primary_office_id" labelText="Primary Office" i18n-labelText=""></app-company-office-select>

    </mat-card-content>
  </mat-card>

  <gaia-shared-address-embed-form formGroupName="address"></gaia-shared-address-embed-form>

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>{{gaiaIconSet.management}}</mat-icon>
      <mat-card-title i18n="Header for users employment details section">Employment Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label i18n="input label for employment start_date">Employment Start Date</mat-label>
        <input matInput [matDatepicker]="employmentStartDate" formControlName="employment_start_date">
        <mat-datepicker-toggle matSuffix [for]="employmentStartDate"></mat-datepicker-toggle>
        <mat-datepicker #employmentStartDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n="input label for employment end_date">Employment End Date</mat-label>
        <input matInput [matDatepicker]="employmentEndDate" formControlName="employment_end_date">
        <mat-datepicker-toggle matSuffix [for]="employmentEndDate"></mat-datepicker-toggle>
        <mat-datepicker #employmentEndDate></mat-datepicker>
      </mat-form-field>

    </mat-card-content>
  </mat-card>

</div>
