import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactTypesListComponent } from './contact-types-list/contact-types-list.component';
import { ContactTypeFormDialogComponent } from './contact-type-form-dialog/contact-type-form-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ContactTypeSelectComponent} from './contact-type-select/contact-type-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [ContactTypesListComponent, ContactTypeFormDialogComponent, ContactTypeSelectComponent],
    exports: [
        ContactTypesListComponent, ContactTypeSelectComponent,
    ],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        PhanesComponentsModule,
        MatInputModule,
        FlexModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatCardModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        MatSelectModule
    ]
})
export class ContactTypesModule { }
