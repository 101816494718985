import { Component } from '@angular/core';
import {IndexTableDirective} from '../../components/index-table.directive';
import {Contact} from '../contact.model';
import {ContactsService} from '../contacts.service';
import {ContactNewDialogComponent} from '../contact-new-dialog/contact-new-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-contacts-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.scss']
})
export class ContactsTableComponent extends IndexTableDirective<Contact> {
  displayedColumns = ['email', 'first_name', 'surname', 'post_code', 'has_login', 'menu'];

  constructor(
    contactsService: ContactsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog
  ) {
    super(contactsService, router, activatedRoute, matDialog, ContactNewDialogComponent, null,
          ['post_code', 'email', 'surname']);
  }
}
