import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ApprovalPanelComponent} from './approval-panel/approval-panel.component';
import {
  StaffExpenseClaimsOverviewComponent
} from '../staff-expense-claims/staff-expense-claims-overview/staff-expense-claims-overview.component';

export class TeamSpaceAdminRoutes {
  public static APPROVAL_PANEL = 'approvals';
  public static EXPENSE_SUMMARY = 'expenses';
}

const routes: Routes = [
  {
    path: TeamSpaceAdminRoutes.APPROVAL_PANEL,
    component: ApprovalPanelComponent,
  },
  {
    path: TeamSpaceAdminRoutes.EXPENSE_SUMMARY,
    component: StaffExpenseClaimsOverviewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamSpaceAdminRoutingModule{ }
