import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {Product} from '../../products/product.model';
import {PriceGroupFormControlService} from '../price-group-form-control.service';
import {PricePoint} from '../price-group.model';

@Component({
  selector: 'app-price-group-product-form',
  templateUrl: './price-group-product-form.component.html',
  styleUrls: ['./price-group-product-form.component.scss'],
})
export class PriceGroupProductFormComponent implements OnInit {

  @Input()
  formGroup: FormGroup;

  constructor(
    private priceGroupFormBuilder: PriceGroupFormControlService,
  ) { }

  ngOnInit(): void {
  }

  productSelected(product: Product) {
    this.formGroup.patchValue({id: product.id, name: product.name, sku: product.sku});
  }

  removePricePoint(index: number) {
    const array = this.formGroup.get('price_points') as FormArray;
    array.controls[index].patchValue({to_delete: true});
  }

  addPricePoint() {
    const array = this.formGroup.get('price_points') as FormArray;
    array.push(this.priceGroupFormBuilder.priceToFormGroup({} as PricePoint));
  }

  calculateVatPrice(index: number) {
    const controls = (this.formGroup.get('price_points') as FormArray).controls[index];
    const exVatPrice = controls.get('ex_vat_price').value;
    const vatRate = controls.get('vat_rate').value;
    const incVat = (exVatPrice * ((100 + vatRate) / 100.0)).toFixed(2);
    controls.patchValue({vat_price: incVat});
  }

  calculateExVatPrice(index: number) {
    const controls = (this.formGroup.get('price_points') as FormArray).controls[index];
    const incVatPrice = controls.get('vat_price').value;
    const vatRate = controls.get('vat_rate').value;
    const exVat = (incVatPrice / ((100 + vatRate) / 100.0)).toFixed(2);
    controls.patchValue({ex_vat_price: exVat});
  }
}
