import {Product} from '../products/product.model';
import {Contact} from '../../contacts/contact.model';
import {Stockist} from '../stockists/stockist.model';
import {PriceGroup} from '../price-groups/price-group.model';
import {GaiaRecord} from '../../models/gaia-record.model';
import {CommercialCustomer} from '../concerns';
import {Address} from '@terravesta/gaia-shared';

export interface Order extends GaiaRecord {
  order_number: string;
  ordered_date: string; // Date
  delivery_notes: string;
  delivery_cost: number;
  customer_order_ref: string;
  billing_address: Address;
  shipping_address: Address;
  total_ex_vat: number;
  total_order_cost: number;
  order_status: string;
  order_status_enum: OrderStatus;
  order_source: string;
  order_source_enum: OrderSource;
  paid: boolean;
  paid_on: string; // DateTime
  payment_method: string;
  invoice_raised: Date;
  invoice_last_sent: string; // DateTime
  invoice_send_issue: string;
  invoice_payment_due: Date;
  shipped_on: Date;
  order_items: OrderItem[];
  customer: Customer;
  price_group_used?: PriceGroup;
  refunds: OrderRefund[];
}

export interface OrderRefund extends GaiaRecord {
  reason: string;
  refunded_on: string; // DateTime
  amount_refunded: number;
}

export interface OrderItem extends GaiaRecord {
  list_price: number;
  cost_price: number;
  ex_vat_sales_price: number;
  sales_price: number;
  quantity: number;
  discount: number;
  vat_rate: number;
  product: Product;
}

export enum CustomerType {
  stockist,
  direct,
  brand_ambassador,
  commercial_user
}


export interface Customer {
  type: string;
  type_enum: CustomerType;
  record: Contact | CommercialCustomer;
}

export enum OrderSource {
  gaia = 'gaia',
  salesforce = 'salesforce',
  shopify = 'shopify',
}

export enum PaymentMethods {
  shopify_payment = 'shopify_payment',
  cash = 'cash',
  bank_transfer = 'bank_transfer',
  paypal = 'paypal',
  unknown = 'unknown'
}

export enum OrderStatus {
  completed = 'completed',
  refunded = 'refunded',
  needing_fulfillment = 'needing_fulfillment',
  awaiting_payment = 'awaiting_payment',
  invoice_not_raised = 'invoice_not_raised'
}

export function getCustomerEmail(customer: Customer): string{
  switch (customer.type_enum){
    case CustomerType.stockist:
      return (customer.record as Stockist).billing_email;
    case CustomerType.direct:
      return (customer.record as Contact).email;
  }
}
