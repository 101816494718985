import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {DroneImage} from './drone-image.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DroneImageFormControlService implements GaiaRecordFormControl<DroneImage> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: DroneImage): FormGroup {
    return this.formBuilder.group({
      date_taken: new FormControl(record.date_taken),
      image_count: new FormControl(record.image_count),
      drone_operator: new FormControl(record.drone_operator),
      contracted_field_id: new FormControl(record.contracted_field ? record.contracted_field.id : ''),
    });
  }
}
