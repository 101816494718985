import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GrowersTableComponent} from './growers-table/growers-table.component';
import {GrowerShowComponent} from './grower-show/grower-show.component';
import {GrowersBaleHistoryComponent} from './growers-bale-history/growers-bale-history.component';

export class GrowerRoutes {
  public static BALE_HISTORIES = 'bale-histories'
}

const routes: Routes = [
  { path: GrowerRoutes.BALE_HISTORIES, component: GrowersBaleHistoryComponent},
  { path: '', component: GrowersTableComponent },
  { path: ':id', component: GrowerShowComponent },
  { path: ':id/contracted_fields', loadChildren: () => import('../contracted-fields/contracted-fields-routing.module')
      .then(m => m.ContractedFieldsRoutingModule), },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrowerRoutingModule { }
