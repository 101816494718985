import { Injectable } from '@angular/core';
import {GaiaRecord} from '../models/gaia-record.model';
import {BehaviorSubject} from 'rxjs';
import {GaiaLogger} from '../../utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class InternalNoteTrackingService {

  currentRecord: BehaviorSubject<GaiaRecord>;

  constructor(
  ) {
    this.currentRecord = new BehaviorSubject<GaiaRecord>(null);
  }

  setRecord(record: GaiaRecord) {
    this.currentRecord.next(record);
    GaiaLogger.log('Setting:', record);
  }

  unsetRecord() {
    this.currentRecord.next(null);
    GaiaLogger.log('unsetting:');
  }

}
