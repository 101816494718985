import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApprovalPanelComponent } from './approval-panel/approval-panel.component';
import { TeamSpaceAdminMenuComponent } from './team-space-admin-menu/team-space-admin-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {StaffExpenseClaimsModule} from '../staff-expense-claims/staff-expense-claims.module';
import {StaffAbsencesModule} from '../staff-absences/staff-absences.module';



@NgModule({
  declarations: [
    ApprovalPanelComponent,
    TeamSpaceAdminMenuComponent
  ],
  exports: [
    TeamSpaceAdminMenuComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        MatButtonModule,
        MatTabsModule,
        StaffExpenseClaimsModule,
        StaffAbsencesModule
    ]
})
export class TeamSpaceAdminModule { }
