import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {DashboardWidgetDirective} from '../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {EChartsOption} from 'echarts';
import {MiscanthusHarvestsService} from '../../miscanthus-harvests.service';
import {GaiaLogger, getHarvestYear} from '../../../../../utils/common-functions';
import {WidgetDefinition} from '../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';

@Component({
  selector: 'app-harvest-tonnage-summary-widget',
  templateUrl: './harvest-tonnage-summary-widget.component.html',
  styleUrls: ['./harvest-tonnage-summary-widget.component.scss']
})
export class HarvestTonnageSummaryWidgetComponent extends DashboardWidgetDirective implements OnInit {

  tonnageChart: EChartsOption;
  harvestYear = getHarvestYear();

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private miscanthusHarvestsService: MiscanthusHarvestsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(elementRef, renderer);
  }

  static widgetDefinition(): WidgetDefinition {
    return  {
      name: 'Harvest Tonnage Tracker',
      componentClass: HarvestTonnageSummaryWidgetComponent,
      description: 'Chart to show the current tonnages on the system',
      code: 'HARVEST-TONNAGE-CHART',
      grouping: 'Operations',
      department: 'Operations',
      minColumnCount: 4,
      minRowCount: 2,
    };
  }

  ngOnInit(): void {
  }

  updateGraph(year: number){
    this.miscanthusHarvestsService.tonnageSummary(year).subscribe((response) => {
      GaiaLogger.log(response);
      this.tonnageChart = {
        title: {
          text: year.toFixed(),
        },
        grid: [
          {

            left: '8%',
            width: '50%',
          },
          {

            right: '5%',
            width: '25%',
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: ['Declared', 'Accepted', 'Rejected'],
            gridIndex: 0
          },
          {
            type: 'category',
            gridIndex: 1,
          }
        ],
        tooltip: {
          trigger: 'axis',
        },
        yAxis: [
          {
            type: 'value',
            name: 'Tonnes',
            gridIndex: 0,

          },
          {
            type: 'value',
            name: 'Bales',
            gridIndex: 0,
          },
          {
            type: 'value',
            gridIndex: 1,
          }
        ],
        legend: {},
        dataset: [
          {
            source: [response.moisture_data]
          },
          {
            transform: {
              type: 'boxplot',
              config: { itemNameFormatter: 'Moisture Content' }
            }
          },
        ],
        series: [
          {
            name: 'Tonnages',
            data: [response.declared_tonnage, response.accepted_tonnage, response.rejected_tonnage],
            type: 'bar',
            yAxisIndex: 0,
            xAxisIndex: 0,
          },
          {
            name: 'Bales',
            data: [response.declared_bales, response.bales_accepted, response.bales_rejected],
            type: 'bar',
            yAxisIndex: 1,
            xAxisIndex: 0,
          },
          {
            name: 'Moisture Content',
            datasetIndex: 1,
            type: 'boxplot',
            xAxisIndex: 1,
            yAxisIndex: 2,
            encode: {
              tooltip: ['High', 'Q3', 'Q2', 'Q1', 'Low'],
            }
          }
        ]
      };
    });
    this.changeDetectorRef.detectChanges();
  }

  processSettings(settings: any) {
  }

}
