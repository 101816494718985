import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MiscanthusStack} from '../../miscanthus-stack.model';
import {BaleGain} from '../bale-gain.model';
import {BaleGainsService} from '../bale-gains.service';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {BaleGainDialogComponent} from '../bale-gain-dialog/bale-gain-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-bale-gains-embed-table',
  templateUrl: './bale-gains-embed-table.component.html',
  styleUrls: ['./bale-gains-embed-table.component.scss']
})
export class BaleGainsEmbedTableComponent implements OnInit, OnChanges {

  gaiaIconSet = GaiaIconSet;

  @Input()
  stack: MiscanthusStack;

  @Output()
  changeMade: EventEmitter<boolean> = new EventEmitter<boolean>();

  baleGains: Array<BaleGain>;
  displayedColumns = ['bales_added', 'reason', 'date_added', 'carry_over', 'menu'];

  constructor(
    private baleGainsService: BaleGainsService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stack){
      if (changes.stack.currentValue) {
        this.loadBaleGains();
      }
    }
  }

  loadBaleGains(){
    this.baleGainsService.getRecords({parentId: this.stack.id, pageNumber: 0, pageSize: 30})
      .subscribe((baleGains) => {
        this.baleGains = baleGains.records;
      });
  }

  editBaleGain(element: BaleGain) {
    const dialogRef = this.matDialog.open(BaleGainDialogComponent, {
      data: {
        record: element,
        parent: this.stack,
      }
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.loadBaleGains();
        this.changeMade.emit(true);
      }
    );
  }

  deleteBaleGain(baleGain: BaleGain) {
    const dialogRef = this.matDialog.open<any, any, boolean>(ConfirmDialogComponent, {
      data: {
        title: 'Confirm: Delete Bale Gain',
        message: 'Are you sure you wish to delete this bale gain record. This will effect the total number of bales in the stack. This action cannot be undone.'
      } as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.baleGainsService.deleteRecord({ recordId: baleGain.id, parentId: this.stack.id }).subscribe(() => {
          this.loadBaleGains();
          this.changeMade.emit(true);
        });
      }
    });
  }
}
