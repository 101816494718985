import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  BaleCollection, BaleCollectionCalendarItem, BaleCollectionUniversalSearchPreview,
  BatchCreationResults, GrowerMonthlyCollections,
  ProcessorRefChecks,
  WeightReportLoadResults
} from './bale-collection.model';
import {RestApiService} from '../../services/rest-api.service';
import {Observable, Subscription} from 'rxjs';
import * as FileSaver from 'file-saver';
import {Grower} from '../../growing/growers/grower.model';
import {flattenSearch} from '@terravesta/phanes';
import {UniversallySearchableService} from '../../universal-search/universally-searchable.service';

@Injectable({
  providedIn: 'root'
})
export class BaleCollectionsService extends RestApiService<BaleCollection> implements UniversallySearchableService<BaleCollectionUniversalSearchPreview>{

  constructor(httpClient: HttpClient) {
    super('bale_collections', 'bale_collection', httpClient);
  }

  calendarIndex(month: number, year: number, search: any): Observable<Array<BaleCollectionCalendarItem>> {
    const flattened = flattenSearch(search, 'search');
    return this.httpGet<Array<BaleCollectionCalendarItem>>(this.generateNonRecordUrl('calendar_index'), { month, year, ...flattened });
  }

  batchCreate(collectionData: any): Observable<BatchCreationResults> {
    return this.httpPost<BatchCreationResults>(this.generateNonRecordUrl('batch_create'), collectionData);
  }

  batchUpdate(collectionData: any): Observable<Array<boolean>> {
    return this.httpPost(this.generateNonRecordUrl('batch_update'), collectionData);
  }

  resendNotifications(baleCollection: BaleCollection): Observable<BaleCollection> {
    return this.httpPost<BaleCollection>(this.generateUrl({ recordId: baleCollection.id }, 'resend_notifications'), null);
  }

  checkProcessorRefs(processorRefs: Array<string>): Observable<Array<ProcessorRefChecks>> {
    return this.httpPost<Array<ProcessorRefChecks>>(this.generateNonRecordUrl('check_processor_refs'), { processor_refs: processorRefs });
  }

  invalidate(baleCollection: BaleCollection, invalidatedReason: string): Observable<BaleCollection> {
    return this.httpPost<BaleCollection>(this.generateUrl({ recordId: baleCollection.id}, 'invalidate'), {
      invalidated_reason: invalidatedReason,
    });
  }

  revalidate(baleCollection: BaleCollection): Observable<BaleCollection> {
    return this.httpPost<BaleCollection>(this.generateUrl({recordId: baleCollection.id}, 'revalidate'), null);
  }

  processWeightReport(weightReport: File): Observable<WeightReportLoadResults> {
    const formData = new FormData();
    formData.append('weight_report', weightReport);
    return this.httpPost<WeightReportLoadResults>(this.generateNonRecordUrl('process_weight_report'), formData);
  }

  processInvoiceReport(invoiceReport: File): Observable<WeightReportLoadResults> {
    const formData = new FormData();
    formData.append('invoice_report', invoiceReport);
    return this.httpPost<WeightReportLoadResults>(this.generateNonRecordUrl('process_invoice_report'), formData);
  }

  monthlyReport(month: number, year: number): Observable<Array<GrowerMonthlyCollections>> {
    return this.httpGet<Array<GrowerMonthlyCollections>>(this.generateNonRecordUrl('report_data'),
      { month, year });
  }

  downloadMonthReport(month: number, year: number, completed: () => void): Subscription {
    return this.httpGetBuffered(this.generateNonRecordUrl('monthly_report'),
      {
        month, year
      })
      .subscribe(
        (response) => {
          const file = new Blob([response], { type: 'application/csv' });
          FileSaver.saveAs(file, `${month}_${year}_collections_report.csv`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  downloadCollectionConfirmation(grower: Grower, month: number, year: number, completed: () => void): Subscription {
    return this.httpGetBuffered(this.generateNonRecordUrl('collection_confirmation_pdf'),
      {
        grower_id: grower.id,
        month, year
      })
      .subscribe(
        (response) => {
          const file = new Blob([response], { type: 'application/pdf' });
          FileSaver.saveAs(file, `bale_collection_confirmation_${grower.grower_code}_${month}_${year}.pdf`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  universalSearchPreviewData(recordId: string): Observable<BaleCollectionUniversalSearchPreview> {
    return this.httpGet(this.generateUrl({ recordId }, 'universal_search_preview_data'));
  }

}

