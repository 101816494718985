<mat-table [dataSource]="baleGains" [ngClass.lt-md]="'mobile-table'">


  <ng-container matColumnDef="bales_added">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Bales Added</span>
      <gaia-shared-cell-mobile-label i18n>Bales</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.bales_added}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="reason">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Reason</span>
      <gaia-shared-cell-mobile-label i18n>Reason</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.reason}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="carry_over">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Carry Over</span>
      <gaia-shared-cell-mobile-label i18n>Carry Over</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.carry_over">{{gaiaIconSet.check}}</mat-icon> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date_added">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Date Added</span>
      <gaia-shared-cell-mobile-label i18n>Added On</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.date_added | date}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
    <mat-cell class="w-75" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Stack menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editBaleGain(element)">
          <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
          <span i18n="edit">Edit</span>
        </button>
        <button mat-menu-item (click)="deleteBaleGain(element)">
          <mat-icon>{{gaiaIconSet.delete}}</mat-icon>
          <span i18n="edit">Delete</span>
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
