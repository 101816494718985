import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {UniversalSearchService} from '../universal-search.service';
import {FormControl} from '@angular/forms';
import {AppRoutes} from '../../app-routing.module';
import {Router} from '@angular/router';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {UniversalSearchResult} from '../universal-search.model';
import {GrowingRoutes} from '../../growing/growing-routing.module';
import {MatDialogRef} from '@angular/material/dialog';
import {OperationsRoutes} from '../../operations/operations-routing.module';

@Component({
  selector: 'app-universal-search-popup',
  templateUrl: './universal-search-popup.component.html',
  styleUrls: ['./universal-search-popup.component.scss']
})
export class UniversalSearchPopupComponent implements OnInit, AfterViewInit {

  @ViewChild('searchBox')
  input: ElementRef;

  gaiaIconSet = GaiaIconSet;

  searchTerm: FormControl;

  currentResults: Array<UniversalSearchResult>;
  previewResult: UniversalSearchResult = null;

  searching = false;

  constructor(
    private universalSearchService: UniversalSearchService,
    private router: Router,
    private matDialogRef: MatDialogRef<UniversalSearchPopupComponent>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.searchTerm = new FormControl();
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges.pipe(
      debounceTime(1500)
    ).subscribe((value) => {
      if(value) { // Deal with the blank, which I assume comes from the focus
        this.currentResults = null;
        this.previewResult = null;
        this.searching = true;
        this.universalSearchService.universalSearch(value).subscribe((results) => {
          this.currentResults = results;
          this.searching = false;
        });
      }
    });
  }

  ngAfterViewInit() {
    this.input.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }

  updatePreview(result: UniversalSearchResult) {
    this.previewResult = result;
    console.log("this.previewResult", this.previewResult)
  }

  getRecordIcon(classType: string, result: any) {
    // console.log("result", result)
    switch (classType) {
      case 'contact':
        return this.gaiaIconSet.contacts;
      case 'grower':
        if (result.data?.grower_code){
          return this.gaiaIconSet.growers;
        } else {
          return this.gaiaIconSet.leads;
        }
      case 'processor':
        return this.gaiaIconSet.processors;
      case 'haulier':
        return this.gaiaIconSet.hauliers;
      case 'contractor':
        return this.gaiaIconSet.contractors;
      case 'contracted_field':
        return this.gaiaIconSet.contracted_fields;
      case 'bale_collection':
        return this.gaiaIconSet.bale_collections;
      default:
        return this.gaiaIconSet.contact;
    }
  }

  private generateRoute(result: UniversalSearchResult): Array<string> {
    let url: Array<string>;
    switch (result.class_type) {
      case 'grower':
        url = ['/', AppRoutes.GROWING, GrowingRoutes.GROWERS, result.data.id];
        break;
      case 'contact':
        url = ['/', AppRoutes.CONTACTS, result.data.id];
        break;
      case 'haulier':
        url = ['/', AppRoutes.OPERATIONS, OperationsRoutes.HAULIERS, result.data.id];
        break;
      case 'contractor':
        url = ['/', AppRoutes.OPERATIONS, OperationsRoutes.CONTRACTORS, result.data.id];
        break;
      case 'processor':
        url = ['/', AppRoutes.OPERATIONS, OperationsRoutes.PROCESSORS, result.data.id];
        break;
      case 'bale_collection':
        url = ['/', AppRoutes.OPERATIONS, OperationsRoutes.BALE_COLLECTIONS, result.data.id];
        break;
      case 'contracted_field':
        url = ['/', AppRoutes.GROWING, GrowingRoutes.CONTRACTED_FIELDS, result.data.id];
        break;
      default:
        url = null
        break;
    }
    return url;
  }

  navigateToRecord(result: UniversalSearchResult) {
    const route = this.generateRoute(result);
    this.router.navigate(route).then(() => {
      this.matDialogRef.close();
    })
  }

  closeDialog() {
    this.matDialogRef.close();
  }
}
