import {GaiaRecord} from '../../models/gaia-record.model';
import {RequiresApproval} from '../team-space-concerns/requires-approval/requires-approval.model';
import {StaffMember} from '../../system-admin/staff-member/staff-member.model';

export interface StaffAbsence extends GaiaRecord, RequiresApproval {

  start_date_time: Date;
  end_date_time: Date;
  in_days: number;
  staff_member: StaffMember;

  reason: string;
  absence_type_enum: StaffAbsenceTypes;
  absence_type: string;

  start_in_afternoon: boolean;
  return_in_afternoon: boolean;
}

export enum StaffAbsenceTypes {
  leave = 'leave',
  absence = 'absence',
}
