import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {GaiaLogger} from '../../utils/common-functions';
import {toInteger} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserCookiesService {

  static REDIRECT_COOKIE = 'gaia-redirect';
  static GAIA_TOKEN = 'gaia_token-expiry';

  constructor(
    private router: Router,
    private cookieService: CookieService,
  ) {

  }

  getCurrentTokenExpiryTime(): number{
    const timeString = this.cookieService.get(UserCookiesService.GAIA_TOKEN);
    return toInteger(timeString);
  }

  setRedirectCookie() {
    GaiaLogger.log('setting redirect cookie', this.router.url);
    if (!this.cookieService.check(UserCookiesService.REDIRECT_COOKIE)) {
      this.cookieService.set(UserCookiesService.REDIRECT_COOKIE, this.router.url,
                      { expires: 1, path: '/' });
    }
  }

  getRedirectedCookieAndClear(): string {
    let cookieValue = null;
    GaiaLogger.log('getting cookie');
    if (this.cookieService.check(UserCookiesService.REDIRECT_COOKIE)){
      GaiaLogger.log('cookie found');
      cookieValue = decodeURI(this.cookieService.get(UserCookiesService.REDIRECT_COOKIE));
      GaiaLogger.log(cookieValue);
      this.cookieService.delete(UserCookiesService.REDIRECT_COOKIE);
    }
    return cookieValue;
  }

}
