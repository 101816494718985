import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {GrowerContractsTableComponent} from './grower-contracts-table/grower-contracts-table.component';
import {GrowerContractShowComponent} from './grower-contract-show/grower-contract-show.component';


const routes: Routes = [
  { path: '', component: GrowerContractsTableComponent },
  { path: ':id', component: GrowerContractShowComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrowerContractsRoutingModule { }
