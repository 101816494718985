<app-show-record-wrapper *ngIf="order" [form]="form">

  <ng-container menu>
    <div fxLayout="row" fxLayoutGap="5px">
      <button *ngIf="!this.order.paid" mat-raised-button color="primary" type="button" i18n (click)="markAsPaid()">Mark as Paid</button>
      <button *ngIf="!this.order.shipped_on" mat-raised-button color="primary" type="button" i18n (click)="fulfill()">Mark as Fulfilled</button>
      <button *ngIf="!this.order.invoice_last_sent" mat-raised-button color="primary" type="button" i18n (click)="sendInvoice()">Send {{invoiceText}}</button>
      <gaia-shared-spinner-loading-button [color]="'primary'" #invoiceDownload (clickOccurred)="downloadInvoice()" i18n>Download {{invoiceText}}</gaia-shared-spinner-loading-button>
      <gaia-shared-spinner-loading-button [color]="'primary'" #deliveryNoteDownload (clickOccurred)="downloadDeliveryNote()" i18n>Download Delivery Note</gaia-shared-spinner-loading-button>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex>
      <mat-chip-list aria-label="Order Statuses">
        <mat-chip i18n *ngIf="this.order.paid">Paid {{ this.order.paid_on | date: "shortDate" }}</mat-chip>
        <mat-chip i18n *ngIf="this.order.shipped_on">Shipped {{ this.order.shipped_on | date: "shortDate" }}</mat-chip>
      </mat-chip-list>
    </div>
  </ng-container>
  <app-order-form [form]="form" [customer]="order.customer"></app-order-form>

</app-show-record-wrapper>
