<div fxLayout="row" fxLayoutGap="15px" [formGroup]="formGroup" *ngIf="formGroup">
  <mat-form-field>
    <mat-label i18n="month select label">Month</mat-label>
    <mat-select formControlName="month" (selectionChange)="doChange()">
      <mat-option *ngFor="let i of 12 | repeatFor" [value]="i+1">
        {{ "2020-" + (i+1) + "-1"  | date: 'MMMM'}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label i18n="year select label">Year</mat-label>
    <mat-select formControlName="year" (selectionChange)="doChange()">
      <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
