import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ContractedField} from '../contracted-field.model';
import {EChartsOption} from 'echarts';
import {ContractedFieldsService} from '../contracted-fields.service';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-contracted-field-harvest-graph',
  templateUrl: './contracted-field-harvest-graph.component.html',
  styleUrls: ['./contracted-field-harvest-graph.component.scss']
})
export class ContractedFieldHarvestGraphComponent implements OnInit, OnChanges {

  @Input()
  contractedField: ContractedField;

  harvestChart: EChartsOption;

  constructor(private contractedFieldsService: ContractedFieldsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.contractedFieldsService.getHarvestSeries(this.contractedField).subscribe(
      (series) => {
        this.harvestChart = {
          title: {
            text: 'Harvest Series'
          },
          xAxis: {
            type: 'category',
            data: series.series.map((value) => value.year),
          },
          legend: {
            show: true,
          },
          yAxis: [
            {
              type: 'value',
              name: 'Bale Count',
            },
            {
              type: 'value',
              name: 'T/ha',
              position: 'right',
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          series: [
            {
              data: series.series.map((value) => value.bales),
              name: 'Bale Count',
              type: 'bar',
              yAxisIndex: 0
            },
            {
              data: series.series.map((value) => value.per_ha),
              name: 'T/ha',
              type: 'bar',
              yAxisIndex: 1
            },
          ]
        };
        GaiaLogger.log(this.harvestChart);
      });
  }

}
