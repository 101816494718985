<div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
  <mat-form-field>
    <mat-label i18n>{{ label }}</mat-label>
    <mat-select
      (selectionChange)="selected($event)"
      [formControl]="control"
      ngDefaultControl>
      <mat-option *ngFor="let contact of contacts | async" [value]="contact.id"> {{ contact.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div i18n="Or for contact select or create contact">Or</div>

  <div>
    <button class="new-contact" mat-raised-button color="primary" (click)="newContact()">{{ newButtonText }}</button>
  </div>
</div>
<div *ngIf="selectedContact">
  <app-contact-form-simple [record]="selectedContact" [account]="account" [form]="contactForm"></app-contact-form-simple>
  <div *ngIf="contactForm.errors">
    <div *ngIf="contactForm.errors.requires_phone" i18n>Please supply a phone number, this is required for loading contact</div>
  </div>
  <button mat-raised-button color="primary" (click)="updateContact()" [disabled]="!(contactChanged | async)">
    <ng-container *ngIf="selectedContact.id else create" i18n>Update Contact</ng-container>
    <ng-template #create><span i18n>Create Contact</span></ng-template>
  </button>
</div>
