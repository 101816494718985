<mat-form-field appearance="outline">
  <mat-label> {{labelText}} </mat-label>
  <mat-select [formControl]="control" (selectionChange)="selectionChange.emit($event)" [(ngModel)]="this.selectDefault"
              ngDefaultControl>
    <mat-option *ngFor="let item of records" [value]="item.id">
      {{item.name}}
    </mat-option>
  </mat-select>
  <mat-icon matSuffix>satellite</mat-icon>
</mat-form-field>
