import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HaulierVehiclesService} from '../haulier-vehicles.service';
import {HaulierVehicle} from '../haulier-vehicle.model';
import {Haulier} from '../../haulier.model';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';

@Component({
  selector: 'app-haulier-vehicles-embed-table',
  templateUrl: './haulier-vehicles-embed-table.component.html',
  styleUrls: ['./haulier-vehicles-embed-table.component.scss']
})
export class HaulierVehiclesEmbedTableComponent implements OnInit, OnChanges {

  @Input()
  haulier: Haulier;

  vehicles: Array<HaulierVehicle>;

  displayedColumns = ['registration', 'type', 'bale_capacity', 'menu'];
  gaiaIconSet = GaiaIconSet;

  constructor(
    private haulierVehiclesService: HaulierVehiclesService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.haulier){
      if (changes.haulier.currentValue) {
        this.loadVehicles();
      }
    }
  }

  loadVehicles(){
    this.haulierVehiclesService.getRecords({parentId: this.haulier.id, pageNumber: 0, pageSize: 30})
      .subscribe((vehicles) => {
        this.vehicles = vehicles.records;
      });
  }

  editVehicle(element: HaulierVehicle) {

  }
}
