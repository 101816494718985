import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {StaffMember} from '../staff-member.model';
import {StaffMemberFormControlService} from '../staff-member-form-control.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {SessionDataService} from '../../../services/session-data.service';
import {StaffMemberService} from '../staff-member.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-staff-member-show',
  templateUrl: './staff-member-show.component.html',
  styleUrls: ['./staff-member-show.component.scss']
})
export class StaffMemberShowComponent extends ShowPageDirective<StaffMember> implements OnInit {

  gaiaIconSet = GaiaIconSet;
  imageName: string;

  constructor(
    staffMemberService: StaffMemberService,
    staffMemberFormControl: StaffMemberFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    private sessionData: SessionDataService,
  ) {
    super(staffMemberService, staffMemberFormControl, route, snackBar, dialog, null, changeDetectorRef);
  }


  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    if (id == null){
      id = this.sessionData.getUser().id;
    }
    this.recordService.getRecord({recordId: id}).subscribe(
      (record) => {
        this.updateRecord(record);
      }
    );
  }

  recordGot(record: StaffMember) {
    this.imageName = `${record.name} profile`;
  }

  recordLoadCompleted() {
    const currentUser = this.sessionData.getUser();
    (this.recordFormControl as StaffMemberFormControlService).updatePermissions(this.form, currentUser.has_hr_access);
  }

}
