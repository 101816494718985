import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MiscanthusStack} from '../../miscanthus-stack.model';

@Component({
  selector: 'app-bale-loss-form',
  templateUrl: './bale-loss-form.component.html',
  styleUrls: ['./bale-loss-form.component.scss']
})
export class BaleLossFormComponent {

  @Input()
  form: FormGroup;

  @Input()
  stack: MiscanthusStack;

  constructor() { }
}
