<app-new-record-dialog [form]="form" dialogTitle="Add Bales to Stack" i18n-dialogTitle (saveClicked)="save()" [dialogRef]="matDialog">

  <phanes-error-panel></phanes-error-panel>

  <ng-container *ngIf="form" [formGroup]="form">

    <app-miscanthus-field-harvest-form [form]="form" [record]="record" [stack]="data.parent">
    </app-miscanthus-field-harvest-form>

  </ng-container>
</app-new-record-dialog>
