<div fxLayout="column" *ngIf="record">
  <app-show-page-header [recordIcon]="record.grower_code ? gaiaIconSet.growers : gaiaIconSet.leads" recordType="Grower" [displayName]="record.grower_code">
    <div header-content>
      <div fxLayout="row">
        <div fxLayout="row" fxFlex="50%">
          <app-header-warning warningHeader="Field/Contract Mismatch" i18n-warningHeader="" *ngIf="record.contract_field_size_mismatch">
            Contracted Field Size: {{ record.current_contract_total_ha | number : '1.1-2'}}ha
            <br />
            Field Measurement Size: {{ record.total_field_planted_ha | number : '1.1-2'}}ha
          </app-header-warning>
        </div>
        <div fxLayout="row" fxFlex="50%" fxLayoutAlign="end start">
          <app-grower-badge-panel [record]="record"></app-grower-badge-panel>
        </div>
      </div>
    </div>
    <app-grower-badge-panel [record]="record" [drawBackground]="true"></app-grower-badge-panel>

  </app-show-page-header>

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Grower Details</span>
      </ng-template>
      <app-show-record-wrapper [form]="form">
        <ng-container menu>
        </ng-container>
        <app-grower-form [record]="record" [form]="form" fxFlex="100%"></app-grower-form>
      </app-show-record-wrapper>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Contacts</ng-container>
      </ng-template>
      <ng-template matTabContent>
        <app-contact-list-embed [account]="record"></app-contact-list-embed>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Contracts</span>
      </ng-template>
      <ng-template matTabContent>
        <app-grower-contract-overview [grower]="record"></app-grower-contract-overview>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Fields</span>
      </ng-template>
      <ng-template matTabContent>
        <app-contracted-fields-embed [grower]="record"></app-contracted-fields-embed>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Contractors</ng-container>
      </ng-template>
      <ng-template matTabContent>
        <app-grower-contractor-view [grower]="record"></app-grower-contractor-view>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Harvests</ng-container>
      </ng-template>
      <ng-template matTabContent>
        <app-grower-harvest-overview [grower]="record"></app-grower-harvest-overview>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Planting Opportunities</ng-container>
      </ng-template>
      <ng-template matTabContent>
        <app-planting-opportunity-embed-table [grower]="record"></app-planting-opportunity-embed-table>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</div>
