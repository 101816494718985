import {Component, Input, OnInit} from '@angular/core';
import {EChartsOption} from 'echarts';

@Component({
  selector: 'app-dashboard-widget-chart',
  templateUrl: './dashboard-widget-chart.component.html',
  styleUrls: ['./dashboard-widget-chart.component.scss']
})
export class DashboardWidgetChartComponent implements OnInit {

  @Input()
  chartSettings: EChartsOption;

  @Input()
  height: number|string = '100px';

  constructor() { }

  ngOnInit(): void {
  }

}
