import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {SoilMeasurement} from './soil-measurement.model';

@Injectable({
  providedIn: 'root'
})
export class SoilMeasurementsService extends RestApiService<SoilMeasurement> {

  constructor(
    httpClient: HttpClient
  ) {
    super('soil_measurements', 'soil_measurement', httpClient);
  }
}
