<app-new-record-dialog [form]="form" [record]="record"
                       dialogTitle="New Soil Sample" i18n-dialogTitle (saveClicked)="save()" [dialogRef]="dialogRef">
  <ng-container *ngIf="showCode else showForm">

    <h1 class="sample-code">Soil Sample Code: {{ newSoilSample.unique_id }}</h1>

    <a mat-raised-button color="primary" (click)="redirectToForm()">Show Form</a>
  </ng-container>
  <ng-template #showForm>
    <app-soil-sample-create-form [form]="form" [record]="record"></app-soil-sample-create-form>
  </ng-template>
</app-new-record-dialog>
