<app-dashboard-widget-wrapper componentTitle="My Surveys" (deleteClicked)="deleteWidget()" [editMode]="editMode"
  (widgetMoved)="widgetMoved($event)" [dashboard]="dashboard">

  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>Today ({{ todaySurveys.length }})</ng-template>
      <div class="survey-list">
        <mat-card *ngFor="let op of todaySurveys">
          <mat-card-header>
            <mat-card-title>{{ op.grower.account_name }}</mat-card-title>
            <mat-card-subtitle> {{ op.farm_survey_date | date:'medium' }} </mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <button mat-raised-button [routerLink]="['/', appRoutes.GROWING, growingRoutes.PLANTING_OPPORTUNITIES, op.id]">View</button>
            <a mat-raised-button [href]="toNavigate(op)" target="_blank">Navigate</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>This Week ({{ upcomingSurveys.length }})</ng-template>
      <div class="survey-list">
        <mat-card *ngFor="let op of upcomingSurveys">
          <mat-card-header>
            <mat-card-title>{{ op.grower.account_name }}</mat-card-title>
            <mat-card-subtitle> {{ op.farm_survey_date | date:'medium' }} </mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <button mat-raised-button [routerLink]="['/', appRoutes.GROWING, growingRoutes.PLANTING_OPPORTUNITIES, op.id]">View</button>
            <a mat-raised-button [href]="toNavigate(op)" target="_blank">Navigate</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>


</app-dashboard-widget-wrapper>
