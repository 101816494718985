import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../services/rest-api.service';
import {map} from 'rxjs/operators';
import {Asset, AssetCategory} from "./asset.model";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
// export class AssetManagementService extends RestApiService<Asset> {

// export class AssetManagementService  {
export class AssetManagementService extends RestApiService<Asset> {

  // constructor(private http: HttpClient) {
  //   // super('assets', 'asset', http);
  // }
  constructor(httpClient: HttpClient) {
    super('', '', httpClient);
  }

  loadAllAssets(filterParams?: any): Observable<Asset[]>{
    return this.httpClient.get<Asset[]>(environment.server_url + `/company_assets?filter_params=${filterParams}`, { withCredentials: true })
      .pipe(map((response: Asset[]) => {
        console.log(response)
        // return response["company_assets"];
        return response;
      }));



    // // working code
    // return this.httpGet<Asset[]>(environment.server_url + `/company_assets`)
    //   .pipe(map((response: Asset[]) => {
    //     console.log(response)
    //     return response;
    //   }));
  }

  saveAsset(asset: any) {
    return this.httpClient.post(environment.server_url + `/company_assets`, asset, { withCredentials: true })
  }

  updateAsset(id: any, asset: any) {
    return this.httpClient.put(environment.server_url + `/company_assets/${id}`, asset, { withCredentials: true })
  }

  is_name_exists(name: any) {
    return this.httpClient.get(environment.server_url + `/company_assets/is_name_exists?name=${name}`, { withCredentials: true })
  }

  loadAssetCategories(): Observable<AssetCategory[]>{
    // map((response: AssetCategory[]) => {

    return this.httpClient.get<AssetCategory[]>(environment.server_url + `/company_assets/company_asset_categories`, { withCredentials: true })
      .pipe(
        map((response: AssetCategory[]) => {
          return response["categories"];
      }));
  }

  findAsset(id: any): Observable<Asset>{
    return this.httpClient.get<Asset>(environment.server_url + `/company_assets/` + id, { withCredentials: true })
  }

  loadAssetDependencies(): Observable<any>{
    return this.httpClient.get<any>(environment.server_url + `/company_assets/company_asset_dependencies`, { withCredentials: true })
      // .pipe(
      //   map((response: AssetCategory[]) => {
      //     return response["categories"];
      //   }));
  }

  get_terravesta_reference(entryType: string, selectedCtegoryId: string, assetId: string): Observable<any> {
    return this.httpClient.get<any>(environment.server_url + `/company_assets/get_terravesta_reference?entry_type=${entryType}&selected_ctegory_id=${selectedCtegoryId}&asset_id=${assetId}`, { withCredentials: true })
  }

  saveAssetImage(formData: any): Observable<any> {
    return this.httpClient.post(environment.server_url + `/company_asset_images`, formData, { withCredentials: true })
  }

  loadAssetImages(assetId: string): Observable<any> {
    return this.httpClient.get(environment.server_url + `/company_asset_images/asset_images?asset_id=${assetId}`, { withCredentials: true })
  }

  deleteAssetImage(id: string): Observable<any> {
    return this.httpClient.delete(environment.server_url + `/company_asset_images/${id}`, { withCredentials: true })
  }

  downloadAssetList(filter_params: any): Observable<any> {
    return this.httpClient.get(environment.server_url + `/company_assets/download_assets_list?filter_params=${filter_params}`, { responseType: 'blob', withCredentials: true })
  }

  assetsStats(): Observable<any>{
    return this.httpClient.get<any>(environment.server_url + `/company_assets/assets_stats`, { withCredentials: true })
  }
}
