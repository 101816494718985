import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {MatPaginator} from '@angular/material/paginator';
import {DroneImagesService} from '../drone-images.service';
import {DroneImage} from '../drone-image.model';
import {GaiaLogger, yearArray} from '../../../../utils/common-functions';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {FormControl, FormGroup} from '@angular/forms';
import {LngLatLike} from 'mapbox-gl';
import {copiedToClipboard} from '../../../../utils/common-snackbars';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Clipboard} from '@angular/cdk/clipboard';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-drone-image-viewer',
  templateUrl: './drone-image-viewer.component.html',
  styleUrls: ['./drone-image-viewer.component.scss']
})
export class DroneImageViewerComponent implements OnInit, AfterViewInit {

  displayedColumns = ['field', 'grower', 'date_taken'];
  dataSource: GaiaIndexDataSource<DroneImage>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild ('kmlDownload') kmlDownload: SpinnerLoadingButtonComponent;
  @ViewChild ('searchButton') searchFinished: SpinnerLoadingButtonComponent;
  @ViewChild ('addHHButton') addHHButton: SpinnerLoadingButtonComponent;
  selectedImage: DroneImage;
  growerCode: string;
  gaiaIconSet = GaiaIconSet;
  plantingYear: number[] = yearArray(2000, new Date().getFullYear()).reverse();
  plantingYearForm = new FormGroup({
    year_planted: new FormControl(),
  });
  selectedPlantingYear: number;
  droneImage: Array<DroneImage>;
  mapCenter: LngLatLike = [-1.313874, 53.196055];
  public latestDroneImage: Array<any> = [];
  urlString: string;
  showTable = false;
  addToHH = false;
  releaseBtn = false;
  releasedToHH: boolean = false;
  copyIdBtn = false;

  constructor(
    private droneImageService: DroneImagesService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.dataSource = new GaiaIndexDataSource<DroneImage>(this.droneImageService);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.getRecords().subscribe(() => {
      this.searchFinished.processFinished();
    });
  }

  doSearch(){
    this.copyIdBtn = false;
    this.releaseBtn = false;
    this.releasedToHH = false;
    this.selectedPlantingYear = this.plantingYearForm.get('year_planted').value
    this.dataSource.search(this.searchOpts());
    this.showTable = true;
  }

  searchOpts(): any {
    const search = {
      year_planted: null,
      contracted_field: null
    }
   /* if (this.searchStartDate && this.searchEndDate){
      search.date_taken = `${dateToQueryString(this.searchStartDate)} - ${dateToQueryString(this.searchEndDate)}`;
    }*/
    if (this.selectedPlantingYear){
      search.contracted_field = {  year_planted: this.selectedPlantingYear};
    }
    /*if (this.growerCode){
      const fuzzySearch = `${this.growerCode}*`;
      search.contracted_field = { grower: {grower_code: fuzzySearch}};
    }*/
    return search;
  }

  showDroneImage(element: DroneImage) {
    this.copyIdBtn = true
    this.selectedImage = element;
    this.releasedToHH = this.selectedImage.release_to_harvest_hub;
    this.urlString = 'mapbox://michaelsterravesta.' + this.selectedImage.mapbox_id;
    this.mapCenter = [this.selectedImage.contracted_field.longitude, this.selectedImage.contracted_field.latitude];
    GaiaLogger.log(this.latestDroneImage);
    this.releaseBtn = true; // show addtoHH button
  }

  searchOptsDroneImage(field): any {
    return {
      contracted_field: {
        uuid: field.id,
      },
    };
  }

  downloadKml() {
    this.droneImageService.downloadKML(() => {
      this.kmlDownload.processFinished();
    });
  }

  releaseToHH() {
    this.addToHH = true;
    this.droneImageService.releaseHH(this.addToHH, this.selectedImage.id).subscribe((record) => {
      //this.droneImageService.getRecord({recordId: this.selectedImage.id}).subscribe((record) => {
        this.showDroneImage(record);
        //this.doSearch();
        this.dataSource.updateRecord(record);
        this.addHHButton.processFinished();
      });
  //});
  }

  copyIdToClipboard() {
    const result = this.clipboard.copy(this.selectedImage.id);
    if (result){
      copiedToClipboard(this.snackBar);
    }
  }

  copyMbIdToClipboard() {
    const result = this.clipboard.copy(this.selectedImage.mapbox_id);
    if (result){
      copiedToClipboard(this.snackBar);
    }
  }
}
