<ng-container *ngIf="form" [formGroup]="form">
  <div class="form-fields">
    <mat-card class="half-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.contracted_fields}}</mat-icon>
        <mat-card-title i18n>Field Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <mat-label i18n>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Parcel Number</mat-label>
          <input matInput formControlName="field_reference_number" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Year Planted</mat-label>
          <input matInput formControlName="year_planted" type="number" />
        </mat-form-field>

        <app-miscanthus-variety-select formControlName="planted_variety_id"></app-miscanthus-variety-select>

        <div class="planting-dates">

          <mat-form-field>
            <mat-label i18n>Date Planting Started</mat-label>
            <input matInput [matDatepicker]="startedPlantingDatePicker" formControlName="date_planting_started">
            <mat-datepicker-toggle matSuffix [for]="startedPlantingDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startedPlantingDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n>Date Planting Ended</mat-label>
            <input matInput [matDatepicker]="endedPlantingDatePicker" formControlName="date_planting_ended">
            <mat-datepicker-toggle matSuffix [for]="endedPlantingDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endedPlantingDatePicker></mat-datepicker>
          </mat-form-field>

        </div>

      </mat-card-content>
    </mat-card>

    <mat-card class="quarter-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.field_size}}</mat-icon>
        <mat-card-title i18n>Sizes</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <mat-label i18n>Total Field Area(ha)</mat-label>
          <input matInput formControlName="total_field_area_ha" type="number" step="0.01" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Planted Area(ha)</mat-label>
          <input matInput formControlName="planted_area_ha" type="number" step="0.01" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Planting Density</mat-label>
          <input matInput formControlName="planting_density" type="number" step="0.01" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Non Planted Area(ha)</mat-label>
          <input matInput formControlName="non_planted_area_ha" type="number" step="0.01" />
        </mat-form-field>

        <ng-container *ngIf="record.geo_data">
          <mat-form-field>
            <mat-label i18n>Calculated Area(ha)</mat-label>
            <input matInput type="number" [disabled]="true" [value]="record.geo_data.area_ha | number: '1.0-2'" />
          </mat-form-field>

          <mat-form-field>
            <mat-label i18n>Calculated Perimeter(m)</mat-label>
            <input matInput type="number" [disabled]="true" [value]="record.geo_data.perimeter_m | number: '1.0-2'" />
          </mat-form-field>
        </ng-container>

      </mat-card-content>
    </mat-card>

    <mat-card class="quarter-card" *ngIf="isReplantingField && record.replanted_field">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.contracted_fields}}</mat-icon>
        <mat-card-title i18n>Replanting</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <div class="replanting-field">
        <!--<mat-form-field>
          <mat-label i18n>Replanted Year</mat-label>
          <input matInput [disabled]="true" [value]="record.replanted_field.year_replanted"/>
        </mat-form-field>-->
       <!-- <mat-form-field>
          <mat-label>Total Replanting for</mat-label>
          <mat-select [formControl]="yearSelection">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>-->

        <!--<mat-form-field *ngIf="record.replanted_field">
          <mat-label i18n>Replanted Area(ha)</mat-label>
          <input matInput [disabled]="true" [value]="record.replanted_field.replanted_area_ha"/>
        </mat-form-field>-->
        <ng-container *ngIf="record.replanted_field">
          <mat-form-field *ngFor="let replanted of record.replanted_field">
            <mat-label i18n>Replanted Area(ha) - {{ replanted.year_replanted }}</mat-label>
            <input matInput [disabled]="true" [value]="replanted.replanted_area_ha" />
          </mat-form-field>
        </ng-container>
      </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="quarter-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.flag}}</mat-icon>
        <mat-card-title i18n>Admin Flags</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-slide-toggle formControlName="own_use" i18n>Own Use?</mat-slide-toggle>
        <mat-slide-toggle formControlName="is_game_cover" i18n>Is Game Cover?</mat-slide-toggle>
        <mat-slide-toggle formControlName="mixed_year_planting" i18n>Mixed Year Planting?</mat-slide-toggle>
<!--        <mat-slide-toggle formControlName="auto_mapping_override" i18n>Prevent Auto Mapping?</mat-slide-toggle>-->
        <mat-slide-toggle formControlName="replanting" i18n (change)="toggleReplanting($event.checked);">FOC Replanting?</mat-slide-toggle>
        <mat-form-field id = "year_replanted" *ngIf="isReplantingField">
          <mat-label i18n>Replanting Year</mat-label>
          <mat-select formControlName="year_replanted">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field id = "replanted_area_ha" *ngIf="isReplantingField">
          <mat-label i18n>Replanting Area</mat-label>
          <input matInput formControlName="replanted_area_ha" type="number" step="0.01" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>

</ng-container>
