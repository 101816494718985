import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {TermContract} from './term-contract.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ContractsFormControlService} from '../contracts-form-control.service';

@Injectable({
  providedIn: 'root'
})
export class TermContractFormControlService implements GaiaRecordFormControl<TermContract> {

  constructor(
    private formBuilder: FormBuilder,
    private contractFormService: ContractsFormControlService
  ) {
  }

  toFormGroup(record: TermContract): FormGroup {
    const group = this.formBuilder.group({
      terminated: new FormControl(record.terminated),
    });
    this.contractFormService.addMiscanthusContractFields(group, record);
    this.contractFormService.addSupplyContractFields(group, record);
    return group;
  }
}
