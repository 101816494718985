import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {InternalNoteTrackingService} from '../internal-note-tracking.service';
import {GaiaRecord} from '../../models/gaia-record.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  InternalNotesPopupComponent,
  InternalNotesPopupData
} from '../internal-notes-popup/internal-notes-popup.component';
import {MatButton} from '@angular/material/button';
import {InternalNotesService} from '../internal-notes.service';

@Component({
  selector: 'app-internal-notes-embed-tool',
  templateUrl: './internal-notes-embed-tool.component.html',
  styleUrls: ['./internal-notes-embed-tool.component.scss']
})
export class InternalNotesEmbedToolComponent implements OnInit, OnChanges {

  gaiaIconSet = GaiaIconSet;
  canRecordNote = true;
  dialogRef: MatDialogRef<InternalNotesPopupComponent>;
  dialogOpen = false;
  noteCount: number = null;

  @ViewChild('notesButton')
  button: MatButton;

  @Input()
  record: GaiaRecord;

  constructor(
    private internalNoteTracker: InternalNoteTrackingService,
    private internalNotesService: InternalNotesService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.record){
      if(changes.record.currentValue){
        if(changes.record.currentValue != changes.record.previousValue) {
          this.updateCount();
        }
      }
    }
  }

  updateCount() {
    this.internalNotesService.getNoteCount(this.record).subscribe(
      (count) => { this.noteCount = count; }
    );
  }

  showNotesPopup() {
    if (!this.dialogOpen) {
      const element = this.button._elementRef.nativeElement;
      this.dialogRef = this.matDialog.open<InternalNotesPopupComponent, InternalNotesPopupData, any>(InternalNotesPopupComponent,
        {
          data: {
            record: this.record,
            // position: element.getBoundingClientRect(),
          },
          //width: '300px',
          hasBackdrop: true,
        });
      this.dialogOpen = true;
      const updateListener = this.dialogRef.componentInstance.notesUpdated.subscribe(() => {
        this.updateCount();
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogOpen = false;
        this.updateCount()
        updateListener.unsubscribe();
      });
    }else{
      this.dialogRef.close();
    }
  }
}
