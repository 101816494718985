<div>
  <button id="addStaff" mat-raised-button  color="primary" *ngIf="this.currentUser.has_hr_access" mat-button (click)="openDialog()" matTooltip="Add Staff">
    <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
  </button>
</div>
<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>List</span>
    </ng-template>

    <mat-table [dataSource]="currentStaffList">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="job_title">
        <mat-header-cell *matHeaderCellDef i18n>Job Title</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.job_title }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef i18n>
          <mat-icon>{{ gaiaIconSet.email }}</mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a mat-button target="_blank" href="mailto:{{element.email}}">{{ element.email }}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <mat-header-cell *matHeaderCellDef i18n>
          <mat-icon>{{ gaiaIconSet.phone }}</mat-icon>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.mobile_number.as_str }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="send_invite">
        <mat-header-cell class="w-75" *matHeaderCellDef i18n>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="w-75">
          <button mat-button (click)="sendInvite(element)"  matTooltip="Send Invite">
            <mat-icon>{{ gaiaIconSet.outgoing_mail }}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="qr_code">
        <mat-header-cell class="w-75" *matHeaderCellDef i18n>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="w-75">
          <button mat-button (click)="openQRCode(element)">
            <mat-icon>{{ gaiaIconSet.qr_code }}</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="edit">
        <mat-header-cell class="w-75" *matHeaderCellDef i18n>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="w-75">
          <a mat-button [routerLink]="['/', appRoutes.SYSTEM_ADMIN, systemAdminRoutes.STAFF_MEMBERS, element.id]">
            <mat-icon>{{ gaiaIconSet.edit }}</mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n>Gallery</span>
    </ng-template>

    <div class="staff-photo-grid">

      <div class="staff-record" *ngFor="let staff of currentStaffList.getRecords() | async">

        <div class="staff-image">
          <img *ngIf="staff.image" [src]="staff.image.large_url" [alt]="staff.name" />
        </div>

        <div class="staff-details">
          <mat-list>
            <mat-list-item>
              <mat-icon matListIcon>{{ gaiaIconSet.my_record }}</mat-icon>
              {{ staff.name }}
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>{{ gaiaIconSet.job }}</mat-icon>
              {{ staff.job_title }}
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>{{ gaiaIconSet.email }}</mat-icon>
              <a target="_blank" href="mailto:{{staff.email}}">{{ staff.email }}</a>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListIcon>{{ gaiaIconSet.phone }}</mat-icon>
              {{ staff.company_mobile_number }}
            </mat-list-item>
          </mat-list>
        </div>

      </div>

    </div>

  </mat-tab>
</mat-tab-group>

