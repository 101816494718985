import { Injectable } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Company} from './company.model';
import {AddressFormControlService} from '@terravesta/gaia-shared';

@Injectable({
  providedIn: 'root'
})
export class CompanyFormControlService {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService
  ) { }

  toFormGroup(company: Company): FormGroup{
    return this.formBuilder.group({
      name: new FormControl(company.name, Validators.required),
      vat_number: new FormControl(company.vat_number),
      reg_number: new FormControl(company.reg_number),
      phone_number: new FormControl(company.phone_number),
      fax_number: new FormControl(company.fax_number),
      web_address: new FormControl(company.web_address),
      primary_email_address: new FormControl(company.primary_email_address, Validators.email),
      bank_name: new FormControl(company.bank_name),
      bank_account_number: new FormControl(company.bank_account_number),
      bank_account_sort_code: new FormControl(company.bank_account_sort_code),
      use_for_orders: new FormControl(company.use_for_orders),
      operational_address: this.addressFormControl.toFormControl(company.operational_address),
      registered_address: this.addressFormControl.toFormControl(company.registered_address),
      logo: new FormControl(company.logo),
    });
  }

}
