import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaleTypeFormControlService} from '../bale-type-form-control.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-bale-type-select-dialog',
  templateUrl: './bale-type-select-dialog.component.html',
  styleUrls: ['./bale-type-select-dialog.component.scss']
})
export class BaleTypeSelectDialogComponent implements OnInit {

  formGroup: FormGroup;

  constructor(
    private baleTypeFormControl: BaleTypeFormControlService,
    private matDialogRef: MatDialogRef<BaleTypeSelectDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.formGroup = this.baleTypeFormControl.selectBaleTypeForm();
  }

  changeBaleType() {
    if (this.formGroup.valid) {
      const value = this.formGroup.value;
      this.matDialogRef.close(value);
    }
  }
}
