import {Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessorConnectorDirective} from '@terravesta/phanes';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ProcessorsService} from '../../processors.service';
import {ProcessorScheduleMonthList} from '../../processor.model';

@Component({
  selector: 'app-schedule-month-select',
  templateUrl: './schedule-month-select.component.html',
  styleUrls: ['./schedule-month-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleMonthSelectComponent),
      multi: true
    }
  ]
})
export class ScheduleMonthSelectComponent extends ControlValueAccessorConnectorDirective implements OnInit, OnChanges {

  @Input()
  harvestYear: number;

  @Input()
  labelText: string;

  scheduleMonthLists: Array<ProcessorScheduleMonthList>;

  updating = false;

  constructor(
    inj: Injector,
    private processorsService: ProcessorsService
  ) {
    super(inj);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.harvestYear){
      if (!changes.harvestYear.firstChange){
        this.control.setValue(null);
      }
      this.updateSchedule();
    }
  }

  updateSchedule() {
    if (this.harvestYear) {
      this.scheduleMonthLists = [];
      this.updating = true;
      this.processorsService.getScheduleMonths(this.harvestYear).subscribe(
        (response) => {
          this.updating = false;
          this.scheduleMonthLists = response;
        }
      );
    }
  }

}
