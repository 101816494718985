import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {SentinelFunction} from './sentinel-function.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SentinelFunctionsService extends RestApiService<SentinelFunction>{

  constructor(httpClient: HttpClient) {
    super('/sentinel_functions', 'sentinel_function', httpClient);
  }
}
