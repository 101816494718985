import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MiscanthusStack} from '../../miscanthus-stack.model';

@Component({
  selector: 'app-bale-gain-form',
  templateUrl: './bale-gain-form.component.html',
  styleUrls: ['./bale-gain-form.component.scss']
})
export class BaleGainFormComponent implements OnInit, OnChanges {

  @Input()
  form: FormGroup;

  @Input()
  stack: MiscanthusStack;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
  }

}
