import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PriceGroupsService} from '../price-groups.service';
import {PriceGroup} from '../price-group.model';
import {PriceGroupFormControlService} from '../price-group-form-control.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-price-group-show',
  templateUrl: './price-group-show.component.html',
  styleUrls: ['./price-group-show.component.scss']
})
export class PriceGroupShowComponent extends ShowPageDirective<PriceGroup> {

  constructor(
    priceGroupService: PriceGroupsService,
    priceGroupFormControl: PriceGroupFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(priceGroupService, priceGroupFormControl, route, snackBar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: PriceGroup) {
  }

}
