import {Component, Inject} from '@angular/core';
import {NewDialogDirective} from '../../../components/new-dialog.directive';
import {DroneImage} from '../drone-image.model';
import {DroneImagesService} from '../drone-images.service';
import {DroneImageFormControlService} from '../drone-image-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractedField} from '../../contracted-fields/contracted-field.model';

@Component({
  selector: 'app-drone-image-new-dialog',
  templateUrl: './drone-image-new-dialog.component.html',
  styleUrls: ['./drone-image-new-dialog.component.scss']
})
export class DroneImageNewDialogComponent extends NewDialogDirective<DroneImage> {

  contractedField: ContractedField;

  constructor(
    droneImageService: DroneImagesService,
    droneImageFormControl: DroneImageFormControlService,
    public matDialog: MatDialogRef<DroneImageNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DroneImageNewDialogData,
  ) {
    super(droneImageService, droneImageFormControl);
    this.contractedField = data.contractedField;
  }

  successfulSave(result: DroneImage) {
    this.matDialog.close();
  }

}

export interface DroneImageNewDialogData {
  contractedField: ContractedField;
}
