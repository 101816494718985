import { Component} from '@angular/core';
import {NewDialogDirective} from '../../../components/new-dialog.directive';
import {GrowerContract} from '../grower-contract.model';
import {GrowerContractsService} from '../grower-contracts.service';
import {GrowerContractFormControlService} from '../grower-contract-form-control.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-grower-contract-new-dialog',
  templateUrl: './grower-contract-new-dialog.component.html',
  styleUrls: ['./grower-contract-new-dialog.component.scss']
})
export class GrowerContractNewDialogComponent extends NewDialogDirective<GrowerContract> {

  constructor(
    growerContractsService: GrowerContractsService,
    growerContractFormService: GrowerContractFormControlService,
    public router: Router,
    public matDialogRef: MatDialogRef<GrowerContractNewDialogComponent>
  ) {
    super(growerContractsService, growerContractFormService);
  }

  successfulSave(result: GrowerContract) {
    this.router.navigate(['./', result.id]).then((x) => {
      this.matDialogRef.close();
    });
  }

}
