import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HauliersTableComponent } from './hauliers-table/hauliers-table.component';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { HaulierFormComponent } from './haulier-form/haulier-form.component';
import { HaulierShowComponent } from './haulier-show/haulier-show.component';
import {MatTabsModule} from '@angular/material/tabs';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {HaulierDriversModule} from './haulier-drivers/haulier-drivers.module';
import {HaulierVehiclesModule} from './haulier-vehicles/haulier-vehicles.module';
import { HaulierNewDialogComponent } from './haulier-new-dialog/haulier-new-dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { HaulierSelectComponent } from './haulier-select/haulier-select.component';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HaulierUniversalSearchResultComponent } from './haulier-universal-search-result/haulier-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../../universal-search/universal-search-layout.module';
import {MatListModule} from '@angular/material/list';
import {BaleCollectionsSharedModule} from '../bale-collections-shared/bale-collections-shared.module';


@NgModule({
    declarations: [HauliersTableComponent, HaulierFormComponent, HaulierShowComponent, HaulierNewDialogComponent, HaulierSelectComponent, HaulierUniversalSearchResultComponent,],
  exports: [
    HaulierSelectComponent,
    HaulierUniversalSearchResultComponent
  ],
  imports: [
    CommonModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    GaiaGeneralModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    AddressesModule,
    FlexModule,
    ReactiveFormsModule,
    HaulierDriversModule,
    HaulierVehiclesModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    UniversalSearchLayoutModule,
    MatListModule,
    BaleCollectionsSharedModule,
  ]
})
export class HauliersModule { }
