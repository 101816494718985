import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../../services/rest-api.service';
import {BaleLoss} from './bale-loss.model';

@Injectable({
  providedIn: 'root'
})
export class BaleLossesService extends RestApiService<BaleLoss> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/miscanthus_stacks/{parentId}/bale_losses', 'bale_loss', httpClient);
  }
}
