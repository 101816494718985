import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ContractedField} from '../../contracted-fields/contracted-field.model';
import {MatPaginator} from '@angular/material/paginator';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {SoilSample} from '../soil-sample.model';
import {SoilSamplesService} from '../soil-samples.service';
import {MatDialog} from '@angular/material/dialog';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {SoilSampleNewDialogComponent} from '../soil-sample-new-dialog/soil-sample-new-dialog.component';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-soil-samples-embed',
  templateUrl: './soil-samples-embed.component.html',
  styleUrls: ['./soil-samples-embed.component.scss']
})
export class SoilSamplesEmbedComponent implements OnInit, AfterViewInit {

  @Input()
  contractedField: ContractedField;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  gaiaIconSet = GaiaIconSet;
  dataSource: GaiaIndexDataSource<SoilSample>;
  displayedColumns = ['unique_id', 'date_taken'];

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;

  constructor(
    private soilSamplesService: SoilSamplesService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.dataSource = new GaiaIndexDataSource<SoilSample>(this.soilSamplesService);
    this.dataSource.search(this.searchOpts());
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchOpts(): any {
    // TODO fix this to be hard coded rather than in searchOpts
    return {
      field: {
        uuid: this.contractedField.id,
      }
    };
  }

  newSoilSample() {
    const dialogRef = this.matDialog.open(SoilSampleNewDialogComponent, {
      data: {
        record: {
          field: this.contractedField,
          owner: { id: this.contractedField.grower.id, name: this.contractedField.grower.grower_code },
        } as SoilSample
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.dataSource.search(this.searchOpts());
    });
  }
}
