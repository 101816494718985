
<ng-container [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n="Title of box that contains the contract information"> Contract Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div class="form-row">
        <mat-form-field>
          <mat-label i18n>Contract Ref</mat-label>
          <input matInput formControlName="grower_contract_ref">
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Intended Hectarage</mat-label>
          <input matInput type="number" formControlName="intended_hectarage">
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field>
          <mat-label i18n>Contract Start Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="start_date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Term Length (years)</mat-label>
          <input matInput type="number" formControlName="contract_term" />
        </mat-form-field>
      </div>

      <mat-form-field fxFlex="calc(50% -15px)">
        <mat-label i18n>Contract Signed On</mat-label>
        <input matInput [matDatepicker]="signedDatePicker" formControlName="signed_on">
        <mat-datepicker-toggle matSuffix [for]="signedDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #signedDatePicker></mat-datepicker>
      </mat-form-field>

    </mat-card-content>
  </mat-card>
</ng-container>
