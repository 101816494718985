import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HaulierVehiclesEmbedTableComponent } from './haulier-vehicles-embed-table/haulier-vehicles-embed-table.component';
import {MatTableModule} from '@angular/material/table';
import {ExtendedModule} from '@angular/flex-layout';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
  declarations: [
    HaulierVehiclesEmbedTableComponent
  ],
  exports: [
    HaulierVehiclesEmbedTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    ExtendedModule,
    GaiaSharedModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class HaulierVehiclesModule { }
