<div *ngIf="visible">
  <mat-expansion-panel id="infoWindow" *ngIf="miscanthusStack" [expanded]="panelOpenState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <!--<b>Account Name</b>-->
        <b><p class="card-title">{{miscanthusStack.grower.account_name}}</p></b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <b>Schedule Month</b>
    <p class="card-title">{{miscanthusStack.schedule_month.month}}
    </p>
    <b>Harvest Year</b>
    <p class="card-title">{{miscanthusStack.harvest_year}}
    </p>
    <b>Declare bale count</b>
    <p class="card-title">{{miscanthusStack.declared_bale_count}}
    </p>
    <b>Collected bale count</b>
    <p class="card-title">{{miscanthusStack.collected_bale_count}}
    </p>
    <b>Processor</b>
    <p class="card-title">{{miscanthusStack.processor.account_name}}
    </p>
    <b>Bale Type</b>
    <p class="card-title">{{miscanthusStack.grower.crop_type}}
    </p>
    <b>Delivery Status</b>
    <p class="card-title" *ngIf="miscanthusStack.stat_no_collections">
      Not Completed</p>
    <p class="card-title" *ngIf="miscanthusStack.stat_completed_collections">
      Completed</p>
    <p class="card-title" *ngIf="miscanthusStack.stat_part_collected">
      Partially Completed</p>
    <mat-card-actions>
      <button id="infoClose" mat-raised-button color="primary" (click)="CloseInfo()">Close</button>
    </mat-card-actions>
  </mat-expansion-panel>
</div>
