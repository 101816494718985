import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ContractedField} from '../../contracted-fields/contracted-field.model';
import {DroneImagesService} from '../drone-images.service';
import {MatDialog} from '@angular/material/dialog';
import {
  DroneImageNewDialogComponent,
} from '../drone-image-new-dialog/drone-image-new-dialog.component';
import {DroneImage} from '../drone-image.model';
import {MatPaginator} from '@angular/material/paginator';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {PaginatedDataSource, PhanesRecordMapItem, RailsAPIWrapper} from '@terravesta/phanes';
import {Grower} from '../../growers/grower.model';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {LngLatLike} from 'mapbox-gl';
import {FormControl, FormGroup} from '@angular/forms';
import {GaiaLogger} from '../../../../utils/common-functions';
import {ContractedFieldsService} from '../../contracted-fields/contracted-fields.service';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-drone-image-field-embed',
  templateUrl: './drone-image-field-embed.component.html',
  styleUrls: ['./drone-image-field-embed.component.scss']
})
export class DroneImageFieldEmbedComponent implements OnInit, AfterViewInit, OnChanges { //implement ngOnchanges

  gaiaIconSet = GaiaIconSet;
  displayedColumns = ['date_taken'];
  mapRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  grower: Grower;
  droneImage: Array<DroneImage>;
  dateClass: MatCalendarCellClassFunction<Date>;
  newStartDate: Date;
  currentDate: Date;
  show = false;
  contractedField: Array<ContractedField>;
  dateTaken: Array<any> = [];
  urlString: string;
  mapBoxId: string;
  centerLatitude: number;
  centerLongitude: number;
  mapCenter: LngLatLike = [-1.313874, 53.196055];
  fieldName: Array<string> = [];
  // showDateField = false;
  miscanthusVariety: string;
  showVariety = false;

  fieldList = new FormGroup({
    field_name: new FormControl(''),
    date_taken: new FormControl(''),
    planted_variety: new FormControl(''),
  });

  @Input()
  field: ContractedField;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  dataSource: PaginatedDataSource<ContractedField>;
  selectedImage: DroneImage;
  dropdownFieldList: Array<ContractedField> = [];
  dropdownFieldNames: Array<string> = [];
  redirectId: string;

  constructor(
    private droneImageService: DroneImagesService,
    private matDialog: MatDialog,
    private contractedFieldsService: ContractedFieldsService,
  ) {
  }

  ngOnInit(): void {
   /* this.dataSource = new GaiaIndexDataSource<DroneImage>(this.droneImageService);
    this.dataSource.search(this.searchOpts());*/
    // this.showDateTaken();
  }

  ngOnChanges() {
    this.getDroneImagesByGrower();
  }

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
  }

  newDroneImage() {
    const dialogRef = this.matDialog.open(DroneImageNewDialogComponent, {
      width: '60%',
      height: '60%',
      data: { contractedField: this.field}
    });
    dialogRef.afterClosed().subscribe(
      () => { this.dataSource.search(this.searchOpts()); }
    );
  }

  searchOpts(): any {
    return {
      contracted_field: {
        uuid: this.field.id
      }
    };
  }

 /* showDroneImage(element: DroneImage) {
    this.selectedImage = element;
  }*/

  getDroneImagesByGrower(){
    this.droneImageService.getRecords({pageNumber: 0, pageSize: 30, search: this.searchOpts()}).subscribe(
      (droneImages: RailsAPIWrapper<DroneImage>) => {
        this.droneImage = droneImages.records;
        this.droneImage.forEach(droneImage => {
          if (droneImage) {
              this.dateTaken.push(droneImage.date_taken);
          }
        });
        GaiaLogger.log(this.droneImage);
      });
    /*this.contractedFieldsService.getContractedFieldsForGrower(this.field.grower, null).subscribe(
      (contField:RailsAPIWrapper<ContractedField>) => {
        this.contractedField = contField;
        GaiaLogger.log(this.contractedField);
      }
    )*/
    this.dataSource = new PaginatedDataSource<ContractedField>(
      (params) => this.contractedFieldsService.getContractedFieldsForGrower(this.field.grower, params));
    this.dataSource.loadRecords();
    this.dataSource.connect(null).subscribe(
      (fields) => {
        if (fields) {
            fields.forEach((field) => {
            this.dropdownFieldList.push(field);
            this.dropdownFieldNames.push(field.name);
          });
        }
      });
   }

  fieldAndDateChosen() {
    const fieldName = this.field.name;
    const dateChosen = this.fieldList.get('date_taken').value;
    this.miscanthusVariety = this.field.planted_variety.name;
    this.mapCenter = [0 , 0];
    this.droneImage.forEach(droneImage => {
      if (droneImage.contracted_field.name === fieldName && droneImage.date_taken === dateChosen)
      {
        this.urlString = 'mapbox://michaelsterravesta.' + droneImage.mapbox_id;
        this.mapCenter = [droneImage.contracted_field.longitude, droneImage.contracted_field.latitude];
        // this.miscanthusVariety = droneImage.contracted_field.planted_variety.name;
      }
    });
    GaiaLogger.log(this.urlString, this.mapCenter, dateChosen, fieldName, this.miscanthusVariety);
    this.showVariety = true;
  }

  redirectToNewField($event: MatSelectChange) {
    const e =$event.source.value
    this.dropdownFieldList.forEach(field => {
      if (e == field.name){
        this.redirectId = field.id
      }
    });
    const redirectUrl = 'growing/contracted-fields/'+this.redirectId;
    console.log(redirectUrl)
    /*const redirectUrl = `/${appRoutes.GROWING}/${growingRoutes.CONTRACTED_FIELDS}/${this.redirectId}`;
    console.log(redirectUrl);*/

    // Redirect to the new URL
    window.location.href = redirectUrl;
  }
}
