
<div class="preview-wrapper">
  <mat-toolbar class="header" color="primary">
    <div class="header-container">
      <div class="icon">
        <mat-icon>{{ icon }}</mat-icon>
      </div>
      <div class="display-name">
        <div>{{ searchResult.display_name }}</div>
        <div class="class-label">{{ classType }}</div>
      </div>
      <div class="btn-container">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
  </mat-toolbar>
  <div class="panels">
    <div class="main-panel">
      <ng-content></ng-content>
    </div>
    <div class="link-panel">
      <ng-content select="[links]"></ng-content>
    </div>
  </div>
</div>
