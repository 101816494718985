import { Component } from '@angular/core';
import {Grower} from '../grower.model';
import {BadgePanelDirective} from '../../../components/badge-panel.directive';

@Component({
  selector: 'app-grower-badge-panel',
  templateUrl: './grower-badge-panel.component.html',
  styleUrls: ['./grower-badge-panel.component.scss']
})
export class GrowerBadgePanelComponent extends BadgePanelDirective<Grower> {


}
