import { Component } from '@angular/core';
import {ForApprovalTableDirective} from '../../team-space-concerns/requires-approval/for-approval-table.directive';
import {StaffAbsence} from '../staff-absence.model';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StaffAbsencesService} from '../staff-absences.service';
import {StaffAbsenceFormDialogComponent} from '../staff-absence-form-dialog/staff-absence-form-dialog.component';

@Component({
  selector: 'app-staff-absence-approval-table',
  templateUrl: './staff-absence-approval-table.component.html',
  styleUrls: ['./staff-absence-approval-table.component.scss']
})
export class StaffAbsenceApprovalTableComponent extends ForApprovalTableDirective<StaffAbsence> {
  displayedColumns = ['claimant', 'type', 'start_date', 'end_date', 'in_days', 'menu'];

  constructor(
    staffAbsencesService: StaffAbsencesService,
    matDialog: MatDialog,
    snackBar: MatSnackBar,
  ) {
    super(staffAbsencesService, matDialog, snackBar, StaffAbsenceFormDialogComponent);
  }

}
