<mat-card *ngIf="currentStats">
  <mat-card-header>
    <mat-icon mat-card-avatar>{{ gaiaIconSet.grower_contracts }}</mat-icon>
    <mat-card-title i18n="Contract stats title">Contracts</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-stats-number-line [displayNumber]="currentStats.in_term_contract"
                           label="Under Term Contract" i18n-label></app-stats-number-line>
    <app-stats-number-line [displayNumber]="currentStats.total_hectarage" [overLine]="true"
                           label="Total Hectares (Field)" i18n-label></app-stats-number-line>
    <app-stats-number-line [displayNumber]="currentStats.contracted_hectarage"
                           label="Total Hectares (Contract)" i18n-label></app-stats-number-line>
    <app-stats-number-line [displayNumber]="currentStats.total_replanting_fields"
                           label="Total Replanting Fields" i18n-label></app-stats-number-line>
  </mat-card-content>
</mat-card>
