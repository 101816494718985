import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlantingOpportunitiesTableComponent } from './planting-opportunities-table/planting-opportunities-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import { PlantingOpportunityShowComponent } from './planting-opportunity-show/planting-opportunity-show.component';
import {ProspectiveFieldsModule} from './prospective-fields/prospective-fields.module';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {ContactsModule} from '../../contacts/contacts.module';
import { PlantingOpportunityEmbedTableComponent } from './planting-opportunity-embed-table/planting-opportunity-embed-table.component';
import {SalesSurveyGrowerFormComponent} from './sales-survey-grower-form/sales-survey-grower-form.component';
import {MatInputModule} from '@angular/material/input';
import { PlantingOpportunityMapFeatureWindowComponent } from './planting-opportunity-map-feature-window/planting-opportunity-map-feature-window.component';
import { PlantingOpportunityStageFunnelWidgetComponent } from './widgets/planting-opportunity-stage-funnel-widget/planting-opportunity-stage-funnel-widget.component';
import {DashboardSharedModule} from '../../custom-dashboards/dashboard-shared/dashboard-shared.module';
import {NgxEchartsModule} from 'ngx-echarts';
import { UpcomingSurveysWidgetComponent } from './widgets/upcoming-surveys-widget/upcoming-surveys-widget.component';
import {MatTabsModule} from '@angular/material/tabs';
import { FarmSurveyToolComponent } from './farm-survey-tool/farm-survey-tool.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatSortModule} from '@angular/material/sort';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {GrowerSharedModule} from '../grower-shared-components/grower-shared.module';


@NgModule({
  declarations: [
    PlantingOpportunitiesTableComponent,
    PlantingOpportunityShowComponent,
    PlantingOpportunityEmbedTableComponent,
    SalesSurveyGrowerFormComponent,
    PlantingOpportunityMapFeatureWindowComponent,
    PlantingOpportunityStageFunnelWidgetComponent,
    UpcomingSurveysWidgetComponent,
    FarmSurveyToolComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    RouterModule,
    MatPaginatorModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    ProspectiveFieldsModule,
    FlexModule,
    MatCardModule,
    ContactsModule,
    MatInputModule,
    AddressesModule,
    PhanesComponentsModule,
    MatIconModule,
    MatDividerModule,
    DashboardSharedModule,
    NgxEchartsModule,
    MatTabsModule,
    MatSortModule,
    MatExpansionModule,
    MatMenuModule,
    GrowerSharedModule,
  ],
  exports: [
    PlantingOpportunitiesTableComponent,
    PlantingOpportunityShowComponent,
    PlantingOpportunityEmbedTableComponent,
    PlantingOpportunityMapFeatureWindowComponent,
    FarmSurveyToolComponent
  ]
})
export class PlantingOpportunitiesModule { }
