import { Component } from '@angular/core';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {Processor} from '../processor.model';
import {ProcessorsService} from '../processors.service';
import {MatDialog} from '@angular/material/dialog';
import {ProcessorNewDialogComponent} from '../processor-new-dialog/processor-new-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {OperationsRoutes} from '../../operations-routing.module';

@Component({
  selector: 'app-processors-table',
  templateUrl: './processors-table.component.html',
  styleUrls: ['./processors-table.component.scss']
})
export class ProcessorsTableComponent extends IndexTableDirective<Processor> {

  operationsRoutes = OperationsRoutes;
  displayedColumns = ['account_name'];

  constructor(
    processorsService: ProcessorsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(processorsService, router, activatedRoute, matDialog, ProcessorNewDialogComponent, null,
      ['account_name']);
  }

}
