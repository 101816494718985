<ng-container *ngIf="form" [formGroup]="form">
  <div class="two-column-cards">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.hauliers}}</mat-icon>
        <mat-card-title i18n>Haulier Details</mat-card-title>
      </mat-card-header>
      <mat-card-content class="fields">

        <mat-form-field>
          <mat-label i18n>Account Name</mat-label>
          <input matInput formControlName="account_name" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Phone</mat-label>
          <input matInput formControlName="phone" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Email</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>

        <mat-slide-toggle formControlName="active" i18n>Active?</mat-slide-toggle>

      </mat-card-content>
    </mat-card>
    <gaia-shared-address-embed-form formGroupName="billing_address"></gaia-shared-address-embed-form>
  </div>
</ng-container>
