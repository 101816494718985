import {
  HarvestCompletionSummaryWidgetComponent
} from '../../operations/miscanthus-harvests/widgets/harvest-completion-summary-widget/harvest-completion-summary-widget.component';
import {
  HarvestTonnageSummaryWidgetComponent
} from '../../operations/miscanthus-harvests/widgets/harvest-tonnage-summary-widget/harvest-tonnage-summary-widget.component';
import {
  PlantingOpportunityStageFunnelWidgetComponent
} from '../../growing/planting-opportunities/widgets/planting-opportunity-stage-funnel-widget/planting-opportunity-stage-funnel-widget.component';
import {
  UpcomingSurveysWidgetComponent
} from '../../growing/planting-opportunities/widgets/upcoming-surveys-widget/upcoming-surveys-widget.component';
import {
  TermContractHectarageOverviewWidgetComponent
} from '../../growing/contracts/term-contracts/widgets/term-contract-hectarage-overview-widget/term-contract-hectarage-overview-widget.component';
import {
  PlantingSummaryWidgetComponent
} from '../../growing/contracted-fields/widget/planting-summary-widget/planting-summary-widget.component';

export const WIDGET_LIST: Array<WidgetDefinition> = [
  HarvestCompletionSummaryWidgetComponent.widgetDefinition(),
  HarvestTonnageSummaryWidgetComponent.widgetDefinition(),
  PlantingOpportunityStageFunnelWidgetComponent.widgetDefinition(),
  UpcomingSurveysWidgetComponent.widgetDefinition(),
  TermContractHectarageOverviewWidgetComponent.widgetDefinition(),
  PlantingSummaryWidgetComponent.widgetDefinition(),
  //GrowerContractStatsWidgetComponent.widgetDefinition(),
];

export interface WidgetDefinition {
  name: string;
  componentClass: any;
  description: string;
  code: string;
  grouping: string;
  department: string;
  minColumnCount: number; // min number of columns
  minRowCount: number; // Min number of rows
}

export class DashboardWidgetFactory {

  static getComponentByCode(code): WidgetDefinition {
    return WIDGET_LIST.find(i => i.code === code);
  }

  static widgetsByDepartment(){
    const groups: Array<{department: string, widgets: Array<WidgetDefinition>}> = [];
    WIDGET_LIST.forEach((item) => {
      const group = item.grouping;
      if (groups.length > 0) {
        const found = groups.find(i => i.department === group);
        if (found) {
          found.widgets.push(item);
        } else {
          groups.push({
            department: group,
            widgets: [item]
          });
        }
      } else {
        groups.push({
          department: group,
          widgets: [item]
        });
      }
    });
    return groups;
  }

}

