import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appScrollableTable]'
})
export class ScrollableTableDirective implements OnInit, OnChanges {

  @Input()
  maxHeight = '400px';

  constructor(private elementRef: ElementRef) {
    elementRef.nativeElement.style.position = 'relative';
    elementRef.nativeElement.style.overflow = 'auto';
  }

  ngOnInit() {
    this.elementRef.nativeElement.style.maxHeight = this.maxHeight;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.elementRef.nativeElement.style.maxHeight = this.maxHeight;
  }

}
