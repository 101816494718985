import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Grower} from './grower.model';
import {AddressFormControlService} from '@terravesta/gaia-shared';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';

@Injectable({
  providedIn: 'root'
})
export class GrowerFormControlService implements GaiaRecordFormControl<Grower> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService
  ) {}

  toFormGroup(grower: Grower): FormGroup{
    return this.formBuilder.group({
      grower_code: new FormControl(grower.grower_code, Validators.required),
      account_name: new FormControl(grower.account_name, Validators.required),
      vat_number: new FormControl(grower.vat_number),
      farm_type: new FormControl(grower.farm_type),
      farm_status: new FormControl(grower.farm_status),
      contract_base_price: new FormControl(grower.contract_base_price),
      trading_name: new FormControl(grower.trading_name),
      billing_address: this.addressFormControl.toFormControl(grower.billing_address),
    });
  }

  toOpportunityEmbedForm(grower: Grower): FormGroup {
    return this.formBuilder.group({
      account_name: new FormControl(grower.account_name, Validators.required),
      billing_address: this.addressFormControl.toFormControl(grower.billing_address),
    });
  }

  toContractorsFormGroup(grower: Grower){
    return this.formBuilder.group({
      baling_contractor_id: new FormControl(grower.baling_contractor ? grower.baling_contractor.id : null),
      cutting_contractor_id: new FormControl(grower.cutting_contractor ? grower.cutting_contractor.id : null),
    });
  }

  toHarvestOptionsFormGroup(grower: Grower){
    return this.formBuilder.group({
      allow_partial_harvest_dec: new FormControl(grower.allow_partial_harvest_dec),
      stack_limit: new FormControl(grower.stack_limit),
    });
  }

}
