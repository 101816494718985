import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemResourcesMenuComponent } from './system-resources-menu/system-resources-menu.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {HarvestHubHelpModule} from './harvest-hub-help/harvest-hub-help.module';
import {ImagesModule} from './images/images.module';
import {SentinelModule} from './sentinel/sentinel.module';


@NgModule({
  declarations: [
    SystemResourcesMenuComponent,
  ],
  exports: [
    SystemResourcesMenuComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule,
    MatIconModule,
    HarvestHubHelpModule,
    ImagesModule,
    SentinelModule
  ]
})
export class SystemResourcesModule { }
