import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {Haulier, HaulierUniversalSearchPreview} from './haulier.model';
import {HttpClient} from '@angular/common/http';
import {UniversallySearchableService} from '../../universal-search/universally-searchable.service';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HauliersService extends RestApiService<Haulier> implements UniversallySearchableService<HaulierUniversalSearchPreview> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('hauliers', 'haulier', httpClient);
  }

  universalSearchPreviewData(recordId: string): Observable<HaulierUniversalSearchPreview> {
    return of(null);
  }
}
