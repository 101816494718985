import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackStorageMethodListComponent } from './stack-storage-method-list/stack-storage-method-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import { StackStorageMethodFormDialogComponent } from './stack-storage-method-form-dialog/stack-storage-method-form-dialog.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {StackStorageMethodSelectComponent} from './stack-storage-method-select/stack-storage-method-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [StackStorageMethodListComponent, StackStorageMethodFormDialogComponent, StackStorageMethodSelectComponent],
    exports: [
        StackStorageMethodListComponent,
        StackStorageMethodSelectComponent
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatCardModule,
        GaiaGeneralModule,
        PhanesComponentsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        MatButtonModule,
        FlexModule,
        MatSelectModule
    ]
})
export class StackStorageMethodsModule { }
