import {Component, Input} from '@angular/core';
import {RecordFormDirective} from '../../components/record-form.directive';
import {Contact} from '../contact.model';
import {GaiaRecord} from '../../models/gaia-record.model';

@Component({
  selector: 'app-contact-form-simple',
  templateUrl: './contact-form-simple.component.html',
  styleUrls: ['./contact-form-simple.component.scss']
})
export class ContactFormSimpleComponent extends RecordFormDirective<Contact> {

  @Input()
  account: GaiaRecord;
}
