import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {RecordFormDirective} from '../../../../components/record-form.directive';
import {MiscanthusFieldHarvest} from '../miscanthus-field-harvest.model';
import {ContractedFieldsService} from '../../../../growing/contracted-fields/contracted-fields.service';
import {CommonContractedFieldDetail, ContractedFieldSelectComponent} from '@terravesta/gaia-shared';
import {MiscanthusStack} from '../../miscanthus-stack.model';

@Component({
  selector: 'app-miscanthus-field-harvest-form',
  templateUrl: './miscanthus-field-harvest-form.component.html',
  styleUrls: ['./miscanthus-field-harvest-form.component.scss']
})
export class MiscanthusFieldHarvestFormComponent extends RecordFormDirective<MiscanthusFieldHarvest> implements OnChanges {

  @Input()
  stack: MiscanthusStack;

  @ViewChild(ContractedFieldSelectComponent)
  contractedFieldSelector: ContractedFieldSelectComponent;

  fields: Array<CommonContractedFieldDetail>;
  selectedField: CommonContractedFieldDetail;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const harvest = this.record as MiscanthusFieldHarvest;
    if (changes.stack){
      if (changes.stack.currentValue !== undefined){
        if (!(harvest.contracted_field)) {
          this.getGrowersFields();
        }
        if (this.stack.bale_type){
          this.form.patchValue({ bale_type_id: this.stack.bale_type.id });
        }
      }
    }
    if (changes.record) {
      if (changes.record.currentValue) {
        if (harvest.contracted_field) {
          const field = harvest.contracted_field;
          this.selectedField = {
            name: field.name,
            id: field.id,
          } as CommonContractedFieldDetail;
        }
      }
    }
  }

  getGrowersFields() {
    this.contractedFieldsService.getContractedFieldsForGrower(this.stack.grower, {pageNumber: 0, pageSize: 100})
      .subscribe((fields) => {
        this.fields = fields.records;
      });
  }

  selectField(field: CommonContractedFieldDetail) {
    this.selectedField = field;
    this.form.patchValue({ contracted_field_id: this.selectedField.id });
  }
}
