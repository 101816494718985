import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {StackStorageMethodsService} from '../stack-storage-methods.service';
import {StackStorageMethod} from '../stack-storage-method.model';
import {StackStorageMethodFormDialogComponent} from '../stack-storage-method-form-dialog/stack-storage-method-form-dialog.component';
import {GaiaIndexDataSource} from '../../../../services/gaia-index-data-source';

@Component({
  selector: 'app-stack-storage-method-list',
  templateUrl: './stack-storage-method-list.component.html',
  styleUrls: ['./stack-storage-method-list.component.scss']
})
export class StackStorageMethodListComponent implements OnInit, AfterViewInit {

  gaiaIcons = GaiaIconSet;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  storageMethods: GaiaIndexDataSource<StackStorageMethod>;
  displayedColumns = ['name', 'active', 'is_outdoor'];

  constructor(
    private storageMethodsService: StackStorageMethodsService,
    private matDialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.storageMethods = new GaiaIndexDataSource<StackStorageMethod>(this.storageMethodsService,
      {active: 'order_weight', direction: 'asc' }, 10);
    this.storageMethods.loadRecords();
  }

  ngAfterViewInit(): void {
    this.storageMethods.paginator = this.paginator;
  }

  newStorageMethod() {
    this.openDialog({} as StackStorageMethod);
  }

  editStorageMethod(record: StackStorageMethod) {
    this.openDialog(record);
  }

  openDialog(record: StackStorageMethod) {
    const dialogRef = this.matDialog.open(StackStorageMethodFormDialogComponent, {
      data: { record },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.storageMethods.loadRecords();
    });
  }

}
