import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {StaffMemberShowComponent} from './staff-member-show/staff-member-show.component';
import {StaffDirectoryComponent} from './staff-directory/staff-directory.component';
import {HrSummaryComponent} from './hr-summary/hr-summary.component';

export class StaffMemberRoutes {
  public static MY_RECORD = 'my_record';
  public static DIRECTORY = 'directory';
  public static HR_SUMMARY = 'hr_summary';
}

const routes: Routes = [
  { path: StaffMemberRoutes.DIRECTORY, component: StaffDirectoryComponent },
  { path: StaffMemberRoutes.MY_RECORD, component: StaffMemberShowComponent },
  { path: StaffMemberRoutes.HR_SUMMARY, component: HrSummaryComponent },
  { path: ':id', component: StaffMemberShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaffMemberRoutingModule{ }
