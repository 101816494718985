import {Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';
import {FileSelectorComponent} from '../../gaia-general/file-selector/file-selector.component';
import {
  DrawingRecord,
  PhanesGoogleMapDrawingMode,
  PhanesRecordMapItem,
  RailsUploadParams
} from '@terravesta/phanes';
import {MatDialog} from '@angular/material/dialog';
import {GaiaLogger, navigateToPointUrl} from '../../../utils/common-functions';
import {agriculturalFieldInfoWindow} from '../contracted-fields/contracted-field-functions';
import {
  KmlSelectPlacemarkDialogComponent
} from '../../kml-utils/kml-select-placemark-dialog/kml-select-placemark-dialog.component';
import {AgriculturalField} from '../agricultural-field.model';
import {GaiaRecord} from '../../models/gaia-record.model';
import {AgriculturalFieldsService} from '../agricultural-fields.service';

@Directive({})
export abstract class FieldMapPanelDirective<T extends AgriculturalField> implements OnInit, OnChanges {

  gaiaIconSet = GaiaIconSet;

  @ViewChild('kmlDownload')
  kmlSpinnerButton: SpinnerLoadingButtonComponent;

  @ViewChild(FileSelectorComponent) fileSelector: FileSelectorComponent;

  @Input()
  agriculturalField: T;

  @Input()
  parent: GaiaRecord;

  @Output()
  fieldMapChanged = new EventEmitter();

  fieldMapData: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();

  navigateUrl: string;

  drawingModes = PhanesGoogleMapDrawingMode;
  mapMode = google.maps.MapTypeId.SATELLITE;

  protected constructor(
    private fieldsService: AgriculturalFieldsService<T>,
    protected matDialog: MatDialog,
  ) {
    console.log(this.mapMode);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.agriculturalField){
      this.updateMap();
      this.navigateUrl = navigateToPointUrl(this.agriculturalField.latitude, this.agriculturalField.longitude);
    }
  }

  private updateMap(){
    this.fieldMapData = new Array<PhanesRecordMapItem>();
    GaiaLogger.log(this.fieldMapData.length, this.fieldMapData);
    const fieldData = {
      record: this.agriculturalField,
      infoWindowHtml: agriculturalFieldInfoWindow(this.agriculturalField),
      displayOptions: {
        polygonStyle: {
          strokeColor: '#000000',
          fillColor: '#707070',
        },
        markerStyle: {
          markerColor: '#000000',
        }
      }
    } as PhanesRecordMapItem;
    this.fieldMapData.push(fieldData);
  }

  downloadKml() {
    this.fieldsService.downloadKML(this.agriculturalField, () => {
      this.kmlSpinnerButton.processFinished();
    }, this.parent);
  }

  uploadMap(file: File) {
    GaiaLogger.log(file);
    const dialogRef = this.matDialog.open(KmlSelectPlacemarkDialogComponent, {
      data: { file }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response.placemark) {
        this.fieldsService.uploadKML(this.agriculturalField, file, response.placemark.name, this.parent).subscribe(
          () => {
            this.fieldMapChanged.emit();
          });
      }
      this.fileSelector.unLinkFile();
    });
  }

  polygonUpdated(newPolygon: DrawingRecord) {
    const params = {
      recordId: this.agriculturalField.id,
      data: {
        gps_polygon_data: newPolygon.record
      } as T
    } as RailsUploadParams<T>;
    if (this.parent) {
      params.parentId = this.parent.id;
    }
    this.fieldsService.updateRecord(params).subscribe((response) => {
      this.fieldMapChanged.emit();
    });
  }
}
