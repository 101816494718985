import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SoilSample} from './soil-sample.model';
import {
  HasSampleLocationsFormControlService
} from '../../concerns/has-sample-locations/has-sample-locations-form-control.service';

@Injectable({
  providedIn: 'root'
})
export class SoilSampleFormControlService implements GaiaRecordFormControl<SoilSample> {

  constructor(
    private formBuilder: FormBuilder,
    private sampleLocationsFormControl: HasSampleLocationsFormControlService,
  ) { }

  toFormGroup(record: SoilSample): FormGroup {
    return this.formBuilder.group({
      // unique_id: new FormControl(record.unique_id),

      field_id: new FormControl(record.field ? record.field.id : null, Validators.required),

      date_taken: new FormControl(record.date_taken),
      sample_processed: new FormControl(record.sample_processed),
      processed_by: new FormControl(record.processed_by),
      processor_id: new FormControl(record.processor_id),

      sample_locations: this.sampleLocationsFormControl.sampleLocationsForm(record.sample_locations),

      total_nitrogen_per: new FormControl(record.total_nitrogen_per),
      organic_carbon_per: new FormControl(record.organic_carbon_per),
      organic_matter_dumas_per: new FormControl(record.organic_matter_dumas_per),
      c_n_ratio: new FormControl(record.c_n_ratio),
      total_carbon_per: new FormControl(record.total_carbon_per),
      total_carbon_per_ha: new FormControl(record.total_carbon_per_ha),
      //total_carbon_stock: new FormControl(record.total_carbon_stock),

      ph: new FormControl(record.ph),
      phosphorus_ppm: new FormControl(record.phosphorus_ppm),
      potassium_ppm: new FormControl(record.potassium_ppm),
      magnesium_ppm: new FormControl(record.magnesium_ppm),
      calcium_ppm: new FormControl(record.calcium_ppm),
      sulphur_ppm: new FormControl(record.sulphur_ppm),
      manganese_ppm: new FormControl(record.manganese_ppm),
      copper_ppm: new FormControl(record.copper_ppm),
      boron_ppm: new FormControl(record.boron_ppm),
      zinc_ppm: new FormControl(record.zinc_ppm),
      molybdenum_ppm: new FormControl(record.molybdenum_ppm),
      iron_ppm: new FormControl(record.iron_ppm),
      sodium_ppm: new FormControl(record.sodium_ppm),
      cec: new FormControl(record.cec),

      sand_per: new FormControl(record.sand_per),
      silt_per: new FormControl(record.silt_per),
      clay_per: new FormControl(record.clay_per),
      soil_type: new FormControl(record.soil_type),

      nickel_mg: new FormControl(record.nickel_mg),
      zinc_mg: new FormControl(record.zinc_mg),
      copper_mg: new FormControl(record.copper_mg),
      arsenic_mg: new FormControl(record.arsenic_mg),
      cadmium_mg: new FormControl(record.cadmium_mg),
      mercury_mg: new FormControl(record.mercury_mg),
      chromium_mg: new FormControl(record.chromium_mg),
    });
  }


}
