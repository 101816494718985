import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractedField} from '../contracted-field.model';

@Component({
  selector: 'app-contracted-field-sentinel-viewer-dialog',
  templateUrl: './contracted-field-sentinel-viewer-dialog.component.html',
  styleUrls: ['./contracted-field-sentinel-viewer-dialog.component.scss']
})
export class ContractedFieldSentinelViewerDialogComponent implements OnInit {

  contractedField: ContractedField;

  constructor(
    public dialogRef: MatDialogRef<ContractedFieldSentinelViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) contractedFieldData: any
  ) {
    this.contractedField = contractedFieldData;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

}
