import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {BrandAmbassador} from '../brand-ambassador.model';

@Component({
  selector: 'app-brand-ambassador-form',
  templateUrl: './brand-ambassador-form.component.html',
  styleUrls: ['./brand-ambassador-form.component.scss']
})
export class BrandAmbassadorFormComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  brandAmbassador: BrandAmbassador;

  gaiaIcons = GaiaIconSet;

  constructor() { }

  ngOnInit(): void {
  }

}
