import {Directive} from '@angular/core';
import {GaiaRecord} from '../models/gaia-record.model';
import {ShowPageDirective} from './show-page.directive';
import {GaiaLogger} from '../../utils/common-functions';

@Directive()
export abstract class ShowPageMultipartDirective<T extends GaiaRecord> extends ShowPageDirective<T>  {

  protected doUpdate(newData: T, message?: string): void {
    GaiaLogger.log('Doing multipart update', newData);
    this.lockScreen();
    this.recordService.updateRecordMultiPart({recordId: this.record.id, data: newData}).subscribe(
      (record) => {
        this.updateRecord(record);
        const snackMessage = message ? message : 'Saved Successfully';
        this.snackBar.open(snackMessage, 'Dismiss', {
        });
      },
      (error) => {
        this.showErrors(error.error);
      },
      () => {
        this.unlockScreen();
      }
    );
  }

}

