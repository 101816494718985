import {Component, forwardRef, Injector} from '@angular/core';
import {StackStorageMethod} from '../stack-storage-method.model';
import {StackStorageMethodsService} from '../stack-storage-methods.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {RecordListSelectDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-stack-storage-method-select',
  templateUrl: './stack-storage-method-select.component.html',
  styleUrls: ['./stack-storage-method-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StackStorageMethodSelectComponent),
      multi: true
    }
  ]
})
export class StackStorageMethodSelectComponent extends RecordListSelectDirective<StackStorageMethod> {

  constructor(
    stackStorageMethodsService: StackStorageMethodsService,
    inj: Injector,
  ) {
    super(stackStorageMethodsService, inj);
  }

}
