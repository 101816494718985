
<app-badge-panel [record]="record" [drawBackground]="drawBackground">

  <app-record-badge i18n-label label="FOC Replanting">
    <mat-icon *ngIf="record.replanting else noReplanting">{{ gaiaIconSet.check }}</mat-icon>
    <ng-template #noReplanting>
      <mat-icon>{{ gaiaIconSet.cancel }}</mat-icon>
    </ng-template>
  </app-record-badge>

  <app-record-badge i18n-label label="Contracted">
    <mat-icon *ngIf="record.contracted else noContract">{{ gaiaIconSet.check }}</mat-icon>
    <ng-template #noContract>
      <mat-icon>{{ gaiaIconSet.cancel }}</mat-icon>
    </ng-template>
  </app-record-badge>

  <app-record-badge i18n-label label="Map Status" [tooltip]="mapToolTip">
    <ng-container [ngSwitch]="record.map_status">
      <mat-icon *ngSwitchCase="'polygon'">{{ gaiaIconSet.map_status_polygon }}</mat-icon>
      <mat-icon *ngSwitchCase="'pin_only'">{{ gaiaIconSet.map_status_pin }}</mat-icon>
      <mat-icon *ngSwitchCase="'unmapped'">{{ gaiaIconSet.map_status_not }}</mat-icon>
    </ng-container>
  </app-record-badge>
</app-badge-panel>
