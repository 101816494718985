import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {ProcessorDeliverySite} from './processor-delivery-site.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DistanceMeasurement} from '../../../../utils/common-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProcessorDeliverySitesService extends RestApiService<ProcessorDeliverySite> {

  constructor(
    httpClient: HttpClient
  ) {
    super('processor_delivery_sites', 'processor_delivery_site', httpClient);
  }

  getDistanceTo(processorSite: ProcessorDeliverySite, postCode: string): Observable<DistanceMeasurement> {
    return this.httpGet<DistanceMeasurement>(this.generateUrl({ recordId: processorSite.id}, '/get_distance_to'), { post_code: postCode});
  }

}
