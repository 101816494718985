import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TermContractsService} from '../term-contracts.service';
import {TermContract} from '../term-contract.model';
import {AppRoutes} from '../../../../app-routing.module';
import {GrowingRoutes} from '../../../growing-routing.module';
import {ContractRoutes} from '../../contracts-routing.module';

@Component({
  selector: 'app-term-contract-display-card',
  templateUrl: './term-contract-display-card.component.html',
  styleUrls: ['./term-contract-display-card.component.scss']
})
export class TermContractDisplayCardComponent implements OnInit, OnChanges {

  AppRoutes = AppRoutes;
  ContractRoutes = ContractRoutes;
  GrowingRoutes = GrowingRoutes;

  contract: TermContract;

  @Input() contractId: string;

  constructor(
    private termContractsService: TermContractsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contractId.previousValue !== changes.contractId.currentValue) {
      this.reload();
    }
  }

  reload() {
    this.termContractsService.getRecord({ recordId: this.contractId }).subscribe(
      (contract) => {
        this.contract = contract;
      }
    );
  }

}
