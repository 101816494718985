import {Component, Inject, OnInit} from '@angular/core';
import {GaiaRecord} from '../../models/gaia-record.model';
import {Contact} from '../contact.model';
import {ContactsService} from '../contacts.service';
import {ContactFormControlService} from '../contact-form-control.service';
import {FormGroup} from '@angular/forms';
import {FormDialogData} from '../../components/form-dialog.directive';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {RailsUploadParams} from '@terravesta/phanes';

@Component({
  selector: 'app-contact-account-dialog',
  templateUrl: './contact-account-dialog.component.html',
  styleUrls: ['./contact-account-dialog.component.scss']
})
export class ContactAccountDialogComponent implements OnInit {

  form: FormGroup;

  contact: Contact;
  account: GaiaRecord;

  constructor(
    private contactsService: ContactsService,
    private contactFormControlService: ContactFormControlService,
    public matDialog: MatDialogRef<ContactAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ContactAccountDialogData,
  ) {
    this.contact = this.data.record;
    this.account = this.data.account;
    this.form = this.contactFormControlService.toFormGroup(this.contact);
  }

  ngOnInit(): void {
  }

  save() {
    if (this.form.valid) {
      const uploadData = {
        data: this.form.value,
        additionalParams: {
          account_id: this.account.id
        }
      } as RailsUploadParams<Contact>;
      let recordProcessing: Observable<Contact>;
      if (this.contact.id) {
        uploadData.recordId = this.contact.id;
        recordProcessing = this.contactsService.updateRecord(uploadData);
      } else {
        recordProcessing = this.contactsService.createRecord(uploadData);
      }
      recordProcessing.subscribe(() => {
          this.matDialog.close();
        },
        (error) => {
          // this.errorPanel.setErrors(error.error);
          // this.newDialog.unlockOverlay();
        });
    }
  }

}


export interface ContactAccountDialogData extends FormDialogData<Contact> {
  account: GaiaRecord;
}
