import {Component, Input, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {ErrorPanelComponent} from '@terravesta/phanes';
import {ProcessingOverlayComponent} from '@terravesta/gaia-shared';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-show-record-wrapper',
  templateUrl: './show-record-wrapper.component.html',
  styleUrls: ['./show-record-wrapper.component.scss']
})
export class ShowRecordWrapperComponent implements OnInit {

  @ViewChild(ProcessingOverlayComponent) overlay;
  @ViewChild(ErrorPanelComponent) errorPanel;

  @Input()
  allowDelete = false;

  @Input()
  form: FormGroup;

  @Output()
  saveClicked = new EventEmitter();

  @Output()
  deleteConfirmed = new EventEmitter();

  defaultMessage = $localize`:@@defaultEditRecordProcessingLockMessage:Processing update...`;

  constructor() { }

  ngOnInit(): void {
  }

  showErrors(errors){
    this.errorPanel.setErrors(errors);
  }

  public lockScreen(updateMessage?: string){
    this.overlay.lock(updateMessage);
  }

  public unlockScreen(){
    this.overlay.unlock();
  }

}
