<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.expense_claims}}</mat-icon>
    <mat-card-title i18n>Expense Summary Table</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-record-monthly-period-selector (changed)="periodChanged($event)"></app-record-monthly-period-selector>


    <mat-table [dataSource]="summaries">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.claimant.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef i18n>Total</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.claim_total | currency }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>

  </mat-card-content>
</mat-card>
