import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiscanthusStacksTableComponent } from './miscanthus-stacks-table/miscanthus-stacks-table.component';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {StackSettingsComponent} from './stack-settings/stack-settings.component';
import {StackStorageMethodsModule} from './stack-storage-methods/stack-storage-methods.module';
import {StackNoticePeriodsModule} from './stack-notice-periods/stack-notice-periods.module';
import {BaleGrabAvailabilitiesModule} from './bale-grab-availabilities/bale-grab-availabilities.module';
import {LoaderAvailabilitiesModule} from './loader-availabilities/loader-availabilities.module';
import {BaleTypesModule} from '../bale-types/bale-types.module';
import {OperationalRegionsModule} from '../operational-regions/operational-regions.module';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {PhanesComponentsModule, PhanesModule, What3WordsModule} from '@terravesta/phanes';
import { MiscanthusStackShowComponent } from './miscanthus-stack-show/miscanthus-stack-show.component';
import { MiscanthusStackFormComponent } from './miscanthus-stack-form/miscanthus-stack-form.component';
import {RouterModule} from '@angular/router';
import {MatInputModule} from '@angular/material/input';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MiscanthusFieldHarvestsModule} from './miscanthus-field-harvests/miscanthus-field-harvests.module';
import {BaleGainsModule} from './bale-gains/bale-gains.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {BaleLossesModule} from './bale-losses/bale-losses.module';
import { MiscanthusStackNewDialogComponent } from './miscanthus-stack-new-dialog/miscanthus-stack-new-dialog.component';
import {ContactsModule} from '../../contacts/contacts.module';
import { MiscanthusStackReviewComponent } from './miscanthus-stack-review/miscanthus-stack-review.component';
import { MiscanthusStackFinderComponent } from './miscanthus-stack-finder/miscanthus-stack-finder.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ScheduleMonthsModule} from '../processors/schedule-months/schedule-months.module';
import { MiscanthusStackCollectionDisplayComponent } from './miscanthus-stack-collection-display/miscanthus-stack-collection-display.component';
import {
  MiscanthusStacksDailyOpsReportComponent
} from './miscanthus-stacks-daily-ops-report/miscanthus-stacks-daily-ops-report.component';
import {MatSortModule} from '@angular/material/sort';
import {BaleCollectionsSharedModule} from '../bale-collections-shared/bale-collections-shared.module';
import {InternalNotesModule} from '../../internal-notes/internal-notes.module';
import { MiscanthusStackCollectionCreationOverviewComponent } from './miscanthus-stack-collection-creation-overview/miscanthus-stack-collection-creation-overview.component';
import {ProcessorsModule} from '../processors/processors.module';
import { MiscanthusStackMapFeatureWindowComponent } from './miscanthus-stack-map-feature-window/miscanthus-stack-map-feature-window.component';

@NgModule({
  declarations: [MiscanthusStacksTableComponent, StackSettingsComponent, MiscanthusStackShowComponent,
    MiscanthusStackFormComponent, MiscanthusStackNewDialogComponent, MiscanthusStackReviewComponent,
  MiscanthusStackFormComponent, MiscanthusStackNewDialogComponent, MiscanthusStackReviewComponent,
  MiscanthusStackFinderComponent,
  MiscanthusStackCollectionDisplayComponent,
  MiscanthusStacksDailyOpsReportComponent,
  MiscanthusStackCollectionCreationOverviewComponent,
  MiscanthusStackMapFeatureWindowComponent],
    imports: [
        StackStorageMethodsModule,
        StackNoticePeriodsModule,
        BaleGrabAvailabilitiesModule,
        LoaderAvailabilitiesModule,
        CommonModule,
        GaiaSharedModule,
        MatTableModule,
        MatPaginatorModule,
        BaleTypesModule,
        OperationalRegionsModule,
        MatSelectModule,
        ReactiveFormsModule,
        GaiaGeneralModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        PhanesModule,
        PhanesComponentsModule,
        RouterModule,
        MatInputModule,
        FlexModule,
        MatCardModule,
        AddressesModule,
        ExtendedModule,
        MatTabsModule,
        MiscanthusFieldHarvestsModule,
        BaleGainsModule,
        BaleLossesModule,
        MatExpansionModule,
        What3WordsModule,
        ContactsModule,
        MatDatepickerModule,
        ScheduleMonthsModule,
        MatAutocompleteModule,
        MatSortModule,
        BaleCollectionsSharedModule,
        InternalNotesModule,
        ProcessorsModule
    ],
    exports: [
        MiscanthusStackFinderComponent,
        MiscanthusStackReviewComponent,
        MiscanthusStackCollectionDisplayComponent,
        MiscanthusStackCollectionCreationOverviewComponent,
        MiscanthusStackMapFeatureWindowComponent,
    ],
})
export class MiscanthusStacksModule { }
