import {Component, forwardRef, Injector, Input} from '@angular/core';
import {RecordListSelectDirective} from '@terravesta/phanes';
import {SentinelFunction} from '../sentinel-function.model';
import {SentinelFunctionsService} from '../sentinel-functions.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-sentinel-functions-select',
  templateUrl: './sentinel-functions-select.component.html',
  styleUrls: ['./sentinel-functions-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SentinelFunctionsSelectComponent),
      multi: true
    }
  ]
})
export class SentinelFunctionsSelectComponent extends RecordListSelectDirective<SentinelFunction> {

  // TODO - need to check this component uses both ngModel and forms, this will error in the future

  constructor(
    sentinelFunctionService: SentinelFunctionsService,
    inj: Injector
  ) {
    super (sentinelFunctionService, inj);
  }
  @Input() selectDefault: string;
}
