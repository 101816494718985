import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceGroupsTableComponent } from './price-groups-table/price-groups-table.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {PriceGroupRoutingModule} from './price-group-routing.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {PriceGroupsService} from './price-groups.service';
import { PriceGroupShowComponent } from './price-group-show/price-group-show.component';
import {ReactiveFormsModule} from '@angular/forms';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {PhanesComponentsModule} from '@terravesta/phanes';
import { PriceGroupSelectComponent } from './price-group-select/price-group-select.component';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import { PriceGroupFormComponent } from './price-group-form/price-group-form.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ProductsModule} from '../products/products.module';
import { PriceGroupNewDialogComponent } from './price-group-new-dialog/price-group-new-dialog.component';
import { PriceGroupProductFormComponent } from './price-group-product-form/price-group-product-form.component';
import {MatListModule} from '@angular/material/list';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    declarations: [PriceGroupsTableComponent, PriceGroupShowComponent, PriceGroupSelectComponent, PriceGroupFormComponent,
        PriceGroupNewDialogComponent, PriceGroupProductFormComponent],
    imports: [
        CommonModule,
        GaiaSharedModule,
        MatTableModule,
        PriceGroupRoutingModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        GaiaGeneralModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        PhanesComponentsModule,
        MatSelectModule,
        MatCardModule,
        MatIconModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        ProductsModule,
        MatListModule,
        FlexLayoutModule,
    ],
    exports: [PriceGroupsTableComponent, PriceGroupShowComponent, PriceGroupSelectComponent],
    providers: [PriceGroupsService]
})
export class PriceGroupsModule { }
