import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {InternalNote} from '../internal-note.model';
import {MatDialog} from '@angular/material/dialog';
import {
  InternalNoteFormDialogComponent,
} from '../internal-note-form-dialog/internal-note-form-dialog.component';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {InternalNotesService} from '../internal-notes.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/phanes';
import {AppRoutes} from "../../app-routing.module";
import {DocumentsService} from "../../system-resources/documents/documents.service";
import {Document} from "../../system-resources/documents/document.model";

@Component({
  selector: 'app-internal-note-card',
  templateUrl: './internal-note-card.component.html',
  styleUrls: ['./internal-note-card.component.scss']
})
export class InternalNoteCardComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;

  @Input()
  note: InternalNote;

  @Output()
  noteEdited = new EventEmitter();

  hasMore = false;
  showMore = false;

  jsNote = false;


  constructor(
    private matDialog: MatDialog,
    private notesService: InternalNotesService,
    private documentsService: DocumentsService
  ) {
  }

  ngOnInit(): void {
    if (this.note.note_preview.length <= this.note.details.length){
      this.hasMore = true;
    }
    if (this.note.details.includes('{')) {
      this.jsNote = true;
    }
  }

  toggleShow() {
    this.showMore = !this.showMore;
  }

  editNote() {
    const ref = this.matDialog.open(InternalNoteFormDialogComponent, {
      data: {
        record: this.note
      },
      width: '60%',
      height: '60vh',
    });
    ref.afterClosed().subscribe(() => {
      this.noteEdited.emit(null);
    });
  }

  pinNote() {
    const updateData = { pinned: !this.note.pinned } as InternalNote;
    this.notesService.updateRecord(
      {
        recordId: this.note.id,
        data: updateData
      }).subscribe(
      (response) => {
        this.note = response;
        this.noteEdited.emit();
      }
    );
  }

  deleteNote() {
    const dialogData = {
      title: $localize`:@@ConfirmNoteDeletionTitle:Confirm note deletion`,
      message: $localize`:@@confirmNoteDeletion:Are you sure?`
    } as ConfirmDialogData;
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialog.afterClosed().subscribe((response) => {
      if(response) {
        this.notesService.deleteRecord({
          recordId: this.note.id,
        }).subscribe(() => {
          this.noteEdited.emit();
        });
      }
    });
  }

  uploadDocComplete() {
    this.noteEdited.emit();
  }

  deleteAttachment(file: Document) {
    const dialogData = {
      title: $localize`:@@ConfirmFileDeletionTitle:Confirm file deletion`,
      message: $localize`:@@confirmFileDeletion:Are you sure?`
    } as ConfirmDialogData;
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData
    });
    dialog.afterClosed().subscribe((response) => {
      if(response) {
        this.documentsService.deleteRecord({
          recordId: file.id,
        }).subscribe(() => {
          this.noteEdited.emit();
        });
      }
    });
  }

  downloadAttachment(file: Document) {
    return this.documentsService.getDocumentUrl(file).subscribe(
      (url) => {
        window.open(url, '_blank');
      }
    );
  }
}
