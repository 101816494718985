<app-month-year-selector [selectedMonth]="managementMonth" [selectedYear]="managementYear"
  (selectionChanged)="monthSelected($event)"></app-month-year-selector>

<gaia-shared-waiting-animation *ngIf="collections.loading$ | async"></gaia-shared-waiting-animation>

<mat-table matSort [dataSource]="collections">

  <ng-container matColumnDef="processor_ref">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Processor Ref</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a mat-button color="accent" [routerLink]="['/', appRoutes.OPERATIONS, operationsRoutes.BALE_COLLECTIONS, element.id]">
        {{ element.processor_ref }}
      </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="collection_date">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Collection Date</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.collection_date | date }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="bales_in_load">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Bale Count</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.bales_in_load }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tonnage_accepted">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Accepted Tonnage</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.tonnage_accepted }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="haulier_invoice_reference">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Haulier Invoice</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.haulier_invoice_reference }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<mat-paginator [length]="collections.totalRecords"
               [pageIndex]="collections.pageIndex"
               [pageSize]="collections.pageSize"
               [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
