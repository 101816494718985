import { Component, OnInit } from '@angular/core';
import {StaffMemberService} from '../staff-member.service';
import {BehaviorSubject} from 'rxjs';
import {HRSummaryRow} from '../staff-member.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';

@Component({
  selector: 'app-hr-summary',
  templateUrl: './hr-summary.component.html',
  styleUrls: ['./hr-summary.component.scss']
})
export class HrSummaryComponent implements OnInit {

  currentYear = new Date().getFullYear();
  endYear = this.currentYear + 5;
  hrSummary = new BehaviorSubject<Array<HRSummaryRow>>([])
  gaiaIconSet = GaiaIconSet;

  displayedColumns = ["name", "leave_allowance", "leave_used", "employment_started"];

  constructor(
    private staffMembersService: StaffMemberService,
  ) { }

  ngOnInit(): void {
  }

  loadData(year: number): void {
    this.staffMembersService.hrSummary(year).subscribe((response) => {
      this.hrSummary.next(response);
    });
  }

}
