<button mat-raised-button color="primary" type="button" i18n (click)="newSoilSample()">New Soil Samples</button>
<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.soil_samples}}</mat-icon>
    <mat-card-title i18n>Soil Samples</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="unique_id">
        <mat-header-cell *matHeaderCellDef i18n>ID</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growingRoutes.SOIL_SAMPLES, element.id]">
            {{ element.unique_id  }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date_taken">
        <mat-header-cell *matHeaderCellDef i18n>Date Taken</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.date_taken | date: 'shortDate'}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>

    <mat-paginator [length]="dataSource.totalRecords" [pageSize]="10"></mat-paginator>

  </mat-card-content>
</mat-card>
