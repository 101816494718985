
<gaia-shared-standard-dialog [dialogTitle]="dialogTitle">


  <div content>
    <div>
      <img [src]="qrCodeUrl" alt="contact qr" />
    </div>
  </div>

  <div actions>
    <button mat-button mat-dialog-close="true" i18n="close dialog button">Close</button>
  </div>

</gaia-shared-standard-dialog>

