
<mat-card [formGroup]="formGroup">
  <mat-card-header>
    <mat-card-title *ngIf="formGroup.get('id').value else productLookup">
      {{ formGroup.get('name').value }}
      <button mat-mini-fab color="primary" type="button" aria-label="" (click)="addPricePoint()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-card-title>
    <ng-template #productLookup>
      <app-product-search (productSelected)="productSelected($event)"></app-product-search>
    </ng-template>
  </mat-card-header>
  <mat-card-content *ngIf="formGroup.get('id').value" fxLayout="column">
    <ng-container *ngFor="let pricePoint; let index = index; of ($any(formGroup.get('price_points')).controls) ">
      <div [formGroup]="pricePoint" class="price-point-row" [class.to-delete]="pricePoint.get('to_delete').value">
        <mat-form-field>
          <mat-label i18n>Min Order Qty</mat-label>
          <input matInput formControlName="min_qty" type="number" step="1" />
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>VAT Rate (%)</mat-label>
          <input matInput formControlName="vat_rate" type="number" />
        </mat-form-field>
        <div>
          <mat-form-field>
            <mat-label i18n>Ex VAT Price</mat-label>
            <input matInput formControlName="ex_vat_price" type="number" />
          </mat-form-field>
          <button mat-icon-button color="primary" type="button" aria-label="" (click)="calculateExVatPrice(index)">
            <mat-icon>calculate</mat-icon>
          </button>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n>Inc VAT Price</mat-label>
            <input matInput formControlName="vat_price" type="number" />
          </mat-form-field>
          <button mat-icon-button color="primary" type="button" aria-label="" (click)="calculateVatPrice(index)">
            <mat-icon>calculate</mat-icon>
          </button>
        </div>
        <button mat-icon-button color="primary" type="button" aria-label="" (click)="removePricePoint(index)">
          <mat-icon>delete</mat-icon>
        </button>

      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
