import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HauliersTableComponent} from './hauliers-table/hauliers-table.component';
import {HaulierShowComponent} from './haulier-show/haulier-show.component';

export class HauliersRoutes {
  public static DRIVERS = 'drivers';
  public static VEHICLES = 'vehicles';
}

const routes: Routes = [
  { path: '', component: HauliersTableComponent },
  {
    path: ':id',
    children: [
      {
        path: '',
        component: HaulierShowComponent,
      },
      {
        path: HauliersRoutes.DRIVERS,
        loadChildren: () => import('./haulier-drivers/haulier-drivers-routing.module').then(m => m.HaulierDriversRoutingModule),
      },
    ]
  },
  {
    path: HauliersRoutes.VEHICLES,
    loadChildren: () => import('./haulier-vehicles/haulier-vehicles-routing.module').then(m => m.HaulierVehiclesRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HauliersRoutingModule{ }
