
<div fxLayout="row" fxLayoutGap="20px">

  <div fxLayout="column" fxFlex="calc(50%-10px)">

    <mat-card>
      <mat-card-header class="with-buttons">
        <mat-card-title i18n="title of box showing term contract status">Term Contract</mat-card-title>

        <div class="buttons">
          <button mat-icon-button [matMenuTriggerFor]="termContractMenuTrigger" id="termMenu">
            <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
          </button>
          <mat-menu #termContractMenuTrigger="matMenu">
            <button mat-menu-item (click)="assignToTerm()" id="addToTerm">
              <mat-icon> {{ gaiaIconSet.assign_to_contract }}</mat-icon>
              <span i18n="assign field to contract btn">Assign To Contract</span>
            </button>
            <button mat-menu-item (click)="removeFromTerm()" *ngIf="contractedField.contracted" id="removeFromTerm">
              <mat-icon> {{ gaiaIconSet.remove_from_contract }}</mat-icon>
              <span i18n="remove field from contract btn">Remove From Contract</span>
            </button>
          </mat-menu>
        </div>
      </mat-card-header>
      <mat-card-content>
        <app-term-contract-display-card *ngIf="contractedField.current_term_contract else notContracted"
                                          [contractId]="contractedField.current_term_contract.id"></app-term-contract-display-card>

        <ng-template #notContracted>
          <span i18n="message stating field is out of contract">Out of Contract</span>
        </ng-template>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title i18n="title of box showing previous contracts">Contract History</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <app-term-contracts-field-table-embed [contractedField]="contractedField"></app-term-contracts-field-table-embed>
      </mat-card-content>
    </mat-card>

  </div>

  <mat-card fxFlex="calc(50%-10px)">
    <mat-card-header class="with-buttons">
      <mat-card-title i18n="title of box showing establishment contract details">Establishment Contract</mat-card-title>
      <div class="buttons">
        <button mat-icon-button [matMenuTriggerFor]="establishmentContractMenu" id="establishmentMenu">
          <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
        </button>
        <mat-menu #establishmentContractMenu="matMenu">
          <button mat-menu-item (click)="assignToEstablishment()" id="assignToEstablishment" *ngIf="!contractedField.establishment_contract">
            <mat-icon> {{ gaiaIconSet.assign_to_contract }}</mat-icon>
            <span i18n="button to assign to establishment contract">Add to Establishment Contract</span>
          </button>
          <button mat-menu-item (click)="removeFromEstablishment()" id="removeFromEstablishment" *ngIf="contractedField.establishment_contract">
            <mat-icon> {{ gaiaIconSet.remove_from_contract }}</mat-icon>
            <span i18n="button to remove field from establishment contract">Remove from Establishment Contract</span>
          </button>
        </mat-menu>
      </div>
    </mat-card-header>
    <mat-card-content>
      <span *ngIf="!contractedField.establishment_contract else showEstablishmentContract" i18n="message when field has no establishment contract">
        This field has no establishment contract
      </span>

      <ng-template #showEstablishmentContract>
        <app-grower-contract-display-card [contractId]="contractedField.establishment_contract.id"></app-grower-contract-display-card>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
