
<mat-tab-group>
  <mat-tab>
    <ng-container *mat-tab-label>
      <span i18n>Leave/Absence Requests</span>
    </ng-container>
    <app-staff-absence-approval-table></app-staff-absence-approval-table>
  </mat-tab>
  <mat-tab>
    <ng-container *mat-tab-label>
      <span i18n>Expenses</span>
    </ng-container>
    <app-staff-expense-claims-approval-table></app-staff-expense-claims-approval-table>
  </mat-tab>
</mat-tab-group>
