import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ClimateDataSourceGraphComponent, GPSPoint} from '@terravesta/phanes';
import {yearArray} from '../../../../utils/common-functions';

@Component({
  selector: 'app-climate-grid-graphs',
  templateUrl: './climate-grid-graphs.component.html',
  styleUrls: ['./climate-grid-graphs.component.scss']
})
export class ClimateGridGraphsComponent implements OnInit, OnChanges {

  @Input()
  gpsPoint: GPSPoint;

  @Input()
  selectedYear: number = new Date().getFullYear() - 1;

  @Output()
  gridLocation = new EventEmitter<GPSPoint>();

  years: number[] = yearArray(2000, new Date().getFullYear() - 1).reverse();

  @ViewChild('yearlyData')
  yearlyGraph: ClimateDataSourceGraphComponent;

  @ViewChild('averageData')
  averageGraph: ClimateDataSourceGraphComponent;

  queryData: {
    query: any,
    gpsPoint: GPSPoint,
  };

  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gpsPoint){
      if (changes.gpsPoint.currentValue) {
        this.loadMetClimateData();
      }
    }
  }

  loadMetClimateData(){
    this.queryData = { query: {YEAR: this.selectedYear}, gpsPoint: this.gpsPoint };
  }

  yearlyRangeChange($event: any) {
    if (this.averageGraph){
      this.averageGraph.updateRange($event);
    }

  }

  averageRangeChange($event: any) {
    if (this.yearlyGraph){
      this.yearlyGraph.updateRange($event);
    }
  }
}
