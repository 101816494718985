<gaia-shared-standard-dialog dialogTitle="Reload Record">
  <div content>
    <p>
      Gaia needs to reload the record, but you have changes pending. Please choose from the following options
    </p>
  </div>
  <div actions>
    <button class="save-and-reload" mat-button type="button" (click)="saveAndReload()" i18n>Save &amp; Reload</button>
    <button class="reload" mat-button type="button" (click)="reloadWithoutSave()" i18n>Reload Without Saving</button>
    <button class="no-reload" mat-button type="button" (click)="noReload()" i18n>Do Not Reload</button>
  </div>
</gaia-shared-standard-dialog>
