import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaleGrabAvailabilitiesListComponent } from './bale-grab-availabilities-list/bale-grab-availabilities-list.component';
import { BaleGrabAvailabilityFormDialogComponent } from './bale-grab-availability-form-dialog/bale-grab-availability-form-dialog.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexModule} from '@angular/flex-layout';
import {BaleGrabAvailabilitySelectComponent} from './bale-grab-availability-select/bale-grab-availability-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [BaleGrabAvailabilitiesListComponent, BaleGrabAvailabilityFormDialogComponent, BaleGrabAvailabilitySelectComponent],
    exports: [
        BaleGrabAvailabilitiesListComponent,
        BaleGrabAvailabilitySelectComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        PhanesComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        FlexModule,
        MatSelectModule
    ]
})
export class BaleGrabAvailabilitiesModule { }
