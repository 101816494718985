  <div class="contacts">

  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Contacts" i18n-pageTitle="Page title for contacts page"
                    i18n-searchHint searchHint="Email/Post Code/Surname">
  </app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="[element.id]">{{element.email}}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.first_name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="surname">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Surname </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.surname }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="post_code">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Post Code </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.address.post_code}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="has_login">
      <mat-header-cell class="w-75" *matHeaderCellDef > Has Login? </mat-header-cell>
      <mat-cell class="w-75" *matCellDef="let element">
        <mat-icon *ngIf="element.can_login">
          {{ gaiaIconSet.check }}
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="menu">
      <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="w-75" *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Contact menu">
          <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="[element.id]">
            <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
            <span i18n="edit option from menu">Edit</span>
          </a>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSize]="dataSource.pageSize"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
