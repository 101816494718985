import {AfterViewInit, OnInit, ViewChild, Directive} from '@angular/core';
import {GaiaIndexDataSource} from '../services/gaia-index-data-source';
import {MatPaginator} from '@angular/material/paginator';
import {RestApiService} from '../services/rest-api.service';
import {take} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AppRoutes} from '../app-routing.module';
import {GaiaLogger} from '../../utils/common-functions';
import {GaiaIconSet} from '../models/gaia-icon-set';
import {MatSort, Sort} from '@angular/material/sort';
import {PhanesNode} from '@terravesta/phanes';

@Directive()
export abstract class IndexTableDirective<T extends PhanesNode> implements OnInit, AfterViewInit {

  gaiaIconSet = GaiaIconSet;
  dataSource: GaiaIndexDataSource<T>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  appRoutes = AppRoutes;
  searchTerm: string = null;
  currentSearch: any = {};

  private startPage = 0;
  private startPageSize = 30;
  private startSort: Sort = null;

  protected constructor(
    protected dataService: RestApiService<T>,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected matDialog: MatDialog,
    protected dialogType: any,
    protected defaultSort: Sort = null,
    protected searchTermAttributes: Array<string> = []
  ){
    this.startSort = defaultSort;
  }

  ngOnInit(): void {
    /*
     * Take one should mean it doesn't trigger after the initial page load
     * otherwise this will reload each time the updateSearchHistory method is called, and this will cause
     * an infinite loop
    */
    this.activatedRoute.queryParams.pipe(take(1)).subscribe( (params) => {
      if ('pageNumber' in params) {
        GaiaLogger.log('requested params', params);
        this.paramsToState(params);
      }
    });
    this.dataSource = new GaiaIndexDataSource<T>(this.dataService, this.startSort, this.startPageSize, this.startPage);
    this.dataSource.recordsChanged.subscribe(() => {
      this.updateSearchHistory();
    });
    this.currentSearch = this.searchOpts();
    this.loadRecords();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  paramsToState(params: any){
    this.startPage = Number(params.pageNumber);
    this.startPageSize = Number(params.pageSize);
    if(params.sort){
      const sort = JSON.parse(params.sort);
      this.startSort = {
        direction: sort.direction,
        active: sort.active,
      }
    }
    if(params.search) {
      const search = JSON.parse(params.search);
      this.parseStartSearch(search);
    }
  }

  loadRecords(){
    this.dataSource.search(this.currentSearch);
  }

  newRecord() {
    this.matDialog.open(this.dialogType);
  }

  doSearch(searchTerm: string = null) {
    if (searchTerm !== null) {
      this.searchTerm = searchTerm;
    }
    this.currentSearch = this.searchOpts();
    this.loadRecords();
  }

  fuzzySearchTerm(): string {
    return `*${this.searchTerm}*`;
  }

  unFuzzySearchTerm(fuzzyTerm: string): string {
    const matches = fuzzyTerm.match(/\*(.*)\*/);
    return matches[1];
  }

  updateTable(): void {
    this.loadRecords();
  }

  parseStartSearch(search: any) {
    this.currentSearch = search;
    if (this.searchTermAttributes.length > 0) {
      if (search[this.searchTermAttributes[0]]) {
        this.searchTerm = this.unFuzzySearchTerm(search[this.searchTermAttributes[0]]);
      }
    }
  }

  searchOpts(): any {
    const searchOpts: any = {};
    if (this.searchTerm && this.searchTerm.length > 0) {
      this.searchTermAttributes.forEach((key) => {
        searchOpts[key] = this.fuzzySearchTerm();
      });
      if (this.searchTermAttributes.length > 1) {
        searchOpts.search_mode = 'or';
      }
    }
    return searchOpts;
  }

  updateSearchHistory(){
    const queryParams = {
      pageNumber: this.dataSource.pageIndex,
      pageSize: this.dataSource.pageSize,
      search: JSON.stringify(this.dataSource.searchParams),
      sort: null,
    }
    if (this.dataSource.sort) {
      if (this.dataSource.sort.active && this.dataSource.sort.direction) {
        queryParams.sort = JSON.stringify({
          active: this.dataSource.sort.active,
          direction: this.dataSource.sort.direction
        });
      }
    }
    this.router.navigate(
      [],
      {
        queryParams,
        relativeTo: this.activatedRoute,
        replaceUrl: true,
      }
    ).then(() => {
      GaiaLogger.log('updated history', queryParams);
    });
  }
}

