import {Component, Inject} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {BaleGrabAvailability} from '../bale-grab-availability.model';
import {BaleGrabAvailabilitiesService} from '../bale-grab-availabilities.service';
import {BaleGrabAvailabilityFormControlService} from '../bale-grab-availability-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-bale-grab-availability-form-dialog',
  templateUrl: './bale-grab-availability-form-dialog.component.html',
  styleUrls: ['./bale-grab-availability-form-dialog.component.scss']
})
export class BaleGrabAvailabilityFormDialogComponent extends FormDialogDirective<BaleGrabAvailability> {

  constructor(
    baleGrabAvailabilitiesService: BaleGrabAvailabilitiesService,
    baleGrabAvailabilityFormControl: BaleGrabAvailabilityFormControlService,
    matDialog: MatDialogRef<BaleGrabAvailabilityFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: FormDialogData<BaleGrabAvailability>,
  ) {
    super(baleGrabAvailabilitiesService, baleGrabAvailabilityFormControl, matDialog, data);
  }

}
