
<mat-progress-spinner *ngIf="updating">
</mat-progress-spinner>

<div>
  <mat-form-field>
    <mat-label>{{ labelText}}</mat-label>
    <mat-select [formControl]="control" ngDefaultControl>
      <mat-optgroup *ngFor="let processor of scheduleMonthLists">
        {{ processor.name }}
        <mat-option *ngFor="let month of processor.schedule_months" [value]="month.id">
          {{ month.month_text }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>
