import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ContractedFieldsService} from '../contracted-fields.service';
import {GaiaLogger, yearArray} from '../../../../utils/common-functions';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {SentinelFunctionsService} from '../../../system-resources/sentinel/sentinel-functions/sentinel-functions.service';
import {SentinelFunction} from '../../../system-resources/sentinel/sentinel-functions/sentinel-function.model';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {ContractedField} from '../contracted-field.model';
import moment, {Moment} from 'moment';


@Component({
  selector: 'app-contracted-field-sentinel-viewer',
  templateUrl: './contracted-field-sentinel-viewer.component.html',
  styleUrls: ['./contracted-field-sentinel-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContractedFieldSentinelViewerComponent implements OnInit {
  // TODO clean up no longer needed attributes
  imageFile: Blob;
  url: SafeUrl;
  src1: string;
  src2: string;
  defaultSelect: Array<SentinelFunction>;
  sentinelSelect: string;
  sentinelSelect1 = new FormGroup({
    select_sentinel: new FormControl(''),
    selected_year: new FormControl(moment().year()),
    selected_month: new FormControl(moment().month()+1)
  });
  newStartDate: Moment;
  //selectedYear: number = new Date().getFullYear();
  years: number[] = yearArray(2015, moment().year()).reverse();
  //selectedMonth: number = new Date().getMonth();
  months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // startDate = new FormControl(new Date());
  endDate = new FormControl( moment());
  startRangeDate: Moment;
  endRangeDate: Moment;
  show = true;
  ndviId = '';
  ndmiId = '';
  sentinelImageDates: any;
  cloudCoverMin = 0;
  cloudCoverMax = 50;
  dateClass: MatCalendarCellClassFunction<Moment>;
  // dateClassFirstDate: MatCalendarCellClassFunction<Moment>;
  currentDate: Moment;
  showFilter = true;
  @Input() contractedField: ContractedField;
  constructor( private contractedFieldsService: ContractedFieldsService,
               private sentinelFunctionsService: SentinelFunctionsService,
               private sanitizer: DomSanitizer,
               public dialog: MatDialog,
              ) {}

  ngOnInit(): void {
    this.dateClass = (cellDate, view) => {
      // Only highlight dates inside the month view.
      if (view === 'month') {

        const highlightDate = this.sentinelImageDates.dates.map((strDate) => moment(strDate)).some(
            (d) => {
              return d.date() === cellDate.date() &&
              d.month() === cellDate.month() &&
              d.year() === cellDate.year()
            });
        return highlightDate ? 'example-custom-date-class' : '';
      }
    };
    this.sentinelFunctionsService.getRecords({pageNumber: 0, pageSize: 100 }).subscribe((list) =>
    {
      this.defaultSelect = list.records;
      // TODO review this, should just use one from list, hard coded values in here are not a good idea
      // TODO needs some checks for if not sentinel functions in database
      list.records.forEach((functionId) => {
        if (functionId.name === 'NDVI Index'){
          this.ndviId = functionId.id;
          this.sentinelSelect = functionId.id;
          this.monthYearChanged();
        }
        if (functionId.name === 'Moisture Index'){
          this.ndmiId = functionId.id;
        }
      });
    });
  }
  getSentinelImage(sentinelSelect: string, startDate: string){
    this.contractedFieldsService.sentinelImage(this.contractedField, sentinelSelect, startDate).
    subscribe(
    (response) =>
    {
      this.url = null;
      this.imageFile = new Blob([response], {type: 'application/png'});
      this.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imageFile as Blob));
      this.show = false;
    });
}
  getSentinelImageDates(sentinelSelect: string, startDate: any, endDate: any, cloudCoverMin: number, cloudCoverMax: number){
    this.contractedFieldsService.sentinelImageDates(this.contractedField, sentinelSelect, startDate, endDate, cloudCoverMin, cloudCoverMax).
    subscribe((response) => {
        this.sentinelImageDates = response;
        GaiaLogger.log(this.sentinelImageDates);
        this.currentDate = this.sentinelImageDates.dates.slice(-1)[0];
        GaiaLogger.log(this.currentDate);
        if (this.sentinelImageDates.dates.length > 0)
        {
          this.getSentinelImage(this.sentinelSelect, this.sentinelImageDates.dates.slice(-1)[0]);
          GaiaLogger.log(this.sentinelSelect, this.sentinelImageDates.dates.slice(-1)[0]);
        }
        this.showFilter = false;
      });
  }

 /* resetIndexFilter() {
    GaiaLogger.log(this.currentDate);
    /!*this.sentinelSelect = this.sentinelSelect.reset('');*!/
    this.sentinelSelect1.patchValue({select_sentinel: null}, {emitEvent: false});
  }*/

  changeIndexFunction() {
    this.show = true;
    this.startRangeDate = this.currentDate;
    this.endRangeDate = this.endDate.value;
    const month = this.startRangeDate.month() + 1; // months from 1-12
    GaiaLogger.log("check me"+month);
    const day = this.startRangeDate.date();
    const year = this.startRangeDate.year();
    const newStartDate = year + '-' + month + '-' + day;
    this.sentinelSelect = this.sentinelSelect1.get('select_sentinel').value;
    this.getSentinelImage(this.sentinelSelect, newStartDate);
  }

  cloudCoverSlider($event: any) {
    this.cloudCoverMax = $event.value;
    GaiaLogger.log($event.value);
    /*this.getSentinelImageDates(this.sentinelSelect, this.startDate.value, this.endDate.value, this.cloudCoverMin, this.cloudCoverMax);*/
    this.monthYearChanged();
  }

  monthYearChanged() {
    this.showFilter = true;
    this.show = true;
    this.newStartDate = moment(this.sentinelSelect1.get('selected_year').value + '-' + this.sentinelSelect1.get('selected_month').value + '-' + 1);
    const newEndDate =  moment(this.newStartDate).endOf('month').format();
    GaiaLogger.log('I am here', this.newStartDate, newEndDate);
    this.getSentinelImageDates(this.sentinelSelect, this.newStartDate, newEndDate, this.cloudCoverMin, this.cloudCoverMax);
  }

  changeSentinelFilter() {
    this.showFilter = true;
    this.show = true;
    this.sentinelSelect = this.sentinelSelect1.get('select_sentinel').value;
    this.newStartDate = moment(this.sentinelSelect1.get('selected_year').value + '-' + this.sentinelSelect1.get('selected_month').value + '-' + 1);
    const newEndDate = new Date(this.newStartDate.year(), this.newStartDate.month() + 1, 0);
    this.getSentinelImageDates(this.sentinelSelect, this.newStartDate, newEndDate, this.cloudCoverMin, this.cloudCoverMax);
  }

}
