import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractorsTableComponent } from './contractors-table/contractors-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ContractorShowComponent } from './contractor-show/contractor-show.component';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import { ContractorFormComponent } from './contractor-form/contractor-form.component';
import { ContractorNewDialogComponent } from './contractor-new-dialog/contractor-new-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ContractorSelectComponent } from './contractor-select/contractor-select.component';
import {MatSelectModule} from '@angular/material/select';
import {OperationalRegionsModule} from '../operational-regions/operational-regions.module';
import {FlexModule} from '@angular/flex-layout';
import {MatTabsModule} from '@angular/material/tabs';
import {ContactsModule} from '../../contacts/contacts.module';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import { ContractorStatsDashboardComponent } from './contractor-stats-dashboard/contractor-stats-dashboard.component';
import { ContractorUniversalSearchResultComponent } from './contractor-universal-search-result/contractor-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../../universal-search/universal-search-layout.module';
import {MatListModule} from '@angular/material/list';

@NgModule({
  declarations: [ContractorsTableComponent, ContractorShowComponent, ContractorFormComponent,
    ContractorNewDialogComponent, ContractorSelectComponent, ContractorStatsDashboardComponent, ContractorUniversalSearchResultComponent, ],
  exports: [
    ContractorSelectComponent,
    ContractorFormComponent,
    ContractorUniversalSearchResultComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    MatIconModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    AddressesModule,
    MatSlideToggleModule,
    MatSelectModule,
    OperationalRegionsModule,
    FlexModule,
    MatTabsModule,
    ContactsModule,
    MatButtonModule,
    MatChipsModule,
    UniversalSearchLayoutModule,
    MatListModule
  ]
})
export class ContractorsModule { }
