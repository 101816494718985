import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Image} from './image.model';
import {RestApiService} from '../../services/rest-api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImagesService extends RestApiService<Image> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/images', 'image', httpClient);
  }

  newVersion(image: Image, file: File): Observable<Image> {
    const params = {
      new_version: file ,
    };
    return this.httpPost(this.generateUrl({recordId: image.id}, '/new_version'),
      this.paramsToFormData(params));
  }

}
