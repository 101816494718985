import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UniversalSearchPopupComponent} from '../universal-search-popup/universal-search-popup.component';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {MatButton} from '@angular/material/button';
import {GaiaLogger} from '../../../utils/common-functions';

@Component({
  selector: 'app-universal-search-tool',
  templateUrl: './universal-search-tool.component.html',
  styleUrls: ['./universal-search-tool.component.scss']
})
export class UniversalSearchToolComponent implements OnInit {

  @ViewChild(MatButton)
  input: MatButton;

  gaiaIconSet = GaiaIconSet;

  constructor(
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  openSearchDialog() {
    const element = this.input._elementRef.nativeElement;
    const location = element.getBoundingClientRect();
    GaiaLogger.log(location);
    this.matDialog.open(UniversalSearchPopupComponent, {
      hasBackdrop: true,
      position: {
        top: `${location.top}px`
      }
    });
  }
}
