
<mat-card>
  <gaia-shared-processing-overlay></gaia-shared-processing-overlay>

  <mat-card-header>
    <mat-card-title>{{ uploaderTitle }}</mat-card-title>
  </mat-card-header>
  <img *ngIf="currentImage" mat-card-image [src]="imageUrl" alt="Current Image">
  <mat-card-actions>

    <ng-container [formGroup]="form" *ngIf="form">
      <app-file-upload formControlName="new_version" [fileTypes]="fileType"></app-file-upload>
    </ng-container>
    <button mat-raised-button color="accent" (click)="upload()" i18n>Upload</button>
  </mat-card-actions>

</mat-card>
