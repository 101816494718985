import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffExpenseClaimsTableComponent } from './staff-expense-claims-table/staff-expense-claims-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TeamSpaceConcernsModule} from '../team-space-concerns/team-space-concerns.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { StaffExpenseClaimsFormDialogComponent } from './staff-expense-claims-form-dialog/staff-expense-claims-form-dialog.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatInputModule} from '@angular/material/input';
import {StaffExpenseCategoriesModule} from '../staff-expense-categories/staff-expense-categories.module';
import {MatMenuModule} from '@angular/material/menu';
import { StaffExpenseClaimsApprovalTableComponent } from './staff-expense-claims-approval-table/staff-expense-claims-approval-table.component';
import { StaffExpenseClaimsOverviewComponent } from './staff-expense-claims-overview/staff-expense-claims-overview.component';



@NgModule({
  declarations: [StaffExpenseClaimsTableComponent, StaffExpenseClaimsFormDialogComponent,
    StaffExpenseClaimsApprovalTableComponent, StaffExpenseClaimsOverviewComponent],
  exports: [
    StaffExpenseClaimsTableComponent,
    StaffExpenseClaimsApprovalTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    TeamSpaceConcernsModule,
    MatFormFieldModule,
    MatSelectModule,
    GaiaGeneralModule,
    FormsModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    PhanesComponentsModule,
    MatInputModule,
    StaffExpenseCategoriesModule,
    MatMenuModule
  ]
})
export class StaffExpenseClaimsModule { }
