import {Directive, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GaiaIconSet} from '../models/gaia-icon-set';
import {RestApiService} from '../services/rest-api.service';
import {GaiaRecordFormControl} from '../services/gaia-record-form-control';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorPanelComponent, RailsUploadParams} from '@terravesta/phanes';
import {NewRecordDialogComponent} from '../gaia-general/new-record-dialog/new-record-dialog.component';
import {Observable} from 'rxjs';
import {GaiaRecord} from '../models/gaia-record.model';
import {GaiaLogger} from '../../utils/common-functions';

@Directive()
export abstract class FormDialogDirective<T extends GaiaRecord> implements OnInit {

  gaiaIconSet = GaiaIconSet;
  form: FormGroup;

  @ViewChild(ErrorPanelComponent) errorPanel: ErrorPanelComponent;
  @ViewChild(NewRecordDialogComponent) newDialog: NewRecordDialogComponent;

  @Input()
  record: T;

  protected constructor(
    protected recordService: RestApiService<T>,
    protected recordFormControl: GaiaRecordFormControl<T>,
    public matDialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<T>,
  ) {
    this.record = data.record;
    GaiaLogger.log('FormDialog: Record', this.record);
  }

  ngOnInit(): void {
    this.form = this.recordFormControl.toFormGroup(this.data.record);
    GaiaLogger.log('FormDialog: Form', this.form);
  }

  save() {
    if (this.form.valid) {
      let recordProcessing: Observable<T>;
      const params = { data: this.form.value } as RailsUploadParams<T>;
      if (this.data.parent){
        params.parentId = this.data.parent.id;
      }
      if (this.record.id) {
        params.recordId = this.record.id;
        recordProcessing = this.recordService.updateRecord(params);
      } else {
        recordProcessing = this.recordService.createRecord(params);
      }
      recordProcessing.subscribe((record) => {
          this.matDialog.close(record);
        },
        (error) => {
          this.errorPanel.setErrors(error.error);
          this.newDialog.unlockOverlay();
        });
    }
  }
}

export interface FormDialogData<T, P = GaiaRecord> {
  record: T;
  parent?: P;
}
