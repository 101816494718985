<gaia-shared-standard-dialog dialogTitle="Remove Crop" i18n-dialogTitle="title for dialog where the user enters details on crop removal">
  <div content>
    <div [formGroup]="formGroup" fxLayout="column">

      <mat-form-field>
        <mat-label i18n="date when field was removed from contract">Remove from Contract on</mat-label>
        <input matInput [matDatepicker]="contractPicker" formControlName="contract_removal_date">
        <mat-datepicker-toggle matSuffix [for]="contractPicker"></mat-datepicker-toggle>
        <mat-datepicker #contractPicker></mat-datepicker>
      </mat-form-field>

      <p>Has the crop also been removed? If so please fill in the following details</p>

      <mat-form-field>
        <mat-label i18n="reason for crop removal">Reason For Removal</mat-label>
        <input matInput formControlName="reason_for_removal" type="text" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="date of crop removal">Date of Removal</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="crop_removed_on">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </div>
  </div>

  <div actions>
    <button mat-button [disabled]="!this.formGroup.valid" (click)="removeCrop()" class="remove-btn">Remove Crop</button>
    <button mat-button mat-dialog-close="cancel">Cancel</button>
  </div>

</gaia-shared-standard-dialog>
