import {Component, forwardRef, Injector} from '@angular/core';
import {StackNoticePeriod} from '../stack-notice-period.model';
import {StackNoticePeriodsService} from '../stack-notice-periods.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {RecordListSelectDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-stack-notice-period-select',
  templateUrl: './stack-notice-period-select.component.html',
  styleUrls: ['./stack-notice-period-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StackNoticePeriodSelectComponent),
      multi: true
    }
  ]
})
export class StackNoticePeriodSelectComponent extends RecordListSelectDirective<StackNoticePeriod> {

  constructor(
    stackNoticePeriodsService: StackNoticePeriodsService,
    inj: Injector,
  ) {
    super(stackNoticePeriodsService, inj);
  }

}
