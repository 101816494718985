import {Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {MiscanthusStack} from '../../miscanthus-stack.model';
import {MiscanthusFieldHarvestsService} from '../miscanthus-field-harvests.service';
import {MiscanthusFieldHarvest} from '../miscanthus-field-harvest.model';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MatDialog} from '@angular/material/dialog';
import {
  MiscanthusFieldHarvestDialogComponent
} from '../miscanthus-field-harvest-dialog/miscanthus-field-harvest-dialog.component';
import {FormDialogData} from '../../../../components/form-dialog.directive';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-miscanthus-field-harvest-embed-table',
  templateUrl: './miscanthus-field-harvest-embed-table.component.html',
  styleUrls: ['./miscanthus-field-harvest-embed-table.component.scss']
})
export class MiscanthusFieldHarvestEmbedTableComponent implements OnInit, OnChanges {

  @Input()
  stack: MiscanthusStack;

  // Output true if reload needed
  @Output()
  changeMade: EventEmitter<boolean> = new EventEmitter<boolean>();

  fieldHarvests: Array<MiscanthusFieldHarvest>;
  displayedColumns = ['contracted_field_name', 'bale_count', 'bale_type', 'date_added', 'menu'];
  gaiaIconSet = GaiaIconSet;

  constructor(
    private miscanthusFieldHarvestsService: MiscanthusFieldHarvestsService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stack){
      if (changes.stack.currentValue) {
        this.loadFieldHarvests();
      }
    }
  }

  loadFieldHarvests(){
    this.miscanthusFieldHarvestsService.getRecords({parentId: this.stack.id, pageNumber: 0, pageSize: 30})
      .subscribe((fieldHarvests) => {
        this.fieldHarvests = fieldHarvests.records;
      });
  }

  editFieldHarvest(fieldHarvest: MiscanthusFieldHarvest) {
    const dialogRef = this.matDialog.open(MiscanthusFieldHarvestDialogComponent, {
      data: {
        record: fieldHarvest,
        parent: this.stack,
      } as FormDialogData<MiscanthusFieldHarvest>
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.loadFieldHarvests();
        this.changeMade.emit(true);
      }
    );
  }

  deleteFieldHarvest(fieldHarvest: MiscanthusFieldHarvest) {
    const dialogRef = this.matDialog.open<any, any, boolean>(ConfirmDialogComponent, {
      data: {
        title: 'Confirm: Delete Field Harvest',
        message: 'Are you sure you wish to delete this field harvest. This will effect the total number of bales in the stack. This action cannot be undone.'
      } as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.miscanthusFieldHarvestsService.deleteRecord({ recordId: fieldHarvest.id, parentId: this.stack.id }).subscribe(() => {
          this.loadFieldHarvests();
          this.changeMade.emit(true);
        });
      }
    });

  }
}
