import { Injectable } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RecordedMonthlyFormControlService {

  constructor(
    private formBuilder: FormBuilder
  ) { }

  periodSelectForm(date = new Date()): FormGroup {
    return this.formBuilder.group({
      month: new FormControl(date.getMonth() + 1),
      year: new FormControl(date.getFullYear()),
    });
  }
}
