import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureBannersTableComponent } from './feature-banners-table/feature-banners-table.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import { FeatureBannerFormComponent } from './feature-banner-form/feature-banner-form.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FeatureBannerNewDialogComponent } from './feature-banner-new-dialog/feature-banner-new-dialog.component';
import { FeatureBannerShowComponent } from './feature-banner-show/feature-banner-show.component';

@NgModule({
  declarations: [
    FeatureBannersTableComponent,
    FeatureBannerFormComponent,
    FeatureBannerNewDialogComponent,
    FeatureBannerShowComponent
  ],
  imports: [
    CommonModule,
    GaiaSharedModule,
    GaiaGeneralModule,
    MatTableModule,
    RouterModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexModule,
    MatDatepickerModule
  ],
  exports: [
    FeatureBannersTableComponent
  ]
})
export class FeatureBannersModule { }
