import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {BaleType, BaleTypeWeights} from './bale-type.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaleTypesService extends RestApiService<BaleType> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/bale_types', 'bale_type', httpClient);
  }

  baleWeightsFor(startYear: number, endYear: number): Observable<Array<BaleTypeWeights>>{
    return this.httpGet(this.generateNonRecordUrl('bale_weights_for'), {
      start_year: startYear, end_year: endYear
    });
  }
}
