<div class="products">
  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button color="accent" [routerLink]="['/', appRoutes.COMMERCIAL_SALES, commercialSalesRoutes.PRODUCTS, element.id]">{{element.name}}</button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sku">
      <mat-header-cell *matHeaderCellDef>SKU</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.sku}}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cost_price">
      <mat-header-cell *matHeaderCellDef>Cost Price</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element.cost_price | currency}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSize]="dataSource.pageSize"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
</div>
