<gaia-shared-standard-dialog dialogTitle="Gallery" i18n-dialogTitle>
  <div content>
    <div fxLayout="row wrap" fxLayoutGap="15px">
      <div *ngFor="let image of images.getRecords() | async" fxFlex="calc(25% - 15px)" fxLayout="column"
        class="gallery-record">
        <app-image-thumbnail [image]="image"></app-image-thumbnail>
        <button mat-button (click)="selectImage(image)">Select</button>
      </div>
    </div>
  </div>
</gaia-shared-standard-dialog>
