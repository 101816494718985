import {Component, Input, OnInit} from '@angular/core';
import {Grower} from '../grower.model';
import {FormGroup} from '@angular/forms';
import {GrowerFormControlService} from '../grower-form-control.service';
import {GrowersService} from '../growers.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-grower-contractor-view',
  templateUrl: './grower-contractor-view.component.html',
  styleUrls: ['./grower-contractor-view.component.scss']
})
export class GrowerContractorViewComponent implements OnInit {

  @Input()
  grower: Grower;

  contractorForm: FormGroup;

  constructor(
    private growersService: GrowersService,
    private growersFormControlService: GrowerFormControlService,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.contractorForm = this.growersFormControlService.toContractorsFormGroup(this.grower);
    GaiaLogger.log(this.contractorForm.value);
  }

  save(): void {
    if (this.contractorForm.valid) {
      this.growersService.updateRecord({recordId: this.grower.id, data: this.contractorForm.value}).subscribe(
        (result) => {
          this.grower = result;
          this.snackbar.open($localize`:@@Grower.contractorInfoUpdate:Contractors Updated`);
        }
      );
    }
  }

}
