<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.gps_map}}</mat-icon>
    <mat-card-title i18n="box title for map view of a contracted field">Map</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <a *ngIf="agriculturalField.latitude" mat-raised-button color="primary"
       [href]="navigateUrl"
       target="_blank" i18n="link to the google map navigate tool">Navigate</a>

    <phanes-google-map width="100%" [preferPolygon]="true" [mapRecordsItems]="fieldMapData" [mapZoom]="14" [enabledEditing]="true" (drawingSaved)="polygonUpdated($event)"></phanes-google-map>

    <gaia-shared-spinner-loading-button color="primary" #kmlDownload (clickOccurred)="downloadKml()" i18n="Button to download kml file">Download KML</gaia-shared-spinner-loading-button>

    <app-file-selector accept="application/vnd.google-earth.kml+xml" buttonText="Upload KML" i18n-buttonText (fileSelected)="uploadMap($event)"></app-file-selector>


  </mat-card-content>

</mat-card>
