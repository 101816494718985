<div>
  <h1 style="text-align: center">Map Overview Dashboard</h1>
</div>
<div>
  <mat-slide-toggle id="toggleCfDisabled" (change)="toggleContFieldDisplay($event);">
    Contracted Fields View</mat-slide-toggle>&nbsp;&nbsp;
  <mat-slide-toggle id="togglePvDisabled" (change)="toggleProcessorDisplay($event);">
    Processor View</mat-slide-toggle>&nbsp;&nbsp;
  <mat-slide-toggle id="toggleOvDisabled" (change)="toggleOpportunityDisplay($event);">
    Opportunities View
  </mat-slide-toggle>&nbsp;&nbsp;
  <mat-slide-toggle id="toggleSvDisabled" (change)="toggleStacksDisplay($event);">
    Stacks View
  </mat-slide-toggle>
</div>
<br/>
<div fxLayout="row" fxLayoutGap="9px" fxLayout.lt-sm="column">
      <ng-container [formGroup]="contractFieldForm" *ngIf = "isShowContField">
        <mat-form-field id = "year_planted" appearance="outline">
          <mat-label i18n>Planted Year</mat-label>
          <mat-select formControlName="year_planted">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field id = "miscanthus_variety" appearance="outline">
          <mat-label i18n>Crop Variety</mat-label>
          <mat-select formControlName="miscanthus_variety">
            <mat-option *ngFor="let miscVariety of variety" [value]="miscVariety.id">{{ miscVariety.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="none" style=" width: 60px !important">
          <mat-label>Polygons</mat-label>
          <mat-slide-toggle formControlName="marker_type" id ="marker_type"></mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </ng-container>
      <ng-container [formGroup]="opportunitiesForm" *ngIf = "isShowOpportunity">
        <mat-form-field id="stage" appearance="outline">
          <mat-label i18n>Stage</mat-label>
          <mat-select formControlName="stage">
            <mat-select-trigger>{{opportunitiesForm.get("stage").value}}<mat-icon [ngStyle]="{'color': stageToColor(opportunitiesForm.get('stage').value)}">star</mat-icon>
            </mat-select-trigger>
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let stage of stages; let i = index" [value]="stage">{{ stage }}
              <mat-icon [ngStyle]="{'color': stageColors[i]}">star</mat-icon>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field id = "expected_planting_year" appearance="outline">
          <mat-label i18n>Expected Planting Year</mat-label>
          <mat-select formControlName="expected_planting_year">
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let year of plantingsYears" [value]="year">{{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field id ="sales_survey_completed" appearance="outline">
          <mat-label i18n>Sales Survey</mat-label>
          <mat-select formControlName="sales_survey_completed">
            <mat-option [value]="null" i18n>-</mat-option>
            <mat-option [value]="true" i18n>Completed</mat-option>
            <mat-option [value]="false" i18n>Not Completed</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container [formGroup]="processorForm" *ngIf = "isShowProcessor">
        <mat-form-field floatLabel="always" appearance="none">
          <mat-label>Active</mat-label>
          <mat-slide-toggle id ="active" formControlName="active" (change)="processorToggleActive($event);"></mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
       </ng-container>
      <ng-container [formGroup]="stackForm" *ngIf = "isShowStack">
        <mat-form-field id="harvest_year" appearance="outline">
          <mat-label i18n>Harvest Year</mat-label>
          <mat-select formControlName="harvest_year">
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field id = "schedule_month" appearance="outline">
          <mat-label i18n>Schedule Month</mat-label>
          <mat-select formControlName="schedule_month">
            <mat-option [value]="null">-</mat-option>
            <mat-option *ngFor="let month of scheduleMonth" [value]="month">{{ month }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field id ="delivery_status" appearance="outline">
          <mat-label i18n>Delivery Status</mat-label>
          <mat-select formControlName="delivery_status">
            <mat-option [value]="null" i18n>-</mat-option>
            <mat-option value="completed" i18n>Completed</mat-option>
            <mat-option value="partially_completed" i18n>Partially Completed</mat-option>
            <mat-option value="not_completed" i18n>Not Completed</mat-option>
          </mat-select>
        </mat-form-field>
        <p>
          <mat-button-toggle-group [formControl]="selectedToggle" (change)="onToggleChange($event)" style=" margin-block: 5px;" name="colorCode" aria-label="Color Code">
            <mat-button-toggle value="processor">Processor</mat-button-toggle>
            <mat-button-toggle value="delivery">Delivery</mat-button-toggle>
          </mat-button-toggle-group>
        </p>
      </ng-container>
</div>
<div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
  <mat-card *ngIf="!show" fxFlex="20%" style="border-bottom: 5px solid #1b7001; border-radius: 10px" class="mat-elevation-z0">
    <ng-container>
        <div echarts style="height: 200px" [options]="cropVarietyChart"></div>
    </ng-container>
  </mat-card>
  <mat-card *ngIf="!show" fxFlex="20%" style="border-bottom: 5px solid #1b7001; border-radius: 10px" class="mat-elevation-z0">
    <ng-container>
      <div echarts style="height: 200px" [options]="yearVsField"></div>
    </ng-container>
  </mat-card>
</div>
<div class="options" fxLayout="row" style="position: relative;" fxLayoutGap="10px" fxLayout.lt-md="column">
  <div fxFlex="100" class="google-map">
      <phanes-google-map width="100%" height="100vh" (recordItemClicked)="mapClicked($event)" [mapRecordsItems]="mapAllRecords" [preferPolygon]=this.markerType>
      </phanes-google-map>
  </div>
  <div fxLayout.lt-sm="30" class="smallInfo" [class.mobile]=isMobile>
    <ng-container [ngSwitch]="selectedRecordType">
      <ng-container *ngSwitchCase="'contractedField'">
        <app-contracted-field-map-feature-window *ngIf="this.isShowContField" (closeClicked)="featureWindowClosed($event)" [clickedContractedFieldRecord]="selectedRecord">
        </app-contracted-field-map-feature-window>
      </ng-container>
      <ng-container *ngSwitchCase="'processorRecords'">
        <app-processor-map-feature-window *ngIf="this.isShowProcessor" (closeClicked)="featureWindowClosed($event)" [clickedProcessorRecord]="selectedRecord">
        </app-processor-map-feature-window>
      </ng-container>
      <ng-container *ngSwitchCase="'opportunitiesRecords'">
        <app-planting-opportunity-map-feature-window *ngIf="this.isShowOpportunity" (closeClicked)="featureWindowClosed($event)" [clickedOpportunityRecord]="selectedRecord">
        </app-planting-opportunity-map-feature-window>
      </ng-container>
      <ng-container *ngSwitchCase="'stackRecords'">
        <app-miscanthus-stack-map-feature-window *ngIf="this.isShowStack" (closeClicked)="featureWindowClosed($event)" [clickedMiscanthusRecord]="selectedRecord">
        </app-miscanthus-stack-map-feature-window>
      </ng-container>
  </ng-container>
  </div>
</div>
