<phanes-page-title pageTitle="Stack Settings" i18n-pageTitle></phanes-page-title>

<div fxLayout="row wrap" fxLayoutGap="15px grid">
  <app-stack-storage-method-list fxFlex="50%"></app-stack-storage-method-list>

  <app-stack-notice-periods-list fxFlex="50%"></app-stack-notice-periods-list>

  <app-bale-grab-availabilities-list fxFlex="50%"></app-bale-grab-availabilities-list>

  <app-loader-availabilities-list fxFlex="50%"></app-loader-availabilities-list>
</div>
