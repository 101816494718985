import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KmlSelectPlacemarkDialogComponent } from './kml-select-placemark-dialog/kml-select-placemark-dialog.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    KmlSelectPlacemarkDialogComponent
  ],
    imports: [
        CommonModule,
        GaiaSharedModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule
    ]
})
export class KmlUtilsModule { }
