<div class="planting-opportunities">

  <gaia-shared-waiting-animation *ngIf=" dataSource && dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growingRoutes.PLANTING_OPPORTUNITIES, element.id]">
          {{ element.name }}
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef i18n>Type</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.type }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expected_planting_year">
      <mat-header-cell *matHeaderCellDef i18n>Expected Planting Year</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.expected_planting_year }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="stage">
      <mat-header-cell *matHeaderCellDef i18n>Stage</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.stage }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_count">
      <mat-header-cell *matHeaderCellDef i18n>Field Count</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.field_count }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords" [pageSize]="30"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
