import {Component, OnInit, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {InternalNoteTrackingService} from '../internal-note-tracking.service';
import {GaiaRecord} from '../../models/gaia-record.model';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InternalNotesPopupComponent} from '../internal-notes-popup/internal-notes-popup.component';
import {MatButton} from '@angular/material/button';
import {InternalNotesService} from '../internal-notes.service';

@Component({
  selector: 'app-internal-notes-tool',
  templateUrl: './internal-notes-tool.component.html',
  styleUrls: ['./internal-notes-tool.component.scss']
})
export class InternalNotesToolComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;
  currentRecord: GaiaRecord;
  canRecordNote = false;
  dialogRef: MatDialogRef<InternalNotesPopupComponent>;
  dialogOpen = false;
  noteCount: number = null;

  @ViewChild('notesButton')
  button: MatButton;

  constructor(
    private internalNoteTracker: InternalNoteTrackingService,
    private internalNotesService: InternalNotesService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.internalNoteTracker.currentRecord.subscribe(
      (record) => {
        this.currentRecord = record;
        this.canRecordNote = record !== null;
        if (this.currentRecord){
          this.internalNotesService.getNoteCount(this.currentRecord).subscribe(
            (count) => {this.noteCount = count; }
          );
        }else{
          this.noteCount = null;
        }
      }
    );
  }

  showNotesPopup() {
    if (!this.dialogOpen) {
      const element = this.button._elementRef.nativeElement;
      this.dialogRef = this.matDialog.open(InternalNotesPopupComponent,
        {
          data: {
            record: this.currentRecord,
            position: element.getBoundingClientRect(),
          },
          width: '300px',
          hasBackdrop: false,
        });
      this.dialogOpen = true;
      this.dialogRef.afterClosed().subscribe(() => this.dialogOpen = false);
    }else{
      this.dialogRef.close();
    }
  }
}
