import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {ContactType} from './contact-type.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ContactTypeFormControlService implements GaiaRecordFormControl<ContactType> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: ContactType): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(record.name, Validators.required),
      active: new FormControl(record.active),
    });
  }
}
