import {Component, Input, OnInit} from '@angular/core';
import {Processor} from '../../processor.model';
import {ScheduleMonthsService} from '../schedule-months.service';
import {ScheduleMonth} from '../schedule-month.model';
import { EChartsOption } from 'echarts';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {
  ScheduleMonthCreateDialogComponent
} from '../schedule-month-create-dialog/schedule-month-create-dialog.component';

@Component({
  selector: 'app-schedule-months-overview',
  templateUrl: './schedule-months-overview.component.html',
  styleUrls: ['./schedule-months-overview.component.scss']
})
export class ScheduleMonthsOverviewComponent implements OnInit {

  @Input()
  processor: Processor;

  availableYears: Array<number> = new Array<number>();
  selectedYear: number;

  months: Array<ScheduleMonth> = new Array<ScheduleMonth>();

  displayedColumns = ['month', 'harvest_year', 'planned_tonnage', 'adjustment_text', 'adjustment_value'];

  chartOptions: EChartsOption;

  constructor(
    private scheduleMonthsService: ScheduleMonthsService,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.loadYears();
  }

  loadYears(): void {
    this.scheduleMonthsService.getAvailableHarvestYears(this.processor).subscribe(
      (years) => {
        this.availableYears = years.years;
      }
    );
  }

  yearChanged($event: any) {
    // 20 to give us a little bit of wiggle room for cross overs
    this.scheduleMonthsService.getRecords({parentId: this.processor.id, pageSize: 20, pageNumber: 0,
      sort: 'month', search: { harvest_year: this.selectedYear }})
      .subscribe(
        (response) => {
          this.months = response.records;
          this.chartOptions = {
            xAxis: {
              type: 'category',
              data: this.months.map((month) => this.datePipe.transform(month.month, 'MMM')),
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                data: this.months.map((month) => month.planned_tonnage),
                type: 'line',
              }
            ]
          };
        }
    );
  }

  createSchedule() {
    const dialogRef = this.matDialog.open(ScheduleMonthCreateDialogComponent, {
      data: {processor: this.processor},
      width: '60%',
      maxHeight: '60vh',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadYears();
    });
  }
}
