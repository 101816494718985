<div class="contracted-fields">
  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Contracted Fields" i18n-pageTitle="Page title for contracted fields page"
                    [hideAdd]="true" i18n-searchHint searchHint="Grower Code/Name">

    <app-contracted-field-stats-dashboard dashboard></app-contracted-field-stats-dashboard>

    <app-miscanthus-variety-select [formControl]="varietyControl" (selectionChange)="doSearch()" [includeBlank]="true"></app-miscanthus-variety-select>

    <mat-form-field>
      <mat-label i18n>Year Planted</mat-label>
      <mat-select [formControl]="formControl" (selectionChange)="doSearch()">
        <mat-option [value]="null">-</mat-option>
        <mat-option [value]="'< 2016'">Pre Terravesta</mat-option>
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <app-search-filter-button #hasWarnings filterText="Has Warnings" i18n-filterText=""
                              [initialState]="hasWarningsInitialState"
                              (stateChanged)="doSearch()"></app-search-filter-button>

    <app-search-filter-button #inContract filterText="In Contract" i18n-filterText=""
                              [initialState]="inContractInitialState"
                              (stateChanged)="doSearch()"></app-search-filter-button>

  </app-index-header>
  <mat-tab-group (selectedTabChange)="redrawMap($event)">

    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n="tab label for the contracted fields table">Table</span>
      </ng-template>

      <app-table-loading-wrapper [dataSource]="dataSource">

        <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header=""> Name</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a mat-button color="accent" [routerLink]="['/', appRoutes.GROWING, growingRoutes.CONTRACTED_FIELDS, element.id]">
              {{element.name}}
            </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="field_reference_number">
          <mat-header-cell *matHeaderCellDef mat-sort-header="" i18n="field reference number table column heading"> Field Reference Number </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.field_reference_number}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="grower_code">
          <mat-header-cell *matHeaderCellDef mat-sort-header="" i18n="grower code table column heading"> Grower Code </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.grower.grower_code}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="variety">
          <mat-header-cell *matHeaderCellDef i18n="variety planted in field column heading">Variety</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.planted_variety ? element.planted_variety.name : "-" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="year_planted">
          <mat-header-cell *matHeaderCellDef mat-sort-header="" i18n="year field was planted column heading">Year Planted</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.year_planted }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="current_harvest_yield">
          <mat-header-cell *matHeaderCellDef mat-sort-header="" i18n="column heading for the current yield">Current Harvest Yield</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.current_harvest_yield | number : '1.0-2' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="badges">
          <mat-header-cell *matHeaderCellDef i18n></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <app-contracted-field-badge-panel [record]="element"></app-contracted-field-badge-panel>
          </mat-cell>
        </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

        </mat-table>
      </app-table-loading-wrapper>

      <mat-paginator [length]="dataSource.totalRecords"
                     [pageSize]="dataSource.pageSize"
                     [pageIndex]="dataSource.pageIndex"
                     [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>Map</span>
      </ng-template>

      <phanes-google-map width="100%" [mapRecordsItems]="mapItems" [preferPolygon]="true"
                         [mapZoom]="6" [enableClustering]="true"></phanes-google-map>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span i18n>By Area</span>
      </ng-template>

      <app-planting-by-area></app-planting-by-area>

    </mat-tab>
  </mat-tab-group>
</div>
