import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockistsTableComponent } from './stockists-table/stockists-table.component';
import {MatTableModule} from '@angular/material/table';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatPaginatorModule} from '@angular/material/paginator';
import {StockistsService} from './stockists.service';
import {RouterModule} from '@angular/router';
import { StockistShowComponent } from './stockist-show/stockist-show.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {PriceGroupsModule} from '../price-groups/price-groups.module';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatDialogModule} from '@angular/material/dialog';
import { StockistFormComponent } from './stockist-form/stockist-form.component';
import { StockistNewDialogComponent } from './stockist-new-dialog/stockist-new-dialog.component';
import { StockistCardComponent } from './stockist-card/stockist-card.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatListModule} from '@angular/material/list';



@NgModule({
    declarations: [StockistsTableComponent, StockistShowComponent, StockistFormComponent,
      StockistNewDialogComponent, StockistCardComponent],
    imports: [
      CommonModule,
      MatTableModule,
      GaiaSharedModule,
      MatPaginatorModule,
      RouterModule,
      ReactiveFormsModule,
      MatCardModule,
      MatIconModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatButtonModule,
      PriceGroupsModule,
      AddressesModule,
      GaiaGeneralModule,
      MatSlideToggleModule,
      FlexLayoutModule,
      MatListModule
    ],
    exports: [StockistsTableComponent, StockistShowComponent, StockistCardComponent, StockistFormComponent],
    providers: [StockistsService]
})
export class StockistsModule { }
