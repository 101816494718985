<ng-container *ngIf="record">

  <app-show-page-header [recordIcon]="gaiaIconSet.contracted_fields" recordType="Contracted Field" i18n-recordType
                        [displayName]="record.name">
    <div header-content>
      <div fxLayout="row" fxLayoutAlign="start end">
        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="start end">
          <button mat-raised-button style="margin-right: 10px" color="primary" i18n type="button"
             [routerLink]="['/', appRoutes.GROWING, growingRoutes.GROWERS, record.grower.id]">Back To Grower</button>
          <app-contracted-field-warnings [contractedField]="record" *ngIf="record.has_warnings"></app-contracted-field-warnings>
        </div>
        <div fxLayout="row" fxFlex="50%" fxLayoutAlign="end start">
          <app-contracted-field-badge-panel [record]="record"></app-contracted-field-badge-panel>
        </div>
      </div>
    </div>
    <div header-menu>
      <mat-form-field style="margin-left: 10px; margin-right: 10px" [formGroup]="fieldList">
        <mat-label for="fields">
          Switch Fields
        </mat-label>
        <mat-select formControlName="field_name"  (selectionChange)="redirectToNewField($event)">
          <mat-option *ngFor="let field of this.dropdownFieldList" [value]="field.id">
            {{ field.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Contracted Field Settings menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item (click)="openRemoveDialog()">
          <mat-icon>{{gaiaIconSet.remove_crop}}</mat-icon>
          <span i18n="Option to remove crop">Remove From Contract</span>
        </a>
      </mat-menu>
    </div>

  </app-show-page-header>
  <mat-tab-group (selectedTabChange)="updateMap($event)">
    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Details</label>
      </ng-template>
      <app-show-record-wrapper [form]="form">
        <ng-container menu>
        </ng-container>
        <app-contracted-field-form [form]="form" [record]="record"></app-contracted-field-form>
      </app-show-record-wrapper>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Map</label>
      </ng-template>
      <ng-template matTabContent>
        <app-contracted-field-map-panel [parent]="field" [agriculturalField]="record" (fieldMapChanged)="reloadField()"></app-contracted-field-map-panel>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Contracts</label>
      </ng-template>
      <ng-template matTabContent>
        <app-contracted-field-contract-panel (detailsChanged)="reloadField()" [contractedField]="record"></app-contracted-field-contract-panel>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Drone images</label>
      </ng-template>
      <ng-template matTabContent>
        <app-drone-image-field-embed [field]="record"></app-drone-image-field-embed>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Soil Data</label>
      </ng-template>
      <ng-template matTabContent>
        <div class="soil-container">
          <app-soil-samples-embed [contractedField]="record"></app-soil-samples-embed>
          <app-soil-measurements-embed [contractedField]="record"></app-soil-measurements-embed>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Harvest Series</label>
      </ng-template>
      <ng-template matTabContent>
        <app-contracted-field-harvest-graph [contractedField]="record"></app-contracted-field-harvest-graph>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label i18n>Meteorological Data</label>
      </ng-template>
      <ng-template matTabContent>
        <app-climate-grid-viewer *ngIf="record.latitude !== null else noClimate"
                                 [contractedField]="record" [selectedYear]="record.year_planted"></app-climate-grid-viewer>
        <ng-template #noClimate>
          <span i18n>We are sorry, as there is no GPS information for the field, we cannot provide climate data</span>
        </ng-template>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="record.geo_data.points.length === 0">
      <ng-template mat-tab-label>
        <label i18n matTooltip="No Sentinel Viewer for fields without boundaries" [matTooltipDisabled]="record.geo_data.points.length !== 0">Sentinel Viewer</label>
      </ng-template>
      <ng-template matTabContent>
        <app-contracted-field-sentinel-viewer *ngIf="record.geo_data.points.length > 0" [contractedField]="record"></app-contracted-field-sentinel-viewer>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</ng-container>
