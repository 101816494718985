import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {BaleType} from './bale-type.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  SpecificationCheckFormControlService
} from '../../concerns/specification-check/specification-check-form-control.service';

@Injectable({
  providedIn: 'root'
})
export class BaleTypeFormControlService implements GaiaRecordFormControl<BaleType> {

  constructor(
    private formBuilder: FormBuilder,
    private specificationFormControl: SpecificationCheckFormControlService,
  ) { }

  toFormGroup(record: BaleType): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(record.name, Validators.required),
      active: new FormControl(record.active),
      avg_num_per_load: new FormControl(record.avg_num_per_load),
      default_weight: new FormControl(record.default_weight),
      weight_specifications: this.specificationFormControl.toFormGroup(record.weight_specifications)
    });
  }

  selectBaleTypeForm(): FormGroup {
    return this.formBuilder.group({
      bale_type_id: new FormControl(null, Validators.required),
    });
  }
}
