
<mat-card style="height: 100%" fxLayout="column" cdkDrag [cdkDragData]="this.dashboard.getGridConfig()"
          [cdkDragDisabled]="!editMode" [cdkDragConstrainPosition]="dragConstraints"
          (cdkDragEnded)="dragEnd($event)">
  <mat-card-header>
    <mat-card-title>{{ componentTitle }}</mat-card-title>
    <div *ngIf="editMode" fxFlex="1 1 auto" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
      <mat-icon>{{gaiaIconSet.drag}}</mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="widgetMenu" aria-label="Widget Options Menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #widgetMenu="matMenu">
        <ng-content select="widgetMenu">
        </ng-content>
        <button mat-menu-item (click)="deleteClicked.emit()" aria-label="Delete widget">
          <mat-icon>{{ gaiaIconSet.delete }}</mat-icon>
          <span i18n="delete widget label for menu button">Delete</span>
        </button>
      </mat-menu>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxFlex="1 1 auto">
    <ng-content>

    </ng-content>
  </mat-card-content>
</mat-card>
