import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {StaffAbsence, StaffAbsenceTypes} from './staff-absence.model';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {processAPIDateTime} from '../../../utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class StaffAbsenceFormControlService implements GaiaRecordFormControl<StaffAbsence> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  public static ReasonValidator: ValidatorFn = (form)  => {
    if (form.get('absence_type').value === StaffAbsenceTypes.absence) {
      if (form.get('reason').value === null || form.get('reason').value === '') {
        form.get('reason').setErrors({reason_not_given: true});
        return {reason_not_given: true};
      }
    }
  }


  toFormGroup(record: StaffAbsence): FormGroup {
    return this.formBuilder.group({
      absence_type: new FormControl(record.absence_type_enum, Validators.required),
      start_date_time: new FormControl(
        // eslint-disable-next-line max-len
        record.absence_type_enum === StaffAbsenceTypes.absence ? processAPIDateTime(record.start_date_time) : record.start_date_time, Validators.required),
      end_date_time: new FormControl(
        // eslint-disable-next-line max-len
        record.absence_type_enum === StaffAbsenceTypes.absence ? processAPIDateTime(record.end_date_time) : record.end_date_time, Validators.required),
      reason: new FormControl(record.reason),
      return_in_afternoon: new FormControl(record.return_in_afternoon),
      start_in_afternoon: new FormControl(record.start_in_afternoon),
    }, { validators: [StaffAbsenceFormControlService.ReasonValidator]});
  }


}
