import {Component, OnInit, Output, Input, forwardRef, Injector} from '@angular/core';
import {ContactsService} from '../contacts.service';
import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Contact} from '../contact.model';
import {MatSelectChange} from '@angular/material/select';
import {ContactFormControlService} from '../contact-form-control.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {GaiaRecord} from '../../models/gaia-record.model';
import {GaiaLogger} from '../../../utils/common-functions';
import {ControlValueAccessorConnectorDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-account-contact-select',
  templateUrl: './account-contact-select.component.html',
  styleUrls: ['./account-contact-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountContactSelectComponent),
      multi: true
    }
  ]
})
export class AccountContactSelectComponent extends ControlValueAccessorConnectorDirective implements OnInit {

  contacts: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);

  @Input()
  newButtonText = 'New Contact';

  @Input()
  label = 'Select Existing Contact';

  @Input()
  account: GaiaRecord;

  @Output()
  contactChanged = new BehaviorSubject<boolean>(false);

  selectedContact: Contact;
  contactForm: FormGroup;
  formChanges: Subscription;

  constructor(
    private contactsService: ContactsService,
    private contactFormControlService: ContactFormControlService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadContacts();
  }

  loadContacts(){
    GaiaLogger.log(this.account);
    this.contactsService.forAccount(this.account, {
      pageSize: 100,
      pageNumber: 0,
    }).subscribe((response) => {
      this.contacts.next(response.records.map<Contact>((item) => item.contact));
      if (this.control.value) {
        this.contacts.getValue().forEach((contact) => {
          if (contact.id === this.control.value) {
            this.selectedContact = contact;
            this.setupForm();
          }
        });
      }
    });

  }

  newContact() {
    this.selectedContact = {} as Contact;
    this.setupForm();
  }

  selected(event: MatSelectChange) {
    this.contacts.getValue().forEach((contact) => {
      if (contact.id === this.control.value) {
        this.selectedContact = contact;
        this.setupForm();
      }
    });
  }

  setupForm(){
    if (this.formChanges) {
      this.formChanges.unsubscribe();
    }
    this.contactForm = this.contactFormControlService.toFormGroup(this.selectedContact);
    this.formChanges = this.contactForm.valueChanges.subscribe(
      (changes) => {
        const currentStatusCheck = this.contactForm.dirty && this.contactForm.valid;
        this.contactChanged.next(currentStatusCheck);
      }
    );
  }

  clear(){
    this.control.setValue(null);
  }

  updateContact() {
    let service: Observable<Contact>;
    if (this.selectedContact.id) {
      service = this.contactsService.updateRecord({recordId: this.selectedContact.id, data: this.contactForm.value,
        additionalParams: { account_id: this.account.id }});
    }else{
      service = this.contactsService.createRecord({data: this.contactForm.value,
        additionalParams: { account_id: this.account.id }});
    }
    service.subscribe((response) => {
      GaiaLogger.log(response);
      this.loadContacts();
      this.selectedContact = response;
    });
  }
}
