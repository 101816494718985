import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiscanthusFieldHarvestEmbedTableComponent } from './miscanthus-field-harvest-embed-table/miscanthus-field-harvest-embed-table.component';
import {MatTableModule} from '@angular/material/table';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import { MiscanthusFieldHarvestDialogComponent } from './miscanthus-field-harvest-dialog/miscanthus-field-harvest-dialog.component';
import { MiscanthusFieldHarvestFormComponent } from './miscanthus-field-harvest-form/miscanthus-field-harvest-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {BaleTypesModule} from '../../bale-types/bale-types.module';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
    declarations: [MiscanthusFieldHarvestEmbedTableComponent, MiscanthusFieldHarvestDialogComponent,
        MiscanthusFieldHarvestFormComponent],
    exports: [
        MiscanthusFieldHarvestEmbedTableComponent
    ],
  imports: [
    CommonModule,
    MatTableModule,
    ExtendedModule,
    GaiaSharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BaleTypesModule,
    GaiaGeneralModule,
    FlexModule,
    PhanesComponentsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ]
})
export class MiscanthusFieldHarvestsModule { }
