import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {BaleGrabAvailability} from './bale-grab-availability.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaleGrabAvailabilitiesService extends RestApiService<BaleGrabAvailability> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/bale_grab_availabilities', 'bale_grab_availability', httpClient);
  }
}
