
<mat-expansion-panel [expanded]="defaultOpenState">

  <mat-expansion-panel-header>
    {{ dashboardTitle }}
  </mat-expansion-panel-header>

  <ng-content></ng-content>

</mat-expansion-panel>
