import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GrowersService} from '../growers/growers.service';
import {GrowerFinderComponent} from './grower-finder/grower-finder.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {GrowersNearbyComponent} from './growers-nearby/growers-nearby.component';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {FlexModule} from '@angular/flex-layout';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import { GrowerFinderFormEmbedComponent } from './grower-finder-form-embed/grower-finder-form-embed.component';

/*
  Module was needed to prevent cyclic dependencies between growing and operations
  Pulls in the service from the other module, but doesnt cause issues doing it this way
 */

@NgModule({
  declarations: [GrowerFinderComponent, GrowersNearbyComponent, GrowerFinderFormEmbedComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    FlexModule,
    PhanesComponentsModule,
    GaiaGeneralModule
  ],
  providers: [GrowersService],
    exports: [
        GrowerFinderComponent,
        GrowersNearbyComponent,
        GrowerFinderFormEmbedComponent
    ]
})
export class GrowerSharedModule { }
