<mat-form-field>
  <mat-label *ngIf="label else defaultLabel" i18n>{{ label }}</mat-label>
  <ng-template #defaultLabel i18n>Contact Type</ng-template>
  <mat-select
    [formControl]="control"
    (selectionChange)="selectionChange.emit($event)"
    ngDefaultControl>
    <mat-option value=""></mat-option>
    <mat-option *ngFor="let contactType of records" [value]="contactType.name"> {{ contactType.name }}</mat-option>
  </mat-select>
</mat-form-field>
