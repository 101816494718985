import { Injectable } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Order, OrderItem, OrderRefund} from './order.model';
import {processAPIDateTime} from '../../../utils/common-functions';
import moment from 'moment';
import {AddressFormControlService} from '@terravesta/gaia-shared';

@Injectable({
  providedIn: 'root'
})
export class OrderFormControlService {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService,
  ) { }

  toFormGroup(order: Order): FormGroup {
    const orderItemsArray = new FormArray([], Validators.required);
    if (order.order_items){
      order.order_items.forEach((orderItem) => {
        const itemConfig = this.toOrderItemFormGroup(orderItem);
        orderItemsArray.push(itemConfig);
      });
    }
    const refundsArray = new FormArray([]);
    if (order.refunds){
      order.refunds.forEach((refund) => {
        const refundConfig = this.toOrderRefundFormGroup(refund);
        refundsArray.push(refundConfig);
      });
    }
    const completed = {
      ordered_date: new FormControl(order.ordered_date ? order.ordered_date : moment().format('YYYY-MM-DD'), Validators.required),
      paid_on: new FormControl(processAPIDateTime(order.paid_on)),
      paid: new FormControl(order.paid ? order.paid : false),
      invoice_last_sent: new FormControl({ value: processAPIDateTime(order.invoice_last_sent),  disabled: true }),
      invoice_raised: new FormControl(order.invoice_raised),
      invoice_payment_due: new FormControl(order.invoice_payment_due),
      shipped_on: new FormControl(order.shipped_on),
      delivery_cost: new FormControl(order.delivery_cost ? order.delivery_cost : null),
      billing_address: this.addressFormControl.toFormControl(order.billing_address),
      shipping_address: this.addressFormControl.toFormControl(order.shipping_address),
      payment_method: new FormControl(order.payment_method),
      invoice_send_issue: new FormControl({ value: order.invoice_send_issue, disabled: true}),
      order_items: orderItemsArray,
      customer_id: new FormControl(order.customer ? order.customer.record.id : null, Validators.required),
      refunds: refundsArray,
    };
    const formGroup = this.formBuilder.group(completed);
    if (formGroup.get('invoice_raised').value){
      formGroup.get('order_items').disable();
      formGroup.get('delivery_cost').disable();
      formGroup.get('invoice_raised').disable();
      formGroup.get('billing_address').disable();
    }
    if (formGroup.get('paid').value && formGroup.get('shipped_on').value){
      formGroup.disable();
    } else if (formGroup.get('paid').value || formGroup.get('shipped_on').value) {
      formGroup.get('order_items').disable();
      formGroup.get('billing_address').disable();
      formGroup.get('delivery_cost').disable();
    }
    if (formGroup.get('shipped_on').value){
      formGroup.get('shipping_address').disable();
    }
    return formGroup;
  }

  toOrderItemFormGroup(orderItem: OrderItem): FormGroup{
    return this.formBuilder.group({
      id: new FormControl(orderItem.id),
      product_id: new FormControl(orderItem.product ? orderItem.product.id : undefined, Validators.required),
      product_name: new FormControl(orderItem.product ? orderItem.product.name : undefined),
      quantity: new FormControl(orderItem.quantity),
      discount: new FormControl(orderItem.discount),
      sales_price: new FormControl(orderItem.sales_price),
      vat_rate: new FormControl(orderItem.vat_rate),
      ex_vat_sales_price: new FormControl(orderItem.ex_vat_sales_price),
      list_price: new FormControl(orderItem.list_price),
      to_delete: new FormControl(false),
    });
  }

  toOrderRefundFormGroup(refund: OrderRefund): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(refund.id),
      reason: new FormControl(refund.reason),
      refunded_on: new FormControl(processAPIDateTime(refund.refunded_on)),
      amount_refunded: new FormControl(refund.amount_refunded),
    });
  }

}
