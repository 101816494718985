import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffAbsenceTableComponent } from './staff-absence-table/staff-absence-table.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { StaffAbsenceFormDialogComponent } from './staff-absence-form-dialog/staff-absence-form-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FlexModule} from '@angular/flex-layout';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {TeamSpaceConcernsModule} from '../team-space-concerns/team-space-concerns.module';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { StaffAbsenceApprovalTableComponent } from './staff-absence-approval-table/staff-absence-approval-table.component';


@NgModule({
    declarations: [StaffAbsenceTableComponent, StaffAbsenceFormDialogComponent, StaffAbsenceApprovalTableComponent],
    exports: [
        StaffAbsenceTableComponent,
        StaffAbsenceApprovalTableComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatPaginatorModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        FlexModule,
        PhanesComponentsModule,
        TeamSpaceConcernsModule,
        MatButtonModule,
        MatMenuModule
    ]
})
export class StaffAbsencesModule { }
