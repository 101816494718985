<div *ngIf="visible" >
  <mat-expansion-panel *ngIf="processorData" id="infoWindow" [expanded]="panelOpenState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Name</b>
        <p class="card-title">{{processorData.account_name}}
        </p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <b>Address</b>
    <p class="card-title">{{processorData.billing_address.address_line_1 +', '+ processorData.billing_address.city +', '+ processorData.billing_address.post_code}}
    </p>
    <b>Active</b>
    <p class="card-title">{{processorData.active}}
    </p>
    <mat-card-actions>
      <button id="infoClose" mat-raised-button color="primary" (click)="CloseInfo()">Close</button>
    </mat-card-actions>
  </mat-expansion-panel>
</div>

