import {Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/phanes';
import {GaiaIconSet} from '../../models/gaia-icon-set';

@Component({
  selector: 'app-show-record-menu',
  templateUrl: './show-record-menu.component.html',
  styleUrls: ['./show-record-menu.component.scss']
})
export class ShowRecordMenuComponent implements OnInit, OnChanges {

  gaiaIconSet = GaiaIconSet;

  @Input()
  form: FormGroup;

  @Input()
  allowDelete = false;

  @Output()
  saveClicked = new EventEmitter();

  @Output()
  deleteClickedAndConfirmed = new EventEmitter();

  canSave = false;
  formChanges: Subscription;

  constructor(
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form){
      if (changes.form.currentValue !== changes.form.previousValue){
        // Form has been reset, so reset the canSave variable to false
        this.canSave = false;
        if (this.formChanges) {
          this.formChanges.unsubscribe();
        }
        this.formChanges = this.form.statusChanges.subscribe(() => {
          this.canSave = this.form.dirty && this.form.valid;
        });
      }
    }
  }

  saveBtClicked() {
    this.saveClicked.emit();
  }

  deleteBtnClicked() {
    const ref = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
      data: {
        message: 'You are about to delete this record, are you sure',
        title: 'Delete Record?'
      }
    });
    ref.afterClosed().subscribe((response) => {
      if (response) {
        this.deleteClickedAndConfirmed.emit();
      }
    });
  }
}
