import { Injectable } from '@angular/core';
import {RestApiService} from '../services/rest-api.service';
import {InternalNote} from './internal-note.model';
import {HttpClient} from '@angular/common/http';
import {GaiaRecord} from '../models/gaia-record.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternalNotesService extends RestApiService<InternalNote> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/internal_notes', 'internal_note', httpClient, InternalNotesService.processDates);
  }

  static processDates(note: InternalNote){
    // note.date_noted = processAPIDateTime(note.date_noted);
  }

  getNoteCount(record: GaiaRecord): Observable<number> {
    return this.httpGet<{count: number}>(this.generateNonRecordUrl('/note_count'), { record_id: record.id}).pipe(map(
      (response) => {
        return response.count;
      }
    ));
  }

}
