<mat-card>
  <mat-card-header>
    <mat-card-title i18n>Loader Availability</mat-card-title>
  </mat-card-header>

  <mat-card-content>

    <button mat-button color="primary" (click)="newLoader()" i18n>New Loader</button>

    <mat-table [dataSource]="loaders">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button (click)="editLoader(element)"> {{ element.name }}</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef i18n>Active</mat-header-cell>
        <mat-cell *matCellDef="let element" align="center">
          <mat-icon *ngIf="element.active">{{ gaiaIcons.active }}</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
    <mat-paginator [length]="loaders.totalRecords" [pageSize]="10"></mat-paginator>

  </mat-card-content>
</mat-card>
