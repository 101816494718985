import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {CompanyOffice} from './company-office.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyOfficesService extends RestApiService<CompanyOffice> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('company_offices', 'company_office', httpClient);
  }
}
