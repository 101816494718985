<div *ngIf="!selectedContact else contactFormSection">
  <p i18n="instruction to search or add for contact">Please search for an existing contact, or click add new</p>

  <div class="search-view">
    <div>
      <app-contact-search (contactSelected)="existingContactSelected($event)" fxFlex="100%">
      </app-contact-search>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="newContact()" i18n="button to add new contact">New Contact</button>
    </div>
  </div>

</div>

<ng-template #contactFormSection>
  <div class="input-view">
    <div class="input-view-menu">
      <button mat-raised-button color="accent" (click)="resetContact()" i18n="button to reset a contact">Reset Contact</button>
    </div>
    <app-contact-embed-form-simple [formGroup]="control"></app-contact-embed-form-simple>
  </div>
</ng-template>
