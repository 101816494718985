import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GaiaLogger} from '../../../../utils/common-functions';
import {Grower, GrowerHarvestSeries} from '../grower.model';
import {GrowersService} from '../growers.service';
import {EChartsOption} from 'echarts';

@Component({
  selector: 'app-grower-harvest-series-graph',
  templateUrl: './grower-harvest-series-graph.component.html',
  styleUrls: ['./grower-harvest-series-graph.component.scss']
})
export class GrowerHarvestSeriesGraphComponent implements OnChanges {

  harvestChart: EChartsOption;
  harvestSeries: GrowerHarvestSeries;

  @Input()
  grower: Grower;

  constructor(
    private growersService: GrowersService,
  ) { }

  ngOnChanges(change: SimpleChanges): void {
    if (change.grower) {
      if (change.grower.currentValue !== change.grower.previousValue) {
        this.growersService.harvestSeries(this.grower).subscribe(
          (response) => {
            this.harvestSeries = response;
            GaiaLogger.log(this.harvestSeries);
            this.createHarvestGraph();
          }
        );
      }
    }
  }

  createHarvestGraph() {
    const colours = ['#f88200', '#526e26', '#3636c4'];
    this.harvestChart = {
      color: colours,
      title: {
        text: 'Harvest Series'
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: this.harvestSeries.series.map((value) => value.year),
      },
      yAxis: [
        {
          type: 'value',
          name: 'Bale Count',
          axisLine: {
            show: true,
            lineStyle: {
              color: colours[0],
            }
          },
        },
        {
          type: 'value',
          name: 'Yield',
          position: 'right',
          axisLine: {
            show: true,
            lineStyle: {
              color: colours[1],
            }
          },
          axisLabel: {
            formatter: '{value} t'
          }
        },
      ],
      legend: {
        data: ['Bale Count', 'Estimated Yield', 'Accepted Tonnage'],
      },
      series: [
        {
          data: this.harvestSeries.series.map((value) => value.bales),
          name: 'Bale Count',
          type: 'line',
          yAxisIndex: 0,
          color: colours[0],
        },
        {
          data: this.harvestSeries.series.map((value) => value.yield),
          name: 'Estimated Yield',
          type: 'line',
          yAxisIndex: 1,
          color: colours[1],
        },
        {
          data: this.harvestSeries.series.map((value) => value.accepted_tonnage),
          name: 'Accepted Tonnage',
          type: 'line',
          yAxisIndex: 1,
          color: colours[2],
        },
      ]
    };
  }
}
