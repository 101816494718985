import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniversalSearchPreviewWrapperComponent } from './universal-search-preview-wrapper/universal-search-preview-wrapper.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
  declarations: [UniversalSearchPreviewWrapperComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule
    ],
  exports: [UniversalSearchPreviewWrapperComponent]
})
export class UniversalSearchLayoutModule { }
