import {Component, Input, OnInit} from '@angular/core';
import {ProductsService} from '../products.service';
import {Observable} from 'rxjs';
import {Product, ProductPriceBook} from '../product.model';

@Component({
  selector: 'app-price-book',
  templateUrl: './price-book.component.html',
  styleUrls: ['./price-book.component.scss']
})
export class PriceBookComponent implements OnInit {

  @Input()
  product: Product;
  displayedColumns = ['min_qty', 'vat_rate', 'ex_vat_price', 'vat_price'];

  priceList$: Observable<ProductPriceBook[]>;

  constructor(
    private productsService: ProductsService
  ) { }

  ngOnInit(): void {
    this.priceList$ = this.productsService.getPriceList({recordId: this.product.id});
  }

}
