import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {CommercialUser} from './commercial-user.model';

@Injectable({
  providedIn: 'root'
})
export class CommercialUsersService extends RestApiService<CommercialUser> {

  constructor(
    httpClient: HttpClient
  ) {
    super('/commercial_users', 'commercial_user', httpClient);
  }
}
