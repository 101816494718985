<app-dashboard-widget-wrapper componentTitle="Planting Summary" (deleteClicked)="deleteWidget()" [editMode]="editMode"
                              (widgetMoved)="widgetMoved($event)" [dashboard]="dashboard">
  <div class="container">
    <app-dashboard-widget-chart [chartSettings]="varietyPlanted"></app-dashboard-widget-chart>

    <div class="stats" *ngIf="opsStats">
      <h2 i18n>Opportunity Stats</h2>

      <div class="stat-block">
        <app-stats-number-line label="Closed Won" i18n-label="label on the stat showing number of opportunities closed won"
                                [displayNumber]="opsStats.closed_won_count"></app-stats-number-line>
        <app-stats-number-line label="Without Contracted Fields" i18n-label="label on the stat showing number of opportunities closed won that do not have contracted fields"
                               [displayNumber]="opsStats.ops_missing_contracted_fields"></app-stats-number-line>
      </div>
      <h2 i18n>Field Stats</h2>

      <div class="stat-block">
        <app-stats-number-line label="Mapped" i18n-label="number of fields that have complete mapping"
                               [displayNumber]="statsByMapStatus(mapStatuses.polygon)"></app-stats-number-line>
        <app-stats-number-line label="Pin Only" i18n-label="number of fields that have pin only mapping"
                               [displayNumber]="statsByMapStatus(mapStatuses.pinOnly)"></app-stats-number-line>
        <app-stats-number-line label="Unmapped" i18n-label="number of fields that have no mapping"
                               [displayNumber]="statsByMapStatus(mapStatuses.unmmapped)"></app-stats-number-line>
      </div>
    </div>

  </div>
  <app-year-selector [currentYear]="currentYear" (yearChanged)="updateGraph($event)"></app-year-selector>
</app-dashboard-widget-wrapper>
