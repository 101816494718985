import {Component, Inject} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MiscanthusStack} from '../../miscanthus-stack.model';
import {BaleLoss} from '../bale-loss.model';
import {BaleLossesService} from '../bale-losses.service';
import {BaleLossFormControlService} from '../bale-loss-form-control.service';

@Component({
  selector: 'app-bale-loss-dialog',
  templateUrl: './bale-loss-dialog.component.html',
  styleUrls: ['./bale-loss-dialog.component.scss']
})
export class BaleLossDialogComponent extends FormDialogDirective<BaleLoss> {

  constructor(
    baleLossesService: BaleLossesService,
    baleLossFormControl: BaleLossFormControlService,
    matDialog: MatDialogRef<BaleLossDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<BaleLoss, MiscanthusStack>,
  ) {
    super(baleLossesService, baleLossFormControl, matDialog, data);
  }

}
