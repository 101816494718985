<mat-card>

  <mat-card-header>
    <mat-icon mat-card-avatar>contact_page</mat-icon>
    <mat-card-title i18n>Contact</mat-card-title>
  </mat-card-header>

  <mat-card-content *ngIf="record" fxLayout="row">
    <mat-list>
      <mat-list-item>
        <mat-icon matListIcon>person</mat-icon>
        {{record.name}}
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>email</mat-icon>
        {{record.email}}
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>phone</mat-icon>
        {{record.phone}}
      </mat-list-item>
    </mat-list>
    <div >
      <h2 i18n="Address Heading">
        Address
        <button *ngIf="canCopy" mat-icon-button color="primary" type="button" (click)="copyAddress.emit(record.address)">
          <mat-icon>content_copy</mat-icon>
        </button>
      </h2>
      <gaia-shared-address-display [address]="record.address"></gaia-shared-address-display>
    </div>

  </mat-card-content>

  <mat-card-actions align="start">
    <button mat-icon-button color="primary" type="button" aria-label="" *ngIf="canCancel" (click)="cancelClicked()">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-card-actions>

</mat-card>
