import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatIconModule} from '@angular/material/icon';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DocumentUploaderComponent} from "./document-uploader/document-uploader.component";
import {DocumentShowComponent} from "./document-show/document-show.component";
import { DocumentsIndexComponent } from './documents-index/documents-index.component';
import { DocumentUploaderIconButtonComponent } from './document-uploader-icon-button/document-uploader-icon-button.component';
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    DocumentUploaderComponent,
    DocumentShowComponent,
    DocumentsIndexComponent,
    DocumentUploaderIconButtonComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    GaiaSharedModule,
    FlexModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    GaiaGeneralModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
  ],
  exports: [
    DocumentUploaderComponent,
    DocumentUploaderIconButtonComponent
  ]
})
export class DocumentsModule { }
