<ng-container *ngIf="form">
  <div [formGroup]="form" fxLayout="row wrap" fxLayoutGap="15px">
    <mat-card fxFlex="calc(50% - 15px)">
      <mat-card-header>
        <mat-icon mat-card-avatar>contact_page</mat-icon>
        <mat-card-title i18n="contact details header">Details</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="row wrap">
        <app-contact-email-input formControlName="email"></app-contact-email-input>
        <gaia-shared-has-name-embed [formGroup]="form"></gaia-shared-has-name-embed>

        <mat-form-field>
          <mat-label i18n>Phone</mat-label>
          <input matInput type="tel" formControlName="phone" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Mobile</mat-label>
          <input matInput type="tel" formControlName="mobile" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Job title</mat-label>
          <input matInput formControlName="job_title" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>

</ng-container>
