<ng-container *ngIf="form" [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label i18n>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Public Name</mat-label>
        <input matInput formControlName="public_name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>SKU</mat-label>
        <input matInput formControlName="sku" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Code</mat-label>
        <input matInput formControlName="code" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Shopify ID</mat-label>
        <input matInput formControlName="shopify_id" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Family</mat-label>
        <input matInput formControlName="family" />
      </mat-form-field>

    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>Pricing</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label i18n>Cost Price</mat-label>
        <input matInput formControlName="cost_price" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>RRP</mat-label>
        <input matInput formControlName="rrp" type="number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Vat Rate(%)</mat-label>
        <input matInput formControlName="vat_rate" type="number" />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-container>
