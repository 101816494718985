import {Component} from '@angular/core';
import {GrowerContract} from '../grower-contract.model';
import {RecordFormDirective} from '../../../components/record-form.directive';

@Component({
  selector: 'app-grower-contract-form',
  templateUrl: './grower-contract-form.component.html',
  styleUrls: ['./grower-contract-form.component.scss']
})
export class GrowerContractFormComponent extends RecordFormDirective<GrowerContract> {
}
