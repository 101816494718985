import {RequiresApprovalService} from '../requires-approval/requires-approval.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StaffClaimSummary} from './staff-claim.model';
import {GaiaRecord} from '../../../models/gaia-record.model';

export abstract class StaffClaimsService<T extends GaiaRecord> extends RequiresApprovalService<T> {

  protected constructor(
    restEndPoint: string,
    paramName: string,
    http: HttpClient,
    processorFunction?: (record: T) => void
  ) {
    super(restEndPoint, paramName, http, processorFunction);
  }

  getSummary(month: number, year: number): Observable<Array<StaffClaimSummary>> {
    return this.httpGet<Array<StaffClaimSummary>>(this.generateNonRecordUrl('month_summary'), { month, year });
  }
}
