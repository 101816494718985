import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {InternalNote} from '../internal-note.model';
import {MatPaginator} from '@angular/material/paginator';
import {InternalNotesService} from '../internal-notes.service';
import {InternalNoteFormDialogComponent} from '../internal-note-form-dialog/internal-note-form-dialog.component';
import {InternalNoteTrackingService} from '../internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';
import {GaiaRecord} from '../../models/gaia-record.model';
import {GaiaLogger} from '../../../utils/common-functions';
import {PaginatedDataSource} from '@terravesta/phanes';

@Component({
  selector: 'app-internal-notes-column',
  templateUrl: './internal-notes-column.component.html',
  styleUrls: ['./internal-notes-column.component.scss']
})
export class InternalNotesColumnComponent implements OnInit, AfterViewInit {

  notes: PaginatedDataSource<InternalNote>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  currentRecord: GaiaRecord;
  totalCount: number;

  constructor(
    private matDialog: MatDialog,
    private internalNoteTracker: InternalNoteTrackingService,
    private notesService: InternalNotesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.internalNoteTracker.currentRecord.subscribe((record) => {
      this.currentRecord = record;
      GaiaLogger.log('record changed', this.currentRecord);
      if (this.currentRecord !== null) {
        this.updateDataSource();
      }
    });
  }

  ngAfterViewInit() {
    if (this.notes) {
      this.notes.paginator = this.paginator;
    }
  }

  updateDataSource() {
    this.notes = new PaginatedDataSource<InternalNote>(
      (params) => {
        params.additionalParams = {
          record_id: this.currentRecord.id
        };
        return this.notesService.getRecords(params);
        // TODO the follow sort here is nasty, maybe could be done at the sever end as default?
      }, 5, 0, { active: 'pinned DESC, date_noted', direction: 'desc'});
    this.reload();
    this.notesService.getNoteCount(this.currentRecord).subscribe(
      (count) => {this.totalCount = count; }
    );
    this.changeDetectorRef.detectChanges();
    this.notes.paginator = this.paginator;
  }

  createNew() {
    const dialogRef = this.matDialog.open(InternalNoteFormDialogComponent, {
      data: { record: {} as InternalNote, relatedRecord: this.internalNoteTracker.currentRecord.getValue()},
    });
    dialogRef.afterClosed().subscribe(() => this.reload());
  }

  reload() {
    this.notes.loadRecords();
  }


}
