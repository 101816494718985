import {Component, Inject} from '@angular/core';
import {ProcessorDeliverySite} from '../processor-delivery-site.model';
import {ProcessorDeliverySitesService} from '../processor-delivery-sites.service';
import {ProcessorDeliverySiteFormControlService} from '../processor-delivery-site-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';

@Component({
  selector: 'app-processor-delivery-site-form-dialog',
  templateUrl: './processor-delivery-site-form-dialog.component.html',
  styleUrls: ['./processor-delivery-site-form-dialog.component.scss']
})
export class ProcessorDeliverySiteFormDialogComponent extends FormDialogDirective<ProcessorDeliverySite> {

  constructor(
    processorDeliverySiteService: ProcessorDeliverySitesService,
    processorDeliverySiteFormControl: ProcessorDeliverySiteFormControlService,
    matDialog: MatDialogRef<ProcessorDeliverySiteFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<ProcessorDeliverySite>,
  ) {
    super(processorDeliverySiteService, processorDeliverySiteFormControl, matDialog, data);
  }

  successfulSave(result: ProcessorDeliverySite) {
    this.matDialog.close();
  }

}
