import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {yearArray} from '../../../utils/common-functions';

@Component({
  selector: 'app-year-selector',
  templateUrl: './year-selector.component.html',
  styleUrls: ['./year-selector.component.scss']
})
export class YearSelectorComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  currentYear = new Date().getFullYear();

  @Input()
  startYear = 2015;

  @Input()
  endYear = undefined;

  @Input()
  labelText = 'Year';

  @Output()
  yearChanged: EventEmitter<number> = new EventEmitter<number>();

  years: Array<number>;
  yearSelection: FormControl = new FormControl();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.years = yearArray(this.startYear, this.endYear);
  }

  ngAfterViewInit() {
    this.selectionMade();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentYear) {
      if (changes.currentYear.currentValue !== changes.currentYear.previousValue) {
        this.yearSelection.setValue(this.currentYear);
      }
    }
    if ((changes.startYear && changes.startYear.currentValue) || (changes.endYear && changes.endYear.currentValue)) {
      this.years = yearArray(this.startYear, this.endYear);
      this.changeDetectorRef.detectChanges();
    }
  }

  selectionMade() {
    this.yearChanged.emit(this.yearSelection.value);
  }
}
