<gaia-shared-standard-dialog dialogTitle="Add Field to Establishment Contract" i18n-dialogTitle="title for dialog where the user assigns field to a establishment contract">
  <div content>
    <div [formGroup]="formGroup" fxLayout="column">

      <app-establishment-contract-select formControlName="establishment_contract_id"
                                         [grower]="contractedField.grower"></app-establishment-contract-select>
    </div>
  </div>

  <div actions>
    <button mat-button [disabled]="!this.formGroup.valid" (click)="confirmThenAdd()" class="add-btn">Add Crop To Contract</button>
    <button mat-button [mat-dialog-close]="null" class="close-btn">Cancel</button>
  </div>

</gaia-shared-standard-dialog>
