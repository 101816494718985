<gaia-shared-standard-dialog dialogTitle="Add Field to Contract" i18n-dialogTitle="title for dialog where the user enters details on crop removal">
  <div content>
    <div [formGroup]="formGroup" fxLayout="column">

      <app-term-contract-select labelText="Term Contract"
                                formControlName="term_contract_id"
                                [grower]="contractedField.grower">
      </app-term-contract-select>

      <mat-form-field>
        <mat-label i18n="date when field was added to contract">Added On</mat-label>
        <input matInput [matDatepicker]="contractStartPicker" formControlName="added_to_contract">
        <mat-datepicker-toggle matSuffix [for]="contractStartPicker"></mat-datepicker-toggle>
        <mat-datepicker #contractStartPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="date when field was removed from contract">Removed From</mat-label>
        <input matInput [matDatepicker]="contractEndPicker" formControlName="removed_from_contract">
        <mat-datepicker-toggle matSuffix [for]="contractEndPicker"></mat-datepicker-toggle>
        <mat-datepicker #contractEndPicker></mat-datepicker>
        <mat-hint i18n="hint to say you can leave this empty, if this is the current contract">Leave blank if current contract</mat-hint>
      </mat-form-field>

    </div>
  </div>

  <div actions>
    <button mat-button [disabled]="!this.formGroup.valid" (click)="confirmThenAdd()" class="add-btn">Add Crop To Contract</button>
    <button mat-button mat-dialog-close="cancel">Cancel</button>
  </div>

</gaia-shared-standard-dialog>
