import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {BaleGrabAvailability} from '../bale-grab-availability.model';
import {BaleGrabAvailabilitiesService} from '../bale-grab-availabilities.service';
import {BaleGrabAvailabilityFormDialogComponent} from '../bale-grab-availability-form-dialog/bale-grab-availability-form-dialog.component';
import {GaiaIndexDataSource} from '../../../../services/gaia-index-data-source';

@Component({
  selector: 'app-bale-grab-availabilities-list',
  templateUrl: './bale-grab-availabilities-list.component.html',
  styleUrls: ['./bale-grab-availabilities-list.component.scss']
})
export class BaleGrabAvailabilitiesListComponent implements OnInit, AfterViewInit {

  gaiaIcons = GaiaIconSet;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  baleGrabs: GaiaIndexDataSource<BaleGrabAvailability>;
  displayedColumns = ['name', 'active'];

  constructor(
    private baleGrabsAvailabilitiesService: BaleGrabAvailabilitiesService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.baleGrabs = new GaiaIndexDataSource<BaleGrabAvailability>(this.baleGrabsAvailabilitiesService,
      {active: 'order_weight', direction: 'asc' }, 10);
    this.baleGrabs.loadRecords();
  }

  ngAfterViewInit(): void {
    this.baleGrabs.paginator = this.paginator;
  }

  newBaleGrab() {
    this.openDialog({} as BaleGrabAvailability);
  }

  openDialog(record: BaleGrabAvailability) {
    const dialogRef = this.matDialog.open(BaleGrabAvailabilityFormDialogComponent, {
      data: { record },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.baleGrabs.loadRecords();
    });
  }

  editBaleGrab(element: BaleGrabAvailability) {
    this.openDialog(element);
  }

}
