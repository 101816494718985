import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {CommercialUser} from '../commercial-user.model';
import {Address} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-commercial-user-card',
  templateUrl: './commercial-user-card.component.html',
  styleUrls: ['./commercial-user-card.component.scss']
})
export class CommercialUserCardComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;

  @Input()
  commercialUser: CommercialUser;

  @Input()
  editable = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  copyAddress: EventEmitter<Address> = new EventEmitter<Address>();

  constructor() { }

  ngOnInit(): void {
  }

  cancelClicked(){
    this.cancelled.emit();
  }

}
