import { Component, OnInit } from '@angular/core';
import {AppRoutes} from '../../app-routing.module';
import {OperationsRoutes} from '../operations-routing.module';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {MiscanthusStacksRoutes} from '../miscanthus-stacks/miscanthus-stacks-routing.module';

@Component({
  selector: 'app-operations-menu',
  templateUrl: './operations-menu.component.html',
  styleUrls: ['./operations-menu.component.scss']
})
export class OperationsMenuComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;
  operationRoutes = OperationsRoutes;
  stackRoutes = MiscanthusStacksRoutes;

  constructor() { }

  ngOnInit(): void {
  }

}
