import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GaiaIconSet} from "../../../models/gaia-icon-set";
import {DocumentsService} from "../documents.service";
import {Document} from "../document.model";
import {GaiaLogger} from "../../../../utils/common-functions";
import {GaiaRecord} from "../../../models/gaia-record.model";
import {ProcessingOverlayComponent} from "@terravesta/gaia-shared";
import {FormGroup} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-document-uploader-icon-button',
  templateUrl: './document-uploader-icon-button.component.html',
  styleUrls: ['./document-uploader-icon-button.component.scss']
})
export class DocumentUploaderIconButtonComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;
  fileName: Document;

  @Input() allowMultiple = false;

  @Output()
  emitUploadComplete = new EventEmitter();

  @Input()
  accept: string;

  @Input()
  record: GaiaRecord;

  @ViewChild(ProcessingOverlayComponent) overlay: ProcessingOverlayComponent;

  form: FormGroup;

  constructor(
    private documentService: DocumentsService,
    private matSnackBar: MatSnackBar,
  ) { }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    for(let i =0; i<event.length; i++){
      this.documentService.createRecordMultiPart({data: {
          new_version: event.item(i),
          associated_record_id: this.record.id
        } as unknown as Document}).subscribe((response)=>{
          GaiaLogger.log(response);
          this.fileName = response;
        this.matSnackBar.open('File uploaded successfully');
        this.emitUploadComplete.emit();
      });
    }
  }




  ngOnInit(): void {
  }

}
