import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddressesModule} from '@terravesta/gaia-shared';
import {StaffAbsencesModule} from './staff-absences/staff-absences.module';
import {StaffExpenseClaimsModule} from './staff-expense-claims/staff-expense-claims.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AddressesModule,
    StaffAbsencesModule,
    StaffExpenseClaimsModule
  ]
})
export class TeamSpaceModule { }
