import {Component, Inject } from '@angular/core';
import {NewDialogData, NewDialogDirective} from '../../../../components/new-dialog.directive';
import {EstablishmentContract} from '../establishment-contract.model';
import {EstablishmentContractsService} from '../establishment-contracts.service';
import {EstablishmentContractFormControlService} from '../establishment-contract-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../../app-routing.module';
import {GrowingRoutes} from '../../../growing-routing.module';
import {ContractRoutes} from '../../contracts-routing.module';

@Component({
  selector: 'app-establishment-contract-new-dialog',
  templateUrl: './establishment-contract-new-dialog.component.html',
  styleUrls: ['./establishment-contract-new-dialog.component.scss']
})
export class EstablishmentContractNewDialogComponent extends NewDialogDirective<EstablishmentContract> {

  constructor(
    establishmentContractService: EstablishmentContractsService,
    establishmentContractFormControl: EstablishmentContractFormControlService,
    @Inject(MAT_DIALOG_DATA) data: NewDialogData<EstablishmentContract>,
    public matDialog: MatDialogRef<EstablishmentContractNewDialogComponent>,
    private router: Router,
  ) {
    super(establishmentContractService, establishmentContractFormControl, data);
  }

  successfulSave(result: EstablishmentContract) {
    this.router.navigate([AppRoutes.GROWING, GrowingRoutes.CONTRACTS, ContractRoutes.ESTABLISHMENT_CONTRACTS, result.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
