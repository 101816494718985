import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ContractedField} from '../contracted-field.model';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ContractedFieldFormControlService} from '../contracted-field-form-control.service';
import {FormGroup} from '@angular/forms';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-contracted-field-remove-from-contract-dialog',
  templateUrl: './contracted-field-remove-from-contract-dialog.component.html',
  styleUrls: ['./contracted-field-remove-from-contract-dialog.component.scss']
})
export class ContractedFieldRemoveFromContractDialogComponent implements OnInit {

  contractedField: ContractedField;
  formGroup: FormGroup;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    private contractedFieldsFormControlService: ContractedFieldFormControlService,
    private dialogRef: MatDialogRef<ContractedFieldRemoveFromContractDialogComponent, ContractedField>,
    @Inject(MAT_DIALOG_DATA) data: ContractedFieldRemoveFromContractDialogData,
    private matDialog: MatDialog
  ) {
    this.contractedField = data.contractedField;
    this.formGroup = this.contractedFieldsFormControlService.removalForm();
  }

  ngOnInit(): void {
  }

  removeCrop() {
    if (this.formGroup.valid) {
      const ref = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent,
        {
          data: {
            title: 'Confirm removal from contract',
            message: 'Are you sure you wish to remove this field from the contract?',
          }
        });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.contractedFieldsService.removeFromCurrentContract(this.contractedField, this.formGroup.value)
            .subscribe((response) => {
              this.dialogRef.close(response);
            });
        }
      });
    }
  }
}

export interface ContractedFieldRemoveFromContractDialogData {
  contractedField: ContractedField;
}
