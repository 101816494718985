import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ContactType} from '../contact-type.model';
import {ContactTypesService} from '../contact-types.service';
import {ContactTypeFormDialogComponent} from '../contact-type-form-dialog/contact-type-form-dialog.component';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {PaginatedDataSource} from '@terravesta/phanes';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-contact-types-list',
  templateUrl: './contact-types-list.component.html',
  styleUrls: ['./contact-types-list.component.scss']
})
export class ContactTypesListComponent implements OnInit, AfterViewInit {

  gaiaIcons = GaiaIconSet;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  contactTypes: GaiaIndexDataSource<ContactType>;
  displayedColumns = ['name', 'active'];

  constructor(
    private baleTypesService: ContactTypesService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.contactTypes = new PaginatedDataSource<ContactType>(
      (params) => this.baleTypesService.getRecords(params));
    this.contactTypes.loadRecords();
  }

  ngAfterViewInit(): void {
    this.contactTypes.paginator = this.paginator;
    this.contactTypes.sort = this.sort;
  }

  newContactType() {
    this.openDialog({} as ContactType);
  }

  openDialog(record: ContactType) {
    const dialogRef = this.matDialog.open(ContactTypeFormDialogComponent, {
      data: { record },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.contactTypes.loadRecords();
    });
  }

  editContactType(element: ContactType) {
    this.openDialog(element);
  }
}
