<a mat-flat-button aria-hidden="false" aria-label="Contacts" color="primary" (click)="contactMenuTrigger.openMenu()">
  <mat-icon>{{ gaiaIcons.contacts }}</mat-icon>
  <div #contactMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="contactMenu"></div>
</a>
<mat-menu #contactMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.GROWERS]" i18n="Growers menu link">
    <mat-icon>{{ gaiaIcons.growers }}</mat-icon>
    Growers
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.CONTACTS]" i18n="Contacts database menu link">
    <mat-icon>{{ gaiaIcons.contacts }}</mat-icon>
    Contacts
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.CONTACTS, contactsRoutes.SETTINGS]" i18n="Contacts database menu link">
    <mat-icon>{{ gaiaIcons.settings }}</mat-icon>
    Settings
  </a>
</mat-menu>
