import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GrowerDashboardStats} from '../../grower.model';
import {GrowersService} from '../../growers.service';
import {DashboardWidgetDirective} from '../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {WidgetDefinition} from '../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';
import {GrowerStatsDashboardComponent} from '../../grower-stats-dashboard/grower-stats-dashboard.component';

@Component({
  selector: 'app-grower-contract-stats-widget',
  templateUrl: './grower-contract-stats-widget.component.html',
  styleUrls: ['./grower-contract-stats-widget.component.scss']
})
export class GrowerContractStatsWidgetComponent extends DashboardWidgetDirective implements OnInit   {

  gaiaIconSet = GaiaIconSet;
  currentStats: GrowerDashboardStats;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private growersService: GrowersService
  ) {
    super(elementRef, renderer);
  }

  ngOnInit() {
    this.growersService.dashboardStats().subscribe(
      (response) => {
        this.currentStats = response;
      }
    );
  }

  static widgetDefinition(): WidgetDefinition {
    return  {
      name: 'Grower Contract Summary',
      componentClass: GrowerStatsDashboardComponent,
      description: 'Chart to show the current contract stats',
      code: 'GROWER_CONTRACT_STATS',
      grouping: 'Operations',
      department: 'Operations',
      minColumnCount: 3,
      minRowCount: 2,
    };
  }

  processSettings(settings: any) {
  }


}
