import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './gaia-layout/homepage/homepage.component';
import {AssetsTableComponent} from "./assets-management/assets-table/assets-table.component";
import {AssetShowComponent} from "./assets-management/asset-show/asset-show.component";

export class AppRoutes {
  public static GROWING = 'growing';
  public static COMMERCIAL_SALES = 'commercial-sales';
  public static MARKETING = 'marketing';
  public static OPERATIONS = 'operations';
  public static TEAM_SPACE = 'team-space';
  public static CONTACTS = 'contacts';
  public static BUSINESS_SETTINGS = 'business';
  public static SYSTEM_RESOURCES = 'system-resources';
  public static SYSTEM_ADMIN = 'system-admin';
  public static DASHBOARDS = 'd';
}

const routes: Routes = [
  { path: '',
    component: HomepageComponent,
    // canActivate: [AuthGuard],
  },
  /*{
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [{
      path: 'drone', component: DroneImagePrintComponent
    }]
  },*/
  {
    path: AppRoutes.DASHBOARDS,
    loadChildren: () => import('./dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule),
  },
  { path: AppRoutes.GROWING,
    loadChildren: () => import('./growing/growing-routing.module').then(m => m.GrowingRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.BUSINESS_SETTINGS,
    loadChildren: () => import('./business-settings/business-settings-routing.module').then(m => m.BusinessSettingsRoutingModule)
    // canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.CONTACTS,
    loadChildren: () => import('./contacts/contact-routing.module').then(m => m.ContactRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.COMMERCIAL_SALES,
    loadChildren: () => import('./commercial-sales/commercial-sales-routing.module').then(m => m.CommercialSalesRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.OPERATIONS,
    loadChildren: () => import('./operations/operations-routing.module').then(m => m.OperationsRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.TEAM_SPACE,
    loadChildren: () => import('./team-space/team-space-routing.module').then(m => m.TeamSpaceRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.MARKETING,
    loadChildren: () => import('./marketing/marketing-routing.module').then(m => m.MarketingRoutingModule),
  },
  {
    path: AppRoutes.SYSTEM_RESOURCES,
    loadChildren: () => import('./system-resources/system-resources-routing.module').then(m => m.SystemResourcesRoutingModule),
  },
  {
    path: AppRoutes.SYSTEM_ADMIN,
    loadChildren: () => import('./system-admin/system-admin-routing.module').then(m => m.SystemAdminRoutingModule),
  },
  {
    path: 'planting',
    loadChildren: () => import('./planting/planting-routing.module').then(m => m.PlantingRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'assets',
    component: AssetsTableComponent
  },
  {
    path: 'assets/:id',
    component: AssetShowComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      relativeLinkResolution: 'corrected' // Fix the issue in the index router history thing
    }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
