import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {MiscanthusFieldHarvest} from './miscanthus-field-harvest.model';
import {MiscanthusStack} from '../miscanthus-stack.model';

@Injectable({
  providedIn: 'root'
})
export class MiscanthusFieldHarvestFormControlService implements GaiaRecordFormControl<MiscanthusFieldHarvest> {

  constructor(private formBuilder: FormBuilder) { }

  toFormGroup(record: MiscanthusFieldHarvest): FormGroup {
    return this.formBuilder.group({
      bale_count: new FormControl(record.bale_count, Validators.required),
      contracted_field_id: new FormControl(record.contracted_field ? record.contracted_field.id : null, Validators.required),
      estimated_moisture_content: new FormControl(record.estimated_moisture_content),
      bale_type_id: new FormControl(record.bale_type ? record.bale_type.id : null, Validators.required),
      added_to_stack: new FormControl(record.added_to_stack, Validators.required),
    });
  }

  toReviewFormGroup(record: MiscanthusFieldHarvest): FormGroup {
    return this.formBuilder.group({
      bale_count: new FormControl(record.bale_count, Validators.required),
      id: new FormControl(record.id, Validators.required),
    });
  }

  toFormArray(records: Array<MiscanthusFieldHarvest>, miscanthusStack: MiscanthusStack): FormGroup {
    const controls = [];
    records.forEach((record) => {
      controls.push(this.toReviewFormGroup(record));
    });
    const fieldControls = this.formBuilder.array(controls, this.stackBaleCountValidator(miscanthusStack));
    return this.formBuilder.group({
      field_harvests: fieldControls
    });
  }

  stackBaleCountValidator(miscanthusStack: MiscanthusStack): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formArray = control as FormArray;
      let totalBaleCount = 0;
      formArray.controls.forEach((fieldHarvest) => {
        totalBaleCount += fieldHarvest.get('bale_count').value;
      });
      const baleCountMisMatched = totalBaleCount !== miscanthusStack.field_bale_count;
      return baleCountMisMatched ? {baleCountInvalid: {value: control.value}} : null;
    };
  }

}
