import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GrowingRoutes} from '../../growing-routing.module';
import {FormControl} from '@angular/forms';
import {PLANTING_OPPORTUNITY_SALESFORCE_STAGES, PlantingOpportunity} from '../planting-opportunity.model';
import {PlantingOpportunitiesService} from '../planting-opportunities.service';
import {Grower} from '../../growers/grower.model';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {MatPaginator} from '@angular/material/paginator';
import {AppRoutes} from '../../../app-routing.module';
import {PaginatedDataSource} from '@terravesta/phanes';

@Component({
  selector: 'app-planting-opportunity-embed-table',
  templateUrl: './planting-opportunity-embed-table.component.html',
  styleUrls: ['./planting-opportunity-embed-table.component.scss']
})
export class PlantingOpportunityEmbedTableComponent implements OnInit, OnChanges, AfterViewInit {

  displayedColumns = ['name', 'type', 'expected_planting_year', 'stage', 'field_count'];
  growingRoutes = GrowingRoutes;

  stageFormControl: FormControl = new FormControl(null);
  stages = PLANTING_OPPORTUNITY_SALESFORCE_STAGES;
  dataSource: GaiaIndexDataSource<PlantingOpportunity>;
  appRoutes = AppRoutes;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input()
  grower: Grower;

  constructor(
    private plantingOpsService: PlantingOpportunitiesService,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grower) {
      if (changes.grower.currentValue !== changes.grower.previousValue) {
        this.loadOpportunities();
      }
    }
  }

  loadOpportunities() {
    this.dataSource = new PaginatedDataSource<PlantingOpportunity>(
      (params) => this.plantingOpsService.getRecordsForGrower(this.grower, params));
    this.dataSource.loadRecords();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

}
