<app-index-dashboard-wrapper i18n-dashboardTitle dashboardTitle="Contracted Field Stats">

  <div fxFlex="1 1 calc(100%/3)"></div>

  <mat-card fxFlex="0 0 calc(100%/3 - 15px)">
    <mat-card-header>
      <mat-icon mat-card-avatar>{{ gaiaIconSet.summary }}</mat-icon>
      <mat-card-title i18n>KML Downloads</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="5px">
        <gaia-shared-spinner-loading-button color="accent" #kmlDownload (clickOccurred)="downloadKML()" i18n>All Mapped Fields</gaia-shared-spinner-loading-button>
        <div fxLayout="row" fxLayoutGap="5px">
          <mat-form-field>
            <mat-label>Year Planted</mat-label>
            <mat-select [formControl]="yearSelection">
              <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
          <gaia-shared-spinner-loading-button color="accent" #yearDownload (clickOccurred)="downloadByYear()" i18n>Fields Planted in Year</gaia-shared-spinner-loading-button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</app-index-dashboard-wrapper>
