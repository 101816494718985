import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PlantingOpportunitiesService} from '../../../growing/planting-opportunities/planting-opportunities.service';
import {DeviceType, LayoutTrackingService} from '../../../gaia-layout/layout-tracking.service';
import {MiscanthusStacksService} from '../miscanthus-stacks.service';
import {GaiaLogger} from '../../../../utils/common-functions';
import {PhanesNode} from '@terravesta/phanes';
import {MiscanthusStack} from '../miscanthus-stack.model';

@Component({
  selector: 'app-miscanthus-stack-map-feature-window',
  templateUrl: './miscanthus-stack-map-feature-window.component.html',
  styleUrls: ['./miscanthus-stack-map-feature-window.component.scss']
})
export class MiscanthusStackMapFeatureWindowComponent implements OnInit, OnChanges {

  constructor(private miscanthusStacksService: MiscanthusStacksService, private layoutTracking: LayoutTrackingService)
  {
      this.layoutTracking.deviceType.subscribe((deviceType) => {
        this.isMobile = deviceType === DeviceType.MOBILE;
        this.panelOpenState = !this.isMobile;
        GaiaLogger.log(deviceType, this.panelOpenState);
    });
  }
  @Input() clickedMiscanthusRecord: PhanesNode;
  @Output() closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  miscanthusStack: MiscanthusStack;
  visible = true;
  panelOpenState = true;
  isMobile = false;

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.clickedMiscanthusRecord){
      if (changes.clickedMiscanthusRecord.currentValue){
        this.getMiscanthusStack();
      }
    }
  }

  getMiscanthusStack() {
    this.miscanthusStacksService.getRecord({recordId: this.clickedMiscanthusRecord.id}).subscribe((miscanthus) => {
      this.miscanthusStack = miscanthus;
    });
  }
  CloseInfo() {
    this.visible = !this.visible;
    this.closeClicked.emit(true);
  }

}
