import {StaffMember} from '../../../system-admin/staff-member/staff-member.model';

export interface RecordedMonthly {
  recorded_month: number;
  recorded_year: number;
}


export interface HasCurrency {
  currency: string;
  exchange_rate: number;
}

export interface StaffClaim {
  date_of_claim: Date;
  claimant: StaffMember;
  claim_value: number;
}

export interface RecordedMonthWindow {
  year: number;
  month: number;
}

export function getCurrentRecordedMonthWindow(cutOff: number): RecordedMonthWindow {
  const today = new Date();
  let month;
  let year = today.getFullYear();
  if (today.getDate() > cutOff) {
    month = today.getMonth() + 2; // stupid zero indexed months
  }else{
    month = today.getMonth() + 1;
  }
  if (month > 12) {
    year += 1;
  }
  return { year, month };
}

export function getPreviousRecordedMonthWindow(cutOff: number): RecordedMonthWindow {
  const today = new Date();
  let month;
  let year = today.getFullYear();
  if (today.getDate() > cutOff) {
    month = today.getMonth() + 1; // stupid zero indexed months
  }else{
    month = today.getMonth();
  }
  if (month > 12) {
    year += 1;
  }
  return { year, month };
}
