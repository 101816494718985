import { Component} from '@angular/core';
import {UniversalSearchPreviewDirective} from '../../../universal-search/universal-search-preview.directive';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {ContractedFieldUniversalSearchPreview, ContractedFieldUniversalSearchResult} from '../contracted-field.model';
import {ContractedFieldsService} from '../contracted-fields.service';

@Component({
  selector: 'app-contracted-field-universal-search-result',
  templateUrl: './contracted-field-universal-search-result.component.html',
  styleUrls: ['./contracted-field-universal-search-result.component.scss']
})
export class ContractedFieldUniversalSearchResultComponent extends UniversalSearchPreviewDirective<ContractedFieldUniversalSearchResult, ContractedFieldUniversalSearchPreview> {

  previewData: any;

  constructor(
    private router: Router,
    contractedFieldsService: ContractedFieldsService,
  ) {
    super(contractedFieldsService)
  }

  showField() {
    this.router.navigate(['/', AppRoutes.GROWING, GrowingRoutes.CONTRACTED_FIELDS, this.record.id]).then(() => {
      this.requestClose.emit();
    });
  }

}
