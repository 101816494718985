import { Component } from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {Haulier} from '../haulier.model';

@Component({
  selector: 'app-haulier-form',
  templateUrl: './haulier-form.component.html',
  styleUrls: ['./haulier-form.component.scss']
})
export class HaulierFormComponent extends RecordFormDirective<Haulier> {


}
