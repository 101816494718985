<ng-container *ngIf="form" [formGroup]="form">
  <div class="two-column-cards">
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.hauliers}}</mat-icon>
        <mat-card-title i18n>Contact</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-contact-find-or-create-simple formGroupName="contact"></app-contact-find-or-create-simple>
      </mat-card-content>
    </mat-card>

    <mat-card class="driver-details">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{gaiaIconSet.hauliers}}</mat-icon>
        <mat-card-title i18n>Driver Information</mat-card-title>
      </mat-card-header>
      <mat-card-content>

        <mat-slide-toggle formControlName="active" i18n>Active</mat-slide-toggle>

        <h2 i18n>Delivery Notifications</h2>

        <mat-slide-toggle formControlName="collection_notice_sms" i18n>SMS</mat-slide-toggle>
        <mat-slide-toggle formControlName="collection_notice_email" i18n>Email</mat-slide-toggle>

      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
