import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderAvailabilitiesListComponent } from './loader-availabilities-list/loader-availabilities-list.component';
import { LoaderAvailabilityFormDialogComponent } from './loader-availability-form-dialog/loader-availability-form-dialog.component';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexModule} from '@angular/flex-layout';
import {LoaderAvailabilitiesSelectComponent} from './loader-availabilities-select/loader-availabilities-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [LoaderAvailabilitiesListComponent, LoaderAvailabilityFormDialogComponent, LoaderAvailabilitiesSelectComponent],
    exports: [
        LoaderAvailabilitiesListComponent,
        LoaderAvailabilitiesSelectComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        PhanesComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        FlexModule,
        MatSelectModule
    ]
})
export class LoaderAvailabilitiesModule { }
