import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OperationsModule} from '../operations/operations.module';
import {SideDrawerComponent} from './side-drawer/side-drawer.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {GrowingModule} from '../growing/growing.module';
import {MatButtonModule} from '@angular/material/button';
import {CommercialSalesModule} from '../commercial-sales/commercial-sales.module';
import {MarketingModule} from '../marketing/marketing.module';
import {SystemResourcesModule} from '../system-resources/system-resources.module';
import {TeamSpaceAdminModule} from '../team-space/team-space-admin/team-space-admin.module';
import {ContactsModule} from '../contacts/contacts.module';
import {BusinessSettingsModule} from '../business-settings/business-settings.module';
import {HomepageComponent} from './homepage/homepage.component';
import {DashboardComponentsModule} from '../custom-dashboards/dashboard-components/dashboard-components.module';
import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {InternalNotesModule} from '../internal-notes/internal-notes.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import { ScreenLayoutComponent } from './screen-layout/screen-layout.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SystemAdminModule} from '../system-admin/system-admin.module';
import {ScreenLockComponent} from './screen-lock/screen-lock.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {UniversalSearchModule} from '../universal-search/universal-search.module';
import {AssetsManagementModule} from "../assets-management/assets-management.module";


@NgModule({
  declarations: [SideDrawerComponent, HomepageComponent, TopToolbarComponent, ScreenLayoutComponent, ScreenLockComponent ],
  imports: [
    CommonModule,
    OperationsModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    GrowingModule,
    MatButtonModule,
    CommercialSalesModule,
    MarketingModule,
    SystemResourcesModule,
    TeamSpaceAdminModule,
    ContactsModule,
    BusinessSettingsModule,
    DashboardComponentsModule,
    MatToolbarModule,
    InternalNotesModule,
    MatTooltipModule,
    FlexModule,
    MatSidenavModule,
    SystemAdminModule,
    MatCardModule,
    MatInputModule,
    UniversalSearchModule,
    AssetsManagementModule
  ],
  exports: [ScreenLayoutComponent, ScreenLockComponent]
})
export class GaiaLayoutModule { }
