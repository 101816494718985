import { Component } from '@angular/core';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {Haulier} from '../haulier.model';
import {HauliersService} from '../hauliers.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {OperationsRoutes} from '../../operations-routing.module';
import {HaulierNewDialogComponent} from '../haulier-new-dialog/haulier-new-dialog.component';

@Component({
  selector: 'app-hauliers-table',
  templateUrl: './hauliers-table.component.html',
  styleUrls: ['./hauliers-table.component.scss']
})
export class HauliersTableComponent extends IndexTableDirective<Haulier> {

  displayedColumns = ['account_name', 'active', 'driver_count'];
  operationsRoutes = OperationsRoutes;

  constructor(
    hauliersService: HauliersService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(hauliersService, router, activatedRoute, matDialog, HaulierNewDialogComponent, { active: 'account_name', direction: 'asc'},
        ['account_name']);
  }


}
