<mat-form-field>
  <mat-label i18n>Loader Availability</mat-label>
  <mat-select
    [formControl]="control"
    (selectionChange)="selectionChange.emit($event)"
    ngDefaultControl>
    <mat-option
      *ngFor="let loaderAvailability of records" [value]="loaderAvailability.id"> {{ loaderAvailability.name }}</mat-option>
  </mat-select>
</mat-form-field>
