import {Component} from '@angular/core';
import {StaffMember} from "../staff-member.model";
import {MatDialogRef} from "@angular/material/dialog";
import {StaffMemberFormControlService} from "../staff-member-form-control.service";
import {NewDialogDirective} from "../../../components/new-dialog.directive";
import {StaffMemberService} from "../staff-member.service";
import {SessionDataService} from "../../../services/session-data.service";

@Component({
  selector: 'app-staff-member-dialog',
  templateUrl: './staff-member-dialog.component.html',
  styleUrls: ['./staff-member-dialog.component.scss']
})
export class StaffMemberDialogComponent extends NewDialogDirective<StaffMember>{

  constructor(
    public dialogRef: MatDialogRef<StaffMemberDialogComponent>,
    private staffMemberFormControlService: StaffMemberFormControlService,
    staffMemberService: StaffMemberService,
    private sessionData: SessionDataService,
  ) {
    super(staffMemberService,staffMemberFormControlService);

  }
  ngOnInit(): void {
    super.ngOnInit();
    const currentUser = this.sessionData.getUser();
    (this.staffMemberFormControlService as StaffMemberFormControlService).updatePermissions(this.form, currentUser.has_hr_access);
  }

  successfulSave(result: StaffMember) {
    this.dialogRef.close();
  }

}
