import {Component, Inject, OnInit} from '@angular/core';
import {StaffAbsence, StaffAbsenceTypes} from '../staff-absence.model';
import {StaffAbsencesService} from '../staff-absences.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StaffAbsenceFormControlService} from '../staff-absence-form-control.service';
import {FormDialogData, FormDialogDirective} from '../../../components/form-dialog.directive';

@Component({
  selector: 'app-staff-absence-form-dialog',
  templateUrl: './staff-absence-form-dialog.component.html',
  styleUrls: ['./staff-absence-form-dialog.component.scss']
})
export class StaffAbsenceFormDialogComponent extends FormDialogDirective<StaffAbsence> implements OnInit {

  absenceTypes = StaffAbsenceTypes;

  leaveDayCount: number = null;


  constructor(
    staffAbsencesService: StaffAbsencesService,
    staffAbsenceFormControl: StaffAbsenceFormControlService,
    matDialog: MatDialogRef <StaffAbsenceFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StaffAbsenceFormDialogData,
  ) {
    super(staffAbsencesService, staffAbsenceFormControl, matDialog, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.form.valueChanges.subscribe((changes) => {
      if (this.form.get('absence_type').value === StaffAbsenceTypes.leave) {
        if (this.form.get('start_date_time').value && this.form.get('end_date_time').value) {

          (this.recordService as StaffAbsencesService).getDayCount(
            this.form.get('start_date_time').value,
            this.form.get('end_date_time').value,
            this.form.get('start_in_afternoon').value,
            this.form.get('return_in_afternoon').value,
          ).subscribe((response) => {
            this.leaveDayCount = response;
          });
        }
      }
    });
  }
}

export interface StaffAbsenceFormDialogData extends FormDialogData<StaffAbsence>{
  remainingLeave: number;
}
