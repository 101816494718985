import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {Event} from '../events/events.model';
import {WebEnquiries} from './web-enquiries.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebEnquiriesService extends RestApiService<WebEnquiries> {

  constructor(
    httpClient: HttpClient
  ) {
    super('web_enquiries', 'web_enquiry', httpClient);
  }
}
