<ng-container *ngIf="form">

  <app-new-record-dialog [form]="form" dialogTitle="Add Bales to Stack" i18n-dialogTitle (saveClicked)="save()" [dialogRef]="matDialog">

    <phanes-error-panel></phanes-error-panel>

    <ng-container [formGroup]="form">

      <app-bale-gain-form [form]="form" [stack]="data.parent"></app-bale-gain-form>

    </ng-container>
  </app-new-record-dialog>
</ng-container>
