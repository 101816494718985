<div class="search-container">

  <div class="search-bar">
    <mat-form-field>
      <mat-icon matPrefix>{{gaiaIconSet.search }}</mat-icon>
      <input #searchBox matInput [formControl]="searchTerm" />
    </mat-form-field>
  </div>

  <div class="search-results-container">
    <div class="search-results">

      <div class="results-list">

        <mat-spinner *ngIf="searching"></mat-spinner>
        <mat-action-list *ngIf="currentResults" dense>
          <mat-list-item *ngFor="let result of currentResults"
                         (mouseover)="updatePreview(result)"
                         (click)="navigateToRecord(result)">
            <mat-icon matListIcon>{{ getRecordIcon(result.class_type, result) }}</mat-icon>

            <div mat-line>{{result.data.display_name}}</div>
            <div mat-line> {{result.class_name }} </div>
          </mat-list-item>
        </mat-action-list>
      </div>
      <div class="results-summary">
        <div *ngIf="currentResults">
          <span i18n>Showing</span> {{ currentResults.length }} <span i18n>Results</span>
        </div>
      </div>
    </div>

    <div class="search-preview">
      <ng-container *ngIf="previewResult" [ngSwitch]="previewResult.class_type">
        <app-contact-universal-search-result *ngSwitchCase="'contact'" (requestClose)="closeDialog()"
                                             [record]="previewResult.data"></app-contact-universal-search-result>

        <app-grower-universal-search-result *ngSwitchCase="'grower'" (requestClose)="closeDialog()"
                                            [record]="previewResult.data"></app-grower-universal-search-result>

        <app-haulier-universal-search-result *ngSwitchCase="'haulier'" (requestClose)="closeDialog()"
                                            [record]="previewResult.data"></app-haulier-universal-search-result>

        <app-processor-universal-search-result *ngSwitchCase="'processor'" (requestClose)="closeDialog()"
                                               [record]="previewResult.data"></app-processor-universal-search-result>

        <app-contractor-universal-search-result *ngSwitchCase="'contractor'" (requestClose)="closeDialog()"
                                                [record]="previewResult.data"></app-contractor-universal-search-result>

        <app-bale-collection-universal-search-result *ngSwitchCase="'bale_collection'" (requestClose)="closeDialog()"
                                                [record]="previewResult.data"></app-bale-collection-universal-search-result>

        <app-contracted-field-universal-search-result *ngSwitchCase="'contracted_field'" (requestClose)="closeDialog()"
                                                     [record]="previewResult.data"></app-contracted-field-universal-search-result>
      </ng-container>
    </div>
  </div>

</div>
