import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {LoaderAvailability} from './loader-availability.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoaderAvailabilityFormControlService implements GaiaRecordFormControl<LoaderAvailability> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: LoaderAvailability): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(record.name, Validators.required),
      active: new FormControl(record.active),
      order_weight: new FormControl(record.order_weight),
      previous_names: new FormControl(record.previous_names)
    });
  }
}
