import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MiscanthusFieldHarvest} from './miscanthus-field-harvest.model';
import {RestApiService} from '../../../services/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class MiscanthusFieldHarvestsService extends RestApiService<MiscanthusFieldHarvest> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/miscanthus_stacks/{parentId}/miscanthus_field_harvests', 'miscanthus_field_harvest', httpClient);
  }
}
