import {Component, Inject, OnInit} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {StackNoticePeriod} from '../stack-notice-period.model';
import {StackNoticePeriodsService} from '../stack-notice-periods.service';
import {StackStorageMethodFormControlService} from '../../stack-storage-methods/stack-storage-method-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-stack-notice-period-form-dialog',
  templateUrl: './stack-notice-period-form-dialog.component.html',
  styleUrls: ['./stack-notice-period-form-dialog.component.scss']
})
export class StackNoticePeriodFormDialogComponent extends FormDialogDirective<StackNoticePeriod> {

  constructor(
    noticePeriodsService: StackNoticePeriodsService,
    noticePeriodFormControl: StackStorageMethodFormControlService,
    matDialog: MatDialogRef<StackNoticePeriod>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<StackNoticePeriod>
  ) {
    super(noticePeriodsService, noticePeriodFormControl, matDialog, data);
  }

}
