import {Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Grower} from '../../../growers/grower.model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorConnectorDirective} from '@terravesta/phanes';
import {TermContract} from '../term-contract.model';
import {TermContractsService} from '../term-contracts.service';

@Component({
  selector: 'app-term-contract-select',
  templateUrl: './term-contract-select.component.html',
  styleUrls: ['./term-contract-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TermContractSelectComponent),
      multi: true
    }

  ]
})
export class TermContractSelectComponent extends ControlValueAccessorConnectorDirective implements OnInit, OnChanges {

  @Input()
  grower: Grower;

  @Input()
  labelText: string;

  contracts: Array<TermContract>;

  constructor(
    private termContractsService: TermContractsService,
    inj: Injector,
  ) {
    super(inj);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grower) {
      if (changes.grower.currentValue != changes.grower.previousValue) {
        this.termContractsService.forGrower(this.grower).subscribe((results) => {
          this.contracts = results.records;
        });
      }
    }
  }

}
