import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProductTableComponent} from './product-table/product-table.component';
import {ProductShowComponent} from './product-show/product-show.component';


const routes: Routes = [
  { path: '', component: ProductTableComponent },
  { path: ':id', component: ProductShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
