import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AppRoutes} from '../../../app-routing.module';
import {UniversalSearchPreviewDirective} from '../../../universal-search/universal-search-preview.directive';
import {BaleCollectionsService} from '../bale-collections.service';
import {OperationsRoutes} from '../../operations-routing.module';
import {BaleCollectionUniversalSearchPreview, BaleCollectionUniversalSearchResult} from '../bale-collection.model';

@Component({
  selector: 'app-bale-collection-universal-search-result',
  templateUrl: './bale-collection-universal-search-result.component.html',
  styleUrls: ['./bale-collection-universal-search-result.component.scss']
})
export class BaleCollectionUniversalSearchResultComponent extends UniversalSearchPreviewDirective<BaleCollectionUniversalSearchResult, BaleCollectionUniversalSearchPreview> {

  previewData: any;

  constructor(
    private router: Router,
    baleCollectionsService: BaleCollectionsService,
  ) {
    super(baleCollectionsService)
  }

  showGrower() {
    this.router.navigate(['/', AppRoutes.OPERATIONS, OperationsRoutes.BALE_COLLECTIONS, this.record.id]).then(() => {
      this.requestClose.emit();
    });
  }


}
