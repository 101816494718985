import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export class TeamSpaceRoutes {
  public static ADMIN = 'admin';
}

const routes: Routes = [
  {
    path: TeamSpaceRoutes.ADMIN,
    loadChildren: () => import('./team-space-admin/team-space-admin-routing.module').then(m => m.TeamSpaceAdminRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeamSpaceRoutingModule{ }
