<app-new-record-dialog [form]="form" dialogTitle="Absence Request" (saveClicked)="save()" [dialogRef]="matDialog">

  <ng-container *ngIf="form" [formGroup]="form">

    <phanes-error-panel></phanes-error-panel>

    <mat-form-field appearance="fill">
      <mat-label i18n>Absence Type</mat-label>
      <mat-select [formControlName]="'absence_type'">
        <mat-option [value]="absenceTypes.absence" i18n>Absence</mat-option>
        <mat-option [value]="absenceTypes.leave" i18n>Leave</mat-option>
      </mat-select>
    </mat-form-field>

    <div fxLayout="column">
      <ng-container [ngSwitch]="form.get('absence_type').value">
        <ng-container *ngSwitchCase="absenceTypes.leave">
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <mat-form-field>
              <mat-label i18n>Enter Start Time</mat-label>
              <input matInput placeholder="Start date" i18n-placeholder [formControlName]="'start_date_time'" [matDatepicker]="startDatePicker" />
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-checkbox [formControlName]="'start_in_afternoon'" i18n>Start in Afternoon</mat-checkbox>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
            <mat-form-field>
              <mat-label i18n>Enter End Time</mat-label>
              <input matInput placeholder="End date" i18n-placeholder [formControlName]="'end_date_time'"
                     [matDatepicker]="endDatePicker" [min]="form.get('start_date_time').value"/>
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-checkbox [formControlName]="'return_in_afternoon'" i18n>Return in Afternoon</mat-checkbox>
          </div>
          <div *ngIf="leaveDayCount">
            <span i18n>This request will use {{leaveDayCount}} days of leave</span>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="absenceTypes.absence">
          <mat-form-field>
            <mat-label i18n>Enter Start Time</mat-label>
            <input matInput type="datetime-local" placeholder="Start date" [formControlName]="'start_date_time'" />
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>Enter End Time</mat-label>
            <input matInput type="datetime-local" placeholder="End date" [formControlName]="'end_date_time'"
                [min]="form.get('start_date_time').value"/>
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>Reason</mat-label>
            <input matInput type="text" placeholder="Please supply reason for absence" i18n-placeholder [formControlName]="'reason'" />
          </mat-form-field>
        </ng-container>

      </ng-container>

    </div>
  </ng-container>

</app-new-record-dialog>
