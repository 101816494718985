<!--<mat-dialog-content>-->
<!--  <div>-->
<!--    <h1 style="text-align: center">New Staff Member-->
<!--      <button style="float:right" mat-stroked-button color="primary" mat-dialog-close>X</button>-->
<!--    </h1>-->
<!--  </div>-->
<!--  <app-staff-member-form [form]="form" [record]="record"></app-staff-member-form>-->
<!--</mat-dialog-content>-->
<app-new-record-dialog [form]="form" dialogTitle="New Staff" (saveClicked)="save()" [dialogRef]="dialogRef">
  <app-staff-member-form [form]="form"></app-staff-member-form>
</app-new-record-dialog>
