import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PlantingOpportunitiesService} from '../planting-opportunities.service';
import {PhanesNode} from '@terravesta/phanes';
import {PlantingOpportunity} from '../planting-opportunity.model';
import {DeviceType, LayoutTrackingService} from '../../../gaia-layout/layout-tracking.service';

@Component({
  selector: 'app-planting-opportunity-map-feature-window',
  templateUrl: './planting-opportunity-map-feature-window.component.html',
  styleUrls: ['./planting-opportunity-map-feature-window.component.scss']
})
export class PlantingOpportunityMapFeatureWindowComponent implements OnInit, OnChanges {

  constructor(private plantingOpportunitiesService: PlantingOpportunitiesService,
              private layoutTracking: LayoutTrackingService) {
    this.layoutTracking.deviceType.subscribe((deviceType) => {
      this.isMobile = deviceType === DeviceType.MOBILE;
      this.panelOpenState = !this.isMobile;
      console.log(deviceType, this.panelOpenState);
    });
  }
  @Input() clickedOpportunityRecord: PhanesNode;
  @Output() closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  plantingOpportunity: PlantingOpportunity;
  visible = true;
  panelOpenState = true;
  isMobile = false;

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
     if (changes.clickedOpportunityRecord){
      if (changes.clickedOpportunityRecord.currentValue){
        this.getPlantingOpportunityData();
      }
    }
  }
  getPlantingOpportunityData() {
    this.plantingOpportunitiesService.getRecord({recordId: this.clickedOpportunityRecord.id}).subscribe((opportunity) => {
      this.plantingOpportunity = opportunity;
    });
  }
  CloseInfo() {
    this.visible = !this.visible;
    this.closeClicked.emit(true);
  }

}
