import {ContractedField, ContractedFieldMapData} from './contracted-field.model';
import {AppRoutes} from '../../app-routing.module';
import {GrowingRoutes} from '../growing-routing.module';
import {AgriculturalField} from '../agricultural-field.model';

export function agriculturalFieldInfoWindow(field: AgriculturalField): string {
  return infoWindowHtml(field.id, field.name, '', '',  null);
}

export function fieldInfoWindowHtml(contractedField: ContractedField): string {
  return infoWindowHtml(contractedField.id, contractedField.name, contractedField.grower.grower_code,
                        contractedField.planted_variety ? contractedField.planted_variety.name : '',
                            contractedField.year_planted);
}

export function mapDataInfoWindowHtml(contractedField: ContractedFieldMapData): string {
  return infoWindowHtml(contractedField.id, contractedField.name, contractedField.grower_code, contractedField.variety,
        contractedField.year_planted);
}

function infoWindowHtml(id: string, name: string, growerCode: string, variety: string, yearPlanted: number): string {
  return `
    <strong style="font-size: 120%;">
      <a style="color: #000000;" target="_blank" href="/${[AppRoutes.GROWING, GrowingRoutes.CONTRACTED_FIELDS, id].join('/')}">${name}</a>
    </strong>
    <br />
    <strong>Grower:</strong> ${growerCode}
    <br />
    <strong>Variety:</strong> ${variety}
    <br />
    <strong>Planted:</strong> ${yearPlanted}
    `;
}

/*
export function addFieldToMap(map: GooglePolygonMapComponent, field: ContractedFieldMapData, zIndex?: number){
  const content = mapDataInfoWindowHtml(field);
  if (field.geo_data.points.length > 0) {
    map.addPolygonRecord(field, {
      strokeColor: field.color,
    }, {content});
  } else {
    map.addMarkerRecord(field, {
      icon: customMapPin(field.color),
      zIndex
    }, {content});
  }
}
*/
