import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-index-header',
  templateUrl: './index-header.component.html',
  styleUrls: ['./index-header.component.scss']
})
export class IndexHeaderComponent implements OnInit, OnDestroy, OnChanges {

  gaiaIconSet = GaiaIconSet;

  @Input()
  pageTitle: string;

  @Input()
  searchHint = '';

  @Input()
  hideAdd = false;

  @Input()
  hideSearch = false;

  @Input()
  searchValue: string = null;

  @Output()
  addNewClicked = new EventEmitter<any>();

  @Output()
  searchRequest = new EventEmitter<string>();

  searchControl: FormControl;

  //private searchQuery: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  private searchDelay = 500;

  constructor() { }

  ngOnInit(): void {
    this.searchControl = new FormControl(this.searchValue);
    this.subscription = this.searchControl.valueChanges.pipe(
      debounceTime(this.searchDelay)
    ).subscribe((searchTerm) => {
      this.searchRequest.emit(searchTerm);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  /*doSearch(value: string) {
    this.searchQuery.next(value);
  }*/

}
