import {Injectable} from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {ContractedField} from './contracted-field.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TermContract} from '../contracts/term-contracts/term-contract.model';

@Injectable({
  providedIn: 'root'
})
export class ContractedFieldFormControlService implements GaiaRecordFormControl<ContractedField> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: ContractedField): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(record.name, Validators.required),
      grower_id: new FormControl(record.grower ? record.grower.id : null, Validators.required),
      planted_area_ha: new FormControl(record.planted_area_ha),
      replanted_area_ha: new FormControl(record.replanted_field ? record.replanted_field.replanted_area_ha : null),
      non_planted_area_ha: new FormControl(record.non_planted_area_ha),
      total_field_area_ha: new FormControl(record.total_field_area_ha),
      field_reference_number: new FormControl(record.field_reference_number),
      year_planted: new FormControl(record.year_planted),
      year_replanted: new FormControl(record.replanted_field ? record.replanted_field.year_replanted : null),
      planted_variety_id: new FormControl(record.planted_variety ? record.planted_variety.id : null, Validators.required),
      is_game_cover: new FormControl(record.is_game_cover),
      own_use: new FormControl(record.own_use),
      auto_mapping_override: new FormControl(record.auto_mapping_override),
      replanting: new FormControl(record.replanting),
      mixed_year_planting: new FormControl(record.mixed_year_planting),
      crop_removed_on: new FormControl(record.crop_removed_on),
      reason_for_removal: new FormControl(record.reason_for_removal),
      date_planting_started: new FormControl(record.date_planting_started),
      date_planting_ended: new FormControl(record.date_planting_ended),
      planting_density: new FormControl(record.planting_density)
    });
  }

  toMapFilter(): FormGroup {
    return this.formBuilder.group({
      year_planted: new FormControl(),
      miscanthus_variety: new FormControl(),
      marker_type: new FormControl(),
    });
  }

  removalForm(): FormGroup {
    return this.formBuilder.group({
      contract_removal_date: new FormControl('', Validators.required),
      reason_for_removal: new FormControl(''),
      crop_removed_on: new FormControl(''),
    });
  }

  establishmentContractsForm(contractedField: ContractedField): FormGroup {
    return this.formBuilder.group({
      establishment_contract_id: new FormControl(contractedField.establishment_contract ? contractedField.establishment_contract.id : null,
        Validators.required),
    });
  }

  addToTermContractForm(termContract: TermContract = null): FormGroup {
    return this.formBuilder.group({
      term_contract_id: new FormControl(termContract ? termContract.id : null, Validators.required),
      added_to_contract: new FormControl(null, Validators.required),
      removed_from_contract: new FormControl(null),
    });
  }

}
