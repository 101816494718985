import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {Company} from './company.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends RestApiService<Company> {

  constructor(httpClient: HttpClient) {
    super('/companies', 'company', httpClient);
  }

}

