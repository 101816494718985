import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiresApprovalStatusI18nComponent } from './requires-approval/requires-approval-status-i18n/requires-approval-status-i18n.component';
import { CurrencySelectComponent } from './currency-select/currency-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { RecordMonthlyPeriodSelectorComponent } from './recorded-monthly/record-monthly-period-selector/record-monthly-period-selector.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {FlexModule} from '@angular/flex-layout';

@NgModule({
  declarations: [RequiresApprovalStatusI18nComponent, CurrencySelectComponent, RecordMonthlyPeriodSelectorComponent],
  exports: [
    RequiresApprovalStatusI18nComponent,
    CurrencySelectComponent,
    RecordMonthlyPeriodSelectorComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    GaiaGeneralModule,
    FlexModule
  ]
})
export class TeamSpaceConcernsModule { }
