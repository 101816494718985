import {Component, Input, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-show-page-header',
  templateUrl: './show-page-header.component.html',
  styleUrls: ['./show-page-header.component.scss']
})
export class ShowPageHeaderComponent implements OnInit {

  @Input() recordType: string;
  @Input() displayName: string;
  @Input() recordIcon: string;

  @Input() warnMode = false;

  constructor() { }

  ngOnInit(): void {
  }

}
