import {Directive} from '@angular/core';
import {GaiaRecord} from '../models/gaia-record.model';
import {NewDialogDirective} from './new-dialog.directive';
import {RailsUploadParams} from '@terravesta/phanes';

@Directive()
export abstract class NewDialogMultipartDirective<T extends GaiaRecord> extends NewDialogDirective<T> {

  save() {
    if (this.form.valid) {
      const params: RailsUploadParams<T> = {data: this.form.value};
      if (this.recordData && this.recordData.parent) {
        params.parentId = this.recordData.parent.id;
      }
      if (this.record.id) {
        params.recordId = this.record.id;
        this.service.updateRecordMultiPart(params).subscribe(
          (result) => {
            this.record = result;
            this.successfulSave(result);
          },
          (error) => {
            this.showErrorsAndHide(error.error);
          },
        );
      } else {
        this.service.createRecordMultiPart({data: this.form.value}).subscribe(
          (result) => {
            this.record = result;
            this.successfulSave(result);
          },
          (error) => {
            this.showErrorsAndHide(error.error);
          });
      }
    }
  }
}
