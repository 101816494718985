<mat-toolbar class="header-toolbar">
  <mat-toolbar-row>
    <div class="start section">
      <ng-container *ngIf="layoutTracking.isHandset$ | async">
        <ng-container *ngIf="showMenu else menuToggle">
          <button mat-icon-button color="primary" (click)="closeMenu()" *ngIf="showMenu">
            <mat-icon>{{ gaiaIconSet.hide_menu }}</mat-icon>
          </button>
        </ng-container>
        <ng-template #menuToggle>
          <button mat-icon-button color="primary" (click)="openMenu()">
            <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
          </button>
        </ng-template>
      </ng-container>
    </div>

    <div class="middle section">
      <app-universal-search-tool></app-universal-search-tool>
    </div>

    <div class="end section">
      <app-internal-notes-tool></app-internal-notes-tool>
      <button mat-icon-button color="primary"
              matTooltip="Staff Directory" i18n-matTooltip="link to staff list page"
              [routerLink]="[appRoutes.SYSTEM_ADMIN, systemAdminRoutes.STAFF_MEMBERS, staffMemberRoutes.DIRECTORY]">
        <mat-icon>{{ gaiaIconSet.directory }}</mat-icon>
      </button>
      <button mat-icon-button color="primary"
              matTooltip="My Details" i18n-matTooltip="Link to users details page"
              [routerLink]="[appRoutes.SYSTEM_ADMIN, systemAdminRoutes.STAFF_MEMBERS, staffMemberRoutes.MY_RECORD]">
        <mat-icon>{{ gaiaIconSet.my_record }}</mat-icon>
      </button>
      <button mat-icon-button color="primary"
              matTooltip="Settings (coming soon...)" i18n-matTooltip>
        <mat-icon>{{ gaiaIconSet.settings }}</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="logout()"
              matTooltip="Logout" i18n-matTooltip>
        <mat-icon>{{ gaiaIconSet.logout }}</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
