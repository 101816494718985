import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoPriceInputComponent } from './auto-price-input/auto-price-input.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import { AutoPriceOverrideInputComponent } from './auto-price-override-input/auto-price-override-input.component';

@NgModule({
  declarations: [
    AutoPriceInputComponent,
    AutoPriceOverrideInputComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatInputModule,
  ],
  exports: [
    AutoPriceInputComponent,
    AutoPriceOverrideInputComponent
  ]

})
export class AutoPriceModule { }
