import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecificationChecksEmbedFormComponent } from './specification-checks-embed-form/specification-checks-embed-form.component';
import {MatTableModule} from '@angular/material/table';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FlexModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    SpecificationChecksEmbedFormComponent
  ],
  exports: [
    SpecificationChecksEmbedFormComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    FlexModule
  ]
})
export class SpecificationCheckModule { }
