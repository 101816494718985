import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GaiaIconSet} from '../models/gaia-icon-set';

@Directive()
export abstract class BadgePanelDirective<T> implements OnChanges {

  gaiaIconSet = GaiaIconSet;

  @Input()
  record: T;

  @Input()
  drawBackground = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.record) {
      if (changes.record.currentValue){
        this.recordChanged();
      }
    }
  }

  recordChanged(){
    // Override me if needed
  }

}
