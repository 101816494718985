import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MonthRecord, MonthYearSelection} from '../calendar.model';
import moment from 'moment/moment';
import {yearArray} from '../../../utils/common-functions';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';


@Component({
  selector: 'app-month-year-selector',
  templateUrl: './month-year-selector.component.html',
  styleUrls: ['./month-year-selector.component.scss']
})
export class MonthYearSelectorComponent implements OnInit, OnChanges {

  monthList: Array<MonthRecord> = Array.from<number, MonthRecord>({length: 12}, (_, i) => {
    const date = moment().set('month', i);
    return { numeric: i + 1, name: date.format('MMM') }
  });
  yearList: Array<number>;

  @Input()
  yearStart = 2016;

  @Input()
  selectedYear = new Date().getFullYear();

  @Input()
  selectedMonth = new Date().getMonth() + 1; // Stupid zero indexed months!

  @Output()
  selectionChanged = new EventEmitter<MonthYearSelection>();

  selectorForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.yearList = yearArray(this.yearStart);
    this.selectorForm = this.formBuilder.group({
      year: new FormControl(this.selectedYear),
      month: new FormControl(this.selectedMonth),
    });
    this.selectorForm.valueChanges.subscribe(() => {
      this.selectionChanged.emit(this.selectorForm.value);
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.yearStart) {
      if (changes.yearStart.currentValue != changes.yearStart.previousValue) {
        this.yearList = yearArray(this.yearStart);
      }
    }
    if (changes.selectedMonth || changes.selectedYear) {
      const update = {
        year: this.selectedYear,
        month: this.selectedMonth,
      };
      this.selectorForm.patchValue(update);
    }
  }

}
