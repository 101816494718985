import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewMapComponent } from './overview-map/overview-map.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatIconModule} from '@angular/material/icon';
import {ContractedFieldsModule} from '../growing/contracted-fields/contracted-fields.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PlantingOpportunitiesModule} from '../growing/planting-opportunities/planting-opportunities.module';
import {ProcessorsModule} from '../operations/processors/processors.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {NgxEchartsModule} from 'ngx-echarts';
import {MiscanthusStacksModule} from '../operations/miscanthus-stacks/miscanthus-stacks.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@NgModule({
  declarations: [
    OverviewMapComponent,
  ],
    imports: [
        CommonModule,
        GoogleMapsModule,
        FlexLayoutModule,
        MatCardModule,
        MatFormFieldModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatRadioModule,
        MatIconModule,
        PhanesComponentsModule,
        ContractedFieldsModule,
        MatTooltipModule,
        PlantingOpportunitiesModule,
        ProcessorsModule,
        MatDialogModule,
        MatButtonModule,
        MatDividerModule,
        NgxEchartsModule,
        MiscanthusStacksModule,
        MatButtonToggleModule,
    ],
  exports: [
    OverviewMapComponent,
  ]
})
export class DashboardModule { }
