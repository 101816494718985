import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {DroneImage} from './drone-image.model';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {ContractedField} from '../contracted-fields/contracted-field.model';

@Injectable({
  providedIn: 'root'
})
export class DroneImagesService extends RestApiService<DroneImage> {

  constructor(httpClient: HttpClient) {
    super('/drone_images', 'drone_image', httpClient);
  }

  getImageUrl(droneImage: DroneImage, imageName: string): Observable<string> {
    return this.httpGet<GetImage>(this.generateUrl({ recordId: droneImage.id }, '/get_image_url'),
                                { image_name: imageName }).pipe(
      map((response) => {
        return response.secure_url;
      })
    );
  }

  downloadKML(completed): Subscription {
    return this.httpClient.get(this.generateNonRecordUrl('download_kml'),
      {withCredentials: true, responseType: 'arraybuffer'})
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/vnd.google-earth.kml+xml'});
          FileSaver.saveAs(file, `terravesta_drone_images.kml`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  releaseHH(release: boolean, droneImageId) :Observable<DroneImage> {
    return this.httpPost(this.generateUrl({ recordId: droneImageId}, 'release_on_harvest_hub'), {release});
  }
}

interface GetImage {
  secure_url: string;
}
