import {AfterViewInit, Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ControlValueAccessorConnectorDirective} from '@terravesta/phanes';
import EditorJS, {API, BlockAPI, OutputData} from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import {ImagesService} from '../../system-resources/images/images.service';
import {debounceTime, map} from 'rxjs/operators';
import {GaiaLogger} from '../../../utils/common-functions';
import {BehaviorSubject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {
  ImageGalleryDialogComponent,
  ImageGallerySelectData
} from '../../system-resources/images/image-gallery-dialog/image-gallery-dialog.component';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditorComponent),
      multi: true
    }
  ]
})
export class TextEditorComponent extends ControlValueAccessorConnectorDirective implements AfterViewInit, OnInit {

  @ViewChild('editorJs') el: ElementRef;

  @Input() label;

  editor: EditorJS;

  changeHappened = new BehaviorSubject(false);

  constructor(
    inj: Injector,
    private imagesService: ImagesService,
    private matDialog: MatDialog,
  ) {
    super(inj);
  }

  ngOnInit(){
    console.log(this.formControlDirective);
    this.changeHappened.pipe(debounceTime(500)).subscribe(
      (state) => {
        if (state) {
          this.saveData();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    const parent = this;
    let currentData = {} as OutputData;
    if (this.control.value) {
      try {
        currentData = JSON.parse(this.control.value);
      }catch (e) {
        currentData = {} as OutputData;
      }
    }
    GaiaLogger.log(currentData);
    // @ts-ignore
    this.editor = new EditorJS({
      data: currentData,
      holder: this.el.nativeElement,
      tools: {
        header: Header,
        list: List,
        /*table: {
          class: Table,
        },*/
        image: {
          class: ImageTool,
          config: {
            actions: [
              {
                name: 'load_gallery',
                icon: '<mat-icon>search</mat-icon>',
                title: 'From Gallery',
                action: () => { this.openGallery(); },
              }
            ],
            uploader: {
              uploadByFile: (file: File) => {
                return this.uploadImage(file);
              }
            }
          }
        }
      },
      onChange(api: API, event) {
        parent.changeHappened.next(true);
      }
    });
  }

  private getCurrentBlockIndex(): number{
    return this.editor.blocks.getCurrentBlockIndex();
  }

  private openGallery() {
    // Need to get index before dialog as we can lose focus in the dialog
    const currentBlockIndex = this.getCurrentBlockIndex();
    const dialogRef = this.matDialog.open<ImageGalleryDialogComponent, any, ImageGallerySelectData>(ImageGalleryDialogComponent);
    dialogRef.afterClosed().subscribe(
      (response) => {
        if (response){
          const block = this.editor.blocks.getBlockByIndex(currentBlockIndex);
          if (block) {
            const id = block.id;
            this.editor.blocks.update(id, { file: {url: response.image.large_url}});
          }
        }
      }
    );
  }

  private saveData() {
    this.editor.save().then((response) => {
      this.control.patchValue(JSON.stringify(response));
      this.control.markAsDirty();
      console.log(this.control);
    });
  }

  private uploadImage(file) {
    const uploadData = {
      name: file.name,
      new_version: file,
      public: true,
      // Add option for public flag
    };
    // @ts-ignore
    return this.imagesService.createRecordMultiPart({data: uploadData}).pipe(map(
      (record) => {
        GaiaLogger.log(record);
        return {
          success: 1,
          file: {
            url: record.large_url,
            id: record.id,
            public: true,
            name: record.name
            // Add data for public URL if needed
          }
        };
      })).toPromise();
  }

}
