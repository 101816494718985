import { Injectable } from '@angular/core';
import {StaffExpenseClaim} from './staff-expense-claim.model';
import {HttpClient} from '@angular/common/http';
import {processRequiresApprovalEnum} from '../team-space-concerns/requires-approval/requires-approval.model';
import {StaffClaimsService} from '../team-space-concerns/staff-claims/staff-claims.service';

@Injectable({
  providedIn: 'root'
})
export class StaffExpenseClaimsService extends StaffClaimsService<StaffExpenseClaim> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('staff_expense_claims', 'staff_expense_claim', httpClient, StaffExpenseClaimsService.processExpenseClaim);
  }

  public static processExpenseClaim(expenseClaim: StaffExpenseClaim) {
    processRequiresApprovalEnum(expenseClaim);
  }
}
