import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegacyFieldHarvestTableComponent } from './legacy-field-harvest-table/legacy-field-harvest-table.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {MiscanthusVarietiesModule} from '../../../growing/miscanthus-varieties/miscanthus-varieties.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



@NgModule({
  declarations: [LegacyFieldHarvestTableComponent],
  imports: [
    CommonModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    GaiaGeneralModule,
    MiscanthusVarietiesModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSlideToggleModule
  ]
})
export class LegacyFieldHarvestsModule { }
