import {AfterViewInit, Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GaiaRecord} from '../models/gaia-record.model';
import {GaiaIconSet} from '../models/gaia-icon-set';

@Directive({})
export abstract class RecordFormDirective<T extends GaiaRecord> implements OnChanges, AfterViewInit {

  gaiaIconSet = GaiaIconSet;

  @Input()
  form: FormGroup;

  @Input()
  record: T;

  public constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form) {
      // GaiaLogger.log(changes.form);
      if (!changes.form.isFirstChange()) {
        this.formUpdated();
      }
      /*
      this.form.statusChanges.subscribe((status) => {
        GaiaLogger.log(status, this.form.errors, this.form.invalid);
      });
       */
    }
  }

  formUpdated() {

  }

  ngAfterViewInit(): void {
    this.formUpdated();
  }

}

