import { Component, OnInit } from '@angular/core';
import {AppRoutes} from '../../app-routing.module';
import {GrowingRoutes} from '../growing-routing.module';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {ContractedFieldsRoutes} from '../contracted-fields/contracted-fields-routing.module';
import {DroneImageRoutes} from '../drone-images/drone-image-routing.module';

@Component({
  selector: 'app-growing-menu',
  templateUrl: './growing-menu.component.html',
  styleUrls: ['./growing-menu.component.scss']
})
export class GrowingMenuComponent implements OnInit {

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  gaiaIcons = GaiaIconSet;
  contractedFieldRoutes = ContractedFieldsRoutes;
  droneImageRoutes = DroneImageRoutes;

  constructor() { }

  ngOnInit(): void {
  }

}
