import { Component, OnInit } from '@angular/core';
import {ProcessorsService} from '../processors.service';
import {ProcessorFormControlService} from '../processor-form-control.service';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {NewDialogDirective} from '../../../components/new-dialog.directive';
import {Processor} from '../processor.model';
import {AppRoutes} from '../../../app-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';

@Component({
  selector: 'app-processor-new-dialog',
  templateUrl: './processor-new-dialog.component.html',
  styleUrls: ['./processor-new-dialog.component.scss']
})
export class ProcessorNewDialogComponent extends NewDialogDirective<Processor> {

  constructor(
    processorsService: ProcessorsService,
    processorsFormControl: ProcessorFormControlService,
    private router: Router,
    public matDialog: MatDialogRef<ProcessorNewDialogComponent>,
  ) {
    super(processorsService, processorsFormControl);
  }

  successfulSave(result: Processor) {
    this.router.navigate([AppRoutes.OPERATIONS, OperationsRoutes.PROCESSORS, result.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
