<!--<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.drone_images}}</mat-icon>
    <mat-card-title i18n>Drone Images</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="row" fxLayoutGap="10px">

      <div class="selector">

          <mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="date_taken">
              <mat-header-cell *matHeaderCellDef i18n>Date Taken</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-button class="drone-btn" color="accent" (click)="showDroneImage(element)">
                  {{ element.date_taken | date: 'shortDate'}}
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

          </mat-table>

        <mat-paginator [length]="dataSource.totalRecords" [pageSize]="10"></mat-paginator>
      </div>

      <div class="viewer" fxFlex>
        <app-drone-image-view [selectedImage]="selectedImage"></app-drone-image-view>
      </div>

    </div>

  </mat-card-content>
</mat-card>-->

<ng-container style="">
  <div fxLayoutGap="10px" [formGroup]="fieldList">
    <button mat-raised-button color="primary" type="button" i18n (click)="newDroneImage()">New Drone Image</button>
  <!--    <mat-form-field>-->
  <!--      <mat-label for="fields">-->
  <!--        Fields-->
  <!--      </mat-label>-->
  <!--      <mat-select formControlName="field_name" (selectionChange)="redirectToNewField($event)">-->
  <!--        <mat-option *ngFor="let field of this.dropdownFieldNames" [value]="field">-->
  <!--          {{ field }}-->
  <!--        </mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  </div>
  <div class="form-container" [formGroup]="fieldList" fxLayoutGap="10px" fxLayoutAlign="space-evenly start">
      <mat-card class="form-card" fxFlex="1 1 280px" fxLayout="row">
        <mat-card-content>
          <mat-form-field appearance="outline" id="date_taken">
            <mat-label for="dates">
              Dates
            </mat-label>
            <mat-select formControlName="date_taken" (selectionChange)="fieldAndDateChosen()" required>
              <mat-option *ngFor="let date of this.dateTaken" [value]="date">
                {{ date }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button *ngIf="showVariety" class="mat-stroked-button" disabled>
            <mat-icon color="green">grass</mat-icon>{{ this.miscanthusVariety }}
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card class="form-card" fxFlex="1 1 auto" fxLayout="column" >
        <mgl-map
          [style]="'mapbox://styles/michaelsterravesta/cleo7aas3002p01mrbmj843vt'"
          [zoom]="[15]"
          [center]="mapCenter">
          <mgl-raster-source *ngIf="urlString" id="droneImage" [url]="urlString"></mgl-raster-source>
          <mgl-layer *ngIf="urlString" id="droneLayer" type="raster" source="droneImage" ></mgl-layer>
          <mgl-control mglNavigation></mgl-control>
        </mgl-map>
      </mat-card>
    </div>

</ng-container>
