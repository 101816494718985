import {Component, forwardRef, Injector} from '@angular/core';
import {RecordListSelectDirective} from '@terravesta/phanes';
import {CompanyOffice} from '../company-office.model';
import {CompanyOfficesService} from '../company-offices.service';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-company-office-select',
  templateUrl: './company-office-select.component.html',
  styleUrls: ['./company-office-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyOfficeSelectComponent),
      multi: true
    }
  ]
})
export class CompanyOfficeSelectComponent extends RecordListSelectDirective<CompanyOffice> {

  constructor(
    companyOfficesService: CompanyOfficesService,
    inj: Injector,
  ) {
    super(companyOfficesService, inj);
  }

}
