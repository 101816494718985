import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HaulierFinderComponent} from './haulier-finder/haulier-finder.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {HaulierCardComponent} from './haulier-card/haulier-card.component';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [HaulierFinderComponent, HaulierCardComponent],
  exports: [
    HaulierFinderComponent,
    HaulierCardComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    FlexModule
  ]
})
export class HaulierSharedComponentsModule { }
