
<button mat-mini-fab (click)="addSpecification()">
  <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
</button>

<div [formGroup]="control">
  <div *ngFor="let row of formArray.controls; let i = index;" [formArrayName]="i" class="specification-row">

    <mat-form-field>
      <mat-label>Min Value</mat-label>
      <input matInput type="number" formControlName="min_value" />
      <span *ngIf="specificationUnits" matSuffix>{{ specificationUnits }}</span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max Value</mat-label>
      <input matInput type="number" formControlName="max_value" />
      <span *ngIf="specificationUnits" matSuffix>{{ specificationUnits }}</span>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Adjustment</mat-label>
      <input matInput type="number" formControlName="value_adjustment" />
      <span matPrefix>&pound;</span>
    </mat-form-field>

    <mat-form-field class="long">
      <mat-label>Adjustment Description</mat-label>
      <input matInput formControlName="value_adjustment_description" />
    </mat-form-field>

    <mat-checkbox formControlName="to_delete">Delete?</mat-checkbox>

  </div>
</div>
