<div class="field_harvests">

  <app-index-header [hideAdd]="false" [hideSearch]="false"
      pageTitle="Legacy Harvests" i18n-pageTitle="page title for legacy harvests page">

    <mat-slide-toggle [formControl]="checkedFilter" (change)="updateTable()">Verified Only</mat-slide-toggle>
    <app-miscanthus-variety-select [formControl]="varietyFilter" (selectionChange)="updateTable()"></app-miscanthus-variety-select>

  </app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="grower_code">
      <mat-header-cell *matHeaderCellDef i18n>Grower Code </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.grower.grower_code}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_name">
      <mat-header-cell *matHeaderCellDef i18n> Field Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.contracted_field.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="planted">
      <mat-header-cell *matHeaderCellDef i18n>Planted</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.contracted_field.year_planted}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="checked">
      <mat-header-cell *matHeaderCellDef i18n>Checked?</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.harvest">
          <mat-icon *ngIf="element.harvest.verified">{{ gaiaIconSet.verified }}</mat-icon>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="bale_count">
      <mat-header-cell *matHeaderCellDef i18n> Bale Count </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.bale_count}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="area_harvested">
      <mat-header-cell *matHeaderCellDef i18n> Area Harvested </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.area_harvested}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="act_tonnage_per_ha">
      <mat-header-cell *matHeaderCellDef i18n> Act. Tonnage per Ha</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.actual_tonnage_per_ha | number:'1.2'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="variety">
      <mat-header-cell *matHeaderCellDef i18n>Variety</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.contracted_field.planted_variety ? element.contracted_field.planted_variety.name : ''}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSize]="dataSource.pageSize"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
