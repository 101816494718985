<app-new-record-dialog [form]="form" dialogTitle="Soil Measurement" i18n-dialogTitle (saveClicked)="save()" [dialogRef]="matDialog">

  <ng-container *ngIf="form" [formGroup]="form">

    <phanes-error-panel></phanes-error-panel>

    <app-soil-measurement-form [form]="form" [record]="record"></app-soil-measurement-form>

  </ng-container>
</app-new-record-dialog>
