<mat-form-field appearance="fill" fxFlex="100%">
  <mat-label i18n>Search by surname or email</mat-label>
  <input matInput type="text"
         [formControl]="contactSearch"
         i18n-placeholder
         placeholder="To search begin typing"
         [matAutocomplete]="auto"/>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="contactChosen($event)" [panelWidth]="'45%'">
  <mat-select-trigger>
    <span *ngIf="selectedContact">{{ selectedContact.email }}</span>
  </mat-select-trigger>
  <mat-option *ngFor="let contact of contactMatches | async" [value]="contact">
    <div>{{ contact.first_name }} {{ contact.surname }} - {{ contact.email }}</div>
  </mat-option>
</mat-autocomplete>
