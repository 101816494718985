<ng-container *ngIf="form" [formGroup]="form">

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>shopping_cart</mat-icon>
      <mat-card-title i18n>Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label i18n>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-slide-toggle formControlName="active" i18n>Active List?</mat-slide-toggle>
      <mat-slide-toggle formControlName="default_group_contact" i18n>Default Price List (Direct)?</mat-slide-toggle>
      <mat-slide-toggle formControlName="default_group_stockist" i18n>Default Price List (Stockist)?</mat-slide-toggle>
      <mat-slide-toggle formControlName="default_group_brand_ambassador" i18n>Default Price List (BA)?</mat-slide-toggle>
      <mat-slide-toggle formControlName="default_group_commercial_user" i18n>Default Price List (Commercial)?</mat-slide-toggle>

    </mat-card-content>
  </mat-card>

  <ng-container *ngIf="!newForm">
    <button mat-fab color="primary" type="button" aria-label="" (click)="addProduct()">
      <mat-icon>add</mat-icon>
    </button>

    <div class="products">
      <ng-container *ngFor="let productControl of $any(form.get('products')).controls">
        <app-price-group-product-form [formGroup]="productControl"></app-price-group-product-form>
      </ng-container>
    </div>

  </ng-container>
</ng-container>
