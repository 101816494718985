import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardContainerDirective} from './dashboard-container.directive';
import {UserDashboardComponent} from './user-dashboard/user-dashboard.component';
import {MatCardModule} from '@angular/material/card';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { AddWidgetDialogComponent } from './add-widget-dialog/add-widget-dialog.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    DashboardContainerDirective,
    UserDashboardComponent,
    AddWidgetDialogComponent,
  ],
    imports: [
        CommonModule,
        MatCardModule,
        FlexModule,
        MatButtonModule,
        MatIconModule,
        GaiaSharedModule,
        MatDialogModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatTooltipModule,
        DragDropModule
    ],
    exports: [
        UserDashboardComponent,
        DashboardContainerDirective
    ]
})
export class DashboardComponentsModule { }
