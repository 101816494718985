import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutTrackingService {

  orientation = new BehaviorSubject<string>(ScreenLayoutOrientation.LANDSCAPE);
  deviceType = new BehaviorSubject<string>(DeviceType.SCREEN);
  showTopMenu = new BehaviorSubject<boolean>(true);
  showSideMenu = new BehaviorSubject<boolean>(true);
  fullscreenMode = new BehaviorSubject<boolean>(false);

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(map((result: BreakpointState) => result.matches));

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Auto reset the settings that components can request on the layout, in case component forgot to reset
        this.showTopMenu.next(true);
        this.showSideMenu.next(true);
        this.fullscreenMode.next(false);
      }
    });
    this.breakpointObserver.observe([Breakpoints.Web, Breakpoints.Handset, Breakpoints.Tablet]).subscribe( () => {
      if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
        this.deviceType.next(DeviceType.SCREEN);
      }
      else if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
        this.deviceType.next(DeviceType.MOBILE);
      }
      else if (this.breakpointObserver.isMatched(Breakpoints.Tablet)) {
        this.deviceType.next(DeviceType.TABLET);
      }
    });
    this.breakpointObserver.observe([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape]).subscribe(
      () => {
        this.orientation.next(ScreenLayoutOrientation.LANDSCAPE);
      }
    );
    this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait]).subscribe(
      () => {
        this.orientation.next(ScreenLayoutOrientation.PORTRAIT);
      }
    );
  }
}

export class ScreenLayoutOrientation {
  public static LANDSCAPE = 'landscape';
  public static PORTRAIT = 'portrait';
}
export class DeviceType {
  public static SCREEN = 'screen';
  public static TABLET = 'tablet';
  public static MOBILE = 'mobile';
}
