import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InternalNoteTrackingService} from './internal-note-tracking.service';
import { InternalNotesToolComponent } from './internal-notes-tool/internal-notes-tool.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { InternalNotesPopupComponent } from './internal-notes-popup/internal-notes-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import { InternalNoteFormDialogComponent } from './internal-note-form-dialog/internal-note-form-dialog.component';
import {GaiaGeneralModule} from '../gaia-general/gaia-general.module';
import {ReactiveFormsModule} from '@angular/forms';
import { InternalNoteCardComponent } from './internal-note-card/internal-note-card.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatBadgeModule} from '@angular/material/badge';
import { InternalNotesColumnComponent } from './internal-notes-column/internal-notes-column.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {EditorJsModule} from '@terravesta/phanes';
import {InternalNotesEmbedToolComponent} from './internal-notes-embed-tool/internal-notes-embed-tool.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {DocumentsModule} from "../system-resources/documents/documents.module";
import {RouterModule} from "@angular/router";
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [InternalNotesToolComponent, InternalNotesPopupComponent, InternalNoteFormDialogComponent,
    InternalNoteCardComponent, InternalNotesColumnComponent, InternalNotesEmbedToolComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatToolbarModule,
        FlexModule,
        MatCardModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        MatExpansionModule,
        EditorJsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        DocumentsModule,
        RouterModule,
        MatTooltipModule
    ],
  exports: [
    InternalNotesToolComponent,
    InternalNotesEmbedToolComponent,
    InternalNotesColumnComponent
  ],
  providers: [
    InternalNoteTrackingService
  ]
})
export class InternalNotesModule { }
