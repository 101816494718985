<ng-container *ngIf="form" [formGroup]="form">

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>{{gaiaIcons.brand_ambassadors}}</mat-icon>
      <mat-card-title i18n>BA Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field>
        <mat-label i18n>Account Name</mat-label>
        <input matInput formControlName="account_name" type="text" />
      </mat-form-field>

      <app-price-group-select [formGroup]="form" [record]="brandAmbassador" [controlName]="'default_price_group_id'"></app-price-group-select>

      <mat-slide-toggle formControlName="on_account" i18n>On Account?</mat-slide-toggle>

      <mat-form-field *ngIf="this.form.get('on_account').value">
        <mat-label i18n>Payment Term (days)</mat-label>
        <input matInput formControlName="payment_term_days" type="number" step="1" />
      </mat-form-field>

    </mat-card-content>
  </mat-card>
</ng-container>

