import { Injectable } from '@angular/core';
import {RestApiService} from '../services/rest-api.service';
import {Contact, ContactForAccount, ContactUniversalSearchPreview} from './contact.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PhanesNode, RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';
import {GaiaRecord} from '../models/gaia-record.model';
import {GaiaLogger} from '../../utils/common-functions';
import {AccountForContact} from './account.model';
import {UniversallySearchableService} from '../universal-search/universally-searchable.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends RestApiService<Contact> implements UniversallySearchableService<ContactUniversalSearchPreview> {

  constructor(httpClient: HttpClient) {
    super('contacts', 'contact', httpClient);
  }

  forAccount(record: GaiaRecord, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<ContactForAccount>> {
    return this.httpGet<RailsAPIWrapper<ContactForAccount>>(this.generateNonRecordUrl('for_account'),
      {...this.processServerParams(params), account_id: record.id}).pipe(map((response) => {
        GaiaLogger.log(response);
        return response;
    }));
  }

  forContactAccount(record: string): Observable<Array<AccountForContact>> {
    return this.httpGet<Array<AccountForContact>>(this.generateUrl({recordId: record}, 'for_contact_account')).pipe
    (map((response) =>
    {
      GaiaLogger.log(response);
      return response;
    }));
  }

  checkForEmail(email: string): Observable<Contact|boolean> {
    return this.httpGet<object>(this.generateNonRecordUrl('check_for_email_match'),
      {email}).pipe(map(
      (response) => {
        if ('no_match' in response){
          return false;
        }else{
          return response as Contact;
        }
      }
    ));
  }

  grantAccessTo(contact: Contact, account: PhanesNode): Observable<null> {
    return this.httpPost(this.generateUrl({recordId: contact.id}, 'grant_access_to'), {account_id: account.id});
  }

  setAdminStatus(contact: Contact, account: PhanesNode, adminRights: boolean): Observable<null> {
    return this.httpPost(this.generateUrl({recordId: contact.id}, 'change_admin_status'),
      {account_id: account.id, can_admin: adminRights});
  }

  resendInvite(contact: Contact, account: PhanesNode): Observable<null> {
    return this.httpPost(this.generateUrl({recordId: contact.id}, 'resend_invite'), {account_id: account.id});
  }

  revokeAccess(contact: Contact, account: PhanesNode): Observable<{ revoked: boolean }>{
    return this.httpPost(this.generateUrl({recordId: contact.id}, 'revoke_access_to'), {account_id: account.id});
  }

  unlinkFromAccount(contact: Contact, account: PhanesNode): Observable<{ result: boolean }> {
    return this.httpPost(this.generateUrl({recordId: contact.id}, 'unlink_from_account'), {account_id: account.id});
  }

  universalSearchPreviewData(recordId: string): Observable<ContactUniversalSearchPreview> {
    return this.httpGet(this.generateUrl({ recordId }, 'universal_search_preview_data'));
  }
}
