<mat-card>

  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.stockists}}</mat-icon>
    <mat-card-title>Stockist</mat-card-title>

  </mat-card-header>

  <mat-card-content *ngIf="stockist" fxLayout="column">
    <mat-list>
      <mat-list-item>
        <mat-icon matListIcon>domain</mat-icon>
        {{stockist.account_name}}
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListIcon>email</mat-icon>
        {{stockist.billing_email}}
      </mat-list-item>
      <mat-list-item *ngIf="stockist.on_account">
        <mat-icon matListIcon>payment</mat-icon>
        <span i18n>Payment Term:</span>&nbsp;{{stockist.payment_term_days}}&nbsp;<span i18n="day count">Days</span>
      </mat-list-item>
    </mat-list>
    <div class="address-row" fxLayout="row">
      <div fxFlex="50">
        <div fxLayout="row">
          <h2>Billing</h2>
          <button mat-icon-button *ngIf="editable" color="primary" type="button" (click)="copyAddress.emit(stockist.billing_address)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <gaia-shared-address-display [address]="stockist.shipping_address"></gaia-shared-address-display>
      </div>
      <div fxFlex="50">
        <div fxLayout="row">
          <h2>Shipping</h2>
          <button mat-icon-button *ngIf="editable" color="primary" type="button" (click)="copyAddress.emit(stockist.shipping_address)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <gaia-shared-address-display [address]="stockist.billing_address"></gaia-shared-address-display>
      </div>
    </div>

  </mat-card-content>

  <mat-card-actions align="start">
    <button mat-icon-button color="primary" type="button" aria-label="" *ngIf="editable" (click)="cancelClicked()"></button>
  </mat-card-actions>

</mat-card>
