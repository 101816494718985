import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {BaleLoss} from './bale-loss.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BaleLossFormControlService implements GaiaRecordFormControl<BaleLoss> {

  constructor(
    private builder: FormBuilder,
  ) { }

  toFormGroup(record: BaleLoss): FormGroup {
    return this.builder.group({
      reason: new FormControl(record.reason, Validators.required),
      date_of_loss: new FormControl(record.date_of_loss, Validators.required),
      bales_lost: new FormControl(record.bales_lost, Validators.required),
    });
  }
}
