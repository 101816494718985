import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import moment from 'moment/moment';
import {MonthRecord} from '../../../restful-calendar/calendar.model';
import {MiscanthusStacksService} from '../miscanthus-stacks.service';
import {MiscanthusStack, MonthSummaries} from '../miscanthus-stack.model';
import {GaiaLogger} from '../../../../utils/common-functions';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {AppRoutes} from '../../../app-routing.module';
import {OperationsRoutes} from '../../operations-routing.module';
import {MatMenuTrigger} from '@angular/material/menu';
import {interval, Subscription} from 'rxjs';
import {MatTable} from '@angular/material/table';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';

interface StackList {
  monthNumber: number;
  stacks: Array<MiscanthusStack>;
  lastLoaded: moment.Moment;
}

@Component({
  selector: 'app-miscanthus-stacks-daily-ops-report',
  templateUrl: './miscanthus-stacks-daily-ops-report.component.html',
  styleUrls: ['./miscanthus-stacks-daily-ops-report.component.scss']
})
export class MiscanthusStacksDailyOpsReportComponent implements OnInit, OnDestroy {

  RELOAD_TIME = 60 * 5; //5 minutes

  gaiaIconSet = GaiaIconSet;
  operationsRoutes = OperationsRoutes;
  appRoutes = AppRoutes;

  displayedColumns = ['menu', 'notes_system', 'grower_code', 'grower_account_name',
                      'post_code', 'stack_completed',
                      'processor', 'miles_to_processor',
                      'collection_month', 'est_tonnage',
                      'contact_name', 'contact_phone',
                      'bale_type', 'declared_bale_count', 'collected_bale_count', 'remaining_bale_count',
                      'storage_method',
                      'notice_period', 'bale_grab_availability', 'loader_availability',
                      'location_description', 'access_restrictions', 'notes'
                      ];
  months = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
  monthList: Array<MonthRecord> = this.months.map((i) => {
    const date = moment().set('month', i - 1); // Zero indexed months
    return { numeric: i, name: date.format('MMMM') }
  });

  @ViewChildren(MatTable)
  tables: QueryList<MatTable<any>>;

  currentYear: number;
  currentMonth = new Date().getMonth() + 1;

  summary: MonthSummaries;

  stackLists: Array<StackList>;
  menuTopLeftPosition = { x: '0px', y: '0px' };

  @ViewChild(MatMenuTrigger, {static: true})
  matMenuTrigger: MatMenuTrigger;
  destination: Array<string>;

  @ViewChild(SpinnerLoadingButtonComponent)
  spinnerLoadingBtn: SpinnerLoadingButtonComponent;
  // mileageReportBtn: SpinnerLoadingButtonComponent;
  // @ViewChild(SpinnerLoadingButtonComponent)
  // powerStationStackReportBtn: SpinnerLoadingButtonComponent;

  reloadTracker: Subscription;
  thisYear = new Date().getFullYear();

  constructor(
    private stacksService: MiscanthusStacksService,
  ) { }

  ngOnInit(): void {
    this.stackLists = new Array<StackList>();
    this.months.forEach((month) => {
      this.stackLists.push({
        monthNumber: month,
        stacks: new Array<MiscanthusStack>(),
        lastLoaded: null,
      });
    });
    this.reloadTracker = interval(1000 * 30).subscribe(() => {
      GaiaLogger.log('Checking for reloads');
      const testDate = moment();
      this.stackLists.forEach((record) => {
        if(record.lastLoaded){
          if ((testDate.diff(record.lastLoaded, 'seconds')) > this.RELOAD_TIME){
            GaiaLogger.log('reloading month', record.monthNumber);
            this.loadMonthData(record.monthNumber);
          }
        }
      });
    })
  }

  ngOnDestroy() {
    this.reloadTracker.unsubscribe();
  }

  loadMonthData(monthNumber: number) {
    this.stacksService.getRecords({
      pageNumber: 0,
      pageSize: 300,
      search: {
        harvest_year: this.currentYear,
        schedule_month: {
          month_numeric: monthNumber
        }
      }
    }).subscribe((response) => {
      this.setupMonth(monthNumber, response.records);
    });
  }

  private setupMonth(monthNumber: number, stacks: Array<MiscanthusStack>){
    const list = this.stackLists.find((record) => record.monthNumber == monthNumber);
    list.stacks = stacks.sort((a, b) => a.grower.grower_code.localeCompare(b.grower.grower_code));
    list.lastLoaded = moment();
  }

  getStackListForMonth(monthNumber: number): StackList {
    return this.stackLists.find((record) => record.monthNumber == monthNumber);
  }

  getLastLoaded(monthNumber: number): string {
    const time = this.stackLists.find((record) => record.monthNumber == monthNumber).lastLoaded;
    return time ? time.format('HH:mm') : '-';
  }

  yearChanged(year: number) {
    this.currentYear = year;
    this.stacksService.monthSummaries(this.currentYear).subscribe((response) => {
      this.summary = response;
    });
    this.loadMonthData(this.currentMonth);
  }

  openMenu(record: MiscanthusStack, event: MouseEvent) {
    event.preventDefault();

    this.destination = ['/', AppRoutes.OPERATIONS, OperationsRoutes.STACKS, record.id]

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  refreshBaleCount(stack: MiscanthusStack, month: MonthRecord) {
    const stackListIndex = this.stackLists.findIndex((record) => record.monthNumber == month.numeric);
    const stacks = this.stackLists[stackListIndex].stacks;
    this.stacksService.updateBaleCount(stack, true).subscribe((updatedStack) => {
      const index = stacks.findIndex((obj => obj.id === updatedStack.id));
      // copy stacks to new array
      const newStacks = [...stacks];
      // delete old copy of stack
      newStacks.splice(index, 1);
      // add updated stack
      newStacks.push(updatedStack);
      this.setupMonth(month.numeric, newStacks);
    });
  }

  downloadMileageReport() {
    this.stacksService.downloadStackMileageReport(this.currentYear, () => {
        alert("Report generated successfully!")
        // this.spinnerLoadingBtn.processFinished();
    });
  }

  downloadPowerStationStackReport() {
    this.stacksService.downloadPowerStationStackReport(this.currentYear, () => {
      // this.spinnerLoadingBtn.processFinished();
      alert("Report generated successfully!")
    });
  }

}
