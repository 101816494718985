import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyShowComponent } from './company-show/company-show.component';
import {CompanyRoutingModule} from './company-routing.module';
import {CompaniesService} from './companies.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CompaniesTableComponent } from './companies-table/companies-table.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { CompanyFormComponent } from './company-form/company-form.component';
import {MatCardModule} from '@angular/material/card';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import { CompanyNewDialogComponent } from './company-new-dialog/company-new-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



@NgModule({
    declarations: [CompanyShowComponent, CompaniesTableComponent, CompanyFormComponent, CompanyNewDialogComponent],
    imports: [
        CommonModule,
        CompanyRoutingModule,
        MatFormFieldModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatButtonModule,
        GaiaSharedModule,
        ReactiveFormsModule,
        MatIconModule,
        MatCardModule,
        GaiaGeneralModule,
        AddressesModule,
        MatDialogModule,
        MatSlideToggleModule,
        AddressesModule,
    ],
    exports: [
        CompanyShowComponent,
        CompaniesTableComponent,
    ],
    providers: [CompaniesService]
})
export class CompaniesModule { }
