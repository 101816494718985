
<div fxLayout="row">
  <mat-card>

    <mat-card-header>
      <mat-card-title i18n>Selected Contractors</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <ng-container [formGroup]="contractorForm" *ngIf="!grower.contractor_data_share_opt_out else optedOut">
        <div fxLayout="column">
          <app-contractor-select label="Baling Contractor" i18n-label
                                 formControlName="baling_contractor_id"></app-contractor-select>

          <app-contractor-select label="Cutting Contractor" i18n-label
                                 formControlName="cutting_contractor_id"></app-contractor-select>

          <button mat-raised-button color="primary" [disabled]="!this.contractorForm.valid"
                  (click)="save()" i18n>Update Contractor Info</button>

        </div>
      </ng-container>
      <ng-template #optedOut>Grower has opted out of the contractor share system</ng-template>
    </mat-card-content>

  </mat-card>
</div>
