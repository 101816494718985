
<div class="screen-lock" *ngIf="screenLockService.lockState | async">

  <div class="screen-lock-panel">
    <mat-card>
      <mat-card-content>
        {{ screenLockService.currentMessage }}
      </mat-card-content>
    </mat-card>
  </div>
</div>
