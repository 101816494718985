import {Component, Input, OnInit} from '@angular/core';
import {GaiaIndexDataSource} from '../../services/gaia-index-data-source';
import {GaiaRecord} from '../../models/gaia-record.model';

@Component({
  selector: 'app-table-loading-wrapper',
  templateUrl: './table-loading-wrapper.component.html',
  styleUrls: ['./table-loading-wrapper.component.scss']
})
export class TableLoadingWrapperComponent implements OnInit {

  @Input() dataSource: GaiaIndexDataSource<GaiaRecord>;

  constructor() { }

  ngOnInit(): void {
  }

}
