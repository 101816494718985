import { Component, OnInit } from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {SoilSample} from '../soil-sample.model';

@Component({
  selector: 'app-soil-sample-create-form',
  templateUrl: './soil-sample-create-form.component.html',
  styleUrls: ['./soil-sample-create-form.component.scss']
})
export class SoilSampleCreateFormComponent extends RecordFormDirective<SoilSample> implements OnInit{

  ngOnInit() {
    this.form.markAsDirty(); // Need to mark form as dirty first, to get the new record wrapper to update button
    this.form.patchValue({
      date_taken: new Date(),
    });
  }

}
