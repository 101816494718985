import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MatPaginator} from '@angular/material/paginator';
import {StackNoticePeriod} from '../stack-notice-period.model';
import {StackNoticePeriodsService} from '../stack-notice-periods.service';
import {MatDialog} from '@angular/material/dialog';
import {StackNoticePeriodFormDialogComponent} from '../stack-notice-period-form-dialog/stack-notice-period-form-dialog.component';
import {GaiaIndexDataSource} from '../../../../services/gaia-index-data-source';

@Component({
  selector: 'app-stack-notice-periods-list',
  templateUrl: './stack-notice-periods-list.component.html',
  styleUrls: ['./stack-notice-periods-list.component.scss']
})
export class StackNoticePeriodsListComponent implements OnInit, AfterViewInit {

  gaiaIcons = GaiaIconSet;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  noticePeriods: GaiaIndexDataSource<StackNoticePeriod>;
  displayedColumns = ['name', 'active'];

  constructor(
    private stackNoticePeriodsService: StackNoticePeriodsService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.noticePeriods = new GaiaIndexDataSource<StackNoticePeriod>(this.stackNoticePeriodsService, {active: 'order_weight', direction: 'asc' }, 10);
    this.noticePeriods.loadRecords();
  }

  ngAfterViewInit(): void {
    this.noticePeriods.paginator = this.paginator;
  }

  newNoticePeriod() {
    this.openDialog({} as StackNoticePeriod);
  }

  openDialog(record: StackNoticePeriod) {
    const dialogRef = this.matDialog.open(StackNoticePeriodFormDialogComponent, {
      data: {record},
    });
    dialogRef.afterClosed().subscribe(() => {
      this.noticePeriods.loadRecords();
    });
  }

  editNoticePeriod(element: StackNoticePeriod) {
    this.openDialog(element);
  }
}
