import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsTableComponent } from './contacts-table/contacts-table.component';
import {GaiaBehavioursModule, GaiaSharedModule, AddressesModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ContactRoutingModule} from './contact-routing.module';
import { ContactShowComponent } from './contact-show/contact-show.component';
import {ReactiveFormsModule} from '@angular/forms';
import {GaiaGeneralModule} from '../gaia-general/gaia-general.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ContactCardComponent } from './contact-card/contact-card.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { ContactFormComponent } from './contact-form/contact-form.component';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ContactNewDialogComponent } from './contact-new-dialog/contact-new-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ContactListEmbedComponent } from './contact-list-embed/contact-list-embed.component';
import { ContactsSettingsComponent } from './contacts-settings/contacts-settings.component';
import {ContactTypesModule} from './contact-types/contact-types.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ContactAccountDialogComponent } from './contact-account-dialog/contact-account-dialog.component';
import { ContactFormSimpleComponent } from './contact-form-simple/contact-form-simple.component';
import {AccountContactSelectComponent} from './account-contact-select/account-contact-select.component';
import {MatSelectModule} from '@angular/material/select';
import { LeadOverviewComponent } from './lead-overview/lead-overview.component';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatTabsModule} from '@angular/material/tabs';
import { AccountListEmbedComponent } from './account-list-embed/account-list-embed.component';
import { ContactsMenuComponent } from './contacts-menu/contacts-menu.component';
import { ContactEmbedFormSimpleComponent } from './contact-embed-form-simple/contact-embed-form-simple.component';
import { ContactEmailInputComponent } from './contact-email-input/contact-email-input.component';
import { NewContactForAccountDialogComponent } from './new-contact-for-account-dialog/new-contact-for-account-dialog.component';
import { ContactSearchComponent } from './contact-search/contact-search.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSortModule} from '@angular/material/sort';
import { ContactFindOrCreateSimpleComponent } from './contact-find-or-create-simple/contact-find-or-create-simple.component';
import { ContactHoverPopupComponent } from './contact-hover-popup/contact-hover-popup.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ContactUniversalSearchResultComponent } from './contact-universal-search-result/contact-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../universal-search/universal-search-layout.module';


@NgModule({
  declarations: [ContactsTableComponent, ContactShowComponent, ContactCardComponent, ContactFormComponent, ContactNewDialogComponent,
    ContactListEmbedComponent, ContactsSettingsComponent, ContactAccountDialogComponent, ContactFormSimpleComponent,
    AccountContactSelectComponent,
    LeadOverviewComponent,
    AccountListEmbedComponent,
    ContactsMenuComponent,
    ContactEmbedFormSimpleComponent,
    ContactEmailInputComponent,
    NewContactForAccountDialogComponent,
    ContactSearchComponent,
    ContactFindOrCreateSimpleComponent,
    ContactHoverPopupComponent,
    ContactUniversalSearchResultComponent
  ],
  imports: [
    CommonModule,
    GaiaSharedModule,
    GaiaBehavioursModule,
    MatTableModule,
    MatPaginatorModule,
    ContactRoutingModule,
    ReactiveFormsModule,
    GaiaGeneralModule,
    MatFormFieldModule,
    AddressesModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    MatDialogModule,
    FlexLayoutModule,
    ContactTypesModule,
    MatMenuModule,
    MatTooltipModule,
    MatSelectModule,
    PhanesComponentsModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatSortModule,
    MatProgressSpinnerModule,
    UniversalSearchLayoutModule,
  ],
  exports: [
    ContactsTableComponent,
    ContactShowComponent,
    ContactCardComponent,
    ContactFormComponent,
    ContactListEmbedComponent,
    ContactsSettingsComponent,
    ContactFormSimpleComponent,
    AccountContactSelectComponent,
    LeadOverviewComponent,
    ContactsMenuComponent,
    ContactEmailInputComponent,
    ContactEmbedFormSimpleComponent,
    ContactSearchComponent,
    ContactFindOrCreateSimpleComponent,
    ContactHoverPopupComponent,
    ContactUniversalSearchResultComponent
  ],
})
export class ContactsModule { }
