import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DroneImageViewerComponent} from './drone-image-viewer/drone-image-viewer.component';
import {PrintLayoutComponent} from '../../gaia-general/print-layout/print-layout.component';

export class DroneImageRoutes {
  public static DRONE_IMAGE_VIEWER = 'drone-image-viewer';
  public static PRINT = 'print';
}

const routes: Routes = [
  {
    path: DroneImageRoutes.DRONE_IMAGE_VIEWER,
    component: DroneImageViewerComponent,
  },
  {
    path: DroneImageRoutes.PRINT,
    outlet: 'print',
    component: PrintLayoutComponent,
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DroneImageRoutingModule { }
