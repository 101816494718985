import {Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppRoutes} from "../../app-routing.module";
import {GaiaIconSet} from "../../models/gaia-icon-set";
import {GrowingRoutes} from "../../growing/growing-routing.module";

@Component({
  selector: 'assets-menu',
  templateUrl: './assets-menu.component.html',
  styleUrls: ['./assets-menu.component.scss']
})
export class AssetsMenuComponent implements OnInit{
  appRoute = AppRoutes;

  gaiaIcons = GaiaIconSet;

  constructor() {
  }
  ngOnInit(): void {
  }

  protected readonly GrowingRoutes = GrowingRoutes;
}
