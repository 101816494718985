import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrowerContractsTableComponent } from './grower-contracts-table/grower-contracts-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {GrowerContractsRoutingModule} from './grower-contracts-routing.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import { GrowerContractFormComponent } from './grower-contract-form/grower-contract-form.component';
import { GrowerContractShowComponent } from './grower-contract-show/grower-contract-show.component';
import { GrowerContractNewDialogComponent } from './grower-contract-new-dialog/grower-contract-new-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import { GrowerContractsTableEmbedComponent } from './grower-contracts-table-embed/grower-contracts-table-embed.component';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import { GrowerContractSelectComponent } from './grower-contract-select/grower-contract-select.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { GrowerContractDisplayCardComponent } from './grower-contract-display-card/grower-contract-display-card.component';
import {MatListModule} from '@angular/material/list';
import {
  GrowerContractsGrowerTableEmbedComponent
} from './grower-contracts-grower-table-embed/grower-contracts-grower-table-embed.component';
import {FlexModule} from '@angular/flex-layout';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';


@NgModule({
    declarations: [GrowerContractsTableComponent, GrowerContractFormComponent, GrowerContractShowComponent,
      GrowerContractNewDialogComponent, GrowerContractsTableEmbedComponent, GrowerContractSelectComponent,
      GrowerContractDisplayCardComponent,
      GrowerContractsGrowerTableEmbedComponent, ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    GrowerContractsRoutingModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    FlexModule,
    MatExpansionModule,
    MatDatepickerModule
  ],
  exports: [
    GrowerContractsGrowerTableEmbedComponent,
    GrowerContractSelectComponent,
    GrowerContractDisplayCardComponent
  ]
})
export class GrowerContractsModule { }
