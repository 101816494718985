import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {StackNoticePeriod} from './stack-notice-period.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StackNoticePeriodsService extends RestApiService<StackNoticePeriod> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/stack_notice_periods', 'stack_notice_period', httpClient);
  }
}
