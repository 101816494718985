import { Pipe, PipeTransform } from '@angular/core';
import {CONTRACT_STATUSES} from '../contracts.model';

@Pipe({
  name: 'contractStatus'
})
export class ContractStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    const values = Object.values(CONTRACT_STATUSES);
    const match = values.find((item) => item.value == value);
    let retVal: string;
    if (match) {
      retVal = match.name;
    }else {
      retVal = "Unknown Status";
    }
    return retVal;
  }

}
