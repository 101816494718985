import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {ScheduleMonth} from './schedule-month.model';
import {HttpClient} from '@angular/common/http';
import {Processor} from '../processor.model';
import {Observable} from 'rxjs';

export interface HarvestYears {
  years: Array<number>;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleMonthsService extends RestApiService<ScheduleMonth> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/processors/{parentId}/schedule_months', 'schedule_month', httpClient);
  }

  getAvailableHarvestYears(processor: Processor): Observable<HarvestYears>{
    return this.httpGet<HarvestYears>(this.generateUrl({pageNumber: 0, pageSize: 100, parentId: processor.id}, 'available_harvest_years'));
  }

}
