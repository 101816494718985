import {Component, Inject} from '@angular/core';
import {NewDialogData, NewDialogDirective} from '../../../../components/new-dialog.directive';
import {HaulierDriver} from '../haulier-driver.model';
import {HaulierDriversService} from '../haulier-drivers.service';
import {HaulierDriverFormControlService} from '../haulier-driver-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-haulier-driver-new-dialog',
  templateUrl: './haulier-driver-new-dialog.component.html',
  styleUrls: ['./haulier-driver-new-dialog.component.scss']
})
export class HaulierDriverNewDialogComponent extends NewDialogDirective<HaulierDriver> {

  constructor(
    haulierDriverService: HaulierDriversService,
    formControl: HaulierDriverFormControlService,
    @Inject(MAT_DIALOG_DATA) data: NewDialogData<HaulierDriver>,
    public matDialog: MatDialogRef<HaulierDriverNewDialogComponent>
  ) {
    super(haulierDriverService, formControl, data);
  }

  successfulSave(result: HaulierDriver) {
    this.matDialog.close();
  }

}
