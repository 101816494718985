import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-copy-choice-dialog',
  templateUrl: './address-copy-choice-dialog.component.html',
  styleUrls: ['./address-copy-choice-dialog.component.scss']
})
export class AddressCopyChoiceDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}

