import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingMenuComponent } from './marketing-menu/marketing-menu.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {FeatureBannersModule} from './feature-banners/feature-banners.module';
import {EventsModule} from './events/events.module';
import {EventCategoriesModule} from './event-categories/event-categories.module';
import {GaiaGeneralModule} from '../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {GrowerProfilesModule} from './grower-profiles/grower-profiles.module';
import {EventBookingModule} from './event-bookings/event-booking.module';
import {WebEnquiriesModule} from './web-enquiries/web-enquiries.module';

@NgModule({
  declarations: [
    MarketingMenuComponent,
  ],
  exports: [
    MarketingMenuComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    FeatureBannersModule,
    EventsModule,
    WebEnquiriesModule,
    EventCategoriesModule,
    GrowerProfilesModule,
    EventBookingModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    FlexLayoutModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule
  ]
})
export class MarketingModule { }
