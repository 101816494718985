    <div fxLayout="row" fxLayoutGap="15px" fxLayout.lt-md="column">
      <ng-container [formGroup]="sentinelSelect1">
      <mat-form-field appearance="outline">
        <mat-label i18n>Year</mat-label>
       <!-- <mat-select [(ngModel)]="selectedYear" (selectionChange)="monthYearChanged();">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>-->
        <mat-select formControlName="selected_year" (selectionChange)="monthYearChanged();">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label i18n>Month</mat-label>
        <mat-select formControlName="selected_month" (selectionChange)="monthYearChanged();">
          <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div fxFlex="100">
        <app-sentinel-functions-select (selectionChange)="changeSentinelFilter();" labelText="Sentinel function" [selectDefault]="this.sentinelSelect" formControlName="select_sentinel">
        </app-sentinel-functions-select>
      </div>
      </ng-container>
    </div>
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-evenly start" fxLayout.lt-md="column">
          <mat-card fxFlex="1 1 280px">
            <mat-card-content>
              <ng-container>
                <mat-spinner style="margin:0 auto;"  [diameter]="30" mode="indeterminate" *ngIf="showFilter"></mat-spinner>
                <mat-calendar class="inline-calendar-card" *ngIf="!showFilter" [dateClass]="dateClass" [startAt]="newStartDate" [(selected)]="currentDate" (selectedChange)="changeIndexFunction()"></mat-calendar>
                <div>
                  <mat-label i18n>Cloud Cover ({{cloudCoverMax}}%)</mat-label>
                  <mat-slider thumbLabel tickInterval="5" min="0" max="100" step="5" [value]="50" (change)="cloudCoverSlider($event)"></mat-slider>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex="1 1 auto">
            <mat-spinner style="margin:0 auto;" mode="indeterminate" *ngIf="show"></mat-spinner>  <!--margin to center the spinner-->
            <!--<mat-card-header>{{this.sentinelSelect.value}}</mat-card-header>-->
            <mat-card-content>
              <!--<mat-spinner *ngIf="show"></mat-spinner>-->
              <ng-container>
                <img *ngIf="!show" alt="" style="width: 75%; height: 50vh; object-fit: contain" [src]="url" />
                &nbsp;&nbsp;
                  <img  *ngIf="this.sentinelSelect === 'MOISTURE_INDEX' && !show" style="width: 20%; height: 100%" [src]=src1 alt=""/>
                  <img *ngIf="this.sentinelSelect === 'NDVI_INDEX' && !show" style="width: 20%; height: 100%" [src]=src2 alt=""/>
              </ng-container>
            </mat-card-content>
            <mat-card-footer>
              <h3 style="text-align: center" *ngIf="this.ndviId === this.sentinelSelect && !show">Normalized Difference Vegetation Index (NDVI)
              </h3>
              <h3 style="text-align: center" *ngIf="this.ndmiId === this.sentinelSelect && !show">Normalized Difference Moisture Index (NDMI)
              </h3>
            </mat-card-footer>
          </mat-card>
        </div>



