import {Component, forwardRef, Injector, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ContactTypesService} from '../contact-types.service';
import {RecordListSelectDirective} from '@terravesta/phanes';
import {ContactType} from '../contact-type.model';

@Component({
  selector: 'app-contact-type-select',
  templateUrl: './contact-type-select.component.html',
  styleUrls: ['./contact-type-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactTypeSelectComponent),
      multi: true
    }
  ]
})
export class ContactTypeSelectComponent extends RecordListSelectDirective<ContactType> {

  @Input()
  label: string;

  constructor(
    contactTypesService: ContactTypesService,
    inj: Injector,
  ) {
    super(contactTypesService, inj);
  }

}
