import { Component, OnInit } from '@angular/core';
import {ProcessorsService} from '../processors.service';
import {getHarvestYear} from '../../../../utils/common-functions';
import {ProcessorYearlyStat} from '../processor.model';

@Component({
  selector: 'app-processor-yearly-stats',
  templateUrl: './processor-yearly-stats.component.html',
  styleUrls: ['./processor-yearly-stats.component.scss']
})
export class ProcessorYearlyStatsComponent implements OnInit {

  currentYear = getHarvestYear();

  stats: Array<ProcessorYearlyStat>

  constructor(
    private processorsService: ProcessorsService,
  ) { }

  ngOnInit(): void {
    this.processorsService.yearlyStats(this.currentYear).subscribe(result => {
      this.stats = result;
    })
  }

}
