<mat-card>
  <img mat-card-image *ngIf="image.current_version" [src]="image.current_version.file.thumb.url" />
  <mat-card-content>
    <div>{{ image.name }}</div>
    <div *ngIf="image.current_version"><span i18n>Version: </span> {{image.current_version.version}}</div>
    <div *ngIf="image.associated_record">
      Is Associated
    </div>
  </mat-card-content>
  <mat-card-actions>
    <ng-content></ng-content>
  </mat-card-actions>
</mat-card>
