import {ChangeDetectorRef, Component} from '@angular/core';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {Haulier} from '../haulier.model';
import {HauliersService} from '../hauliers.service';
import {HaulierFormControlService} from '../haulier-form-control.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-haulier-show',
  templateUrl: './haulier-show.component.html',
  styleUrls: ['./haulier-show.component.scss']
})
export class HaulierShowComponent extends ShowPageDirective<Haulier> {

  constructor(
    hauliersService: HauliersService,
    haulierFormControl: HaulierFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(hauliersService, haulierFormControl, route, snackBar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Haulier) {
  }

}
