import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../environments/environment';
import {UserCookiesService} from '../services/user-cookies.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private snackBar: MatSnackBar,
    private userCookieService: UserCookiesService,
    private window: Window,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          // console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          // console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          const errorDismiss = $localize`:@@errorDismiss:Dismiss`;
          switch (error.status) {
            case 0: // Server offline
              const serverDown = $localize`:@@serverDownError:Error code 0: ${error.message}`;
              console.log(error.status, error.message);
              this.snackBar.open(serverDown, errorDismiss, {
                duration: 10000
              });
              break;
            case 401: // Unauthorized
              const unauthError = $localize`:@@serverUnauthorizedAccess:You are not logged in`;
              this.snackBar.open(unauthError, errorDismiss, {
                duration: 5000
              });
              this.userCookieService.setRedirectCookie();
              // Commented out to deal with cyclic dependency issue
              // this.sessionDataService.authenticationState.next(false);
              setTimeout(() => {
                this.window.location.href = environment.login_url;
              }, 2000);
              break;
            case 403: // Forbidden
              const forbiddenError = $localize`:@@serverUnauthorizedAccess:One of the routes you tried to access is unauthorized`;
              this.snackBar.open(forbiddenError, errorDismiss, {
                duration: 5000
              });
              break;
            case 422: // Unprocessable Entity
              // Error is not central should be managed in the service not here
              /*const unprocessableError = $localize`:@@saveOrUpdateFailed:We are sorry, something has gone wrong in your save`;
              this.snackBar.open(unprocessableError, errorDismiss, {
                duration: 5000
              });*/
              // pass error on so can be handled
              return throwError(error);
            case 500:
              const serverError = $localize`:@@serverError:We are sorry, something has gone wrong`;
              this.snackBar.open(serverError, errorDismiss, {
                duration: 5000
              });
              break;
          }
        }

        // If you want to return a new response:
        //
        // return of(new HttpResponse({body: [{name: "Default value..."}]}));

        // If you want to return the error on the upper level:
        // return throwError(error);

        // or just return nothing:
        return EMPTY;
      })
    );
  }
}
