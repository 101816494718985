import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ContractedFieldsService} from '../../growing/contracted-fields/contracted-fields.service';
import {getHarvestYear, yearArray} from '../../../utils/common-functions';
import {GPSPoint, PhanesGoogleMapComponent, PhanesMapOutput, PhanesNode, PhanesRecordMapItem} from '@terravesta/phanes';
import {ProcessorsService} from '../../operations/processors/processors.service';
import {MiscanthusVarietiesService} from '../../growing/miscanthus-varieties/miscanthus-varieties.service';
import {PlantingOpportunitiesService} from '../../growing/planting-opportunities/planting-opportunities.service';
import {
  PLANTING_OPPORTUNITY_SALESFORCE_STAGES, STAGES_COLORS, stageToColor,
} from '../../growing/planting-opportunities/planting-opportunity.model';
import {FormControl, FormGroup} from '@angular/forms';
import {MiscanthusVariety} from '../../growing/miscanthus-varieties/miscanthus-variety.model';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ContractedFieldMapFeatureWindowComponent} from '../../growing/contracted-fields/contracted-field-map-feature-window/contracted-field-map-feature-window.component';
import {DeviceType, LayoutTrackingService} from '../../gaia-layout/layout-tracking.service';
import {EChartsOption} from 'echarts';
import {MiscanthusStacksService} from '../../operations/miscanthus-stacks/miscanthus-stacks.service';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {
  DELIVERY_COLORS,
  deliveryStatusToColor,
  MISCANTHUS_STACK_DELIVERY_STATUS, MiscanthusStack
} from '../../operations/miscanthus-stacks/miscanthus-stack.model';
import moment from 'moment';

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss']
})
export class OverviewMapComponent implements OnInit  {

  isMobile = false;

  constructor(
    private contractedFieldService: ContractedFieldsService,
    private processorsService: ProcessorsService,
    private miscanthusVarietiesService: MiscanthusVarietiesService,
    private plantingOpportunitiesService: PlantingOpportunitiesService,
    private miscanthusStackService: MiscanthusStacksService,
    private cdf: ChangeDetectorRef,
    private layoutTracking: LayoutTrackingService,
  ) {
    this.layoutTracking.deviceType.subscribe((deviceType) => {
      this.isMobile = deviceType === DeviceType.MOBILE;
      console.log(deviceType);
    }); }

  @ViewChild(PhanesGoogleMapComponent, {static: false}) googleMap: PhanesGoogleMapComponent;
  @ViewChild(ContractedFieldMapFeatureWindowComponent, {static: false}) infoWindow: ContractedFieldMapFeatureWindowComponent;

  selectedRecord: PhanesNode;
  selectedRecordType: string;

  contractedFieldRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  processorRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  opportunitiesRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  mapAllRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();
  stackRecords: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();

  panelOpenState = false;
  toggleValue = true;

  selectedYear: FormControl = new FormControl(new Date().getFullYear() + 1);
  years: number[] = yearArray(2000, new Date().getFullYear()).reverse();
  plantingsYears = yearArray(2017, (new Date()).getFullYear() + 2);
  stages = PLANTING_OPPORTUNITY_SALESFORCE_STAGES;
  stageColors = STAGES_COLORS;
  stageToColor = stageToColor;

  deliveryStages = MISCANTHUS_STACK_DELIVERY_STATUS;
  deliveryStageColors = DELIVERY_COLORS;
  deliveryStageToColor = deliveryStatusToColor;

  markerType: boolean;

  // chart variables
  show = true;
  cropVarietyChart: EChartsOption;
  yearVsField: EChartsOption;
  cropData: MiscanthusVariety;
// -----------------
  /*contractFieldForm: FormGroup = this.contractedFieldFormControlService.toMapFilter();*/
  variety: Array<MiscanthusVariety> = [];
  /*varietyFormControl: FormControl = new FormControl(null);*/
  contractFieldForm = new FormGroup({
    year_planted: new FormControl(),
    miscanthus_variety: new FormControl(),
    marker_type: new FormControl(),
  });
  opportunitiesForm = new FormGroup({
    stage: new FormControl(null),
    expected_planting_year: new FormControl(null),
    sales_survey_completed: new FormControl(null),
  });
  processorForm = new FormGroup({
    active: new FormControl(true),
  });
  stackForm = new FormGroup({
    harvest_year: new FormControl(getHarvestYear()),
    schedule_month: new FormControl(null),
    delivery_status: new FormControl(null),
    stat_completed_collections: new FormControl(null),
    stat_part_collected: new FormControl(null),
    stat_no_collections: new FormControl(null)
  });
  selectedToggle = new FormControl(null);
  isShowContField = false;
  isShowOpportunity = false;
  isShowProcessor = false;
  isShowStack = false;
  scheduleMonth: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  infoPanel: google.maps.InfoWindow;
  bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

  static pointToLatLng(point: GPSPoint): google.maps.LatLng{
    return new google.maps.LatLng(point.latitude, point.longitude);
  }

  ngOnInit(): void {
    console.log(this.markerType);
    this.getMiscVarietiesList();
    this.contractFieldForm.valueChanges.subscribe(() => {
      this.fieldFilterChange();
    });
    this.opportunitiesForm.valueChanges.subscribe(() => {
      this.opportunityChange();
    });
    this.processorForm.valueChanges.subscribe(() => {
      this.processorChange();
    });
    this.stackForm.valueChanges.subscribe(() => {
      this.stacksChange();
    });
  }
  toggleContFieldDisplay(event: MatSlideToggleChange) {
    this.isShowContField = event.checked;
    if (this.isShowContField) {
      this.getContractedFields('');
    }
    else {
      this.contractedFieldRecords = new Array<PhanesRecordMapItem>();
      this.updateMapLists();
      this.contractFieldForm.patchValue({year_planted: null, miscanthus_variety: null}, {emitEvent: false});
    }
  }
  toggleOpportunityDisplay(event: MatSlideToggleChange) {
    this.isShowOpportunity = event.checked;
    if (this.isShowOpportunity) {
      this.getOpportunitiesList('');
    }
    else {
      this.opportunitiesRecords = new Array<PhanesRecordMapItem>();
      this.updateMapLists();
      this.opportunitiesForm.patchValue({stage: null, expected_planting_year: null, sales_survey_completed: null}, {emitEvent: false});
    }
  }
  toggleProcessorDisplay(event: MatSlideToggleChange) {
    this.isShowProcessor = event.checked;
    if (this.isShowProcessor) {
      this.getProcessorLists(this.processorForm.value);
    }
    else {
      this.processorRecords = new Array<PhanesRecordMapItem>();
      this.updateMapLists();
      this.processorForm.patchValue({active: true}, {emitEvent: false});
    }
  }
  toggleStacksDisplay(event: MatSlideToggleChange) {
    this.isShowStack = event.checked;
    if (this.isShowStack) {
      this.getMiscanthusStackList(this.stackForm.value);
    }
    else {
      this.stackRecords = new Array<PhanesRecordMapItem>();
      this.updateMapLists();
      this.stackForm.patchValue({harvest_year: null, schedule_month: null, delivery_status: null}, {emitEvent: false});
    }
  }

  varietyChange(event: any){
  /*this.clearMap();*/
    const variety = this.contractFieldForm.get('miscanthus_variety').value;
    this.getContractedFields(variety);
  }

  fieldFilterChange() {
    const search = this.contractFieldForm.value;
    this.markerType = this.contractFieldForm.get('marker_type').value;
    this.getContractedFields(search);
   /* this.getOpportunitiesList(search1);
    this.getProcessorLists(this.toggleValue);*/
  }

  opportunityChange() {
    const search = this.opportunitiesForm.value;
    this.getOpportunitiesList(search);
  }
  processorToggleActive($event) {
    this.toggleValue = $event.checked;
    this.getProcessorLists(this.toggleValue);
  }
  processorChange() {
    this.getProcessorLists(this.processorForm.value);
  }
  stacksChange() {
    const search = this.stackForm.value;
    this.getMiscanthusStackList(search);
  }
  getContractedFields(searchValue: any){
    this.contractedFieldService.map(searchValue).subscribe((gpsData) => {
      this.contractedFieldRecords = new Array<PhanesRecordMapItem>();
      gpsData.forEach((mapData) => {
          const content = `<strong style="font-size: 120%;">
            <a style="color: #000000;" target="_blank"</a>
          </strong>
          <br />
          <strong>Name:</strong> ${mapData.name}
          <br />
          <strong>Variety:</strong> ${mapData.variety}
          <br />
          <strong>Year-Planted:</strong> ${mapData.year_planted}
          `;
          const mapRecord = {
            record : mapData,
            recordType : 'contractedField',
            infoWindowHtml : content,
            displayOptions: {
              markerStyle: {
                markerColor: '#228D04FF'}
              } ,
          } as PhanesRecordMapItem;
          this.contractedFieldRecords.push(mapRecord);
     });
      this.updateMapLists();
    });
  }

  getProcessorLists(data: any){
    this.processorsService.getRecords({pageNumber: 0, pageSize: 100, search: data }).subscribe(
      (processorsList) => {
        this.processorRecords = new Array<PhanesRecordMapItem>();
        processorsList.records.forEach((processors) => {
          const content = `<strong style="font-size: 120%;">
            <a style="color: #000000;" target="_blank"</a>
          </strong>
          <br />
          <strong>Name:</strong> ${processors.account_name}
          <br />
          <strong>Post-code:</strong> ${processors.billing_address.post_code}
          <br />
          <strong>City:</strong> ${processors.billing_address.city}`;
          const mapRecord = {
            record : {
              id: processors.id, latitude: processors.billing_address.address_latitude,
              longitude: processors.billing_address.address_longitude
            },
            recordType : 'processorRecords',
            infoWindowHtml : content,
            displayOptions : {
              markerStyle: {
                markerUrl: '/assets/icons/processor.png'
              }
            }
          } as PhanesRecordMapItem;
          this.processorRecords.push(mapRecord);
        });
        this.updateMapLists();
      });
  }
  getOpportunitiesList(search: any) {
    this.plantingOpportunitiesService.opportunity(search).subscribe(
      (opportunitiesList) => {
        this.opportunitiesRecords = new Array<PhanesRecordMapItem>();
        opportunitiesList.forEach((opportunities) => {
            const content = `<strong style="font-size: 120%;">
            <a style="color: #000000;" target="_blank"</a>
          </strong>
          <br />
          <strong>Name:</strong> ${opportunities.name}
          <br />
          <strong>Expected Planting Year:</strong> ${opportunities.expected_planting_year}
          <br />
          <strong>Stage:</strong> ${opportunities.stage}
          `;
            const mapRecord = {
              record : opportunities,
              recordType: 'opportunitiesRecords',
              infoWindowHtml : content,
              displayOptions : {
                markerStyle: {
                  markerColor: stageToColor(opportunities.stage)
                }
              }
          } as PhanesRecordMapItem;
            this.opportunitiesRecords.push(mapRecord);
         });
        this.updateMapLists();
      });
  }
  getMiscanthusStackList(search: any) {
    this.miscanthusStackService.miscanthusStack(search).subscribe(
      (stackList) => {
        this.stackRecords = new Array<PhanesRecordMapItem>();
        console.log(stackList);
        stackList.forEach((stacks) => {
          const selectedValue = this.selectedToggle.value;
          const content = `<strong style="font-size: 120%;">
            <a style="color: #000000;" target="_blank"</a>
          </strong>
          <br/>
          <strong>Name:</strong> ${stacks.name}
          <br/>
          <strong>Miles to Processor:</strong> ${stacks.miles_to_selected_processor}
          <br/>
          <strong>Bale count:</strong> ${stacks.declared_bale_count}
          <br />
          <strong>Harvest Year:</strong> ${stacks.harvest_year}
          <br />
          `;
           if (selectedValue === 'delivery'){
             if(stacks.stat_no_collections) {
               const mapRecord = {
                 record: stacks,
                 recordType: 'stackRecords',
                 infoWindowHtml: content,
                 displayOptions: {
                   markerStyle: {
                     markerColor: '#22730b',
                   }
                 }
               } as PhanesRecordMapItem;
               this.stackRecords.push(mapRecord);
             }
             else if(stacks.stat_part_collected) {
               const mapRecord = {
                 record: stacks,
                 recordType: 'stackRecords',
                 infoWindowHtml: content,
                 displayOptions: {
                   markerStyle: {
                     markerColor: '#ef6125',
                   }
                 }
               } as PhanesRecordMapItem;
               this.stackRecords.push(mapRecord);
             }
             else if(stacks.stat_completed_collections) {
               const mapRecord = {
                 record: stacks,
                 recordType: 'stackRecords',
                 infoWindowHtml: content,
                 displayOptions: {
                   markerStyle: {
                     markerColor: '#e3051f',
                   }
                 }
               } as PhanesRecordMapItem;
               this.stackRecords.push(mapRecord);
             }
             else {
               const mapRecord = {
                 record: stacks,
                 recordType: 'stackRecords',
                 infoWindowHtml: content,
                 displayOptions: {
                   markerStyle: {
                     markerColor: '#083dbe',
                   }
                 }
               } as PhanesRecordMapItem;
               this.stackRecords.push(mapRecord);
             }
          }
           else if (selectedValue === 'processor'){
            const mapRecord = {
              record : stacks,
              recordType: 'stackRecords',
              infoWindowHtml : content,
              displayOptions: {
                markerStyle: {
                  markerColor: stacks.processor_colour}
              }
            } as PhanesRecordMapItem;
            this.stackRecords.push(mapRecord);
          }
           else {
             const mapRecord = {
               record: stacks,
               recordType: 'stackRecords',
               infoWindowHtml: content,
               displayOptions: {
                 markerStyle: {
                   markerColor: '#083dbe'
                 }
               }
             } as PhanesRecordMapItem;
             this.stackRecords.push(mapRecord);
           }
        });
        this.updateMapLists();
      });
  }
  getMiscVarietiesList(){
    this.miscanthusVarietiesService.getRecords({pageNumber: 0, pageSize: 100 }).subscribe(
      (miscVarieties) => {
          this.variety = miscVarieties.records;
      });
  }
  updateMapLists(){
    /*this.mapAllRecords = new Array<PhanesRecordMapItem>();*/
    this.mapAllRecords = this.contractedFieldRecords.concat(this.opportunitiesRecords, this.processorRecords, this.stackRecords);
  }

  mapClicked(event: PhanesMapOutput) {
    this.selectedRecord = null;
    this.cdf.detectChanges();
    this.selectedRecord = event.record;
    this.selectedRecordType = event.recordType;
  }

  featureWindowClosed($event: boolean) {
    this.selectedRecord = null;
    this.selectedRecordType = null;
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  cropVarietyPieChart(misc){
    this.cropVarietyChart = this.cropPieChart(misc);
    this.show = false;
  }
/*  yearVsPlantedField(data){
    this.yearVsField = this.contractedFieldPerYear(data);
    this.show = false;
  }*/
  cropPieChart(data: any): EChartsOption{
    return {
      tooltip: {
        trigger: 'item',
      },
      title: {
        text: 'Crop Varieties',
        left: 'center',
        bottom: 10,
        textStyle: {
          color: 'black',
          fontSize: 20,
        }
      },
      legend: {
        top: '5%',
        left: 'left'
      },
      series: [
        {
          name: 'Crop Variety',
          type: 'pie',
          radius: ['30%', '55%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: data.records[0].field_count, name: data.records[0].name },
            { value: data.records[1].field_count, name: data.records[1].name }
          ]
        },
      ],
    };
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.selectedToggle.setValue(null);
    this.selectedToggle.setValue(event.value);
    this.getMiscanthusStackList(this.stackForm.value);
  }
}
