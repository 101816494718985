import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiscanthusVarietyFormComponent } from './miscanthus-variety-form/miscanthus-variety-form.component';
import { MiscanthusVarietiesTableComponent } from './miscanthus-varieties-table/miscanthus-varieties-table.component';
import { MiscanthusVarietyNewDialogComponent } from './miscanthus-variety-new-dialog/miscanthus-variety-new-dialog.component';
import { MiscanthusVarietyShowComponent } from './miscanthus-variety-show/miscanthus-variety-show.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { MiscanthusVarietySelectComponent } from './miscanthus-variety-select/miscanthus-variety-select.component';
import {MatSelectModule} from '@angular/material/select';
import {NgxEchartsModule} from 'ngx-echarts';

@NgModule({
    declarations: [MiscanthusVarietyFormComponent, MiscanthusVarietiesTableComponent, MiscanthusVarietyNewDialogComponent,
        MiscanthusVarietyShowComponent,
        MiscanthusVarietySelectComponent],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        GaiaSharedModule,
        MatTableModule,
        MatPaginatorModule,
        RouterModule,
        ReactiveFormsModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        NgxEchartsModule,
    ],
    providers: [],
    exports: [
        MiscanthusVarietySelectComponent
    ]
})
export class MiscanthusVarietiesModule { }
