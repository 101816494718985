import {Component, Input, OnInit} from '@angular/core';
import * as Color from 'color';

@Component({
  selector: 'app-record-badge',
  templateUrl: './record-badge.component.html',
  styleUrls: ['./record-badge.component.scss']
})
export class RecordBadgeComponent implements OnInit {

  @Input()
  color: string;

  @Input()
  tooltip: string;

  @Input()
  label: string;

  colorObj: Color;
  badgeStyle: any;

  constructor() { }

  ngOnInit(): void {
    if (!this.tooltip) {
      this.tooltip = this.label;
    }
    if (this.color){
      this.colorObj = new Color(this.color);
      this.badgeStyle = {
        background: `radial-gradient(circle at 20px 20px, ${this.colorObj.hex()} , ${this.colorObj.darken(0.35)});`,
        color: this.colorObj.luminosity() > 0.5 ? '#000000' : '#ffffff',
      };
    }
  }

}
