import { Injectable } from '@angular/core';
import {StaffExpenseClaim} from './staff-expense-claim.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';

@Injectable({
  providedIn: 'root'
})
export class StaffExpenseClaimFormControlService implements GaiaRecordFormControl<StaffExpenseClaim> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: StaffExpenseClaim): FormGroup {
    return this.formBuilder.group({
      date_of_claim: new FormControl(record.date_of_claim, Validators.required),
      details: new FormControl(record.details, Validators.required),
      currency: new FormControl(record.currency, Validators.required),
      claimed_amount: new FormControl(record.claimed_amount, [Validators.required, Validators.min(0)]),
      category_id: new FormControl(record.category ? record.category.id : null, Validators.required)
    });
  }
}
