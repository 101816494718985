import {Component, OnInit, ViewChild} from '@angular/core';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';
import {ContractedFieldsService} from '../contracted-fields.service';
import {GrowersService} from '../../growers/growers.service';
import {yearArray} from '../../../../utils/common-functions';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-contracted-field-stats-dashboard',
  templateUrl: './contracted-field-stats-dashboard.component.html',
  styleUrls: ['./contracted-field-stats-dashboard.component.scss']
})
export class ContractedFieldStatsDashboardComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;

  @ViewChild('kmlDownload') kmlDownload: SpinnerLoadingButtonComponent;
  @ViewChild('yearDownload') yearDownload: SpinnerLoadingButtonComponent;

  years = yearArray(2017);
  yearSelection = new FormControl(new Date().getFullYear());


  constructor(
    private contractedFieldsService: ContractedFieldsService,
    private growersService: GrowersService,
  ) { }

  ngOnInit(): void {
  }

  downloadKML() {
    this.growersService.growerKML(() => { this.kmlDownload.processFinished(); });
  }

  downloadByYear() {
    this.contractedFieldsService.downloadPlantingKML(this.yearSelection.value, () => {
      this.yearDownload.processFinished();
    });
  }
}
