import {ChangeDetectorRef, Component} from '@angular/core';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {Processor} from '../processor.model';
import {ProcessorsService} from '../processors.service';
import {ProcessorFormControlService} from '../processor-form-control.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-processor-show',
  templateUrl: './processor-show.component.html',
  styleUrls: ['./processor-show.component.scss']
})
export class ProcessorShowComponent extends ShowPageDirective<Processor> {

  constructor(
    processorsService: ProcessorsService,
    processorFormControl: ProcessorFormControlService,
    activatedRoute: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(processorsService, processorFormControl, activatedRoute, snackBar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Processor) {
  }

}
