<gaia-shared-spinner-loading-button (clickOccurred)="downloadBaleHistories()"
                                    color="accent"
                                    i18n="download bale summaries button">
  <mat-icon>{{ gaiaIconSet.download }}</mat-icon>
  Bale History
</gaia-shared-spinner-loading-button>

<mat-table *ngIf="this.baleHistories" mat-table [dataSource]="this.baleHistories.grower_data">
  <ng-container matColumnDef="grower_code">
    <mat-header-cell *matHeaderCellDef i18n="unique code for grower"> Grower Code </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.grower_code }}
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="column" *ngFor="let column of years; let index">
    <mat-header-cell mat-header-cell *matHeaderCellDef> {{column}} </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <div *ngIf="element.bale_data[column]" class="bale_change"
        [class.up]="element.bale_data[column].change === 'up'"
        [class.down]="element.bale_data[column].change === 'down'"
      >
        {{ element.bale_data[column].bales }}
        <mat-icon *ngIf="element.bale_data[column].change === 'up'">{{ gaiaIconSet.up }}</mat-icon>
        <mat-icon *ngIf="element.bale_data[column].change === 'down'">{{ gaiaIconSet.down }}</mat-icon>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
</mat-table>
