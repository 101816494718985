import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstablishmentContractsTableComponent } from './establishment-contracts-table/establishment-contracts-table.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import { EstablishmentContractShowComponent } from './establishment-contract-show/establishment-contract-show.component';
import { EstablishmentContractFormComponent } from './establishment-contract-form/establishment-contract-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import { EstablishmentContractNewDialogComponent } from './establishment-contract-new-dialog/establishment-contract-new-dialog.component';
import {FlexModule} from '@angular/flex-layout';
import {ContractsSharedModule} from '../contracts-shared/contracts-shared.module';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { EstablishmentContractSelectComponent } from './establishment-contract-select/establishment-contract-select.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {
  EstablishmentContractAddFieldDialogComponent
} from './establishment-contract-add-field-dialog/establishment-contract-add-field-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    EstablishmentContractsTableComponent,
    EstablishmentContractShowComponent,
    EstablishmentContractFormComponent,
    EstablishmentContractNewDialogComponent,
    EstablishmentContractSelectComponent,
    EstablishmentContractAddFieldDialogComponent
  ],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        GaiaSharedModule,
        MatTableModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        RouterModule,
        MatButtonModule,
        FlexModule,
        ContractsSharedModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatSlideToggleModule,
    ],
  exports: [
    EstablishmentContractsTableComponent,
    EstablishmentContractShowComponent,
    EstablishmentContractSelectComponent
  ]
})
export class EstablishmentContractsModule { }
