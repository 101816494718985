import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentinelFunctionNewDialogComponent } from './sentinel-function-new-dialog/sentinel-function-new-dialog.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {HarvestHubHelpModule} from '../../harvest-hub-help/harvest-hub-help.module';
import {SentinelFunctionsTableComponent} from './sentinel-functions-table/sentinel-functions-table.component';
import {SentinelFunctionShowComponent} from './sentinel-function-show/sentinel-function-show.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import { SentinelFunctionFormComponent } from './sentinel-function-form/sentinel-function-form.component';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import { SentinelFunctionsSelectComponent } from './sentinel-functions-select/sentinel-functions-select.component';
import {MatSelectModule} from '@angular/material/select';


@NgModule({
  declarations: [
    SentinelFunctionNewDialogComponent,
    SentinelFunctionsTableComponent,
    SentinelFunctionShowComponent,
    SentinelFunctionFormComponent,
    SentinelFunctionsSelectComponent
  ],
  exports: [
    SentinelFunctionsSelectComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    HarvestHubHelpModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    FlexModule,
    MatCardModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule,
    MatSelectModule,
  ]
})
export class SentinelFunctionsModule { }
