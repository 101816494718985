import {Component, ViewChild} from '@angular/core';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ContractedField, ContractedFieldMapData} from '../contracted-field.model';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ContractedFieldNewDialogComponent} from '../contracted-field-new-dialog/contracted-field-new-dialog.component';
import {GrowingRoutes} from '../../growing-routing.module';
import {GaiaLogger, yearArray} from '../../../../utils/common-functions';
import {MatSelect} from '@angular/material/select';
import {FormControl} from '@angular/forms';
import {PhanesGoogleMapComponent, PhanesRecordMapItem} from '@terravesta/phanes';
import {Observable} from 'rxjs';
import {mapDataInfoWindowHtml} from '../contracted-field-functions';
import {SearchFilterButtonComponent} from '../../../gaia-general/search-filter-button/search-filter-button.component';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-contracted-fields-table',
  templateUrl: './contracted-fields-table.component.html',
  styleUrls: ['./contracted-fields-table.component.scss']
})
export class ContractedFieldsTableComponent extends IndexTableDirective<ContractedField> {

  growingRoutes = GrowingRoutes;
  displayedColumns = ['name', 'grower_code', 'field_reference_number', 'variety', 'year_planted', 'current_harvest_yield', 'badges'];

  years = yearArray(2015);

  @ViewChild('yearPlantedSelect')
  yearPlantedSelect: MatSelect;

  @ViewChild('inContract')
  inContract: SearchFilterButtonComponent;
  inContractInitialState = false;

  @ViewChild('hasWarnings')
  hasWarnings: SearchFilterButtonComponent;
  hasWarningsInitialState = false;

  @ViewChild(PhanesGoogleMapComponent)
  map: PhanesGoogleMapComponent;

  formControl: FormControl = new FormControl();
  varietyControl: FormControl = new FormControl();

  mapData: Array<ContractedFieldMapData> = new Array<ContractedFieldMapData>();
  mapDataSub: Observable<ContractedFieldMapData[]>;
  mapItems: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();

  constructor(
    contractedFieldsService: ContractedFieldsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(contractedFieldsService, router, activatedRoute, matDialog, ContractedFieldNewDialogComponent, null);
    this.getMapData();
  }

  searchOpts(): any {
    const searchData = super.searchOpts();
    if (this.searchTerm) {
      const fuzzyTerm = this.fuzzySearchTerm();
      searchData.grower = {
        grower_code: `${fuzzyTerm}`,
        account_name: `${fuzzyTerm}`,
      };
    }
    if (this.formControl && this.formControl.value){
      searchData.year_planted = this.formControl.value;
    }
    if (this.varietyControl && this.varietyControl.value) {
      searchData.planted_variety = {
        uuid: this.varietyControl.value
      };
    }
    if (this.inContract && this.inContract.getState()) {
      searchData.in_contract = 1;
    }
    if (this.hasWarnings && this.hasWarnings.getState()) {
      searchData.has_warnings = 1;
    }
    GaiaLogger.log(searchData);
    return searchData;
  }


  parseStartSearch(search: any) {
    super.parseStartSearch(search);
    if (this.currentSearch.in_contract) {
      this.inContractInitialState = true;
    }
    if (this.currentSearch.has_warnings) {
      this.hasWarningsInitialState = true;
    }
    this.formControl.setValue(search.year_planted);
  }

  getMapData(){
    this.mapDataSub = (this.dataService as ContractedFieldsService).map();
    this.mapDataSub.subscribe(
      (data) => {
        this.mapData = data;
        this.updateMap();
      });
  }

  updateMap() {
    this.mapItems = new Array<PhanesRecordMapItem>();
    this.mapData.forEach((fieldMapData) => {
      const content = mapDataInfoWindowHtml(fieldMapData);
      const record = {
        record: fieldMapData,
        title: `${fieldMapData.grower_code} - ${fieldMapData.name}`,
        infoWindowHtml: content,
        displayOptions: {
          markerStyle: {
            markerColor: fieldMapData.color,
          },
          polygonStyle: {
            fillColor: fieldMapData.color,
            strokeColor: fieldMapData.color,
          }
        }
      } as PhanesRecordMapItem;
      this.mapItems.push(record);
    });
  }

  redrawMap(tabEvent: MatTabChangeEvent) {
    if (this.map && tabEvent.index === 1){
      this.map.fitToBounds(false, true, true, true);
    }
  }
}
