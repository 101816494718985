import {
  Component,
  forwardRef,
  Injector,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {Contact} from '../contact.model';
import {GroupValueAccessorConnectorDirective} from '@terravesta/phanes';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ContactsService} from '../contacts.service';

@Component({
  selector: 'app-contact-find-or-create-simple',
  templateUrl: './contact-find-or-create-simple.component.html',
  styleUrls: ['./contact-find-or-create-simple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactFindOrCreateSimpleComponent),
      multi: true,
    }
  ],
})
export class ContactFindOrCreateSimpleComponent extends GroupValueAccessorConnectorDirective implements OnInit, OnChanges {

  selectedContact: Contact;

  constructor(
    private contactService: ContactsService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formGroupName || changes.formGroup) {
      if (this.control) {
        if (this.control.get('id').value) {
          this.contactService.getRecord({recordId: this.control.get('id').value}).subscribe((response) => {
            this.existingContactSelected(response);
          });
        }
      }
    }
  }

  existingContactSelected($event: Contact) {
    this.selectedContact = $event;
    this.updateForm();
  }

  newContact() {
    this.selectedContact = {} as Contact;
    this.updateForm();
  }

  updateForm() {
    this.control.patchValue(this.selectedContact);
  }

  resetContact() {
    this.selectedContact = null;
    this.control.reset();
  }


}
