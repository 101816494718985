import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GrowersService} from './growers.service';
import {GrowerShowComponent} from './grower-show/grower-show.component';
import {GrowersTableComponent} from './growers-table/growers-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {GrowerRoutingModule} from './grower-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatButtonModule} from '@angular/material/button';
import {ContractedFieldsModule} from '../contracted-fields/contracted-fields.module';
import {AddressesModule, GaiaSharedModule} from '@terravesta/gaia-shared';
import { GrowerFormComponent } from './grower-form/grower-form.component';
import { GrowerNewDialogComponent } from './grower-new-dialog/grower-new-dialog.component';
import {DroneImagesModule} from '../drone-images/drone-images.module';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {GrowerContractsModule} from '../grower-contracts/grower-contracts.module';
import {ContactsModule} from '../../contacts/contacts.module';
import {FlexModule} from '@angular/flex-layout';
import {MatTabsModule} from '@angular/material/tabs';
import { GrowerContractorViewComponent } from './grower-contractor-view/grower-contractor-view.component';
import {ContractorsModule} from '../../operations/contractors/contractors.module';
import { GrowerHarvestOverviewComponent } from './grower-harvest-overview/grower-harvest-overview.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { GrowerStatsDashboardComponent } from './grower-stats-dashboard/grower-stats-dashboard.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import { GrowerBadgePanelComponent } from './grower-badge-panel/grower-badge-panel.component';
import {MiscanthusHarvestsModule} from '../../operations/miscanthus-harvests/miscanthus-harvests.module';
import {MatSelectModule} from '@angular/material/select';
import { GrowerHarvestSeriesGraphComponent } from './grower-harvest-series-graph/grower-harvest-series-graph.component';
import {PlantingOpportunitiesModule} from '../planting-opportunities/planting-opportunities.module';
import {MatSortModule} from '@angular/material/sort';
import {ContractsModule} from '../contracts/contracts.module';
import { GrowersBaleHistoryComponent } from './growers-bale-history/growers-bale-history.component';
import {DashboardSharedModule} from '../../custom-dashboards/dashboard-shared/dashboard-shared.module';
import { GrowerContractStatsWidgetComponent } from './widgets/grower-contract-stats-widget/grower-contract-stats-widget.component';
import {DashboardComponentsModule} from '../../custom-dashboards/dashboard-components/dashboard-components.module';
import {
  GrowerUniversalSearchResultComponent
} from './grower-universal-search-result/grower-universal-search-result.component';
import {UniversalSearchLayoutModule} from '../../universal-search/universal-search-layout.module';
import {MatListModule} from '@angular/material/list';

@NgModule({
  declarations: [GrowerShowComponent, GrowersTableComponent, GrowerFormComponent,
    GrowerNewDialogComponent, GrowerContractorViewComponent,
    GrowerHarvestOverviewComponent,
    GrowerStatsDashboardComponent,
    GrowerBadgePanelComponent,
    GrowerHarvestSeriesGraphComponent,
    GrowersBaleHistoryComponent,
    GrowerContractStatsWidgetComponent,
    GrowerUniversalSearchResultComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        GrowerRoutingModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        GaiaGeneralModule,
        ReactiveFormsModule,
        AddressesModule,
        GaiaSharedModule,
        ContractedFieldsModule,
        DroneImagesModule,
        MatCardModule,
        MatIconModule,
        GrowerContractsModule,
        ContactsModule,
        FlexModule,
        MatTabsModule,
        ContractorsModule,
        MatExpansionModule,
        MatCheckboxModule,
        NgxEchartsModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatSelectModule,
        MiscanthusHarvestsModule,
        PlantingOpportunitiesModule,
        MatSortModule,
        ContractsModule,
        DashboardSharedModule,
        DashboardComponentsModule,
        UniversalSearchLayoutModule,
        MatListModule,
    ],
  exports: [
    GrowerShowComponent,
    GrowersTableComponent,
    GrowerFormComponent,
    GrowerUniversalSearchResultComponent
  ],
  providers: [GrowersService]
})
export class GrowersModule { }
