import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../../services/gaia-record-form-control';
import {BaleGain} from './bale-gain.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BaleGainFormControlService implements GaiaRecordFormControl<BaleGain> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(record: BaleGain): FormGroup {
    return this.formBuilder.group({
      bales_added: new FormControl(record.bales_added, Validators.required),
      date_added: new FormControl(record.date_added, Validators.required),
      reason: new FormControl(record.reason, Validators.required),
      carry_over: new FormControl(record.carry_over),
    });
  }

}
