<div *ngIf="visible">
  <mat-expansion-panel id="infoWindow" *ngIf="plantingOpportunity" [expanded]="panelOpenState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b>Name</b>
        <p class="card-title">{{plantingOpportunity.name}}</p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <b>Stage</b>
    <p class="card-title">{{plantingOpportunity.stage}}
    </p>
    <b>Expected Planting Year</b>
    <p class="card-title">{{plantingOpportunity.expected_planting_year}}
    </p>
    <b>Field count</b>
    <p class="card-title">{{plantingOpportunity.field_count}}
    </p>
    <b>Next Steps</b>
    <p class="card-title">{{plantingOpportunity.next_steps}}
    </p>
    <mat-card-actions>
      <button id="infoClose" mat-raised-button color="primary" (click)="CloseInfo()">Close</button>
    </mat-card-actions>
  </mat-expansion-panel>
</div>
