import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiscanthusHarvestTableComponent } from './miscanthus-harvest-table/miscanthus-harvest-table.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import { MiscanthusHarvestShowComponent } from './miscanthus-harvest-show/miscanthus-harvest-show.component';
import { MiscanthusHarvestFormComponent } from './miscanthus-harvest-form/miscanthus-harvest-form.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ContractorsModule} from '../contractors/contractors.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {
  MiscanthusHarvestStatsDashboardComponent
} from './miscanthus-harvest-stats-dashboard/miscanthus-harvest-stats-dashboard.component';
import {MatIconModule} from '@angular/material/icon';
import { MiscanthusHarvestNewDialogComponent } from './miscanthus-harvest-new-dialog/miscanthus-harvest-new-dialog.component';
import { MiscanthusHarvestReviewComponent } from './miscanthus-harvest-review/miscanthus-harvest-review.component';
import {GrowerSharedModule} from '../../growing/grower-shared-components/grower-shared.module';
import { HarvestCompletionSummaryWidgetComponent } from './widgets/harvest-completion-summary-widget/harvest-completion-summary-widget.component';
import {DashboardSharedModule} from '../../custom-dashboards/dashboard-shared/dashboard-shared.module';
import {NgxEchartsModule} from 'ngx-echarts';
import { HarvestTonnageSummaryWidgetComponent } from './widgets/harvest-tonnage-summary-widget/harvest-tonnage-summary-widget.component';
import {MiscanthusStacksModule} from '../miscanthus-stacks/miscanthus-stacks.module';
import {MatSortModule} from '@angular/material/sort';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MiscanthusHarvestDeclarationStatusComponent } from './miscanthus-harvest-declaration-status/miscanthus-harvest-declaration-status.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [MiscanthusHarvestTableComponent, MiscanthusHarvestShowComponent, MiscanthusHarvestFormComponent,
    MiscanthusHarvestStatsDashboardComponent,
    MiscanthusHarvestNewDialogComponent,
    MiscanthusHarvestReviewComponent,
    HarvestCompletionSummaryWidgetComponent,
    HarvestTonnageSummaryWidgetComponent,
    MiscanthusHarvestDeclarationStatusComponent],
  imports: [
    CommonModule,
    GaiaSharedModule,
    MatTableModule,
    MatPaginatorModule,
    ExtendedModule,
    RouterModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    ContractorsModule,
    ReactiveFormsModule,
    GaiaGeneralModule,
    MatInputModule,
    MatCardModule,
    FlexModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    GrowerSharedModule,
    FormsModule,
    DashboardSharedModule,
    NgxEchartsModule,
    MiscanthusStacksModule,
    MatSortModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  exports: [
    MiscanthusHarvestReviewComponent
  ]
})
export class MiscanthusHarvestsModule { }
