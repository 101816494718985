import { Injectable } from '@angular/core';
import {StaffMember} from './staff-member.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  HasNameFormControlService,
  AddressFormControlService,
  TelephoneFormControlService
} from '@terravesta/gaia-shared';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {GaiaLogger} from '../../../utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class StaffMemberFormControlService implements GaiaRecordFormControl<StaffMember> {

  constructor(
    private formBuilder: FormBuilder,
    private hasNameFormControl: HasNameFormControlService,
    private addressFormControl: AddressFormControlService,
    private telephoneFormControl: TelephoneFormControlService,
  ) { }

  toFormGroup(record: StaffMember): FormGroup {
    const nameGroup = this.hasNameFormControl.toFormControls(record);
    const staffData = {
      job_title: new FormControl({ value: record.job_title, disabled: true }),
      email: new FormControl( {value: record.email, disabled: true }),
      address: this.addressFormControl.toFormControl(record.address),
      web_description: new FormControl(record.web_description),
      company_mobile_number: new FormControl(record.company_mobile_number),
      extension: new FormControl(record.extension),
      primary_office_id: new FormControl(record.primary_office ? record.primary_office.id : null),
      mobile_number: this.telephoneFormControl.toFormGroup(record.mobile_number),
      web_visible: new FormControl(record.web_visible),
      web_sort_order: new FormControl(record.web_sort_order),
      employment_start_date: new FormControl({value: record.employment_start_date, disabled: true }),
      employment_end_date: new FormControl({value: record.employment_end_date, disabled: true }),
    };
    return this.formBuilder.group({
      ...nameGroup,
      ...staffData
    });
  }

  updatePermissions(formGroup: FormGroup, hrAccess: boolean){
    GaiaLogger.log('updating form', hrAccess);
    if (hrAccess) {
      formGroup.get('job_title').enable();
      formGroup.get('email').enable();
      formGroup.get('employment_start_date').enable();
      formGroup.get('employment_end_date').enable();
    }
  }

}
