
<phanes-error-panel></phanes-error-panel>

<div class="content">
  <gaia-shared-processing-overlay [defaultMessage]="defaultMessage"></gaia-shared-processing-overlay>
  <app-show-record-menu [form]="form" (saveClicked)="saveClicked.emit()" [allowDelete]="allowDelete" (deleteClickedAndConfirmed)="deleteConfirmed.emit()">
    <ng-content select="[menu]"></ng-content>
  </app-show-record-menu>
  <div [formGroup]="form">
    <ng-content></ng-content>
  </div>
</div>

