import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {TermContract, TermContractYearlyStatistics} from './term-contract.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Grower} from '../../growers/grower.model';
import {RailsAPIWrapper} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class TermContractsService extends RestApiService<TermContract> {

  constructor(
    httpClient: HttpClient
  ) {
    super('term_contracts', 'term_contract', httpClient);
  }

  forGrower(grower: Grower): Observable<RailsAPIWrapper<TermContract>> {
    return this.getRecords({ pageNumber: 0, pageSize: 100, search: {
        grower: { uuid: grower.id }
      }
    });
  }

  yearlyStatistics(startYear: number, endYear: number): Observable<Array<TermContractYearlyStatistics>> {
    return this.httpGet(this.generateNonRecordUrl('yearly_statistics'), {
      start_year: startYear, end_year: endYear
    });
  }
}
