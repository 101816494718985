<mat-card>

  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.brand_ambassadors}}</mat-icon>
    <mat-card-title>Brand Ambassador</mat-card-title>

  </mat-card-header>

  <mat-card-content *ngIf="brandAmbassador" fxLayout="column">
    <mat-list>
      <mat-list-item>
        <mat-icon matListIcon>domain</mat-icon>
        {{brandAmbassador.account_name}}
      </mat-list-item>
      <mat-list-item *ngIf="brandAmbassador.on_account">
        <mat-icon matListIcon>payment</mat-icon>
        <span i18n>Payment Term:</span>&nbsp;{{brandAmbassador.payment_term_days}}&nbsp;<span i18n="day count">Days</span>
      </mat-list-item>
    </mat-list>
  </mat-card-content>

  <mat-card-actions align="start">
    <button mat-icon-button color="primary" type="button" aria-label="" *ngIf="editable" (click)="cancelClicked()"></button>
  </mat-card-actions>

</mat-card>
