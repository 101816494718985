<div *ngIf="form" [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title i18n>Measurements</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field>
        <mat-label i18n>Field</mat-label>
        <input matInput [value]="record.field.name" [readonly]="true" />
      </mat-form-field>

      <phanes-what-three-words-input formGroupName="what_3_words"></phanes-what-three-words-input>

      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="time_taken" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSeconds]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Temperature</mat-label>
        <input matInput formControlName="temperature" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Moisture (%)</mat-label>
        <input matInput formControlName="moisture_percentage" />
      </mat-form-field>

    </mat-card-content>
  </mat-card>

</div>
