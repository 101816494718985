import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HaulierDriversEmbedTableComponent } from './haulier-drivers-embed-table/haulier-drivers-embed-table.component';
import {MatTableModule} from '@angular/material/table';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { HaulierDriverFormComponent } from './haulier-driver-form/haulier-driver-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {ContactsModule} from '../../../contacts/contacts.module';
import { HaulierDriverShowComponent } from './haulier-driver-show/haulier-driver-show.component';
import {MatTabsModule} from '@angular/material/tabs';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {RouterModule} from '@angular/router';
import { HaulierDriverSelectComponent } from './haulier-driver-select/haulier-driver-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HaulierDriverNewDialogComponent } from './haulier-driver-new-dialog/haulier-driver-new-dialog.component';
import { HaulierDriverFormEmbedComponent } from './haulier-driver-form-embed/haulier-driver-form-embed.component';
import {HaulierSharedComponentsModule} from '../../haulier-shared-components/haulier-shared-components.module';


@NgModule({
  declarations: [
    HaulierDriversEmbedTableComponent,
    HaulierDriverFormComponent,
    HaulierDriverShowComponent,
    HaulierDriverSelectComponent,
    HaulierDriverNewDialogComponent,
    HaulierDriverFormEmbedComponent,
  ],
    exports: [
        HaulierDriversEmbedTableComponent,
        HaulierDriverSelectComponent,
        HaulierDriverFormEmbedComponent
    ],
  imports: [
    CommonModule,
    MatTableModule,
    GaiaSharedModule,
    ExtendedModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatCardModule,
    FlexModule,
    ContactsModule,
    MatTabsModule,
    GaiaGeneralModule,
    RouterModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    HaulierSharedComponentsModule,
  ]
})
export class HaulierDriversModule { }
