import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PriceGroupsTableComponent} from './price-groups-table/price-groups-table.component';
import {PriceGroupShowComponent} from './price-group-show/price-group-show.component';


const routes: Routes = [
  { path: '', component: PriceGroupsTableComponent },
  { path: ':id', component: PriceGroupShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PriceGroupRoutingModule{ }
