import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {CompaniesService} from '../companies.service';
import {CompanyFormControlService} from '../company-form-control.service';
import {Company} from '../company.model';
import {NewDialogMultipartDirective} from '../../../components/new-dialog-multipart.directive';
import {AppRoutes} from '../../../app-routing.module';
import {BusinessSettingsRoutes} from '../../business-settings-routing.module';

@Component({
  selector: 'app-company-new-dialog',
  templateUrl: './company-new-dialog.component.html',
  styleUrls: ['./company-new-dialog.component.scss']
})
export class CompanyNewDialogComponent extends NewDialogMultipartDirective<Company> {

  constructor(
    companyService: CompaniesService,
    companyFormControl: CompanyFormControlService,
    private router: Router,
    public matDialog: MatDialogRef<CompanyNewDialogComponent>,
  ) {
    super(companyService, companyFormControl);
  }

  successfulSave(company: Company) {
    this.router.navigate([AppRoutes.BUSINESS_SETTINGS, BusinessSettingsRoutes.COMPANIES, company.id]).then((x) => {
      this.matDialog.close();
    });
  }
}
