import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ContactsService} from '../contacts.service';
import {Contact, ContactForAccount, ContactLogin} from '../contact.model';
import {MatPaginator} from '@angular/material/paginator';
import {GaiaRecord} from '../../models/gaia-record.model';
import {AppRoutes} from '../../app-routing.module';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {
  ConfirmDialogComponent,
} from '@terravesta/gaia-shared';
import {MatDialog} from '@angular/material/dialog';
import {showSomethingWentWrongSnackbar} from '../../../utils/common-snackbars';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GaiaLogger} from '../../../utils/common-functions';
import {ContactAccountDialogComponent} from '../contact-account-dialog/contact-account-dialog.component';
import {ContactLoginsService} from '../contact-logins.service';
import {
  NewContactForAccountDialogComponent
} from '../new-contact-for-account-dialog/new-contact-for-account-dialog.component';
import {PaginatedDataSource} from '@terravesta/phanes';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-contact-list-embed',
  templateUrl: './contact-list-embed.component.html',
  styleUrls: ['./contact-list-embed.component.scss']
})
export class ContactListEmbedComponent implements OnInit, AfterViewInit, OnChanges {

  appRoutes = AppRoutes;
  gaiaIconSet = GaiaIconSet;

  dataSource: PaginatedDataSource<ContactForAccount> = new PaginatedDataSource<ContactForAccount>(() => null);
  displayedColumns = ['name', 'relationship', 'email', 'phone', 'mobile', 'has_login', 'can_access_account', 'can_admin_account', 'menu'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  account: GaiaRecord;

  @Input()
  showAddNew = true;

  constructor(
    private contactsService: ContactsService,
    private contactLoginsService: ContactLoginsService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const account = changes.account;
    if (account.currentValue) {
      if (account.firstChange) {
        this.loadContacts();
      } else {
        if (account.previousValue !== account.currentValue) {
          this.loadContacts();
        }
      }
    }
  }

  loadContacts(){
    this.dataSource = new PaginatedDataSource<ContactForAccount>(
      (params) => this.contactsService.forAccount(this.account, params));
    this.dataSource.loadRecords();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  grantAccess(contact: Contact) {
    const message = $localize`:@@Contact.ConfirmGrantAccess:Are you sure you wish to allow ${contact.email} access to your account?`;
    const dialogData = {
      title: $localize`:@@ConfirmVerifiedTitle:Confirm`,
      message
    };
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(
      dialogResult => {
        if (dialogResult) {
          this.contactsService.grantAccessTo(contact, this.account).subscribe(
            () => {
              this.snackBar.open($localize`:@@InviteSent:Invite Sent`);
              this.dataSource.loadRecords();
            },
            () => { showSomethingWentWrongSnackbar(this.snackBar); }
          );
        }
      });
  }

  resendInvite(contact: Contact) {
    this.contactsService.resendInvite(contact, this.account).subscribe(
      () => {
        this.snackBar.open($localize`:@@InviteSent:Invite Sent`);
        this.dataSource.loadRecords();
      },
      () => { showSomethingWentWrongSnackbar(this.snackBar); }
    );
  }

  changeAdminStatus(contact: Contact, adminRights: boolean) {
    this.contactsService.setAdminStatus(contact, this.account, adminRights).subscribe(
      () => {
        this.snackBar.open($localize`:@@Contact.changeAdminStatus:Admin Status Changed`);
        this.dataSource.loadRecords();
      },
      () => { showSomethingWentWrongSnackbar(this.snackBar); }
    );
  }

  revokeAccess(contact: Contact) {
    const message = $localize`:@@Contact.ConfirmRevokeAccess:Are you sure you wish to remove ${contact.email} access to your account?`;
    const dialogData = {
      title: $localize`:@@ConfirmVerifiedTitle:Confirm`,
      message
    };
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(
      dialogResult => {
        if (dialogResult) {
          this.contactsService.revokeAccess(contact, this.account).subscribe(
            (response) => {
              GaiaLogger.log(response);
              if (response.revoked) {
                this.snackBar.open($localize`:@@AccessRevoked:Access revoked`);
                this.dataSource.loadRecords();
              }else{
                showSomethingWentWrongSnackbar(this.snackBar);
              }
            },
            () => {
              showSomethingWentWrongSnackbar(this.snackBar);
            }
          );
        }
      });
  }

  editContact(contact: Contact) {
    this.openDialog(contact);
  }

  unlockAccount(element: ContactForAccount) {
    const login = { id: element.contact_login_id } as ContactLogin;
    this.contactLoginsService.unlockAccount(login).subscribe((response) => {
      if (response.result){
        this.snackBar.open($localize`:@@Account.unlocked:Account unlocked`);
        this.loadContacts();
      } else {
        this.snackBar.open($localize`:@@Account.notUnlocked:Unable to unlock account`);
      }
    });
  }

  newContact() {
    const dialogRef = this.matDialog.open(NewContactForAccountDialogComponent, {
      data: {
        account: this.account
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.dataSource.loadRecords();
    });
  }

  openDialog(contact: Contact) {
    const dialogRef = this.matDialog.open(ContactAccountDialogComponent, {
      data: {
        record: contact,
        account: this.account
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.dataSource.loadRecords();
    });
  }

  unlinkContact(contact: Contact) {
    const message = $localize`:@@Contact.ConfirmRevokeAccess:Are you sure you wish to remove ${contact.email} from this account. N.B. This will not delete the contact, just remove their connection to this account`;
    const dialogData = {
      title: $localize`:@@ConfirmVerifiedTitle:Confirm`,
      message
    };
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(
      dialogResult => {
        if (dialogResult) {
          this.contactsService.unlinkFromAccount(contact, this.account).subscribe((result) => {
            this.loadContacts();
            if (!result.result) {
              this.snackBar.open('Something went wrong, please speak to the admin team');
            }
          });
        }
      });
  }
}
