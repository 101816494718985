import {Component, Inject, OnInit} from '@angular/core';
import {InternalNote} from '../internal-note.model';
import {InternalNotesService} from '../internal-notes.service';
import {InternalNoteFormControlService} from '../internal-note-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormDialogData, FormDialogDirective} from '../../components/form-dialog.directive';
import {GaiaRecord} from '../../models/gaia-record.model';

@Component({
  selector: 'app-internal-note-form-dialog',
  templateUrl: './internal-note-form-dialog.component.html',
  styleUrls: ['./internal-note-form-dialog.component.scss']
})
export class InternalNoteFormDialogComponent extends FormDialogDirective<InternalNote> implements OnInit{

  constructor(
    internalNotesService: InternalNotesService,
    internalNoteFormControl: InternalNoteFormControlService,
    public matDialog: MatDialogRef<InternalNoteFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private formData: InternalNoteFormDialogData,
  ) {
    super(internalNotesService, internalNoteFormControl, matDialog, formData);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.formData.relatedRecord){
      this.form.patchValue({related_record_id: this.formData.relatedRecord.id});
    }
  }

  successfulSave(result: InternalNote) {
    this.matDialog.close();
  }

}

export interface InternalNoteFormDialogData extends FormDialogData<InternalNote>{
  record: InternalNote;
  relatedRecord?: GaiaRecord;
}
