import {Component, Inject} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../../components/form-dialog.directive';
import {BaleGain} from '../bale-gain.model';
import {BaleGainsService} from '../bale-gains.service';
import {BaleGainFormControlService} from '../bale-gain-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MiscanthusStack} from '../../miscanthus-stack.model';

@Component({
  selector: 'app-bale-gain-dialog',
  templateUrl: './bale-gain-dialog.component.html',
  styleUrls: ['./bale-gain-dialog.component.scss']
})
export class BaleGainDialogComponent extends FormDialogDirective<BaleGain> {

  constructor(
    baleGainsService: BaleGainsService,
    baleGainFormControl: BaleGainFormControlService,
    matDialog: MatDialogRef<BaleGainDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<BaleGain, MiscanthusStack>,
  ) {
    super(baleGainsService, baleGainFormControl, matDialog, data);
  }

}
