import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarViewComponent} from './calendar-view/calendar-view.component';
import {CalendarDayComponent} from './calendar-day/calendar-day.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CalendarItemContainerDirective} from './calendar-item-container.directive';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import { MonthYearSelectorComponent } from './month-year-selector/month-year-selector.component';
import {CalendarNotesModule} from './calendar-notes/calendar-notes.module';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    CalendarViewComponent,
    CalendarDayComponent,
    CalendarItemContainerDirective,
    MonthYearSelectorComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatCardModule,
    DragDropModule,
    GaiaSharedModule,
    ReactiveFormsModule,
    CalendarNotesModule,
    MatButtonModule,
  ],
  exports: [
    CalendarViewComponent,
    MonthYearSelectorComponent
  ]
})
export class RestfulCalendarModule { }
