import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProcessorsTableComponent} from './processors-table/processors-table.component';
import {ProcessorShowComponent} from './processor-show/processor-show.component';

const routes: Routes = [
  { path: '', component: ProcessorsTableComponent },
  { path: ':id', component: ProcessorShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessorsRoutingModule { }
