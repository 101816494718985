<mat-table [dataSource]="baleLosses" [ngClass.lt-md]="'mobile-table'">

  <ng-container matColumnDef="bales_lost">
    <mat-header-cell *matHeaderCellDef>
      <span i18n="bales lost">Bales Lost</span>
      <gaia-shared-cell-mobile-label i18n="mobile label for bales lost">Lost</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.bales_lost}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="reason">
    <mat-header-cell *matHeaderCellDef>
      <span i18n="reason for bale loss">Reason</span>
      <gaia-shared-cell-mobile-label i18n="mobile label for bale loss reason">Reason</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.reason}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date_of_loss">
    <mat-header-cell *matHeaderCellDef>
      <span i18n="date bales where lost">Date of Loss</span>
      <gaia-shared-cell-mobile-label i18n="mobile label for date bales were lost">Date Lost</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.date_of_loss | date}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
    <mat-cell class="w-75" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Stack menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editBaleLoss(element)">
          <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
          <span i18n="edit">Edit</span>
        </button>
        <button mat-menu-item (click)="deleteBaleLoss(element)">
          <mat-icon>{{gaiaIconSet.delete}}</mat-icon>
          <span i18n="edit">Delete</span>
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
