<mat-card class="vertical-field">
  <mat-card-header>
    <mat-icon mat-card-avatar>contact_page</mat-icon>
    <mat-card-title i18n>Find Customer</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <input matInput #searchBox type="text"
           (input)="doSearch(searchBox.value)"
           placeholder="Search by email"
           [matAutocomplete]="auto"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCustomer($event)">
      <mat-option *ngFor="let customer of matches" [value]="customer">
        <div [ngSwitch]="customer.type_enum">
          <div *ngSwitchCase="customerTypes.direct">
            <mat-icon>{{ gaiaIcons.contacts }}</mat-icon>
            {{customer.record.name}} - {{customer.record.email}}
          </div>
          <div *ngSwitchCase="customerTypes.stockist">
            <mat-icon>{{ gaiaIcons.stockists }}</mat-icon>
            {{customer.record.account_name}}
          </div>
          <div *ngSwitchCase="customerTypes.brand_ambassador">
            <mat-icon>{{ gaiaIcons.brand_ambassadors }}</mat-icon>
            {{customer.record.account_name}}
          </div>
          <div *ngSwitchCase="customerTypes.commercial_user">
            <mat-icon>{{ gaiaIcons.commercial_users }}</mat-icon>
            {{customer.record.account_name}}
          </div>
          <div *ngSwitchDefault>
            {{customer.record.name}}
            {{customer.record.email}}
          </div>
      </div>
      </mat-option>
    </mat-autocomplete>
  </mat-card-content>
  <mat-card-actions>
    <button mat-icon-button color="primary" type="button" aria-label="" (click)="createCustomer()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
