import {Component, Inject, OnInit} from '@angular/core';
import {StaffMemberService} from '../staff-member.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {StaffMember} from '../staff-member.model';

@Component({
  selector: 'app-staff-member-contact-qr-dialog',
  templateUrl: './staff-member-contact-qr-dialog.component.html',
  styleUrls: ['./staff-member-contact-qr-dialog.component.scss']
})
export class StaffMemberContactQrDialogComponent implements OnInit {

  qrCodeUrl: string;
  staffMember: StaffMember;
  dialogTitle: string;

  constructor(
    private staffMemberService: StaffMemberService,
    @Inject(MAT_DIALOG_DATA) private data: {record: StaffMember},
  ){ }

  ngOnInit(): void {
    this.staffMember = this.data.record;
    this.qrCodeUrl = this.staffMemberService.getQRCodeUrl(this.staffMember);
    this.dialogTitle = `${this.staffMember.first_name} ${this.staffMember.surname} Contact QR Code`;
  }

}
