import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyOfficeShowComponent } from './company-office-show/company-office-show.component';
import { CompanyOfficesTableComponent } from './company-offices-table/company-offices-table.component';
import { CompanyOfficeNewDialogComponent } from './company-office-new-dialog/company-office-new-dialog.component';
import { CompanyOfficeFormComponent } from './company-office-form/company-office-form.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {AddressesModule, GaiaSharedModule, TelephoneModule} from '@terravesta/gaia-shared';
import {RouterModule} from '@angular/router';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import { CompanyOfficeSelectComponent } from './company-office-select/company-office-select.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ImagesModule} from '../../system-resources/images/images.module';



@NgModule({
  declarations: [
    CompanyOfficeShowComponent,
    CompanyOfficesTableComponent,
    CompanyOfficeNewDialogComponent,
    CompanyOfficeFormComponent,
    CompanyOfficeSelectComponent
  ],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        GaiaSharedModule,
        RouterModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FlexModule,
        AddressesModule,
        MatSelectModule,
        TelephoneModule,
        MatCheckboxModule,
        ImagesModule
    ],
  exports: [
    CompanyOfficeSelectComponent,
  ]
})
export class CompanyOfficesModule { }
