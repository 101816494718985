import {Observable} from 'rxjs';
import {RestApiService} from './rest-api.service';

export abstract class DashboardService<T, TD> extends RestApiService<T> {

  dashboardStats(): Observable<TD> {
    return this.httpGet<TD>(this.generateNonRecordUrl('dashboard_stats'));
  }

}
