import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import {Haulier} from '../../haulier.model';
import {HaulierDriversService} from '../haulier-drivers.service';
import {HaulierDriver} from '../haulier-driver.model';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {AppRoutes} from '../../../../app-routing.module';
import {HauliersRoutes} from '../../hauliers-routing.module';
import {MatDialog} from '@angular/material/dialog';
import {HaulierDriverNewDialogComponent} from '../haulier-driver-new-dialog/haulier-driver-new-dialog.component';

@Component({
  selector: 'app-haulier-drivers-embed-table',
  templateUrl: './haulier-drivers-embed-table.component.html',
  styleUrls: ['./haulier-drivers-embed-table.component.scss']
})
export class HaulierDriversEmbedTableComponent implements OnInit, OnChanges {

  @Input()
  haulier: Haulier;

  gaiaIconSet = GaiaIconSet;
  appRoutes = AppRoutes;
  haulierRoutes = HauliersRoutes;

  drivers: Array<HaulierDriver>;
  displayedColumns = ['first_name', 'surname', 'email', 'phone', 'active', 'menu'];

  constructor(
    private haulierDriversService: HaulierDriversService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.haulier){
      if (changes.haulier.currentValue) {
        this.loadDrivers();
      }
    }
  }

  loadDrivers(){
    this.haulierDriversService.getRecords({parentId: this.haulier.id, pageNumber: 0, pageSize: 30})
      .subscribe((drivers) => {
        this.drivers = drivers.records;
      });
  }

  addDriver() {
    const dialogRef = this.matDialog.open(HaulierDriverNewDialogComponent, {
      data: {
        parent: this.haulier,
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadDrivers();
    });
  }
}
