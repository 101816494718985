import { Injectable } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PriceGroup, PricePoint, ProductPriceList} from './price-group.model';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {orderBy} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PriceGroupFormControlService implements GaiaRecordFormControl<PriceGroup> {

  constructor(private formBuilder: FormBuilder) { }

  toFormGroup(priceGroup: PriceGroup): FormGroup {
    const productsArray = new FormArray([]);
    if (priceGroup.products){
      priceGroup.products.forEach((price) => {
        productsArray.push(this.productPriceListFormGroup(price));
      });
    }
    return this.formBuilder.group({
      name: new FormControl(priceGroup.name, Validators.required),
      default_group_contact: new FormControl(priceGroup.default_group_contact),
      default_group_stockist: new FormControl(priceGroup.default_group_stockist),
      default_group_brand_ambassador: new FormControl(priceGroup.default_group_brand_ambassador),
      default_group_commercial_user: new FormControl(priceGroup.default_group_commercial_user),
      active: new FormControl(priceGroup.active),
      products: productsArray,
    });
  }

  productPriceListFormGroup(productPriceList: ProductPriceList): FormGroup {
    const pricePoints = new FormArray([]);
    if (productPriceList.price_points){
      // Sort price points here, it was becoming problematic to do them in the component
      const sortedPoints = orderBy(productPriceList.price_points, ['min_qty'], ['asc']);
      sortedPoints.forEach((point) => {
        pricePoints.push(this.priceToFormGroup(point));
      });
    }
    return this.formBuilder.group({
      id: new FormControl(productPriceList.id, Validators.required),
      name: new FormControl(productPriceList.name),
      sku: new FormControl(productPriceList.sku),
      price_points: pricePoints,
    });
  }

  priceToFormGroup(price: PricePoint): FormGroup {
    return this.formBuilder.group({
      ex_vat_price: new FormControl(price.ex_vat_price, Validators.required),
      vat_price: new FormControl(price.vat_price, Validators.required),
      min_qty: new FormControl(price.min_qty, [Validators.required, Validators.min(1)]),
      vat_rate: new FormControl(price.vat_rate, Validators.required),
      to_delete: new FormControl(false),
    });
  }
}
