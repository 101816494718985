import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClimateGridViewerComponent } from './climate-grid-viewer/climate-grid-viewer.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {ClimateDataSourcesModule, PhanesComponentsModule} from '@terravesta/phanes';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxEchartsModule} from 'ngx-echarts';
import {FlexModule} from '@angular/flex-layout';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ClimateGridGraphsComponent } from './climate-grid-graphs/climate-grid-graphs.component';

@NgModule({
  declarations: [
    ClimateGridViewerComponent,
    ClimateGridGraphsComponent,
  ],
  exports: [
    ClimateGridViewerComponent,
    ClimateGridGraphsComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    GaiaGeneralModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    PhanesComponentsModule,
    MatTabsModule,
    NgxEchartsModule,
    FlexModule,
    GaiaSharedModule,
    MatProgressSpinnerModule,
    ClimateDataSourcesModule
  ]
})
export class ClimateGridUkModule { }
