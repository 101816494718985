import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Directive, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShowRecordWrapperComponent} from '../gaia-general/show-record-wrapper/show-record-wrapper.component';
import {FormGroup} from '@angular/forms';
import {RestApiService} from '../services/rest-api.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GaiaRecord} from '../models/gaia-record.model';
import {GaiaRecordFormControl} from '../services/gaia-record-form-control';
import {InternalNoteTrackingService} from '../internal-notes/internal-note-tracking.service';
import {GaiaLogger} from '../../utils/common-functions';
import {GaiaIconSet} from '../models/gaia-icon-set';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {RecordReloadConfirmDialogComponent} from '../gaia-general/record-reload-confirm-dialog/record-reload-confirm-dialog.component';
import {AppRoutes} from '../app-routing.module';
import {GrowingRoutes} from '../growing/growing-routing.module';

@Directive({})
export abstract class ShowPageDirective<T extends GaiaRecord> implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {


  @ViewChild(ShowRecordWrapperComponent, {static: false})
  showWrapper: ShowRecordWrapperComponent;

  gaiaIconSet = GaiaIconSet;
  saveSubscription: Subscription;
  deleteSubscription: Subscription;

  form: FormGroup;
  record: T;

  // Just import all routes to make life easier
  // TODO finish
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;

  protected constructor(
    protected recordService: RestApiService<T>,
    protected recordFormControl: GaiaRecordFormControl<T>,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog,
    private internalNotesTracker: InternalNoteTrackingService,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  abstract recordGot(record: T);
  recordLoadCompleted(){}
  deleteSuccessful(){}

  ngOnInit(): void {
    this.route.paramMap.subscribe(() => {
      this.loadRecord();
    });
    // Moved this to the paramMap to deal with page changes when in the component
    // this.loadRecord();
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
  }

  lockScreen(message?: string): void {
    this.showWrapper.lockScreen(message);
  }

  unlockScreen(): void {
    this.showWrapper.unlockScreen();
  }

  showErrors(errors): void {
    this.showWrapper.showErrors(errors);
  }

  save(): void {
    GaiaLogger.log('save clicked', this.form.value);
    if (this.form.valid) {
      const values = this.form.value;
      this.doUpdate(values);
    }
  }

  delete(): void {
    this.recordService.deleteRecord({ recordId: this.record.id }).subscribe(() => {
      this.unsetNoteTracker();
      this.snackBar.open('Delete Successful', 'Dismiss');
      this.deleteSuccessful();
    },
    () => {
      this.snackBar.open('Delete failed', 'Dismiss');
    })
  }

  reloadRecord(): void {
    if (this.form.dirty){
      const dialogRef = this.dialog.open(RecordReloadConfirmDialogComponent);
      dialogRef.afterClosed().subscribe((result) => {
        switch (result.response){
          case RecordReloadConfirmDialogComponent.RELOAD_NO_SAVE:
            this.loadRecord();
            break;
          case RecordReloadConfirmDialogComponent.NO_RELOAD:
            // Do Nothing
            break;
          case RecordReloadConfirmDialogComponent.SAVE_AND_RELOAD:
            this.save();
            break;
        }
      });
    } else {
      // Form not dirty so just reload
      this.loadRecord();
    }
  }

  protected loadRecord(){
    this.record = null;
    GaiaLogger.log('loading record');
    const id = this.route.snapshot.paramMap.get('id');
    this.recordService.getRecord({recordId: id}).subscribe(
      record => {
        GaiaLogger.log('loading record', record);
        this.updateRecord(record);
      }
    );
  }

  protected updateRecord(newRecord: T){
    GaiaLogger.log('Record accessed', newRecord);
    this.record = newRecord;
    this.recordGot(newRecord);
    this.form = this.recordFormControl.toFormGroup(newRecord);
    GaiaLogger.log(this.form);
    if (this.internalNotesTracker) {
      this.internalNotesTracker.setRecord(this.record);
    }
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    this.changeDetectorRef.detectChanges();
    if (this.showWrapper) {
      this.saveSubscription = this.showWrapper.saveClicked.subscribe(() => this.save());
      this.deleteSubscription = this.showWrapper.deleteConfirmed.subscribe(() => this.delete());
    }
    this.recordLoadCompleted();
  }

  protected doUpdate(newData: T, message?: string): void {
    this.lockScreen();
    this.recordService.updateRecord({recordId: this.record.id, data: newData}).subscribe(
      (record) => {
        this.updateRecord(record);
        const snackMessage = message ? message : 'Saved Successfully';
        this.snackBar.open(snackMessage, 'Dismiss');
      },
      (error) => {
        this.showErrors(error.error);
      },
      () => {
        this.unlockScreen();
      }
    );
  }

  ngOnDestroy(): void {
    this.unsetNoteTracker();
  }

  private unsetNoteTracker(){
    if (this.internalNotesTracker) {
      this.internalNotesTracker.unsetRecord();
    }
  }

}
