import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackNoticePeriodsListComponent } from './stack-notice-periods-list/stack-notice-periods-list.component';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { StackNoticePeriodFormDialogComponent } from './stack-notice-period-form-dialog/stack-notice-period-form-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexModule} from '@angular/flex-layout';
import {StackNoticePeriodSelectComponent} from './stack-notice-period-select/stack-notice-period-select.component';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [StackNoticePeriodsListComponent, StackNoticePeriodFormDialogComponent, StackNoticePeriodSelectComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        GaiaGeneralModule,
        PhanesComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        FlexModule,
        MatSelectModule,
    ],
    exports: [
        StackNoticePeriodsListComponent,
        StackNoticePeriodSelectComponent
    ]
})
export class StackNoticePeriodsModule { }
