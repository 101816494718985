<div class="notes-wrapper" fxLayout="column">
  <mat-toolbar color="accent">
    <h2 mat-dialog-title>{{ noteTitle }}</h2>
  </mat-toolbar>
  <mat-dialog-content fxFlex="1 1 auto">
    <div fxLayout="column" fxLayoutAlign="start center" *ngIf="notes.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <app-internal-note-card [note]="note" *ngFor="let note of notes.getRecords() | async"
      (noteEdited)="reload()"></app-internal-note-card>

    <mat-paginator [length]="notes.totalRecords" [pageSize]="10"></mat-paginator>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="createNew()">New Note</button>
    <button mat-button [mat-dialog-close]="true">Close</button>
  </mat-dialog-actions>
</div>
