import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ContractedField,
  ContractedFieldHarvestSeries,
  ContractedFieldMapData, ContractedFieldUniversalSearchPreview, FieldContractHistory, KMLIntersectionResult,
  ParcelLocationData, PlantingReport, PlantingSummaryStats
} from './contracted-field.model';
import {Observable} from 'rxjs';
import {flattenSearch, GPSPoint, RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';
import {Grower} from '../growers/grower.model';
import * as FileSaver from 'file-saver';
import {AgriculturalFieldsService} from '../agricultural-fields.service';
import {UniversallySearchableService} from '../../universal-search/universally-searchable.service';

@Injectable({
  providedIn: 'root'
})
export class ContractedFieldsService extends AgriculturalFieldsService<ContractedField> implements UniversallySearchableService<ContractedFieldUniversalSearchPreview> {

  constructor(httpClient: HttpClient) {
    super('contracted_fields', 'contracted_field', httpClient);
  }

  allTermContracts(contractedField: ContractedField): Observable<Array<FieldContractHistory>> {
    return this.httpGet(this.generateUrl({ recordId: contractedField.id }, 'all_term_contracts'));
  }

  getContractedFieldsForGrower(grower: Grower, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<ContractedField>> {
    params.search = {
      ...params.search,
      grower: {uuid: grower.id},
      // TODO fix me once contracting is finished
      // contracted: true, // Only get contracted fields with this method
    };
    return this.getRecords(params);
  }

  getPreviouslyContractedFieldsForGrower(grower: Grower, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<ContractedField>> {
    params.search = {
      ...params.search,
      grower: {uuid: grower.id},
      contracted: false, // Only get non contracted fields with this method
    };
    return this.getRecords(params);
  }


  getParcelLocationInformation(parcelReference: string): Observable<ParcelLocationData> {
    return this.httpPost<ParcelLocationData>(this.generateNonRecordUrl('parcel_reference_to_location'),
      { field_reference_number: parcelReference });
  }

  kmlIntersection(kmlFile: File) {
    const formData = new FormData();
    formData.append('kml_filepath', kmlFile);
    return this.httpPost<Array<KMLIntersectionResult>>(this.generateNonRecordUrl('kml_intersection'), formData);
  }

  getHarvestSeries(field: ContractedField) {
    return this.httpGet<ContractedFieldHarvestSeries>(this.generateUrl({ recordId: field.id }, 'harvest_series'));
  }

  map(searchValues: any = {}): Observable<Array<ContractedFieldMapData>> {
    const flattened = flattenSearch(searchValues, 'search');
    return this.httpGet<Array<ContractedFieldMapData>>(this.generateNonRecordUrl('map'),
      { ...flattened });
  }

  withinMilesOf(point: GPSPoint, miles: number): Observable<Array<ContractedFieldMapData>> {
    return this.httpGet<Array<ContractedFieldMapData>>(this.generateNonRecordUrl('within_miles_of'),
      {
        latitude: point.latitude,
        longitude: point.longitude,
        miles
      });
  }

  plantingByPostCode(postCodeRegions: Array<string>): Observable<Array<PlantingReport>> {
    return this.httpGet<Array<PlantingReport>>(this.generateNonRecordUrl('planting_by_post_code'),
      {
        post_code_areas: postCodeRegions
      });
  }

  sentinelImage(contractedField: ContractedField, sentinelValue: string, startDate: any): Observable<ArrayBuffer> {
    return this.httpClient.get(this.generateUrl({recordId: contractedField.id}, 'sentinel_image'), {
      // eslint-disable-next-line max-len
      withCredentials: true, responseType: 'arraybuffer', params: {start_date: startDate, function_id: sentinelValue }
    });
  }

  sentinelImageDates(contractedField: ContractedField, sentinelValue: string, startDate: any, endDate: any, cloudCoverMin: number,
                     cloudCoverMax: number): Observable<Array<Date>> {
    return this.httpGet<Array<Date>>(this.generateUrl({recordId: contractedField.id}, 'search_sentinel_image_dates'),
      {
        start_date: startDate, end_date: endDate, function_id: sentinelValue,
        cloud_cover_min: cloudCoverMin, cloud_cover_max: cloudCoverMax
      });
  }

  downloadPlantingKML(year: number, completed: () => void) {
    return this.httpGetBuffered(this.generateNonRecordUrl('planting_kml'), { year })
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/vnd.google-earth.kml+xml'});
          FileSaver.saveAs(file, `planted_in_${year}.kml`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  addToTermContract(contractedField: ContractedField, formData: any) {
    return this.httpPost<ContractedField>(this.generateUrl({ recordId: contractedField.id}, 'add_to_term_contract'), formData);
  }

  removeFromCurrentContract(field: ContractedField, formData: any): Observable<ContractedField> {
    return this.httpPost<ContractedField>(this.generateUrl({ recordId: field.id }, 'remove_from_current_contract'), formData);
  }

  yearlyPlantingStats(year: number): Observable<PlantingSummaryStats> {
    return this.httpGet<PlantingSummaryStats>(this.generateNonRecordUrl('planting_summary_stats'), { year });
  }

  universalSearchPreviewData(recordId: string): Observable<ContractedFieldUniversalSearchPreview> {
    return this.httpGet(this.generateUrl({ recordId }, 'universal_search_preview_data'));
  }
}


