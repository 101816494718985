import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {Haulier} from './haulier.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AddressFormControlService} from '@terravesta/gaia-shared';

@Injectable({
  providedIn: 'root'
})
export class HaulierFormControlService implements GaiaRecordFormControl<Haulier> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormBuilder: AddressFormControlService,
  ) { }

  toFormGroup(record: Haulier): FormGroup {
    return this.formBuilder.group({
      account_name: new FormControl(record.account_name),
      phone: new FormControl(record.phone),
      email: new FormControl(record.email),
      billing_address: this.addressFormBuilder.toFormControl(record.billing_address),
      active: new FormControl(record.active),
    });
  }
}
