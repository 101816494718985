import {Component} from '@angular/core';
import {RecordFormDirective} from '../../../../components/record-form.directive';
import {HaulierDriver} from '../haulier-driver.model';
import {Contact} from '../../../../contacts/contact.model';

@Component({
  selector: 'app-haulier-driver-form',
  templateUrl: './haulier-driver-form.component.html',
  styleUrls: ['./haulier-driver-form.component.scss']
})
export class HaulierDriverFormComponent extends RecordFormDirective<HaulierDriver>{

  selectedContact: Contact;

}
