import { Component, OnInit } from '@angular/core';
import {ScreenLockService} from '../screen-lock.service';

@Component({
  selector: 'app-screen-lock',
  templateUrl: './screen-lock.component.html',
  styleUrls: ['./screen-lock.component.scss']
})
export class ScreenLockComponent implements OnInit {

  constructor(
    public screenLockService: ScreenLockService,
  ) { }

  ngOnInit(): void {
  }

}
