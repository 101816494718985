import { Component } from '@angular/core';
import {RecordFormDirective} from '../../../../components/record-form.directive';
import {TermContract} from '../term-contract.model';

@Component({
  selector: 'app-term-contract-form',
  templateUrl: './term-contract-form.component.html',
  styleUrls: ['./term-contract-form.component.scss']
})
export class TermContractFormComponent extends RecordFormDirective<TermContract> {


}
