import {AfterViewInit, Directive, OnInit, ViewChild} from '@angular/core';
import {GaiaRecord} from '../../../models/gaia-record.model';
import {PaginatedComponent, PaginatedDataSource, PaginatedState} from '@terravesta/gaia-shared';
import {MatPaginator} from '@angular/material/paginator';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {RequiresApprovalStatuses} from './requires-approval.model';
import {RailsGetRecordsParams} from '@terravesta/phanes';
import {RequiresApprovalService} from './requires-approval.service';
import {tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Directive({
})
export abstract class ForApprovalTableDirective<T extends GaiaRecord> implements PaginatedComponent, OnInit, AfterViewInit {

  gaiaIconSet = GaiaIconSet;
  approvalStatuses = RequiresApprovalStatuses;

  dataSource: PaginatedDataSource<T>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  protected constructor(
    protected recordsService: RequiresApprovalService<T>,
    protected matDialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected dialog: any
  ){
  }

  ngOnInit(): void {
    this.dataSource = new PaginatedDataSource<T>(
      (params: RailsGetRecordsParams) => this.recordsService.forApproval(params)
    );
    this.dataSource.loadRecordsPaginated(this.getCurrentSearch());
  }

  ngAfterViewInit(): void {
    this.paginator.page
      .pipe(
        tap(() => this.dataSource.loadRecordsPaginated(this.getCurrentSearch()))
      ).subscribe();
  }

  update(): void {
    this.dataSource.loadRecordsPaginated(this.getCurrentSearch());
  }

  viewRecord(record: T) {
    this.matDialog.open(this.dialog, {
      data: {
        record
      }
    });
  }

  getCurrentSearch(): PaginatedState {
    return {
      pageNumber: this.paginator ? this.paginator.pageIndex : 0,
      pageSize: this.paginator ? this.paginator.pageSize : 30,
    };
  }


  setCurrentSearch(search: PaginatedState): any {
  }

  approve(record: T){
    this.recordsService.approve(record).subscribe((response) => {

    });

  }

  reject(record: T){

  }

  bulkApprove(records: Array<T>){

  }

  bulkReject(records: Array<T>) {}

}
