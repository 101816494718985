import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaleLossFormComponent } from './bale-loss-form/bale-loss-form.component';
import { BaleLossDialogComponent } from './bale-loss-dialog/bale-loss-dialog.component';
import { BaleLossesEmbedTableComponent } from './bale-losses-embed-table/bale-losses-embed-table.component';
import {MatTableModule} from '@angular/material/table';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {PhanesComponentsModule} from '@terravesta/phanes';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
    declarations: [BaleLossFormComponent, BaleLossDialogComponent, BaleLossesEmbedTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    GaiaSharedModule,
    ExtendedModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    GaiaGeneralModule,
    PhanesComponentsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
    exports: [
        BaleLossesEmbedTableComponent
    ]
})
export class BaleLossesModule { }
