import {GroupValueAccessorConnectorDirective} from '@terravesta/phanes';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {Component, forwardRef, Injector, Input} from '@angular/core';
import {FormArray, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SpecificationCheckFormControlService} from '../specification-check-form-control.service';
import {SpecificationCheck} from '../specification-check.model';

@Component({
  selector: 'app-specification-checks-embed-form',
  templateUrl: './specification-checks-embed-form.component.html',
  styleUrls: ['./specification-checks-embed-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpecificationChecksEmbedFormComponent),
      multi: true
    }
  ]
})
export class SpecificationChecksEmbedFormComponent extends GroupValueAccessorConnectorDirective {

  gaiaIconSet = GaiaIconSet;

  @Input()
  specificationUnits: string = null;

  constructor(
    injector: Injector,
    private specificationFormControl: SpecificationCheckFormControlService,
  ) {
    super(injector);
  }

  get formArray(): FormArray {
    return this.control as FormArray;
  }

  addSpecification() {
    this.formArray.push(this.specificationFormControl.toRow({} as SpecificationCheck));
  }
}
