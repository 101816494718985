import { Component, OnInit } from '@angular/core';
import {ContractedFieldsService} from '../contracted-fields.service';
import {FormControl} from '@angular/forms';
import {PlantingReport} from '../contracted-field.model';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-planting-by-area',
  templateUrl: './planting-by-area.component.html',
  styleUrls: ['./planting-by-area.component.scss']
})
export class PlantingByAreaComponent implements OnInit {

  control: FormControl = new FormControl('NR');

  currentReport: PlantingReport;

  displayedColumns = ['year_planted', 'total_area'];

  constructor(
    private contractedFieldsService: ContractedFieldsService,
  ) { }

  ngOnInit(): void {

  }

  getData() {
    if (this.control.value) {
      this.contractedFieldsService.plantingByPostCode([this.control.value]).subscribe(
        (response) => {
          GaiaLogger.log(response);
          this.currentReport = response[0];
        }
      );
    }
  }
}
