
<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      Contacts
    </ng-template>
    <app-show-record-wrapper *ngIf="form" [form]="form">
      ID: {{ record?.id }}
      <br>
      <br>
      <app-contact-form [form]="form" [record]="record"></app-contact-form>

    </app-show-record-wrapper>
  </mat-tab>

  <mat-tab [disabled]="!hasPostCode">
    <ng-template mat-tab-label>
      <span i18n  matTooltip="Contact doesn't have a post code, so cannot map"
            [matTooltipDisabled]="hasPostCode">Lead Overview</span>
    </ng-template>
    <ng-template matTabContent>
      <app-lead-overview *ngIf="hasPostCode" [currentContact]="record"></app-lead-overview>
    </ng-template>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon"></mat-icon>
      Accounts
    </ng-template>
    <app-account-list-embed *ngIf="form">
      <ng-template matTabContent>
        <app-account-list-embed></app-account-list-embed>
      </ng-template>
    </app-account-list-embed>

  </mat-tab>


</mat-tab-group>






