<mat-table [dataSource]="fieldHarvests" [ngClass.lt-md]="'mobile-table'">

  <ng-container matColumnDef="contracted_field_name">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Field Name</span>
      <gaia-shared-cell-mobile-label i18n>Field</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.contracted_field.name}} </mat-cell>
  </ng-container>


  <ng-container matColumnDef="bale_count">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Bale Count</span>
      <gaia-shared-cell-mobile-label i18n>Bales</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.bale_count}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="bale_type">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Bale Type</span>
      <gaia-shared-cell-mobile-label i18n>Type</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.bale_type.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date_added">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Date Added</span>
      <gaia-shared-cell-mobile-label i18n>Added On</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.added_to_stack | date}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
    <mat-cell class="w-75" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Stack menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editFieldHarvest(element)">
          <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
          <span i18n="edit">Edit</span>
        </button>
        <button mat-menu-item (click)="deleteFieldHarvest(element)">
          <mat-icon>{{gaiaIconSet.delete}}</mat-icon>
          <span i18n="edit">Delete</span>
        </button>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
