import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'repeatFor'
})
export class RepeatForPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const data = Array.from(Array(value).keys());
    return (data);
  }

}
