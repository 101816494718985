import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-date-selector-dialog',
  templateUrl: './date-selector-dialog.component.html',
  styleUrls: ['./date-selector-dialog.component.scss']
})
export class DateSelectorDialogComponent implements OnInit {

  @ViewChild(MatInput) dateSelector;

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DateSelectorDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  dateSelected() {
    this.dialogRef.close({
      selectedDate: this.dateSelector.value,
    });
  }
}
