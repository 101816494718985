import { Component, OnInit } from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {SoilMeasurement} from '../soil-measurement.model';

@Component({
  selector: 'app-soil-measurement-form',
  templateUrl: './soil-measurement-form.component.html',
  styleUrls: ['./soil-measurement-form.component.scss']
})
export class SoilMeasurementFormComponent  extends RecordFormDirective<SoilMeasurement> implements OnInit {

  ngOnInit(): void {
  }

}
