import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {HaulierVehicle} from './haulier-vehicle.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HaulierVehiclesService extends RestApiService<HaulierVehicle> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('hauliers/{parentId}/haulier_vehicles', 'haulier_vehicle', httpClient);
  }
}
