<div mat-menu-item [matMenuTriggerFor]="contractsMenu">
  <mat-icon>{{ gaiaIcons.contracts }}</mat-icon>
  <span i18n>Contracts</span>
</div>
<mat-menu #contractsMenu="matMenu">
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.ESTABLISHMENT_CONTRACTS]" i18n="establishment contracts link">
    Establishment
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.TERM_CONTRACTS]" i18n="term contracts link">
    Term
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.CONTRACTS, contractRoutes.RPI_ADJUSTMENTS]" i18n="rpi adjsument link">
    RPI Adjustments
  </a>
  <a mat-menu-item [routerLink]="[appRoutes.GROWING, growingRoutes.GROWER_CONTRACTS]" i18n="legacy contracts">
    Legacy Contracts
  </a>
</mat-menu>
