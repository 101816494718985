import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AssetsMenuComponent} from "./assets-menu/assets-menu.component";
import {AssetsTableComponent} from "./assets-table/assets-table.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {ContractsModule} from "../growing/contracts/contracts.module";
import {RouterModule} from "@angular/router";
import {AssetsPopupComponent} from "./assets-popup/assets-popup.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {LoadingComponent} from "../loading/loading.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {AssetShowComponent} from "./asset-show/asset-show.component";
import {MatTabsModule} from "@angular/material/tabs";
import {AssetImagePopupComponent} from "./asset-image-popup/asset-image-popup.component";
import {FlexModule} from "@angular/flex-layout";
import {MatCardModule} from "@angular/material/card";
import {NgxEchartsModule} from "ngx-echarts";
import {GaiaGeneralModule} from "../gaia-general/gaia-general.module";
import {MatExpansionModule} from "@angular/material/expansion";

@NgModule({
  declarations: [
    AssetsTableComponent,
    AssetsMenuComponent,
    AssetsPopupComponent,
    LoadingComponent,
    AssetShowComponent,
    AssetImagePopupComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ContractsModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatTabsModule,
    FlexModule,
    MatCardModule,
    NgxEchartsModule,
    GaiaGeneralModule,
    MatExpansionModule
  ],
  exports: [
    AssetsTableComponent,
    AssetsMenuComponent,
    AssetsPopupComponent,
    LoadingComponent,
    AssetShowComponent,
    AssetImagePopupComponent
  ]
})
export class AssetsManagementModule { }
