import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {CommercialUser} from './commercial-user.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommercialUserFormControlService implements GaiaRecordFormControl<CommercialUser>{

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(commercialUser: CommercialUser): FormGroup {
    return this.formBuilder.group({
      account_name: new FormControl(commercialUser.account_name, Validators.required),
      default_price_group_id: new FormControl(commercialUser.default_price_group ? commercialUser.default_price_group.id : null,
                                                Validators.required),
      on_account: new FormControl(commercialUser.on_account),
      payment_term_days: new FormControl(commercialUser.payment_term_days, Validators.pattern('^\\d+$')),
    });
  }

}
