<mat-drawer-container>
  <mat-drawer mode="side" [opened]="showMenu">
    <app-side-drawer></app-side-drawer>
  </mat-drawer>
  <mat-drawer-content>
    <app-top-toolbar *ngIf="showToolBar" (menuRequestChanged)="menuOpen($event)"></app-top-toolbar>
    <div class="main-panel" [class.fullscreen]="fullScreenMode">
      <div class="router-container">
        <router-outlet></router-outlet>
        <router-outlet name="print"></router-outlet>
      </div>

      <div class="side-panel" *ngIf="!hideSideColumn">
        <div class="side-panel-menu">
          <button mat-icon-button color="primary" (click)="closeSideMenu()">
            <mat-icon *ngIf="!collapseSideColumn else showMenuToggle">{{ gaiaIconSet.hide_side_column }}</mat-icon>
            <ng-template #showMenuToggle>
              <mat-icon>{{ gaiaIconSet.show_side_column }}</mat-icon>
            </ng-template>
          </button>
        </div>
        <div class="side-panel-content" [class.hide-side-column]="this.collapseSideColumn">
          <app-internal-notes-column></app-internal-notes-column>
        </div>
      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>
