import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiscanthusHarvestsModule} from './miscanthus-harvests/miscanthus-harvests.module';
import {BaleCollectionsModule} from './bale-collections/bale-collections.module';
import {LegacyHarvestsModule} from './legacy-harvests/legacy-harvests.module';
import { HarvestReviewerComponent } from './harvest-reviewer/harvest-reviewer.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {GaiaGeneralModule} from '../gaia-general/gaia-general.module';
import {MatInputModule} from '@angular/material/input';
import {ContractorsModule} from './contractors/contractors.module';
import {BaleTypesModule} from './bale-types/bale-types.module';
import {ProcessorsModule} from './processors/processors.module';
import {OperationalRegionsModule} from './operational-regions/operational-regions.module';
import {MiscanthusStacksModule} from './miscanthus-stacks/miscanthus-stacks.module';
import {HauliersModule} from './hauliers/hauliers.module';
import {BalerRecordsModule} from './baler-records/baler-records.module';
import { OperationsMenuComponent } from './operations-menu/operations-menu.component';
import {RouterModule} from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import {HaulageMatricesModule} from './haulage-matrix/haulage-matrices.module';

@NgModule({
  declarations: [HarvestReviewerComponent, OperationsMenuComponent],
  imports: [
    CommonModule,
    MiscanthusHarvestsModule,
    BaleCollectionsModule,
    LegacyHarvestsModule,
    ContractorsModule,
    ProcessorsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    GaiaGeneralModule,
    MatInputModule,
    BaleTypesModule,
    OperationalRegionsModule,
    MiscanthusStacksModule,
    HauliersModule,
    BalerRecordsModule,
    HaulageMatricesModule,
    RouterModule,
    MatMenuModule
  ],
  exports: [
    OperationsMenuComponent
  ]
})
export class OperationsModule { }
