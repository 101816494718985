import {Component, Input, OnInit} from '@angular/core';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {AppRoutes} from '../../../../app-routing.module';
import {GrowingRoutes} from '../../../growing-routing.module';
import {TermContractHistory} from '../term-contract.model';

@Component({
  selector: 'app-term-contract-history-table',
  templateUrl: './term-contract-history-table.component.html',
  styleUrls: ['./term-contract-history-table.component.scss']
})
export class TermContractHistoryTableComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  displayedColumns = ['added', 'removed', 'current'];

  @Input()
  histories: Array<TermContractHistory>;

  constructor() { }

  ngOnInit(): void {
  }

}
