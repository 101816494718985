import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessorConnectorDirective} from '@terravesta/phanes';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ContactsService} from '../contacts.service';
import {debounceTime} from 'rxjs/operators';
import {Contact} from '../contact.model';

@Component({
  selector: 'app-contact-email-input',
  templateUrl: './contact-email-input.component.html',
  styleUrls: ['./contact-email-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactEmailInputComponent),
      multi: true
    }
  ]
})
export class ContactEmailInputComponent extends ControlValueAccessorConnectorDirective implements OnInit {

  emailInUse = false;

  @Input()
  contact: Contact;

  constructor(
    private contactsService: ContactsService,
    inj: Injector,
  ) {
    super(inj);
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(
      debounceTime(800)
    ).subscribe((email) => {
        this.contactsService.checkForEmail(email).subscribe(
          (response) => {
            if (response) {
              // We have found contact with the same email address as the one entered
              const returnedContact = response as Contact;
              if (this.contact) {
                if (returnedContact.id !== this.contact.id) {
                  // Matched to existing but different contact
                  this.setEmailInUse();
                }
              } else {
                this.setEmailInUse();
              }
            } else {
              this.emailInUse = false;
            }
          }
        );
      }
    );
  }

  setEmailInUse(){
    // const control = this.form.get('email');
    // control.setErrors({incorrect: true});
    // control.markAsTouched();
    this.emailInUse = true;
  }

}
