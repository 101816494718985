import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {StackStorageMethod} from './stack-storage-method.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StackStorageMethodsService extends RestApiService<StackStorageMethod> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/stack_storage_methods', 'stack_storage_method', httpClient);
  }
}
