import { Component, OnInit } from '@angular/core';
import {OrderFormControlService} from '../order-form-control.service';
import {Order} from '../order.model';
import {FormGroup} from '@angular/forms';
import {OrdersService} from '../orders.service';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {NewDialogDirective} from '../../../components/new-dialog.directive';

@Component({
  selector: 'app-order-new-dialog',
  templateUrl: './order-new-dialog.component.html',
  styleUrls: ['./order-new-dialog.component.scss']
})
export class OrderNewDialogComponent extends NewDialogDirective<Order>{

  constructor(
    orderFormControl: OrderFormControlService,
    ordersService: OrdersService,
    private router: Router,
    public matDialog: MatDialogRef<OrderNewDialogComponent>,
  ) {
    super(ordersService, orderFormControl);
  }

  successfulSave(order: Order) {
    this.router.navigate([`/commercial-sales/orders/`, order.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
