<ng-container *ngIf="record">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.my_record}}</mat-icon>
        <span i18n>My Details</span>
      </ng-template>
      <ng-container *ngIf="form">
        <div [formGroup]="form">
          <app-show-record-wrapper [form]="form">
            <app-staff-member-form [form]="form"></app-staff-member-form>
          </app-show-record-wrapper>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.images}}</mat-icon>
        <span i18n>My Photo</span>
      </ng-template>
        <app-image-uploader [currentImage]="record.image" [record]="record"
                            [imageName]="imageName"
                            uploaderTitle="Staff Image" i18n-uploaderTitle=""></app-image-uploader>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.email}}</mat-icon>
        <span i18n>Email Signature</span>
      </ng-template>
      <app-staff-member-email-signature [staffMember]="record"></app-staff-member-email-signature>
    </mat-tab>
    <!--
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.absences}}</mat-icon>
        Leave
      </ng-template>
      <app-staff-absence-table [staffMember]="record"></app-staff-absence-table>
    </mat-tab>
    <mat-tab *ngIf="record.company_car">
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.company_car}}</mat-icon>
        Company Car
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="!record.company_car">
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.mileage_claims}}</mat-icon>
        Mileage Claims
      </ng-template>
      <app-staff-mileage-claims-table [staffMember]="record"></app-staff-mileage-claims-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.expense_claims}}</mat-icon>
        Expenses
      </ng-template>
      <app-staff-expense-claims-table [staffMember]="record"></app-staff-expense-claims-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{gaiaIconSet.appraisals}}</mat-icon>
        Appraisals
      </ng-template>
    </mat-tab>
    -->
  </mat-tab-group>
</ng-container>
