import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Processor} from '../../processor.model';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ScheduleMonth} from '../schedule-month.model';
import {ScheduleMonthFormControlService} from '../schedule-month-form-control.service';
import {BehaviorSubject} from 'rxjs';
import {ProcessorsService} from '../../processors.service';
import {NewRecordDialogComponent} from '../../../../gaia-general/new-record-dialog/new-record-dialog.component';
import {GaiaLogger} from '../../../../../utils/common-functions';

@Component({
  selector: 'app-schedule-month-create-dialog',
  templateUrl: './schedule-month-create-dialog.component.html',
  styleUrls: ['./schedule-month-create-dialog.component.scss']
})
export class ScheduleMonthCreateDialogComponent implements OnInit {

  form: FormGroup;
  processor: Processor;
  harvestYear: number;
  displayedColumns = ['harvest_year', 'month', 'planned_tonnage', 'adjustment_value', 'adjustment_text'];
  dataSource: BehaviorSubject<AbstractControl[]>;
  totalTonnage = 0;

  @ViewChild(NewRecordDialogComponent) newRecordWrapper: NewRecordDialogComponent;

  constructor(
    private formBuilder: FormBuilder,
    private scheduleMonthFormControl: ScheduleMonthFormControlService,
    private processorsService: ProcessorsService,
    public matDialog: MatDialogRef<ScheduleMonthCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ScheduleMonthCreateData
  ) {
    this.processor = data.processor;
    const today = new Date();
    if (today.getMonth() >= 2){
      this.harvestYear = today.getFullYear();
    }else{
      this.harvestYear = today.getFullYear() - 1;
    }
    this.form = this.formBuilder.group({
      schedule_months: new FormArray([]),
    });
    this.form.valueChanges.subscribe(() => {
      this.totalTonnage = 0;
      const list = this.form.get('schedule_months') as FormArray;
      list.controls.forEach((row) => {
        this.totalTonnage += row.get('planned_tonnage').value;
      });
    });
    this.dataSource = new BehaviorSubject<AbstractControl[]>((this.form.get('schedule_months') as FormArray).controls);
  }

  ngOnInit(): void {
  }

  save() {
    if (this.form.valid) {
      const data = this.form.value;
      GaiaLogger.log(data);
      this.processorsService.createYearlySchedule(this.processor, data).subscribe(
        (response) => {
          if (response.created) {
            this.matDialog.close();
          }
        },
        (errors) => {
          const errorArray = {};
          errors.error.forEach((error, index) => {
            GaiaLogger.log(error);
            if (error.harvest_year) {
              errorArray[index] = error.harvest_year[0];
            }
          });
          GaiaLogger.log(errorArray);
          this.newRecordWrapper.showErrors(errorArray);
          this.newRecordWrapper.unlockOverlay();
          GaiaLogger.log(errors);
        }
      );
    }
  }

  createForm() {
    const startMonth = 3;
    const formData = new FormArray([]);
    for (let i = 0; i < 12; i++) {
      let currentMonth = startMonth + i;
      let currentYear = Number(this.harvestYear);
      if (currentMonth > 11){
        currentMonth -= 12;
        currentYear += 1;
      }
      GaiaLogger.log(currentMonth, currentYear);
      const monthDate = new Date(currentYear, currentMonth, 1);
      GaiaLogger.log(monthDate);
      const schedule = { month: monthDate, harvest_year: this.harvestYear, year: currentYear } as ScheduleMonth;
      formData.push(this.scheduleMonthFormControl.toFormGroup(schedule));
    }
    this.form.setControl('schedule_months', formData);
    this.dataSource.next((this.form.get('schedule_months') as FormArray).controls);

  }
}

export interface ScheduleMonthCreateData {
  processor: Processor;
}
