
<ng-container [formGroup]="form">

  <div fxLayout="row" fxLayoutGap="15px">

    <mat-card fxFlex="calc(50% -15px)">
      <mat-card-header>
        <mat-card-title i18n>Contract Dates</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <mat-form-field>
          <mat-label i18n>Start Date</mat-label>
          <input matInput [matDatepicker]="startDatePicker" formControlName="start_date">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>End Date</mat-label>
          <input matInput [matDatepicker]="endDatePicker" formControlName="end_date">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex="calc(50% -15px)">
      <mat-card-header>
        <mat-card-title i18n>Associations</mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        Show field and grower details
      </mat-card-content>
    </mat-card>

  </div>



</ng-container>

