import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Grower} from '../grower.model';
import {GrowersService} from '../growers.service';
import {FormControl, FormGroup} from '@angular/forms';
import {GrowerFormControlService} from '../grower-form-control.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';
import {yearArray} from '../../../../utils/common-functions';
import {MiscanthusHarvest} from '../../../operations/miscanthus-harvests/miscanthus-harvest.model';
import {MiscanthusHarvestsService} from '../../../operations/miscanthus-harvests/miscanthus-harvests.service';

@Component({
  selector: 'app-grower-harvest-overview',
  templateUrl: './grower-harvest-overview.component.html',
  styleUrls: ['./grower-harvest-overview.component.scss']
})
export class GrowerHarvestOverviewComponent implements OnInit {

  @Input()
  grower: Grower;

  @ViewChild('paperHarvestDecDownload')
  paperHarvestDecBtn: SpinnerLoadingButtonComponent;

  environment = environment;

  harvestOptionsForm: FormGroup;

  reviewYears = yearArray(2020);
  reviewHarvest: MiscanthusHarvest;
  harvestYearSelection: FormControl = new FormControl();

  newHarvests: Array<MiscanthusHarvest> = new Array<MiscanthusHarvest>();

  constructor(
    private growersService: GrowersService,
    private growersFormControlService: GrowerFormControlService,
    private miscanthusHarvestService: MiscanthusHarvestsService,
    private snackBar: MatSnackBar,
  ) {

  }

  ngOnInit(): void {
    this.harvestOptionsForm = this.growersFormControlService.toHarvestOptionsFormGroup(this.grower);
    this.getHarvests();
    this.harvestYearSelection.valueChanges.subscribe((value) => {
      this.reviewHarvest = value;
    });

  }

  getHarvests(){
    this.miscanthusHarvestService.getRecords({ pageSize: 100, pageNumber: 0,
      sort: 'year',
      search: {
        grower: { uuid: this.grower.id }
      }
    }).subscribe((response) => {
      this.newHarvests = response.records;
    });
  }


  save() {
    if (this.harvestOptionsForm.valid) {
      this.growersService.updateRecord({recordId: this.grower.id, data: this.harvestOptionsForm.value}).subscribe(
        (result) => {
          this.grower = result;
          this.snackBar.open($localize`:@@Grower.harvestSettingsUpdate:Harvest settings updated`);
        }
      );
    }
  }

  downloadHarvestDec() {
    this.growersService.downloadPaperHarvestDec(this.grower,  () => {
      this.paperHarvestDecBtn.processFinished();
    });
  }

}
