<app-universal-search-preview-wrapper [searchResult]="record" classType="Bale Collection" [icon]="gaiaIconSet.bale_collections">

  <mat-list>
    <mat-list-item>
      <span matLine>Processor Ref</span>
      <span matLine>{{record.processor_ref}}</span>
    </mat-list-item>
    <mat-list-item>
      <span matLine>Grower Code</span>
      <span matLine>{{record.grower_codes}}</span>
    </mat-list-item>
  </mat-list>


  <ng-container buttons>
    <button mat-icon-button>
      <mat-icon (click)="showGrower()">{{ gaiaIconSet.edit }}</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
    <mat-list *ngIf="previewData">
    </mat-list>
  </ng-container>

</app-universal-search-preview-wrapper>
