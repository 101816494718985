import {Injectable} from '@angular/core';
import {StaffMember} from '../system-admin/staff-member/staff-member.model';
import {BehaviorSubject, interval, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthCheckService} from '@terravesta/phanes';
import {GaiaLogger} from '../../utils/common-functions';
import {MatDialog} from '@angular/material/dialog';
import {LoginExpiryCheckPopupComponent} from '../gaia-general/login-expiry-check-popup/login-expiry-check-popup.component';
import {Router} from '@angular/router';
import {UserCookiesService} from './user-cookies.service';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {

  private static CHECK_TIME = 60;
  private static CHECK_TIME_MS = 1000 * SessionDataService.CHECK_TIME;

  authenticationState = new BehaviorSubject(false);
  private currentUser: StaffMember = null;
  private expired = false;

  private loginCheck: Observable<any>;
  authenticationRedirect = new BehaviorSubject<boolean>(false);

  constructor(
    httpClient: HttpClient,
    private matDialog: MatDialog,
    private router: Router,
    private userCookieService: UserCookiesService,
    private authCheckService: AuthCheckService<StaffMember>,
    private window: Window,
  ) {
    this.setupSession();
    this.loginCheck = interval(SessionDataService.CHECK_TIME_MS);
    this.loginCheck.subscribe(
      () => {
        const expiresAt = this.userCookieService.getCurrentTokenExpiryTime();
        if (expiresAt != null) {
          const timeLeft = expiresAt - SessionDataService.comparableTime();
          if (timeLeft < SessionDataService.CHECK_TIME) {
            if (!this.expired) {
              const dialogRef = this.matDialog.open(LoginExpiryCheckPopupComponent);
              this.expired = true;
              dialogRef.afterClosed().subscribe((response) => {
                  if (response) {
                    this.checkAuth();
                  } else {
                    this.logout();
                }
              });
            }
          }
        }
      }
    );
  }

  private static comparableTime() {
    return Date.now() / 1000;
  }

  setupSession(){
    this.authenticationState.next(false);
    this.authenticationRedirect.next(false);
    this.checkAuth();
  }

  checkAuth(){
    this.authCheckService.checkAuth('StaffMember').subscribe(
      (response) => {
        this.currentUser = response.user;
        GaiaLogger.log(this.currentUser);
        this.expired = false;
        if (response.expiry > (Date.now() / 1000)){
          this.authenticationState.next(true);
          const url = this.userCookieService.getRedirectedCookieAndClear();
          if (url) {
            this.router.navigateByUrl(url).then(() => {});
          }
        }else{
          GaiaLogger.log('out of time');
        }
      },
      (error) => {
        this.authenticationRedirect.next(true);
        // Failed
        GaiaLogger.log('FAILED', error);
        this.userCookieService.setRedirectCookie();
        setTimeout(() => {
          this.window.location.href = environment.login_url;
        }, 2000);
      });
  }

  logout() {
    this.authCheckService.logout().subscribe((response) => {
      if (response.success){
        this.window.location.href = response.login_url;
      }
    });
  }

  getUser(): StaffMember {
    return this.currentUser;
  }
}

