import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Stockist} from '../stockist.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {Address} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-stockist-card',
  templateUrl: './stockist-card.component.html',
  styleUrls: ['./stockist-card.component.scss']
})
export class StockistCardComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;

  @Input()
  stockist: Stockist;

  @Input()
  editable = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  copyAddress: EventEmitter<Address> = new EventEmitter<Address>();

  constructor() { }

  ngOnInit(): void {
  }

  cancelClicked(){
    this.cancelled.emit();
  }

}
