import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MiscanthusStack} from '../miscanthus-stack.model';
import {MiscanthusStacksService} from '../miscanthus-stacks.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {GaiaLogger, yearArray} from '../../../../utils/common-functions';
import {FormControl} from '@angular/forms';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {BehaviorSubject} from 'rxjs';
import {GPSPoint, PhanesMapPoint, PhanesNode} from '@terravesta/phanes';
import {
  SearchFilterToggleButtonsComponent
} from '../../../gaia-general/search-filter-toggle-buttons/search-filter-toggle-buttons.component';
import {ActivatedRoute, Router} from '@angular/router';
import {OperationsRoutes} from '../../operations-routing.module';

@Component({
  selector: 'app-miscanthus-stacks-table',
  templateUrl: './miscanthus-stacks-table.component.html',
  styleUrls: ['./miscanthus-stacks-table.component.scss']
})
export class MiscanthusStacksTableComponent extends IndexTableDirective<MiscanthusStack> implements AfterViewInit{

  operationsRoutes = OperationsRoutes;
  harvestYear = new FormControl(new Date().getFullYear());
  years = yearArray(2016).reverse();

  displayedColumns = ['grower', 'post_code', 'bale_type', 'declared_bale_count', 'remaining_bale_count', 'est_tonnage', 'menu'];
  gaiaIconSet = GaiaIconSet;

  stackPositions: BehaviorSubject<Array<GPSPoint & PhanesNode>> = new BehaviorSubject<Array<GPSPoint & PhanesNode>>(null);
  stackPoints: Array<PhanesMapPoint> = new Array<PhanesMapPoint>();

  @ViewChild(SearchFilterToggleButtonsComponent)
  toggleBtn: SearchFilterToggleButtonsComponent;

  completedOptions = [
    {value: false, text: 'Not Completed'},
    {value: true, text: 'Completed'},
  ];

  constructor(
    miscanthusStacksService: MiscanthusStacksService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(miscanthusStacksService, router, activatedRoute, matDialog, null);
    this.getPositions();

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.stackPositions.subscribe((newPoints) => {
      this.stackPoints = new Array<PhanesMapPoint>();
      if (newPoints) {
        newPoints.forEach((item) => {
          if (item.latitude && item.longitude) {
            this.stackPoints.push({
              position: {
                latitude: item.latitude,
                longitude: item.longitude,
              }
            });
          }
        });
      }
    });
  }

  getPositions() {
    (this.dataService as MiscanthusStacksService).stackPositions(this.harvestYear.value).subscribe(
      (res) => this.stackPositions.next(res.stacks)
    );
  }

  searchOpts(): any {
    const terms = {
      harvest_year: this.harvestYear.value,
      completed: null,
      harvest: {
        grower: {
          grower_code: null,
        }
      }
    };
    if (this.searchTerm) {
      terms.harvest.grower.grower_code = this.fuzzySearchTerm();
    }
    if (this.toggleBtn && this.toggleBtn.selectedValue !== null){
      terms.completed = this.toggleBtn.selectedValue ? 1 : 0;
    }
    GaiaLogger.log(terms);
    return terms;
  }

  yearChanged(event: MatSelectChange) {
    this.updateTable();
    this.getPositions();
  }

}
