import { Injectable } from '@angular/core';
import {LegacyContractedFieldHarvest} from './legacy-field-harvest.model';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../../services/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyFieldHarvestsService extends RestApiService<LegacyContractedFieldHarvest> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/contracted_field_harvests', 'contracted_field_harvest', httpClient);
  }
}
