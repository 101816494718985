
<button mat-fab color="accent" (click)="addDriver()">
  <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
</button>

<mat-table [dataSource]="drivers" [ngClass.lt-md]="'mobile-table'">

  <ng-container matColumnDef="first_name">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>First name</span>
      <gaia-shared-cell-mobile-label i18n>First Name</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.contact.first_name}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="surname">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Surname</span>
      <gaia-shared-cell-mobile-label i18n>Surname</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.contact.surname}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Email</span>
      <gaia-shared-cell-mobile-label i18n>Email</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.contact.email}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef>
      <span i18n>Phone</span>
      <gaia-shared-cell-mobile-label i18n>Phone</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.contact.phone}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="active">
    <mat-header-cell class="w-75" *matHeaderCellDef>
      <span i18n>Active</span>
      <gaia-shared-cell-mobile-label i18n>Active</gaia-shared-cell-mobile-label>
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="w-75">
      <mat-icon *ngIf="element.active">{{ gaiaIconSet.check }}</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="menu">
    <mat-header-cell class="w-75" *matHeaderCellDef>
    </mat-header-cell>
    <mat-cell class="w-75" *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Contact settings menu">
        <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item [routerLink]="[haulierRoutes.DRIVERS, element.id]">
          <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
          <span i18n>Edit</span>
        </a>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
