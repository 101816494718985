<mat-table [dataSource]="histories">

  <ng-container matColumnDef="added">
    <mat-header-cell *matHeaderCellDef i18n="added to contract table header">Added</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.added_to_contract | date}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="removed">
    <mat-header-cell *matHeaderCellDef i18n="removed from contract table header">Removed</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.removed_from_contract | date}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="current">
    <mat-header-cell *matHeaderCellDef i18n="contract current flag table header">Current Contract</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element.current">{{ gaiaIconSet.check }}</mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
