import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {DashboardWidgetDirective} from '../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {PlantingOpportunitiesService} from '../../planting-opportunities.service';
import {SessionDataService} from '../../../../services/session-data.service';
import {PlantingOpportunity} from '../../planting-opportunity.model';
import {WidgetDefinition} from '../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';
import {
  dateToQueryString,
  GaiaLogger, isToday,
  navigateToPostcode,
} from '../../../../../utils/common-functions';
import {GrowingRoutes} from '../../../growing-routing.module';
import {AppRoutes} from '../../../../app-routing.module';

@Component({
  selector: 'app-upcoming-surveys-widget',
  templateUrl: './upcoming-surveys-widget.component.html',
  styleUrls: ['./upcoming-surveys-widget.component.scss']
})
export class UpcomingSurveysWidgetComponent extends DashboardWidgetDirective implements OnInit {

  todaySurveys = new Array<PlantingOpportunity>();
  upcomingSurveys = new Array<PlantingOpportunity>();
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private sessionDataService: SessionDataService,
    private plantingOpportunitiesService: PlantingOpportunitiesService
  ) {
    super(elementRef, renderer);
  }

  static widgetDefinition(): WidgetDefinition {
    return {
      name: 'My Farm Surveys',
      componentClass: UpcomingSurveysWidgetComponent,
      description: 'Shows your surveys over the next few days',
      code: 'MY-SURVEY',
      grouping: 'Sales',
      department: 'Sales',
      minColumnCount: 4,
      minRowCount: 4,
    };
  }

  ngOnInit(): void {
    this.plantingOpportunitiesService.getRecords({
      search: {
        surveyed_by: {
          uuid: this.sessionDataService.getUser().id
        },
        farm_survey_date: `${dateToQueryString(new Date())}-${dateToQueryString(new Date(new Date().setDate(new Date().getDate() + 7)))}`
      },
      pageSize: 20,
      pageNumber: 0,
      sort: 'farm_survey_date ASC'
    }).subscribe((response) => {
      GaiaLogger.log(response.records);
      this.upcomingSurveys = new Array<PlantingOpportunity>();
      this.todaySurveys = new Array<PlantingOpportunity>();
      response.records.forEach((op) => {
        const date: Date = new Date(op.farm_survey_date);
        if (isToday(date)) {
          this.todaySurveys.push(op);
        } else {
          this.upcomingSurveys.push(op);
        }
      });
    });
  }

  processSettings(settings: any) {
  }

  toNavigate(op: PlantingOpportunity) {
    return navigateToPostcode(op.grower.billing_address.post_code);
  }

}
