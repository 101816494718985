

<div *ngFor="let priceSet of priceList$ | async">

    {{ priceSet.price_group.name }}

    <mat-table [dataSource]="priceSet.price_points">

      <ng-container matColumnDef="min_qty">
        <mat-header-cell *matHeaderCellDef>Min Qty</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.min_qty}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vat_rate">
        <mat-header-cell *matHeaderCellDef>Vat Rate (%)</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.vat_rate}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ex_vat_price">
        <mat-header-cell *matHeaderCellDef>ex Vat Price</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.ex_vat_price | currency}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vat_price">
        <mat-header-cell *matHeaderCellDef>inc Vat Price</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.vat_price | currency}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

</div>

