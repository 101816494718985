import { Component, OnInit } from '@angular/core';
import {AppRoutes} from '../../app-routing.module';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {ContactsRoutes} from '../contact-routing.module';
import {GrowingRoutes} from '../../growing/growing-routing.module';

@Component({
  selector: 'app-contacts-menu',
  templateUrl: './contacts-menu.component.html',
  styleUrls: ['./contacts-menu.component.scss']
})
export class ContactsMenuComponent implements OnInit {

  appRoutes = AppRoutes;
  gaiaIcons = GaiaIconSet;
  contactsRoutes = ContactsRoutes;
  growingRoutes = GrowingRoutes;

  constructor() { }

  ngOnInit(): void {
  }

}
