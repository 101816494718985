import {RestApiService} from '../../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {GaiaRecord} from '../../../models/gaia-record.model';
import {Observable} from 'rxjs';
import {RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';

export abstract class RequiresApprovalService<T extends GaiaRecord> extends RestApiService<T> {

  protected constructor(
    restEndPoint: string,
    paramName: string,
    http: HttpClient,
    processorFunction?: (record: T) => void
  ) {
    super(restEndPoint, paramName, http, processorFunction);
  }

  forApproval(params: RailsGetRecordsParams): Observable<RailsAPIWrapper<T>>{
    const railsServerParams = this.processServerParams(params);
    return this.httpGet<RailsAPIWrapper<T>>(this.generateNonRecordUrl('awaiting_approval'), railsServerParams);
  }

  approve(record: T): Observable<RequiresApprovalResponse> {
    return this.httpPost<RequiresApprovalResponse>(this.generateUrl({ recordId: record.id }, 'approve'), {});
  }

  reject(record: T): Observable<RequiresApprovalResponse> {
    return this.httpPost<RequiresApprovalResponse>(this.generateUrl({ recordId: record.id }, 'reject'), {});
  }
}

export interface RequiresApprovalResponse {
  result: boolean;
}
