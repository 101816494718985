import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';
import {MiscanthusStack, MiscanthusStackPositions, MonthSummaries} from './miscanthus-stack.model';
import {Observable, Subscription} from 'rxjs';
import {StackScheduleMonth} from '../processors/schedule-months/schedule-month.model';
import {MiscanthusFieldHarvest} from './miscanthus-field-harvests/miscanthus-field-harvest.model';
import * as FileSaver from 'file-saver';
import {PlantingOpportunityMapData} from '../../growing/planting-opportunities/planting-opportunity.model';
import {flattenSearch} from '@terravesta/phanes';


@Injectable({
  providedIn: 'root'
})
export class MiscanthusStacksService extends RestApiService<MiscanthusStack> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('miscanthus_stacks', 'miscanthus_stack', httpClient);
  }

  miscanthusStack(search: any = {}): Observable<Array<MiscanthusStack>> {
    const flattened = flattenSearch(search, 'search');
    return this.httpGet<Array<MiscanthusStack>>(this.generateNonRecordUrl('get_miscanthus_stack'),
      { ...flattened });
  }

  stackPositions(harvestYear: number): Observable<MiscanthusStackPositions> {
    return this.httpGet<MiscanthusStackPositions>(this.generateNonRecordUrl('stack_positions'), { harvest_year: harvestYear});
  }

  getSchedule(postCode: string, harvestYear: number): Observable<StackScheduleMonth[]> {
    return this.httpGet<StackScheduleMonth[]>(this.generateNonRecordUrl('/available_schedule'),
      { stack_post_code: postCode, harvest_year: harvestYear});
  }

  markCompleted(miscanthusStack: MiscanthusStack): Observable<MiscanthusStack> {
    return this.httpPost<MiscanthusStack>(this.generateUrl({ recordId: miscanthusStack.id }, 'mark_completed'), {});
  }

  markUncompleted(miscanthusStack: MiscanthusStack): Observable<MiscanthusStack> {
    return this.httpPost<MiscanthusStack>(this.generateUrl({ recordId: miscanthusStack.id }, 'mark_uncompleted'), {});
  }

  updateBaleType(miscanthusStack: MiscanthusStack, baleTypeId: string) { // Todo should we be passing object (need to look at selects)
    return this.httpPost<MiscanthusStack>(this.generateUrl({ recordId: miscanthusStack.id }, 'update_bale_type'),
      { bale_type_id: baleTypeId });
  }

  updateFieldHarvests(miscanthusStack: MiscanthusStack, fieldHarvestsData: {field_harvests: Array<MiscanthusFieldHarvest>}) {
    return this.httpPost(this.generateUrl({ recordId: miscanthusStack.id }, 'update_field_harvests'), fieldHarvestsData);
  }

  monthSummaries(harvestYear :number): Observable<MonthSummaries> {
    return this.httpGet<MonthSummaries>(this.generateNonRecordUrl('month_summaries'), { year: harvestYear });
  }

  updateBaleCount(miscanthusStack: MiscanthusStack, indexView= false): Observable<MiscanthusStack> {
    const uploadData = {}
    if(indexView){
      uploadData['view'] = 'index';
    }
    return this.httpPost(this.generateUrl({ recordId: miscanthusStack.id }, 'update_bale_count'), uploadData);
  }

  downloadStackMileageReport(year: number, completed): Subscription {
    return this.httpGetBuffered(this.generateNonRecordUrl('mileage_to_delivery_sites'), {
      year
    }).subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/csv'});
          FileSaver.saveAs(file, `${year}_stacks_to_mileage.csv`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  downloadPowerStationStackReport(year: number, completed): Subscription {
    return this.httpGetBuffered(this.generateNonRecordUrl('mileage_to_power_stations'), {
      year
    }).subscribe(
      (response) => {
        const file = new Blob([response], {type: 'application/csv'});
        FileSaver.saveAs(file, `${year}_stacks_to_power_station.csv`);
      },
      () => {},
      () => {
        completed();
      }
    );
  }
}

