<ng-container [formGroup]="form" *ngIf="form">
  <div fxLayout="row" fxLayoutGap="15px" fxFlex="100%">
    <mat-card fxFlex="calc(50% - 15px)">
      <mat-card-header>
        <mat-icon mat-card-avatar>{{ record?.grower_code ? gaiaIconSet.growers : gaiaIconSet.leads }}</mat-icon>
        <mat-card-title i18n>Grower Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>ID: <span>{{ record?.id }}</span></p>
        <mat-form-field>
          <mat-label i18n>Grower Code</mat-label>
          <input matInput formControlName="grower_code" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Account Name</mat-label>
          <input matInput formControlName="account_name" />
        </mat-form-field>

        <mat-form-field>
          <mat-label i18n>Vat Number</mat-label>
          <input matInput formControlName="vat_number" />
        </mat-form-field>

<!--        <mat-form-field>-->
<!--          <mat-label i18n>Farm Type1</mat-label>-->
<!--          <input matInput formControlName="farm_type" />-->
<!--        </mat-form-field>-->

        <mat-form-field>
          <mat-label i18n>Farm Status</mat-label>
          <mat-select placeholder="Select Farm Status"
                      formControlName="farm_status"
          >
            <mat-option value="contract_expired"> Contract Expired </mat-option>
            <mat-option value="contract_terminated"> Contract Terminated </mat-option>
            <mat-option value="on_contract"> On Contract </mat-option>
            <mat-option value="own_use"> Own Use </mat-option>
            <mat-option value="spot_ccntract"> Spot Contract </mat-option>
            <mat-option value="europen_contract"> European Contract </mat-option>
          </mat-select>


        </mat-form-field>


      </mat-card-content>
    </mat-card>

    <gaia-shared-address-embed-form fxFlex="calc(50% - 15px)" formGroupName="billing_address"></gaia-shared-address-embed-form>
  </div>
</ng-container>
