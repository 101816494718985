
<mat-expansion-panel>
  <mat-expansion-panel-header i18n>
    Harvest Options
  </mat-expansion-panel-header>

  <div [formGroup]="harvestOptionsForm" fxLayout="column" fxLayoutGap="15px">

    <div fxLayout="row">
      <mat-form-field>
        <mat-label i18n>Stack Limit</mat-label>
        <input matInput type="number" formControlName="stack_limit" />
        <mat-hint>Defaults to 1 for all growers</mat-hint>
      </mat-form-field>

      <mat-checkbox formControlName="allow_partial_harvest_dec">Allow partial declarations?</mat-checkbox>
    </div>
    <div>
      <button mat-raised-button color="primary" [disabled]="!this.harvestOptionsForm.valid"
              (click)="save()" i18n>Update Harvest Settings</button>
    </div>

  </div>

</mat-expansion-panel>

<gaia-shared-spinner-loading-button color="primary" #paperHarvestDecDownload
                                    (clickOccurred)="downloadHarvestDec()" i18n>Paper Harvest Dec</gaia-shared-spinner-loading-button>


<app-grower-harvest-series-graph [grower]="grower"></app-grower-harvest-series-graph>

<mat-card>
  <mat-card-header>
    <mat-card-title i18n>Harvest Review</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
      <mat-label i18n>Harvest Review</mat-label>
      <mat-select [formControl]="harvestYearSelection">
        <mat-option *ngFor="let harvest of newHarvests" [value]="harvest">{{ harvest.year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <app-miscanthus-harvest-review *ngIf="reviewHarvest" [miscanthusHarvest]="reviewHarvest"></app-miscanthus-harvest-review>

  </mat-card-content>

</mat-card>


