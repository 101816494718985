import {Component, OnInit, ViewChild} from '@angular/core';
import {GrowersService} from '../growers.service';
import {GrowersBaleHistories} from '../grower.model';
import {GaiaLogger} from '../../../../utils/common-functions';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-growers-bale-history',
  templateUrl: './growers-bale-history.component.html',
  styleUrls: ['./growers-bale-history.component.scss']
})
export class GrowersBaleHistoryComponent implements OnInit {

  gaiaIconSet = GaiaIconSet;
  baleHistories: GrowersBaleHistories;

  tableColumns: Array<string>;
  years: Array<string>;
  yearsNumeric: Array<number>;

  @ViewChild(SpinnerLoadingButtonComponent)
  loadingBtn: SpinnerLoadingButtonComponent;

  constructor(
    private growersService: GrowersService,
  ) { }

  ngOnInit(): void {
    this.growersService.baleHistory().subscribe((results) => {
      this.baleHistories = results;
      this.yearsNumeric = Array.from({length: (this.baleHistories.max_year - this.baleHistories.min_year + 1)}, (v, k) => k + this.baleHistories.min_year);
      this.years = this.yearsNumeric.map((i) => i.toString());
      this.tableColumns = ['grower_code'].concat(this.years);
      GaiaLogger.log(this.baleHistories, this.tableColumns);
    });
  }

  downloadBaleHistories() {
    this.growersService.baleHistoryCSV(() => {
      this.loadingBtn.processFinished();
    });
  }
}
