<div class="processors">

  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Processors" i18n-pageTitle="page title for processors page"
                    i18n-searchHint searchHint="Company name">

  </app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="account_name">
      <mat-header-cell *matHeaderCellDef i18n> Name </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-button color="accent" [routerLink]="['/', appRoutes.OPERATIONS, operationsRoutes.PROCESSORS, element.id]">{{element.account_name}}</a>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSize]="dataSource.pageSize"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

</div>
