import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-record-reload-confirm-dialog',
  templateUrl: './record-reload-confirm-dialog.component.html',
  styleUrls: ['./record-reload-confirm-dialog.component.scss']
})
export class RecordReloadConfirmDialogComponent implements OnInit {

  public static SAVE_AND_RELOAD = 'save-and-reload';
  public static RELOAD_NO_SAVE = 'reload';
  public static NO_RELOAD = 'no-reload';

  constructor(
    private dialogRef: MatDialogRef<RecordReloadConfirmDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  saveAndReload() {
    this.dialogRef.close({ response: RecordReloadConfirmDialogComponent.SAVE_AND_RELOAD });
  }

  reloadWithoutSave() {
    this.dialogRef.close({ response: RecordReloadConfirmDialogComponent.RELOAD_NO_SAVE });
  }

  noReload() {
    this.dialogRef.close({ response: RecordReloadConfirmDialogComponent.NO_RELOAD });
  }
}
