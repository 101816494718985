import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {SoilMeasurement} from './soil-measurement.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {What3WordsFormControlService} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class SoilMeasurementFormControlService implements GaiaRecordFormControl<SoilMeasurement> {

  constructor(
    private formBuilder: FormBuilder,
    private what3WordsService: What3WordsFormControlService,
  ) { }

  toFormGroup(record: SoilMeasurement): FormGroup {
    return this.formBuilder.group({
      field_id: new FormControl(record.field.id),
      time_taken: new FormControl(record.time_taken),
      moisture_percentage: new FormControl(record.moisture_percentage),
      temperature: new FormControl(record.temperature),
      what_3_words: this.what3WordsService.toFormGroup(record),
    });
  }
}
