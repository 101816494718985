<app-universal-search-preview-wrapper [searchResult]="record" classType="Haulier" i18n-classType="Haulier label for search result" [icon]="gaiaIconSet.contractors">

  <mat-list>
    <mat-list-item>
      <span matLine>Account Name</span>
      <span matLine>{{record.account_name}}</span>
    </mat-list-item>
  </mat-list>


  <ng-container buttons>
    <button mat-icon-button (click)="showHaulier()">
      <mat-icon> {{ gaiaIconSet.edit }}</mat-icon>
    </button>
  </ng-container>

  <ng-container links>
  </ng-container>

</app-universal-search-preview-wrapper>
