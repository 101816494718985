import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CompaniesService} from '../companies.service';
import {Company} from '../company.model';
import {CompanyFormControlService} from '../company-form-control.service';
import {ShowPageMultipartDirective} from '../../../components/show-page-multipart.directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-company-show',
  templateUrl: './company-show.component.html',
  styleUrls: ['./company-show.component.scss']
})
export class CompanyShowComponent extends ShowPageMultipartDirective<Company> {

  company: Company;

  constructor(
    companiesService: CompaniesService,
    companyFormControl: CompanyFormControlService,
    route: ActivatedRoute,
    snackbar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(companiesService, companyFormControl, route, snackbar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Company) {
    this.company = record;
  }

}
