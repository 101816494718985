import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppCredentialsTableComponent} from '@terravesta/phanes';

export class SystemAdminRoutes {
  public static STAFF_MEMBERS = 'staff';
  public static APP_CREDENTIALS = 'app-credentials';
}

const routes: Routes = [
  {
    path: SystemAdminRoutes.STAFF_MEMBERS,
    loadChildren: () => import('./staff-member/staff-member-routing.module').then(m => m.StaffMemberRoutingModule),
  },
  {
    path: SystemAdminRoutes.APP_CREDENTIALS,
    children: [
      { path: '', component: AppCredentialsTableComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemAdminRoutingModule{ }
