import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MiscanthusContract, SupplyContract} from './contracts.model';
import {GaiaLogger} from '../../../utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class ContractsFormControlService {

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  statusChangeForm(data: MiscanthusContract): FormGroup {
    const group = this.formBuilder.group({
      terminated: new FormControl(data.terminated),
      superseded: new FormControl(data.superseded),
      reason_terminated_superseded: new FormControl(data.reason_terminated_superseded),
      termination_notes: new FormControl(data.termination_notes)
    });
    this.updateStatusForm(group, data);
    group.valueChanges.subscribe((values) => {
      this.updateStatusForm(group, values);
    })
    return group;
  }

  private updateStatusForm(group: FormGroup, values: MiscanthusContract){
    if(values.terminated){
      group.get('superseded').disable({emitEvent: false});
    } else {
      group.get('superseded').enable({emitEvent: false});
      group.get('termination_notes').setValue(null, { emitEvent: false });
    }
    if(values.superseded){
      group.get('terminated').disable({emitEvent: false});
    } else {
      group.get('terminated').enable({emitEvent: false});
    }
    GaiaLogger.log(values);
    if(group.get('terminated').value == false && group.get('superseded').value == false){
      group.get('reason_terminated_superseded').setValue(null, { emitEvent: false });
    }
  }

  addMiscanthusContractFields(formGroup: FormGroup, record: MiscanthusContract) {
    formGroup.addControl('grower_contract_ref', new FormControl(record.grower_contract_ref, Validators.required));
    formGroup.addControl('grower_id', new FormControl(record.grower ? record.grower.id : null, Validators.required));
    formGroup.addControl('start_date', new FormControl(record.start_date, Validators.required));
    formGroup.addControl('contract_term', new FormControl(record.contract_term, Validators.required));
    formGroup.addControl('signed_on', new FormControl(record.signed_on));
    formGroup.addControl('intended_hectarage', new FormControl(record.intended_hectarage));
  }

  addSupplyContractFields(formGroup: FormGroup, record: SupplyContract) {
    formGroup.addControl('sustainability_check_list_signed', new FormControl(record.sustainability_check_list_signed));
    formGroup.addControl('sustainability_report_signed', new FormControl(record.sustainability_report_signed));
    formGroup.addControl('self_billing_signed', new FormControl(record.self_billing_signed));
    formGroup.addControl('base_price', new FormControl(record.base_price, Validators.required));
    formGroup.addControl('ex_farm_contract', new FormControl(record.ex_farm_contract));
  }

}
