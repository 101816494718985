import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ContractedField} from '../contracted-field.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {ContractedFieldFormControlService} from '../contracted-field-form-control.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {ContractedFieldMapPanelComponent} from '../contracted-field-map-panel/contracted-field-map-panel.component';
import {EChartsOption} from 'echarts';
import {
  ClimateGridViewerComponent
} from '../../../field-data/climate-grid-uk/climate-grid-viewer/climate-grid-viewer.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatDialog} from '@angular/material/dialog';
import {
  ContractedFieldRemoveFromContractDialogComponent
} from '../contracted-field-remove-from-contract-dialog/contracted-field-remove-from-contract-dialog.component';
import {MatSelectChange} from '@angular/material/select';
import {FormControl, FormGroup} from '@angular/forms';
import {PaginatedDataSource} from '@terravesta/phanes';
import {GaiaLogger} from '../../../../utils/common-functions';
import {PlantingOpportunity} from "../../planting-opportunities/planting-opportunity.model";
import {GrowingRoutes} from "../../growing-routing.module";
import {AppRoutes} from "../../../app-routing.module";

@Component({
  selector: 'app-contracted-field-show',
  templateUrl: './contracted-field-show.component.html',
  styleUrls: ['./contracted-field-show.component.scss']
})
export class ContractedFieldShowComponent extends ShowPageDirective<ContractedField>{

  @ViewChild(ContractedFieldMapPanelComponent)
  mapPanel: ContractedFieldMapPanelComponent;

  @ViewChild(ClimateGridViewerComponent)
  climatePanel: ClimateGridViewerComponent;

  plantingOpportunity: PlantingOpportunity;

  gaiaIconSet = GaiaIconSet;
  harvestChart: EChartsOption;
  fieldList = new FormGroup({
    field_name: new FormControl(''),
    date_taken: new FormControl(''),
    planted_variety: new FormControl(''),
  });
  dropdownFieldList: Array<ContractedField>;
  redirectId: string;
  dropdownFieldNames: Array<string> = [];
  dataSource: PaginatedDataSource<ContractedField>;

  field: ContractedField;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    contractedFieldFormControl: ContractedFieldFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) {
    super(contractedFieldsService, contractedFieldFormControl, route, snackBar, dialog, internalNotesTracker,
      changeDetectorRef);
  }

  recordGot(record: ContractedField) {
    this.dataSource = new PaginatedDataSource<ContractedField>(
      (params) => this.contractedFieldsService.getContractedFieldsForGrower(record.grower, params));
    this.dataSource.loadRecords();
    GaiaLogger.log(this.dataSource, 'here');
    this.dropdownFieldList = [];
    this.dataSource.connect(null).subscribe(
      (fields) => {
        if (fields) {
          fields.forEach((field) => {
            this.dropdownFieldList.push(field);
            this.dropdownFieldNames.push(field.name);
          });
        }
      });
  }

  updateMap(event: MatTabChangeEvent){
    if (this.climatePanel) {
      this.climatePanel.updateMap();
    }
  }

  reloadField() {
    this.reloadRecord();
  }

  openRemoveDialog() {
    this.dialog.open(ContractedFieldRemoveFromContractDialogComponent, {
      data: {
        record: this.record,
      }
    });
  }

  redirectToNewField($event: MatSelectChange) {
    this.redirectId = $event.value
    this.router.navigate([AppRoutes.GROWING, GrowingRoutes.CONTRACTED_FIELDS, this.redirectId]).then(()=>{
    });
  }


}
