<gaia-shared-standard-dialog [dialogTitle]="dialogTitle">
  <div content>
    <mat-form-field appearance="fill" *ngIf="!form">
      <mat-label i18n>Select Customer Type</mat-label>
      <mat-select [(ngModel)]="selectedType" (selectionChange)="typeSelected($event)">
        <mat-option [value]="customerTypes.direct" i18n="direct sale to customer, not stockist etc">Direct Sale</mat-option>
        <mat-option [value]="customerTypes.stockist" i18n="sale to a new stockists">Stockist</mat-option>
        <mat-option [value]="customerTypes.commercial_user" i18n="sale to a new commercial user">Commercial User</mat-option>
        <mat-option [value]="customerTypes.brand_ambassador" i18n="sale to a new brand ambassador">Brand Ambassador</mat-option>
      </mat-select>
    </mat-form-field>
    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
      <div class="form" [ngSwitch]="selectedType">
        <app-stockist-form *ngSwitchCase="customerTypes.stockist" [form]="form"></app-stockist-form>
        <app-contact-form *ngSwitchCase="customerTypes.direct" [form]="form"></app-contact-form>
        <app-brand-ambassador-form *ngSwitchCase="customerTypes.brand_ambassador" [form]="form"></app-brand-ambassador-form>
        <app-commercial-user-form *ngSwitchCase="customerTypes.commercial_user" [form]="form"></app-commercial-user-form>
      </div>
    </form>
  </div>
  <div actions>
    <button mat-button *ngIf="form" [disabled]="!form.valid" (click)="save()" color="primary">Save</button>
    <button mat-button mat-dialog-close>Cancel</button>
  </div>
</gaia-shared-standard-dialog>
