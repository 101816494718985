import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-search-filter-toggle-button',
  templateUrl: './search-filter-toggle-buttons.component.html',
  styleUrls: ['./search-filter-toggle-buttons.component.scss']
})
export class SearchFilterToggleButtonsComponent implements OnInit, OnChanges {

  @Input()
  filterOptions: Array<SearchFilterToggleButtonData>;

  @Input()
  initialValue: number|string|boolean;


  @Output()
  filterChanged: EventEmitter<number|string|boolean> = new EventEmitter<number | string | boolean>();

  selectedIndex: number;
  selectedValue: number|string|boolean = null;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.initialValue){
      if(changes.initialValue.currentValue !== undefined) {
        this.setInitialState();
      }
    }
  }

  setInitialState() {
    if(this.filterOptions) {
      const index = this.filterOptions.findIndex((item) => item.value === this.initialValue)
      if(index > -1){
        this.selectedIndex = index;
        this.selectedValue = this.filterOptions[index].value;
      }
    }

  }

  clicked(index: number) {
    if (this.selectedIndex !== index) {
      this.selectedIndex = index;
      this.selectedValue = this.filterOptions[index].value;
    } else {
      this.selectedIndex = null;
      this.selectedValue = null;
    }
    this.filterChanged.emit(this.selectedValue);
  }
}

export interface SearchFilterToggleButtonData {
  text: string;
  value: number|string|boolean;
}
