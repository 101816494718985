import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GrowerContract} from '../grower-contract.model';
import {GrowerContractsService} from '../grower-contracts.service';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-grower-contract-display-card',
  templateUrl: './grower-contract-display-card.component.html',
  styleUrls: ['./grower-contract-display-card.component.scss']
})
export class GrowerContractDisplayCardComponent implements OnInit, OnChanges {

  contract: GrowerContract;

  @Input() contractId: string;

  constructor(
    private growerContractsService: GrowerContractsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contractId.previousValue !== changes.contractId.currentValue) {
      this.reload();
    }
  }

  reload() {
    this.growerContractsService.getRecord({ recordId: this.contractId }).subscribe(
      (contract) => {
        this.contract = contract;
        GaiaLogger.log('Contract', this.contract);
      }
    );
  }

}
