import { Injectable } from '@angular/core';
import {MiscanthusVariety} from './miscanthus-variety.model';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class MiscanthusVarietiesService extends RestApiService<MiscanthusVariety> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/miscanthus_varieties', 'miscanthus_variety', httpClient);
  }
}
