import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {GrowerContract} from './grower-contract.model';
import {Grower} from '../growers/grower.model';
import {RailsAPIWrapper, RailsGetRecordsParams} from '@terravesta/phanes';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GrowerContractsService  extends RestApiService<GrowerContract> {

  constructor(httpClient: HttpClient) {
    super('/grower_contracts', 'grower_contract', httpClient);
  }

  getRecordsForGrower(grower: Grower, params: RailsGetRecordsParams): Observable<RailsAPIWrapper<GrowerContract>> {
    params.search = {
      ...params.search,
      grower: {uuid: grower.id}
    };
    return this.getRecords(params);
  }

}
