<ng-container *ngIf="form" [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>storefront</mat-icon>
      <mat-card-title>Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label i18n>Account Name</mat-label>
        <input matInput formControlName="account_name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Billing Email</mat-label>
        <input matInput formControlName="billing_email" type="email" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Phone Number</mat-label>
        <input matInput formControlName="phone_number" type="tel" />
      </mat-form-field>

      <app-price-group-select [formGroup]="form" [record]="stockist" [controlName]="'default_price_group_id'"></app-price-group-select>

      <mat-slide-toggle formControlName="on_account" i18n>On Account?</mat-slide-toggle>

      <mat-form-field *ngIf="isOnAccount()">
        <mat-label i18n>Payment Term (days)</mat-label>
        <input matInput formControlName="payment_term_days" type="number" step="1" />
      </mat-form-field>


    </mat-card-content>
  </mat-card>

  <div gaiaSharedMultiAddressContainer>
    <gaia-shared-address-embed-form formGroupName="billing_address"
                            [title]="'Billing'" [icon]="'payment'"></gaia-shared-address-embed-form>

    <gaia-shared-address-embed-form formGroupName="shipping_address"
                            [title]="'Shipping'" [icon]="'local_shipping'"></gaia-shared-address-embed-form>
  </div>

</ng-container>
