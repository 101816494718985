<!--Asset Details page area start-->
<div class="asset_details_page_area">
  <loading></loading>
  <h1>Asset Details</h1>

  <mat-tab-group>
    <!--  Asset details markup start -->
    <mat-tab label="Asset Details">
      <div class="assets_form_control_btn">
        <button mat-raised-button color="primary" [disabled]="!hasChanges()" (click)="saveAsset()">
          Save
        </button>
      </div>

      <form class="assets_popup_area" [formGroup]="assetForm">
        <!--  Form row 1 start-->
        <div class="form_row_1 form_row">
          <!--  Group 1 start-->
          <div class="form_group identification">
            <div class="gp_heading">
              <div class="gh_icon">
                <mat-icon>account_box</mat-icon>
              </div>
              <h2>Identification</h2>
            </div>
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>ID</mat-label>
              <input formControlName="id" matInput>
            </mat-form-field>

            <mat-form-field class="input-full-width">
              <mat-label>Terravesta Reference</mat-label>
              <input formControlName="terravesta_reference" readonly class="readonly-input" matInput>
            </mat-form-field>

            <mat-form-field class="input-full-width">
              <mat-label>Name</mat-label>
              <input formControlName="name" matInput>
            </mat-form-field>

            <mat-form-field class="input-full-width">
              <mat-label>Asset Category</mat-label>
              <mat-select placeholder="Select Category"
                          formControlName="category"
                          (selectionChange)="onSelectionCategoryChange($event.value)"
              >
                <mat-option *ngFor="let category of categories" [value]="category.id"> {{ category.name }}   </mat-option>
              </mat-select>
            </mat-form-field>
          </div><!--  Group 1 end-->

          <!--  Group 2 start-->
          <div class="form_group price">
            <div class="gp_heading">
              <div class="gh_icon">
                <mat-icon>currency_pound</mat-icon>
              </div>
              <h2>Price</h2>
            </div>


            <mat-form-field class="input-full-width">
              <mat-label>Purchase Price</mat-label>
              <input formControlName="purchase_price" matInput type="number">
            </mat-form-field>

            <mat-form-field class="input-full-width">
              <mat-label>Estimated VAlue</mat-label>
              <input formControlName="estimated_value" matInput type="number">
            </mat-form-field>
          </div>
          <!--  Group 2 end-->
        </div>
        <!--  Form row 1 end-->

        <!--  Form row 2 start-->
        <div class="form_row_2 form_row">
          <!--  Group 3 start-->
          <div class="form_group purchase_date">
            <div class="gp_heading">
              <div class="gh_icon">
                <mat-icon>calendar_today</mat-icon>
              </div>

              <h2>Purchase Date</h2>
            </div>
            <mat-form-field>
              <input matInput [matDatepicker]="purchaseDatepicker" #searchInput
                     formControlName="purchase_date">
              <mat-datepicker-toggle matSuffix
                                     [for]="purchaseDatepicker">
              </mat-datepicker-toggle>
              <mat-datepicker #purchaseDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <!--  Group 3 end-->

          <!--  Group 4 start-->
          <div class="form_group make_model">
            <div class="gp_heading">
              <div class="gh_icon">
                <mat-icon> highlight</mat-icon>
              </div>

              <h2>Make & Model</h2>
            </div>
            <mat-form-field class="input-full-width">
              <mat-label>Asset Make</mat-label>
              <mat-select placeholder="Select Make"
                          formControlName="make">
                <mat-option *ngFor="let make of makes" [value]="make.id"> {{ make.name }}   </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="input-full-width">
              <mat-label>Asset Model</mat-label>
              <mat-select placeholder="Select Model"
                          formControlName="model">
                <mat-option *ngFor="let model of models" [value]="model.id"> {{ model.name }}   </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--  Group 4 end-->
        </div>
        <!--  Form row 2 end-->

        <!--  Form row 3 start-->
        <div class="form_row_3 form_row">

          <!--  Group 5 start-->
          <div class="form_group purchase_date">
            <div class="gp_heading">
              <div class="gh_icon">
                <!--          <mat-icon>done_all</mat-icon>-->
                <!--          <mat-icon>build</mat-icon>-->
                <mat-icon>how_to_reg</mat-icon>
              </div>
              <h2>Tested By</h2>
            </div>

            <mat-form-field class="input-full-width">
              <mat-label>Test Status</mat-label>
              <mat-select placeholder="Select Test Status"
                          formControlName="status"
              >
                <mat-option value="working"> Working </mat-option>
                <mat-option value="not_working"> Not Working </mat-option>
                <mat-option value="unable_to_test"> Unable To Test </mat-option>
                <mat-option value="not_tested"> Not Tested </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="input-full-width">
              <mat-label>Asset Tested By</mat-label>
              <mat-select placeholder="Select Tested By"
                          formControlName="tested_by_staffmember_id">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let user of tested_by_users" [value]="user.id"> {{ user.name }}   </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <!--  Group 5 end-->

          <!--  Group 6 start-->
          <div class="form_group purchase_date">
            <div class="gp_heading">
              <div class="gh_icon">
                <mat-icon>store</mat-icon>
              </div>
              <h2>Store Location</h2>
            </div>

            <mat-form-field class="input-full-width">
              <mat-label>Store Location</mat-label>
              <mat-select placeholder="Select Store Location"
                          formControlName="store_location">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let store_location of store_locations" [value]="store_location.id"> {{ store_location.name }}   </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <!--  Group 6 start-->
        </div>
        <!--  Form row 3 end-->

        <!--  Form row 4 start-->
        <div class="form_row_3 form_row">
          <!-- Note textarea start-->
          <div class="form_group purchase_date">
            <div class="gp_heading">
              <div class="gh_icon">
                <mat-icon>speaker_notes</mat-icon>
              </div>
              <h2>Notes</h2>
            </div>

            <mat-form-field  class="input-full-width notes-textarea">
              <mat-label>Notes</mat-label>
              <textarea formControlName="notes" matInput></textarea>
            </mat-form-field>
          </div><!--  Note textarea end  -->
        </div><!--  Form row 4 end-->

      </form>

    </mat-tab>
    <!--  Asset details markup end-->


    <!--  ==========================================================-->
    <!--  ==========================================================-->

    <!--  Background Images markup start -->
    <mat-tab label="Images">
      <button mat-fab extended  mat-raised-button color="primary" (click)="openPopup('')">
        <mat-icon>add</mat-icon>
      </button>
      <br>

      <div class="img_cart_wrapper">
        <mat-card *ngFor="let image of assetImages" class="asset_card_area" style="">
          <mat-card-header>
            <mat-card-title>{{ image.name }}</mat-card-title>
            <mat-card-subtitle>Added at: date here</mat-card-subtitle>
          </mat-card-header>
          <div style="background-image: url('{{ image.image_url }}');" class="asset_image"></div>
          <!--  image CSS background-->

          <mat-card-actions>
            <button mat-button class="asset_delete_btn" (click)="deleteAssetImage(image.id)">
              DELETE
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </mat-tab>
    <!--  Background Images markup end -->
  </mat-tab-group>
</div>
<!--Asset Details page area end-->
