import {Component, OnInit} from '@angular/core';
import {ImagesService} from '../images.service';
import {PaginatedDataSource} from '@terravesta/gaia-shared';
import {Image} from '../image.model';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-image-gallery-dialog',
  templateUrl: './image-gallery-dialog.component.html',
  styleUrls: ['./image-gallery-dialog.component.scss']
})
export class ImageGalleryDialogComponent implements OnInit {

  images: PaginatedDataSource<Image>;

  constructor(
    private imagesService: ImagesService,
    private matDialogRef: MatDialogRef<ImageGalleryDialogComponent, ImageGallerySelectData>,
  ) { }

  ngOnInit(): void {
    this.images = new PaginatedDataSource<Image>(
      (params) => this.imagesService.getRecords(params));
    this.images.loadRecords({pageSize: 30, pageNumber: 0});
  }


  selectImage(image: Image) {
    this.matDialogRef.close({image});
  }
}

export interface ImageGallerySelectData {
  image: Image;
}
