import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MiscanthusContractFormComponent} from './miscanthus-contract-form/miscanthus-contract-form.component';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FlexModule} from '@angular/flex-layout';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {GrowerSharedModule} from '../../grower-shared-components/grower-shared.module';
import { MiscanthusContractNewFormComponent } from './miscanthus-contract-new-form/miscanthus-contract-new-form.component';
import {GaiaGeneralModule} from '../../../gaia-general/gaia-general.module';
import {
    MiscanthusContractShowWrapperComponent
} from './miscanthus-contract-show-wrapper/miscanthus-contract-show-wrapper.component';
import {RouterModule} from '@angular/router';
import { ContractStatusSelectComponent } from './contract-status-select/contract-status-select.component';
import {MatSelectModule} from '@angular/material/select';
import { ContractStatusPipe } from './contract-status.pipe';
import { ContractStatusChangeDialogComponent } from './contract-status-change-dialog/contract-status-change-dialog.component';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
  declarations: [MiscanthusContractFormComponent, MiscanthusContractNewFormComponent,
    MiscanthusContractShowWrapperComponent,
    ContractStatusSelectComponent,
    ContractStatusPipe,
    ContractStatusChangeDialogComponent],
    imports: [
        CommonModule,
        MatCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FlexModule,
        MatDatepickerModule,
        MatInputModule,
        GrowerSharedModule,
        GaiaGeneralModule,
        RouterModule,
        MatSelectModule,
        GaiaSharedModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        MatSlideToggleModule
    ],
  exports: [
    MiscanthusContractFormComponent,
    MiscanthusContractShowWrapperComponent,
    ContractStatusSelectComponent,
    ContractStatusPipe
  ]
})
export class ContractsSharedModule { }
