import { Component, OnInit } from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {AppRoutes} from '../../app-routing.module';
import {SystemAdminRoutes} from '../system-admin-routing.module';
import {SessionDataService} from '../../services/session-data.service';
import {StaffMember} from '../staff-member/staff-member.model';
import {StaffMemberRoutes} from '../staff-member/staff-member-routing.module';

@Component({
  selector: 'app-system-admin-menu',
  templateUrl: './system-admin-menu.component.html',
  styleUrls: ['./system-admin-menu.component.scss']
})
export class SystemAdminMenuComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;
  systemAdminRoutes = SystemAdminRoutes;
  staffMemberRoutes = StaffMemberRoutes;
  currentUser: StaffMember;

  constructor(
    private sessionService: SessionDataService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.sessionService.getUser();
  }

}
