import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalerRecordsTableComponent } from './baler-records-table/baler-records-table.component';
import { BalerRecordNewDialogComponent } from './baler-record-new-dialog/baler-record-new-dialog.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import { BalerRecordFormComponent } from './baler-record-form/baler-record-form.component';
import { BalerRecordShowComponent } from './baler-record-show/baler-record-show.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {BaleTypesModule} from '../bale-types/bale-types.module';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
    declarations: [BalerRecordsTableComponent, BalerRecordNewDialogComponent, BalerRecordFormComponent, BalerRecordShowComponent],
    imports: [
        CommonModule,
        GaiaGeneralModule,
        GaiaSharedModule,
        MatTableModule,
        RouterModule,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        ExtendedModule,
        ReactiveFormsModule,
        MatCardModule,
        FlexModule,
        MatFormFieldModule,
        MatInputModule,
        BaleTypesModule,
        MatSelectModule
    ]
})
export class BalerRecordsModule { }
