<div class="container" [formGroup]="selectorForm">
  <mat-form-field>
    <mat-label i18n>Month</mat-label>
    <mat-select formControlName="month">
      <mat-option *ngFor="let month of monthList" [value]="month.numeric">
        {{ month.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>Year</mat-label>
    <mat-select formControlName="year">
      <mat-option *ngFor="let year of yearList" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
