import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ContractedField} from '../../contracted-fields/contracted-field.model';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {MatDialog} from '@angular/material/dialog';
import {
  SoilMeasurementFormDialogComponent
} from '../soil-measurement-form-dialog/soil-measurement-form-dialog.component';
import {SoilMeasurement} from '../soil-measurement.model';
import {SoilMeasurementsService} from '../soil-measurements.service';

@Component({
  selector: 'app-soil-measurements-embed',
  templateUrl: './soil-measurements-embed.component.html',
  styleUrls: ['./soil-measurements-embed.component.scss']
})
export class SoilMeasurementsEmbedComponent implements OnInit {

  @Input()
  contractedField: ContractedField;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  gaiaIconSet = GaiaIconSet;
  dataSource: GaiaIndexDataSource<SoilMeasurement>;
  displayedColumns = ['time_taken', 'moisture_percentage', 'temperature'];

  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;

  constructor(
    private soilSamplesService: SoilMeasurementsService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.dataSource = new GaiaIndexDataSource<SoilMeasurement>(this.soilSamplesService);
    this.dataSource.search(this.searchOpts());
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchOpts(): any {
    // TODO fix this to be hard coded rather than in searchOpts
    return {
      field: {
        uuid: this.contractedField.id,
      }
    };
  }

  newSoilMeasurement(){
    this.openSoilMeasurementDialog({ field: this.contractedField } as SoilMeasurement);
  }

  editSoilMeasurement(element) {
    this.openSoilMeasurementDialog(element);
  }

  private openSoilMeasurementDialog(measurement: SoilMeasurement){
    const dialogRef = this.matDialog.open(SoilMeasurementFormDialogComponent, {
      data: {
        record: measurement,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.dataSource.search(this.searchOpts());
    });
  }
}
