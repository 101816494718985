import { Injectable } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Product} from './product.model';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';

@Injectable({
  providedIn: 'root'
})
export class ProductFormControlService implements GaiaRecordFormControl<Product> {

  constructor(private formBuilder: FormBuilder) {
  }

  toFormGroup(product: Product): FormGroup{
    return this.formBuilder.group({
      name: new FormControl(product.name, Validators.required),
      public_name: new FormControl(product.public_name),
      sku: new FormControl(product.sku),
      cost_price: new FormControl(product.cost_price),
      shopify_id: new FormControl({value: product.shopify_id, disabled: true}),
      vat_rate: new FormControl(product.vat_rate, Validators.required),
      rrp: new FormControl(product.rrp),
      code: new FormControl(product.code),
      family: new FormControl(product.family),
    });
  }
}
