import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {PriceGroupFormControlService} from '../price-group-form-control.service';
import {PriceGroupsService} from '../price-groups.service';
import {PriceGroup} from '../price-group.model';
import {NewDialogDirective} from '../../../components/new-dialog.directive';

@Component({
  selector: 'app-price-group-new-dialog',
  templateUrl: './price-group-new-dialog.component.html',
  styleUrls: ['./price-group-new-dialog.component.scss']
})
export class PriceGroupNewDialogComponent extends NewDialogDirective<PriceGroup> {

  constructor(
    priceGroupService: PriceGroupsService,
    priceGroupFormControl: PriceGroupFormControlService,
    private router: Router,
    public matDialog: MatDialogRef<PriceGroupNewDialogComponent>,
  ) {
    super(priceGroupService, priceGroupFormControl);
  }

  successfulSave(result: PriceGroup) {
    this.router.navigate([`/commercial-sales/price-groups/`, result.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
