import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HarvestHubHelpArticlesTableComponent } from './harvest-hub-help-articles-table/harvest-hub-help-articles-table.component';
import {GaiaGeneralModule} from '../../gaia-general/gaia-general.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import { HarvestHubHelpArticleFormComponent } from './harvest-hub-help-article-form/harvest-hub-help-article-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { HarvestHubHelpArticleNewDialogComponent } from './harvest-hub-help-article-new-dialog/harvest-hub-help-article-new-dialog.component';
import { HarvestHubHelpArticleShowComponent } from './harvest-hub-help-article-show/harvest-hub-help-article-show.component';
import {FlexModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
  declarations: [
    HarvestHubHelpArticlesTableComponent,
    HarvestHubHelpArticleFormComponent,
    HarvestHubHelpArticleNewDialogComponent,
    HarvestHubHelpArticleShowComponent
  ],
  imports: [
    CommonModule,
    GaiaGeneralModule,
    GaiaSharedModule,
    MatTableModule,
    RouterModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule
  ],
    exports: [HarvestHubHelpArticlesTableComponent, HarvestHubHelpArticleFormComponent]
})
export class HarvestHubHelpModule { }
