import { Injectable } from '@angular/core';
import {
  MiscanthusHarvest,
  MiscanthusHarvestDashboardStats,
  MiscanthusHarvestsCompletedStats, MiscanthusHarvestsTonnageSummary
} from './miscanthus-harvest.model';
import {HttpClient} from '@angular/common/http';
import {DashboardService} from '../../services/dashboard.service';
import {Observable, Subscription} from 'rxjs';
import * as FileSaver from 'file-saver';
import {GaiaLogger} from '../../../utils/common-functions';

@Injectable({
  providedIn: 'root'
})
export class MiscanthusHarvestsService extends DashboardService<MiscanthusHarvest, MiscanthusHarvestDashboardStats> {

  constructor(httpClient: HttpClient) {
    super('/miscanthus_harvests', 'miscanthus_harvest', httpClient);
  }

  downloadMissingDecList(year: number, completed): Subscription {
    GaiaLogger.log('downloading');
    return this.httpGetBuffered(this.generateNonRecordUrl('missing_dec_report'))
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/csv'});
          FileSaver.saveAs(file, `${year}_missing_harvest_decs.csv`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  downloadComparisonReport(year: number, completed): Subscription {
    GaiaLogger.log('downloading');
    return this.httpGetBuffered(this.generateNonRecordUrl('year_comparison_report'))
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/csv'});
          FileSaver.saveAs(file, `${year}_diff_report.csv`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

  completionStats(year: number): Observable<MiscanthusHarvestsCompletedStats> {
    return this.httpGet<MiscanthusHarvestsCompletedStats>(this.generateNonRecordUrl('completion_stats'), { year });
  }

  tonnageSummary(year: number): Observable<MiscanthusHarvestsTonnageSummary> {
    return this.httpGet<MiscanthusHarvestsTonnageSummary>(this.generateNonRecordUrl('tonnage_summary'), { year });
  }

  downloadSustainabilityConfirmation(harvest: MiscanthusHarvest, completed): Subscription {
    return this.httpGetBuffered(this.generateUrl({ recordId: harvest.id }, 'download_sustainability_confirmation'))
      .subscribe(
        (response) => {
          const file = new Blob([response], {type: 'application/pdf'});
          FileSaver.saveAs(file, `${harvest.grower.grower_code}_${harvest.year}_sustainability_confirmation.pdf`);
        },
        () => {},
        () => {
          completed();
        }
      );
  }

}

