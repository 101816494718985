<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.absences}}</mat-icon>
    <mat-card-title i18n>Leave Requests</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="claimant">
        <mat-header-cell *matHeaderCellDef i18n>Claimant</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.staff_member.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef i18n>Type</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.absence_type }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="start_date">
        <mat-header-cell *matHeaderCellDef i18n>Start</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.start_date_time | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="end_date">
        <mat-header-cell *matHeaderCellDef i18n>End</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.end_date_time | date }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="in_days">
        <mat-header-cell *matHeaderCellDef i18n>In Days</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.in_days }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="menu">
        <mat-header-cell *matHeaderCellDef class="w-75"></mat-header-cell>
        <mat-cell *matCellDef="let element" class="w-75">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Approval menu">
            <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewRecord(element)">
              <mat-icon>{{gaiaIconSet.view}}</mat-icon>
              <span i18n="menu option to view full record details">View</span>
            </button>
            <button mat-menu-item (click)="approve(element)">
              <mat-icon>{{gaiaIconSet.approve}}</mat-icon>
              <span i18n="menu option to mark as approved">Approve</span>
            </button>
            <button mat-menu-item (click)="reject(element)">
              <mat-icon>{{gaiaIconSet.reject}}</mat-icon>
              <span i18n="menu option to mark as rejected">Reject</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
    <mat-paginator [length]="dataSource.totalRecords" [pageSize]="30"
                   [pageSizeOptions]="[30, 50, 100]"></mat-paginator>

  </mat-card-content>
</mat-card>
