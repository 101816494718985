<div class="price-groups">
  <app-index-header (addNewClicked)="newRecord()" (searchRequest)="doSearch($event)"
                    [searchValue]="searchTerm"
                    pageTitle="Price Groups" i18n-pageTitle="price groups page title"
                    searchHint="Name"></app-index-header>

  <gaia-shared-waiting-animation *ngIf="dataSource.loading$ | async"></gaia-shared-waiting-animation>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button color="accent" [routerLink]="['/', appRoutes.COMMERCIAL_SALES, commercialSalesRoutes.PRICE_GROUPS, element.id]">{{element.name}}</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="default_group_contact">
      <mat-header-cell *matHeaderCellDef i18n="Default group table header">Default (Direct)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.default_group_contact">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="default_group_stockist">
      <mat-header-cell *matHeaderCellDef i18n="Default group table header">Default (Stockist)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.default_group_stockist">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="default_group_brand_ambassador">
      <mat-header-cell *matHeaderCellDef i18n="Default group table header">Default (BA)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.default_group_brand_ambassador">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="default_group_commercial_user">
      <mat-header-cell *matHeaderCellDef i18n="Default group table header">Default (Commercial)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.default_group_commercial_user">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef i18n="Active table header">Active</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.active">check_circle</mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalRecords"
                 [pageIndex]="dataSource.pageIndex"
                 [pageSize]="dataSource.pageSize"
                 [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
</div>
