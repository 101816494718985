import { Component } from '@angular/core';
import {IndexTableDirective} from '../../../components/index-table.directive';
import {MatDialog} from '@angular/material/dialog';
import {GrowerContract} from '../grower-contract.model';
import {GrowerContractsService} from '../grower-contracts.service';
import {GrowerContractNewDialogComponent} from '../grower-contract-new-dialog/grower-contract-new-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowingRoutes} from '../../growing-routing.module';

@Component({
  selector: 'app-grower-contracts-table',
  templateUrl: './grower-contracts-table.component.html',
  styleUrls: ['./grower-contracts-table.component.scss']
})
export class GrowerContractsTableComponent extends IndexTableDirective<GrowerContract> {

  growingRoutes = GrowingRoutes;
  displayedColumns = ['contract_ref', 'grower_code'];

  constructor(
    growerContractsService: GrowerContractsService,
    router: Router,
    activatedRoute: ActivatedRoute,
    matDialog: MatDialog,
  ) {
    super(growerContractsService, router, activatedRoute, matDialog, GrowerContractNewDialogComponent);
  }

  searchOpts(): any {
    let searchData = {};
    if (this.searchTerm) {
      const fuzzyTerm = this.fuzzySearchTerm();
      searchData = {
        grower: { grower_code: fuzzyTerm, },
        search_mode: 'or',
      };
    }
    return searchData;
  }
}
