import {Component, Input, OnInit} from '@angular/core';
import {ContractedField} from '../contracted-field.model';

@Component({
  selector: 'app-contracted-field-warnings',
  templateUrl: './contracted-field-warnings.component.html',
  styleUrls: ['./contracted-field-warnings.component.scss']
})
export class ContractedFieldWarningsComponent implements OnInit {

  @Input()
  contractedField: ContractedField;

  constructor() { }

  ngOnInit(): void {
  }

}
