import {Component, forwardRef, Injector, Input} from '@angular/core';
import {ContractorsService} from '../contractors.service';
import {Contractor} from '../contractor.model';
import {RecordListSelectDirective} from '@terravesta/phanes';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-contractor-select',
  templateUrl: './contractor-select.component.html',
  styleUrls: ['./contractor-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractorSelectComponent),
      multi: true
    }
  ]
})
export class ContractorSelectComponent extends RecordListSelectDirective<Contractor> {

  @Input()
  label: string = $localize`:@@ContractorSelect.defaultLabel:Contractor`;

  @Input()
  activeOnly = false;

  constructor(
    contractorService: ContractorsService,
    inj: Injector
  ) {
    super(contractorService, inj, 100);
  }

  searchFilters(): any {
    if (this.activeOnly) {
      return {
        active: true,
      };
    }else{
      return {};
    }
  }

}
