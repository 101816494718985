import {Component} from '@angular/core';
import {RecordFormDirective} from '../../../components/record-form.directive';
import {StaffMember} from '../staff-member.model';

@Component({
  selector: 'app-staff-member-form',
  templateUrl: './staff-member-form.component.html',
  styleUrls: ['./staff-member-form.component.scss']
})
export class StaffMemberFormComponent extends RecordFormDirective<StaffMember> {


}
