import {Component, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AssetManagementService} from "../asset-management.service";
import {Asset} from "../asset.model";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {AssetsPopupComponent} from "../assets-popup/assets-popup.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {GaiaIconSet} from "../../models/gaia-icon-set";
import {LoadingComponent} from "../../loading/loading.component";
import {LoadingService} from "../../loading/loading.service";
import * as FileSaver from "file-saver";
import {FormBuilder, Validators} from "@angular/forms";
import {EChartsOption} from "echarts";

@Component({
  selector: 'assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss'],
  providers: [
    LoadingComponent
  ]
})
export class AssetsTableComponent implements OnInit{
  @ViewChild(MatPaginator) _paginator!:MatPaginator;
  @ViewChild(MatSort) _sort!:MatSort;

  // assets$: Observable<Asset[]>;
  assetData: Asset[] = [];
  displayColumns :string[] = ["name", "terravesta_reference", "category_name", "actions"]
  finalData: any;
  paginatorData: { [key: string]: any } = {};
  categories: any[] = [];
  filterText: any;
  assetsStats: any[] = [];
  years: string[] | undefined
  option: EChartsOption = {};
  optionsYearlyChart: EChartsOption = {};

  constructor(
    private assetService: AssetManagementService,
    private dialog:MatDialog,
    private loadingService: LoadingService,
    private builder: FormBuilder
  ) {

    // const reportYears = this.assetsStats?.['years'].map(String)
    const years = this.assetsStats && this.assetsStats['years'];
    if (years && Array.isArray(years)) {
      const mappedYears = years.map(String);
      // Use mappedYears as needed
    } else {
      console.error('years is undefined or not an array');
    }
  }

  ngOnInit(): void {
    console.log("I AM AT ngOnInit");
    this.loadAssetData();

    const allCategories$ =  this.assetService.loadAssetDependencies();
    this.loadingService.showLoaderUntilCompleted(allCategories$).subscribe({
      next:(res) => {
        this.categories = res["categories"];
      },
      error: () => {
        alert("Error while fetching asset categories")
      }
    })

    const assetsStats$ =  this.assetService.assetsStats();
    this.loadingService.showLoaderUntilCompleted(assetsStats$).subscribe({
      next:(res) => {
        // this.assetsStats = JSON.parse(JSON.stringify(res["assets_stats"]));
        this.assetsStats = res["assets_stats"][0];
        console.log(this.assetsStats);
        console.log("Report years this.assetsStats['years']: " + this.assetsStats['years'].map(String));
        console.log(Array.isArray(this.assetsStats['years']))
        this.years = this.assetsStats['years'].map(String)

        this.updateChartOptions();
      },
      error: () => {
        alert("Error while fetching asset statistics")
      }
    })

  }

  updateChartOptions() {
    // Apache echarts
    this.option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Quantity', 'Value']
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: this.years
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Quantity',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: this.assetsStats['yearly_quantities']
        },
        {
          name: 'Value',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: this.assetsStats['yearly_values']
        }
      ]
    };

  }

  updateOptionsYearlyChart(value: any) {
    console.log('value', Number.isInteger(value));

     // Find the 'yearly_categorywise_stats' for the given 'value' year
    const yearly_categorywise_stats = this.assetsStats['yearly_stats'].find(stat => stat['year'] === Number(value));

    // Apache echarts
    this.optionsYearlyChart = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: ['Quantity', 'Value']
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: this.assetsStats['all_category_names']
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Quantity',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: yearly_categorywise_stats['yearly_asset_categorywise_quantities']
        },
        {
          name: 'Value',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: yearly_categorywise_stats['yearly_asset_categorywise_values']
        }
      ]
    };

  }

  loadAssetData(filterParams?: any) {
    const allAssetData$ = this.assetService.loadAllAssets(filterParams);

    this.loadingService.showLoaderUntilCompleted(allAssetData$).subscribe({
      next:(res) => {
        this.assetData = res;
        console.log('res', res)
        console.log('this.assetData[\'paginator\']', this.assetData['paginator']['total_count'])
        this.finalData = new MatTableDataSource<Asset>(this.assetData["company_assets"])
        this.finalData.paginator=this._paginator;
        this.finalData.sort=this._sort;
        this.paginatorData['total_count'] = this.assetData['paginator']['total_count=']
        this.paginatorData['per_page'] = this.assetData['paginator']['per_page=']
        console.log(this.paginatorData.total_count);
      },
      error: () => {
        alert("Error while fetching assets")
      }
    })
  }

  openPopup(id: any) {
    // const _popup = this.dialog.open(AssetsPopupComponent, {
    //   data: {
    //     id: id
    //   }
    // })

    const _popup = this.dialog.open(AssetsPopupComponent, {
      width: '800px',
      data: {
        id: id
      }
    })

    _popup.afterClosed().subscribe(res => {
      this.loadAssetData();
    })
  }

  editAsset(id) {
    this.openPopup(id);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterText = filterValue;
    this.finalData.filter = filterValue.trim().toLowerCase();
  }

  protected readonly gaiaIconSet = GaiaIconSet;

  downloadAssetList() {

    console.log("----------------------------------------")
    console.log(this.filterText);
    console.log(this.filterAssetForm.get('category')?.value);
    console.log("----------------------------------------")

    const params = {filtered_text: this.filterText, filtered_category: this.filterAssetForm.get('category')?.value}

    const assetList$ = this.assetService.downloadAssetList(JSON.stringify(params));
    this.loadingService.showLoaderUntilCompleted(assetList$).subscribe({
      next:(response: any) => {
        console.log('Response received:', response);
        // alert("File will be downloaded...");
        const file = new Blob([response], {type: 'application/csv'});
        FileSaver.saveAs(file, "assets_list_report.csv");
      },
      error: (err: any) => {
        console.error('Error while downloading assets list:', err);
        alert("Error while downloading assets list")
      },
      complete: () => {
        console.log('Download asset list completed');
      }
    })
  }

  filterAssetForm = this.builder.group({
    category: this.builder.control('')
  })

  submitFilterAssetForm(value: any) {
    this.loadAssetData(JSON.stringify(this.filterAssetForm.value))
  }

  yearlyAssetfilterForm = this.builder.group({
    year: this.builder.control('')
  })

  submitYearlyAssetfilterForm(value: any) {
    this.updateOptionsYearlyChart(value)
  }
}
