import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stats-number-line',
  templateUrl: './stats-number-line.component.html',
  styleUrls: ['./stats-number-line.component.scss']
})
export class StatsNumberLineComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  displayNumber: number;

  @Input()
  overLine = false;

  constructor() { }

  ngOnInit(): void {
  }

}
