import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export class CommercialSalesRoutes {
  public static PRODUCTS = 'products';
  public static PRICE_GROUPS = 'price_groups';
  public static ORDERS = 'orders';
  public static STOCKISTS = 'stockists';
  public static BRAND_AMBASSADORS = 'brand-ambassadors';
  public static COMMERCIAL_USERS = 'commercial_users';
}

const routes: Routes = [
  {
    path: CommercialSalesRoutes.PRODUCTS,
    loadChildren: () => import('./products/product-routing.module').then(m => m.ProductRoutingModule),
  },
  {
    path: CommercialSalesRoutes.PRICE_GROUPS,
    loadChildren: () => import('./price-groups/price-group-routing.module').then(m => m.PriceGroupRoutingModule),
  },
  {
    path: CommercialSalesRoutes.ORDERS,
    loadChildren: () => import('./orders/order-routing.module').then(m => m.OrderRoutingModule),
  },
  {
    path: CommercialSalesRoutes.STOCKISTS,
    loadChildren: () => import('./stockists/stockist-routing.module').then(m => m.StockistRoutingModule),
  },
  {
    path: CommercialSalesRoutes.BRAND_AMBASSADORS,
    loadChildren: () => import('./brand-ambassadors/brand-ambassador-routing.module').then(m => m.BrandAmbassadorRoutingModule),
  },
  {
    path: CommercialSalesRoutes.COMMERCIAL_USERS,
    loadChildren: () => import('./commercial-users/commercial-users-routing.module').then(m => m.CommercialUsersRoutingModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommercialSalesRoutingModule { }
