<mat-card>
  <mat-card-header>
    <mat-card-title>
      <a [routerLink]="['/', appRoutes.GROWING, growingRoutes.GROWERS, miscanthusHarvest.grower.id]" target="_blank">
        {{ miscanthusHarvest.grower.grower_code }}</a>
    </mat-card-title>
    <div fxFlex fxLayoutAlign="end center" fxLayoutGap="5px">
      <button mat-flat-button color="primary" class="verify-button" *ngIf="!miscanthusHarvest.ops_checked" (click)="markVerified()">Mark Verified</button>
    </div>
  </mat-card-header>
  <mat-card-content>

    <div *ngIf="miscanthusHarvest.baler_of_harvest">
      Baled By:
      <a [routerLink]="['/', appRoutes.OPERATIONS, operationsRoutes.CONTRACTORS, miscanthusHarvest.baler_of_harvest.id]" target="_blank">
        {{ miscanthusHarvest.baler_of_harvest.account_name }}
      </a>
      - {{ miscanthusHarvest.baler_of_harvest.phone }}
    </div>
    <!--
    <div *ngIf="miscanthusHarvest.grower.contract">
      Contracted Area: {{ miscanthusHarvest.contract.contract_total_area_ha }}ha
    </div>
    -->
    <div>{{ miscanthusHarvest.declared_bale_count }} <span i18n="">Bales</span></div>
    <div><span i18n="number of stacks">Stack Count: </span> {{ miscanthusHarvest.stack_count }} </div>
    <div *ngIf="harvestSummary">
      <span i18n="average bale weight">Avg Delivered Bale Weight:</span> {{harvestSummary.collection_details.avg_bale_weight | number}}
    </div>

  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      Field Performance
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <gaia-shared-processing-overlay defaultMessage="Updating the field performance data"
                                    i18n-defaultMessage="updating field performance loading message"></gaia-shared-processing-overlay>

    <mat-table *ngIf="harvestSummary" [dataSource]="harvestSummary.fields">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef i18n> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.field.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="planted_area_ha">
        <mat-header-cell *matHeaderCellDef i18n> Planted Area(ha) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.field.planted_area_ha}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="bale_count">
        <mat-header-cell *matHeaderCellDef i18n> Bale Count <br /> (Grower) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.harvest_details.bale_count }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="baler_count">
        <mat-header-cell *matHeaderCellDef i18n> Bale Count <br /> (Contractor) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.harvest_details.baler_count }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total_yield">
        <mat-header-cell *matHeaderCellDef i18n> Total Field </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.harvest_details.bale_count * harvestSummary.collection_details.avg_bale_weight | number }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="per_ha">
        <mat-header-cell *matHeaderCellDef i18n> T/Ha </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{(element.harvest_details.bale_count * harvestSummary.collection_details.avg_bale_weight)/element.field.planted_area_ha | number }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="variety">
        <mat-header-cell *matHeaderCellDef i18n> Variety </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.field.variety }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="fieldColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: fieldColumns"></mat-row>
    </mat-table>


  </mat-card-content>
</mat-card>

<h1> Stacks </h1>

<div fxLayout="row wrap" fxLayoutGap="10px">
  <app-miscanthus-stack-review *ngFor="let stack of stacks" fxFlex="calc(50% - 10px)" [miscanthusStack]="stack"
    (fieldHarvestsUpdated)="loadFieldPerformance()"></app-miscanthus-stack-review>
</div>

