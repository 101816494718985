import { Injectable } from '@angular/core';
import {RestApiService} from '../../services/rest-api.service';
import {HttpClient} from '@angular/common/http';
import {ContactType} from './contact-type.model';

@Injectable({
  providedIn: 'root'
})
export class ContactTypesService extends RestApiService<ContactType> {

  constructor(
    httpClient: HttpClient
  ) {
    super('/contact_types', 'contact_type', httpClient);
  }
}
