<ng-container [formGroup]="form">

  <mat-form-field>
    <mat-label i18n>Category</mat-label>
    <mat-select [formControlName]="fieldName">
      <mat-option *ngFor="let category of categories" [value]="category.id"> {{ category.name }}</mat-option>
    </mat-select>
  </mat-form-field>

</ng-container>
