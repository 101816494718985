<button mat-raised-button color="primary" type="button" i18n (click)="newSoilMeasurement()">New Soil Measurement</button>
<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.measurement}}</mat-icon>
    <mat-card-title i18n>Soil Measurement</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="time_taken">
        <mat-header-cell *matHeaderCellDef i18n>Time Taken</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-button color="accent" (click)="editSoilMeasurement(element)">
            {{ element.time_taken | date: 'shortDate'}}
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="moisture_percentage">
        <mat-header-cell *matHeaderCellDef i18n>Mositure (%)</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.moisture_percentage }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="temperature">
        <mat-header-cell *matHeaderCellDef i18n>Temperature</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.temperature }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>

    <mat-paginator [length]="dataSource.totalRecords" [pageSize]="10"></mat-paginator>

  </mat-card-content>
</mat-card>
