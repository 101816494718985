import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CompaniesModule} from './companies/companies.module';
import { BusinessSettingsMenuComponent } from './business-settings-menu/business-settings-menu.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {CompanyOfficesModule} from './company-offices/company-offices.module';



@NgModule({
  declarations: [
    BusinessSettingsMenuComponent
  ],
  imports: [
    CommonModule,
    CompaniesModule,
    CompanyOfficesModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    MatButtonModule
  ],
  exports: [
    BusinessSettingsMenuComponent
  ]
})
export class BusinessSettingsModule { }
