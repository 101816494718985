import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UniversalSearchData} from '../universal-search.model';

@Component({
  selector: 'app-universal-search-preview-wrapper',
  templateUrl: './universal-search-preview-wrapper.component.html',
  styleUrls: ['./universal-search-preview-wrapper.component.scss']
})
export class UniversalSearchPreviewWrapperComponent implements OnChanges {

  @Input()
  searchResult: UniversalSearchData;

  @Input()
  classType: string;

  @Input()
  icon: string;

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges) {
  }

}
