<app-new-record-dialog [form]="form" dialogTitle="New Schedule" (saveClicked)="save()" [dialogRef]="matDialog">

  <mat-form-field>
    <mat-label>Harvest Year</mat-label>
    <input matInput [(ngModel)]="harvestYear" />
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="createForm()">Create</button>

  <ng-container [formGroup]="form">
    <mat-table [dataSource]="dataSource" formArrayName="schedule_months">

      <ng-container matColumnDef="harvest_year">
        <mat-header-cell *matHeaderCellDef i18n>Harvest Year</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="harvest_year" [readonly]="true" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="month">
        <mat-header-cell *matHeaderCellDef i18n>Month</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          {{ element.get('month').value | date: 'MMM y' }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="planned_tonnage">
        <mat-header-cell *matHeaderCellDef i18n>Planned Tonnage</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="planned_tonnage" type="number" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef> {{ totalTonnage }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="adjustment_value">
        <mat-header-cell *matHeaderCellDef i18n>Adjustment (£)</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="adjustment_value" type="number" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="adjustment_text">
        <mat-header-cell *matHeaderCellDef i18n>Adjustment Text</mat-header-cell>
        <mat-cell *matCellDef="let element; let index = index" [formArrayName]="index">
          <input matInput formControlName="adjustment_text" />
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>

    </mat-table>
  </ng-container>

</app-new-record-dialog>
