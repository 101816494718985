import { Component, OnInit } from '@angular/core';
import {StaffMember} from '../../../system-admin/staff-member/staff-member.model';
import {SessionDataService} from '../../../services/session-data.service';
import { TeamSpaceAdminRoutes } from '../team-space-admin-routing.module';
import { TeamSpaceRoutes } from '../../team-space-routing.module';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {AppRoutes} from '../../../app-routing.module';

@Component({
  selector: 'app-team-space-admin-menu',
  templateUrl: './team-space-admin-menu.component.html',
  styleUrls: ['./team-space-admin-menu.component.scss']
})
export class TeamSpaceAdminMenuComponent implements OnInit {

  appRoutes = AppRoutes;
  teamSpaceRoutes = TeamSpaceRoutes;
  teamSpaceAdminRoutes = TeamSpaceAdminRoutes;
  gaiaIcons = GaiaIconSet;

  currentUser: StaffMember;

  constructor(private sessionDataService: SessionDataService) { }

  ngOnInit(): void {
    this.currentUser = this.sessionDataService.getUser();
  }

}
