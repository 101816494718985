import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {AssetManagementService} from "../asset-management.service";
import {Observable} from "rxjs";
import {AssetCategory} from "../asset.model";
import {LoadingService} from "../../loading/loading.service";

@Component({
  selector: 'assets-popup',
  templateUrl: './assets-popup.component.html',
  styleUrls: ['./assets-popup.component.scss']
})
export class AssetsPopupComponent implements OnInit{
  // categories: Observable<AssetCategory[]>
  categories: any[] = [];
  makes: any[] = [];
  models: any[] = [];
  tested_by_users: any[] = [];
  store_locations: any[] = [];
  editData: any;
  actionBtn: string = "Save"

  constructor(
    private dialog: MatDialog,
    private builder: FormBuilder,
    private assetService: AssetManagementService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private loadingService: LoadingService
    ) {

  }

  ngOnInit(): void {
    console.log('this.data.id', this.data.id);
    if(this.data.id != '' && this.data.id != null) {

      this.actionBtn = "Update";

      const findAsset$ = this.assetService.findAsset(this.data.id)

      this.loadingService.showLoaderUntilCompleted(findAsset$).subscribe(res => {
        this.editData = res;
        console.log('this.editData', this.editData)
        this.assetForm.setValue({
          id: this.editData.id,
          name: this.editData.name,
          category: this.editData.category_id,
          status: this.editData.status,
          purchase_price: this.editData.purchase_price,
          estimated_value: this.editData.estimated_value,
          purchase_date: this.editData.purchase_date,
          make: this.editData.make_id,
          model: this.editData.model_id,
          tested_by_staffmember_id: this.editData.tested_by_staffmember_id,
          store_location: this.editData.store_location_id,
          terravesta_reference: this.editData.terravesta_reference,
          notes: this.editData.notes
        })
      })
    } else {
      // alert('I am here');

    }

    // const allCategories$ =  this.assetService.loadAssetCategories();
    const allCategories$ =  this.assetService.loadAssetDependencies();
    this.loadingService.showLoaderUntilCompleted(allCategories$).subscribe({
      next:(res) => {
        this.categories = res["categories"];
        this.makes = res["makes"];
        this.models = res["models"];
        this.tested_by_users = res["tested_by_users"];
        this.store_locations = res["store_locations"];
        console.log(this.categories);
      },
      error: () => {
        alert("Error while fetching asset categories")
      }
    })
  }

  assetForm = this.builder.group({
    id: this.builder.control({value: '', disabled: true}),
    name: this.builder.control('', Validators.required),
    category: this.builder.control('', Validators.required),
    status: this.builder.control('', Validators.required),
    purchase_price: this.builder.control(''),
    estimated_value: this.builder.control(''),
    purchase_date: this.builder.control(''),
    make: this.builder.control('', Validators.required),
    model: this.builder.control('', Validators.required),
    tested_by_staffmember_id: this.builder.control(''),
    store_location: this.builder.control(''),
    terravesta_reference: this.builder.control({value: 'TV-', disabled: false}),
    notes: this.builder.control('')
  })

  saveAsset() {
    if(this.assetForm.valid) {
      const editId = this.assetForm.getRawValue().id;

      if (editId != '' && editId != null) {
        this.assetService.updateAsset(editId, this.assetForm.value).subscribe({
          next:() => {
            alert("Asset updated successfully");
            this.closePopup();
          },
          error:(res) => {
            console.log(res.error.error_message);
            alert(res.error.error_message);
          }
        })
      } else {
        // if (this.checkIsNameExists(this.assetForm.value.name) == true) {
        this.assetService.saveAsset(this.assetForm.value).subscribe({
          next:(res) => {
            alert("Asset created successfully");
            this.closePopup();
          },
          error:(res) => {
            console.log(res.error.error_message);
            alert(res.error.error_message);
            // alert("Error while creating the Asset");
          }
        })
        // } else {
        //   alert("The name already exists.")
        // }
      }
    } else {
      alert("Fill up all required fields with valid data.")
    }
  }

  closePopup() {
    this.dialog.closeAll();
  }

  checkIsNameExists(name: any) {
    this.assetService.is_name_exists(name).subscribe({
      next:(res) => {
        console.log(res);
      },
      error:() => {
        alert("Error checking name whether exists.");
      }
    })
    return false;
  }

  onSelectionCategoryChange(selectedCtegoryId: any) {
    // const selected = this.categories.find(category => category.id === selectedCtegoryId)
    // this.assetForm.get('terravesta_reference').setValue(`TV-${selected.sort_code}`);

    let entryType = '';
    let assetId = '';
    if(this.data.id != '' && this.data.id != null) {
      entryType = 'edit';
      assetId = this.data.id;
    } else {
      entryType = 'new';
    }

    // alert(entryType);
    this.assetService.get_terravesta_reference(entryType, selectedCtegoryId, assetId).subscribe({
      next:(res) => {
        this.assetForm.get('terravesta_reference').setValue(res["terravesta_reference"]);
      },
      error:() => {
        alert("Error fetching terravesta reference.");
      }
    })
  }
}
