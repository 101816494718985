import { Component } from '@angular/core';

@Component({
  selector: 'app-grower-stats-dashboard',
  templateUrl: './grower-stats-dashboard.component.html',
  styleUrls: ['./grower-stats-dashboard.component.scss']
})
export class GrowerStatsDashboardComponent {


}
