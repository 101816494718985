import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ContactsTableComponent} from './contacts-table/contacts-table.component';
import {ContactShowComponent} from './contact-show/contact-show.component';
import {ContactsSettingsComponent} from './contacts-settings/contacts-settings.component';

export class ContactsRoutes {
  public static SETTINGS = 'settings';
}

const routes: Routes = [
  { path: ContactsRoutes.SETTINGS, component: ContactsSettingsComponent},
  { path: '', component: ContactsTableComponent },
  { path: ':id', component: ContactShowComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
