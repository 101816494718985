import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {UserDashboardComponent} from '../../dashboard-components/user-dashboard/user-dashboard.component';

@Component({
  selector: 'app-dashboard-widget-wrapper',
  templateUrl: './dashboard-widget-wrapper.component.html',
  styleUrls: ['./dashboard-widget-wrapper.component.scss']
})
export class DashboardWidgetWrapperComponent implements OnInit {

  @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input() componentTitle: string;

  @Input() editMode: boolean;

  @Input() dashboard: UserDashboardComponent;

  @Output() widgetMoved: EventEmitter<{ x: number, y: number }> = new EventEmitter<{x: number; y: number}>();

  gaiaIconSet = GaiaIconSet;

  constructor() { }

  ngOnInit(): void {
  }

  dragConstraints(pos, dragRef) {
    // This is not the component, but instead it's the drag event
    const drag = (this as any);
    const inElement = drag._pickupPositionInElement;
    const config = drag.data.data;
    const width = config.cellWidth + config.gap;
    const height = config.cellHeight + config.gap;

    const indexes = toIndex(pos, config);
    const snapX = (indexes.x * width) + config.left + inElement.x;
    const snapY = (indexes.y * height) + config.top + inElement.y;

    return {x: snapX, y: snapY};
  }

  dragEnd(event) {
    const config = event.source.data;
    const indexes = toIndex(event.dropPoint, config);
    this.widgetMoved.emit(indexes);
  }
}

function toIndex(pos, config) {
  const width = config.cellWidth + config.gap;
  const height = config.cellHeight + config.gap;

  const relX = pos.x - config.left;
  let indexX = Math.floor(relX / width);
  if (indexX < 0) {
    indexX = 0;
  }
  // GaiaLogger.log(indexX, width, config.left, inElement.x, snapX, relSnapX);

  const relY = pos.y - config.top;
  let indexY = Math.floor(relY / height);
  if (indexY < 0) {
    indexY = 0;
  }

  return {x: indexX, y: indexY};
}
