import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProcessorsService} from '../processors.service';
import {PhanesNode} from '@terravesta/phanes';
import {Processor} from '../processor.model';
import {DeviceType, LayoutTrackingService} from '../../../gaia-layout/layout-tracking.service';

@Component({
  selector: 'app-processor-map-feature-window',
  templateUrl: './processor-map-feature-window.component.html',
  styleUrls: ['./processor-map-feature-window.component.scss']
})
export class ProcessorMapFeatureWindowComponent implements OnInit, OnChanges {

  constructor(
    private processorsService: ProcessorsService,
    private layoutTracking: LayoutTrackingService
  ) {
    this.layoutTracking.deviceType.subscribe((deviceType) => {
      this.isMobile = deviceType === DeviceType.MOBILE;
      this.panelOpenState = !this.isMobile;
      console.log(deviceType, this.panelOpenState);
    });
  }
  @Input() clickedProcessorRecord: PhanesNode;
  @Output() closeClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  processorData: Processor;
  visible = true;
  panelOpenState = true;
  isMobile = false;

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.visible = true;
    if (changes.clickedProcessorRecord){
      if (changes.clickedProcessorRecord.currentValue){
        this.getProcessorData();
      }
    }
  }
  getProcessorData(){
    this.processorsService.getRecord({recordId: this.clickedProcessorRecord.id}).subscribe((processor) => {
      this.processorData = processor;
    });
  }
  CloseInfo() {
    this.visible = !this.visible;
    this.closeClicked.emit(true);
  }

}
