import {GaiaRecord} from '../../models/gaia-record.model';
import {Grower} from '../../growing/growers/grower.model';
import {Address} from '@terravesta/gaia-shared';
import {BaleType} from '../bale-types/bale-type.model';
import {GPSPoint, PhanesNode, PhanesW3W} from '@terravesta/phanes';
import {BaleGrabAvailability} from './bale-grab-availabilities/bale-grab-availability.model';
import {StackStorageMethod} from './stack-storage-methods/stack-storage-method.model';
import {LoaderAvailability} from './loader-availabilities/loader-availability.model';
import {StackNoticePeriod} from './stack-notice-periods/stack-notice-period.model';
import {MiscanthusHarvest} from '../miscanthus-harvests/miscanthus-harvest.model';
import {Contact} from '../../contacts/contact.model';
import {ScheduleMonth} from '../processors/schedule-months/schedule-month.model';
import {Processor} from '../processors/processor.model';

export interface MiscanthusStack extends GaiaRecord, PhanesW3W, GPSPoint {
  harvest_year: number;
  harvest: MiscanthusHarvest;

  location_description: string;
  access_restrictions: string;
  notes: string;

  loader_availability: LoaderAvailability;
  bale_grab_availability: BaleGrabAvailability;
  notice_period: StackNoticePeriod;
  storage_method: StackStorageMethod;
  estimated_tonnage: number;

  declared_bale_count: number;
  remaining_bale_count: number;
  collected_bale_count: number;
  field_bale_count: number;
  other_bale_count: number;
  bale_loss_count: number;

  delivered_avg_bale_weight: number;
  miles_to_selected_processor: number;

  stored_date: string;
  completed: boolean;
  stat_no_collections: boolean,
  stat_part_collected: boolean,
  stat_completed_collections: boolean,

  grower: Grower;
  address: Address;
  stack_contact: Contact;

  bale_type: BaleType;
  schedule_month: ScheduleMonth;
  name: string;
  processor_name: string;
  processor_colour: string;

  processor: Processor; // For the ops dashboard view

}

export interface MiscanthusStackPositions  {
  stacks: Array<GPSPoint & PhanesNode>;
}


export interface MonthSummaries {
  [key: string]: MonthSummary;
}

export interface MonthSummary {
  stack_count: number;
  not_stated: number;
  part_completed: number;
  completed: number;
}

export const MISCANTHUS_STACK_DELIVERY_STATUS = [
  'Completed',
  'Partially Completed',
  'Not Completed',
];

export const DELIVERY_COLORS = [
  '#ee021d',
  '#22730b',
  'rgb(236,230,8)'
];

export function deliveryStatusToColor(stage: string) {
  const index = MISCANTHUS_STACK_DELIVERY_STATUS.indexOf(stage);
  return DELIVERY_COLORS[index];
}

