import { Component, OnInit } from '@angular/core';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {ContractRoutes} from '../contracts-routing.module';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';

@Component({
  selector: 'app-contracts-menu',
  templateUrl: './contracts-menu.component.html',
  styleUrls: ['./contracts-menu.component.scss']
})
export class ContractsMenuComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  contractRoutes = ContractRoutes;

  constructor() { }

  ngOnInit(): void {
  }

}
