import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {DashboardWidgetDirective} from '../../../../custom-dashboards/dashboard-shared/dashboard-widget.directive';
import {EChartsOption} from 'echarts';
import {PlantingOpportunitiesService} from '../../planting-opportunities.service';
import {GaiaLogger} from '../../../../../utils/common-functions';
import {PLANTING_OPPORTUNITY_SALESFORCE_STAGES} from '../../planting-opportunity.model';
import {WidgetDefinition} from '../../../../custom-dashboards/dashboard-components/dashboard-widget-factory';

@Component({
  selector: 'app-planting-opportunity-stage-funnel-widget',
  templateUrl: './planting-opportunity-stage-funnel-widget.component.html',
  styleUrls: ['./planting-opportunity-stage-funnel-widget.component.scss']
})
export class PlantingOpportunityStageFunnelWidgetComponent extends DashboardWidgetDirective implements OnInit {

  funnelChart: EChartsOption;

  endYear = new Date().getFullYear() + 3;
  startYear = new Date().getFullYear() + 1;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    private plantingOpportunitiesService: PlantingOpportunitiesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(elementRef, renderer);
  }

  static widgetDefinition(): WidgetDefinition {
    return {
      name: 'Opportunity Stages',
        componentClass: PlantingOpportunityStageFunnelWidgetComponent,
      description: 'Funnel Diagram of the stages',
      code: 'PLANT-OPS-FUNNEL',
      grouping: 'Sales',
      department: 'Sales',
      minColumnCount: 2,
      minRowCount: 3,
    };
  }

  ngOnInit(): void {
  }

  updateGraph(year: number) {
    this.plantingOpportunitiesService.countByStage(year).subscribe((response) => {
      const data = [];
      // const data = response.map((item) => ({ value: item.count, name: item.stage }));
      PLANTING_OPPORTUNITY_SALESFORCE_STAGES.forEach((stage) => {
        const match = response.find(i => i.stage === stage);
        data.push({
          name: stage,
          value: match ? match.count : 0,
        });
      });
      GaiaLogger.log(data);
      this.funnelChart = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} {c}'
        },
        series: [
          {
            type: 'funnel',
            data,
            sort: 'none',
          }
        ]
      };
    });
    this.changeDetectorRef.detectChanges();
  }

  processSettings(settings: any) {
  }
}
