import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppRoutes} from '../../app-routing.module';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {MatPaginator} from '@angular/material/paginator';
import {AccountForContact} from '../account.model';
import {Contact} from '../contact.model';
import {ActivatedRoute} from '@angular/router';
import {ContactsService} from '../contacts.service';
import {GrowingRoutes} from '../../growing/growing-routing.module';
import {OperationsRoutes} from '../../operations/operations-routing.module';
import {CommercialSalesRoutes} from '../../commercial-sales/commercial-sales-routing.module';

@Component({
  selector: 'app-account-list-embed',
  templateUrl: './account-list-embed.component.html',
  styleUrls: ['./account-list-embed.component.scss']
})
export class AccountListEmbedComponent implements  OnInit, AfterViewInit, OnChanges {

  appRoutes = AppRoutes;
  growerRoutes = GrowingRoutes;
  operationRoutes = OperationsRoutes;
  commercialSalesRoutes = CommercialSalesRoutes;
  gaiaIconSet = GaiaIconSet;

  dataSource: Array<AccountForContact> = new Array<AccountForContact>();
  displayedColumns = ['account_name', 'relationship_type', 'grower_code', 'account_type'];


  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  contact: AccountForContact;
  currentContact: Contact;

  @Input()
  showAddNew = true;
  constructor(
    private contactsService: ContactsService,
    protected route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loadAccounts();
  }

  loadAccounts(){
    const id = this.route.snapshot.paramMap.get('id');
    this.contactsService.getRecord({recordId: id}).subscribe(
      (response) => {
        this.currentContact = response;
        this.contactsService.forContactAccount(this.currentContact.id).subscribe(
           (data) => {
             this.dataSource = data;
           }
         );
      }
      );
  }
  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
