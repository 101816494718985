
<ng-container *ngIf="currentRecord">

  <mat-expansion-panel [expanded]="true">

    <mat-expansion-panel-header >
      <mat-panel-title i18n>Notes ({{totalCount}})</mat-panel-title>
    </mat-expansion-panel-header>

    <button class="add-new-btn" mat-raised-button color="primary" (click)="createNew()" i18n>New Note</button>

    <div fxLayout="column" fxLayoutAlign="start center" *ngIf="notes.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <app-internal-note-card [note]="note" *ngFor="let note of notes.getRecords() | async"
                            (noteEdited)="reload()"></app-internal-note-card>

    <mat-paginator [length]="notes.totalRecords" [pageSize]="5" [hidePageSize]="true"></mat-paginator>

  </mat-expansion-panel>


</ng-container>
