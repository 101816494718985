import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ContractedFieldsService} from '../contracted-fields.service';
import {Grower} from '../../growers/grower.model';
import {GaiaIndexDataSource} from '../../../services/gaia-index-data-source';
import {ContractedField} from '../contracted-field.model';
import {GaiaIconSet} from '../../../models/gaia-icon-set';
import {AppRoutes} from '../../../app-routing.module';
import {GrowingRoutes} from '../../growing-routing.module';
import {PaginatedDataSource, PhanesGoogleMapComponent, PhanesRecordMapItem, RailsAPIWrapper} from '@terravesta/phanes';
import {MatDialog} from '@angular/material/dialog';
import {ContractedFieldNewDialogComponent} from '../contracted-field-new-dialog/contracted-field-new-dialog.component';
import {fieldInfoWindowHtml} from '../contracted-field-functions';
import {MatSort} from '@angular/material/sort';
import {SpinnerLoadingButtonComponent} from '@terravesta/gaia-shared';
import {GrowersService} from '../../growers/growers.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DroneImage} from '../../drone-images/drone-image.model';
import {GaiaLogger, yearArray} from '../../../../utils/common-functions';
import {DroneImagesService} from '../../drone-images/drone-images.service';
import {LngLatLike} from 'mapbox-gl';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-contracted-fields-embed',
  templateUrl: './contracted-field-embed.component.html',
  styleUrls: ['./contracted-field-embed.component.scss']
})
export class ContractedFieldEmbedComponent implements OnInit, OnChanges, AfterViewInit {

  gaiaIconSet = GaiaIconSet;
  appRoutes = AppRoutes;
  growingRoutes = GrowingRoutes;
  droneImage: Array<DroneImage>;
  mapCenter: LngLatLike = [-1.313874, 53.196055];
  zoom: number = 10;

  showOld = false;

  displayedColumns = ['name', 'variety', 'year_planted'];
  years = yearArray(2020).reverse();

  yearSelection = new FormControl(new Date().getFullYear());

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input()
  grower: Grower;

  /*@Input()
  field: ContractedField;*/

  @ViewChild(SpinnerLoadingButtonComponent)
  exportKmlButton: SpinnerLoadingButtonComponent;

  @ViewChild(PhanesGoogleMapComponent)
  map: PhanesGoogleMapComponent;

  fields: Array<PhanesRecordMapItem> = new Array<PhanesRecordMapItem>();

  dataSource: GaiaIndexDataSource<ContractedField>;
  oldFieldsDataSource: GaiaIndexDataSource<ContractedField>;

  @ViewChild(MatSort) sort: MatSort;
  public latestDroneImage: Array<any> = [];
  showPopup = false;
  selectedImage: DroneImage;
  urlString: string;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    private droneImageService: DroneImagesService,
    private growersService: GrowersService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.dataSource = new PaginatedDataSource<ContractedField>(
      (params) => this.contractedFieldsService.getContractedFieldsForGrower(this.grower, params));
    this.dataSource.loadRecords();
    // TODO replace me with an optimised grower full query, so no need for pagination
    this.dataSource.connect(null).subscribe(
      (fields) => {
        this.fields = new Array<PhanesRecordMapItem>();
        if (fields) {
         // this.contractedFields = [];
          fields.forEach((field) => {
            const infoWindow = fieldInfoWindowHtml(field);
            const fieldItem = {
              record: field,
              title: field.name,
              infoWindowHtml: infoWindow,
            } as PhanesRecordMapItem;
            this.fields.push(fieldItem);
            this.getDroneImagesByGrower(field);
            GaiaLogger.log(fields);
          });
        }
      });
    this.oldFieldsDataSource = new PaginatedDataSource<ContractedField>(
      (params) => this.contractedFieldsService.getPreviouslyContractedFieldsForGrower(this.grower, params));
    this.oldFieldsDataSource.loadRecords();
    this.oldFieldsDataSource.connect(null).subscribe((records) => {
      if (records) {
        if (records.length > 0) {
          this.showOld = true;
        }
      }
    });
  }

  ngOnChanges() {
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addContractedField() {
    this.matDialog.open(ContractedFieldNewDialogComponent, {
      data: {
        record: { grower: this.grower } as ContractedField,
      }
    });
  }

  exportToKML(){
    this.growersService.currentFieldKMLForGrower(this.grower, () => {
      this.exportKmlButton.processFinished();
    });
  }

  updateMap(event: MatTabChangeEvent){
    if (this.map) {
      this.map.fitToBounds(false, true, true, true);
    }
  }

  searchOpts(field): any {
    return {
        contracted_field: {
          uuid: field.id,
      },
    };
  }

  getDroneImagesByGrower(field: ContractedField){
    this.droneImageService.getRecords({pageNumber: 0, pageSize: 30, search: this.searchOpts(field)}).subscribe(
      (droneImages: RailsAPIWrapper<DroneImage>) => {
        this.droneImage = droneImages.records;
        if (this.droneImage.length > 0)
        {
          this.droneImage.sort((a, b) => new Date(b.date_taken).getTime() - new Date(a.date_taken).getTime()); // Sort the records in descending order by date_taken timestamp
          const latestDroneImage = this.droneImage[0]; // Get the latest image (first element after sorting)
          const urlString = 'mapbox://michaelsterravesta.' + latestDroneImage.mapbox_id;
          this.mapCenter = [latestDroneImage.contracted_field.longitude, latestDroneImage.contracted_field.latitude];
          this.latestDroneImage.push({droneImage: latestDroneImage, urlString: urlString});
          GaiaLogger.log(this.latestDroneImage);
        }
      });
  }

  showAlert() {
    this.showPopup = true;
  }

  handleFeatureClick(event: any) {
    const features = event.lngLat;

    if (features) {
      // Show the popup for the clicked aerial photograph
      this.showPopup = true;
    }
  }

  zoomOnImage(element) {
    this.zoom = 15;
    this.mapCenter = [element.longitude, element.latitude];
  }
}
