<app-new-record-dialog [form]="form" dialogTitle="Note" (saveClicked)="save()" [dialogRef]="matDialog">

  <ng-container [formGroup]="form">

    <mat-form-field>
      <mat-label i18n>Note Date</mat-label>
      <input matInput [ngxMatDatetimePicker]="picker" formControlName="date_noted">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker [showSeconds]="false">
      </ngx-mat-datetime-picker>
    </mat-form-field>

    <app-text-editor formControlName="details"></app-text-editor>

  </ng-container>

</app-new-record-dialog>
