import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import {copiedToClipboard} from '../../../utils/common-snackbars';

@Component({
  selector: 'app-email-link',
  templateUrl: './email-link.component.html',
  styleUrls: ['./email-link.component.scss']
})
export class EmailLinkComponent implements OnInit, OnChanges {

  gaiaIconSet = GaiaIconSet;

  @Input()
  email: string;

  mailToLink = '';

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.email) {
      if (changes.email.currentValue) {
        this.mailToLink = `mailto:${this.email}`;
      }
    }
  }

  copyToClipboard() {
    const result = this.clipboard.copy(this.email);
    if (result){
      copiedToClipboard(this.snackBar);
    }
  }
}
