import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasSampleLocationsFormEmbedComponent } from './has-sample-locations-form-embed/has-sample-locations-form-embed.component';
import {ReactiveFormsModule} from '@angular/forms';
import {What3WordsModule} from '@terravesta/phanes';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        HasSampleLocationsFormEmbedComponent
    ],
    exports: [
        HasSampleLocationsFormEmbedComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    What3WordsModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class HasSampleLocationsModule { }
