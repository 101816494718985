<ng-container *ngIf="form" [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>{{gaiaIconSet.companies}}</mat-icon>
      <mat-card-title>Company Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field>
        <mat-label i18n>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Vat Number</mat-label>
        <input matInput formControlName="vat_number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Company Registration Number</mat-label>
        <input matInput formControlName="reg_number" />
      </mat-form-field>

      <mat-slide-toggle formControlName="use_for_orders" i18n>Used For Orders?</mat-slide-toggle>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>phone</mat-icon>
      <mat-card-title>Contact Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field>
        <mat-label i18n>Phone Number</mat-label>
        <input matInput formControlName="phone_number" type="tel" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Fax Number</mat-label>
        <input matInput formControlName="fax_number" type="tel" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Main Email Address</mat-label>
        <input matInput formControlName="primary_email_address" type="email" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Website</mat-label>
        <input matInput formControlName="web_address" type="url" />
      </mat-form-field>

      <app-file-upload formControlName="logo"></app-file-upload>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-icon mat-card-avatar>payment</mat-icon>
      <mat-card-title>Bank Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field>
        <mat-label i18n>Bank Name</mat-label>
        <input matInput formControlName="bank_name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Bank Account Number</mat-label>
        <input matInput formControlName="bank_account_number" />
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n>Bank Account Number</mat-label>
        <input matInput formControlName="bank_account_sort_code" />
      </mat-form-field>


    </mat-card-content>
  </mat-card>

  <div>

    <gaia-shared-address-embed-form formGroupName="registered_address"
                            [title]="'Registered'"></gaia-shared-address-embed-form>
    <gaia-shared-address-embed-form formGroupName="operational_address"
                                    [title]="'Operational'"></gaia-shared-address-embed-form>
  </div>
</ng-container>
