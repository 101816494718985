import { Component } from '@angular/core';
import {RecordFormDirective} from '../../../../components/record-form.directive';
import {EstablishmentContract} from '../establishment-contract.model';

@Component({
  selector: 'app-establishment-contract-form',
  templateUrl: './establishment-contract-form.component.html',
  styleUrls: ['./establishment-contract-form.component.scss']
})
export class EstablishmentContractFormComponent extends RecordFormDirective<EstablishmentContract> {

}
