import { Injectable } from '@angular/core';
import {GaiaRecordFormControl} from '../../services/gaia-record-form-control';
import {BrandAmbassador} from './brand-ambassador.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BrandAmbassadorFormControlService implements GaiaRecordFormControl<BrandAmbassador> {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  toFormGroup(ba: BrandAmbassador): FormGroup {
    return this.formBuilder.group({
      account_name: new FormControl(ba.account_name, Validators.required),
      default_price_group_id: new FormControl(ba.default_price_group ? ba.default_price_group.id : null, Validators.required),
      on_account: new FormControl(ba.on_account),
      payment_term_days: new FormControl(ba.payment_term_days, Validators.pattern('^\\d+$')),
    });
  }
}
