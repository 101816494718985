import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContactFormControlService} from '../contact-form-control.service';
import {Contact} from '../contact.model';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';
import {ContactsService} from '../contacts.service';
import {NewDialogDirective} from '../../components/new-dialog.directive';

@Component({
  selector: 'app-contact-new-dialog',
  templateUrl: './contact-new-dialog.component.html',
  styleUrls: ['./contact-new-dialog.component.scss']
})
export class ContactNewDialogComponent extends NewDialogDirective<Contact> {

  constructor(
    contactsService: ContactsService,
    contactFormBuilder: ContactFormControlService,
    private router: Router,
    public matDialog: MatDialogRef<ContactNewDialogComponent>,
  ) {
    super(contactsService, contactFormBuilder);
  }

  successfulSave(result: Contact) {
    this.router.navigate([`/contacts/`, result.id]).then((x) => {
      this.matDialog.close();
    });
  }

}
