<mat-card>
  <mat-card-header>
    <mat-icon mat-card-avatar>{{gaiaIconSet.contacts}}</mat-icon>
    <mat-card-title i18n>Contacts</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="column">
      <div fxLayout="row">
        <div *ngIf="showAddNew">
          <button mat-fab class="new-contact" (click)="newContact()">
            <mat-icon>{{ gaiaIconSet.add }}</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="dataSource">
        <mat-table [dataSource]="dataSource" [ngClass.lt-md]="'mobile-table'">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef i18n>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <gaia-shared-cell-mobile-label i18n>Name</gaia-shared-cell-mobile-label>
              {{ element.contact.name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="has_login">
            <mat-header-cell class="w-75" *matHeaderCellDef i18n>Has Login</mat-header-cell>
            <mat-cell class="w-75" *matCellDef="let element">
              <gaia-shared-cell-mobile-label i18n>Has Login</gaia-shared-cell-mobile-label>
              <mat-icon *ngIf="element.locked else noLocked"> {{ gaiaIconSet.locked }}</mat-icon>
              <ng-template #noLocked>
                <mat-icon *ngIf="element.contact.can_login">{{ gaiaIconSet.check }}</mat-icon>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="relationship">
            <mat-header-cell *matHeaderCellDef i18n class="w-125">Type</mat-header-cell>
            <mat-cell *matCellDef="let element" class="w-125">
              <gaia-shared-cell-mobile-label i18n>Type</gaia-shared-cell-mobile-label>
              {{ element.contact.relationship_to_account }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef i18n>Email</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <gaia-shared-cell-mobile-label i18n>Email</gaia-shared-cell-mobile-label>
              <app-email-link [email]="element.contact.email"></app-email-link>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef i18n class="w-125">Phone</mat-header-cell>
            <mat-cell *matCellDef="let element" class="w-125">
              <gaia-shared-cell-mobile-label i18n>Phone</gaia-shared-cell-mobile-label>
              {{ element.contact.phone }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mobile">
            <mat-header-cell *matHeaderCellDef i18n class="w-125">Mobile</mat-header-cell>
            <mat-cell *matCellDef="let element" class="w-125">
              <gaia-shared-cell-mobile-label i18n>Mobile</gaia-shared-cell-mobile-label>
              {{ element.contact.mobile }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="can_access_account">
            <mat-header-cell class="w-75" *matHeaderCellDef i18n>Can Access Account</mat-header-cell>
            <mat-cell class="w-75" *matCellDef="let element">
              <gaia-shared-cell-mobile-label i18n>Access</gaia-shared-cell-mobile-label>
              <mat-icon *ngIf="element.login_to_account">{{ gaiaIconSet.check }}</mat-icon>
              <div *ngIf="element.invite_pending" i18n-matTooltip matTooltip="User has not completed invite process">
                <mat-icon>{{ gaiaIconSet.invite_pending }}</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="can_admin_account">
            <mat-header-cell class="w-75" *matHeaderCellDef i18n>Can Admin</mat-header-cell>
            <mat-cell class="w-75" *matCellDef="let element">
              <gaia-shared-cell-mobile-label i18n>Admin</gaia-shared-cell-mobile-label>
              <mat-icon *ngIf="element.can_admin_account">{{ gaiaIconSet.check }}</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="menu">
            <mat-header-cell class="w-75" *matHeaderCellDef></mat-header-cell>
            <mat-cell class="w-75" *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Contact settings menu">
                <mat-icon>{{ gaiaIconSet.menu }}</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="edit" (click)="editContact(element.contact)">
                  <mat-icon>{{gaiaIconSet.edit}}</mat-icon>
                  <span i18n>Edit</span>
                </button>
                <button mat-menu-item *ngIf="element.locked" (click)="unlockAccount(element)">
                  <mat-icon>{{gaiaIconSet.unlock}}</mat-icon>
                  <span i18n>Unlock</span>
                </button>
                <button mat-menu-item *ngIf="element.login_to_account" class="revoke-access" (click)="revokeAccess(element.contact)">
                  <mat-icon>{{gaiaIconSet.revoke_access}}</mat-icon>
                  <span i18n>Revoke Access</span>
                </button>
                <button mat-menu-item *ngIf="!element.login_to_account && !element.invite_pending" (click)="grantAccess(element.contact)">
                  <mat-icon>{{gaiaIconSet.grant_access}}</mat-icon>
                  <span i18n>Grant Access</span>
                </button>
                <button mat-menu-item *ngIf="element.invite_pending || element.login_to_account" class="resend-invite" (click)="resendInvite(element.contact)">
                  <mat-icon>{{gaiaIconSet.resend_invite}}</mat-icon>
                  <span i18n *ngIf="element.invite_pending else notPending">Resend Invite</span>
                  <ng-template #notPending><span i18n="menu option to reset password">Reset Password</span></ng-template>
                </button>
                <ng-container *ngIf="element.contact_login_id">
                  <button mat-menu-item *ngIf="!element.can_admin_account else demoteAccount" class="promote-admin"
                          (click)="changeAdminStatus(element.contact, true)">
                    <mat-icon>{{gaiaIconSet.promote_to_admin}}</mat-icon>
                    <span i18n>Promote To Admin</span>
                  </button>
                  <ng-template #demoteAccount>
                    <button mat-menu-item class="remove-admin" (click)="changeAdminStatus(element.contact, false)">
                      <mat-icon>{{gaiaIconSet.demote_admin}}</mat-icon>
                      <span i18n>Remove Admin Rights</span>
                    </button>
                  </ng-template>
                </ng-container>
                <button mat-menu-item class="unlink-contact" (click)="unlinkContact(element.contact)">
                  <mat-icon>{{gaiaIconSet.unlink}}</mat-icon>
                  <span i18n="unlink contact from account doesnt delete contact">Unlink from Account</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator [length]="dataSource.totalRecords" [pageSize]="30"
                       [pageSizeOptions]="[30, 50, 100]"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>
