import {Component, Inject, OnInit} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../components/form-dialog.directive';
import {StaffExpenseClaim} from '../staff-expense-claim.model';
import {StaffExpenseClaimsService} from '../staff-expense-claims.service';
import {StaffExpenseClaimFormControlService} from '../staff-expense-claim-form-control.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-staff-expense-claims-form-dialog',
  templateUrl: './staff-expense-claims-form-dialog.component.html',
  styleUrls: ['./staff-expense-claims-form-dialog.component.scss']
})
export class StaffExpenseClaimsFormDialogComponent extends FormDialogDirective<StaffExpenseClaim> implements OnInit {

  constructor(
    staffExpenseClaimsService: StaffExpenseClaimsService,
    staffExpenseClaimFormControl: StaffExpenseClaimFormControlService,
    matDialog: MatDialogRef<StaffExpenseClaimsFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<StaffExpenseClaim>,
  ) {
    super(staffExpenseClaimsService, staffExpenseClaimFormControl, matDialog, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}

