import {ChangeDetectorRef, Component} from '@angular/core';
import {ShowPageDirective} from '../../../components/show-page.directive';
import {MiscanthusStack} from '../miscanthus-stack.model';
import {MiscanthusStacksService} from '../miscanthus-stacks.service';
import {MiscanthusStackFormControlService} from '../miscanthus-stack-form-control.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../../internal-notes/internal-note-tracking.service';
import {PhanesRecordMapItem} from '@terravesta/phanes';
import {MatDialog} from '@angular/material/dialog';
import {MiscanthusFieldHarvestDialogComponent} from '../miscanthus-field-harvests/miscanthus-field-harvest-dialog/miscanthus-field-harvest-dialog.component';
import {MiscanthusFieldHarvest} from '../miscanthus-field-harvests/miscanthus-field-harvest.model';
import {BaleGainDialogComponent} from '../bale-gains/bale-gain-dialog/bale-gain-dialog.component';
import {BaleGain} from '../bale-gains/bale-gain.model';
import {BaleLoss} from '../bale-losses/bale-loss.model';
import {BaleLossDialogComponent} from '../bale-losses/bale-loss-dialog/bale-loss-dialog.component';
import {
  BaleTypeSelectDialogComponent
} from '../../bale-types/bale-type-select-dialog/bale-type-select-dialog.component';
import {GaiaLogger} from '../../../../utils/common-functions';

@Component({
  selector: 'app-miscanthus-stack-show',
  templateUrl: './miscanthus-stack-show.component.html',
  styleUrls: ['./miscanthus-stack-show.component.scss']
})
export class MiscanthusStackShowComponent extends ShowPageDirective<MiscanthusStack> {

  mapItem: PhanesRecordMapItem;

  constructor(
    miscanthusStacksService: MiscanthusStacksService,
    miscanthusStackFormControl: MiscanthusStackFormControlService,
    router: ActivatedRoute,
    snackbar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(miscanthusStacksService, miscanthusStackFormControl, router, snackbar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: MiscanthusStack) {
    GaiaLogger.log('Stack:', record, this.recordService);
    this.mapItem = {
      record: {
        id: record.id,
        latitude: record.address.address_latitude,
        longitude: record.address.address_longitude
      }
    };
  }

  recordLoadCompleted() {
  }

  unlockStack() {
    this.showWrapper.lockScreen();
    (this.recordService as MiscanthusStacksService).markUncompleted(this.record).subscribe(
      (response) => {
        this.updateRecord(response);
        this.snackBar.open($localize`:@@MiscanthusStack.unlocked:Unlocked stack`);
      },
      () => {},
      () => {
        this.showWrapper.unlockScreen();
      }
    );
  }

  addFieldHarvest() {
    const harvestForm = this.dialog.open(MiscanthusFieldHarvestDialogComponent, {
      data: {
        record: {} as MiscanthusFieldHarvest,
        parent: this.record,
      },
    });
    harvestForm.afterClosed().subscribe(() => {
      this.loadRecord();
    });
  }

  addBaleGain() {
    const baleGainForm = this.dialog.open(BaleGainDialogComponent, {
      data: {
        record: {} as BaleGain,
        parent: this.record,
      },
    });
    baleGainForm.afterClosed().subscribe(() => {
      this.loadRecord();
    });
  }

  removeBales() {
    const baleLossForm = this.dialog.open(BaleLossDialogComponent, {
      data: {
        record: {} as BaleLoss,
        parent: this.record,
      },
    });
    baleLossForm.afterClosed().subscribe(() => {
      this.loadRecord();
    });
  }

  completedStack() {
    this.showWrapper.lockScreen();
    (this.recordService as MiscanthusStacksService).markCompleted(this.record).subscribe(
      (response) => {
        this.updateRecord(response);
        this.snackBar.open($localize`:@@MiscanthusStack.completed:Completed`);
      },
      () => {},
      () => {
        this.showWrapper.unlockScreen();
      }
    );
  }

  baleCountChangeMade(event: boolean) {
    if (event) {
      this.loadRecord();
    }
  }

  changeBaleType() {
    const dialogRef = this.dialog.open(BaleTypeSelectDialogComponent);
    dialogRef.afterClosed().subscribe(
      (response) => {
        if (response) {
          if ('bale_type_id' in response) {
            (this.recordService as MiscanthusStacksService).updateBaleType(this.record, response.bale_type_id).subscribe(
              (stack) => {
                this.updateRecord(stack);
                this.snackBar.open($localize`:@@MiscanthusStack.baleTypeChanged:Bale Type Changed`);
              }
            );
          }
        }
      }
    );
  }

  refreshBaleCount() {
    (this.recordService as MiscanthusStacksService).updateBaleCount(this.record).subscribe((stack) => {
      this.updateRecord(stack);
    });
  }
}
