import {Component, Input} from '@angular/core';
import {PaymentMethods} from '../order.model';

@Component({
  selector: 'app-payment-method-i18n',
  template: `
  <ng-container i18n>
    {key, select,
      shopify_payment {Shopify payment}
      paypal {Paypal}
      cash {Cash}
      bank_transfer {Bank transfer}
      unknown {Unknown}
    }
  </ng-container>
  `,
})
export class PaymentMethodI18nComponent {

  @Input()
  key: PaymentMethods;

}
