import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Product} from '../product.model';
import {ProductsService} from '../products.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {PriceGroup} from '../../price-groups/price-group.model';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {

  @Input()
  priceGroup: PriceGroup;

  @Output()
  productSelected: EventEmitter<Product> = new EventEmitter<Product>();

  private searchTerm: Subject<string> = new Subject<string>();
  private delayTime: 500;

  productMatches: Array<Product> = new Array<Product>();

  constructor(
    private productsService: ProductsService,
  ) { }

  ngOnInit(): void {
    this.searchTerm.pipe(
      debounceTime(this.delayTime)
    ).subscribe((searchTerm) => {
      if (this.priceGroup) {
        const products = this.priceGroup.products;
        this.productMatches = products.filter((prices) => {
          return prices.name.toLowerCase().startsWith(searchTerm.toLowerCase());
        });
      } else {
        this.productsService.getRecords({pageSize: 15, pageNumber: 0, search: {name: `*${searchTerm}*`}}).subscribe(
          (response) => {
            this.productMatches = response.records;
          });
      }
    });
  }

  doSearch(searchTerm: string){
    this.searchTerm.next(searchTerm);
  }

  selectedProduct(event: MatAutocompleteSelectedEvent): void{
    const productId = event.option.value;
    const productPicked = this.productMatches.find(product => product.id === productId);
    this.productSelected.emit(productPicked);
  }

}
