<gaia-shared-standard-dialog i18n-dialogTitle dialogTitle="Chose Placemark">


  <div content>
    <mat-spinner *ngIf="loading | async else selector"></mat-spinner>
    <ng-template #selector>
      <mat-form-field>
        <mat-select [formControl]="placemarkSelector">
          <mat-option *ngFor="let placemark of placemarks" [value]="placemark">{{ placemark.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </div>
  <div actions>
    <button mat-button [mat-dialog-close]="{ placemark: null }">Cancel</button>
    <button mat-button (click)="selectPlacemark()" [disabled]="!placemarkSelector.valid" i18n="button to select">Select</button>
  </div>

</gaia-shared-standard-dialog>
