import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';
import {StaffMember, StaffLeaveDetails, HRSummaryRow} from './staff-member.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffMemberService extends RestApiService<StaffMember> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('staff_members', 'staff_member', httpClient);
  }

   leaveDetails(staffMember: StaffMember, year= new Date().getFullYear()): Observable<StaffLeaveDetails> {
      return this.httpGet<StaffLeaveDetails>(this.generateUrl({ recordId: staffMember.id}, 'leave_details'), {year});
   }

  getQRCodeUrl(staffMember: StaffMember): string {
    return ('http:' + this.generateUrl({recordId: staffMember.id}, 'contact_qr_code'));
  }

  hrSummary(year): Observable<Array<HRSummaryRow>> {
    return this.httpGet<Array<HRSummaryRow>>(this.generateNonRecordUrl('hr_summary'), {year});
  }

  sendInvite(staffMember: StaffMember): Observable<string> {
    const url = this.generateUrl({ recordId: staffMember.id }, 'send_gaia_invite');
    return this.httpPost<string>(url, staffMember);
  }
}
