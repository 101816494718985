import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';
import {Event} from './events.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends RestApiService<Event> {

  constructor(
    httpClient: HttpClient
  ) {
    super('/events', 'event', httpClient);
  }

}
