import {Component, Inject, OnInit} from '@angular/core';
import {FormDialogData, FormDialogDirective} from '../../../components/form-dialog.directive';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContactType} from '../contact-type.model';
import {ContactTypesService} from '../contact-types.service';
import {ContactTypeFormControlService} from '../contact-type-form-control.service';

@Component({
  selector: 'app-contact-type-form-dialog',
  templateUrl: './contact-type-form-dialog.component.html',
  styleUrls: ['./contact-type-form-dialog.component.scss']
})
export class ContactTypeFormDialogComponent extends FormDialogDirective<ContactType> {

  constructor(
    baleTypesService: ContactTypesService,
    baleTypeFormControl: ContactTypeFormControlService,
    matDialog: MatDialogRef<ContactTypeFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData<ContactType>,
  ) {
    super(baleTypesService, baleTypeFormControl, matDialog, data);
  }

}
