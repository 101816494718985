import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BaleCollectionsEmbedTableComponent
} from './bale-collections-embed-table/bale-collections-embed-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {RestfulCalendarModule} from '../../restful-calendar/restful-calendar.module';
import {GaiaSharedModule} from '@terravesta/gaia-shared';



@NgModule({
  declarations: [BaleCollectionsEmbedTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    RouterModule,
    MatButtonModule,
    RestfulCalendarModule,
    GaiaSharedModule
  ],
  exports: [BaleCollectionsEmbedTableComponent]
})
export class BaleCollectionsSharedModule { }
