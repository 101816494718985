import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContractedField} from '../contracted-field.model';
import {ContractedFieldsService} from '../contracted-fields.service';
import {ContractedFieldFormControlService} from '../contracted-field-form-control.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-contracted-field-add-to-contract-dialog',
  templateUrl: './contracted-field-add-to-contract-dialog.component.html',
  styleUrls: ['./contracted-field-add-to-contract-dialog.component.scss']
})
export class ContractedFieldAddToContractDialogComponent implements OnInit {

  contractedField: ContractedField;
  formGroup: FormGroup;

  constructor(
    private contractedFieldsService: ContractedFieldsService,
    private contractedFieldsFormControlService: ContractedFieldFormControlService,
    private dialogRef: MatDialogRef<ContractedFieldAddToContractDialogComponent, ContractedField>,
    @Inject(MAT_DIALOG_DATA) data: ContractedFieldAddToContractDialogData,
    private matDialog: MatDialog,
  ) {
    this.contractedField = data.contractedField;
    this.formGroup = this.contractedFieldsFormControlService.addToTermContractForm();
  }

  ngOnInit(): void {
  }

  confirmThenAdd() {
    if (this.formGroup.valid) {
      const ref = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent,
        {
          data: {
            title: 'Confirm Contract Add',
            message: 'Are you sure you wish to add this field to the contract?',
          }
        });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.contractedFieldsService.addToTermContract(this.contractedField, this.formGroup.value).subscribe((response) => {
            this.dialogRef.close(response);
          });
        }
      });
    }
  }
}

export interface ContractedFieldAddToContractDialogData {
  contractedField: ContractedField;
}
