import { Injectable } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Contact} from './contact.model';
import {AddressFormControlService, HasNameFormControlService} from '@terravesta/gaia-shared';
import {GaiaRecordFormControl} from '../services/gaia-record-form-control';

@Injectable({
  providedIn: 'root'
})
export class ContactFormControlService implements GaiaRecordFormControl<Contact> {

  constructor(
    private formBuilder: FormBuilder,
    private addressFormControl: AddressFormControlService,
    private hasNameFormControl: HasNameFormControlService,
  ) { }

  toFormGroup(contact: Contact): FormGroup {
    const contactControls = {
      email: new FormControl(contact.email, [Validators.email, Validators.required]),
      address: this.addressFormControl.toFormControl(contact.address, false, false),
      phone: new FormControl(contact.phone),
      mobile: new FormControl(contact.mobile),
      accept_email_contact: new FormControl(contact.accept_email_contact),
      accept_post_contact: new FormControl(contact.accept_post_contact),
      accept_phone_contact: new FormControl(contact.accept_phone_contact),
      source_text: new FormControl(contact.source_text),
      job_title: new FormControl(contact.job_title),
      relationship_to_account: new FormControl(contact.relationship_to_account)
    };
    const nameGroup = this.hasNameFormControl.toFormControls(contact);
    return this.formBuilder.group({
      ...nameGroup, ...contactControls
    });
  }

  toSimpleForm(contact): FormGroup {
    const controls = {
      id: new FormControl(contact.id),
      email: new FormControl(contact.email, [Validators.email, Validators.required]),
      phone: new FormControl(contact.phone),
      mobile: new FormControl(contact.mobile),
      company_name: new FormControl(contact.company_name),
    };
    return this.formBuilder.group({
      ...controls,
      ...this.hasNameFormControl.toFormControls(contact),
    });
  }
}
