import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContactsService} from '../contacts.service';
import {Contact} from '../contact.model';
import {ContactFormControlService} from '../contact-form-control.service';
import {ShowPageDirective} from '../../components/show-page.directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InternalNoteTrackingService} from '../../internal-notes/internal-note-tracking.service';
import {LeadOverviewComponent} from '../lead-overview/lead-overview.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatDialog} from '@angular/material/dialog';
import {PhanesNode} from '@terravesta/phanes';

@Component({
  selector: 'app-contact-show',
  templateUrl: './contact-show.component.html',
  styleUrls: ['./contact-show.component.scss']
})
export class ContactShowComponent extends ShowPageDirective<Contact> implements OnInit {

  hasPostCode = false;
  account: PhanesNode;

  @ViewChild(LeadOverviewComponent)
  leadOverview: LeadOverviewComponent;

  constructor(
    contactsService: ContactsService,
    contactFormControl: ContactFormControlService,
    route: ActivatedRoute,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    internalNotesTracker: InternalNoteTrackingService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(contactsService, contactFormControl, route, snackBar, dialog, internalNotesTracker, changeDetectorRef);
  }

  recordGot(record: Contact) {
    if (this.record.address) {
      if (this.record.address.post_code) {
        this.hasPostCode = true;
      }
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    // this.leadOverview.reloadMap();
  }
}

