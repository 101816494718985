import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClimateDataSourcesRoutingModule} from '@terravesta/phanes';

export class SystemResourcesRoutes {
  public static HARVEST_HUB_HELP = 'harvest-hub-help';
  public static IMAGES = 'images';
  public static DOCUMENTS = 'documents';
  public static SENTINEL = 'sentinel';
  public static CLIMATE_DATA_SOURCES = 'climate-data-sources';
}

const routes: Routes = [
  {
    path: SystemResourcesRoutes.HARVEST_HUB_HELP,
    loadChildren: () => import('./harvest-hub-help/harvest-hub-help-routing.module').then(m => m.HarvestHubHelpRoutingModule),
  },
  {
    path: SystemResourcesRoutes.IMAGES,
    loadChildren: () => import('./images/images-routing.module').then(m => m.ImageRoutingModule),
  },
  {
    path: SystemResourcesRoutes.DOCUMENTS,
    loadChildren: () => import('./documents/documents-routing.module').then(m => m.DocumentsRoutingModule),
  },
  {
    path: SystemResourcesRoutes.SENTINEL,
    loadChildren: () => import('./sentinel/sentinel-routing.module').then(m => m.SentinelRoutingModule),
  },
  {
    path: SystemResourcesRoutes.CLIMATE_DATA_SOURCES,
    loadChildren: () => ClimateDataSourcesRoutingModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemResourcesRoutingModule { }
