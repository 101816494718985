import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {EstablishmentContract} from './establishment-contract.model';
import {HttpClient} from '@angular/common/http';
import {Grower} from '../../growers/grower.model';
import {Observable} from 'rxjs';
import {RailsAPIWrapper} from '@terravesta/phanes';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentContractsService extends RestApiService<EstablishmentContract> {

  constructor(
    httpClient: HttpClient
  ) {
    super('establishment_contracts', 'establishment_contract', httpClient);
  }

  forGrower(grower: Grower): Observable<RailsAPIWrapper<EstablishmentContract>> {
    return this.getRecords({ pageNumber: 0, pageSize: 100, search: {
        grower: { uuid: grower.id }
      }
    });
  }
}
