import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {GaiaIconSet} from '../../../../models/gaia-icon-set';
import {MiscanthusStack} from '../../miscanthus-stack.model';
import {BaleLoss} from '../bale-loss.model';
import {BaleLossesService} from '../bale-losses.service';
import {MatDialog} from '@angular/material/dialog';
import {BaleLossDialogComponent} from '../bale-loss-dialog/bale-loss-dialog.component';
import {ConfirmDialogComponent, ConfirmDialogData} from '@terravesta/gaia-shared';

@Component({
  selector: 'app-bale-losses-embed-table',
  templateUrl: './bale-losses-embed-table.component.html',
  styleUrls: ['./bale-losses-embed-table.component.scss']
})
export class BaleLossesEmbedTableComponent implements OnChanges {

  gaiaIconSet = GaiaIconSet;

  @Input()
  stack: MiscanthusStack;

  @Output()
  changeMade: EventEmitter<boolean> = new EventEmitter<boolean>();

  baleLosses: Array<BaleLoss>;
  displayedColumns = ['bales_lost', 'reason', 'date_of_loss', 'menu'];

  constructor(
    private baleLossesService: BaleLossesService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stack){
      if (changes.stack.currentValue) {
        this.loadBaleLosses();
      }
    }
  }

  loadBaleLosses(){
    this.baleLossesService.getRecords({parentId: this.stack.id, pageNumber: 0, pageSize: 30})
      .subscribe((baleLosses) => {
        this.baleLosses = baleLosses.records;
      });
  }

  editBaleLoss(baleLoss: BaleLoss) {
    const dialogRef = this.matDialog.open(BaleLossDialogComponent, {
      data: {
        record: baleLoss,
        parent: this.stack,
      }
    });
    dialogRef.afterClosed().subscribe(
      () => {
        this.loadBaleLosses();
        this.changeMade.emit(true);
      }
    );
  }

  deleteBaleLoss(baleLoss: BaleLoss) {
    const dialogRef = this.matDialog.open<any, any, boolean>(ConfirmDialogComponent, {
      data: {
        title: 'Confirm: Delete Bale Loss',
        message: 'Are you sure you wish to delete this bale loss record. This will effect the total number of bales in the stack. This action cannot be undone.'
      } as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.baleLossesService.deleteRecord({ recordId: baleLoss.id, parentId: this.stack.id }).subscribe(() => {
          this.loadBaleLosses();
          this.changeMade.emit(true);
        });
      }
    });
  }
}
