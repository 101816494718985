
<mat-expansion-panel>
  <mat-expansion-panel-header>
    Processor Stats
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="stat-container">
      <mat-card class="stat-box" *ngFor="let stat of stats">
        <mat-card-header>
          <mat-card-title>{{ stat.account_name }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-stats-number-line [displayNumber]="stat.stats.assigned_stacks" label="Assigned Stacks" i18n-label="label for number of stacks assigned to processor"></app-stats-number-line>
          <app-stats-number-line [displayNumber]="stat.stats.delivered" label="Amount Delivered (T)" i18n-label="label for amount of tonnage delivered to proessor"></app-stats-number-line>
          <app-stats-number-line [displayNumber]="stat.stats.estimated_assigned_tonnage" label="Estimated Assigned Tonnage (T)" i18n-label="label for the estimated amount of tonnage in stacks assigned to this processor"></app-stats-number-line>
          <app-stats-number-line [displayNumber]="stat.stats.assigned_growers" [overLine]="true" label="Assigned Growers" i18n-label="label for number of growers assigned to processor"></app-stats-number-line>
          <app-stats-number-line [displayNumber]="stat.stats.hectares_under_contract" label="Contracted Area (ha)" i18n-label="label for hectares of fields assigned to processor"></app-stats-number-line>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-template>
</mat-expansion-panel>
