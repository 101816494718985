import { Injectable } from '@angular/core';
import {RestApiService} from '../../../services/rest-api.service';
import {LoaderAvailability} from './loader-availability.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoaderAvailabilitiesService extends RestApiService<LoaderAvailability> {

  constructor(
    httpClient: HttpClient,
  ) {
    super('/loader_availabilities', 'loader_availability', httpClient);
  }
}
