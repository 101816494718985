import {Directive, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {DashboardGridConfig, MyDashboardWidgetConfig} from '../dashboard-config';
import {WidgetDefinition} from '../dashboard-components/dashboard-widget-factory';
import {UserDashboardComponent} from '../dashboard-components/user-dashboard/user-dashboard.component';

@Directive()
export abstract class DashboardWidgetDirective {

  @Output()
  widgetDeleted: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  editMode = false;

  @Input()
  dashboard: UserDashboardComponent;

  public widgetId = null;
  widgetCode = null;

  public gridWidth;
  public gridHeight;

  public gridX;
  public gridY;

  protected constructor(
    protected elementRef: ElementRef,
    protected renderer: Renderer2
  ) {
  }

  static widgetDefinition(): WidgetDefinition {
    throw new Error('Widget Missing Definition');
  }

  abstract processSettings(settings: any);

  setupWidget(config: MyDashboardWidgetConfig, gridSize: DashboardGridConfig) {
    this.widgetCode = config.widget_code;
    this.gridWidth = config.width;
    this.gridHeight = config.height;
    this.gridY = config.y ?? 1;
    this.gridX = config.x ?? 1;
    this.updateSize(gridSize);
    this.processSettings(config.settings);
  }

  updateSize(gridSize: DashboardGridConfig) {
    const natEl = this.elementRef.nativeElement;
    const width = (this.gridWidth * gridSize.cellWidth) + ((this.gridWidth - 1) * gridSize.gap);
    const height = (this.gridHeight * gridSize.cellHeight) + ((this.gridHeight - 1) * gridSize.gap);

    const x = ((this.gridX) * gridSize.cellWidth) + ((this.gridX) * gridSize.gap);
    const y = ((this.gridY) * gridSize.cellHeight) + ((this.gridY) * gridSize.gap);

    this.renderer.setStyle(natEl, 'height', `${height}px`);
    this.renderer.setStyle(natEl, 'width', `${width}px`);
    this.renderer.setStyle(natEl, 'display', 'block');
    this.renderer.setStyle(natEl, 'position', 'absolute');
    this.renderer.setStyle(natEl, 'top', `${y}px`);
    this.renderer.setStyle(natEl, 'left', `${x}px`);
  }

  deleteWidget(){
    this.widgetDeleted.emit(this.widgetId);
  }

  widgetMoved(position: { x: number, y: number }) {
    this.gridX = position.x;
    this.gridY = position.y;
  }

  saveWidget(): MyDashboardWidgetConfig {
    return {
      widget_code: this.widgetCode,
      x: this.gridX,
      y: this.gridY,
      width: this.gridWidth,
      height: this.gridHeight,
    };
  }

}
