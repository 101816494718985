<mat-card>
  <mat-card-header>
    <mat-card-title i18n>Delivery Months</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Harvest Year</mat-label>
      <mat-select [(ngModel)]="selectedYear" (ngModelChange)="yearChanged($event)">
        <mat-option *ngFor="let year of availableYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="createSchedule()" i18n>Create New Delivery</button>


    <ng-container *ngIf="selectedYear">
      <div echarts [options]="chartOptions"></div>
      <mat-table [dataSource]="months">

        <ng-container matColumnDef="month">
          <mat-header-cell *matHeaderCellDef i18n>Month</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.month | date: 'MMMM' }} {{ element.year }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="harvest_year">
          <mat-header-cell *matHeaderCellDef i18n>Harvest Year</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.harvest_year }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="planned_tonnage">
          <mat-header-cell *matHeaderCellDef i18n>Planned Tonnage</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.planned_tonnage }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustment_text">
          <mat-header-cell *matHeaderCellDef i18n>Adjustment Text</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.adjustment_text }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="adjustment_value">
          <mat-header-cell *matHeaderCellDef i18n>Adjustment Value</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.adjustment_value }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>
    </ng-container>
  </mat-card-content>
</mat-card>
