import { Component, OnInit } from '@angular/core';
import {GaiaIconSet} from '../../models/gaia-icon-set';
import {AppRoutes} from '../../app-routing.module';
import {SessionDataService} from '../../services/session-data.service';
import {StaffMember} from '../../system-admin/staff-member/staff-member.model';
import {DashboardRoutes} from '../../dashboard/dashboard-routing.module';

@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss']
})
export class SideDrawerComponent implements OnInit {

  gaiaIcons = GaiaIconSet;
  appRoutes = AppRoutes;
  dashboardsRoute = DashboardRoutes;

  currentUser: StaffMember;

  constructor(private sessionDataService: SessionDataService) { }

  ngOnInit(): void {
    this.currentUser = this.sessionDataService.getUser();
  }


}
