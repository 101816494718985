<div class="new-wrapper">
  <gaia-shared-processing-overlay defaultMessage="Saving..."></gaia-shared-processing-overlay>
  <phanes-standard-dialog [dialogTitle]="dialogTitle" color="primary">
    <div content class="content">
      <phanes-error-panel></phanes-error-panel>

      <form [formGroup]="form">
        <ng-content></ng-content>
      </form>
    </div>
    <div actions>
      <button mat-button class="save" [disabled]="!canSave" color="primary" i18n="save new record" (click)="emitSave()">Submit</button>
      <button mat-button class="close" type="button" (click)="checkClose()">
        <ng-container *ngIf="record else noRecord">
          <ng-container *ngIf="record.id else noRecord">
            <span i18n="close dialog">Close</span>
          </ng-container>
        </ng-container>
        <ng-template #noRecord>
          <span i18n="cancel dialog">Cancel</span>
        </ng-template>
      </button>
    </div>
  </phanes-standard-dialog>
</div>
