import { Injectable } from '@angular/core';
import {Stockist} from './stockist.model';
import {HttpClient} from '@angular/common/http';
import {RestApiService} from '../../services/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class StockistsService extends RestApiService<Stockist>{

  constructor(
    httpClient: HttpClient
  ) {
    super('/stockists', 'stockist', httpClient);
  }
}
