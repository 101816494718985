import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent implements OnInit {

  file: File = null;

  // @Input() allowMultiple = false;
  allowMultiple = false;
  @Input() buttonText: string;

  @Output()
  fileSelected = new EventEmitter<File>();

  @Input()
  accept: string;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.file = file;
    this.fileSelected.emit(file);
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
  ) { }

  ngOnInit(): void {
  }

  unLinkFile() {
    this.host.nativeElement.value = '';
    this.file = null;
  }
}
