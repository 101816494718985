import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardWidgetWrapperComponent} from './dashboard-widget-wrapper/dashboard-widget-wrapper.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { DashboardWidgetChartComponent } from './dashboard-widget-chart/dashboard-widget-chart.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {DragDropModule} from '@angular/cdk/drag-drop';



@NgModule({
  declarations: [DashboardWidgetWrapperComponent, DashboardWidgetChartComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        FlexModule,
        MatIconModule,
        MatMenuModule,
        NgxEchartsModule,
        DragDropModule
    ],
  exports: [
    DashboardWidgetWrapperComponent,
    DashboardWidgetChartComponent
  ]
})
export class DashboardSharedModule { }
