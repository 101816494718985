import {Component, forwardRef, Injector, Input} from '@angular/core';
import {MiscanthusVarietiesService} from '../miscanthus-varieties.service';
import {MiscanthusVariety} from '../miscanthus-variety.model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {RecordListSelectDirective} from '@terravesta/phanes';

@Component({
  selector: 'app-miscanthus-variety-select',
  templateUrl: './miscanthus-variety-select.component.html',
  styleUrls: ['./miscanthus-variety-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MiscanthusVarietySelectComponent),
      multi: true
    }
  ]
})
export class MiscanthusVarietySelectComponent extends RecordListSelectDirective<MiscanthusVariety>{

  @Input()
  labelText = 'Variety';

  constructor(
    inj: Injector,
    miscanthusVarietiesService: MiscanthusVarietiesService,
  ) {
    super(miscanthusVarietiesService, inj);
  }

}
